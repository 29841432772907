import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/productRequests";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getCompanyProductRequestById(id) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/getCompanyProductRequestById/${id}`
    );
    return response.data;
  },

  async getCommonAvailabilities(teacherId, students, productRequestId) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/findCommonAvailabilities`,
      {
        params: {
          teacher: teacherId,
          students: students,
          productRequest: productRequestId,
        },
      }
    );
    return response.data;
  },

  async save(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
  },

  async saveForCompany(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}/company`, entity)).data;
  },

  async updateState(entity) {
    return (
      await HTTP.put(
        `${RESOURCE_NAME}/${entity.id}/state`,
        {},
        { params: { requestStatus: entity.state } }
      )
    ).data;
  },
};
