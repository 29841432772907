var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lectures)?_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"type":"info","elevation":"2","dismissible":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("lecture.headers.close-list-info"))+" ")]),_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"rows-clickable",attrs:{"headers":_vm.headers,"items":_vm.lectures,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.lectureDetail},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [(item.productName)?_c('span',[_vm._v(" "+_vm._s(item.productName === "-" ? _vm.$t("lecture.levelTest") : item.productName)+" ")]):_vm._e()]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [(item.startTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.startTime,"medium"))+" ")]):_vm._e()]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [(item.endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.endTime,"medium"))+" ")]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("lecture.states." + (item.state))))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"color":"success","disabled":!_vm.userCanJoin(item),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(item)}}},[_c('v-icon',[_vm._v(" login ")])],1)],1)]}}],null,true)},[(_vm.userCanJoin(item))?_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))]):_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join-not-ready")))])])]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }