<template>
  <v-dialog persistent width="500" :value="dialog">
    <v-form v-model="validForm">
      <v-card>
        <v-card-title primary-title class="headline primary white--text">
          <v-icon class="mr-4 white--text">school</v-icon>
          {{ $t("lecture.associateLevel") }}
        </v-card-title>
        <v-card-text class="mt-4">
          <level-selector
            v-model="selectedLevel"
            :languageFilter="languageFilter"
            outlined
            append_icon="school"
            class="required"
            :label="$t('level.name')"
            :rules="[(v) => !!v || $t('level.error.required')]"
          ></level-selector>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('cancel')">
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn
            class="mx-4 primary"
            :disabled="!validForm"
            @click="$emit('submit', selectedLevel)"
          >
            {{ $t("actions.accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import LevelSelector from "@/components/selectors/LevelSelector";

/**
 * Events emitted: cancel, submit
 */
export default {
  name: "AssociateLevelDialog",
  components: {
    LevelSelector,
  },
  props: {
    level: {
      type: Object,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    languageFilter: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      validForm: true,
      selectedLevel: null,
    };
  },
  created() {
    if (this.level) {
      this.selectedLevel = this.level.level;
    }
  },
};
</script>
