<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="mx-4 d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.cancel") }}
              </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save" :disabled="!validForm">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm" ref="form">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                class="required"
                v-model="wordlist.name"
                type="text"
                :rules="[(v) => !!v || $t('wordlist.error.required')]"
                :label="$t('wordlist.prop.name')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <level-selector
                outlined
                append_icon="school"
                class="required"
                v-model="wordlist.level"
                :label="$t('wordlist.prop.level')"
                :rules="[(v) => !!v || $t('wordlist.error.required')]"
              ></level-selector>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <wordlist-selector
                v-model="wordlist.childWordlist"
                :label="$t('wordlist.prop.child_wordlist')"
                :request-params="childWordlistSelectorParams"
                append_icon="school"
                clearable
                outlined
              ></wordlist-selector>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row>
            <v-col cols="12">
              <h3 class="ml-2 text-center text-md-left">
                {{ $t("wordlist.prop.words") }}
              </h3>
              <word-list
                creatable="true"
                editable="true"
                removable="true"
                :language="wordlist.level ? wordlist.level.language : null"
                :wordlist="wordlist.id"
                @savedWord="saveWords"
              ></word-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import LevelSelector from "@/components/selectors/LevelSelector";
import WordList from "@/mockups/content-cloud/wordlist/WordList";
import WordlistSelector from "@/components/selectors/WordlistSelector";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WordlistEntityRepository = RepositoryFactory.get(
  "WordlistEntityRepository"
);

export default {
  name: "WordlistForm",
  components: {
    LoadingPage,
    LevelSelector,
    WordlistSelector,
    WordList,
  },
  data() {
    return {
      validForm: true,
      loading: false,
      wordlist: {},
      selectedWord: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this._fetchData(this.$route.params.id);
    }
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    childWordlistSelectorParams() {
      let result = { params: {} };
      if (this.wordlist) {
        // Avoid associating the wordlist with itself
        result.params.notIncludeId = this.wordlist.id;
      }
      if (this.wordlist.level) {
        // Only show wordlists of the same language
        result.params.langId = this.wordlist.level.language.id;
        result.params.maxOrder = this.wordlist.level.levelOrder;
      }
      return result;
    },
    langIds() {
      return this.wordlist.level && [this.wordlist.level.language.id];
    },
  },

  methods: {
    _fetchData(id) {
      this.loading = true;
      return WordlistEntityRepository.get(id)
        .then((response) => {
          this.wordlist = response;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    saveWords(words) {
      this.wordlist.words = words;
    },
    save() {
      let wordlistToSave = { ...this.wordlist };
      wordlistToSave.level = this.wordlist.level.id;

      // If a childWordlist is selected, we ony send their ID
      wordlistToSave.childWordlist &&= this.wordlist.childWordlist.id;

      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("wordlist.error.form-errors"),
          duration: 30000,
        });
        this.loading = false;
        return;
      }

      const route = this.$route.params.id
        ? {
            name: "Wordlist Detail",
            params: {
              id: this.wordlist.id,
              backAction: this.$route.params.backPrevious,
            },
          }
        : {
            name: "Wordlist List",
          };
      WordlistEntityRepository.save(wordlistToSave)
        .then(() => this.$router.push(route))
        .catch(() =>
          this.$log.debug("Error saving wordlist: " + wordlistToSave)
        )
        .finally(() => (this.loading = false));
    },

    back() {
      this.$router.push({
        name: "Wordlist List",
        params: { backAction: true },
      });
    },
  },
};
</script>
