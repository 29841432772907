<template>
  <button
    class="clrm-button"
    :class="classAlign"
    :title="title"
    :aria-label="ariaLabel"
    @click="$emit('click')"
  >
    <div class="clrm-button__icon">
      <div v-if="badge" class="badge">{{ pBadge }}</div>
      <icon
        v-if="icon"
        :name="icon"
        :width="iconWidth"
        :height="iconHeight"
        class="clrm-button__icon2"
      />
    </div>
    <div v-if="slotPassed" class="clrm-button__content"><slot></slot></div>
    <span v-if="text" class="clrm-button__text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "ClrmButton",
  props: {
    text: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    iconWidth: {
      type: [Number, String],
      default: null,
      required: false,
    },
    iconHeight: {
      type: [Number, String],
      default: null,
      required: false,
    },
    align: {
      type: String,
      default: null,
      required: false,
    },
    badge: {
      default: null,
      required: false,
    },
  },
  computed: {
    slotPassed() {
      return !!this.$slots.default && !!this.$slots.default[0];
    },
    ariaLabel() {
      return !this.text ? this.title : null;
    },
    classAlign() {
      return this.align ? "clrm-button--align-" + this.align : null;
    },
    classObject() {
      return {
        active: this.title,
        "text-danger": this.align,
      };
    },
    pBadge() {
      if (typeof this.badge === "number") {
        return this.badge;
      } else if (this.badge) {
        return " ";
      }
      return null;
    },
  },
};
</script>

<style scoped>
.badge {
  min-height: 10px;
  min-width: 10px;
}
</style>
