<template>
  <v-dialog persistent width="500" :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline error white--text">
        {{ $t("profile.unregister.card_title") }}
      </v-card-title>
      <v-container v-if="!isLoading" fluid>
        <v-card-text>
          <label class="d-block red--text" v-if="isUnableToUnregister">
            {{
              isTutor && currentUserId === userId
                ? $t("profile.unregister.tutorHasRegistrations")
                : $t("profile.unregister.hasRegistrations")
            }}
          </label>
          <label> {{ $t("profile.unregister.label_text") }}</label>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('cancel')">
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn
            class="mx-4 error"
            @click="unregister"
            :disabled="isUnableToUnregister"
          >
            {{ $t("actions.accept") }}
          </v-btn>
        </v-card-actions>
      </v-container>
      <loading-page v-else></loading-page>
    </v-card>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import UserManagementRepository from "@/repositories/components/UserManagementRepository";
import LoadingPage from "@/components/loading-page/LoadingPage";

const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");
export default {
  components: { LoadingPage },
  props: {
    userId: {
      type: Number,
      required: false,
    },
    studentId: {
      type: Number,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isUnableToUnregister: true,
      loading: false,
      currentUserId: auth.getUser().id,
    };
  },
  computed: {
    ...mapAuthGetter(["isTutor", "isAnyTeacher", "isAnyStudent"]),
    isLoading() {
      return this.loading;
    },
  },
  mounted() {
    this.loading = true;
    UserManagementRepository.isUnableToUnregister(this.userId)
      .then((res) => (this.isUnableToUnregister = res.data))
      .catch(() => this.$log.debug("Error fetching registrations"))
      .finally(() => (this.loading = false));
  },
  methods: {
    unregister() {
      this.loading = true;
      UserManagementRepository.unregister(this.userId)
        .then(() => {
          if (this.isTutor && this.currentUserId !== this.userId) {
            this.deleteFromTutor().then(() => this.$router.goToUserHome());
          } else {
            auth.logout().then(() => this.$router.push({ name: "Login" }));
          }
          this.$notify({
            title: this.$t("account.notifications.successfull"),
            text: this.$t("account.notifications.successfull_unregister"),
            type: "success",
          });
        })
        .catch(() => this.$log.debug("Error unsubscribing"))
        .finally(() => (this.loading = false));
    },
    deleteFromTutor() {
      return TutorEntityRepository.deleteFromTutor(
        this.currentUserId,
        this.studentId
      )
        .then(() =>
          this.$notify({
            title: this.$t("account.notifications.successfull"),
            text: this.$t("account.notifications.successfull_unlinked"),
            type: "success",
          })
        )
        .catch(() => this.$log.debug("Error unlinking student from tutor"));
    },
  },
};
</script>
