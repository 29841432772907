<template>
  <div>
    <v-row class="lesson-row" align="center">
      <v-col cols="8">
        <v-form v-model="validForm">
          <span v-if="!editing" class="headline">
            {{ myLesson.name }}
          </span>

          <v-text-field
            v-else
            outlined
            v-model="myLesson.name"
            class="required"
            :rules="[(v) => !!v || $t('lesson.error.required')]"
          ></v-text-field>
        </v-form>
      </v-col>

      <!-- Action buttons for CRUD lessons -->
      <v-col cols="4" class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="!editing"
              @click="enableEdit()"
              icon
              color="warning"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("actions.edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!editing"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog = true"
              icon
              color="error"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("actions.edit") }}</span>
        </v-tooltip>

        <!-- Actions for changing lesson name -->
        <v-btn
          :disabled="!validForm"
          @click="saveLessonName()"
          color="success"
          v-if="editing"
          icon
        >
          <v-icon>check</v-icon>
        </v-btn>
        <v-btn v-if="editing" @click="disableEdit" color="error" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <delete-dialog
      :dialog="showDeleteDialog"
      :loading="deleteLoading"
      :title="$t('lesson.messages.confirmation_delete')"
      @cancel="showDeleteDialog = false"
      @submit="deleteLesson"
    >
    </delete-dialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");
export default {
  name: "LessonDetail",
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  watch: {
    lesson(value) {
      this.$set(this, "myLesson", value);
    },
  },
  components: {
    DeleteDialog,
  },
  created() {
    Object.assign(this.myLesson, this.lesson);
  },
  data() {
    return {
      deleteLoading: false,
      editing: false,
      myLesson: {},
      showDeleteDialog: false,
      validForm: true,
    };
  },
  methods: {
    // Edit name functions
    enableEdit() {
      this.editing = true;
    },
    disableEdit() {
      this.editing = false;
      Object.assign(this.myLesson, this.lesson);
    },
    // Persist lesson data functions
    saveLessonName() {
      if (this.validForm) {
        LessonEntityRepository.save(this.myLesson)
          .then(() => {
            this.editing = false;
            this.$emit("changed", this.unit);
          })
          .catch(() =>
            this.$log.debug("Error saving lesson: " + this.myLesson)
          );
      }
    },
    deleteLesson() {
      this.deleteLoading = true;
      LessonEntityRepository.delete(this.myLesson.id)
        .then(() => {
          this.$notify({
            title: "Lesson deleted",
            text: this.$t("account.notifications.successfull_deleted"),
            type: "success",
          });
          this.$emit("changed", this.unit);
          this.showDeleteDialog = false;
        })
        .catch(() =>
          this.$log.debug("Error deleting lesson with ID " + this.myLesson.id)
        )
        .finally(() => (this.deleteLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.grey-color {
  background-color: #97d2ff88;
}
</style>
