var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":"","value":_vm.dialog && _vm.selectedProduct}},[(_vm.dialog && _vm.selectedProduct)?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text headline",attrs:{"primary-title":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',{staticClass:"primary white--text mr-4"},[_vm._v(" school ")]),_vm._v(" "+_vm._s(_vm.$t("productRequest.modal.title"))+" ")],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.cancelDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('day-picker',{attrs:{"calendarColor":"primary","date":_vm.limitDate,"outlined":"","clearable":"","label":_vm.$t('productRequest.prop.limitDate'),"futureDates":true,"pastDates":false},on:{"change":_vm.handleLimitDate}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('autocomplete',{staticClass:"required",attrs:{"items":_vm.students,"rules":[
                function (v) { return !!v || _vm.$t('productRequest.modal.validation.required'); },
                function (v) { return v.length >= _vm.selectedProduct.minStudents ||
                  _vm.$t('productRequest.modal.validation.min_students', {
                    value: _vm.selectedProduct.minStudents,
                  }); },
                function (v) { return v.length <= _vm.selectedProduct.maxStudents ||
                  _vm.$t('productRequest.modal.validation.max_students', {
                    value: _vm.selectedProduct.maxStudents,
                  }); } ],"outlined":"","multiple":"","item-text":_vm.getFullName,"item-value":"id","label":_vm.$t('productRequest.modal.students'),"loading":_vm.loadingStudents},model:{value:(_vm.studentIds),callback:function ($$v) {_vm.studentIds=$$v},expression:"studentIds"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("productRequest.modal.required-classes-per-week", { value: _vm.selectedProduct.classesPerWeek, })))])]),_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("productRequest.modal.required-slots-per-class", { value: _vm.slotsPerClass, })))])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-4 primary",attrs:{"loading":_vm.loading,"disabled":!_vm.validSchedule || !_vm.validForm},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("actions.send"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"xs":""}},[_vm._v("mdi-send")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"height":_vm.height}},[_c('v-calendar',{ref:"calendar",attrs:{"value":_vm.focus,"color":"primary","events":_vm.availabilities,"event-color":_vm.getEventColor,"interval-height":25,"interval-minutes":30,"interval-count":48,"type":"week","weekdays":[1, 2, 3, 4, 5, 6, 0],"locale":_vm.localeRoot},on:{"click:event":_vm.selectSlot},scopedSlots:_vm._u([{key:"event",fn:function(ref){
              var event = ref.event;
return [_c('div',{staticClass:"event-div"},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])]),(event.checked)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()],1)],1)]}}],null,false,1895411995)})],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }