<template>
  <v-container fluid>
    <v-card flat :loading="isLoading">
      <v-row v-if="isLoading" class="mt-16 mb-16" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-card-title v-if="!isLoading">
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col cols="12" md="7" class="text-center text-sm-right">
            <v-btn class="warning" @click="goToEditForm">
              <v-icon>edit</v-icon>
              {{ $t("account.actions.edit_profile") }}
            </v-btn>
            <v-btn
              class="primary ml-3"
              :to="{ name: 'ChangePassword' }"
              v-if="isSameUser"
            >
              <v-icon>vpn_key</v-icon>
              {{ $t("account.actions.change_password") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!isLoading">
        <v-row v-if="userData" align="start" justify="start">
          <!-- Photo, name and about me -->
          <v-col cols="12" md="3" lg="3">
            <!-- Title for movile version -->
            <v-row class="d-block d-md-none">
              <v-col class="centered-element" cols="12">
                <div class="headline text-center">
                  {{ fullName }}
                </div>
                <div class="caption">
                  {{ userData.displayName }}
                </div>
                <div class="caption">
                  {{ userData.login }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="centered-element">
                <v-img
                  max-height="250"
                  style="margin: auto"
                  max-width="250"
                  :src="photo"
                ></v-img>
              </v-col>
              <v-col cols="12" v-if="isAdmin">
                <span>
                  <v-icon class="mr-1">receipt</v-icon>
                  <b class="mr-4"> {{ $t("profile.fields.billing_id") }}: </b>
                  {{ userData.billingId }}
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-3 d-none d-md-block"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="pb-0">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.fields.about_me") }}
                </div>
              </v-col>
              <v-col cols="12">
                <div class="aboutme-field">
                  {{ userData.aboutMe }}
                </div>
              </v-col>
            </v-row>
          </v-col>

          <!-- Another fields -->
          <v-col cols="12" md="9" lg="9">
            <v-row class="d-none d-md-block" no-gutters>
              <v-col cols="12">
                <div class="headline">
                  {{ fullName }}
                </div>
                <div class="caption">
                  {{ userData.displayName }}
                </div>
                <div class="caption">
                  {{ userData.login }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="start" no-gutters>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.fields.contact_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.main_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ userData.mainPhone }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.secondary_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ userData.secondaryPhone }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.main_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ userData.mainEmail }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.secondary_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ userData.secondaryEmail }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-info field-info--icon field-info--multiline">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">mdi-home-city</v-icon>
                    {{ $t("profile.fields.address") }}:
                  </label>
                  <span>{{ fullAddress }}</span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_emails") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="userData.acceptEmails != null"
                  >
                    {{ $t(`${userData.acceptEmails}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_whats") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="userData.acceptWhats != null"
                  >
                    {{ $t(`${userData.acceptWhats}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_calls") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="userData.acceptCalls != null"
                  >
                    {{ $t(`${userData.acceptCalls}`) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1 pt-6">
                  {{ $t("profile.personal_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.birthdate") }}:</label
                  >
                  <span class="field-info__content">
                    {{ userData.birthdate | dateTimeWithoutTz }}
                  </span>
                </div>
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.genre") }}:</label
                  >
                  <span class="field-info__content" v-if="userData.genre">
                    {{ $t(`genre.values.${userData.genre}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.nif") }}:</label
                  >
                  <span class="field-info__content">
                    {{ userData.nif }}
                  </span>
                </div>
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.nif_expiration") }}:</label
                  >
                  <span
                    class="field-info__content"
                    v-if="userData.nifExpiration"
                  >
                    {{ userData.nifExpiration | dateTimeWithoutTz }}
                  </span>
                </div>
              </v-col>
              <v-col>
                <div class="field-info" v-if="userData.timeZone">
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{
                      getOffset(userData.timeZone.name) +
                      " " +
                      $t(`timezones.${userData.timeZone.name}`)
                    }}
                  </span>
                </div>
                <div class="field-info" v-else>
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{ $t("profile.fields.timeZone_automatic") }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!isLoading">
        <v-col class="text-right">
          <v-btn @click="showDialog = true" class="warning" v-if="isTutor">
            <span class="d-none d-sm-block">
              {{ $t("profile.unregister.button_label") }}
            </span>
          </v-btn>
          <unregister-dialog
            v-if="showDialog"
            :userId="userData.userId"
            :dialog="showDialog"
            @cancel="showDialog = false"
          ></unregister-dialog>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { getOffset } from "@/common/timeZoneUtils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import checkInvalidID from "@/common/checkInvalidID";
import UnregisterDialog from "@/mockups/components/UnregisterDialog";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "Profile",
  components: {
    UnregisterDialog,
  },
  data() {
    return {
      loading: false,
      user: auth.getUser(),
      userData: null,
      photo: require("@/assets/icon_visits.png"),
      showDialog: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    ...mapAuthGetter(["isAdmin", "isTutor"]),
    entityIsTutor() {
      return this.userData.authorities
        ? this.userData.authorities.indexOf("ROLE_TUTOR") !== -1
        : false;
    },
    isSameUser() {
      return this.userData ? this.userData.userId === this.user.id : false;
    },
    fullName() {
      return (
        (this.userData.name ? this.userData.name : "") +
        (this.userData.surname ? " " + this.userData.surname : "")
      );
    },
    fullAddress() {
      const aux = [
        this.userData.address,
        this.userData.postalcode,
        this.userData.town,
        this.userData.country,
      ].filter((i) => i && i !== "");
      return aux.join(", ");
    },
  },
  mounted() {
    this.$route.params.id
      ? this._fetchData(this.$route.params.id)
      : this._fetchData(this.user.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      UserDataEntityRepository.getByUserId(id)
        .then((res) => {
          this.userData = res;
          UserDataEntityRepository.getPhoto(id)
            .then((data) => {
              if (data.size > 0) {
                this.photo = URL.createObjectURL(data);
              }
            })
            .catch(() =>
              this.$log.debug(
                "Error fetching profile photo for user with ID " + id
              )
            );
        })
        .catch((err) => {
          this.$log.debug("Error fetching user data for user with ID " + id);
          checkInvalidID(err);
        })
        .finally(() => (this.loading = false));
    },
    goToEditForm() {
      this.isAdmin && !this.isSameUser
        ? this.$router.push({
            name: "AdminEditProfile",
            params: {
              id: this.userData.userId,
              backPrevious: true,
            },
          })
        : this.$router.push({
            name: "EditProfile",
            params: { backPrevious: true },
          });
    },
    getOffset: getOffset,
  },
};
</script>
