<template>
  <v-container>
    <v-card v-if="!loading">
      <v-card-title>
        <span class="headline no-split-words">
          {{ test.name }}
        </span>
      </v-card-title>
      <v-card-text v-if="test.activities.length > 0">
        <div v-for="activity in pendingActivities" :key="activity.id">
          <H5PPlayer
            :exercise="activity.activity.content.fileName"
            :onXApiStatement="processStatement"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="finished" @click="back">
          <v-icon>arrow_back</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("lesson.actions.leave_test") }}
          </span>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!finished"
          :disabled="!saved"
          color="primary"
          @click="saveAndLeave"
          ><span class="d-none d-sm-block">
            {{ $t("lesson.actions.save_leave") }}</span
          ></v-btn
        >
        <v-btn :disabled="!finished" color="success" @click="back">
          <span class="d-none d-sm-block">
            {{ $t("lesson.actions.finish_test") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import H5PPlayer from "../../content-cloud/activity/H5PPlayer.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import XApiStatementsManagement from "./XApiStatementsManagement";

const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const StudentLevelEvaluationEntityRepository = RepositoryFactory.get(
  "StudentLevelEvaluationEntityRepository"
);

export default {
  name: "LevelTest",
  components: {
    H5PPlayer,
    LoadingPage,
  },
  props: {
    lessonId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      test: {},
      studentLevelEvaluation: {},
      saved: false,
      loading: false,
      finished: false,
      activityId: null,
      numActivities: null,
      processStatement: null,
      numCompleted: 0,
      pendingActivities: [],
      completedActivities: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchLesson(to.query.lessonId);
    next();
  },
  created() {
    this.loading = true;
    this._fetchLesson(this.$route.query.lessonId)
      .then(() =>
        this._fetchActivityInteractions(
          this.$route.query.lessonId,
          this.$route.params.studentId
        )
      )
      .finally(() => (this.loading = false));
  },
  watch: {
    numCompleted() {
      if (this.numCompleted >= this.numActivities) {
        this.finished = true;
        if (this.studentLevelEvaluation && !this.loading) {
          this.studentLevelEvaluation.state = "COMPLETED";
          this.saveStudentLevelEvaluation();
        }
      }
    },
  },
  methods: {
    async _fetchLesson(lessonId) {
      await LessonEntityRepository.get(lessonId)
        .then((response) => {
          this.test = response;
          this.numActivities = this.test.activities.length;
          this._fetchStudentLevelEvaluation(
            this.$route.query.studentLevelEvaluationId,
            response.id
          );
        })
        .catch(() => {
          this.$log.debug("Error fetching lesson with ID: " + lessonId);
          this.back();
        });
      this.processStatement = XApiStatementsManagement.processStatement.bind(
        this,
        this.$route.query.lessonId,
        undefined,
        this.onSaved
      );
    },
    async _fetchStudentLevelEvaluation(studentLevelEvaluationId, testId) {
      await StudentLevelEvaluationEntityRepository.get(
        studentLevelEvaluationId,
        { params: { testId: testId } }
      )
        .then((response) => (this.studentLevelEvaluation = response))
        .catch(() => {
          this.$log.debug(
            "Error fetching student level evaluation with ID: " +
              studentLevelEvaluationId
          );
          this.back();
        });
    },
    async _fetchActivityInteractions(lessonId, studentId) {
      await StudentEntityRepository.getLevelEvaluationActivityInteractions(
        studentId,
        lessonId
      )
        .then((response) => {
          this.completedActivities = response;
          this.numCompleted = this.completedActivities.length;
          this.filterCompleted();
        })
        .catch(() => {
          this.$log.debug(
            "Error fetching activities for student with ID: " + studentId
          );
          this.back();
        });
    },
    filterCompleted() {
      if (this.completedActivities.length > 0) {
        this.pendingActivities = this.test.activities.filter(
          (act) =>
            !this.completedActivities.some(
              (ca) => ca.activityId === act.activityId
            )
        );
      } else {
        this.pendingActivities = this.test.activities;
      }
    },
    onSaved() {
      // Executed when a H5P activity is completed
      this.saved = true;
      this.numCompleted += 1;
    },
    saveAndLeave() {
      this.$notify({
        title: this.$t("lesson.messages.test_saved"),
        text: this.$t("lesson.messages.progress_saved"),
        type: "success",
      });
      this.back();
    },
    saveStudentLevelEvaluation() {
      StudentLevelEvaluationEntityRepository.save(this.studentLevelEvaluation)
        .then(() => {
          this.$notify({
            title: this.$t("lesson.messages.test_completed"),
            text: this.$t("lesson.messages.results_saved"),
            type: "success",
          });
        })
        .catch(() =>
          this.$log.debug("Error saving the student level evaluation ")
        );
    },
    back() {
      this.$router.push({ name: "ProfileStudentLevelsTab" });
    },
  },
};
</script>
