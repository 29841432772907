var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.teacherName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'TeacherEducationTab',
              params: { id: item.teacherId },
            }}},[_vm._v(_vm._s(item.teacherName))])]}},{key:"item.level",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("languages." + (item.level.language.name))))+"/"+_vm._s(item.level.name)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(!_vm.loadingRow[item.id])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.levelAction(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" thumb_up ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.approve")))])]):_vm._e(),(!_vm.loadingRow[item.id])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.levelAction(item, false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" thumb_down ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.reject")))])]):_c('loading-spinner')]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }