<template>
  <v-container>
    <v-card>
      <v-row align="center" justify="space-between">
        <v-col class="mx-4 d-none d-md-block">
          <span class="headline no-split-words">
            {{
              !simplified
                ? $t($route.meta.label)
                : $t("course.actions.edit_details")
            }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="back" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="!validLesson"
            class="success mr-2"
            @click="save"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-form v-model="validForm">
        <v-row justify="center">
          <v-col cols="12" :sm="!simplified ? '6 ' : '12'" class="text-center">
            <v-row :no-gutters="!simplified">
              <v-col cols="12" :md="!simplified ? '12' : '4'">
                <v-text-field
                  dense
                  outlined
                  v-model="lesson.name"
                  class="required"
                  :rules="[(v) => !!v || $t('lesson.error.required')]"
                  :label="$t('lesson.prop.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" :md="!simplified ? '12' : '4'">
                <level-selector
                  v-model="lesson.level"
                  class="required"
                  dense
                  :disabled="lesson.id != null"
                  :label="$t('lesson.prop.level')"
                  outlined
                  :rules="[(v) => !!v || $t('lesson.error.required')]"
                >
                </level-selector>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-card-text v-if="lesson.id">
          <!-- Activities -->
          <div v-if="lesson.activities.length === 0">
            <v-row class="text-center" align="center">
              <v-col cols="12">
                <h3>{{ $t("activity.messages.no_activities") }}</h3>
              </v-col>
              <v-col cols="12">
                <v-btn color="success" @click="manageActivityDialog()">
                  <v-icon>add</v-icon>
                  {{ $t("activity.name") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                  class="mt-4 mr-3"
                  color="success"
                  @click="manageActivityDialog()"
                >
                  <v-icon>add</v-icon>
                  {{ $t("activity.name") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-card-text>
            <v-col
              v-for="(activity, index) in lesson.activities"
              :key="activity.id"
              class="py-0"
            >
              <activity-lesson-detail
                :id="'level-test-activity-row ' + activity.id"
                :activity="activity"
                :max-order="maxOrder"
                :loading="loadingOrder"
                @changed="updateInfo"
                @update-order="updateOrder(...arguments)"
              ></activity-lesson-detail>
              <v-divider
                v-if="index !== lesson.activities.length - 1"
                class="grey lighten-1"
              ></v-divider>
            </v-col>
          </v-card-text>
          <v-card-text class="text-center" v-if="!lesson.activities">
            {{ $t("unit.messages.no_activities_avail") }}
          </v-card-text>
        </v-card-text>
      </v-form>
    </v-card>
    <!-- Create / edit Activity dialog -->
    <v-dialog scrollable width="80%" v-model="activityFormDialog">
      <activity-lesson-form
        v-if="lesson && activityFormDialog"
        @cancel="manageActivityDialog(false)"
        @update="updateInfo()"
        :lesson="lesson"
      ></activity-lesson-form>
    </v-dialog>
  </v-container>
</template>

<script>
import ActivityLessonDetail from "@/mockups/content-cloud/_components/ActivityLessonDetail";
import ActivityLessonForm from "@/mockups/content-cloud/_components/ActivityLessonForm";
import LevelSelector from "@/components/selectors/LevelSelector";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");
const ActivityLessonEntityRepository = RepositoryFactory.get(
  "ActivityLessonEntityRepository"
);

export default {
  name: "LessonForm",
  components: { ActivityLessonDetail, ActivityLessonForm, LevelSelector },
  props: {
    entity: {
      type: Object,
      required: false,
    },
    simplified: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      lesson: {
        id: null,
        name: null,
        level: null,
        isLevelTest: true,
        activities: [],
      },
      loadingOrder: false,
      loading: false,
      validForm: true,
      // Activities
      activityFormDialog: false,
    };
  },
  computed: {
    validLesson() {
      return this.validForm;
    },
    maxOrder() {
      return Math.max.apply(
        Math,
        this.lesson.activities.map(function (o) {
          return o.order;
        })
      );
    },
  },
  created() {
    if (this.$route.params.id) {
      this._fetchData(this.$route.params.id);
    }
  },
  methods: {
    _fetchData() {
      if (this.$route.params.id) {
        LessonEntityRepository.get(this.$route.params.id)
          .then((res) => {
            this.lesson = res;
            this.lesson.activities.sort((a, b) => a.order - b.order);
          })
          .catch(() =>
            this.$log.debug(
              "Error fetching lesson with ID " + this.$route.params.id
            )
          );
      }
    },
    back() {
      this.$emit("cancelEdit");
    },
    save() {
      this.loading = true;
      LessonEntityRepository.save(this.lesson)
        .then((res) => {
          this.$notify({
            title: this.$t("lesson.messages.save_confirmation"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("saved", res);
        })
        .catch(() => this.$log.debug("Error saving level test: " + this.lesson))
        .finally(() => (this.loading = false));
    },

    updateInfo() {
      this._fetchData();
      this.manageActivityDialog(false);
    },
    // CRUD Activities
    manageActivityDialog(open = true) {
      this.activityFormDialog = open;
    },
    updateOrder(activity, order, loadingIndex) {
      this.loadingOrder = true;
      return ActivityLessonEntityRepository.updateOrder(activity.id, order)
        .then(() => {
          // Update activities order
          if (activity.order < order) {
            this.lesson.activities.forEach((el) => {
              if (el.order > activity.order && el.order <= order) {
                el.order--;
              }
            });
          } else {
            this.lesson.activities.forEach((el) => {
              if (el.order < activity.order && el.order >= order) {
                el.order++;
              }
            });
          }
          this.lesson.activities.find((el) => el.id === activity.id).order =
            order;
          // Sort activities again
          this.lesson.activities.sort((a, b) => a.order - b.order);
          // Next tick for wait data table updates rows order before apply css animations
          this.$nextTick(() => {
            const element = document.getElementById(
              "level-test-activity-row " + activity.id
            );
            element.classList.add("change-out");
            setTimeout(() => {
              element.classList.remove("change-out");
              element.classList.add("change-in");
              setTimeout(() => {
                element.classList.remove("change-in");
                this.loadingOrder = false;
              }, 1000);
            }, 400);
          });
        })
        .catch(() => {
          this.$log.debug(
            "Error updating order for activity with ID " + activity.activityId
          );
          this.loadingOrder = false;
        });
    },
  },
};
</script>
