import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/wallets";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAvailableStudents(id) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/getAvailableStudentsForEdition/${id}`
    );
    return response.data;
  },

  async getByStudentId(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/findByStudent/${id}`)).data;
  },

  async getByUserId(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/findByUser/${id}`)).data;
  },

  async getMovementsByStudentId(id, options = {}) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/findByStudent/${id}/movements`, options)
    ).data;
  },

  async getMovementsByUserId(id, options = {}) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/findByUser/${id}/movements`, options)
    ).data;
  },

  async getMovementById(id, type) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/movements/${id}`, {
        params: {
          type: type,
        },
      })
    ).data;
  },

  async chargeWallet(walletId, entity) {
    return (await HTTP.put(`${RESOURCE_NAME}/${walletId}/charge`, entity)).data;
  },

  async chargeWalletRedSys(walletId, entity) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/${walletId}/charge/redsys`, entity)
    ).data;
  },

  async balanceTransfer(userSourceId, targetsIds, amount) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/transfer/${userSourceId}`, amount, {
        params: {
          targets: targetsIds,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).data;
  },

  async captureOrder(reference, type, data = {}) {
    return (
      await HTTP.post(`${RESOURCE_NAME}/capture-order/${reference}`, data, {
        params: {
          type: type,
        },
      })
    ).data;
  },
  async refund(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/refund/${id}`, {})).data;
  },
};
