<template>
  <v-card flat>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" align="center">
        <v-col cols="5" class="text-right">
          <b>{{ $t("wallet.prop.balance") }}:</b>
        </v-col>
        <v-col cols="7" class="text-center">
          <h1>
            {{ $n(wallet.balance, "currency") }}
          </h1>
        </v-col>
        <v-col cols="5" class="text-right">
          <b>{{ $t("wallet.prop.expiration") }}:</b>
        </v-col>
        <v-col cols="7" class="text-center">
          <h1 v-if="wallet.expiration">
            {{ wallet.expiration | dateTimeWithoutTz }}
          </h1>
        </v-col>
      </v-row>
      <br />
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <br />
      <v-row v-if="wallet.nextPayment">
        <!-- next payment -->
        <v-col cols="5" class="text-right">
          <b>{{ $t("wallet.prop.next_payment") }}:</b>
        </v-col>
        <v-col cols="7" class="text-center">
          <h1 class="payment">
            {{ $n(wallet.nextPayment, "currency") }}
          </h1>
        </v-col>
        <v-col cols="5" class="text-right">
          <b>{{ $t("wallet.prop.next_payment_date") }}:</b>
        </v-col>
        <v-col cols="7" class="text-center">
          <h1>
            {{ lastDayOfMonth | dateTimeWithoutTz("short") }}
          </h1>
        </v-col>
      </v-row>
      <v-row v-if="!isAdmin && wallet.id">
        <v-col cols="12" class="text-center">
          <charge-dialog
            v-model="chargeDialog"
            :walletId="wallet.id"
            @save="chargeWallet"
            @charged="updateWallet"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="success"
                v-bind="attrs"
                v-on="on"
                :disabled="!invoiceData"
              >
                <v-icon>mdi-plus</v-icon>
                <span>{{ $t("wallet.actions.charge") }}</span>
              </v-btn>
              <p v-if="!invoiceData">
                {{ $t("wallet.messages.invoice_data.text") }}{{ emptyFields }}
              </p>
            </template>
          </charge-dialog>
        </v-col>
        <v-col
          v-if="sourceId && targets && targets.length > 0"
          class="text-center"
        >
          <transfer-dialog
            v-model="transferDialog"
            :balance="wallet.balance"
            :targets="targets"
            :multiple-targets="multipleTargets"
            @save="balanceTransfer"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-swap-horizontal</v-icon>
                <span>{{ $t("wallet.actions.transfer") }}</span>
              </v-btn>
            </template>
          </transfer-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import ChargeDialog from "./components/ChargeDialog";
import TransferDialog from "./components/TransferDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateToDateArray } from "@/common/conversion-utils";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "BalanceDetail",
  components: { ChargeDialog, TransferDialog },
  props: {
    value: {
      type: Object,
      required: true,
    },
    multipleTargets: {
      type: Boolean,
      required: false,
      default: false,
    },
    sourceId: {
      type: Number,
      required: false,
    },
    targets: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      chargeDialog: false,
      transferDialog: false,
      invoiceData: false,
      emptyFields: "",
      wallet: {},
      user: auth.getUser(),
      userData: null,
      lastDayOfMonth: null,
    };
  },
  created() {
    const today = new Date();
    this.lastDayOfMonth = dateToDateArray(
      new Date(today.getFullYear(), today.getMonth() + 1, 0)
    );
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
  },
  mounted() {
    this.fetchInvoiceData(this.user.id);
  },
  watch: {
    wallet(val) {
      this.$emit("input", val);
    },
    value: {
      handler(val) {
        this.wallet = val;
      },
      immediate: true,
    },
  },
  methods: {
    balanceTransfer(balance) {
      WalletEntityRepository.balanceTransfer(
        this.sourceId,
        balance.target,
        balance.amount
      )
        .then((data) => {
          this.wallet = data;
          this.$notify({
            title: this.$t("wallet.messages.transfer_dialog.title"),
            text: this.$t("wallet.messages.transfer_dialog.success"),
            type: "success",
          });
        })
        .catch(() =>
          this.$log.debug(
            "Error doing transfer from " +
              this.sourceId +
              "to " +
              balance.target
          )
        );
      this.transferDialog = false;
    },
    chargeWallet(charge) {
      WalletEntityRepository.chargeWallet(this.value.id, charge)
        .then((data) => {
          if (data.status == "CONFIRMED") {
            this.$notify({
              title: this.$t("wallet.messages.charge_dialog.title"),
              text: this.$t("wallet.messages.charge_dialog.success"),
              type: "success",
            });
            this.wallet.balance += data.amount;
          }
        })
        .catch(() =>
          this.$log.debug(
            "Error charging " + charge + " to wallet with ID " + this.value.id
          )
        );
      this.chargeDialog = false;
    },
    updateWallet(walletUpdated) {
      this.wallet = { ...this.wallet, ...walletUpdated };
      this.$notify({
        title: this.$t("wallet.messages.charge_dialog.title"),
        text: this.$t("wallet.messages.charge_dialog.success"),
        type: "success",
      });
      this.chargeDialog = false;
    },
    fetchInvoiceData(id) {
      UserDataEntityRepository.getByUserId(id).then((response) => {
        this.userData = response;

        this.invoiceData =
          !!this.userData &&
          !!this.user.firstName &&
          !!this.user.lastName &&
          !!this.userData.nif &&
          !!this.userData.address &&
          !!this.userData.town &&
          !!this.userData.country &&
          !!this.userData.postalcode;

        if (!this.userData)
          this.addField(this.$t("wallet.messages.invoice_data.userData"));
        else {
          if (!this.userData.nif)
            this.addField(this.$t("wallet.messages.invoice_data.nif"));
          if (!this.userData.town)
            this.addField(this.$t("wallet.messages.invoice_data.town"));
          if (!this.userData.country)
            this.addField(this.$t("wallet.messages.invoice_data.country"));
          if (!this.userData.address)
            this.addField(this.$t("wallet.messages.invoice_data.address"));
          if (!this.userData.postalcode)
            this.addField(this.$t("wallet.messages.invoice_data.postalcode"));
        }
        if (!this.user.firstName)
          this.addField(this.$t("wallet.messages.invoice_data.firstName"));
        if (!this.user.lastName)
          this.addField(this.$t("wallet.messages.invoice_data.lastName"));
      });
    },
    addField(text) {
      if (this.emptyFields == "") this.emptyFields += " " + text;
      else this.emptyFields += ", " + text;
    },
  },
};
</script>
<style scoped>
.payment {
  color: red;
}
</style>
