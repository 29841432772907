import Vue from "vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import i18n from "@/plugins/i18n";
import extensions from "./file-extensions";
import properties from "@/properties";

const FileEntityRepository = RepositoryFactory.get("FileEntityRepository");

function getFileNameFromHeaders(headers) {
  let filename = "";
  let disposition = headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  return filename;
}

function downloadFile(response, downloadInput) {
  const url = window.URL.createObjectURL(response.data);
  const filename = getFileNameFromHeaders(response.headers);
  const downloadLink = downloadInput;
  downloadLink.href = url;
  downloadLink.download = filename;
  downloadLink.click();
  window.URL.revokeObjectURL(url);
}

async function deleteTempFile(entity, name) {
  let file = entity[name];
  if (file && file.temporary) {
    await FileEntityRepository.deleteTempFile(file.uuid)
      .then(() => Vue.set(entity, name, null))
      .catch(() =>
        Vue.notify({
          type: "error",
          text: i18n.t("file.error.delete-error"),
          duration: 30000,
        })
      );
  }
}

function _hasExtension(fileName, exts) {
  if (
    !new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$", "i").test(
      fileName
    )
  ) {
    Vue.notify({
      type: "error",
      text: i18n.t("file.error.extension", {
        extension: fileName.split(".").pop(),
      }),
      duration: 30000,
    });
    return false;
  }
  return true;
}

function uploadDocument(entity, filePropName, refs, htmlElement, fileName) {
  return checkExtensionsAndUpload(
    entity,
    filePropName,
    refs,
    htmlElement,
    fileName,
    extensions.document
  );
}

function uploadPdf(entity, filePropName, refs, htmlElement, fileName) {
  return checkExtensionsAndUpload(
    entity,
    filePropName,
    refs,
    htmlElement,
    fileName,
    extensions.pdf
  );
}

function uploadImage(entity, filePropName, refs, htmlElement, fileName) {
  return checkExtensionsAndUpload(
    entity,
    filePropName,
    refs,
    htmlElement,
    fileName,
    extensions.image
  );
}

function uploadVideo(entity, filePropName, refs, htmlElement, fileName) {
  return checkExtensionsAndUpload(
    entity,
    filePropName,
    refs,
    htmlElement,
    fileName,
    extensions.video
  );
}

function uploadAudio(entity, filePropName, refs, htmlElement, fileName) {
  return checkExtensionsAndUpload(
    entity,
    filePropName,
    refs,
    htmlElement,
    fileName,
    extensions.audio
  );
}

function checkExtensionsAndUpload(
  entity,
  filePropName,
  refs,
  htmlElement,
  fileName,
  exts
) {
  if (_hasExtension(htmlElement.target.value, exts)) {
    return _uploadFile(entity, filePropName, refs, htmlElement, fileName);
  }
}

function uploadPhoto(entity, filePropName, fileName, refs, htmlElement) {
  if (_hasExtension(htmlElement.target.value, extensions.image)) {
    const uploadedFile = htmlElement.target.files[0];
    return _uploadFile(
      entity,
      filePropName,
      refs,
      htmlElement,
      fileName + "_profile_photo"
    ).then(() => {
      // Read the photo file to show it
      const reader = new FileReader();
      reader.onload = (event) => {
        refs.photo = event.target.result;
      };
      reader.readAsDataURL(uploadedFile);
    });
  } else {
    return reject("Invalid file extension", refs);
  }
}

async function _uploadFile(
  entity,
  filePropName,
  refs,
  htmlElement,
  fileName = ""
) {
  refs.loading = true;
  await deleteTempFile(entity, filePropName);
  const uploadedFile = htmlElement.target.files[0];
  htmlElement.target.value = null;
  if (uploadedFile) {
    if (uploadedFile.size > properties.MAXFILESIZE) {
      Vue.notify({
        type: "error",
        text: i18n.t("file.error.max_file_size_exceeded", {
          value: properties.MAXFILESIZE,
        }),
        duration: 30000,
      });
      return reject("Max file exceeded", refs);
    } else if (uploadedFile.size === 0) {
      Vue.notify({
        type: "error",
        text: i18n.t("file.error.empty-file"),
        duration: 30000,
      });
      return reject("Empty file", refs);
    }
    const file = new FormData();
    file.append("fileName", fileName);
    file.append("content", uploadedFile);
    return FileEntityRepository.save(file)
      .then((response) => Vue.set(entity, filePropName, response))
      .catch((err) => {
        // If there is not a defined error, we show a generic one
        if (!err.response.headers["x-app-error"]) {
          Vue.notify({
            type: "error",
            text: i18n.t("file.error.uploading"),
            duration: 30000,
          });
        }
        return reject("Error updating file");
      })
      .finally(() => (refs.loading = false));
  }
}

const reject = function (message, refs) {
  return new Promise((resolve, reject) => {
    if (refs) {
      refs.loading = false;
    }
    reject(message);
  });
};

export {
  getFileNameFromHeaders,
  downloadFile,
  deleteTempFile,
  uploadImage,
  uploadVideo,
  uploadPhoto,
  uploadAudio,
  uploadDocument,
  uploadPdf,
};
