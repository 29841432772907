<template>
  <v-container style="padding-top: 100px">
    <audio-player v-if="audioSrc" :src="audioSrc" />
    <br />
  </v-container>
</template>
<script>
import AudioPlayer from "@/components/audio-player/AudioPlayer";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ClrmAudioPlayer",
  components: {
    AudioPlayer,
  },
  props: {
    activity: {
      type: Object,
    },
  },
  computed: {
    audioSrc() {
      return ActivityEntityRepository.getActivityContentUrl(
        this.activity.activityId
      );
    },
  },
};
</script>
