<template>
  <v-container fluid>
    <a ref="hiddenDownloader" class="d-none" />
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline">
          {{ $t("teacher.tabs.references.titleView") }}</span
        >
      </v-col>
      <v-col cols="12" md="3">
        <v-btn outlined block color="primary" :to="addReferenceRoute()">
          <v-icon class="mr-2"> mdi-certificate </v-icon>
          {{ $t("teacher.tabs.references.titleAdd") }}
        </v-btn>
      </v-col>
      <v-container fluid>
        <v-row row>
          <v-col cols="12">
            <v-data-table
              class="elevation-1s"
              :headers="headers"
              :items="items"
              :loading="loading"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="statusColor[item.status] || 'yellow'">
                  {{ $t(`reference.status.${item.status}`) }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      @click="requestTeacherReference(item)"
                      v-if="isAdmin && item.status === 'PRELIMINARY'"
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingEmailSend[item.id]"
                      ><v-icon color="primary">send</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("actions.send") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn
                        v-if="isAdmin"
                        icon
                        small
                        color="primary"
                        :disabled="item.file === null"
                        :loading="loadingDownloadFile"
                        @click="downloadFile(item.id)"
                      >
                        <v-icon>preview</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>{{ $t("actions.download") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :to="editReferenceRoute(item.id)"
                      v-if="isAdmin || item.status === 'PRELIMINARY'"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="warning">edit</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("actions.edit") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isAdmin || item.status === 'PRELIMINARY'"
                      icon
                      small
                      @click="selectEntityToDelete(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="error">delete</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="deleteReference"
    ></delete-dialog>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { downloadFile } from "@/common/file-utils";

const ReferenceEntityRepository = RepositoryFactory.get(
  "ReferenceEntityRepository"
);

export default {
  name: "ReferencesTab",
  components: { DeleteDialog },
  props: {
    teacherId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      loading: false,
      deleteDialog: false,
      selectedEntity: null,
      loadingDownloadFile: false,
      loadingEmailSend: [],
      statusColor: {
        SENT: "primary",
        PRELIMINARY: "secondary",
        CHECKED: "success",
      },
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    headers() {
      return [
        {
          text: this.$t("reference.prop.fullName"),
          value: "fullName",
        },
        {
          text: this.$t("reference.prop.positionTitle"),
          value: "positionTitle",
        },
        {
          text: this.$t("reference.prop.companyName"),
          value: "companyName",
        },
        {
          text: this.$t("reference.prop.email"),
          value: "email",
        },
        {
          text: this.$t("reference.prop.phone"),
          value: "phone",
        },
        {
          text: this.$t("reference.prop.status"),
          value: "status",
        },
        {
          text: this.$t("reference.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      ReferenceEntityRepository.getTeacherReferences(this.teacherId)
        .then((data) => (this.items = data))
        .catch(() =>
          this.$log.debug(
            "Error fetching teacher references for teacher with ID " +
              this.teacherId
          )
        )
        .finally(() => (this.loading = false));
    },
    downloadFile(id) {
      this.loadingDownloadFile = true;
      return ReferenceEntityRepository.downloadReferenceFile(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("reference.messages.download-file"),
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t("reference.error.file-not-loaded"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.loadingDownloadFile = false));
    },
    editReferenceRoute(id) {
      const route = this.isAdmin
        ? "TeacherReferenceFormEdit"
        : "ProfileReferenceFormEdit";
      return {
        name: route,
        params: { referenceId: id, backAction: true },
        props: { teacherId: this.teacherId },
      };
    },
    addReferenceRoute() {
      const route = this.isAdmin
        ? "TeacherReferenceFormNew"
        : "ProfileReferenceFormNew";
      return {
        name: route,
        params: { backAction: true },
        props: { teacherId: this.teacherId },
      };
    },
    requestTeacherReference(item) {
      this.$set(this.loadingEmailSend, item.id, true);
      ReferenceEntityRepository.requestTeacherReference(item)
        .then((res) => (item.status = res.status))
        .catch(() => this.$log.debug("Error requesting teacher reference"))
        .finally(() => this.$set(this.loadingEmailSend, item.id, false));
    },
    selectEntityToDelete(entity) {
      if (!this.isAdmin && entity.status !== "PRELIMINARY") {
        this.$notify({
          title: this.$t("reference.error.status-invalid"),
          type: "error",
          duration: 30000,
        });
        return;
      }
      this.selectedEntity = entity;
      this.deleteDialog = true;
    },
    deleteReference() {
      this.deleteLoading = true;
      ReferenceEntityRepository.delete(this.selectedEntity.id)
        .then(() => {
          this.items.splice(this.items.indexOf(this.selectedEntity), 1);
          this.$notify({
            title: this.$t("reference.messages.delete-success"),
            type: "success",
          });
        })
        .catch(() =>
          this.$notify({
            title: this.$t("reference.messages.delete-error"),
            type: "error",
            duration: 30000,
          })
        )
        .finally(() => {
          this.selectedEntity = null;
          this.deleteDialog = false;
          this.deleteLoading = false;
        });
    },
  },
};
</script>
