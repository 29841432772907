<template>
  <v-container fluid v-if="lectures">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="center">
          <v-col cols="6" md="3" xl="2">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="teachers"
              v-model="teacherFilter"
              :label="$t('teacher.name')"
              :item-text="(item) => item.name"
              item-value="id"
              :loading="teachersLoading"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="students"
              v-model="studentFilter"
              :label="$t('student.name')"
              :item-text="(item) => item.name"
              item-value="id"
              :loading="studentsLoading"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="products"
              v-model="productFilter"
              :label="$t('product.name')"
              :item-text="(item) => item.title"
              item-value="id"
              :loading="productsLoading"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2">
            <autocomplete
              @change="redirectOnFilterChange"
              :clearable="false"
              :items="lectureStatus"
              v-model="stateFilter"
              :label="$t('lecture.prop.state')"
              :item-text="translate"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="paymentStatus"
              v-model="paymentFilter"
              :label="$t('lecture.prop.settled')"
              :item-text="translate"
              :item-value="(item) => item.value === 'PAID'"
            ></autocomplete>
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: startTimeFilter,
                label: 'lecture.prop.startTime',
              }"
              @update-time="
                updateDateTime('startTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: endTimeFilter,
                label: 'lecture.prop.endTime',
              }"
              @update-time="
                updateDateTime('endTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="lectures"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="tableFooterProps"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.startTime`]="{ item }">
            <span v-if="item.startTime">
              {{ item.startTime | dateTimeWithTz("long") }}
            </span>
          </template>

          <template v-slot:[`item.endTime`]="{ item }">
            <span v-if="item.endTime">
              {{ item.endTime | dateTimeWithTz("long") }}
            </span>
          </template>

          <template v-slot:[`item.duration`]="{ item }">
            <span v-if="item.duration">
              {{ `${item.duration} ${$t("lecture.prop.duration.time")}` }}
            </span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <span v-if="item.state">
              {{ $t(`lecture.states.${item.state}`) }}
            </span>
          </template>

          <template v-slot:[`item.paid`]="{ item }">
            <span>
              {{ settledValue(item) }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top open-delay="150">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!item.paid"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="selectEntity(item)"
                >
                  mdi-credit-card
                </v-icon>
              </template>
              <span>{{ $t("lecture.actions.mark-as-settled") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal-dialog
      :content="$t('teacherPayment.modal.content')"
      :dialog="dialog"
      submitClass="primary"
      :submitText="$t('actions.send')"
      :title="$t('teacherPayment.modal.title')"
      titleClass="primary white--text"
      titleIcon="mdi-credit-card"
      @submit="createPayment"
      @cancel="dialog = false"
    ></modal-dialog>
  </v-container>
</template>

<script>
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  dateArrayToDateString,
  dateToMinLocalDateOrMaxLocalDate,
} from "@/common/conversion-utils";
import { translate } from "@/common/translation-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ModalDialog from "@/components/modal_dialog/ModalDialog.vue";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import paymentStatus from "@/enumerates/LecturePayment";
import lectureStatus from "@/enumerates/LectureStatus";
import { generateSort } from "@/common/pagination-utils";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const TeacherPaymentEntityRepository = RepositoryFactory.get(
  "TeacherPaymentEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

export default {
  name: "LecturePaymentList",
  components: {
    Autocomplete,
    DateAndHourPicker,
    ModalDialog,
  },
  data() {
    return {
      lectures: [],
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
      },
      totalItems: 0,
      loading: false,
      showFilters: false,
      teacherFilter: null,
      teachers: [],
      teachersLoading: false,
      studentFilter: null,
      students: [],
      studentsLoading: false,
      productFilter: null,
      products: [],
      productsLoading: false,
      startTimeFilter: null,
      endTimeFilter: null,
      lectureStatus,
      stateFilter: "DONE",
      paymentStatus,
      paymentFilter: null,
      dialog: false,
      selectedLecture: null,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lecture.prop.product"),
          sortable: false,
          value: "productName",
        },
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.duration.name"),
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("lecture.prop.state"),
          sortable: false,
          value: "state",
        },
        {
          text: this.$t("lecture.prop.settled"),
          sortable: false,
          value: "paid",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    if (this.$route.query.teacherFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.teacherFilter);
      this.teacherFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.studentFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.studentFilter);
      this.studentFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.productFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.productFilter);
      this.productFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.startTimeFilter) {
      this.showFilters = true;
      this.startTimeFilter = this.$route.query.startTimeFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.endTimeFilter) {
      this.showFilters = true;
      this.endTimeFilter = this.$route.query.endTimeFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.stateFilter) {
      this.showFilters = true;
      this.stateFilter = this.$route.query.stateFilter;
    }

    if (this.$route.query.paymentFilter) {
      this.showFilters = true;
      this.paymentFilter = this.$route.query.paymentFilter === "true";
    }
    this.getTeachers();
    this.getStudents();
    this.getProducts();
  },
  methods: {
    getLectures() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage),
          teacherId: this.teacherFilter,
          studentId: this.studentFilter,
          productId: this.productFilter,
          startTime:
            this.startTimeFilter &&
            dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.startTimeFilter),
              false
            ),
          endTime:
            this.endTimeFilter &&
            dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.endTimeFilter),
              true
            ),
          state: this.stateFilter || "DONE",
          paid: this.paymentFilter?.toString(),
        },
      };
      LectureEntityRepository.getAllWithTeacherPayments(options)
        .then((response) => {
          this.lectures = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching lectures"))
        .finally(() => (this.loading = false));
    },
    getTeachers() {
      this.teachersLoading = true;
      TeacherEntityRepository.getAllTeacherWithLectures()
        .then((res) => (this.teachers = res))
        .catch(() => this.$log.debug("Error fetching teachers for selector"))
        .finally(() => (this.teachersLoading = false));
    },
    getStudents() {
      this.studentsLoading = true;
      StudentEntityRepository.findAllStudentWithLectures()
        .then((res) => (this.students = res))
        .catch(() => this.$log.debug("Error fetching students for selector"))
        .finally(() => (this.studentsLoading = false));
    },
    getProducts() {
      this.productsLoading = true;
      ProductEntityRepository.getAllProductsWithLectures()
        .then((res) => (this.products = res))
        .catch(() => this.$log.debug("Error fetching products for selector"))
        .finally(() => (this.productsLoading = false));
    },
    createPayment() {
      const payment = {
        lectureId: this.selectedLecture.id,
        teacherId: this.selectedLecture.teacherId,
      };
      TeacherPaymentEntityRepository.save(payment)
        .then(() => {
          this.$notify({
            title: this.$t("teacherPayment.notifications.successfull_payment"),
            type: "success",
          });
          this.selectedLecture.paid = true;
        })
        .catch(() =>
          this.$log.debug(
            "Error creating payment with lectureId: " + payment.lectureId
          )
        )
        .finally(() => {
          this.selectedLecture = null;
          this.dialog = false;
        });
    },
    selectEntity(entity) {
      this.dialog = true;
      this.selectedLecture = entity;
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "Lecture Payment List",
          query: query,
        });
      }
      this.getLectures();
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    changeQueryFilters(query) {
      query.teacherFilter =
        this.teacherFilter != null ? this.teacherFilter : undefined;

      query.studentFilter =
        this.studentFilter != null ? this.studentFilter : undefined;

      query.productFilter =
        this.productFilter != null ? this.productFilter : undefined;

      query.startTimeFilter = this.startTimeFilter
        ? dateArrayToDateString(this.startTimeFilter)
        : undefined;

      query.endTimeFilter = this.endTimeFilter
        ? dateArrayToDateString(this.endTimeFilter)
        : undefined;

      query.stateFilter =
        this.stateFilter != null
          ? this.stateFilter
          : this.$t(`lecture.states.DONE`);

      query.paymentFilter =
        this.paymentFilter != null ? this.paymentFilter : undefined;
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    settledValue(item) {
      let value =
        item.paid === true
          ? this.$t(`lecture.payment-status.SETTLED`)
          : this.$t(`lecture.payment-status.NOT_SETTLED`);
      return value;
    },
    translate,
  },
};
</script>
