<template>
  <v-container fluid>
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="lectureEvaluations"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateTimeWithTz("medium") }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  color="success"
                  @click.stop="showDialog('approveDialog', item)"
                >
                  <v-icon> thumb_up </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.approve") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  color="error"
                  @click.stop="showDialog('rejectDialog', item)"
                >
                  <v-icon> thumb_down </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.reject") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal-dialog
      :dialog="approveDialog"
      @cancel="approveDialog = false"
      @submit="updateState('PUBLIC')"
      :title="$t('lectureEvaluation.headers.approveDialog')"
      titleClass="success white--text"
      titleIcon="warning"
      submitClass="success"
      :submitText="$t('actions.approve')"
      :cancelText="$t('actions.cancel')"
      :content="$t('lectureEvaluation.messages.approve')"
      :retain-focus="false"
    ></modal-dialog>

    <modal-dialog
      :dialog="rejectDialog"
      @cancel="rejectDialog = false"
      @submit="updateState('REJECTED')"
      :title="$t('lectureEvaluation.headers.rejectDialog')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('actions.reject')"
      :cancelText="$t('actions.cancel')"
      :content="$t('lectureEvaluation.messages.reject')"
      :retain-focus="false"
    ></modal-dialog>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ModalDialog from "@/components/modal_dialog/ModalDialog.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const LectureEvaluationEntityRepository = RepositoryFactory.get(
  "LectureEvaluationEntityRepository"
);

export default {
  components: {
    ModalDialog,
  },
  data() {
    return {
      lectureEvaluations: [],
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      approveDialog: false,
      rejectDialog: false,
      selected: null,
      tableFooterProps,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("lectureEvaluation.prop.teacher"),
          value: "teacherName",
        },
        {
          text: this.$t("lectureEvaluation.prop.student"),
          value: "studentName",
        },
        {
          text: this.$t("lectureEvaluation.prop.date"),
          value: "date",
        },
        {
          text: this.$t("lectureEvaluation.prop.comments"),
          value: "comments",
        },
        {
          text: this.$t("lectureEvaluation.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        teacherName: "attendance.lecture.teacher.userData.user.firstName",
        studentName: "attendance.student.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      LectureEvaluationEntityRepository.getAllPending(options)
        .then((response) => {
          this.lectureEvaluations = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching lecture evaluations"))
        .finally((this.loading = false));
    },
    updateState(state) {
      // Make a local copy
      let itemCpy = JSON.parse(JSON.stringify(this.selected));
      itemCpy.state = state;
      // Try to persist data
      LectureEvaluationEntityRepository.updateState(itemCpy)
        .then(() => {
          this.getItems();
          this.$notify({
            title: this.$t("lectureEvaluation.messages.update_success"),
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t("lectureEvaluation.messages.update_error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => {
          this.closeDialog("approveDialog");
          this.closeDialog("rejectDialog");
        });
    },
    showDialog(dialog, item) {
      this[dialog] = true;
      this.selected = item;
    },
    closeDialog(dialog) {
      this[dialog] = false;
      this.selected = null;
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
  },
};
</script>
