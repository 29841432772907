<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
    </v-row>
    <v-row>
      <!-- Teacher information -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="8" class="text-center">
            <span class="text-h6">{{
              $t("teacher.dashboard.your_information")
            }}</span>
            <v-row>
              <!-- Teacher next lectures -->
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  text
                  @click="expandNextLectures = !expandNextLectures"
                  :disabled="nextLectures.length < 1"
                >
                  {{ $t("teacher.dashboard.next_lectures") }}
                  <v-icon>
                    {{ expandNextLectures ? "expand_less" : "expand_more" }}
                  </v-icon>
                </v-btn>
                <v-row v-if="loading" class="mt-8" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <transition name="slide-fade">
                  <v-container v-if="expandNextLectures && !loading" fluid>
                    <v-row
                      v-for="lecture in nextLectures"
                      :key="lecture.id"
                      class="mt-2"
                      justify="center"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :elevation="hover ? 12 : 2"
                          :class="lecture.state === 'DOING' ? 'doing' : ''"
                          width="300px"
                          @click="navigate('Lecture Detail', lecture.id)"
                        >
                          <v-card-title class="py-0">
                            <v-col class="text-center">
                              <span class="no-split-words">
                                {{ lecture.productName }}
                              </span>
                            </v-col>
                          </v-card-title>
                          <v-card-text>
                            <span class="text-overline">
                              {{ lecture.startTime | dateTimeWithTz("medium") }}
                              -
                              {{ lecture.endTime | dateTimeWithTz("time") }}
                            </span>
                            <v-container
                              v-if="userCanJoin(lecture)"
                              class="py-0"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    @click.stop="joinToLecture(lecture)"
                                  >
                                    <v-icon color="success">login</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("lecture.join") }}</span>
                              </v-tooltip>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-row>
                    <v-row
                      v-if="!nextLectures || nextLectures.length === 0"
                      class="mt-2"
                      justify="center"
                    >
                      <span>
                        {{ $t("teacher.dashboard.no_next_lectures") }}
                      </span>
                    </v-row>
                  </v-container>
                </transition>
              </v-col>
              <!-- Teacher next level tests -->
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  text
                  @click="expandNextTests = !expandNextTests"
                  :disabled="nextLevelTest.length < 1"
                >
                  {{ $t("teacher.dashboard.next_level_test") }}
                  <v-icon>
                    {{ expandNextTests ? "expand_less" : "expand_more" }}
                  </v-icon>
                </v-btn>
                <v-row v-if="loading" class="mt-8" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <transition name="slide-fade">
                  <v-container v-if="expandNextTests && !loading" fluid>
                    <v-row
                      v-for="lecture in nextLevelTest"
                      :key="lecture.id"
                      class="mt-2"
                      justify="center"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :class="lecture.state === 'DOING' ? 'doing' : ''"
                          :elevation="hover ? 12 : 2"
                          width="300px"
                          @click="navigate('Lecture Detail', lecture.id)"
                        >
                          <v-card-title class="py-0">
                            <v-col class="text-center">
                              <span class="no-split-words">
                                {{
                                  lecture.language
                                    ? $t("teacher.dashboard.level_test", {
                                        lang: $t(
                                          `languages.${lecture.language}`
                                        ),
                                      })
                                    : "-"
                                }}
                              </span>
                            </v-col>
                          </v-card-title>
                          <v-card-text>
                            <span class="text-overline">
                              {{ lecture.startTime | dateTimeWithTz("medium") }}
                              -
                              {{ lecture.endTime | dateTimeWithTz("time") }}
                            </span>
                            <v-container
                              v-if="userCanJoin(lecture)"
                              class="py-0"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    @click.stop="joinToLecture(lecture)"
                                  >
                                    <v-icon color="success">login</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("lecture.join") }}</span>
                              </v-tooltip>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-row>
                    <v-row
                      v-if="!nextLevelTest || nextLevelTest.length === 0"
                      class="mt-2"
                      justify="center"
                    >
                      <span>
                        {{ $t("teacher.dashboard.no_next_level_test") }}</span
                      >
                    </v-row>
                  </v-container>
                </transition>
              </v-col>
            </v-row>
          </v-col>
          <!-- Supervised teachers next lectures -->
          <v-col cols="12" md="4" class="text-center">
            <span class="text-h6">
              {{ $t("teacher.dashboard.supervised_information") }}
            </span>
            <v-row justify="center">
              <!-- Teacher next lectures -->
              <v-col cols="12" class="text-center">
                <v-btn
                  text
                  @click="
                    expandTeachersNextLectures = !expandTeachersNextLectures
                  "
                  :disabled="teachersNextLectures.length < 1"
                >
                  {{ $t("teacher.dashboard.next_lectures") }}
                  <v-icon>
                    {{
                      expandTeachersNextLectures ? "expand_less" : "expand_more"
                    }}
                  </v-icon>
                </v-btn>
                <v-row v-if="loading" class="mt-8" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <transition name="slide-fade">
                  <v-container
                    v-if="expandTeachersNextLectures && !loading"
                    fluid
                  >
                    <v-row
                      v-for="lecture in teachersNextLectures"
                      :key="lecture.id"
                      class="mt-2"
                      justify="center"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :elevation="hover ? 12 : 2"
                          :class="lecture.state === 'DOING' ? 'doing' : ''"
                          width="300px"
                          @click="navigate('Lecture Detail', lecture.id)"
                        >
                          <v-card-title class="py-0">
                            <v-row no-gutters>
                              <v-col class="text-center" cols="12">
                                <span class="no-split-words">
                                  {{ getTeacherName(lecture) }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text class="py-0">
                            <v-col class="text-center text-subtitle-1">
                              <span class="no-split-words">
                                {{ lecture.productName }}
                              </span>
                            </v-col>
                            <span class="text-overline">
                              {{ lecture.startTime | dateTimeWithTz("medium") }}
                              -
                              {{ lecture.endTime | dateTimeWithTz("time") }}
                            </span>
                          </v-card-text>
                          <v-card-actions>
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      :to="{
                                        name: 'Supervised Teacher Lecture List',
                                        params: {
                                          teacherId: lecture.teacherId,
                                        },
                                        query: { closeLectures: 'true' },
                                      }"
                                      icon
                                      small
                                    >
                                      <v-icon color="primary">list</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("teacher.dashboard.supervised_lectures")
                                  }}</span>
                                </v-tooltip>
                                <span v-if="userCanJoin(lecture)">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        small
                                        @click.stop="joinToLecture(lecture)"
                                      >
                                        <v-icon color="success">login</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t("lecture.join") }}</span>
                                  </v-tooltip>
                                </span>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-hover>
                    </v-row>
                    <v-row
                      v-if="
                        !teachersNextLectures ||
                        teachersNextLectures.length === 0
                      "
                      class="mt-2"
                      justify="center"
                    >
                      <span>
                        {{
                          $t(
                            "teacher.dashboard.no_next_lectures_supervised_teachers"
                          )
                        }}</span
                      >
                    </v-row>
                  </v-container>
                </transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { joinToLecture, userCanJoin } from "@/common/lecture-common";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");

export default {
  name: "HeadmasterDashboard",
  data() {
    return {
      nextLectures: [],
      nextLevelTest: [],
      teachersNextLectures: [],
      expandNextLectures: true,
      expandNextTests: true,
      expandTeachersNextLectures: true,
      loading: false,
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      return DashboardRepository.getForHeadmaster()
        .then((data) => {
          this.nextLectures = data.teacherData.nextLectures;
          this.nextLevelTest = data.teacherData.nextLevelTest;
          this.teachersNextLectures = data.teachersNextLectures;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    navigate(routeName, entityId) {
      this.$router.push({
        name: routeName,
        params: { id: entityId, backPrevious: true },
      });
    },
    getTeacherName(lecture) {
      return lecture.teacherName + " " + lecture.teacherSurname;
    },
    userCanJoin,
    joinToLecture,
  },
};
</script>
<style scoped lang="scss">
.doing {
  border: 2px solid #00a451;
}
</style>
