<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <autocomplete
          dense
          no-filter
          solo
          :debouncing="300"
          @change="redirectOnFilterChange"
          :items="teachers.items"
          :loading="teachers.loading"
          v-model="teacherFilter"
          :label="$t('studentEvaluation.prop.teacher')"
          item-text="name"
          item-value="id"
        >
        </autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <autocomplete
          dense
          no-filter
          solo
          :debouncing="300"
          @change="redirectOnFilterChange"
          :items="products.items"
          :loading="products.loading"
          v-model="productFilter"
          :label="$t('studentEvaluation.prop.product')"
          item-text="title"
          item-value="id"
        >
        </autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="studentEvaluations"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.productTitle`]="{ item }">
            {{ item.productTitle || $t("lecture.levelTest") }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateTimeWithTz("medium") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const StudentEvaluationEntityRepository = RepositoryFactory.get(
  "StudentEvaluationEntityRepository"
);

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  components: { Autocomplete },
  name: "FeedbackTab",
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      studentEvaluations: [],
      teacherFilter: this.$route.query.teacher,
      productFilter: this.$route.query.product,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      teachers: {
        items: [],
        loading: false,
      },
      products: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("studentEvaluation.prop.product"),
          value: "productTitle",
        },
        {
          text: this.$t("studentEvaluation.prop.teacher"),
          value: "teacherName",
        },
        {
          text: this.$t("studentEvaluation.prop.comments"),
          value: "comments",
        },
        {
          text: this.$t("studentEvaluation.prop.date"),
          value: "date",
        },
      ];
    },
  },
  created() {
    this.getItems();
    this.getSelectorsItems(
      this.teachers,
      TeacherEntityRepository.getAllOfStudent
    );
    this.getSelectorsItems(
      this.products,
      ProductEntityRepository.getAllOfStudent
    );
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        productTitle: "attendance.lecture.edition.product.title",
        teacherName: "attendance.lecture.teacher.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          teacher: this.teacherFilter,
          product: this.productFilter,
        },
      };
      StudentEvaluationEntityRepository.getAllByStudentId(
        this.studentId,
        options
      )
        .then((response) => {
          this.studentEvaluations = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    async getSelectorsItems(entity, repositoryMethod) {
      entity["loading"] = true;
      entity["items"] = await repositoryMethod({
        params: {
          studentId: this.studentId,
        },
      });
      entity["loading"] = false;
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.teacher =
        this.teacherFilter != null ? this.teacherFilter : undefined;
      query.product =
        this.productFilter != null ? this.productFilter : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
  },
};
</script>
