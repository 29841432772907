<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4" class="text-center">
        <span class="headline">
          {{ editionTitle }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <span v-if="startDate" class="headline">
          {{ $t("edition.from") }}
          {{ startDate | dateTimeWithTz("short") }}
        </span>
        <span v-if="endDate" class="headline">
          {{ $t("edition.to") }}
          {{ endDate | dateTimeWithTz("short") }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <v-chip :color="!isFinished ? 'green' : 'red'" class="white--text">
          {{
            !isFinished
              ? $t("edition.status.NOT_FINISHED")
              : $t("edition.status.FINISHED")
          }}
        </v-chip>
      </v-col>
    </v-row>
    <v-col class="text-right">
      <add-student-dialog
        v-if="addButton"
        :edition-id="parseInt($route.params.editionId)"
        :max-students="maxStudents - numberOfStudentsActive"
        @added="$emit('update:students')"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            class="mt-2"
            :disabled="numberOfStudentsActive >= maxStudents"
          >
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.add") }} </span>
          </v-btn>
        </template>
      </add-student-dialog>
      <span v-if="finishButton">
        <span v-if="endDate">
          {{ $t("edition.messages.finishes_at") }}:
          {{ endDate | dateTimeWithoutTz }}
        </span>
        <close-edition-dialog v-model="closeEditionDialog" @save="closeEdition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-2 ml-2 error"
              v-bind="attrs"
              v-on="on"
              :disabled="isFinished"
              :loading="loadingClose"
            >
              <v-icon>close</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.close") }} </span>
            </v-btn>
          </template>
        </close-edition-dialog>
      </span>
      <v-btn class="ml-2 mt-2" @click="back">
        <v-icon>arrow_back</v-icon>
        <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
      </v-btn>
    </v-col>

    <v-divider class="mt-2 mb-2" light></v-divider>

    <!-- Students row -->
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline"> {{ $t("student.headers.list") }}</span>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :class="getStudentsTableClass()"
          :headers="studentHeaders"
          :items="students.items"
          :loading="students.loading"
          disable-pagination
          hide-default-footer
          @click:row="studentDetail"
        >
          <template v-slot:[`item.registrationState`]="{ item }">
            <v-chip
              :color="item.registrationState === 'ACTIVE' ? 'green' : ''"
              :class="item.registrationState === 'ACTIVE' ? 'white--text' : ''"
            >
              {{
                $t(`registration.status.${item.registrationState}`, {
                  date: $d(
                    dateArrayToDate(item.registrationWithdrawalConfDate),
                    "short"
                  ),
                })
              }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-col v-if="!hideHeader" class="d-md-block">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="showDialog(item, 'unregisterDialog')"
                    small
                    :class="
                      item.registrationState === 'WITHDRAW_REQUESTED'
                        ? 'warning'
                        : 'error'
                    "
                    :disabled="item.registrationState === 'INACTIVE'"
                  >
                    <v-icon> person_remove </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    item.registrationState === "WITHDRAW_REQUESTED"
                      ? $t("edition.unregister.tooltip.change_date")
                      : $t("edition.unregister.tooltip.unregister")
                  }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col v-else class="d-md-block">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="showDialog(item, 'dialog')"
                    color="primary"
                    icon
                  >
                    <v-icon
                      :color="item.finalEvaluationId ? 'warning' : 'primary'"
                      >post_add</v-icon
                    >
                  </v-btn>
                </template>
                <span v-if="!item.finalEvaluationId">
                  {{
                    $t("studentFinalEvaluation.messages.make_final_evaluation")
                  }}
                </span>
                <span v-else>
                  {{
                    $t(
                      "studentFinalEvaluation.messages.modify_final_evaluation"
                    )
                  }}
                </span>
              </v-tooltip>
            </v-col>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-2" light></v-divider>

    <!-- Lectures row -->
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline"> {{ $t("lecture.headers.list") }}</span>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="lectureHeaders"
          :items="lectures.items"
          :loading="lectures.loading"
          :options.sync="lecturePagination"
          :server-items-length="lectures.totalItems"
          @click:row="lectureDetail"
        >
          <template v-slot:[`item.startTime`]="{ item }">
            <span v-if="item.startTime">
              {{ item.startTime | dateTimeWithTz("long") }}
            </span>
          </template>
          <template v-slot:[`item.endTime`]="{ item }">
            <span v-if="item.endTime">
              {{ item.endTime | dateTimeWithTz("long") }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Evaluations row -->
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline">
          {{ $t("lectureEvaluation.headers.list") }}
        </span>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="evaluationHeaders"
          :items="evaluations.items"
          :loading="evaluations.loading"
          :options.sync="evaluationPagination"
          :server-items-length="evaluations.totalItems"
          @click:row="lectureDetailFromEvaluation"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateTimeWithTz("medium") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="80%"
      @click:outside="closeEvaluationDialog"
    >
      <student-final-evaluation-form
        :editionId="editionId"
        :student-id="selectedStudent.id"
        :evaluation-id="selectedStudent.finalEvaluationId"
        @save="closeEvaluationDialog"
        @cancel="closeEvaluationDialog"
      ></student-final-evaluation-form>
    </v-dialog>
    <v-dialog
      v-if="unregisterDialog"
      v-model="unregisterDialog"
      @click:outside="closeUnregisterDialog"
    >
      <edition-unregister-dialog
        :student="selectedStudent"
        :editionId="editionId"
        @requested="submitUnregisterDialog"
        @cancel="closeUnregisterDialog"
      >
      </edition-unregister-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import StudentFinalEvaluationForm from "@/mockups/teachers/components/StudentFinalEvaluationForm";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import AddStudentDialog from "./AddStudentDialog";
import EditionUnregisterDialog from "@/mockups/components/EditionUnregisterDialog.vue";
import CloseEditionDialog from "./CloseEditionDialog";
import { dateArrayToDate } from "@/common/conversion-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);

export default {
  components: {
    StudentFinalEvaluationForm,
    AddStudentDialog,
    EditionUnregisterDialog,
    CloseEditionDialog,
  },
  props: {
    addButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    lectures: {
      type: Object,
      required: false,
      default: () => {
        return {
          loading: true,
          items: [],
          totalItems: 0,
        };
      },
    },
    students: {
      type: Object,
      required: false,
      default: () => {
        return {
          loading: true,
          items: [],
        };
      },
    },
    editionTitle: {
      type: String,
      required: false,
    },
    maxStudents: {
      type: Number,
      required: false,
      default: 1000,
    },
    evaluations: {
      type: Object,
      required: false,
      default: () => {
        return {
          loading: true,
          items: [],
          totalItems: 0,
        };
      },
    },
    startDate: {
      type: Array,
      required: false,
      default: null,
    },
    endDate: {
      type: Array,
      required: false,
      default: null,
    },
    finishButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      lecturePagination: defaultPaginationSettings,
      evaluationPagination: defaultPaginationSettings,
      closeEditionDialog: false,
      loadingClose: false,
      // showAdminButtons: hide columns when the user is an admin
      showAdminButtons: null,
      dialog: false,
      unregisterDialog: false,
      selectedStudent: null,
      editionId: this.$route.params.editionId,
      tableFooterProps,
    };
  },
  computed: {
    isFinished() {
      if (this.endDate == null) {
        return false;
      }
      const endDate = dateArrayToDate(this.endDate);
      const now = new Date();
      if (endDate.getFullYear() === now.getFullYear()) {
        if (endDate.getMonth() === now.getMonth()) {
          return endDate.getDate() <= now.getDate();
        } else {
          return endDate.getMonth() < now.getMonth();
        }
      } else {
        return endDate.getFullYear() < now.getFullYear();
      }
    },
    numberOfStudentsActive() {
      return this.students.items.filter(
        (student) => student.registrationState !== "INACTIVE"
      ).length;
    },
    studentHeaders() {
      return [
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "email",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "phone",
        },
        {
          text: this.$t("registration.prop.status"),
          value: "registrationState",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ];
    },
    lectureHeaders() {
      return [
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.state"),
          value: "state",
        },
      ];
    },
    evaluationHeaders() {
      return [
        {
          text: this.$t("lectureEvaluation.prop.student"),
          value: "studentName",
        },
        {
          text: this.$t("lectureEvaluation.prop.teacher"),
          value: "teacherName",
          align: this.showAdminButtons,
        },
        {
          text: this.$t("lectureEvaluation.prop.comments"),
          value: "comments",
        },
        {
          text: this.$t("lectureEvaluation.prop.date"),
          value: "date",
        },
        {
          text: this.$t("lectureEvaluation.prop.state"),
          value: "state",
          align: this.showAdminButtons,
        },
      ];
    },
  },
  watch: {
    lecturePagination: {
      handler() {
        this.$emit("change-lecture-pagination", this.lecturePagination);
      },
      deep: true,
    },
    evaluationPagination: {
      handler() {
        this.$emit("change-evaluation-pagination", this.evaluationPagination);
      },
      deep: true,
    },
    hideHeader: {
      handler() {
        this.showAdminButtons = this.hideHeader ? " d-none" : "";
      },
      immediate: true,
    },
  },
  methods: {
    submitUnregisterDialog(res) {
      this.selectedStudent.registrationState = res.data.status;
      this.selectedStudent.registrationWithdrawalConfDate =
        res.data.withdrawConfDate;
      this.closeUnregisterDialog();
    },
    closeEdition(date) {
      this.loadingClose = true;
      EditionEntityRepository.closeEdition(this.$route.params.editionId, date)
        .then(() => {
          this.$emit("close-edition", date);
          this.closeEditionDialog = false;
        })
        .finally(() => (this.loadingClose = false));
    },
    lectureDetailFromEvaluation(lectureEvaluation) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lectureEvaluation.lectureId,
            backPrevious: true,
          },
        });
      }
    },
    lectureDetail(lecture) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lecture.id,
            backPrevious: true,
          },
        });
      }
    },
    studentDetail(entity) {
      if (!this.hideHeader) {
        const selection = window.getSelection().toString();
        if (selection.length === 0) {
          this.$router.push({
            name: "StudentView Detail",
            params: { id: entity.id, backPrevious: true },
          });
        }
      }
    },
    getStudentsTableClass() {
      return !this.hideHeader ? "rows-clickable" : "";
    },
    showDialog(item, dialogName) {
      this.selectedStudent = item;
      this[dialogName] = true;
    },
    closeEvaluationDialog(finalEvaluationSaved) {
      if (finalEvaluationSaved) {
        this.selectedStudent.finalEvaluationId = finalEvaluationSaved.id;
      }
      this.dialog = false;
      this.selectedStudent = null;
    },
    closeUnregisterDialog() {
      this.unregisterDialog = false;
      this.selectedStudent = null;
    },
    back() {
      this.$emit("back");
    },
    dateArrayToDate,
  },
};
</script>
