<template>
  <v-toolbar-items>
    <span v-if="false">
      <v-btn
        text
        v-for="singleItem in singleItems"
        :key="singleItem.title"
        :to="{
          name: singleItem.link,
          params: singleItem.params,
          query: singleItem.query,
        }"
      >
        {{ $t(singleItem.title) }}
      </v-btn>
      <v-menu
        offset-y
        transition="slide-y-transition"
        class="dropdown-content"
        v-for="menuItem in menuItems"
        :key="menuItem.title"
      >
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="height: 100%" tile>
            {{ $t(menuItem.title) }}
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="item in menuItem.items">
            <v-list-item
              :key="item.title"
              :to="{ name: item.link, params: item.params, query: item.query }"
            >
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="item.divider" :key="item.title + '_divider'" />
          </template>
        </v-list>
      </v-menu>
    </span>
    <v-spacer></v-spacer>
    <span>
      <v-btn
        color="accent"
        v-if="user.id && !isGuest"
        icon
        @click="$emit('open-chat')"
        tile
      >
        <v-badge
          v-if="user.id && !isGuest"
          :content="notifications"
          :value="notifications > 0"
          color="red"
          overlap
        >
          <icon name="chat-simple" width="28" height="28" title="chat"></icon>
        </v-badge>
      </v-btn>
    </span>
    <span class="profile-menu">
      <!-- My Profile Information -->
      <v-menu offset-y transition="slide-y-transition" v-if="isLogged">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :class="{ 'button-on-collapse': collapsed }" icon>
            <v-avatar size="28">
              <v-img :src="photo" :lazy-src="defaultProfilePhoto"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="teal">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img :src="photo" :lazy-src="defaultProfilePhoto"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.displayName || user.firstName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.login }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ loggedAs }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="user.authorities.length > 1" />

          <v-list-group
            class="teal lighten-4"
            v-if="user.authorities.length > 1"
            no-action
            @click.stop.prevent
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ $t("user_management.authorities") }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="authority in authorities"
              :class="
                user.selectedAuthority === authority
                  ? 'selected-authority'
                  : 'no-selected-authority'
              "
              :key="authority"
              @click="changeAuthority(authority)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("user_management.roles." + authority) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-divider v-if="profileItems.length > 0" />

          <v-list class="teal lighten-4">
            <v-list-item
              v-for="profileItem in profileItems"
              :key="profileItem.title"
              :to="{
                name: profileItem.link,
                params: profileItem.params,
                query: profileItem.query,
              }"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(profileItem.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item @click="logout">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("menuBar.logout") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list>
      </v-menu>
    </span>
  </v-toolbar-items>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import usersHome from "@/enumerates/UsersHome";
import {
  getChat,
  mapNotificationsGetter,
} from "@/common/mapNotificationsGetter";

const publicItems = require("./menu-items/PUBLIC_BarItems.json");

export default {
  name: "MenuBarItems",
  data() {
    return {
      defaultProfilePhoto: require("@/assets/icon_user-accent.png"),
      user: auth.getUser(),
      chat: getChat(),
    };
  },
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapAuthGetter(["isLogged", "isGuest"]),
    ...mapNotificationsGetter(["notifications"]),
    loggedAs() {
      return this.isLogged
        ? this.$t(`menuBar.roles.${this.selectedAuthority}`)
        : "";
    },
    roleItems() {
      let items = [];
      if (!this.user.id || !this.selectedAuthority) {
        return JSON.parse(JSON.stringify(publicItems));
      }
      let authItems = [];
      try {
        authItems = require("./menu-items/" +
          this.selectedAuthority +
          "_BarItems.json");
      } catch (e) {
        this.$log.debug(
          "Error downloading menuItems for role " + this.selectedAuthority
        );
        return JSON.parse(JSON.stringify(publicItems));
      }
      items = items.concat(authItems);
      return JSON.parse(JSON.stringify(items)).filter(
        (i) => i != null && (!i.mustBeAdult || this.isAdult)
      );
    },
    singleItems() {
      return this.roleItems.filter(
        (i) => i.link && (!i.mustBeAdult || this.isAdult)
      );
    },
    menuItems() {
      const auxItems = this.roleItems.filter(
        (menuItem) =>
          menuItem.title !== "menuBar.profile" &&
          !menuItem.link &&
          (!menuItem.mustBeAdult || this.isAdult)
      );
      return this.isAdult
        ? auxItems
        : auxItems.map((i) => {
            i.items = i.items.filter((i) => !i.mustBeAdult || this.isAdult);
            return i;
          });
    },
    profileItems() {
      return this.roleItems.length > 0 &&
        this.roleItems.find((i) => i.title === "menuBar.profile")
        ? this.roleItems
            .find((i) => i.title === "menuBar.profile")
            .items.filter((i) => !i.mustBeAdult || this.isAdult)
        : [];
    },
    selectedAuthority: {
      get: function () {
        return this.user ? auth.getSelectedAuthority() : null;
      },
      set: function (value) {
        auth.setSelectedAuthority(value);
      },
    },
    authorities() {
      return this.user ? this.user.authorities : [];
    },
    photo() {
      return this.user && this.user.photo
        ? this.user.photo
        : this.defaultProfilePhoto;
    },
    isAdult() {
      return this.user ? this.user.isAdult : false;
    },
  },
  methods: {
    changeAuthority(authority) {
      if (authority != this.selectedAuthority) {
        this.selectedAuthority = authority;
        this.$router.push({ name: usersHome[this.selectedAuthority] });
      }
    },
    logout() {
      auth.logout().then(
        this.$router.push({ name: "Login" }).catch((err) => {
          //Do nothing if navigating to current route, otherwise throw error
          if (!err.name === "NavigationDuplicated") {
            throw err;
          }
        })
      );
    },
  },
};
</script>
<style scoped>
.row {
  height: 100%;
}

.button-element {
  height: 100% !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 100%;
}

.v-btn.active .v-icon {
  transform: rotate(-180deg);
}

.dropdown-content {
  max-height: 70vh;
  overflow-y: auto;
}

.v-toolbar__items {
  padding-left: 16px;
}

.selected-authority {
  background-color: rgba(66, 139, 202, 0.2);
}

.no-selected-authority {
  cursor: pointer;
  background-color: unset;
}

.profile-menu {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
}
</style>
