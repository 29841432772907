<template>
  <v-card v-if="!loading">
    <v-card-title class="grey lighten-1 white--text">
      <h2>
        {{ $t("activity.headers.form") }}
      </h2>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-alert
                outlined
                :color="showSelectedError ? 'red darken-2' : 'grey darken-2'"
              >
                <v-card-title>
                  <h4>{{ $t("activity.messages.selected") }}</h4>
                </v-card-title>
                <v-card-text
                  v-if="activitiesSelected && activitiesSelected.length > 0"
                >
                  <activities-selected-list
                    :activitiesSelected="activitiesSelected"
                    :removeActivity="removeActivity"
                  />
                </v-card-text>
                <v-card-text v-else>
                  {{ $t("activity.messages.no_selected") }}
                </v-card-text>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="secondary my-4"></v-divider>

      <activity-simple-list
        :language="lesson.level.language.id"
        :level="lesson.level.id"
        :presentActivities="presentActivities"
        @selected="selectActivity"
      ></activity-simple-list>
    </v-card-text>
    <v-card-actions>
      <v-row justify="space-around">
        <v-btn color="secondary" @click="cancelEdit()">
          <v-icon class="mr-2">cancel</v-icon>
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn color="success" @click="saveActivity()">
          <v-icon class="mr-2">save</v-icon>
          {{ $t("actions.save") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
  <loading-page v-else></loading-page>
</template>

<script>
import ActivitySimpleList from "@/mockups/content-cloud/activity/ActivitySimpleList";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ActivitiesSelectedList from "@/mockups/content-cloud/_components/ActivitiesSelectedList.vue";

const ActivityLessonEntityRepository = RepositoryFactory.get(
  "ActivityLessonEntityRepository"
);
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ActivityForm",
  components: {
    LoadingPage,
    ActivitySimpleList,
    ActivitiesSelectedList,
  },
  props: {
    activity: {
      type: Object,
      required: false,
    },
    lesson: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      validForm: true,
      loading: false,
      lessonActivities: [],
      presentActivities: [],
      activitiesSelected: [],
      showSelectedError: false,
    };
  },
  created() {
    this.presentActivities = this.lesson.activities.map((a) => a.activityId);
    if (this.activity) {
      this.lessonActivities.push(this.activity);
      ActivityEntityRepository.get(this.activity.activityId).then((res) => {
        this.activitiesSelected.push(res);
        this.lessonActivities.lessonId = this.lesson.id;
      });
    }
  },
  methods: {
    selectActivity(activity) {
      if (!this.activitiesSelected.includes(activity)) {
        this.activitiesSelected.push(activity);
        this.lessonActivities.push({
          activityId: activity.id,
          lessonId: this.lesson.id,
        });
        this.presentActivities.push(activity.id);
        this.showSelectedError = false;
      }
    },
    cancelEdit() {
      this.activitySelected = [];
      this.$emit("cancel");
    },
    saveActivity() {
      this.$refs.form.validate();
      if (this.activitiesSelected.length === 0) {
        this.showSelectedError = true;
        return;
      }
      if (this.validForm) {
        ActivityLessonEntityRepository.batchSave(this.lessonActivities)
          .then(() => this.$emit("update"))
          .catch(() =>
            this.$log.debug(
              "Error creating activity lessons for lesson: " + this.lesson.id
            )
          );
      }
    },
    removeActivity(item) {
      this.activitiesSelected.splice(this.activitiesSelected.indexOf(item), 1);
      this.lessonActivities.splice(this.lessonActivities.indexOf(item), 1);
      this.presentActivities.splice(this.presentActivities.indexOf(item.id), 1);
    },
  },
};
</script>
