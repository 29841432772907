<template>
  <v-container fluid>
    <a ref="hiddenDownloader" class="d-none" />
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" xl="2">
        <dateAndHourPicker
          :datePickerProp="{
            data: startTimeFilter,
            label: 'lecture.prop.startTime',
          }"
          @update-time="updateDateTime('startTimeFilter', false, ...arguments)"
        ></dateAndHourPicker>
      </v-col>
      <v-col cols="12" md="4" xl="2">
        <dateAndHourPicker
          :datePickerProp="{
            data: endTimeFilter,
            label: 'lecture.prop.endTime',
          }"
          @update-time="updateDateTime('endTimeFilter', false, ...arguments)"
        ></dateAndHourPicker>
      </v-col>
      <v-col cols="12" md="4" xl="2" align="right">
        <v-btn
          class="ml-2"
          outlined
          color="primary"
          :loading="isLoadingPDF"
          @click="getPDF"
          ><v-icon class="mr-2">file_download</v-icon>
          {{ $t("lecture.export.pdf") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="lectures"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span v-if="item.date">
              {{ item.date | dateTimeWithTz("short") }}
            </span>
          </template>

          <template v-slot:[`item.startTime`]="{ item }">
            <span v-if="item.startTime">
              {{ item.startTime | dateTimeWithTz("time") }}
            </span>
          </template>

          <template v-slot:[`item.endTime`]="{ item }">
            <span v-if="item.endTime">
              {{ item.endTime | dateTimeWithTz("time") }}
            </span>
          </template>

          <template v-slot:[`item.settleTime`]="{ item }">
            <span v-if="item.settleTime">
              {{ item.settleTime | dateTimeWithTz("long") }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import {
  dateArrayToDateString,
  dateToMinLocalDateOrMaxLocalDate,
} from "@/common/conversion-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import { downloadFile } from "@/common/file-utils";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
const today = new Date();
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
const defaultEndTime = [
  lastDayOfMonth.getFullYear(),
  lastDayOfMonth.getMonth() + 1,
  lastDayOfMonth.getDate(),
];
export default {
  name: "TeacherSettledLecturesTab",
  components: {
    DateAndHourPicker,
  },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      lectures: [],
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      startTimeFilter: null,
      endTimeFilter: null,
      firstLoad: true,
      tableFooterProps,
      isLoadingPDF: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lecture.prop.product"),
          value: "edition",
        },
        {
          text: this.$t("lecture.prop.date"),
          value: "date",
        },
        {
          text: this.$t("lecture.prop.startLectureTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endLectureTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.settlement-date"),
          value: "settleTime",
        },
      ];
    },
    filters() {
      return {
        startTime: this.startTimeFilter
          ? dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.startTimeFilter),
              false
            )
          : null,
        endTime: this.endTimeFilter
          ? dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.endTimeFilter),
              true
            )
          : null,
      };
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.startTimeFilter) {
      if (this.$route.query.startTimeFilter === "null") {
        this.startTimeFilter = null;
      } else {
        this.startTimeFilter = this.$route.query.startTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.startTimeFilter = null;
    }

    if (this.$route.query.endTimeFilter) {
      if (this.$route.query.endTimeFilter === "null") {
        this.endTimeFilter = null;
      } else {
        this.endTimeFilter = this.$route.query.endTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.endTimeFilter = defaultEndTime;
    }
    if (this.firstLoad) {
      this.getLectures().then(() => (this.firstLoad = false));
    }
  },
  methods: {
    getLectures() {
      this.loading = true;
      const sortMapping = {
        date: "startTime",
        settleTime: "teacherPayment.date",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage, sortMapping),
          ...this.filters,
        },
      };
      return LectureEntityRepository.settledTeacherLectures(
        this.teacherId,
        options
      )
        .then((response) => {
          this.lectures = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching lectures"))
        .finally(() => (this.loading = false));
    },
    getPDF() {
      this.loadingPDF = true;
      const options = {
        params: {
          teacher: this.teacherId,
          startTime: this.startTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.startTimeFilter),
                false
              )
            : null,
          endTime: this.endTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.endTimeFilter),
                true
              )
            : null,
        },
      };
      return LectureEntityRepository.getPDFSettlement(options)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("lecture.export.download_success"),
            type: "success",
          });
        })
        .catch((response) => {
          this.$notify({
            title: this.$t("lecture.export.download_error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.loadingPDF = false));
    },
    redirect(query) {
      if (
        !this.firstLoad &&
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getLectures();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    changeQueryFilters(query) {
      query.startTimeFilter = this.startTimeFilter
        ? dateArrayToDateString(this.startTimeFilter)
        : "null";

      query.endTimeFilter = this.endTimeFilter
        ? dateArrayToDateString(this.endTimeFilter)
        : "null";
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
  },
};
</script>
