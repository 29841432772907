// Used to gain access to my profile routes
const navBarItemsProfile = [
  {
    title: "student.navbar.profile",
    icon: "mdi-account",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.info",
        link: "ProfileStudentView Detail",
        icon: "mdi-account",
        notification: false,
        showed: true,
      },
      {
        title: "student.navbar.tutors",
        link: "ProfileStudentTutorsTab",
        icon: "escalator_warning",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.documents",
    link: "ProfileStudentDocumentsTab",
    icon: "mdi-file-document",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.schedule",
    icon: "mdi-calendar",
    link: "ProfileStudentSchedulesTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.levels",
    icon: "mdi-school",
    link: "ProfileStudentLevelsTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.registrations",
    link: "ProfileStudentRegistrationsTab",
    icon: "mdi-school",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.balance",
        link: "ProfileStudentBalanceTab",
        icon: "mdi-cash",
        notification: false,
        showed: true,
      },
      {
        title: "student.navbar.movements",
        link: "ProfileStudentMovementsTab",
        icon: "mdi-swap-horizontal",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.feedback",
    link: "ProfileStudentFeedbackTab",
    icon: "mdi-star",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.results",
    link: "ProfileStudentResultsTab",
    icon: "mdi-briefcase",
    notification: false,
    showed: true,
  },
];

export default navBarItemsProfile;
