<template>
  <v-data-table
    class="rows-clickable"
    :headers="headers"
    :items="activitiesSelected"
    hide-default-footer
  >
    <template v-slot:[`item.level`]="{ item }">
      <v-chip
        v-if="item.level"
        :color="getChipBackColor(item.level.language)"
        :text-color="getChipTextColor(item.level.language)"
      >
        {{ item.level.name | uppercase }}
      </v-chip>
    </template>

    <template v-slot:[`item.activityType`]="{ item }">
      <v-chip
        v-if="item.activityType"
        :color="getChipBackColor(item.activityType)"
        :text-color="getChipTextColor(item.activityType)"
        >{{ item.activityType.name }}</v-chip
      >
    </template>

    <template v-slot:[`item.resourceType`]="{ item }">
      <v-chip
        v-if="item.resourceType"
        :color="stringToColour(item.resourceType)"
        :text-color="stringToColourText(item.resourceType)"
        >{{ $t(`activity.resource_type.${item.resourceType}`) }}</v-chip
      >
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon @click.stop="removeActivity(item)"> cancel </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import {
  getChipBackColor,
  getChipTextColor,
  stringToColour,
  stringToColourText,
} from "@/common/customization";

export default {
  name: "ActivitiesSelestedList",
  props: {
    activitiesSelected: {
      required: true,
      type: [],
    },
    removeActivity: {
      required: true,
      type: Function,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("activity.prop.title"),
          value: "title",
        },

        {
          text: this.$t("activity.prop.description"),
          value: "description",
        },

        {
          text: this.$t("activity.prop.level"),
          value: "level",
        },
        {
          text: this.$t("activity.prop.activity_type"),
          sortable: false,
          value: "activityType",
        },
        {
          text: this.$t("activity.prop.resource_type"),
          value: "resourceType",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  methods: {
    getChipBackColor,
    getChipTextColor,
    stringToColour,
    stringToColourText,
  },
};
</script>
