import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import i18n from "@/plugins/i18n";

const authFunctions = mapAuthGetter(["isTutor", "isAdmin"]);

function checkAuth(authority) {
  return auth.getUser().authorities.includes(authority);
}

function getNames(list, filter) {
  return list
    .filter((user) => filter(user))
    .slice(0, 3)
    .map((user) => user.companyName || user.firstName)
    .join(", ");
}

function getStudentNames(users) {
  return getNames(
    users,
    (user) =>
      user.authorities.includes("ROLE_STUDENT") ||
      user.authorities.includes("ROLE_STUDENT_CANDIDATE")
  );
}

function renderChatList(chatList) {
  return chatList.map((chat) => {
    switch (chat.channelType) {
      case "USER_ADMINS":
        if (authFunctions.isTutor()) {
          const tutored = getStudentNames(chat.users);
          chat.chatName =
            tutored !== ""
              ? `${i18n.t("chat.tutor-admin-default-name", {
                  user: tutored,
                })}`
              : `${i18n.t("chat.user-admin-default-name")}`;
          break;
        }
        if (authFunctions.isAdmin()) {
          chat.chatName =
            `${i18n.t("chat.admin-default-name")}` +
            `${getNames(
              chat.users,
              (user) => !user.authorities.includes("ROLE_ADMIN")
            )}`;
          break;
        }
        chat.chatName = `${i18n.t("chat.user-admin-default-name")}`;
        break;
      case "STUDENT_TEACHER":
        if (checkAuth("ROLE_TEACHER_CANDIDATE") || checkAuth("ROLE_TEACHER")) {
          chat.chatName = `${i18n.t("chat.teacher-student-name", {
            user: `${getStudentNames(chat.users)}`,
          })}`;
        } else {
          chat.chatName = `${i18n.t("chat.student-teacher-name", {
            user: `${getNames(
              chat.users,
              (user) =>
                user.authorities.includes("ROLE_TEACHER_CANDIDATE") ||
                user.authorities.includes("ROLE_TEACHER")
            )}`,
          })}`;
          if (authFunctions.isTutor()) {
            chat.chatName =
              chat.chatName +
              ", " +
              getNames(
                chat.users,
                (user) =>
                  user.id !== auth.getUser().id &&
                  !(
                    user.authorities.includes("ROLE_TEACHER_CANDIDATE") ||
                    user.authorities.includes("ROLE_TEACHER")
                  )
              );
          }
        }
        if (chat.users.length > 2) {
          chat.chatName = chat.chatName + ` ${i18n.t("chat.tutors")}`;
        }
        break;
      case "TUTOR_TEACHER":
        if (authFunctions.isTutor()) {
          chat.chatName = `${i18n.t("chat.student-teacher-name", {
            user: `${getNames(
              chat.users,
              (user) => user.id !== auth.getUser().id
            )}`,
          })}`;
        } else {
          chat.chatName = `${i18n.t("chat.tutor-teacher-name", {
            user: `${getNames(
              chat.users,
              (user) => user.id !== auth.getUser().id
            )}`,
          })}`;
        }
        break;
      case "DEFAULT":
        if (chat.chatName === "" || chat.chatName == null) {
          chat.chatName =
            `${i18n.t("chat.default-name")}` +
            `${getNames(chat.users, (user) => user.id !== auth.getUser().id)}`;
          if (chat.users.length > 4) {
            chat.chatName = chat.chatName + ` +${chat.users.length - 4}`;
          }
        }
        break;
      case "TEACHERS":
        chat.chatName = `${i18n.t("chat.teachers-name")}`;
        break;
      case "SUPERVISION":
        let name = "";
        if (checkAuth("ROLE_HEADMASTER")) {
          name = getNames(
            chat.users,
            (user) => !user.authorities.includes("ROLE_HEADMASTER")
          );
          chat.chatName = `${i18n.t("chat.supervisor-name", {
            user: name,
          })}`;
        } else {
          name = getNames(chat.users, (user) =>
            user.authorities.includes("ROLE_HEADMASTER")
          );
          chat.chatName = `${i18n.t("chat.supervised-name", {
            user: name,
          })}`;
        }
        break;
      case "NOTIFICATION":
        if (authFunctions.isTutor()) {
          if (chat.users.length > 1) {
            chat.chatName = `${i18n.t("chat.tutor-notification-name", {
              user: getStudentNames(chat.users),
            })}`;
            break;
          }
        }
        chat.chatName = `${i18n.t("chat.notification-name")}`;
        break;
      case "ADMINS_NOTIFICATIONS":
        chat.chatName = `${i18n.t("chat.admins-notifications-name")}`;
        break;
    }
    if (
      chat.lastMessage &&
      !chat.lastMessage.text &&
      chat.channelType !== "USER_ADMINS"
    ) {
      switch (chat.lastMessage.messageType) {
        case "SYSTEM_USER_JOINED":
          chat.lastMessage.text = `${i18n.t("chat.user-added", {
            user: `${chat.lastMessage.sender.name}`,
          })}`;
          break;
        case "SYSTEM_USER_LEFT":
          chat.lastMessage.text = `${i18n.t("chat.user-left", {
            user: `${chat.lastMessage.sender.name}`,
          })}`;
          break;
      }
    }
    return chat;
  });
}

export { renderChatList, getStudentNames };
