<template>
  <v-navigation-drawer
    :absolute="absolute"
    :temporary="temporary"
    :stateless="stateless"
    :value="value"
    @input="onInput"
  >
    <v-list>
      <menu-element :items="items"></menu-element>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MenuElement from "./MenuElement";
export default {
  props: {
    absolute: {
      required: false,
      type: Boolean,
      default: false,
    },
    temporary: {
      required: false,
      type: Boolean,
      default: false,
    },
    items: {
      required: true,
      type: Array,
    },
    stateless: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      default: undefined,
    },
  },
  components: { MenuElement },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>
