<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.cancel") }}
              </span>
            </v-btn>
            <v-btn
              class="success ml-2"
              :disabled="!entity.file || file.loading"
              @click="save"
            >
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.name"
                type="text"
                class="required"
                :rules="[(v) => !!v || $t('merit.error.required')]"
                :label="$t('merit.prop.name')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.organization"
                type="text"
                class="required"
                :rules="[(v) => !!v || $t('merit.error.required')]"
                :label="$t('merit.prop.organization')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <number-field
                v-model="entity.year"
                class="required"
                :rules="[(v) => !!v || $t('merit.error.required')]"
                type="integer"
                :label="$t('merit.prop.year')"
              ></number-field>
            </v-col>

            <v-col cols="12" md="6" class="mt-2" @click="chooseFile">
              <input
                hidden
                class="d-none"
                type="file"
                ref="fileLoader"
                :accept="extensions.document"
                @change="uploadDocument(entity, 'file', file, ...arguments)"
              />
              <v-icon>folder</v-icon>
              <span v-if="entity.file && !file.loading">
                {{ entity.file.fileName }}
              </span>
              <v-col v-else-if="file.loading" cols="4" class="d-inline-flex">
                <v-progress-linear
                  indeterminate
                  color="light-blue"
                  height="10"
                  striped
                ></v-progress-linear>
              </v-col>
              <span v-else>
                {{ $t("merit.messages.select-file") }}
                <span style="color: red">*</span>
              </span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import NumberField from "@/components/number-field/NumberField.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import extensions from "@/common/file-extensions";
import checkInvalidID from "@/common/checkInvalidID";
import { deleteTempFile, uploadDocument } from "@/common/file-utils";

const MeritEntityRepository = RepositoryFactory.get("MeritEntityRepository");

export default {
  name: "MeritForm",
  components: {
    LoadingPage,
    NumberField,
  },
  data() {
    return {
      loading: false,
      entity: {},
      file: {
        loading: false,
      },
      extensions,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isLoading() {
      return this.loading;
    },
    isEditForm() {
      return !!this.$route.params.meritId;
    },
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.meritId) this._fetchData(to.params.meritId);
    next();
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "file");
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      if (this.isEditForm) {
        this.loading = true;
        MeritEntityRepository.get(this.$route.params.meritId)
          .then((response) => {
            this.entity = response;
          })
          .catch((err) => checkInvalidID(err))
          .finally(() => (this.loading = false));
      }
    },
    back() {
      this.$router.go(-1);
    },
    goToNextRoute() {
      this.isAdmin
        ? this.$router.push({
            name: "TeacherEducationTab",
            params: {
              id: this.$attrs.teacherId,
              backAction: this.$route.params.backPrevious,
            },
          })
        : this.$router.push({
            name: "ProfileTeacherEducationTab",
            params: {
              backAction: this.$route.params.backPrevious,
            },
          });
    },
    save() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("merit.error.form-errors"),
          duration: 30000,
        });
      } else {
        this.loading = true;
        this.entity.teacherId = this.$attrs.teacherId;
        MeritEntityRepository.save(this.entity)
          .then(() => {
            this.entity.file = null;
            this.goToNextRoute();
          })
          .catch(() => this.$log.debug("Error saving merit: " + this.entity))
          .finally(() => (this.loading = false));
      }
    },
    chooseFile() {
      this.$refs.fileLoader.click();
    },
    uploadDocument,
  },
};
</script>
