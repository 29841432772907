var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.files,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("userFile.type." + (item.type))))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.loadingRow[item.id])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.download")))])]):_c('loading-spinner')]}}],null,true)}),_c('a',{ref:"hiddenDownloader",staticClass:"d-none"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }