<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-col cols="12" class="text-center text-h2">
          <information-panel
            :hasDegrees="hasDegrees"
            :hasAvailabilities="hasAvailabilities"
            :hasLevels="hasLevels"
          >
          </information-panel>
        </v-col>
        <v-row>
          <v-col cols="12" md="6" class="text-center">
            <span class="text-h6">
              {{ $t("teacher.dashboard.next_lectures") }}
              <v-icon
                v-if="!expandNextLectures && nextLectures.length > 0"
                @click="expandNextLectures = true"
              >
                expand_more
              </v-icon>
              <v-icon
                v-else-if="nextLectures.length > 0"
                @click="expandNextLectures = false"
              >
                expand_less
              </v-icon>
            </span>
            <v-row v-if="loading" class="mt-8" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <transition name="slide-fade">
              <v-container v-if="expandNextLectures && !loading" fluid>
                <v-row
                  v-for="lecture in nextLectures"
                  :key="lecture.id"
                  class="mt-2"
                  justify="center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="lecture.state === 'DOING' ? 'doing' : ''"
                      width="300px"
                      @click="navigate('Lecture Detail', lecture.id)"
                    >
                      <v-card-title class="py-0">
                        <v-col class="text-center">
                          <span class="no-split-words">
                            {{ lecture.productName }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <span class="text-overline">
                          {{ lecture.startTime | dateTimeWithTz("medium") }} -
                          {{ lecture.endTime | dateTimeWithTz("time") }}
                        </span>
                        <v-container v-if="userCanJoin(lecture)" class="py-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                @click.stop="joinToLecture(lecture)"
                              >
                                <v-icon color="success">login</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("lecture.join") }}</span>
                          </v-tooltip>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-row>
                <v-row
                  v-if="!nextLectures || nextLectures.length === 0"
                  class="mt-2"
                  justify="center"
                >
                  <span> {{ $t("teacher.dashboard.no_next_lectures") }} </span>
                </v-row>
              </v-container>
            </transition>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <span class="text-h6">
              {{ $t("teacher.dashboard.next_level_test") }}
              <v-icon
                v-if="!expandNextTests && nextLevelTest.length > 0"
                @click="expandNextTests = true"
              >
                expand_more
              </v-icon>
              <v-icon
                v-else-if="nextLevelTest.length > 0"
                @click="expandNextTests = false"
              >
                expand_less
              </v-icon>
            </span>
            <v-row v-if="loading" class="mt-8" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <transition name="slide-fade">
              <v-container v-if="expandNextTests && !loading" fluid>
                <v-row
                  v-for="lecture in nextLevelTest"
                  :key="lecture.id"
                  class="mt-2"
                  justify="center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :class="lecture.state === 'DOING' ? 'doing' : ''"
                      :elevation="hover ? 12 : 2"
                      width="300px"
                      @click="navigate('Lecture Detail', lecture.id)"
                    >
                      <v-card-title class="py-0">
                        <v-col class="text-center">
                          <span class="no-split-words">
                            {{
                              lecture.language
                                ? $t("teacher.dashboard.level_test", {
                                    lang: $t(`languages.${lecture.language}`),
                                  })
                                : "-"
                            }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <v-icon>mdi-account-group</v-icon>
                        <span
                          class="text-overline d-block"
                          :style="index != 0 ? 'margin-top: -5px' : ''"
                          v-for="(student, index) in lecture.studentNames"
                          :key="index"
                          >{{ student }}</span
                        >
                        <span class="text-overline">
                          {{ lecture.startTime | dateTimeWithTz("medium") }} -
                          {{ lecture.endTime | dateTimeWithTz("time") }}
                        </span>
                        <v-container v-if="userCanJoin(lecture)" class="py-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                @click.stop="joinToLecture(lecture)"
                              >
                                <v-icon color="success">login</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("lecture.join") }}</span>
                          </v-tooltip>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-row>
                <v-row
                  v-if="!nextLevelTest || nextLevelTest.length === 0"
                  class="mt-2"
                  justify="center"
                >
                  <span> {{ $t("teacher.dashboard.no_next_level_test") }}</span>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { joinToLecture, userCanJoin } from "@/common/lecture-common";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import InformationPanel from "@/mockups/teachers/components/InformationPanel";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");

export default {
  name: "TeacherDashboard",
  components: { InformationPanel },
  data() {
    return {
      hasDegrees: true,
      hasAvailabilities: true,
      hasLevels: true,
      nextLectures: [],
      nextLevelTest: [],
      expandNextLectures: true,
      expandNextTests: true,
      loading: false,
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      return DashboardRepository.getForTeacher()
        .then((data) => {
          this.hasDegrees = data.hasDegrees;
          this.hasAvailabilities = data.hasAvailabilities;
          this.hasLevels = data.hasLevels;
          this.nextLectures = data.nextLectures;
          this.nextLevelTest = data.nextLevelTest;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    navigate(routeName, entityId) {
      this.$router.push({
        name: routeName,
        params: { id: entityId, backPrevious: true },
      });
    },
    userCanJoin,
    joinToLecture,
  },
};
</script>
<style scoped lang="scss">
.doing {
  border: 2px solid #00a451;
}
</style>
