<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-block d-lg-none" cols="auto">
        <v-btn icon @click="navDrawer = !navDrawer">
          <v-icon> mdi-menu </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <navigation-drawer
        v-model="navDrawer"
        class="d-block d-lg-none"
        :absolute="true"
        :items="navBarCalculated"
        :temporary="true"
      >
      </navigation-drawer>
      <v-col class="d-none d-lg-block" md="auto">
        <div>
          <div v-if="!isMe">
            <h3 class="mb-4 pl-2" v-if="!isMe">
              {{ entity.companyName }}
              <v-btn
                v-if="chatId"
                class="mb-4"
                color="accent"
                icon
                tile
                @click.stop="$EventBus.$emit('open-chat', chatId)"
              >
                <icon
                  name="chat-simple"
                  width="28"
                  height="28"
                  title="chat"
                ></icon>
              </v-btn>
            </h3>
          </div>
          <navigation-drawer :items="navBarCalculated" stateless :value="true">
          </navigation-drawer>
        </div>
      </v-col>
      <v-col>
        <router-view
          v-if="userId"
          :userId="userId"
          :companyId="companyId"
        ></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isMe, mapAuthGetter } from "@/common/mapAuthGetter";
import NavigationDrawer from "@/components/navigation-drawer/NavigationDrawer";
import { navBarItemsAdmin, navBarItemsProfile } from "./tabs/navBarItems";
import checkInvalidID from "@/common/checkInvalidID";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ChatRepository from "@/repositories/components/ChatRepository";

const CompanyEntityRepository = RepositoryFactory.get(
  "CompanyEntityRepository"
);

export default {
  name: "CompanyDetail",
  components: {
    NavigationDrawer,
  },
  data() {
    return {
      companyId: null,
      entity: {},
      navDrawer: false,
      userId: null,
      chatId: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isMe() {
      return isMe(this.entity.login);
    },
    navBarCalculated() {
      if (this.isAdmin) return navBarItemsAdmin;
      return navBarItemsProfile;
    },
  },
  created() {
    if (!this.$route.params.id) {
      CompanyEntityRepository.getByUser()
        .then((response) => {
          this.entity = response;
          this.companyId = response.id;
          this.userId = response.userId;
        })
        .catch((err) => {
          this.$log.debug("Error fetching company for current user");
          checkInvalidID(err);
        });
    } else {
      this.companyId = parseInt(this.$route.params.id);
      this._fetchData(this.$route.params.id);
    }
  },
  methods: {
    _fetchData(id) {
      return CompanyEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          this.userId = response.userId;
          if (this.isAdmin) {
            this.getUserAdminsChat();
          }
        })
        .catch((err) => {
          this.$log.debug("Error fetching company with ID " + id);
          checkInvalidID(err);
        });
    },
    getUserAdminsChat() {
      const options = {
        params: {
          userIds: this.userId,
          type: "USER_ADMINS",
        },
      };
      ChatRepository.getChannelsByUserAndType(options)
        .then((response) => {
          if (response.length > 0) {
            this.chatId = response[0].id;
          }
        })
        .catch(() => {
          this.$log.debug(
            "Error gettin USER_ADMINS chat for user with id " + this.userId
          );
        });
    },
  },
};
</script>
