<template>
  <div
    class="
      clrm-video
      clrm-video--border-radius
      clrm-video--focused
      clrm-video--active
    "
  >
    <div class="clrm-video__wrapper">
      <video
        v-show="showVideo"
        class="clrm-video__source"
        ref="videoCanvas"
        width="320"
        height="180"
      />

      <img
        v-show="!showVideo"
        class="clrm-video__userimage"
        width="320"
        height="180"
        :src="image.photo"
      />
    </div>
    <div class="clrm-video__title">
      {{ userDisplayName }}<span v-show="!currentDevice"> (offline)</span>
    </div>
    <div class="clrm-video__toolbar" v-if="controls">
      <button
        v-if="!audioActive"
        class="clrm-video__button clrm-video__button--camera"
        :class="{ 'not-active': currentDevice && !audioActive }"
        :title="$t('classroom.discussion.audio-action')"
        @click="$emit('start-audio')"
      >
        <icon
          name="arrow-top"
          width="16"
          height="16"
          :title="$t('classroom.discussion.audio')"
        />
      </button>
      <button
        v-if="audioActive"
        class="clrm-video__button clrm-video__button--microphone"
        :class="{ 'not-active': currentDevice && currentDevice.muted }"
        :title="$t('classroom.discussion.microphone-action')"
        @click="$emit('switch-audio')"
      >
        <icon
          name="microphone"
          width="16"
          height="16"
          :title="$t('classroom.discussion.microphone')"
        />
      </button>
      <button
        v-if="audioActive && selectedCamera"
        class="clrm-video__button clrm-video__button--camera"
        :class="{ 'not-active': currentDevice && !currentDevice.bVideoOn }"
        :title="$t('classroom.discussion.camera-action')"
        @click="switchCamera()"
      >
        <icon
          name="camera"
          width="16"
          height="16"
          :title="$t('classroom.discussion.camera')"
        />
      </button>
    </div>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  mapClassroomStateGetter,
  getClassroomState,
} from "@/components/classroom/store/mapClassroomStateGetter";
import auth from "@/common/auth";
import { isAndroidBrowser, isSupportOffscreenCanvas } from "./util/platform";

const isUseVideoElementToDrawSelfVideo =
  isAndroidBrowser() || isSupportOffscreenCanvas();
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);
let videoOn = false;

export default {
  name: "OwnCameraReproduction",
  props: {
    controls: {
      type: Boolean,
      default: true,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: {
        photo: require("@/assets/icon_visits.png"),
      },
      state: getClassroomState(),
      userDisplayName: null,
    };
  },
  computed: {
    showVideo() {
      return (
        isUseVideoElementToDrawSelfVideo &&
        this.currentDevice?.bVideoOn &&
        this.zoom.userCanSeeTheLocalCamera()
      );
    },
    ...mapClassroomStateGetter([
      "selectedCamera",
      "currentDevice",
      "audioActive",
    ]),
  },
  mounted() {
    const userId = auth.getUser().id;
    this.userDisplayName = auth.getUser().displayName;
    UserDataEntityRepository.getPhoto(userId)
      .then((data) => {
        if (data.size > 0) {
          this.image.photo = URL.createObjectURL(data);
        }
      })
      .catch(() =>
        this.$log.debug("Error fetching profile photo for user: " + userId)
      );
  },
  methods: {
    switchCamera() {
      if (this.currentDevice.bVideoOn) {
        videoOn = false;
        this.zoom.stopCamera();
      } else {
        videoOn = true;
        this.zoom.startCamera(this.$refs.videoCanvas);
      }
    },
  },
};
</script>

<style></style>
