var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"80%","persistent":""}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("product.headers.tariff_form"))+" ")])]),_c('v-divider',{staticClass:"mb-6"}),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"required",attrs:{"outlined":"","type":"Number","rules":[
                function (v) { return !!v || _vm.$t('product.error.required'); },
                function (v) { return !v ||
                  !_vm.outOfTariffRange(v) ||
                  _vm.$t('product.error.tariff_range_error'); },
                function (v) { return !v ||
                  _vm.maxGreaterThanMin(v) ||
                  _vm.$t('product.error.min_lower_max'); },
                function (v) { return !v ||
                  _vm.higherThanGlobalMin(v) ||
                  _vm.$t('product.error.min_students_higher_global', {
                    value: this$1.product.minStudents,
                  }); },
                function (v) { return !v ||
                  _vm.higherThanGlobalMax(v) ||
                  _vm.$t('product.error.min_students_higher_global', {
                    value: this$1.product.maxStudents,
                  }); } ],"label":_vm.$t('product.prop.min_students')},model:{value:(_vm.myTariff.minStudents),callback:function ($$v) {_vm.$set(_vm.myTariff, "minStudents", $$v)},expression:"myTariff.minStudents"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"required",attrs:{"outlined":"","type":"Number","rules":[
                function (v) { return !!v || _vm.$t('product.error.required'); },
                function (v) { return !v ||
                  !_vm.outOfTariffRange(v) ||
                  _vm.$t('product.error.tariff_range_error'); },
                function (v) { return !v ||
                  _vm.maxGreaterThanMin(v) ||
                  _vm.$t('product.error.max_higher_min'); },
                function (v) { return !v ||
                  _vm.lowerThanGlobalMax(v) ||
                  _vm.$t('product.error.max_students_higher_global', {
                    value: this$1.product.maxStudents,
                  }); },
                function (v) { return !v ||
                  _vm.higherThanGlobalMin(v) ||
                  _vm.$t('product.error.min_students_lower_global', {
                    value: this$1.product.minStudents,
                  }); } ],"label":_vm.$t('product.prop.max_students')},model:{value:(_vm.myTariff.maxStudents),callback:function ($$v) {_vm.$set(_vm.myTariff, "maxStudents", $$v)},expression:"myTariff.maxStudents"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('payment-type-selector',{attrs:{"outlined":"","disabled":"","label":_vm.$t('product.payment_type.label')},model:{value:(_vm.myTariff.paymentType),callback:function ($$v) {_vm.$set(_vm.myTariff, "paymentType", $$v)},expression:"myTariff.paymentType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('number-field',{staticClass:"required",attrs:{"type":"Double","outlined":"","dense":false,"label":_vm.$t('product.prop.price'),"rules":[function (v) { return !!v || _vm.$t('product.error.required'); }]},model:{value:(_vm.myTariff.price),callback:function ($$v) {_vm.$set(_vm.myTariff, "price", $$v)},expression:"myTariff.price"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("close")]),_vm._v(" "+_vm._s(_vm.$t("actions.cancel")))],1),_c('v-btn',{attrs:{"disabled":!_vm.validForm,"color":"success"},on:{"click":function($event){return _vm.$emit('save', _vm.myTariff)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.$t("actions.save")))],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }