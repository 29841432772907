<template>
  <v-container id="container">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <v-checkbox
              class="configuration-field"
              v-model="copy"
              :label="$t('scheduleCalendar.availability_calendar.copy')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <date-and-hour-picker
              class="configuration-field"
              :datePickerProp="{
                minDate: currentDate.toISOString(),
                data: limit,
                label: 'scheduleCalendar.availability_calendar.limit',
              }"
              :disabled="!copy"
              @update-time="updateLimit(...arguments)"
            ></date-and-hour-picker>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <v-btn
              class="success"
              @click="onSave"
              :disabled="!changed"
              :loading="loading"
            >
              <v-icon>save</v-icon>
              <span>{{ $t("actions.save") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-right">
        <span
          >{{ $t("profile.fields.timeZone") }} :
          {{ $t(`timezones.${schedule.timeZone.name}`) }}</span
        >
      </v-col>
    </v-row>

    <v-row no-gutters align="start">
      <v-col cols="3">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          {{ $t("scheduleCalendar.today") }}
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" md="3">
        <span class="headline">
          {{
            focus.split("-").map((item) => parseInt(item))
              | dateTimeWithoutTz("monthAndYear")
          }}
        </span>
      </v-col>
      <v-col class="text-right">
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-btn class="px-1" outlined v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changeView('day')">
              <v-list-item-title>{{ $t("day") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeView('week')">
              <v-list-item-title>{{ $t("week") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeView('month')">
              <v-list-item-title>{{ $t("month") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-sheet :height="height">
      <v-calendar
        ref="availability_calendar"
        v-model="focus"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :events="availabilities"
        :event-color="getEventColor"
        :short-weekdays="false"
        :interval-height="25"
        :interval-minutes="30"
        :interval-count="48"
        color="primary"
        :type="type"
        @click:more="onDayClick"
        @click:date="onDayClick"
        @change="updateRange"
        :locale="$i18n.locale"
      >
        <template v-slot:event="{ event }">
          <div
            :id="event.start"
            class="event-div"
            @mousedown="startDrag(event, ...arguments)"
            @mouseover="checkEvent(event, ...arguments)"
            @mouseup="endDrag"
          >
            <v-row align="center" justify="center" no-gutters>
              <span>
                {{ event.name }}
              </span>
            </v-row>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
    <modal-dialog
      :content="$t('scheduleCalendar.availability_calendar.alert.content')"
      :dialog="dialog"
      submitClass="success"
      :submitText="$t('actions.save')"
      :title="$t('scheduleCalendar.availability_calendar.alert.title')"
      titleClass="warning white--text"
      titleIcon="mdi-alert"
      @submit="persistConfig"
      @cancel="dialog = false"
    ></modal-dialog>
  </v-container>
</template>

<script>
import {
  momentToUTCDateArray,
  utcDateArrayToMoment,
} from "@/common/conversion-utils";
import timeSlots from "./TimeSlots";
import DateAndHourPicker from "./DateAndHourPicker.vue";
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import moment from "moment-timezone";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherAvailabilityEntityRepository = RepositoryFactory.get(
  "TeacherAvailabilityEntityRepository"
);
const StudentAvailabilityEntityRepository = RepositoryFactory.get(
  "StudentAvailabilityEntityRepository"
);

export default {
  components: { DateAndHourPicker, ModalDialog },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: "650",
    },
    entityId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      availabilities: [],
      changed: false,
      copy: false,
      currentDate: null,
      dialog: false,
      end: null,
      focus: null,
      limit: null,
      limitDate: null,
      loading: false,
      start: null,
      timezone: null,
      type: "week",
      firstDragEvent: null,
      dragType: null,
    };
  },
  computed: {
    typeToLabel() {
      return {
        month: this.$t("month"),
        week: this.$t("week"),
        day: this.$t("day"),
      };
    },
  },
  created() {
    this.timezone = this.schedule.timeZone
      ? this.schedule.timeZone
      : { name: moment.tz.guess() };
    this.currentDate = moment.tz(this.timezone.name);
    this.focus = this.currentDate.format().substring(0, 10);
    this.limitDate = moment(this.currentDate).add(3, "months");
    let limitDateArray = this.limitDate.toArray();
    limitDateArray[1] = limitDateArray[1] + 1;
    this.$set(this, "limit", limitDateArray);
  },
  watch: {
    copy() {
      this.changed = true;
    },
    schedule(val) {
      this.timezone = val.timeZone ? val.timeZone : { name: moment.tz.guess() };
      this.markAvailabilities();
    },
    "$i18n.locale"() {
      this.formatAvailabilities();
    },
  },
  mounted() {
    this.formatAvailabilities();
    this.markAvailabilities();
    this.$refs.availability_calendar.scrollToTime("09:00");
  },
  methods: {
    setToday() {
      this.currentDate = moment.tz(this.timezone.name);
      this.focus = this.currentDate.format().substring(0, 10);
    },
    prev() {
      this.$refs.availability_calendar.prev();
    },
    next() {
      this.$refs.availability_calendar.next();
    },
    updateRange({ start, end }) {
      this.start = moment.utc(start.date);
      this.end = moment.utc(end.date).add(1, "days");
      this.currentDate = moment.tz(this.focus, this.timezone.name);
      this.formatAvailabilities();
      this.$emit("calendar-change", this.start, this.end);
    },
    changeView(type) {
      this.type = type;
    },
    onDayClick({ date }) {
      this.focus = date;
      if (this.type === "week") {
        this.type = "day";
      } else if (this.type === "month") {
        this.type = "week";
      }
    },
    /**
     * Persists data, checks on teacher/student availability if exists
     * if exists delete row, if not create a new availability for the teacher
     */
    persistConfig() {
      this.dialog = false;
      let repository = null;
      if (this.entityType === "student") {
        repository = StudentAvailabilityEntityRepository;
      } else if (this.entityType === "teacher") {
        repository = TeacherAvailabilityEntityRepository;
      }
      this.loading = true;
      let configuration = this.availabilities.filter((el) => el.checked);
      let end = this.end;
      if (this.copy) {
        end = this.limitDate;
        configuration.forEach((el) => {
          let week = 1;
          let endDate = moment(el.endDate).add(week, "weeks");
          let startDate = moment(el.startDate).add(week, "weeks");
          while (endDate.isSameOrBefore(this.limitDate)) {
            let copiedElement = JSON.parse(JSON.stringify(el));
            week++;
            copiedElement.startDate = startDate;
            copiedElement.endDate = endDate;
            configuration.push(copiedElement);
            endDate = moment(el.endDate).add(week, "weeks");
            startDate = moment(el.startDate).add(week, "weeks");
          }
        });
      }
      let configurationFormatted = configuration.map((el) => {
        let startDate = momentToUTCDateArray(el.startDate);
        let endDate = momentToUTCDateArray(el.endDate);
        return {
          personId: this.entityId,
          personType: this.entityType,
          startDate: startDate,
          endDate: endDate,
        };
      });
      repository
        .saveConfiguration(
          configurationFormatted,
          this.entityId,
          this.start.format("yyyy-MM-DDTHH:mm"),
          end.format("yyyy-MM-DDTHH:mm")
        )
        .then(() => {
          this.changed = false;
          this.$emit("availabilityChanged", this.start, this.end);
        })
        .catch(() =>
          this.$log.debug(
            "Error saving configuration: " + configurationFormatted
          )
        )
        .finally(() => (this.loading = false));
    },
    checkEvent(event, args) {
      if (event.event) {
        event.event.checked = !event.event.checked;
        this.changed = true;
      } else {
        // dragging
        if (args.buttons === 1) {
          event.checked = this.dragType;
          this.changed = true;
        }
      }
    },
    updateLimit(data) {
      this.changed = true;
      this.$set(this, "limit", data.date);
      let formattedArray = JSON.parse(JSON.stringify(data.date));
      formattedArray[1] = formattedArray[1] - 1;
      this.limitDate = moment.tz(formattedArray, this.timezone.name);
    },
    onSave() {
      if (this.copy) {
        this.dialog = true;
      } else {
        this.persistConfig();
      }
    },
    /**
     * Map the backend info with timeslots
     * to a valid format for calendar to show
     */
    formatAvailabilities() {
      if (this.type === "month") {
        this.availabilities = [];
      } else {
        this.availabilities = timeSlots.map((el) => {
          return {
            id: [],
            name: this.formatTitle(
              el.startTimeHour,
              el.startTimeMinute,
              el.endTimeHour,
              el.endTimeMinute
            ),
            start: this.getTimeSlotDate(
              el.day,
              el.startTimeHour,
              el.startTimeMinute
            )
              .format()
              .substring(0, 16),
            end: this.getTimeSlotDate(el.day, el.endTimeHour, el.endTimeMinute)
              .format()
              .substring(0, 16),
            startDate: this.getTimeSlotDate(
              el.day,
              el.startTimeHour,
              el.startTimeMinute
            ),
            endDate: this.getTimeSlotDate(
              el.day,
              el.endTimeHour,
              el.endTimeMinute
            ),
            checked: false,
          };
        });
      }
    },
    markAvailabilities() {
      this.schedule.availabilities.forEach((el) => {
        let startDate = utcDateArrayToMoment(el.startDate, this.timezone);
        let endDate = utcDateArrayToMoment(el.endDate, this.timezone);
        let av = this.availabilities.find(
          (slot) =>
            this._checkDateInInterval(
              startDate,
              slot.startDate,
              slot.endDate
            ) ||
            this._checkDateInInterval(endDate, slot.startDate, slot.endDate)
        );
        if (av) {
          av.checked = true;
          av.id.push(el.id);
        }
      });
    },
    _checkDateInInterval(date, init, end) {
      return date.isSameOrAfter(init) && date.isBefore(end);
    },
    /*
     * Formats the title into HH:MM / HH:MM
     */
    formatTitle(startHour, startMinute, endHour, endMinute) {
      return (
        this.formatInteger(startHour) +
        ":" +
        this.formatInteger(startMinute) +
        " / " +
        this.formatInteger(endHour) +
        ":" +
        this.formatInteger(endMinute)
      );
    },
    formatInteger(int) {
      return ("0" + int.toString()).slice(-2);
    },
    getTimeSlotDate(day, hours, minutes) {
      let distance = (day == 0 ? 7 : day) - 1;
      let date = moment(this.currentDate)
        .startOf("isoWeek")
        .add(distance, "days");
      date.hours(hours);
      date.minutes(minutes);
      return date;
    },

    startDrag(event, args) {
      // Dependiendo del estado de checked del primer elemento, marco o desmarco los siguientes mientras se arrastra
      this.dragType = !event.checked;
      this.firstDragEvent = event;
      this.checkEvent(event, args);
      var firstElement = document.getElementById(event.start);
      firstElement.classList.add("user-select-none");
      var container = document.getElementById("container");
      container.classList.add("user-select-none");
    },

    endDrag() {
      if (this.firstDragEvent) {
        var firstElement = document.getElementById(this.firstDragEvent.start);
        firstElement.classList.remove("user-select-none");
      }
      var container = document.getElementById("container");
      container.classList.remove("user-select-none");
      this.firstDragEvent = null;
      this.dragType = null;
    },
    getEventColor(event) {
      if (event.checked) {
        return "green";
      } else {
        return event.start.split("T")[1].split(":")[0] % 2 == 0
          ? "primary"
          : "accent";
      }
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
.configuration-field {
  max-width: 250px;
  margin: auto;
}
.user-select-none {
  user-select: none;
}
</style>
