<template>
  <v-card flat>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn v-if="backBtn" @click="$emit('back')">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            :disabled="!validStudent || image.loading"
            class="success ml-2"
            @click="$emit('save')"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form v-model="validForm">
        <v-row dense outlined>
          <v-col cols="12" sm="4" md="3" lg="3" xl="3">
            <image-edit v-model="entity"></image-edit>
            <v-row>
              <v-col cols="12">
                <number-field
                  v-if="isAdmin"
                  dense
                  outlined
                  v-model="entity.billingId"
                  :label="$t('profile.fields.billing_id')"
                  :rules="[
                    (v) =>
                      !v ||
                      v < 10000 ||
                      this.$t('profile.fields.validation.billing-id-size'),
                  ]"
                ></number-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9" xl="9">
            <v-row dense outlined>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.name"
                  class="required"
                  :rules="[(v) => !!v || $t('student.error.required')]"
                  :label="$t('profile.fields.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.surname"
                  class="required"
                  :rules="[(v) => !!v || $t('student.error.required')]"
                  :label="$t('profile.fields.surname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.displayName"
                  class="required"
                  :rules="[(v) => !!v || $t('student.error.required')]"
                  :label="$t('profile.fields.display_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="6">
                <day-picker
                  v-model="entity.birthdate"
                  :future-dates="false"
                  :label="$t('profile.fields.birthdate')"
                  :rules="[(v) => !!v || $t('student.error.required')]"
                  @change="updateTutors()"
                  dense
                  outlined
                  pastDates
                  required
                  :disabled="isStudent || isStudentCandidate"
                ></day-picker>
              </v-col>
              <v-col cols="12" sm="6" lg="6">
                <v-select
                  dense
                  outlined
                  :items="genres"
                  v-model="entity.genre"
                  class="required"
                  :rules="[(v) => !!v || $t('student.error.required')]"
                  :label="$t('profile.fields.genre')"
                  :item-text="translate"
                >
                </v-select>
              </v-col>
              <v-col v-if="accountFields" cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.login"
                  maxlength="50"
                  prepend-inner-icon="person"
                  type="text"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.username_required'),
                    (v) =>
                      !v ||
                      this.regex.LOGIN_REGEX.test(v) ||
                      $t('user_management.loginValid'),
                  ]"
                  :label="$t('profile.fields.login')"
                  @input="entity.login = entity.login.toLowerCase()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" :lg="accountFields ? 4 : 6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.mainEmail"
                  type="text"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('student.error.required'),
                    (v) =>
                      !v ||
                      this.regex.EMAIL_REGEX.test(v) ||
                      $t('userData.error.regex.emailPattern'),
                  ]"
                  :label="$t('profile.fields.main_email')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" :lg="accountFields ? 4 : 6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.secondaryEmail"
                  type="text"
                  :rules="[
                    (v) =>
                      !v ||
                      this.regex.EMAIL_REGEX.test(v) ||
                      $t('userData.error.regex.emailPattern'),
                  ]"
                  :label="$t('profile.fields.secondary_email')"
                ></v-text-field>
              </v-col>

              <v-col v-if="registerFields" cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.password"
                  prepend-inner-icon="vpn_key"
                  maxLength="100"
                  :append-icon="hidePassword1 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.form.password')"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    (value) =>
                      value.length <= 100 ||
                      $t('account.form.validation.password_less_chars'),
                    (value) =>
                      value.length > 3 ||
                      this.$t('account.form.validation.password_more_chars'),
                  ]"
                  :type="hidePassword1 ? 'password' : 'text'"
                  @click:append="() => (hidePassword1 = !hidePassword1)"
                ></v-text-field>
              </v-col>

              <v-col v-if="registerFields" cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.password2"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword2 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.actions.repeat_password')"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    passwordConfirmationRule,
                  ]"
                  :type="hidePassword2 ? 'password' : 'text'"
                  @click:append="() => (hidePassword2 = !hidePassword2)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.mainPhone"
                  type="text"
                  :rules="[]"
                  :label="$t('profile.fields.main_phone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.secondaryPhone"
                  type="text"
                  :rules="[]"
                  :label="$t('profile.fields.secondary_phone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.address"
                  :rules="[]"
                  :label="$t('profile.fields.address')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.postalcode"
                  :rules="[]"
                  :label="$t('profile.fields.postalcode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.town"
                  :rules="[]"
                  :label="$t('profile.fields.town')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.country"
                  :rules="[]"
                  :label="$t('profile.fields.country')"
                ></v-text-field>
              </v-col>
              <v-row dense class="d-flex d-md">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    append-icon="mdi-badge-account-horizontal"
                    v-model="entity.nif"
                    :label="$t('profile.fields.nif')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <day-picker
                    v-model="entity.nifExpiration"
                    :label="$t('profile.fields.passport_validity')"
                    :pastDates="false"
                    dense
                    future-dates
                    outlined
                  ></day-picker>
                </v-col>
              </v-row>
              <v-row dense class="d-flex d-md">
                <v-col cols="12" sm="6" lg="6">
                  <time-zone-selector
                    dense
                    outlined
                    v-model="entity.timeZone"
                    class="required"
                    :rules="[(v) => !!v || $t('student.error.required')]"
                    :label="$t('profile.fields.timeZone')"
                    autocomplete="no"
                  >
                  </time-zone-selector>
                </v-col>
                <v-col cols="12" sm="6" lg="6">
                  <language-selector
                    dense
                    outlined
                    v-model="entity.langKey"
                    :rules="[]"
                    :label="$t('profile.fields.language')"
                    autocomplete="no"
                  >
                  </language-selector>
                </v-col>
              </v-row>
              <v-row dense class="d-none d-md-flex">
                <v-col cols="12" sm="6" lg="4">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptEmails"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_emails')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptWhats"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_whats')"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="6" lg="4">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptCalls"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_calls')"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-row dense class="d-flex d-md-none">
            <v-col cols="12" sm="4">
              <v-switch
                dense
                outlined
                v-model="entity.acceptEmails"
                class="ma-2 tp-0"
                :rules="[]"
                :label="$t('profile.fields.accept_emails')"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="4">
              <v-switch
                dense
                outlined
                v-model="entity.acceptWhats"
                class="ma-2 tp-0"
                :rules="[]"
                :label="$t('profile.fields.accept_whats')"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="4">
              <v-switch
                dense
                outlined
                v-model="entity.acceptCalls"
                class="ma-2 tp-0"
                :rules="[]"
                :label="$t('profile.fields.accept_calls')"
              ></v-switch>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-textarea
              dense
              outlined
              v-model="entity.aboutMe"
              type="text"
              :rules="[]"
              :label="$t('profile.fields.about_me')"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-switch
              dense
              outlined
              v-model="entity.acceptCommercialCommunications"
              :rules="[]"
              :label="$t('profile.fields.accept_commercial_communication')"
            ></v-switch>
          </v-col>
          <span v-if="!entity.id">
            <v-col cols="12">
              <v-switch
                class="required"
                dense
                outlined
                v-model="entity.acceptRGPD"
                :rules="[
                  (v) => v || $t('account.form.validation.rgpd_required'),
                ]"
                :label="$t('profile.fields.accept_rgpd')"
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <span class="text-caption" v-html="$t('profile.legal_text.rgpd')">
              </span>
            </v-col>
          </span>
          <v-col cols="12" v-show="includeTutors && !isAdult">
            <v-card>
              <v-card-title>
                <v-col>
                  <span class="headline no-split-words">
                    {{ $t("student.prop.tutors") }}
                    <span style="color: red"> * </span>
                  </span>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    class="primary ml-2"
                    @click="addTutor()"
                    :disabled="tutors.length > 1"
                  >
                    <v-icon>mdi-plus</v-icon>
                    <span class="d-none d-sm-block">
                      {{ $t("tutor.form.add_tutor") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-card-title>
              <v-card-text>
                <v-row v-for="(_, i) in tutors" :key="i">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      class="error ml-2"
                      @click="removeTutor(i)"
                      :disabled="tutors.length < 2"
                    >
                      <v-icon>remove</v-icon>
                      <span class="d-none d-sm-block">
                        {{ $t("tutor.form.remove_tutor") }}
                      </span>
                    </v-btn>
                  </v-col>
                  <tutor-form
                    v-model="tutors[i]"
                    @validation-update="updateTutorValidation(i, ...arguments)"
                  ></tutor-form>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import TimeZoneSelector from "@/components/debouncing-inputs/TimeZoneSelector.vue";
import LanguageSelector from "@/components/debouncing-inputs/LanguageSelector.vue";
import regex from "@/common/regex-validation";
import TutorForm from "@/mockups/tutor/components/TutorForm";
import ImageEdit from "@/components/profile/ImageEdit.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { translate } from "@/common/translation-utils";
import genres from "@/enumerates/Genre";
import NumberField from "@/components/number-field/NumberField";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

const fullAge = 18;

export default {
  name: "studentForm",
  components: {
    DayPicker,
    TutorForm,
    TimeZoneSelector,
    LanguageSelector,
    ImageEdit,
    NumberField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    accountFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    backBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    includeTutors: {
      type: Boolean,
      required: false,
      default: false,
    },
    registerFields: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      validForm: true,
      validTutors: [true],
      entity: {},
      tutors: [{}],
      genres: genres,
      genreLoading: false,
      timeZones: [],
      timeZonesLoading: false,
      hidePassword1: true,
      hidePassword2: true,
      image: {
        loading: false,
        photo: require("@/assets/icon_visits.png"),
      },
      regex,
    };
  },
  computed: {
    ...mapAuthGetter(["isStudent", "isStudentCandidate", "isAdmin"]),
    isAdult() {
      if (this.entity.birthdate) {
        let birthdate = this.entity.birthdate;
        let today = new Date();
        let monthDiff = today.getMonth() - (birthdate[1] - 1);
        let age = today.getFullYear() - birthdate[0];
        if (monthDiff < 0 || (monthDiff == 0 && today.getDate() > birthdate[2]))
          age--;
        return age >= fullAge;
      } else return true;
    },
    validStudent() {
      if (!this.includeTutors || this.isAdult) return this.validForm;
      else return this.validForm && this.validTutors.every((v) => v == true);
    },
    passwordConfirmationRule() {
      return () =>
        this.entity.password === this.entity.password2 ||
        this.$t("account.form.validation.password_match");
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.entity = newVal;
          if (newVal.userId && (!oldVal || oldVal.userId != newVal.userId))
            UserDataEntityRepository.getPhoto(newVal.userId)
              .then((data) => {
                if (data.size > 0) {
                  this.image.photo = URL.createObjectURL(data);
                }
              })
              .catch(() =>
                this.$log.debug(
                  "Error downloading profile photo for user " + newVal.userId
                )
              );
        }
      },
      immediate: true,
    },
  },
  methods: {
    addTutor() {
      if (this.tutors.length < 2) {
        this.tutors.push({});
        this.validTutors.push(false);
      }
    },
    removeTutor(index) {
      if (this.tutors.length > 1) {
        this.tutors.splice(index, 1);
        this.validTutors.splice(index, 1);
      }
    },
    updateTutors() {
      this.entity.tutors = this.isAdult ? null : this.tutors;
    },
    updateTutorValidation(index, value) {
      this.$set(this.validTutors, index, value);
    },
    choosePhoto() {
      this.$refs.fileLoader.click();
    },
    deletePhoto() {
      this.entity.photo = null;
      this.image.photo = require("@/assets/icon_visits.png");
    },
    translate,
  },
};
</script>
