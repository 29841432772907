<template>
  <div ref="wrapper"></div>
</template>

<script>
import { mapClassroomStateGetter } from "@/components/classroom/store/mapClassroomStateGetter";

const participants = [];

export default {
  name: "ClassroomVideoMultiple",
  props: {
    zoom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      participants: [],
    };
  },
  computed: {
    ...mapClassroomStateGetter(["sessionState"]),
  },
  methods: {
    async addParticipant(participant) {
      this.$log.debug(participant);

      const videoDiv = document.createElement("div");
      videoDiv.classList.add("clrm-video", "clrm-video--border-radius");
      this.$refs.wrapper.appendChild(videoDiv);

      const canvasWrapper = document.createElement("div");
      canvasWrapper.classList.add("clrm-video__wrapper");
      videoDiv.appendChild(canvasWrapper);

      const canvasElement = document.createElement("canvas");
      canvasElement.width = 320;
      canvasElement.height = 180;
      canvasElement.classList.add("clrm-video__source");
      canvasWrapper.appendChild(canvasElement);

      const nameDiv = document.createElement("div");
      nameDiv.classList.add("clrm-video__title");
      nameDiv.innerText = participant.name;
      videoDiv.appendChild(nameDiv);

      await this.zoom.renderVideo(canvasElement, participant.cameraOnId, {
        canvasSize: { width: 320, height: 180 },
        position: [0, 0],
      });

      participants.push({
        cameraOnId: participant.cameraOnId,
        participant: participant,
        videoDiv,
        canvasElement,
      });

      return true;
    },
    async removeParticipant(participant) {
      this.$log.debug(participant);

      const toRemove = participants.find(
        (p) => p.cameraOnId == participant.cameraOnId
      );
      await this.zoom.stopRenderVideo(
        toRemove.canvasElement,
        toRemove.participant.cameraOnId
      );
      toRemove.videoDiv.remove();
      participants.splice(
        participants.findIndex((p) => p.cameraOnId == participant.cameraOnId),
        1
      );

      return true;
    },
  },
};
</script>
