<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between">
        <v-col class="mx-4 d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t("lectureChange.headers.form") }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="back" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="!validLectureChange"
            class="success mr-2"
            @click="save"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-form v-model="validForm">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" :sm="'12'" class="text-center">
            <v-row no-gutters>
              <v-col cols="12" :md="'12'">
                <v-select
                  v-model="lectureChange.teacherId"
                  :items="teachers"
                  :label="$t('lectureChange.prop.teacher')"
                  :item-text="fullName"
                  item-value="id"
                  outlined
                  dense
                  class="required"
                  :rules="[
                    (v) => !!v || $t('lecture.error.change.teacher_required'),
                  ]"
                ></v-select>

                <v-textarea
                  outlined
                  v-model="lectureChange.reason"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('lecture.error.change.reason_required'),
                  ]"
                  :label="$t('lectureChange.prop.reason')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TeacherEvaluationEntityRepository = RepositoryFactory.get(
  "TeacherEvaluationEntityRepository"
);

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

export default {
  name: "LectureChangeForm",
  props: {
    lecture: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lectureChange: {},
      loading: false,
      validForm: true,
      teacherLoading: false,
      teachers: [],
    };
  },
  created() {
    this._fetchTeachers();
  },
  computed: {
    validLectureChange() {
      return this.validForm;
    },
  },
  methods: {
    _fetchTeachers() {
      this.teacherLoading = true;
      return TeacherEntityRepository.getAvailableTeachersForLecture(
        this.lecture.id
      )
        .then((response) => {
          this.teachers = response;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching available teachers for lecture " + this.lecture.id
          )
        )
        .finally(() => (this.teacherLoading = false));
    },
    back() {
      this.$emit("cancel");
    },
    save() {
      this.loading = true;
      this.lectureChange.lectureId = this.lecture.id;
      LectureEntityRepository.changeTeacher(this.lecture.id, this.lectureChange)
        .then((res) => {
          this.$notify({
            title: this.$t("lectureChange.messages.create_success.title"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", res);
        })
        .catch(() =>
          this.$log.debug("Error changing lecture: " + this.lectureChange)
        )
        .finally(() => (this.loading = false));
    },
    fullName(item) {
      return item.name + " " + item.surname;
    },
  },
};
</script>
