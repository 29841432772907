var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-1 white--text"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("activity.headers.form"))+" ")])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-alert',{attrs:{"outlined":"","color":_vm.showSelectedError ? 'red darken-2' : 'grey darken-2'}},[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.$t("activity.messages.selected")))])]),(_vm.activitiesSelected && _vm.activitiesSelected.length > 0)?_c('v-card-text',[_c('activities-selected-list',{attrs:{"activitiesSelected":_vm.activitiesSelected,"removeActivity":_vm.removeActivity}}),(!_vm.lecture.levelTest)?_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('lecture.prop.homework'),"disabled":_vm.checkIfLink()},model:{value:(_vm.homework),callback:function ($$v) {_vm.homework=$$v},expression:"homework"}})],1),(_vm.homework)?_c('v-col',{staticClass:"mt-4"},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
                        data: _vm.date.endDate,
                        label: 'teacherLecture.prop.endTime',
                      },"timePickerProp":{
                        data: _vm.date.endTime,
                        label:
                          'teacher.tabs.schedules.unavailabilityDialog.timeEnd',
                      }},on:{"update-time":_vm.updateDateTimeEnd}}),(_vm.showDateError)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("lectureActivity.error.past-date"))+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()],1):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("activity.messages.no_selected"))+" ")])],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"secondary my-4"}),_c('activity-simple-list',{attrs:{"language":_vm.lecture.languageId,"level":_vm.lecture.levelId,"present-activities":_vm.presentActivities},on:{"selected":_vm.selectActivity}})],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.cancelEdit()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("cancel")]),_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")],1),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.validActivity},on:{"click":function($event){return _vm.saveActivity()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")],1)],1)],1)],1):_c('loading-page')}
var staticRenderFns = []

export { render, staticRenderFns }