var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1s",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.teacher",fn:function(ref){
var item = ref.item;
return [(item.teacher)?_c('router-link',{attrs:{"to":{
              name: 'TeacherEducationTab',
              params: { id: item.teacher.id },
            }}},[_vm._v(" "+_vm._s(_vm.teacherFullName(item.teacher))+" ")]):_vm._e()]}},{key:"item.file",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":item.file === null,"icon":"","small":"","loading":_vm.loadingDownloadFile},on:{"click":function($event){return _vm.downloadFile(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("preview")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("merit.actions.download")))])])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","loading":_vm.loading,"icon":"","small":""},on:{"click":function($event){return _vm.validateDiploma(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" thumb_up ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("merit.actions.approve")))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }