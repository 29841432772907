<template>
  <v-container fluid v-if="items">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block" cols="6">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col
            cols="12"
            md="6"
            order="2"
            order-sm="1"
            class="text-center text-md-right mt-4 mt-sm-0"
          >
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="space-between">
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: dateFilter,
                label: 'productRequest.prop.date',
              }"
              @update-time="updateDateTime('dateFilter', false, ...arguments)"
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: limitDateFilter,
                label: 'productRequest.prop.limitDate',
              }"
              @update-time="
                updateDateTime('limitDateFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <number-field
              :debouncing="300"
              @input="redirectOnFilterChange"
              v-model="classesPerWeekFilter"
              type="integer"
              :label="$t('productRequest.prop.classesPerWeek')"
            ></number-field>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <v-select
              dense
              @change="redirectOnFilterChange"
              clearable
              :items="requestStatus"
              :item-text="translate"
              :menu-props="{ offsetY: true }"
              v-model="stateFilter"
              :label="$t('levelTestRequest.prop.state')"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <autocomplete
              dense
              no-filter
              solo
              :debouncing="300"
              @change="redirectOnFilterChange"
              :items="products.items"
              :loading="products.loading"
              :search-input.sync="productSearch"
              v-model="productFilter"
              :label="$t('productRequest.prop.product')"
              item-text="title"
              item-value="id"
            >
            </autocomplete>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <autocomplete
              dense
              no-filter
              solo
              :debouncing="300"
              @change="redirectOnFilterChange"
              :items="students.items"
              :loading="students.loading"
              :search-input.sync="studentSearch"
              v-model="studentFilter"
              :label="$t('productRequest.prop.student')"
              item-text="displayName"
              item-value="id"
            >
            </autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span v-if="item.date">{{ item.date | dateTimeWithoutTz }}</span>
            <span v-else>--</span>
          </template>

          <template v-slot:[`item.limitDate`]="{ item }">
            <span v-if="item.limitDate">
              {{ item.limitDate | dateTimeWithoutTz }}
            </span>
            <span v-else>--</span>
          </template>

          <template v-slot:[`item.product`]="{ item }">
            <router-link
              v-if="item.product"
              :to="{
                name: 'Product Detail',
                params: { id: item.product.id },
              }"
            >
              {{ item.product.title }}
            </router-link>
          </template>

          <template v-slot:[`item.requester`]="{ item }">
            <span v-if="item.company">
              <v-icon color="primary" class="mr-2">mdi-domain</v-icon>
              <router-link
                :to="{
                  name: 'CompanyView Detail',
                  params: { id: item.company.id },
                }"
              >
                {{ item.company.companyName }}
              </router-link>
            </span>
            <span v-else-if="item.student">
              <v-icon color="primary" class="mr-2">mdi-account</v-icon>
              <router-link
                :to="{
                  name: 'StudentView Detail',
                  params: { id: item.student.id },
                }"
              >
                {{ item.student.name }}
                {{ item.student.surname }}
              </router-link>
            </span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            {{ $t(`productRequest.state.${item.state}`) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <component
              :is="getDialogComponent(item)"
              :product-request="item"
              @scheduled="getItems"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        large
                        v-if="item.state === 'REQUESTED'"
                        icon
                        color="primary"
                      >
                        <v-icon> mdi-calendar </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("actions.accept") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </component>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.state === 'REQUESTED'"
                  v-bind="attrs"
                  v-on="on"
                  large
                  :loading="loadingRow[item.id]"
                  icon
                  color="error"
                  @click.stop="rejectRequest(item)"
                >
                  <v-icon> cancel </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.reject") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import NumberField from "@/components/number-field/NumberField.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import ProgramCourseDialog from "./components/ProgramCourseDialog";
import ProgramCompanyCourseDialog from "./components/ProgramCompanyCourseDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import requestStatus from "@/enumerates/ProductRequestState";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateArrayToDateString } from "@/common/conversion-utils";
import { translate } from "@/common/translation-utils";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const ProductRequestEntityRepository = RepositoryFactory.get(
  "ProductRequestEntityRepository"
);

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

export default {
  name: "ProductRequestsList",
  components: {
    DateAndHourPicker,
    Autocomplete,
    NumberField,
    ProgramCourseDialog,
    ProgramCompanyCourseDialog,
  },
  data() {
    return {
      items: [],
      stateFilter: "REQUESTED",
      showFilters: false,
      productSearch: null,
      studentSearch: null,
      dateFilter: null,
      limitDateFilter: null,
      classesPerWeekFilter: null,
      productFilter: null,
      studentFilter: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      products: {
        items: [],
        loading: false,
      },
      students: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      loadingRow: [],
      requestStatus,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("productRequest.prop.requester"),
          value: "requester",
        },
        {
          text: this.$t("productRequest.prop.date"),
          value: "date",
        },
        {
          text: this.$t("productRequest.prop.limitDate"),
          value: "limitDate",
        },
        {
          text: this.$t("productRequest.prop.product"),
          value: "product",
        },
        {
          text: this.$t("productRequest.prop.classesPerWeek"),
          value: "classesPerWeek",
        },
        {
          text: this.$t("productRequest.prop.state"),
          value: "state",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    filters() {
      let filters = "";
      filters =
        filters +
        (this.dateFilter
          ? "date:" + dateArrayToDateString(this.dateFilter) + ","
          : "");

      filters =
        filters +
        (this.stateFilter != null && this.stateFilter !== ""
          ? "state:" + this.stateFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.limitDateFilter
          ? "limitDate:" + dateArrayToDateString(this.limitDateFilter)
          : "");

      filters = filters + (this.limitDateFilter ? "," : "");

      filters =
        filters +
        (this.classesPerWeekFilter != null && this.classesPerWeekFilter !== ""
          ? "classesPerWeek:" + this.classesPerWeekFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.productFilter
          ? "product.id:" + this.productFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.studentFilter
          ? "student.id:" + this.studentFilter.toString() + ","
          : "");

      return filters !== "" ? filters : null;
    },
  },
  watch: {
    productSearch: {
      handler() {
        this.getProductItems();
      },
    },
    studentSearch: {
      handler() {
        this.getStudentItems();
      },
    },
  },
  created() {
    //Setting route params
    if (
      this.$route.query.stateFilter &&
      this.$route.query.stateFilter !== "null"
    ) {
      this.showFilters = true;
      this.stateFilter = this.$route.query.stateFilter;
    }
    if (this.$route.query.stateFilter === "null") this.stateFilter = null;

    if (this.$route.query.dateFilter) {
      this.showFilters = true;
      this.dateFilter = this.$route.query.dateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.limitDateFilter) {
      this.showFilters = true;
      this.limitDateFilter = this.$route.query.limitDateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.classesPerWeekFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.classesPerWeekFilter);
      this.classesPerWeekFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.productFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.productFilter);
      this.productFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.studentFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.studentFilter);
      this.studentFilter = isNaN(value) ? null : value;
    }

    this.getItems();
    this.getProductItems();
    this.getStudentItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        requester: "student.userData.user.firstName",
        product: "product.title",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage, sortMapping),
        },
      };
      ProductRequestEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching product request list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    getProductItems() {
      this.products.loading = true;
      const options = {
        params: {
          search: this.productSearch,
        },
      };
      ProductEntityRepository.getAll(options)
        .then((response) => (this.products.items = response.content))
        .catch(() =>
          this.$log.debug("Error fetching products with params: " + options)
        )
        .finally(() => (this.products.loading = false));
    },
    getStudentItems() {
      this.students.loading = true;
      const options = {
        params: {
          search: this.studentSearch,
        },
      };
      StudentEntityRepository.getAll(options)
        .then((response) => (this.students.items = response.content))
        .catch(() =>
          this.$log.debug("Error fetching students with params: " + options)
        )
        .finally(() => (this.students.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "Admin Product Request List",
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);

      this.changeQueryFilters(query);

      this.redirect(query);
    },
    changeQueryFilters(query) {
      query.dateFilter = this.dateFilter
        ? dateArrayToDateString(this.dateFilter)
        : undefined;

      query.limitDateFilter = this.limitDateFilter
        ? dateArrayToDateString(this.limitDateFilter)
        : undefined;

      query.classesPerWeekFilter =
        this.classesPerWeekFilter != null
          ? this.classesPerWeekFilter.toString()
          : undefined;

      query.stateFilter =
        this.stateFilter != null ? this.stateFilter.toString() : "null";

      query.productFilter = this.productFilter
        ? this.productFilter.toString()
        : undefined;

      query.studentFilter = this.studentFilter
        ? this.studentFilter.toString()
        : undefined;
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    rejectRequest(item) {
      this.$set(this.loadingRow, item.id, true);
      const itemToSend = { ...item, state: "REJECTED" };
      ProductRequestEntityRepository.updateState(itemToSend)
        .then(() => {
          this.$notify({
            title: this.$t("levelTestRequest.messages.rejected_success"),
            type: "success",
          });
          if (this.entitiesPage.page !== 1 && this.items.length === 1) {
            this.entitiesPage.page -= 1;
          } else {
            this.getItems();
          }
        })
        .catch(() =>
          this.$log.debug("Error updating product request state: " + item)
        )
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
    getDialogComponent(item) {
      return item.product.recipientType === "COMPANY"
        ? "ProgramCompanyCourseDialog"
        : "ProgramCourseDialog";
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    translate,
  },
};
</script>
