<template>
  <v-autocomplete
    v-model="wordlistSelected"
    :append-icon="append_icon"
    :clearable="clearable"
    :dense="dense"
    :items="wordlists"
    :label="label"
    :loading="loading"
    :outlined="outlined"
    :rules="rules"
    :search-input.sync="searchInput"
    item-value="id"
    no-filter
    return-object
  >
    <template v-slot:selection="{ item }">
      <span>{{ item.name }}</span>
      <v-chip
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.level.name }}</span>
      </v-chip>
      <v-chip
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.level.language.name }}</span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <span class="font-weight-bold mr-2">{{ item.name }}</span>
      <v-chip
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.level.name }}</span>
      </v-chip>
      <v-chip
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.level.language.name }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WordlistEntityRepository = RepositoryFactory.get(
  "WordlistEntityRepository"
);

export default {
  name: "WordlistSelector",
  props: {
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: function () {
        this.$t("wordlist.name");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    requestParams: {
      type: Object,
      default: () => {
        params: {
        }
      },
      required: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      searchInput: null,
      wordlists: [],
      wordlistSelected: null,
    };
  },
  watch: {
    requestParams: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    searchInput: {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
    value: {
      handler(newValue) {
        this.wordlistSelected = newValue;
      },
      immediate: true,
    },
    wordlistSelected(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.wordlistSelected = this.value;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      const options = { ...this.requestParams };
      options.params.search = this.searchInput;
      WordlistEntityRepository.getAll(options)
        .then((res) => {
          this.wordlists = res.content;
          if (
            this.wordlistSelected &&
            !this.wordlists.find((w) => w.id === this.wordlistSelected.id)
          ) {
            this.wordlistSelected = null;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>

<style scoped></style>
