<template>
  <v-select
    :items="paymentTypeItems"
    v-model="paymentType"
    :label="label"
    :outlined="outlined"
    :dense="dense"
    :rules="rules"
    :disabled="disabled"
  >
    <template v-slot:selection="{ item }">
      <span>{{ $t(`product.payment_type.${item}`) }}</span>
    </template>
    <template v-slot:item="{ item }">
      <span>{{ $t(`product.payment_type.${item}`) }}</span>
    </template>
  </v-select>
</template>

<script>
import { paymentTypeConst } from "./productConst";

export default {
  name: "PaymentTypeSelector",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: function () {
        this.$t("product.payment_type.label");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.paymentType = newValue;
      },
      immediate: true,
    },
    paymentType(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.paymentType = this.value;
  },
  data() {
    return {
      paymentTypeItems: paymentTypeConst,
      paymentType: null,
    };
  },
};
</script>

<style></style>
