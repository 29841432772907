import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "user-management/users";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(RESOURCE_NAME, options);
    return response.data;
  },

  async getRegistrated(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/registrated`, options);
    return response.data;
  },

  async save(user) {
    return (await HTTP.put(RESOURCE_NAME, user)).data;
  },

  async resetUserPassword(login) {
    const loginDTO = { login: login };
    return (await HTTP.post("account/reset_password/init", loginDTO)).data;
  },
  async unregister(userId) {
    return await HTTP.delete(`${RESOURCE_NAME}/${userId}`);
  },
  async isUnableToUnregister(userId) {
    return await HTTP.get(`${RESOURCE_NAME}/is-unable-to-unregister/${userId}`);
  },
};
