import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/words";

export default {
  async getAll(options = {}) {
    // Setting default sort
    if (!options.params?.sort) {
      options.params = { ...options.params, sort: "name,asc" };
    }
    return (await HTTP.get(`${RESOURCE_NAME}`, options)).data;
  },

  async getAllByLevelAndLanguage(options = {}) {
    // Setting default sort
    if (!options.params?.sort) {
      options.params = { ...options.params, sort: "name,asc" };
    }
    return (await HTTP.get(`${RESOURCE_NAME}/level`, options)).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
