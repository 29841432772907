<template>
  <v-container fluid>
    <teacher-form
      account-fields
      v-if="!loading"
      v-model="entity"
      @save="save"
      @back="back"
    ></teacher-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>
<script>
import TeacherForm from "@/mockups/teachers/components/TeacherForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { deleteTempFile } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
export default {
  name: "TeacherCreateForm",
  components: { TeacherForm, LoadingPage },
  data() {
    return {
      entity: {
        acceptCV: true,
        acceptRGPD: true,
      },
      loading: false,
    };
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  methods: {
    back() {
      this.$router.push({
        name: "Candidate teachers list",
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      TeacherEntityRepository.saveCandidate(this.entity)
        .then((data) => {
          this.entity.photo = null;
          this.$router.push({
            name: "TeacherView Detail",
            params: {
              backAction: this.$route.params.backPrevious,
              id: data.id,
            },
          });
        })
        .catch(() => this.$log.debug("Error saving teachers: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
