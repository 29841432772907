<template>
  <v-select
    :items="classDurationItems"
    v-model="classDuration"
    :append-icon="append_icon"
    :label="label"
    :outlined="outlined"
    :dense="dense"
    :item-text="(item) => (item.name ? $t(item.name) : '')"
    :rules="rules"
  >
    <template v-slot:selection="{ item }">
      <span>{{ item }} min</span>
    </template>

    <template v-slot:item="{ item }">
      <span>{{ item }} min</span>
    </template>
  </v-select>
</template>

<script>
import { classDurationConst } from "./productConst";

export default {
  name: "ClassDurationSelector",
  props: {
    value: {
      type: Number,
    },
    label: {
      type: String,
      default: function () {
        this.$t("product.class_duration.label");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.classDuration = newValue;
      },
      immediate: true,
    },
    classDuration(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.classDuration = this.value;
  },
  data() {
    return {
      classDurationItems: classDurationConst,
      classDuration: null,
    };
  },
};
</script>

<style></style>
