<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="text-center text-h2">
            <information-panel
              :hasStudentLevelEvaluation="hasStudentLevelEvaluation"
              :hasLevelTestRequested="hasLevelTestRequested"
              :hasAvailabilities="hasAvailabilities"
              :hasBalance="hasBalance"
              :hasCourses="hasCourses"
            >
            </information-panel>
          </v-col>
          <v-col v-if="isAdult" cols="12" md="6" class="text-center">
            <v-row justify="center">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mt-13"
                  width="75%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('ProfileStudentBalanceTab')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="py-0 text-center">
                      <span>
                        <v-icon x-large color="primary">mdi-cash</v-icon>
                        {{ $t("student.dashboard.wallet") }}
                      </span>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span
                      class="font-weight-black text-overline text-md-body-2"
                    >
                      {{ $t("student.dashboard.wallet_balance") }}:
                    </span>
                    <span class="font-weight-black">
                      {{ $n(walletBalance, "currency") }}
                    </span>
                    <br />
                    <span class="text-overline">
                      {{ $t("student.dashboard.next_payment") }}:
                    </span>
                    <span class="payment">
                      {{ "-" }}
                      {{ $n(nextPayment, "currency") }}
                    </span>
                    <span>
                      ({{ lastDayOfMonth | dateTimeWithTz("short") }})
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-row>
          </v-col>
          <v-col cols="12" :md="isAdult ? 6 : 12" class="text-center">
            <!-- next leveltest -->
            <span class="text-h6" v-if="nextLevelTest">
              {{ $t("student.dashboard.next_level_test") }}
              <v-icon
                v-if="!expandNextLevelTest && nextLevelTest"
                @click="expandNextLevelTest = true"
              >
                expand_more
              </v-icon>
              <v-icon
                v-else-if="nextLevelTest"
                @click="expandNextLevelTest = false"
              >
                expand_less
              </v-icon>
            </span>
            <transition name="slide-fade" v-if="nextLevelTest">
              <v-container v-if="expandNextLevelTest" fluid>
                <v-row class="mt-2" justify="center">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="nextLevelTest.state === 'DOING' ? 'doing' : ''"
                      width="75%"
                      @click="navigate('Lecture Detail', nextLevelTest.id)"
                      :loading="loading"
                    >
                      <v-card-title class="py-0">
                        <v-col class="text-center">
                          <span class="no-split-words">
                            {{
                              nextLevelTest.language
                                ? $t("student.dashboard.level_test", {
                                    lang: $t(
                                      `languages.${nextLevelTest.language}`
                                    ),
                                  })
                                : "-"
                            }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <span class="text-overline">
                          <v-icon>mdi-human-male-board</v-icon>
                          {{ nextLevelTest.teacherName }}
                        </span>
                        <br />
                        <span class="text-overline">
                          {{
                            nextLevelTest.startTime | dateTimeWithTz("medium")
                          }}
                          -
                          {{ nextLevelTest.endTime | dateTimeWithTz("time") }}
                        </span>
                        <v-container
                          v-if="userCanJoin(nextLevelTest)"
                          class="py-0"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                @click.stop="joinToLecture(nextLevelTest)"
                              >
                                <v-icon color="success">login</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("lecture.join") }}</span>
                          </v-tooltip>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-row>
              </v-container>
            </transition>
            <br v-if="nextLevelTest" />
            <!-- next lectures -->
            <span class="text-h6">
              {{ $t("student.dashboard.next_lectures") }}
              <v-icon
                v-if="!expandNextLectures && nextLectures.length > 0"
                @click="expandNextLectures = true"
              >
                expand_more
              </v-icon>
              <v-icon
                v-else-if="nextLectures.length > 0"
                @click="expandNextLectures = false"
              >
                expand_less
              </v-icon>
            </span>
            <v-row v-if="loading" class="mt-12" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <transition name="slide-fade">
              <v-container v-if="expandNextLectures && !loading" fluid>
                <v-row
                  v-for="lecture in nextLectures"
                  :key="lecture.id"
                  class="mt-2"
                  justify="center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="lecture.state === 'DOING' ? 'doing' : ''"
                      width="75%"
                      @click="navigate('Lecture Detail', lecture.id)"
                    >
                      <v-card-title class="py-0">
                        <v-col class="text-center">
                          <span class="no-split-words">
                            {{ lecture.productName }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <span class="text-overline">
                          <v-icon>mdi-human-male-board</v-icon>
                          {{ lecture.teacherName }}
                        </span>
                        <br />
                        <span class="text-overline">
                          {{ lecture.startTime | dateTimeWithTz("medium") }} -
                          {{ lecture.endTime | dateTimeWithTz("time") }}
                        </span>
                        <br />
                        <router-link
                          v-if="lecture.homeworkCount > 0"
                          :to="{
                            name: 'ProfileStudentResultsTab',
                            query: { editionFilter: lecture.editionId },
                          }"
                        >
                          <span class="text-overline">
                            {{ $t("student.dashboard.pending_homework") }}:
                            {{ lecture.homeworkCount }}
                          </span>
                        </router-link>
                        <v-container v-if="userCanJoin(lecture)" class="py-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                @click.stop="joinToLecture(lecture)"
                              >
                                <v-icon color="success">login</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("lecture.join") }}</span>
                          </v-tooltip>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-row>
                <v-row
                  v-if="!nextLectures || nextLectures.length === 0"
                  class="mt-2"
                  justify="center"
                >
                  <span>
                    {{ $t("student.dashboard.no_next_lectures") }}
                  </span>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { joinToLecture, userCanJoin } from "@/common/lecture-common";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { dateToDateArray } from "@/common/conversion-utils";
import InformationPanel from "@/mockups/student/components/InformationPanel";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");
export default {
  components: { InformationPanel },
  data() {
    return {
      walletBalance: null,
      nextPayment: null,
      nextLectures: [],
      nextLevelTest: null,
      expandNextLectures: true,
      expandNextLevelTest: true,
      lastDayOfMonth: null,
      hasStudentLevelEvaluation: true,
      hasLevelTestRequested: true,
      hasAvailabilities: true,
      hasBalance: true,
      hasCourses: true,
      loading: false,
    };
  },
  created() {
    const today = new Date();
    this.lastDayOfMonth = dateToDateArray(
      new Date(today.getFullYear(), today.getMonth() + 1, 0)
    );
    this._fetchData();
  },
  computed: {
    ...mapAuthGetter(["isAdult"]),
  },
  methods: {
    _fetchData() {
      this.loading = true;
      DashboardRepository.getForStudent()
        .then((data) => {
          this.walletBalance = data.walletBalance;
          this.nextPayment = data.nextPayment;
          this.nextLectures = data.nextLectures;
          this.nextLevelTest = data.nextLevelTest;
          this.hasStudentLevelEvaluation = data.hasStudentLevelEvaluation;
          this.hasLevelTestRequested = data.hasLevelTestRequested;
          this.hasAvailabilities = data.hasAvailabilities;
          this.hasBalance = data.hasBalance;
          this.hasCourses = data.hasCourses;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    navigate(routeName, entityId) {
      this.$router.push({
        name: routeName,
        params: { id: entityId, backPrevious: true },
      });
    },
    userCanJoin,
    joinToLecture,
  },
};
</script>

<style scoped>
.payment {
  color: red;
}
.doing {
  border: 2px solid #00a451;
}
</style>
