<template>
  <component :is="defaultStyle" v-if="listParticipantOpened">
    <hsc-window
      class="list-participants__window"
      :resizable="true"
      :isScrollable="true"
      :width="width"
      :height="height"
      :left="xpos"
      :top="ypos"
      :maxHeight="height"
      :minHeight="height"
    >
      <div class="list-participants__container">
        <div
          class="list-participants__header"
          @mousedown.stop.prevent="mousedown"
        >
          <div class="list-participants__header__title">
            <span>{{ $t("participant-list.title") }}</span>
          </div>
          <div class="list-participants__header__close">
            <button
              type="button"
              class="list-participants__header__button"
              @click="closeWindow()"
            >
              <icon
                name="close"
                width="16"
                height="16"
                :title="$t('participant-list.close')"
              ></icon>
            </button>
          </div>
        </div>
        <div class="list-participants__content">
          <div
            class="list-participants__user"
            v-for="p in participants"
            :key="p.userId"
          >
            <div class="list-participants__user__icon">
              <v-avatar size="40px">
                <img v-if="userImages[p.userId]" :src="userImages[p.userId]" />
              </v-avatar>
            </div>
            <div class="list-participants__user__name">
              {{ p.name }} - {{ $t("participant-list." + p.role) }}
            </div>
            <div
              class="list-participants__user__buttons"
              v-if="sessionState != null"
            >
              <span
                class="list-participants__user__name"
                v-if="p.devices.length != 0"
                >{{ $t("participant-list.online") }}</span
              >
              <span
                class="list-participants__user__name"
                v-if="p.devices.length == 0"
                >{{ $t("participant-list.offline") }}</span
              >
              <!-- make host -->
              <!-- <button
                type="button"
                class="list-participants__user__button"
                :title="$t('participant-list.actions.make-host')"
                v-if="!p.host && classroomMode == 'teacher'"
                @click="makeHost(p)"
              >
                <icon
                  name="editor-face-focus"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.make-host')"
                ></icon>
              </button> -->

              <!-- kick -->
              <button
                type="button"
                class="list-participants__user__button"
                :title="$t('participant-list.actions.kick')"
                v-if="p.role != 'TEACHER' && classroomMode == 'teacher'"
                @click="kickUser(p)"
              >
                <icon
                  name="kick"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.kick')"
                ></icon>
              </button>

              <!-- mute -->
              <button
                type="button"
                class="list-participants__user__button"
                :title="$t('participant-list.actions.mute')"
                v-if="
                  classroomMode == 'teacher' &&
                  p.unmutedId &&
                  p.devices.length > 0
                "
                @click="muteUser(p)"
              >
                <icon
                  name="microphone"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.mute')"
                ></icon>
              </button>

              <!-- mute status -->
              <button
                type="button"
                class="list-participants__user__button no-pointer"
                :class="{ disabled: !p.unmutedId }"
                v-if="
                  p.devices.length > 0 &&
                  (classroomMode != 'teacher' || !p.unmutedId)
                "
              >
                <icon
                  name="microphone"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.mute')"
                ></icon>
              </button>

              <!-- stopUserCamera -->
              <button
                type="button"
                class="list-participants__user__button"
                :title="$t('participant-list.actions.stop-camera')"
                v-if="
                  classroomMode == 'teacher' &&
                  p.cameraOnId &&
                  p.devices.length > 0
                "
                @click="stopUserCamera(p)"
              >
                <icon
                  name="camera"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.stop-camera')"
                ></icon>
              </button>

              <!-- camera status -->
              <button
                type="button"
                class="list-participants__user__button no-pointer"
                :class="{ disabled: !p.cameraOnId }"
                v-if="
                  p.devices.length > 0 &&
                  (classroomMode != 'teacher' || !p.cameraOnId)
                "
              >
                <icon
                  name="camera"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.stop-camera')"
                ></icon>
              </button>

              <!-- stopUserSharing -->
              <button
                type="button"
                class="list-participants__user__button"
                :title="$t('participant-list.actions.stop-sharing')"
                v-if="
                  classroomMode == 'teacher' &&
                  p.sharerOnId &&
                  p.devices.length > 0
                "
                @click="stopUserSharing(p)"
              >
                <icon
                  name="share-screen"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.stop-sharing')"
                ></icon>
              </button>

              <!-- sharing status -->
              <button
                type="button"
                class="list-participants__user__button no-pointer"
                :class="{ disabled: !p.sharerOnId }"
                v-if="
                  p.devices.length > 0 &&
                  (classroomMode != 'teacher' || !p.sharerOnId)
                "
              >
                <icon
                  name="share-screen"
                  width="18"
                  height="18"
                  :title="$t('participant-list.actions.stop-sharing')"
                ></icon>
              </button>
            </div>
            <button
              v-if="showChatIcon(p)"
              type="button"
              class="list-participants__user__button no-pointer"
              @click="getChannel(p.userId)"
            >
              <icon
                name="chat-simple"
                width="18"
                height="18"
                title="chat"
              ></icon>
            </button>
          </div>
        </div>
      </div>
    </hsc-window>
  </component>
</template>

<script>
import Vue from "vue";
import VueWindow from "@hscmap/vue-window";
import { mapClassroomStateGetter } from "@/components/classroom/store/mapClassroomStateGetter";
import ParticipantSessionStatus from "@/enumerates/ParticipantSessionStatus";
import ChatRepository from "@/repositories/components/ChatRepository";
import { mapAuthGetter } from "@/common/mapAuthGetter";

Vue.use(VueWindow);
export default {
  name: "ClrmParticipantsList",
  props: {
    zoom: {
      type: Object,
      required: true,
    },
    initialWidth: {
      type: Number,
      default: 640,
    },
    initialHeight: {
      type: Number,
      default: 480,
    },
    classroomMode: {
      type: String,
      default: "student",
    },
  },
  data() {
    return {
      listParticipantOpened: false,
      defaultStyle: null,
      width: this.initialWidth,
      height: this.initialHeight,
      xpos: null,
      ypos: null,
      sessionStatusEnum: ParticipantSessionStatus,
    };
  },
  mounted() {
    this.defaultStyle = VueWindow.StyleFactory({
      window: {
        color: "#000",
        backgroundColor: "transparent",
        borderRadius: "12px",
      },
      titlebar: {
        pointerEvents: "none",
      },
    });
  },
  computed: {
    ...mapClassroomStateGetter(["participants", "sessionState", "userImages"]),
    ...mapAuthGetter(["isAnyTeacher", "isAnyStudent"]),
  },
  methods: {
    init() {
      this.mainEl = document.getElementById("main");
      if (!this.mainEl) {
        this.mainEl = document.getElementById("classroom-layout");
      }
      this.listParticipantOpened = true;
      this.resetWindowPosAndSize();
    },
    resetWindowPosAndSize() {
      // Centramos en la ventana
      this.xpos = (this.mainEl.clientWidth - this.width) / 2;
      this.ypos = (this.mainEl.clientHeight - this.height) / 2;
    },
    async mousedown(e) {
      const baseLeft = this.xpos;
      const baseTop = this.ypos;
      const mousemove = (e2) => {
        this.xpos = baseLeft + e2.clientX - e.clientX;
        this.ypos = baseTop + e2.clientY - e.clientY;
        if (this.ypos < -9) {
          this.ypos = -9;
        }
      };
      const mouseup = async () => {
        document.removeEventListener("mousemove", mousemove);
        document.removeEventListener("mouseup", mouseup);
      };
      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);
    },
    makeHost(participant) {
      this.zoom.makeHost(participant.userId);
    },
    kickUser(participant) {
      this.zoom.kickUser(participant.userId);
    },
    muteUser(participant) {
      this.zoom.muteUser(participant.userId);
    },
    stopUserCamera(participant) {
      this.zoom.stopUserCamera(participant.userId);
    },
    stopUserSharing(participant) {
      this.zoom.stopUserSharing(participant.userId);
    },
    closeWindow() {
      this.listParticipantOpened = false;
    },
    getChannel(participantId) {
      const options = {
        params: {
          userIds: participantId,
          type: "STUDENT_TEACHER",
        },
      };
      ChatRepository.getChannelsByUserAndType(options)
        .then((response) => {
          if (response.content.length > 0) {
            this.$emit("open-chat", response.content[0].id);
          }
        })
        .catch((e) => {
          this.$log.debug(
            "Error gettin USER_ADMINS chat for user with id " + this.userId
          );
        });
    },
    showChatIcon(participant) {
      if (this.isAnyTeacher) {
        return participant.role === "STUDENT";
      } else if (this.isAnyStudent) {
        return participant.role === "TEACHER";
      } else return false;
    },
  },
};
</script>

<style scoped>
.no-pointer {
  cursor: default;
}
</style>
