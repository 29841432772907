<template>
  <v-dialog v-if="dialog" persistent width="80%" :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline error white--text">
        {{ $t("edition.unregister.dialog.title") }}
      </v-card-title>
      <v-card-text class="mt-4">
        <label
          v-html="
            $t('edition.unregister.dialog.text', {
              name: student.name,
              surname: student.surname,
            })
          "
        ></label>
      </v-card-text>
      <v-card-text>
        <v-form v-model="form" ref="form">
          <day-picker
            v-model="dateSelected"
            calendarColor="error"
            futureDates
            :label="$t('registration.prop.withdrawConfDate')"
            outlined
            :pastDates="false"
            :rules="datePickerRules"
          ></day-picker>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('cancel')">
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn
          class="mx-4 error"
          :disabled="!form"
          @click="dropSelectedRegistration"
        >
          {{ $t("actions.accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  dateArrayToDate,
  dateArrayToDateString,
} from "@/common/conversion-utils";

const RegistrationEntityRepository = RepositoryFactory.get(
  "RegistrationEntityRepository"
);

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const today = new Date();
const defaultDate = today
  .toLocaleDateString(undefined, dateOptions)
  .split("/")
  .reverse()
  .map((v) => parseInt(v));

/**
 * Events emitted: cancel, submit
 */
export default {
  name: "EditionUnregisterDialog",
  components: { DayPicker },
  props: {
    editionId: {
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateSelected: this.student.registrationWithdrawalConfDate || defaultDate,
      dialog: true,
      form: null,
    };
  },
  computed: {
    datePickerRules() {
      /* We use dateSelected to do the checks because the received value changes with the language and
        produces errors */
      let rules = [(v) => !!v || this.$t("edition.unregister.error.required")];
      rules.push(
        (v) =>
          !v ||
          dateArrayToDate(this.dateSelected) >=
            new Date(
              today.getUTCFullYear(),
              today.getUTCMonth(),
              today.getUTCDate()
            ) ||
          this.$t("edition.unregister.error.invalid_date")
      );
      return rules;
    },
  },
  methods: {
    dropSelectedRegistration() {
      const options = {
        params: {
          studentId: this.student.id,
          editionId: this.editionId,
          date: dateArrayToDateString(this.dateSelected),
        },
      };
      RegistrationEntityRepository.unregisterStudentFromCourse(options)
        .then((res) => {
          this.$notify({
            title: this.$t("registration.messages.update-success"),
            type: "success",
          });
          this.$emit("requested", res);
        })
        .catch(() => {
          this.$log.debug("Error trying to withdrawal registration with id ");
          this.$notify({
            title: this.$t("registration.messages.update-error"),
            type: "error",
            duration: 30000,
          });
        });
    },
  },
};
</script>
