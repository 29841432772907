import store from "./store";

const state = store.state;

function getSessionState() {
  return state.sessionState;
}

function getClassroomViewMode() {
  return state.classroomViewMode;
}

function getRecording() {
  return state.recording;
}

function getParticipants() {
  return state.participants;
}

function getCurrentDevice() {
  return state.currentDevice;
}

function getCameras() {
  return state.hardwareDevices.cameras;
}

function getMicrophones() {
  return state.hardwareDevices.microphones;
}

function getSelectedCamera() {
  return state.hardwareDevices.selectedCamera;
}

function getSelectedMicrophone() {
  return state.hardwareDevices.selectedMicrophone;
}

function getDeviceSharing() {
  return state.deviceSharing;
}

function getUserImages() {
  return state.userImages;
}

function getSessions() {
  return state.sessions;
}

function getAudioActive() {
  return state.audioActive;
}

export default {
  getSessionState,
  getClassroomViewMode,
  getRecording,
  getParticipants,
  getCurrentDevice,
  getCameras,
  getMicrophones,
  getSelectedCamera,
  getSelectedMicrophone,
  getDeviceSharing,
  getUserImages,
  getSessions,
  getAudioActive,
};
