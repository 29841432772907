<template>
  <div class="clrm-body">
    <div class="clrm-container-fluid">
      <Classroom
        :classroomInfo="classroomInfo"
        :zoom="zoom"
        ref="Classroom"
      ></Classroom>
    </div>
  </div>
</template>

<script>
import Classroom from "@/components/classroom/Classroom.vue";

export default {
  components: { Classroom },
  props: {
    classroomInfo: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  methods: {
    startVideo() {
      this.$refs.Classroom.startVideo();
    },
    openSettings() {
      this.$refs.Classroom.openSettings();
    },
  },
};
</script>

<style></style>
