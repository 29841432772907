import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/teacherSupervisorReviews";

export default {
  async getAllBySupervisedTeacher(id, options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/teacher/${id}`, options);
    return response.data;
  },
  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },
};
