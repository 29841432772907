<template>
  <v-container fluid>
    <student-form
      account-fields
      include-tutors
      v-if="!loading"
      v-model="entity"
      @save="save"
      @back="back"
    ></student-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>
<script>
import StudentForm from "@/mockups/student/components/StudentForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { deleteTempFile } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
export default {
  name: "StudentCreateForm",
  components: { StudentForm, LoadingPage },
  data() {
    return {
      entity: {
        acceptRGPD: true,
      },
      loading: false,
    };
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  methods: {
    back() {
      this.$router.push({
        name: "Candidate students list",
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      StudentEntityRepository.saveCandidate(this.entity)
        .then((data) => {
          this.entity.photo = null;
          this.$router.push({
            name: "StudentView Detail",
            params: {
              backAction: this.$route.params.backPrevious,
              id: data.id,
            },
          });
        })
        .catch(() => this.$log.debug("Error saving student: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
