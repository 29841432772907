<template>
  <v-container>
    <input
      v-if="!loading"
      hidden
      class="d-none"
      type="file"
      ref="interactionFileLoader"
      :accept="extensions.pdf"
      @change="saveFile"
    />
    <h1>{{ $t("student.tabs.results_homework.title") }}</h1>
    <v-row v-if="!loading">
      <v-col>
        <v-select
          dense
          solo
          clearable
          :items="editions"
          :loading="loadingEditions"
          v-model="editionFilter"
          :label="$t('activityInteraction.prop.edition')"
          item-text="title"
          item-value="id"
          @change="onFilterChange"
        >
        </v-select>
      </v-col>
      <v-col>
        <dateAndHourPicker
          :datePickerProp="{
            data: dateSolvedFilterStart,
            label: 'activityInteraction.filters.dateSolvedSince',
          }"
          @update-time="
            updateDateTime('dateSolvedFilterStart', false, ...arguments)
          "
        >
        </dateAndHourPicker>
      </v-col>
      <v-col>
        <dateAndHourPicker
          :datePickerProp="{
            data: dateSolvedFilterEnd,
            label: 'activityInteraction.filters.dateSolvedTill',
          }"
          @update-time="
            updateDateTime('dateSolvedFilterEnd', false, ...arguments)
          "
        >
        </dateAndHourPicker>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" class="text-center">
        <v-card class="mx-4" v-if="homework.length === 0">
          <v-card-title class="justify-center">
            {{ $t("student.tabs.results_homework.no_homework") }}
          </v-card-title>
        </v-card>
        <v-data-table
          v-else
          :headers="homeworkHeaders"
          :items="homework"
          :server-items-length="totalItems"
          hide-default-footer
          disable-sort
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t("student.tabs.results_homework.homework") }}
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.edition`]="{ item }">
            <a v-if="item.edition && isAdult" @click="goToEdition(item)">
              <span>{{ item.edition.title }}</span>
            </a>
            <span v-if="item.edition && !isAdult">{{
              item.edition.title
            }}</span>
            <span v-if="!item.edition">
              {{ $t("activityInteraction.level_test") }}
            </span>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <span v-if="item.dueDate">
              {{ item.dueDate | dateTimeWithTz("medium") }}
            </span>
          </template>
          <template v-if="!isTutor" v-slot:[`item.action`]="{ item }">
            <v-icon
              v-if="
                item.activity.resourceType === 'ACTIVITY' &&
                item.activity.typeH5p
              "
              @click.stop="playActivity(item)"
            >
              mdi-play-circle
            </v-icon>
            <span
              v-if="
                ((item.activity.resourceType === 'ACTIVITY' &&
                  !item.activity.typeH5p) ||
                  item.activity.resourceType === 'DOCUMENT') &&
                !loadingHomeworkRow[item.id]
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="primary"
                    @click="
                      downloadStatement(
                        item.activity.id,
                        loadingHomeworkRow,
                        item.id
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    file_download
                  </v-icon>
                </template>
                <span>{{ $t("activity.actions.download_statement") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="success"
                    @click="chooseFile(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    file_upload
                  </v-icon>
                </template>
                <span>{{ $t("activity.actions.add_file") }}</span>
              </v-tooltip>
            </span>

            <loading-spinner
              v-if="loadingHomeworkRow[item.id]"
            ></loading-spinner>

            <v-tooltip v-if="item.activity.resourceType === 'LINK'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="pastTime(item.dueDate) ? 'primary' : 'dark'"
                  @click="openLink(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-link
                </v-icon>
              </template>
              <span>{{ item.activity.link }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'IMAGE'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="pastTime(item.dueDate) ? 'primary' : 'dark'"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.watch_image") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'VIDEO'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="pastTime(item.dueDate) ? 'primary' : 'dark'"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-video
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.watch_video") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'AUDIO'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="pastTime(item.dueDate) ? 'primary' : 'dark'"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-music-box
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.play_audio") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" class="text-center">
        <v-data-table
          class="ma-4 mx-0"
          :headers="resultsHeaders"
          :items="results"
          :footer-props="tableFooterProps"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t("student.tabs.results_homework.results") }}
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.edition`]="{ item }">
            <a v-if="item.edition && isAdult" @click="goToEdition(item)">
              <span>{{ item.edition.title }}</span>
            </a>
            <span v-if="item.edition && !isAdult">
              {{ item.edition.title }}
            </span>
            <span v-if="!item.edition">
              {{ $t("activityInteraction.level_test") }}
            </span>
          </template>

          <template v-slot:[`item.grade`]="{ item }">
            <span>
              {{
                item.grade != null
                  ? item.grade
                  : $t("activityInteraction.messages.no_grade")
              }}
            </span>
          </template>
          <template v-slot:[`item.timeConsumed`]="{ item }">
            <span v-if="item.timeConsumed">
              {{ item.timeConsumed }}
            </span>
            <span v-else>--</span>
          </template>
          <template v-slot:[`item.dateSolved`]="{ item }">
            <span v-if="item.dateSolved">
              {{ item.dateSolved | dateTimeWithTz("medium") }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <a ref="hiddenDownloader" class="d-none" />
            <v-container
              v-if="
                ((item.activity.resourceType == 'ACTIVITY' &&
                  !item.activity.typeH5p) ||
                  item.activity.resourceType == 'DOCUMENT') &&
                !loadingRow[item.id]
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="secondary"
                    @click="
                      downloadStatement(item.activity.id, loadingRow, item.id)
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    file_download
                  </v-icon>
                </template>
                <span>{{ $t("activity.actions.download_statement") }}</span>
              </v-tooltip>
              <span v-if="item.content">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="primary"
                      @click="downloadContent(item.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      file_download
                    </v-icon>
                  </template>
                  <span>{{ $t("activity.actions.download_file") }}</span>
                </v-tooltip>
              </span>
            </v-container>

            <loading-spinner v-if="loadingRow[item.id]"></loading-spinner>

            <v-tooltip v-if="item.activity.resourceType === 'IMAGE'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="dark"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.watch_image") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'VIDEO'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="dark"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-video
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.watch_video") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'AUDIO'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="dark"
                  @click="playActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-music-box
                </v-icon>
              </template>
              <span>{{ $t("activity.actions.play_audio") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.activity.resourceType === 'LINK'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="dark"
                  @click="openLink(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-link
                </v-icon>
              </template>
              <span>{{ item.activity.link }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { downloadFile, uploadPdf } from "@/common/file-utils";
import extensions from "@/common/file-extensions";
import { dateArrayToDate } from "@/common/conversion-utils";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);

export default {
  name: "ResultsTab",
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingSpinner,
    DateAndHourPicker,
    LoadingPage,
  },
  data() {
    return {
      loading: false,
      results: [],
      homework: [],
      dateSolvedFilterStart: this.$route.query.dateSolvedFilterStart
        ?.split("-")
        .map((e) => parseInt(e)),
      dateSolvedFilterEnd: this.$route.query.dateSolvedFilterEnd
        ?.split("-")
        .map((e) => parseInt(e)),
      editionFilter: null,
      selectedEntity: null,
      editions: [],
      loadingEditions: false,
      totalItems: 0,
      file: {
        loading: false,
      },
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
      extensions,
      loadingHomeworkRow: [],
      loadingRow: [],
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAdult", "isTutor"]),
    resultsHeaders() {
      return [
        {
          text: this.$t("activityInteraction.prop.activity"),
          sortable: false,
          value: "activity.description",
        },
        {
          text: this.$t("activityInteraction.prop.edition"),
          sortable: false,
          value: "edition",
        },
        {
          text: this.$t("activityInteraction.prop.grade"),
          value: "grade",
        },
        {
          text: this.$t("activityInteraction.prop.timeConsumed"),
          value: "timeConsumed",
        },
        {
          text: this.$t("activityInteraction.prop.dateSolved"),
          value: "dateSolved",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    homeworkHeaders() {
      return [
        {
          text: this.$t("activityInteraction.prop.activity"),
          value: "activity.description",
        },
        {
          text: this.$t("activityInteraction.prop.edition"),
          value: "edition",
        },
        {
          text: this.$t("activityInteraction.prop.dueDate"),
          value: "dueDate",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.editionFilter) {
      let value = parseFloat(this.$route.query.editionFilter);
      this.editionFilter = isNaN(value) ? null : value;
    }

    this._fetchResults();
    this._fetchHomework();
    this._fetchStudentEditions();
  },
  methods: {
    async _fetchResults() {
      this.loading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          edition: this.editionFilter,
          start: this.dateSolvedFilterStart
            ?.map((e) => (e < 10 ? "0" + e.toString() : e.toString()))
            .join("-"),
          end: this.dateSolvedFilterEnd
            ?.map((e) => (e < 10 ? "0" + e.toString() : e.toString()))
            .join("-"),
        },
      };
      await ActivityInteractionEntityRepository.getAllResultsByStudent(
        this.studentId,
        options
      )
        .then((response) => {
          this.results = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching results for student with id ",
            this.studentId
          )
        )
        .finally(() => (this.loading = false));
    },

    async _fetchHomework() {
      this.loading = true;
      const options = {
        params: {
          edition: this.editionFilter,
        },
      };
      await ActivityInteractionEntityRepository.getAllHomeworkByStudent(
        this.studentId,
        options
      )
        .then((response) => (this.homework = response.content))
        .catch(() =>
          this.$log.debug(
            "Error fetching results for student with id ",
            this.studentId
          )
        )
        .finally(() => (this.loading = false));
    },
    async _fetchStudentEditions() {
      this.loadingEditions = true;
      await EditionEntityRepository.getAllByStudent(this.studentId)
        .then((data) => (this.editions = data))
        .catch(() =>
          this.$log.debug(
            "Error fetching editions for student with id ",
            this.studentId
          )
        )
        .finally(() => (this.loadingEditions = false));
    },
    chooseFile(selectedItem) {
      this.selectedEntity = selectedItem;
      this.$refs.interactionFileLoader.click();
    },
    saveFile(htmlElement) {
      this.$set(this.loadingHomeworkRow, this.selectedEntity.id, true);
      uploadPdf(this.selectedEntity, "content", this.file, htmlElement).then(
        () => {
          ActivityInteractionEntityRepository.save({
            id: this.selectedEntity.id,
            content: this.selectedEntity.content,
          }).then((res) => {
            this.$notify({
              title: this.$t("activityInteraction.messages.save_success"),
              text: this.$t("account.notifications.changes_saved"),
              type: "success",
            });
            this.$emit("saved", res);
            this._fetchResults();
            this._fetchHomework();
            this.$set(this.loadingHomeworkRow, this.selectedEntity.id, false);
            this.selectedEntity = null;
          });
        }
      );
    },
    downloadContent(id) {
      this.$set(this.loadingRow, id, true);
      return ActivityInteractionEntityRepository.getContent(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("activity.messages.downloading_file"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("activity.messages.file_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("activity.messages.not_downloading_file"),
              type: "error",
              duration: 30000,
            });
          }
        })
        .finally(() => this.$set(this.loadingRow, id, false));
    },
    downloadStatement(id, loading, itemId) {
      this.$set(loading, itemId, true);
      return ActivityEntityRepository.downloadActivityFile(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("activity.messages.downloading_file"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("activity.messages.file_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("activity.messages.not_downloading_file"),
              type: "error",
              duration: 30000,
            });
          }
        })
        .finally(() => this.$set(loading, itemId, false));
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.onFilterChange();
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this._fetchResults();
        this._fetchHomework();
      }
    },
    redirectOnTableChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    changeQueryFilters(query) {
      query.editionFilter = this.editionFilter
        ? this.editionFilter.toString()
        : undefined;

      query.dateSolvedFilterStart = this.dateSolvedFilterStart
        ? this.dateSolvedFilterStart
            .map((e) => (e < 10 ? "0" + e.toString() : e.toString()))
            .join("-")
        : undefined;

      query.dateSolvedFilterEnd = this.dateSolvedFilterEnd
        ? this.dateSolvedFilterEnd
            .map((e) => (e < 10 ? "0" + e.toString() : e.toString()))
            .join("-")
        : undefined;
    },
    onFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    goToEdition(item) {
      if (this.isAdmin) {
        this.$router.push({
          name: "Admin Edition Detail",
          params: { editionId: item.edition.id },
        });
      } else {
        this.$router.push(
          this.$route.path.replace(
            "/results",
            `/registrations/${item.registrationId}`
          )
        );
      }
    },
    playActivity(item) {
      this.$router.push({
        name: "PlayHomework",
        params: {
          studentId: this.studentId,
          activityInteractionId: item.id,
          lectureActivityId: item.lectureActivityId,
        },
      });
    },
    pastTime(time) {
      if (time) {
        time[5] = 0;
        const itemDueDate = dateArrayToDate(time);
        return itemDueDate > new Date();
      }
      return false;
    },
    openLink(item) {
      if (!item.dateSolved) {
        ActivityInteractionEntityRepository.save(item).then((res) => {
          this.$notify({
            title: this.$t("activityInteraction.messages.save_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("saved", res);
          this._fetchResults();
          this._fetchHomework();
        });
      }
      window.open(item.activity.link);
    },
  },
};
</script>
