/* eslint-disable no-unused-vars */
export default class StreamingLibrary {
  constructor(config) {}

  init(onParticipantsUpdate, onActiveShareChange, onStopShareChange) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  getParticipants() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  join(name, room) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  startVideo(container) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  leave() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  startCamera() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  stopCamera() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  renderVideo(container, userId) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  stopRenderVideo(container, userId) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  startShareScreen(container) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  stopShareScreen() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  startShareView(container) {
    throw "Must be implemented for StreamingLibrary classes";
  }

  stopShareView() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  getCurrentUser() {
    throw "Must be implemented for StreamingLibrary classes";
  }

  makeHost(userId) {
    throw "Must be implemented for StreamingLibrary classes";
  }
}
