import RegistrationDetail from "../components/RegistrationDetail";
import LevelTest from "../components/LevelTest";
import PlayHomework from "../components/PlayHomework";
import StudentDetail from "../StudentDetail";
import BalanceTab from "../tabs/BalanceTab";
import DocumentFormTab from "../tabs/DocumentFormTab";
import DocumentsTab from "../tabs/DocumentsTab";
import FeedbackTab from "../tabs/FeedbackTab";
import LevelsTab from "../tabs/LevelsTab";
import MovementsTab from "../tabs/MovementsTab";
import PersonalInfoFormTab from "../tabs/PersonalInfoFormTab";
import PersonalInfoTab from "../tabs/PersonalInfoTab";
import ResultsTab from "../tabs/ResultsTab";
import RegistrationsTab from "../tabs/RegistrationsTab";
import SchedulesTab from "../tabs/ScheduleTab";
import TutorsTab from "../tabs/TutorsTab";
import StudentDashboard from "@/mockups/student/views/StudentDashboard";

// MY ACCOUNT ROUTES DEFINITION
const routes = [
  {
    path: "/student/dashboard",
    name: "Student Home",
    component: StudentDashboard,
    meta: {
      authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
      label: "student.dashboard.title",
    },
  },
  {
    path: "/account/profile/student",
    name: "ProfileStudentView",
    component: StudentDetail,
    meta: {
      authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
    },
    children: [
      {
        path: "info",
        name: "ProfileStudentView Detail",
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.info",
        },
        component: PersonalInfoTab,
      },
      {
        path: "edit",
        name: "ProfileStudentView Edit",
        component: PersonalInfoFormTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "profile.your_profile",
        },
      },
      {
        path: "tutors",
        name: "ProfileStudentTutorsTab",
        component: TutorsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.tutors",
        },
      },
      {
        path: "schedules",
        name: "ProfileStudentSchedulesTab",
        component: SchedulesTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.schedule",
        },
      },
      {
        path: "levels",
        name: "ProfileStudentLevelsTab",
        component: LevelsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.levels",
        },
      },
      {
        path: "documents",
        name: "ProfileStudentDocumentsTab",
        component: DocumentsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "documents/new",
        name: "ProfileStudentDocumentFormTab",
        component: DocumentFormTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "registrations",
        name: "ProfileStudentRegistrationsTab",
        component: RegistrationsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.registrations",
        },
      },
      {
        path: "registrations/:registrationId",
        name: "ProfileStudentRegistrationDetail",
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "registration.name",
        },
        component: RegistrationDetail,
      },
      {
        path: "balance",
        name: "ProfileStudentBalanceTab",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "ProfileStudentMovementsTab",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.movements",
        },
      },
      {
        path: "feedback",
        name: "ProfileStudentFeedbackTab",
        component: FeedbackTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.feedback",
        },
      },
      {
        path: "results",
        name: "ProfileStudentResultsTab",
        component: ResultsTab,
        meta: {
          authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
          label: "student.navbar.results",
        },
      },
    ],
  },
  {
    path: "/account/profile/student/:studentId/level-test",
    name: "LevelTest",
    component: LevelTest,
    meta: {
      authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
    },
  },
  {
    path: "/account/profile/student/:studentId/homework/:lectureActivityId",
    name: "PlayHomework",
    component: PlayHomework,
    meta: {
      authority: ["ROLE_STUDENT", "ROLE_STUDENT_CANDIDATE"],
    },
  },
];

export default routes;
