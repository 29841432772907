export default {
  ROLE_ADMIN: "Admin Home",
  ROLE_TEACHER: "Teacher Home",
  ROLE_TEACHER_CANDIDATE: "Teacher Home",
  ROLE_TEACHER_GUEST: "TeacherProfile Create",
  ROLE_STUDENT: "Student Home",
  ROLE_STUDENT_GUEST: "StudentProfile Create",
  ROLE_STUDENT_CANDIDATE: "Student Home",
  ROLE_COMPANY: "Company Home",
  ROLE_TUTOR: "Tutor Home",
  ROLE_HEADMASTER: "Headmaster Home",
  ROLE_CONTENT_EDITOR: "ContentEditor Home",
};
