<template>
  <div class="menu-bar">
    <template v-for="item in items">
      <!-- If the element has children create a group -->
      <v-list-group
        color=""
        v-if="item.tabs && item.tabs.length && item.showed"
        :key="item.title"
        v-model="item.active"
      >
        <v-list-item class="px-0" slot="activator">
          <v-list-item-icon>
            <v-badge
              v-if="item.notification"
              color="warning accent-4"
              icon="priority_high"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
            <v-icon v-else>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Recursive call for all the children -->
        <menu-element class="pl-6" :items="item.tabs" />
      </v-list-group>

      <!-- If the element doesnt have children is a leaf -->

      <v-list-item
        v-else-if="item.showed"
        :key="item.title"
        :to="{ name: item.link }"
      >
        <!----------------- ICON ELEMENTS ----------------->
        <v-list-item-icon v-if="item.icon">
          <v-badge
            v-if="item.notification"
            color="warning accent-4"
            icon="priority_high"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <!-- Else only show icon -->
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content v-if="item.icon">
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>

        <!----------------- ELEMENTS THAT DOESNT HAVE ICON ----------------->
        <v-list-item-content v-else>
          <!-- if has notification show badge -->
          <v-list-item-title v-if="item.notification">
            <v-badge color="warning accent-4" icon="priority_high">
              {{ $t(item.title) }}
            </v-badge>
          </v-list-item-title>
          <!-- if not invisible badge to keep same height -->
          <v-list-item-title v-else>
            <v-badge color="">
              {{ $t(item.title) }}
            </v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "menu-element",
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
};
</script>
