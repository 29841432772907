<template>
  <v-dialog persistent :value="dialog" width="80%">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("student.tabs.exams.headers.new_language_exam") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"> <v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-row justify="center">
              <v-col cols="12" md="6">
                <language-selector
                  v-model="selectedLang"
                  :filter="hiddenLanguages"
                >
                </language-selector>
              </v-col>
              <v-col cols="12" md="6">
                <level-selector
                  v-model="selectedLevel"
                  :languageFilter="languageFilter"
                  class="required"
                  :label="$t('level.name')"
                  :rules="[(v) => !!v || $t('level.error.required')]"
                ></level-selector>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="text-center" no-gutters justify="space-around">
          <v-col cols="6" md="3">
            <v-btn color="secondary" @click="$emit('close')">
              <v-icon class="mr-2">close</v-icon>
              {{ $t(closeButtonText) }}
            </v-btn>
          </v-col>
          <v-col cols="6" md="3">
            <v-btn
              :disabled="!validForm || !selectedLang"
              color="success"
              @click="
                $emit('save', {
                  level: selectedLevel,
                })
              "
            >
              <v-icon class="mr-2">send</v-icon>
              {{ $t(saveButtonText) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LanguageSelector from "@/components/selectors/LanguageSelector";
import LevelSelector from "@/components/selectors/LevelSelector";

export default {
  name: "LevelSelectorDialog",
  components: {
    LanguageSelector,
    LevelSelector,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    hiddenLanguages: {
      type: Array,
      default: () => null,
      required: false,
    },
    saveButtonText: {
      type: String,
      default: () => "actions.save",
      required: false,
    },
    closeButtonText: {
      type: String,
      default: () => "actions.cancel",
      required: false,
    },
  },
  data() {
    return {
      selectedLang: null,
      selectedLevel: null,
      validForm: true,
    };
  },
  computed: {
    languageFilter() {
      return this.selectedLang?.id;
    },
  },
  watch: {
    selectedLevel(newValue) {
      if (
        newValue &&
        (!this.selectedLang || this.selectedLang.id !== newValue.language.id)
      ) {
        this.selectedLang = newValue.language;
      }
    },
    selectedLang(newValue) {
      if (
        newValue &&
        this.selectedLevel &&
        this.selectedLevel.language.id !== newValue.id
      ) {
        this.selectedLevel = null;
      }
    },
  },
};
</script>

<style></style>
