<template>
  <v-container fluid>
    <teacher-form
      v-if="!isLoading"
      v-model="entity"
      @save="save"
      @back="back"
    ></teacher-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { deleteTempFile } from "@/common/file-utils";
import auth from "@/common/auth.js";
import TeacherForm from "../components/TeacherForm.vue";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  components: {
    LoadingPage,
    TeacherForm,
  },
  props: {
    teacherId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      entity: {},
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  created() {
    this._fetchData(this.teacherId);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return TeacherEntityRepository.get(id)
        .then((response) => (this.entity = response))
        .catch(() => this.$log.debug("Error fetching teacher with ID " + id))
        .finally(() => (this.loading = false));
    },
    back() {
      const route = this.$route.params.id
        ? "TeacherView Detail"
        : "ProfileTeacherView Detail";
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      TeacherEntityRepository.save(this.entity)
        .then(() => {
          let route = this.$route.params.id
            ? "TeacherView Detail"
            : "ProfileTeacherView Detail";
          this.entity.photo = null;
          return auth.reloadUserData().finally(() => {
            this.$router.push({
              name: route,
              params: {
                backAction: this.$route.params.backPrevious,
              },
            });
          });
        })
        .catch(() => this.$log.debug("Error saving teacher: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail === ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
