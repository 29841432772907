<template>
  <v-col cols="12">
    <v-alert
      :value="pendingAlert"
      outlined
      dense
      type="info"
      transition="scale-transition"
    >
      {{ alertMessage }}
      <div class="float-right d-inline-flex">
        <v-btn class="success" small :disabled="loading" @click="approve()">{{
          $t("actions.approve")
        }}</v-btn>
        <div class="ml-4" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-alert>
    <v-alert
      :value="approvedAlert"
      dense
      dismissible
      type="success"
      transition="scale-transition"
    >
      {{ approvedMessage }}
    </v-alert>
    <v-alert
      :value="rejectedAlert"
      dense
      dismissible
      type="error"
      transition="scale-transition"
    >
      {{ rejectedMessage }}
    </v-alert>
  </v-col>
</template>

<script>
export default {
  name: "ApproveAlert",
  props: {
    alertMessage: {
      type: String,
      required: true,
    },
    approvedMessage: {
      type: String,
      required: true,
    },
    rejectedMessage: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      approvedAlert: false,
      pendingAlert: true,
      rejectedAlert: false,
    };
  },
  watch: {
    status(val) {
      switch (val) {
        case "PENDING":
          this.approvedAlert = false;
          this.pendingAlert = true;
          this.rejectedAlert = false;
          break;
        case "APPROVED":
          this.approvedAlert = true;
          this.pendingAlert = false;
          this.rejectedAlert = false;
          break;
        case "REJECTED":
          this.approvedAlert = false;
          this.pendingAlert = false;
          this.rejectedAlert = true;
          break;
      }
    },
  },
  methods: {
    approve() {
      this.$emit("approve");
    },
  },
};
</script>
