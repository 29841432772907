import ResetPasswordRequest from "../password/reset/ResetPasswordRequest.vue";

import ResetPasswordFinish from "../password/reset/ResetPasswordFinish.vue";

import Profile from "../profile/Profile.vue";
import ProfileEdit from "../profile/ProfileEdit.vue";

import CompanyRegister from "@/account/register/CompanyRegister";
import TutorRegister from "@/account/register/TutorRegister";
import TutorConfirmation from "@/account/confirmation/TutorConfirmation";
import CompanyStudentRegister from "@/account/register/CompanyStudentRegister";
import CompanyStudentConfirmation from "@/account/confirmation/CompanyStudentConfirmation";

import Activate from "../activate/Activate.vue";

import Register from "../register/Register.vue";

import ChangePassword from "@/account/profile/ChangePassword";

import StudentGuestForm from "@/mockups/student/StudentGuestForm";
import TeacherGuestForm from "@/mockups/teachers/TeacherGuestForm";

const routes = [
  {
    path: "/account/reset_password",
    name: "ResetPasswordRequest",
    component: ResetPasswordRequest,
    meta: {
      public: true,
      label: "account.actions.reset_password",
      layout: "AppLayoutLogin",
    },
  },
  {
    path: "/account/reset/finish",
    name: "ResetPasswordFinish",
    component: ResetPasswordFinish,
    meta: {
      public: true,
      label: "account.actions.reset_password",
    },
  },
  {
    path: "/account/set/finish",
    name: "UserCreatedByAdminNewPassword",
    component: ResetPasswordFinish,
    meta: {
      public: true,
      label: "account.actions.reset_password",
    },
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    meta: {
      public: true,
      label: "account.form.validation.register.name",
      layout: "AppLayoutLogin",
    },
  },
  {
    path: "/account/tutor/create",
    name: "Tutor Register",
    component: TutorRegister,
    meta: {
      public: true,
      label: "account.form.validation.register.tutor",
    },
  },
  {
    path: "/account/company/create",
    name: "Company Register",
    component: CompanyRegister,
    meta: {
      public: true,
      label: "account.form.validation.register.company",
      layout: "AppLayoutLogin",
    },
  },
  {
    path: "/account/company/student/create",
    name: "CompanyStudent Register",
    component: CompanyStudentRegister,
    meta: {
      public: true,
      label: "companyStudent.actions.register",
    },
  },
  {
    path: "/account/tutor/confirmation",
    name: "Tutor Confirmation",
    component: TutorConfirmation,
    meta: {
      label: "account.actions.confirm_tutor",
    },
  },
  {
    path: "/account/company/student/confirmation",
    name: "CompanyStudent Confirmation",
    component: CompanyStudentConfirmation,
    meta: {
      label: "companyStudent.actions.confirm",
    },
  },
  {
    path: "/account/activate",
    name: "Activate",
    component: Activate,
    meta: {
      public: true,
      label: "account.user_activation",
    },
  },
  {
    path: "/admin/account/:id/edit",
    name: "AdminEditProfile",
    component: ProfileEdit,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "profile.your_profile",
    },
  },
  {
    path: "/account/profile",
    name: "Profile",
    component: Profile,
    meta: {
      label: "profile.your_profile",
    },
  },
  {
    path: "/account/edit",
    name: "EditProfile",
    component: ProfileEdit,
    meta: {
      label: "account.actions.edit_profile",
    },
  },
  {
    path: "/account/change_password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      label: "account.actions.change_password",
    },
  },

  {
    path: "/account/student/new",
    name: "StudentProfile Create",
    component: StudentGuestForm,
    meta: {
      authority: ["ROLE_STUDENT_GUEST"],
      label: "profile.your_profile",
    },
  },
  {
    path: "/account/teacher/new",
    name: "TeacherProfile Create",
    component: TeacherGuestForm,
    meta: {
      authority: ["ROLE_TEACHER_GUEST"],
      label: "profile.your_profile",
    },
  },
];

export default routes;
