<template>
  <v-container v-if="items">
    <v-card>
      <v-card-title>
        <span>
          {{ $t("activity.headers.list") }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              clearable
              outlined
              no-filter
              attach
              :debouncing="300"
              :items="resourceType"
              :item-text="translate"
              :search-input.sync="resourceTypeSearch"
              v-model="resourceTypeFilter"
              item-value="value"
              :label="$t('activity.prop.resource_type')"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <debounced-text-field
              dense
              outlined
              v-model="titleFilter"
              :label="$t('activity.prop.title')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <debounced-text-field
              dense
              outlined
              v-model="descriptionFilter"
              :label="$t('activity.prop.description')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
              attach
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTypes.items"
              :loading="activityTypes.loading"
              :search-input.sync="activityTypeSearch"
              v-model="activityTypeFilter"
              :label="$t('activity.prop.activity_type')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                  v-if="index === 0"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row align="center" justify="space-between">
          <v-col cols="12" md="3">
            <LanguageSelector
              v-model="languageFilter"
              attach
              :disabled="language ? true : false"
              dense
              :clearable="!levelFilter"
              outlined
              :debouncing="300"
              :label="$t('activity.prop.language')"
              show-chips
              @input="onSelectorChange('onLanguageChange', ...arguments)"
            ></LanguageSelector>
          </v-col>
          <v-col cols="12" md="3">
            <LevelSelector
              v-model="levelFilter"
              attach
              dense
              clearable
              outlined
              :debouncing="300"
              :label="$t('activity.prop.level')"
              :language-filter="languageFilter ? languageFilter.id : null"
              :show-language="false"
              @input="onSelectorChange('onLevelChange', ...arguments)"
            ></LevelSelector
          ></v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
              attach
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activitySkills.items"
              :loading="activitySkills.loading"
              :search-input.sync="activitySkillSearch"
              v-model="activitySkillFilter"
              :label="$t('activity.prop.skills')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
              attach
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTopics.items"
              :loading="activityTopics.loading"
              :search-input.sync="activityTopicSearch"
              v-model="activityTopicFilter"
              :label="$t('activity.prop.topics')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
              attach
              dense
              outlined
              small-chips
              deletable-chips
              no-filter
              multiple
              :debouncing="300"
              :items="activityWords.items"
              :loading="activityWords.loading"
              :search-input.sync="activityWordSearch"
              v-model="activityWordFilter"
              :label="$t('activity.prop.words')"
              item-text="name"
              :item-value="(item) => parseInt(item.id)"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :options.sync="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @click:row="selectRow"
        >
          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ item.level.name | uppercase }}
            </v-chip>
          </template>

          <template v-slot:[`item.activityType`]="{ item }">
            <v-chip
              v-if="item.activityType"
              :color="getChipBackColor(item.activityType)"
              :text-color="getChipTextColor(item.activityType)"
              >{{ item.activityType.name }}</v-chip
            >
          </template>

          <template v-slot:[`item.resourceType`]="{ item }">
            <v-chip
              v-if="item.resourceType"
              :color="stringToColour(item.resourceType)"
              :text-color="stringToColourText(item.resourceType)"
              >{{ $t(`activity.resource_type.${item.resourceType}`) }}</v-chip
            >
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.content != null"
                  color="primary"
                  @click.stop="previewActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t("actions.preview") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog width="50%" v-model="previewDialog" v-if="previewDialog">
      <activity-preview
        v-if="preview"
        :activity="preview"
        @cancel="closePreview()"
      ></activity-preview>
    </v-dialog>

    <v-dialog
      v-model="pdfDialog"
      v-if="pdfDialog"
      width="80%"
      @click:outside="closePreview()"
    >
      <PDFViewer
        :exercise="preview.id"
        :homework="false"
        @closeDialog="closePreview()"
      >
      </PDFViewer>
    </v-dialog>
  </v-container>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ResourceTypes from "@/enumerates/ResourceType";
import ActivityPreview from "../_components/ActivityPreview.vue";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";
import {
  getChipBackColor,
  getChipTextColor,
  stringToColour,
  stringToColourText,
} from "@/common/customization";
import { translate } from "@/common/translation-utils.js";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import { generateSort } from "@/common/pagination-utils";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const ActivityTypeEntityRepository = RepositoryFactory.get(
  "ActivityTypeEntityRepository"
);
const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");

export default {
  name: "ActivitySimpleList",
  components: {
    ActivityPreview,
    PDFViewer,
    DebouncedTextField,
    LanguageSelector,
    LevelSelector,
  },
  props: {
    language: {
      type: Number,
      required: false,
    },
    level: {
      type: Number,
      requited: false,
    },
    presentActivities: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      items: [],
      activityTypeSearch: null,
      activityTopicSearch: null,
      activitySkillSearch: null,
      activityWordSearch: null,
      resourceTypeSearch: null,
      resourceType: ResourceTypes,
      dialog: false,
      selected: null,
      titleFilter: null,
      descriptionFilter: null,
      levelFilter: null,
      languageFilter: null,
      activityTypeFilter: null,
      activityTopicFilter: null,
      activitySkillFilter: null,
      resourceTypeFilter: null,
      activityWordFilter: [],
      entitiesPage: {
        ...defaultPaginationSettings,
        sortBy: [],
        sortDesc: [],
      },
      activityTypes: {
        items: [],
        loading: false,
      },
      activityTopics: {
        items: [],
        loading: false,
      },
      activitySkill: {
        items: [],
        loading: false,
      },
      activitySkills: {
        items: [],
        loading: false,
      },
      activityWords: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
      preview: {},
      previewDialog: false,
      pdfDialog: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("activity.prop.title"),
          value: "title",
        },

        {
          text: this.$t("activity.prop.description"),
          value: "description",
        },

        {
          text: this.$t("activity.prop.level"),
          value: "level",
        },
        {
          text: this.$t("activity.prop.activity_type"),
          sortable: false,
          value: "activityType",
        },
        {
          text: this.$t("activity.prop.resource_type"),
          value: "resourceType",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    activityFilter() {
      return this.activityTopicFilter?.toString();
    },
    wordFilter() {
      return this.activityWordFilter.join();
    },
    filters() {
      let filters = "";

      filters =
        filters +
        (this.titleFilter != null && this.titleFilter !== ""
          ? "title:" + this.titleFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.descriptionFilter != null && this.descriptionFilter !== ""
          ? "description:" + this.descriptionFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.activityTypeFilter
          ? "activityType.id:" + this.activityTypeFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.resourceTypeFilter
          ? "resourceType:" + this.resourceTypeFilter.toString() + ","
          : "");

      return filters !== "" ? filters : null;
    },
  },
  watch: {
    entitiesPage: {
      handler() {
        this.getItems(this.level);
      },
      deep: true,
    },
    activityTypeSearch() {
      this.getActivityTypeItems();
    },
    activityTopicSearch() {
      this.getActivityTopicItems();
    },
    activitySkillSearch() {
      this.getActivitySkillItems();
    },
    activityWordSearch() {
      this.getActivityWordItems();
    },
    resourceTypeSearch(newVal) {
      this.resourceType = newVal
        ? ResourceTypes.filter(
            (item) =>
              this.$t(item.text).toLowerCase().indexOf(newVal.toLowerCase()) !==
              -1
          )
        : ResourceTypes;
    },
    activityFilter() {
      this.getItems();
    },
    activitySkillFilter() {
      this.getItems();
    },
    wordFilter() {
      this.entitiesPage.page = 1;
      this.activityWordSearch = null;
      this.getItems();
    },
    filters() {
      // Return to the first page to show all results
      if (this.entitiesPage.page > 1) {
        this.entitiesPage.page = 1;
      } else {
        this.getItems();
      }
    },
    presentActivities(newValue) {
      this.$set(this, "presentActivities", newValue);
      this.getItems();
    },
  },
  created() {
    if (this.level) {
      let value = parseFloat(this.level);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.language) {
      let value = parseFloat(this.language);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }
    //Setting route params
    this.getActivityTypeItems();
    this.getActivityTopicItems();
    this.getActivitySkillItems();
    this.getActivityWordItems();
  },
  methods: {
    selectRow(activity) {
      this.$emit("selected", activity);
    },
    // Gets info for activity list
    getItems() {
      this.loading = true;
      const sortMapping = {
        "level.language.name": "level.name",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage, sortMapping),
          level: this.levelFilter?.id,
          language: this.languageFilter?.id,
          topics: this.activityFilter,
          skills: this.activitySkillFilter,
          resourceType: this.resourceTypeFilter,
          activityWords: this.wordFilter,
          presentActivities: this.presentActivities,
          active: true,
          complete: true,
        },
      };
      ActivityEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching activities list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    getActivityTypeItems() {
      this.activityTypes.loading = true;
      ActivityTypeEntityRepository.getAll()
        .then((response) => (this.activityTypes.items = response))
        .catch(() =>
          this.$log.debug(
            "Error fetching activity types with params: " + options
          )
        )
        .finally(() => (this.activityTypes.loading = false));
    },
    getActivityTopicItems() {
      this.activityTopics.loading = true;
      const options = {
        params: {
          languages: this.languageFilter ? this.languageFilter.id : undefined,
          search: this.activityTopicSearch,
          types: "2,3",
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.activityTopics.items = response.content;
        })
        .catch(() =>
          this.$log.debug("Error fetching topics with params: " + options)
        )
        .finally(() => (this.activityTopics.loading = false));
    },
    getActivitySkillItems() {
      this.activitySkills.loading = true;
      const options = {
        params: {
          languages: this.languageFilter ? this.languageFilter.id : undefined,
          search: this.activitySkillSearch,
          types: "1",
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.activitySkills.items = response.content;
        })
        .catch(() =>
          this.$log.debug("Error fetching topics with params: " + options)
        )
        .finally(() => (this.activitySkills.loading = false));
    },
    getActivityWordItems() {
      this.activityWords.loading = true;
      const options = {
        params: {
          search: this.activityWordSearch,
          level: this.levelFilter ? this.levelFilter.id : undefined,
          language: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      return WordEntityRepository.getAllByLevelAndLanguage(options)
        .then((data) => {
          this.activityWords.items = data.content;
        })
        .finally(() => (this.activityWords.loading = false));
    },
    previewActivity(activity) {
      this.preview = activity;
      if (
        (activity.resourceType === "DOCUMENT" ||
          activity.resourceType === "ACTIVITY") &&
        !activity.typeH5p
      ) {
        this.pdfDialog = true;
      } else {
        this.previewDialog = true;
      }
    },
    closePreview() {
      if (
        (this.preview.resourceType === "DOCUMENT" ||
          this.preview.resourceType === "ACTIVITY") &&
        !this.preview.typeH5p
      ) {
        this.pdfDialog = false;
      } else {
        this.previewDialog = false;
      }
      this.preview = null;
    },
    onSelectorChange(op, value) {
      if (this.entitiesPage.page > 1) {
        this.entitiesPage.page = 1;
      } else {
        if (op === "onLevelChange" && value) {
          this.languageFilter = value.language;
        }
        if (op === "onLanguageChange") {
          if (
            this.levelFilter != null &&
            this.levelFilter.language.id === value?.id
          ) {
            return;
          }
        }
        this.getActivityTopicItems();
        this.getActivitySkillItems();
        this.getActivityWordItems();
        this.getItems();
      }
    },
    getChipBackColor,
    getChipTextColor,
    translate,
    stringToColour,
    stringToColourText,
  },
};
</script>
