import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "/chats";

export default {
  async getChatRoomList(options = {}) {
    return (await HTTP.get(RESOURCE_NAME, options)).data;
  },
  async getNotificationChannels() {
    return (await HTTP.get(`${RESOURCE_NAME}/channels/notifications`)).data;
  },
  async getChat(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },
  async saveChat(chat) {
    await HTTP.post(`${RESOURCE_NAME}`, chat);
  },
  async sendMessage(id, message) {
    return (await HTTP.post(`${RESOURCE_NAME}/${id}/messages`, message)).data;
  },
  async updateNotifications(id) {
    await HTTP.post(`${RESOURCE_NAME}/${id}/notifications`);
  },
  async updateArchivedStatus(id) {
    return (await HTTP.patch(`${RESOURCE_NAME}/${id}/archive`)).data;
  },
  async getNotifications() {
    return (await HTTP.get(`${RESOURCE_NAME}/notifications`)).data;
  },
  async getChannelsByUserAndType(options = {}) {
    return (await HTTP.get(`${RESOURCE_NAME}/filtered`, options)).data;
  },
};
