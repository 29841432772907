var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),(!_vm.showForm)?_c('v-btn',{attrs:{"color":"success ml-2"},on:{"click":function($event){_vm.showForm = true}}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.new"))+" ")])],1):_c('v-btn',{staticClass:"error ml-3",on:{"click":function($event){_vm.showForm = false}}},[_c('v-icon',[_vm._v("mdi-window-close")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.close"))+" ")])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.showForm)?_c('company-student-form',{attrs:{"companyId":_vm.companyId},on:{"saved":_vm.fetchData}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"data-table",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.confirmed)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"loading":_vm.deleteLoading},on:{"cancel":function($event){_vm.deleteDialog = false},"submit":_vm.unlinkStudent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }