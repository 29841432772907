const productStatusConst = ["DRAFT", "PUBLIC", "DISABLED"];

const productTypeConst = ["COURSE", "ADHOC_ONE", "ADHOC_TWO", "ADHOC_GROUP"];

const classDurationConst = [25, 50];

const paymentTypeConst = ["PERIODIC", "PER_CLASS"];

export {
  productStatusConst,
  productTypeConst,
  classDurationConst,
  paymentTypeConst,
};
