import store from "@/common/store";

/**
 * Calculates the difference in hours with GMT of a TimeZone
 * @param {String} timeZone
 * @returns TimeZone in GMT hours difference
 * in format: "(GMT+hours:minutes)"
 */
const getOffset = (timeZone) => {
  if (!timeZone) return "";

  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "short",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName").value;

  // Handling some timeZones not found
  let offset = null;
  if (timeZoneName == "CEST") {
    offset = "+2";
  } else if (timeZoneName == "EEST") {
    offset = "+3";
  } else if (timeZoneName == "WEST") {
    offset = "+1";
  } else offset = timeZoneName.slice(3);

  if (!offset) return "(GMT)";

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);

  const [, sign, hour, minute] = matchData;
  let result = "(GMT" + sign + ("0" + hour).slice(-2);

  minute ? (result += ":" + parseInt(minute)) : (result += ":00");

  return result + ")";
};

const getDefaultTimeZone = () => {
  return "Europe/Brussels";
};

const getUserTimeZone = () => {
  if (store.state.user && store.state.user.timeZone) {
    return store.state.user.timeZone;
  }
  return getDefaultTimeZone();
};

export { getOffset, getDefaultTimeZone, getUserTimeZone };
