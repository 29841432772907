<template>
  <v-container fluid v-if="teachersLectures">
    <a ref="hiddenDownloader" class="d-none" />
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="4"
            lg="6"
            xl="8"
            order="2"
            order-sm="1"
            class="text-center text-sm-right mt-4 mt-sm-0"
          >
            <v-btn
              class="mr-2"
              outlined
              color="primary"
              :loading="isLoadingPDF"
              @click="getPDF"
              ><v-icon class="mr-2">file_download</v-icon>
              {{ $t("teacherLecture.export.pdf") }}
            </v-btn>

            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="space-between">
          <v-col cols="12" sm="4" md="2">
            <autocomplete
              v-model="teacherFilter"
              dense
              no-filter
              solo
              :debouncing="300"
              :items="filteredTeachers"
              :loading="teachers.loading"
              :search-input.sync="teacherSearch"
              :label="$t('teacherLecture.prop.teacher')"
              :item-text="getFullName"
              item-value="id"
              @change="redirectOnFilterChange"
            >
            </autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-checkbox
              v-model="teacherCheckbox"
              :label="$t('teacherLecture.prop.checkbox')"
              @change="redirectOnFilterChange"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <dateAndHourPicker
              :datePickerProp="{
                data: startTimeFilter,
                label: 'teacherLecture.prop.startTime',
              }"
              @update-time="
                updateDateTime('startTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <dateAndHourPicker
              :datePickerProp="{
                data: endTimeFilter,
                label: 'teacherLecture.prop.endTime',
              }"
              @update-time="
                updateDateTime('endTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              disable-pagination
              :headers="headers"
              hide-default-footer
              :items="teachersLectures"
              :loading="loading"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import {
  dateArrayToDateString,
  dateToMinLocalDateOrMaxLocalDate,
} from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const today = new Date();
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
const defaultEndTime = [
  lastDayOfMonth.getFullYear(),
  lastDayOfMonth.getMonth() + 1,
  lastDayOfMonth.getDate(),
];
export default {
  name: "TeacherLecture",
  components: {
    Autocomplete,
    DateAndHourPicker,
  },
  data() {
    return {
      teachersLectures: [],
      loading: false,
      showFilters: true,
      teacherCheckbox: this.$route.query.teacherCheckbox === "true",
      teacherFilter: null,
      teacherSearch: "",
      startTimeFilter: null,
      endTimeFilter: defaultEndTime,
      loadingPDF: false,
      teachers: {
        items: [],
        loading: false,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("teacherLecture.prop.displayName"),
          value: "displayName",
        },
        {
          text: this.$t("teacherLecture.prop.lastName"),
          value: "lastName",
        },
        {
          text: this.$t("teacherLecture.prop.firstName"),
          value: "firstName",
        },
        {
          text: this.$t("teacherLecture.prop.lectures"),
          value: "lectures",
        },
      ];
    },
    filters() {
      return {
        teacher: this.teacherFilter,
        teachersWithoutLectures: this.teacherCheckbox,
        startTime: this.startTimeFilter
          ? dateArrayToDateString(this.startTimeFilter)
          : null,
        endTime: this.endTimeFilter
          ? dateArrayToDateString(this.endTimeFilter)
          : null,
      };
    },
    isLoadingPDF() {
      return this.loadingPDF;
    },
    filteredTeachers() {
      return this.teacherSearch
        ? this.teachers.items.filter((item) =>
            (item.name + item.surname)
              .replace(/\s+/g, "")
              .toUpperCase()
              .includes(this.teacherSearch.replace(/\s+/g, "").toUpperCase())
          )
        : this.teachers.items;
    },
  },
  created() {
    //Setting route params
    if (this.teacherCheckbox) {
      this.showFilters = true;
    }
    if (this.$route.query.teacherFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.teacherFilter);
      this.teacherFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.startTimeFilter) {
      if (this.$route.query.startTimeFilter === "null") {
        this.startTimeFilter = null;
      } else {
        this.startTimeFilter = this.$route.query.startTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.startTimeFilter = null;
    }

    if (this.$route.query.endTimeFilter) {
      if (this.$route.query.endTimeFilter === "null") {
        this.endTimeFilter = null;
      } else {
        this.endTimeFilter = this.$route.query.endTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.endTimeFilter = defaultEndTime;
    }

    if (this.startTimeFilter || this.endTimeFilter) {
      this.redirectOnFilterChange();
    } else {
      this._fetchData();
    }
    this.getTeacherItems();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const options = {
        params: this.filters,
      };
      return LectureEntityRepository.getTeacherLectureCount(options)
        .then((response) => (this.teachersLectures = response))
        .catch(() => this.$log.debug("Error fetching lectures "))
        .finally(() => (this.loading = false));
    },
    getTeacherItems() {
      this.teachers.loading = true;
      let request = null;
      if (this.teacherCheckbox) {
        request = TeacherEntityRepository.getAll().then((res) => {
          this.teachers.items = res.content;
        });
      } else {
        const options = {
          params: {
            startTime: this.startTimeFilter
              ? dateArrayToDateString(this.startTimeFilter)
              : null,
            endTime: this.endTimeFilter
              ? dateArrayToDateString(this.endTimeFilter)
              : null,
          },
        };
        request = TeacherEntityRepository.getAllWithLecturesNotPaid(options)
          .then((response) => {
            this.teachers.items = response;
          })
          .then(() => {
            if (
              this.teacherFilter &&
              !this.teachers.items.find((i) => i.id === this.teacherFilter)
            ) {
              this.teacherFilter = null;
            }
          });
      }
      request
        .catch(() => this.$log.debug("Error fetching teachers"))
        .finally(() => (this.teachers.loading = false));
    },
    getPDF() {
      this.loadingPDF = true;
      const options = {
        params: {
          teacher: this.teacherFilter,
          startTime: this.startTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.startTimeFilter),
                false
              )
            : null,
          endTime: this.endTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.endTimeFilter),
                true
              )
            : null,
        },
      };
      return LectureEntityRepository.settleLectures(options)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("teacherLecture.export.download_success"),
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t("teacherLecture.export.download_error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => {
          this.loadingPDF = false;
          this._fetchData();
        });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        if (this.$route.query.teacherFilter === query.teacherFilter) {
          this.getTeacherItems();
        }
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this._fetchData();
    },
    redirectOnFilterChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.teacherCheckbox = this.teacherCheckbox.toString();
      query.teacherFilter = this.teacherFilter ? this.teacherFilter : undefined;
      query.startTimeFilter = this.startTimeFilter
        ? dateArrayToDateString(this.startTimeFilter)
        : "null";
      query.endTimeFilter = this.endTimeFilter
        ? dateArrayToDateString(this.endTimeFilter)
        : "null";

      this.redirect(query);
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    getFullName(item) {
      return item.name + " " + item.surname;
    },
  },
};
</script>
