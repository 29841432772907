<template>
  <div class="mt-10">
    <audio
      v-if="options"
      id="audioPlayer"
      ref="audioPlayer"
      class="video-js vjs-default-skin"
      crossorigin="use-credentials"
    ></audio>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import auth from "@/common/auth";

export default {
  name: "AudioPlayer",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        sources: [],
        controls: true,
        preload: "auto",
        playbackRates: [0.2, 0.5, 1, 1.5, 2],
      },
      player: null,
    };
  },
  beforeDestroy() {
    // When the user leaves the player, we destroy the player and the cookie to prevent errors
    this.player.dispose();
    document.cookie =
      "authorization=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  watch: {
    src(newSrc) {
      this.player.src({
        type: "audio/mp3",
        src: newSrc + "?v=" + Date.now(),
      });
    },
  },
  mounted() {
    this.options.sources.push({
      src: this.src + "?v=" + Date.now(), // This query param forces video.js to refresh the content
      type: "audio/mp3",
    });

    // We create a cookie to authenticate the user in the server (it was not possible to add the authentication header)
    // All information here: https://gitlab.lbd.org.es/lms4.0/webapp/-/merge_requests/846
    let d = new Date();
    d.setTime(d.getTime() + 3 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
      "authorization=" +
      auth.getToken() +
      ";" +
      expires +
      ";domain=" +
      window.location.hostname +
      ";path=/";
    this.player = videojs(
      this.$refs.audioPlayer,
      this.options,
      function onPlayerReady() {}
    );
  },
};
</script>

<style>
#audioPlayer.video-js {
  height: 30px;
  width: 100%;
  margin: 1em;
}
#audioPlayer.video-js .vjs-big-play-button {
  height: 28px;
  width: 40px;
  top: 1px;
  left: 1px;
  line-height: 0.8em;
}
</style>
