<template>
  <div style="overflow: hidden">
    <video
      v-if="options"
      ref="videoPlayer"
      class="video-js vjs-default-skin"
      crossorigin="use-credentials"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import auth from "@/common/auth";

export default {
  name: "VideoPlayer",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        preload: "auto",
        autoplay: false,
        language: "en",
        controls: true,
        fluid: true,
        fill: true,
        sources: [],
        playbackRates: [0.2, 0.5, 1, 1.5, 2],
      },
      player: null,
    };
  },
  beforeDestroy() {
    // When the user leaves the player, we destroy the player and the cookie to prevent errors
    this.player.dispose();
    document.cookie =
      "authorization=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  watch: {
    src(newSrc) {
      this.player.src({ type: "video/mp4", src: newSrc + "?v=" + Date.now() });
    },
  },
  mounted() {
    this.options.sources.push({
      src: this.src + "?v=" + Date.now(), // This query param forces video.js to refresh the content
      type: "video/mp4",
    });

    // We create a cookie to authenticate the user in the server (it was not possible to add the authentication header)
    // All information here: https://gitlab.lbd.org.es/lms4.0/webapp/-/merge_requests/846
    let d = new Date();
    d.setTime(d.getTime() + 3 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
      "authorization=" +
      auth.getToken() +
      ";" +
      expires +
      ";domain=" +
      window.location.hostname +
      ";path=/";
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {}
    );
  },
};
</script>
