<template>
  <v-card v-if="!loading">
    <v-card-title class="grey lighten-1 white--text">
      <h2>
        {{ $t("activity.headers.form") }}
      </h2>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-alert
                outlined
                :color="showSelectedError ? 'red darken-2' : 'grey darken-2'"
              >
                <v-card-title>
                  <h4>{{ $t("activity.messages.selected") }}</h4>
                </v-card-title>
                <v-card-text
                  v-if="activitiesSelected && activitiesSelected.length > 0"
                >
                  <activities-selected-list
                    :activitiesSelected="activitiesSelected"
                    :removeActivity="removeActivity"
                  />
                  <v-row v-if="!lecture.levelTest">
                    <v-col>
                      <v-switch
                        v-model="homework"
                        inset
                        :label="$t('lecture.prop.homework')"
                        :disabled="checkIfLink()"
                      ></v-switch>
                    </v-col>
                    <v-col v-if="homework" class="mt-4">
                      <dateAndHourPicker
                        :datePickerProp="{
                          data: date.endDate,
                          label: 'teacherLecture.prop.endTime',
                        }"
                        :timePickerProp="{
                          data: date.endTime,
                          label:
                            'teacher.tabs.schedules.unavailabilityDialog.timeEnd',
                        }"
                        @update-time="updateDateTimeEnd"
                      ></dateAndHourPicker>
                      <span v-if="showDateError" class="red--text">
                        {{ $t("lectureActivity.error.past-date") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else>
                  {{ $t("activity.messages.no_selected") }}
                </v-card-text>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="secondary my-4"></v-divider>

      <activity-simple-list
        :language="lecture.languageId"
        :level="lecture.levelId"
        :present-activities="presentActivities"
        @selected="selectActivity"
      ></activity-simple-list>
    </v-card-text>
    <v-card-actions>
      <v-row justify="space-around">
        <v-btn color="secondary" @click="cancelEdit()">
          <v-icon class="mr-2">cancel</v-icon>
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn
          color="success"
          @click="saveActivity()"
          :disabled="!validActivity"
        >
          <v-icon class="mr-2">save</v-icon>
          {{ $t("actions.save") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
  <loading-page v-else></loading-page>
</template>

<script>
import {
  dateArrayToDate,
  dateArrayToUTCDateFormatted,
} from "@/common/conversion-utils";
import ActivitySimpleList from "@/mockups/content-cloud/activity/ActivitySimpleList";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import ActivitiesSelectedList from "@/mockups/content-cloud/_components/ActivitiesSelectedList.vue";

const LectureActivityEntityRepository = RepositoryFactory.get(
  "LectureActivityEntityRepository"
);
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ActivityForm",
  components: {
    LoadingPage,
    ActivitySimpleList,
    DateAndHourPicker,
    ActivitiesSelectedList,
  },
  props: {
    activity: {
      type: Object,
      required: false,
    },
    lecture: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validForm: true,
      loading: false,
      homework: false,
      activitiesSelected: [],
      presentActivities: [],
      showSelectedError: false,
      date: {
        endDate: null,
        endTime: ["23", "59"],
      },
      showDateError: false,
    };
  },
  computed: {
    validActivity() {
      return (
        this.validForm &&
        (!this.homework ||
          (this.date.endDate && this.date.endTime && !this.showDateError))
      );
    },
  },
  watch: {
    date: {
      handler() {
        // Shows an error if the date is past
        this.showDateError =
          new Date() >
          dateArrayToDate([...this.date.endDate, ...this.date.endTime]);
      },
      deep: true,
    },
    homework(newValue) {
      /*
       * If no homework is being added, it is filtered by the class activities
       *   already added in addition to the homework that are of type link
       * */
      const activities = newValue
        ? this.lecture.homework
        : this.lecture.inclassActivities.concat(
            this.lecture.homework.filter(
              (homework) => homework.resourceType === "LINK"
            )
          );
      this.activitiesSelected = this.activitiesSelected.filter(
        (actSel) =>
          activities.findIndex((act) => act.activityId === actSel.id) === -1
      );
      this.presentActivities = activities
        .map((act) => act.activityId)
        .concat(this.activitiesSelected.map((el) => el.id));
    },
  },
  created() {
    this.presentActivities = this.lecture.inclassActivities
      .concat(
        this.lecture.homework.filter(
          (homework) => homework.resourceType === "LINK"
        )
      )
      .map((a) => a.activityId);

    if (this.activity) {
      ActivityEntityRepository.get(this.activityId).then((res) => {
        this.activitiesSelected.push({ id: 0, activity: res });
      });
    }
  },
  methods: {
    selectActivity(activity) {
      if (
        !this.activitiesSelected.map((el) => el.activity).includes(activity)
      ) {
        this.activitiesSelected.push(activity);
        this.presentActivities.push(activity.id);
        this.showSelectedError = false;
        if (activity.resourceType === "LINK" && !this.homework) {
          this.homework = true;
          this.$notify({
            title: this.$t("activity.messages.request_change"),
            text: this.$t("activity.messages.link_as_homework"),
            type: "warn",
          });
        }
      }
    },
    cancelEdit() {
      this.activitiesSelected = [];
      this.$emit("cancel");
    },
    saveActivity() {
      this.$refs.form.validate();
      if (this.activitiesSelected.length === 0) {
        this.showSelectedError = true;
        return;
      }
      if (this.validForm) {
        let dueDate = null;
        if (this.homework) {
          dueDate = dateArrayToUTCDateFormatted([
            ...this.date.endDate,
            ...this.date.endTime,
          ]);
        }
        const activitiesToAdd = this.activitiesSelected.map((act) => {
          return {
            activityId: act.id,
            lectureId: this.lecture.id,
          };
        });
        LectureActivityEntityRepository.batchSave(activitiesToAdd, dueDate)
          .then(() => this.$emit("update"))
          .catch(() =>
            this.$log.debug(
              "Error creating lecture activities for lecture: " +
                this.lecture.id
            )
          );
      }
    },
    removeActivity(item) {
      this.activitiesSelected.splice(this.activitiesSelected.indexOf(item), 1);
      this.presentActivities.splice(this.presentActivities.indexOf(item.id), 1);
    },
    updateDateTimeEnd(data) {
      this.date.endDate = data.date ? data.date : null;
      this.date.endTime = data.time ? data.time : null;
    },
    checkIfLink() {
      return this.activitiesSelected.some(
        (activity) => activity.resourceType === "LINK"
      );
    },
  },
};
</script>
