<template>
  <v-container fluid>
    <a ref="hiddenDownloader" class="d-none" />
    <v-card v-if="!isLoading && lecture">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-block">
            <span v-if="lecture.levelTest" class="headline no-split-words">
              {{ $t("lecture.levelTest") }}
              <v-chip color="warning" class="ma-2">
                {{ $t(`languages.${lecture.language}`) }}
              </v-chip>
            </span>
            <router-link
              v-else-if="userCanViewDetail"
              class="headline no-split-words"
              :to="editionDetailRoute"
            >
              {{ lecture.product }}
            </router-link>
            <span v-else class="headline no-split-words">
              {{ lecture.product }}
            </span>
            <v-btn
              v-if="lecture.editionChatId"
              class="ml-2 mb-4"
              color="accent"
              icon
              tile
              @click="$EventBus.$emit('open-chat', lecture.editionChatId)"
            >
              <icon
                name="chat-simple"
                width="28"
                height="28"
                title="chat"
              ></icon>
            </v-btn>
            <br />
            <span class="headline no-split-words">
              {{ $t("lecture.headers.detail") }}
              {{ lecture.startTime | dateTimeWithTz }}
            </span>
            <br />
            <span class="title no-split-words">
              {{ $t("lecture.from") }}
              {{ lecture.startTime | dateTimeWithTz("time") }}
              {{ $t("lecture.to") }}
              {{ lecture.endTime | dateTimeWithTz("time") }}
            </span>
            <v-btn
              class="ml-2"
              @click="showScheduleChangeDialog"
              v-if="isAdmin && lecture.state === 'PLANNED'"
            >
              <v-icon color="primary">mdi-calendar-clock</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("lectureChange.action.change-schedule") }}
              </span>
            </v-btn>
            <br />
            <span class="grey--text">{{
              $t(`lecture.states.${lecture.state}`)
            }}</span>
          </v-col>

          <v-col class="text-right">
            <v-btn class="mt-2" @click="back">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
            <v-tooltip v-if="!isAdmin" open-delay="400" right>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="d-inline-block">
                  <v-btn
                    :disabled="!userCanJoin"
                    @click.stop="joinToLecture(lecture)"
                    class="ml-2 mt-2"
                  >
                    <v-icon color="success"> login </v-icon>
                    {{ $t("lecture.join") }}
                  </v-btn>
                </div>
              </template>
              <span v-if="userCanJoin">{{ $t("lecture.join") }}</span>
              <span v-else>{{ $t("lecture.join-not-ready") }}</span>
            </v-tooltip>

            <!-- Lecture evaluation by student or tutor -->
            <v-btn
              v-if="
                !isHeadmaster &&
                ((isAdult && isAnyStudent) || isTutor) &&
                lecture.state === 'DONE'
              "
              class="ml-2 mt-2"
              @click.stop="showLectureEvaluationDialog"
            >
              <v-icon color="success">mdi-star</v-icon>
              <span v-if="!lecture.evaluation">
                {{ $t("lectureEvaluation.messages.create") }}
              </span>
              <span v-else>
                {{
                  canEditLectureEvaluation
                    ? $t("lectureEvaluation.messages.edit")
                    : $t("lectureEvaluation.messages.view")
                }}</span
              >
            </v-btn>

            <!-- Botón para cancelar una clase -->
            <v-btn
              class="ml-2 mt-2"
              v-if="isAdmin && lecture.state === 'PLANNED'"
              @click="showCancelDialog"
            >
              <v-icon color="error">close</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.cancel") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="isAnyTeacher || isAdmin">
        <span class="title">{{ $t("lecture.stats.stats") }}</span>
        <div class="body-1">
          {{ $t("lecture.stats.mean_grade") }}:
          {{ meanGrade || $t("lecture.stats.no_data") }}
        </div>
        <div class="body-1">
          {{ $t("lecture.stats.mean_time") }}:
          {{ meanTime || $t("lecture.stats.no_data") }}
        </div>
        <v-row>
          <v-col cols="12">
            <span class="title">
              {{ $t("lecture.prop.students") }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="2"
            v-for="student in lecture.students"
            :key="student.id"
          >
            <student-lecture-details-preview
              :student="student"
              :lecture="lecture"
              :is-same-teacher="isSameTeacher"
              @saveEvaluation="saveStudentEvaluation"
              @saveLevel="saveStudentLevel"
            ></student-lecture-details-preview>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="title" v-if="lecture.students.length === 0">
              {{ $t("lecture.noStudents") }}</span
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        v-if="isAnyStudent || isTutor || isAdmin || isSupervisorOfTeacher"
      >
        <v-row>
          <v-col cols="12">
            <span class="title">
              {{ $t("lecture.prop.teacher") }}
            </span>
            <v-btn
              v-if="isAdmin && lecture.state === 'PLANNED'"
              class="ml-2"
              @click="showLectureChangeDialog"
            >
              <v-icon color="primary">mdi-account-switch</v-icon>
              {{ $t("lectureChange.action.change-teacher") }}
            </v-btn>

            <v-btn
              v-if="
                isSupervisorOfTeacher &&
                !lecture.teacherSupervisorReview &&
                (lecture.state === 'DONE' || lecture.state === 'DOING')
              "
              class="ml-2"
              @click.native="teacherSupervisorReviewDialog = true"
            >
              <v-icon color="success">rate_review</v-icon>
              {{ $t("teacherSupervisorReview.action.review") }}
            </v-btn>
            <v-btn
              v-if="
                (isAdmin || isSupervisorOfTeacher) &&
                lecture.teacherSupervisorReview
              "
              class="ml-2"
              @click.native="teacherSupervisorReviewDialog = true"
            >
              <v-icon
                :color="!canEditSupervisorEvaluation ? 'primary' : 'warning'"
              >
                rate_review
              </v-icon>
              {{
                !canEditSupervisorEvaluation
                  ? $t("teacherSupervisorReview.action.view_review")
                  : $t("teacherSupervisorReview.action.review")
              }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" :key="lecture.teacher.id">
            <teacher-lecture-details-preview
              :lecture="lecture"
              :is-supervisor-of-teacher="isSupervisorOfTeacher"
            ></teacher-lecture-details-preview>
          </v-col>
        </v-row>
        <v-row v-if="isAnyStudent">
          <lecture-detail-uploadable-activities
            v-if="isStudent"
            :lecture-id="lecture.id"
            @saved="$emit('saved', ...args)"
          ></lecture-detail-uploadable-activities>
          <v-col cols="12" class="mt-2">
            <span class="title">
              {{ $t("lecture.prop.grades") }}
            </span>
          </v-col>
          <v-col cols="12" class="text-center">
            <student-interactions-table
              :lecture-id="$route.params.id"
            ></student-interactions-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="isAnyTeacher || isAdmin">
        <div v-if="lecture.levelTest && !isAdmin">
          <v-row>
            <v-col cols="10">
              <span class="title">
                {{ $t("lecture.prop.level_evaluation") }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <student-interactions-table
                :lecture-id="$route.params.id"
                :student-id="lecture.students[0].id"
              ></student-interactions-table>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="10">
            <span class="title">
              {{ $t("lecture.prop.in_class") }}
            </span>
          </v-col>
          <v-col
            v-if="(isSameTeacher || isSupervisorOfTeacher) && isTeacher"
            cols="4"
            md="2"
            class="text-right"
          >
            <v-btn class="mr-2" color="success" @click="openActivityDialog">
              <v-icon>add</v-icon>
              {{ $t("activity.name") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <lecture-detail-activities-table
              v-model="lecture"
              :is-same-teacher="isSameTeacher"
              :is-supervisor-of-teacher="isSupervisorOfTeacher"
              @update="fetchLecture(lecture.id)"
              @updateOrder="updateOrder"
            ></lecture-detail-activities-table>
          </v-col>
        </v-row>

        <v-row v-if="!lecture.levelTest">
          <v-col cols="10">
            <span class="title">
              {{ $t("lecture.prop.homework") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="!lecture.levelTest">
          <v-col cols="12" class="text-center">
            <lecture-detail-activities-table
              v-model="lecture"
              homework
              :is-same-teacher="isSameTeacher"
              :is-supervisor-of-teacher="isSupervisorOfTeacher"
              @update="fetchLecture(lecture.id)"
            >
            </lecture-detail-activities-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>

    <!-- Create / edit Activity dialog -->
    <v-dialog
      scrollable
      width="80%"
      v-model="activityFormDialog"
      @click:outside="closeActivityDialog"
    >
      <lecture-activity-form
        v-if="activityFormDialog"
        :lecture="lecture"
        @cancel="closeActivityDialog"
        @update="updateLecture"
      ></lecture-activity-form>
    </v-dialog>
    <v-dialog
      v-model="lectureEvaluationDialog"
      v-if="lectureEvaluationDialog"
      width="80%"
      @click:outside="closeLectureEvaluationDialog(false)"
    >
      <lecture-evaluation-form
        v-model="lecture.evaluation"
        :studentId="isTutor ? this.studentId : null"
        :lectureId="lecture.id"
        :editable="canEditLectureEvaluation || lecture.evaluation == null"
        @cancel="closeLectureEvaluationDialog"
        @save="closeLectureEvaluationDialog"
      >
      </lecture-evaluation-form>
    </v-dialog>
    <v-dialog
      v-model="teacherSupervisorReviewDialog"
      v-if="teacherSupervisorReviewDialog && (isSupervisorOfTeacher || isAdmin)"
      width="80%"
      @click:outside="closeTeacherSupervisorReviewDialog(false)"
    >
      <teacher-supervisor-review-form
        v-model="lecture.teacherSupervisorReview"
        :lectureId="lecture.id"
        :canEdit="
          !lecture.teacherSupervisorReview || canEditSupervisorEvaluation
        "
        @cancel="closeTeacherSupervisorReviewDialog"
        @save="closeTeacherSupervisorReviewDialog"
      >
      </teacher-supervisor-review-form>
    </v-dialog>
    <v-dialog
      v-model="lectureChangeDialog"
      v-if="lectureChangeDialog"
      width="80%"
      @click:outside="closeLectureChangeDialog"
    >
      <lecture-change-form
        :lecture="lecture"
        @cancel="closeLectureChangeDialog"
        @save="closeLectureChangeDialog"
      >
      </lecture-change-form>
    </v-dialog>
    <modal-dialog
      :dialog="cancelDialog"
      @cancel="cancelDialog = false"
      @submit="cancelLecture"
      :title="$t('lecture.headers.cancelDialog')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('yes')"
      :cancelText="$t('no')"
      :content="$t('lecture.messages.cancel')"
      :retain-focus="false"
    ></modal-dialog>

    <v-dialog
      v-if="scheduleChangeDialog"
      v-model="scheduleChangeDialog"
      fullscreen
      @click:outside="closeScheduleChangeDialog"
    >
      <schedule-change-form
        :lecture="lecture"
        @closeDialog="closeScheduleChangeDialog"
        @save="closeScheduleChangeDialog"
      >
      </schedule-change-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { dateArrayToDate } from "@/common/conversion-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { joinToLecture, userCanJoin } from "@/common/lecture-common";
import checkInvalidID from "@/common/checkInvalidID";
import ModalDialog from "@/components/modal_dialog/ModalDialog.vue";

import LectureActivityForm from "@/mockups/content-cloud/_components/LectureActivityForm";
import LectureEvaluationForm from "@/mockups/admin/lectureEvaluations/LectureEvaluationForm";
import LectureChangeForm from "@/mockups/admin/lectures/lecture-detail/LectureChangeForm";
import ScheduleChangeForm from "@/mockups/admin/lectures/lecture-detail/ScheduleChangeForm";
import TeacherSupervisorReviewForm from "@/mockups/admin/teachersupervisoreview/TeacherSupervisorReviewForm";

import auth from "@/common/auth";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import LectureDetailActivitiesTable from "@/mockups/admin/lectures/lecture-detail/LectureDetailActivitiesTable";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LectureDetailUploadableActivities from "@/mockups/admin/lectures/lecture-detail/LectureDetailUploadableActivities";
import StudentLectureDetailsPreview from "@/mockups/admin/lectures/lecture-detail/StudentLectureDetailsPreview";
import TeacherLectureDetailsPreview from "@/mockups/admin/lectures/lecture-detail/TeacherLectureDetailsPreview";
import StudentInteractionsTable from "@/mockups/admin/lectures/lecture-detail/StudentInteractionsTable";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);
const StudentLevelEntityRepository = RepositoryFactory.get(
  "StudentLevelEntityRepository"
);

export default {
  name: "LectureDetail",
  props: {
    studentId: {
      type: Number,
      required: false,
    },
  },
  components: {
    StudentInteractionsTable,
    TeacherLectureDetailsPreview,
    StudentLectureDetailsPreview,
    LectureDetailUploadableActivities,
    LectureDetailActivitiesTable,
    TeacherSupervisorReviewForm,
    LectureEvaluationForm,
    LoadingPage,
    LectureActivityForm,
    LectureChangeForm,
    ModalDialog,
    ScheduleChangeForm,
  },
  data() {
    return {
      loading: false,
      file: {
        loading: false,
      },
      lecture: null,
      activityFormDialog: false,
      lectureEvaluationDialog: false,
      teacherSupervisorReviewDialog: false,
      lectureChangeDialog: false,
      cancelDialog: false,
      scheduleChangeDialog: false,
      meanGrade: null,
      meanTime: null,
    };
  },
  computed: {
    ...mapAuthGetter([
      "isAdmin",
      "isAnyStudent",
      "isAnyTeacher",
      "isTeacher",
      "isHeadmaster",
      "isAdult",
      "isTutor",
      "isStudent",
    ]),
    isLoading() {
      return this.loading;
    },
    editionDetailRoute() {
      if (this.isAnyTeacher) {
        return {
          name: "ProfileEditionsDetailTab",
          params: { editionId: this.lecture.editionId, backPrevious: true },
        };
      } else if (this.isAdmin) {
        return {
          name: "Admin Edition Detail",
          params: { editionId: this.lecture.editionId, backPrevious: true },
        };
      } else {
        return {};
      }
    },
    userCanJoin() {
      return userCanJoin(this.lecture);
    },
    isSupervisorOfTeacher() {
      return this.lecture?.supervisorTeacherId === auth.getUser().roleId;
    },
    isSameTeacher() {
      return this.lecture?.teacher.id === auth.getUser().roleId;
    },
    userCanViewDetail() {
      return (
        (this.isAnyTeacher &&
          this.lecture.teacher.id === auth.getUser().roleId) ||
        this.isAdmin
      );
    },
    canEditLectureEvaluation() {
      // The user can only edit an assessment if less than 24 hours have passed since its creation
      if (this.lecture.evaluation) {
        const dateNow = new Date(
          Date.now() + new Date().getTimezoneOffset() * 60000
        );
        const evaluationDate = dateArrayToDate(this.lecture.evaluation.date);
        const after24hoursOfEvaluation = new Date(
          evaluationDate.setHours(evaluationDate.getHours() + 24)
        );

        return (
          after24hoursOfEvaluation > dateNow &&
          this.lecture.evaluation.state === "PENDING"
        );
      } else {
        return false;
      }
    },
    canEditSupervisorEvaluation() {
      // The user can only edit an assessment if less than 24 hours have passed since its creation
      if (this.isSupervisorOfTeacher && this.lecture.teacherSupervisorReview) {
        const dateNow = new Date(
          Date.now() + new Date().getTimezoneOffset() * 60000
        );
        const evaluationDate = dateArrayToDate(
          this.lecture.teacherSupervisorReview.date
        );
        const after24hoursOfEvaluation = new Date(
          evaluationDate.setHours(evaluationDate.getHours() + 24)
        );

        return after24hoursOfEvaluation > dateNow;
      }
      return false;
    },
  },
  created() {
    this.fetchLecture(this.$route.params.id);
  },
  methods: {
    async fetchLecture(id) {
      this.loading = true;
      if (this.isAnyTeacher || this.isHeadmaster) {
        await LectureEntityRepository.getByTeacher(id)
          .then((res) => {
            this.lecture = res;
            this.downloadStudentPhotos();
            if (this.isSupervisorOfTeacher) {
              this.downloadTeacherPhoto();
            }
            this.lectureMeanGrade(res);
          })
          .catch((err) => checkInvalidID(err));
      } else if (this.isAnyStudent) {
        await LectureEntityRepository.getByStudent(id)
          .then((res) => {
            this.lecture = res;
            this.downloadTeacherPhoto();
          })
          .catch((err) => checkInvalidID(err));
      } else if (this.isTutor) {
        await LectureEntityRepository.getByTutor(id, {
          params: { studentId: this.studentId },
        })
          .then((res) => {
            this.lecture = res;
            this.downloadTeacherPhoto();
          })
          .catch((err) => checkInvalidID(err));
      } else if (this.isAdmin) {
        await LectureEntityRepository.getByAdmin(id)
          .then((res) => {
            this.lecture = res;
            this.downloadTeacherPhoto();
            this.downloadStudentPhotos();
          })
          .catch((err) => checkInvalidID(err));
      }
      if (this.lecture && this.lecture.inclassActivities) {
        this.lecture.inclassActivities.sort((a, b) => a.order - b.order);
      }
      this.loading = false;
    },
    downloadStudentPhotos() {
      this.lecture.students.forEach(
        async (s) => (s.photo = await this.fetchPhoto(s.userId))
      );
    },
    async downloadTeacherPhoto() {
      this.lecture.teacher.photo = await this.fetchPhoto(
        this.lecture.teacher.userId
      );
    },
    async fetchPhoto(userId) {
      if (userId) {
        userId = parseInt(userId);
        return await UserDataEntityRepository.getPhoto(userId)
          .then((data) => {
            if (data.size > 0) {
              return URL.createObjectURL(data);
            }
          })
          .catch(() =>
            this.$log.debug(
              "Error fetching profile photo for user with ID " + userId
            )
          );
      }
      return require("@/assets/icon_visits.png");
    },
    updateLecture() {
      this.fetchLecture(this.$route.params.id);
      this.closeActivityDialog();
    },
    lectureMeanGrade(lecture) {
      if (
        lecture.inclassActivities.length > 0 &&
        lecture.inclassActivities.some(
          (act) => act.activityInteractions != null
        )
      ) {
        let grades = 0,
          time = 0,
          length = 0;
        lecture.inclassActivities.forEach((activity) => {
          if (activity.activityInteractions) {
            activity.activityInteractions.forEach((interaction) => {
              grades += interaction.grade;
              time += interaction.timeConsumed;
            });
            length += activity.activityInteractions.length;
          }
        });
        this.meanGrade = grades / length;
        this.meanTime = time / length;
      } else {
        this.meanGrade = null;
        this.meanTime = null;
      }
    },
    cancelLecture() {
      this.loading = true;
      LectureEntityRepository.cancelLecture(this.lecture.id)
        .then((res) => {
          this.$notify({
            title: this.$t("lecture.messages.cancel-success"),
            type: "success",
          });
          this.lecture.state = res.state;
        })
        .catch(() =>
          this.$log.debug("Error canceling lecture " + this.lecture.id)
        )
        .finally(() => {
          this.closeCancelDialog();
          this.loading = false;
        });
    },
    back() {
      if (this.isTutor) {
        this.$router.go(-1);
        return;
      }
      const route = this.isAdmin ? "Lecture List" : "Close Lecture List";
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
    showLectureEvaluationDialog() {
      this.lectureEvaluationDialog = true;
    },
    closeLectureEvaluationDialog(newEvaluation) {
      if (newEvaluation) {
        this.lecture.evaluation = newEvaluation;
      }
      this.lectureEvaluationDialog = false;
    },
    showLectureChangeDialog() {
      this.lectureChangeDialog = true;
    },
    closeLectureChangeDialog() {
      this.lectureChangeDialog = false;
      this.fetchLecture(this.lecture.id);
    },
    showScheduleChangeDialog() {
      this.scheduleChangeDialog = true;
    },
    closeScheduleChangeDialog() {
      this.scheduleChangeDialog = false;
      this.fetchLecture(this.lecture.id);
    },
    saveStudentEvaluation(studentId, evaluation) {
      const student = this.lecture.students.find(
        (student) => student.id === studentId
      );
      if (student) {
        student.evaluation = evaluation;
        student.canEditEvaluation = true;
      }
    },
    saveStudentLevel(studentId, level) {
      const student = this.lecture.students.find(
        (student) => student.id === studentId
      );
      if (student) {
        student.level = level;
      }
    },
    closeTeacherSupervisorReviewDialog(newEvaluation) {
      if (newEvaluation) {
        this.lecture.teacherSupervisorReview = newEvaluation;
      }
      this.teacherSupervisorReviewDialog = false;
    },
    openActivityDialog() {
      this.activityFormDialog = true;
    },
    closeActivityDialog() {
      this.activityFormDialog = false;
    },
    showCancelDialog() {
      this.cancelDialog = true;
    },
    closeCancelDialog() {
      this.cancelDialog = false;
    },
    updateOrder(activity, order) {
      if (activity.order < order) {
        this.lecture.inclassActivities.forEach((el) => {
          if (el.order > activity.order && el.order <= order) {
            el.order--;
          }
        });
      } else {
        this.lecture.inclassActivities.forEach((el) => {
          if (el.order < activity.order && el.order >= order) {
            el.order++;
          }
        });
      }
      this.lecture.inclassActivities.find((el) => el.id === activity.id).order =
        order;
      this.lecture.inclassActivities.sort((a, b) => a.order - b.order);
    },
    joinToLecture,
  },
};
</script>
