<template>
  <v-container>
    <balance-detail v-model="wallet" v-show="!loading"></balance-detail>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import BalanceDetail from "@/mockups/economy/wallet/BalanceDetail";
import LoadingPage from "@/components/loading-page/LoadingPage";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
export default {
  name: "StudentBalanceTab",
  components: {
    BalanceDetail,
    LoadingPage,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      user: auth.getUser(),
      wallet: {},
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      WalletEntityRepository.getByStudentId(this.studentId)
        .then((data) => (this.wallet = data))
        .catch(() =>
          this.$log.debug(
            "Error fetching wallet for student with ID " + this.studentId
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
