<template>
  <header
    class="clrm-header"
    :class="[
      { 'clrm-header--active': headerActive },
      'clrm-header--' + classroomMode,
    ]"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div class="clrm-container-fluid">
      <div class="clrm-header__content">
        <div class="clrm-header__logo">
          <ClassroomLogo></ClassroomLogo>
        </div>
        <ClrmToolbarPrimary
          :classroomMode="classroomMode"
          @click="onClickToolbarPrimary"
          :screenSharing="screenSharing"
          :recording="isRecording"
          :connected="sessionState == sessionStatusEnum.JOINED"
        ></ClrmToolbarPrimary>

        <div class="clrm-header__recording">
          <v-tooltip bottom v-if="isRecording">
            <template v-slot:activator="{ on, attrs }">
              <div slot="activator" v-bind="attrs" v-on="on" class="blob"></div>
            </template>
            <span>{{ $t("classroom.global.recording-alert") }}</span>
          </v-tooltip>
        </div>

        <ClrmTimer
          v-if="startTime"
          :startTime="startTime"
          :endTime="endTime"
        ></ClrmTimer>
        <ClrmToolbarSecondary
          @click="onClickToolbarSecondary"
          :classroomMode="classroomMode"
        ></ClrmToolbarSecondary>
      </div>
    </div>
    <div v-show="false" class="screen-container" ref="screenContainer"></div>
  </header>
</template>

<script>
let currentScreenCanvas = null;
import ClrmToolbarPrimary from "@/components/clrm/clrm-toolbar-primary/ClrmToolbarPrimary.vue";
import ClrmToolbarSecondary from "@/components/clrm/clrm-toolbar-secondary/ClrmToolbarSecondary.vue";
import ClrmTimer from "@/components/clrm/clrm-timer/ClrmTimer.vue";
import ClassroomLogo from "./util/ClassroomLogo.vue";
import stomp from "@/common/StompClient";
import { ClassroomMessageType } from "@/common/StompClientActions";
import {
  mapClassroomStateGetter,
  getClassroomState,
} from "./store/mapClassroomStateGetter";
import classroomStateGetter from "./store/classroomStateGetter";
import ParticipantSessionStatus from "@/enumerates/ParticipantSessionStatus";

export default {
  components: {
    ClrmToolbarPrimary,
    ClrmToolbarSecondary,
    ClrmTimer,
    ClassroomLogo,
  },
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
    zoom: {
      type: Object,
      required: true,
    },
    startTime: {
      required: true,
      type: Date,
    },
    chatId: {
      required: false,
      type: Number,
    },
    endTime: {
      required: true,
      type: Date,
    },
  },
  data() {
    return {
      headerActive: false,
      recordedBlobs: [],
      mediaRecorder: null,
      screenStream: [],
      userMic: [],
      recordingStartTime: null,
      state: getClassroomState(),
      sessionStatusEnum: ParticipantSessionStatus,
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.mediaRecorder != null) {
      this.stopRecording();
      this.removeDataRecordings();
    }
    if (this.screenSharing) {
      this.zoom.stopShareScreen();
      this._removeCurrentScreenCanvas();
    }
  },
  computed: {
    ...mapClassroomStateGetter([
      "isRecording",
      "sessionState",
      "deviceSharing",
    ]),
    screenSharing() {
      return (
        this.deviceSharing != null &&
        this.deviceSharing == classroomStateGetter.getCurrentDevice()?.userId
      );
    },
  },
  watch: {
    deviceSharing(oldVal, newVal) {
      if (
        newVal == null &&
        oldVal == classroomStateGetter.getCurrentDevice()?.userId
      ) {
        this._removeCurrentScreenCanvas();
      }
    },
  },
  methods: {
    mouseEnter() {
      this.timerMouse = setTimeout(() => {
        this.headerActive = true;
      }, 200);
    },
    mouseLeave() {
      this.headerActive = false;
      clearTimeout(this.timerMouse);
    },
    onClickToolbarPrimary(operation) {
      this.$log.debug("Click on menu", operation);
      if (this.headerActive) {
        switch (operation) {
          case "share-screen": {
            if (
              classroomStateGetter.getDeviceSharing() ===
              classroomStateGetter.getCurrentDevice().userId
            ) {
              // este dispositivo está compartiendo, se pararía
              this._removeCurrentScreenCanvas();
              this.zoom.stopShareScreen();
            }
            // else if (this.zoom.state.loggedLemsiUser.sharing) {
            //   // estoy compartiendo en otro dispositivo
            //   alert(
            //     "Estás compartiendo en otro dispositivo, primero páralo en el otro"
            //   );
            else {
              // puede empezar a compartir
              this.zoom.startShareScreen(this._createNewScreenCanvas());
            }
            break;
          }
          case "join": {
            this.$emit("join");
            break;
          }
          case "chat": {
            this.$emit("open-chat", this.chatId);
            break;
          }
          case "participants": {
            this.$emit("open-participants");
            break;
          }
          case "record": {
            if (this.isRecording) {
              this.stopRecording();
            } else {
              this.startRecording();
            }
            break;
          }
          default: {
            this.$emit(operation);
          }
        }
      }
    },
    async onClickToolbarSecondary(operation) {
      this.$log.debug("Click on secondary menu", operation);
      if (this.headerActive) {
        switch (operation) {
          case "exit": {
            await this.zoom.currentDeviceLeave();
            this.$router.push({ name: "Close Lecture List" });
            break;
          }
          case "end-lecture": {
            this.$emit("end-lecture");
            break;
          }
          case "support": {
            this.$emit("support");
            break;
          }
        }
      }
    },
    _removeCurrentScreenCanvas() {
      if (currentScreenCanvas) {
        this.$refs.screenContainer.removeChild(currentScreenCanvas);
        currentScreenCanvas = null;
      }
    },
    _createNewScreenCanvas() {
      this._removeCurrentScreenCanvas();
      if (isSupportWebCodecs()) {
        currentScreenCanvas = document.createElement("video");
      } else {
        currentScreenCanvas = document.createElement("canvas");
      }
      this.$refs.screenContainer.appendChild(currentScreenCanvas);
      return currentScreenCanvas;
    },
    async startRecording() {
      this.recordedBlobs = [];
      this.screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      this.userMic = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      try {
        const mixedStream = new MediaStream();

        this.screenStream.getVideoTracks().forEach(function (videoTrack) {
          mixedStream.addTrack(videoTrack);
        });

        if (this.screenStream.getAudioTracks().length > 0) {
          var context = new AudioContext();
          var audioDestination = context.createMediaStreamDestination();

          const systemSource = context.createMediaStreamSource(
            this.screenStream
          );
          const systemGain = context.createGain();
          systemGain.gain.value = 1.0;
          systemSource.connect(systemGain).connect(audioDestination);

          if (this.userMic && this.userMic.getAudioTracks().length > 0) {
            const micSource = context.createMediaStreamSource(this.userMic);
            const micGain = context.createGain();
            micGain.gain.value = 1.0;
            micSource.connect(micGain).connect(audioDestination);
          }

          audioDestination.stream
            .getAudioTracks()
            .forEach(function (audioTrack) {
              mixedStream.addTrack(audioTrack);
            });
        } else {
          this.userMic.getAudioTracks().forEach(function (micTrack) {
            mixedStream.addTrack(micTrack);
          });
        }

        this.mediaRecorder = new MediaRecorder(mixedStream, {
          mimeType: "video/webm;codecs=vp8,opus",
        });
      } catch (e) {
        this.$log.debug("Exception while creating MediaRecorder:", e);
        return;
      }

      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.onstop = this.download;
      this.mediaRecorder.start();
      this.recordingStartTime = new Date();
      await stomp.sendMessageToClassroom(this.$route.params.id, {
        type: ClassroomMessageType.RECORDING_STARTED,
      });
    },
    handleDataAvailable(event) {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    },
    async stopRecording() {
      this.mediaRecorder.stop();
      if (
        classroomStateGetter.getSessionState() ==
        ParticipantSessionStatus.JOINED
      ) {
        await stomp.sendMessageToClassroom(this.$route.params.id, {
          type: ClassroomMessageType.RECORDING_ENDED,
        });
      }
    },
    download() {
      const blob = new Blob(this.recordedBlobs, {
        type: "video/webm",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = this.getVideoName();
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        this.removeDataRecordings();
      }, 100);
    },
    removeDataRecordings() {
      this.recordedBlobs = [];
      this.mediaRecorder = null;
      this.screenStream.getTracks().forEach((track) => track.stop());
      this.userMic.getTracks().forEach((track) => track.stop());
    },
    getVideoName() {
      let date_now = new Date();
      let seconds = Math.floor((date_now - this.recordingStartTime) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      minutes = minutes - hours * 60;
      return `${this.$i18n.t("classroom.global.file-name", {
        id: `${this.$route.params.id}`,
        hours: `${hours}`,
        mins: `${minutes}`,
      })}`;
    },
  },
};
function isSupportWebCodecs() {
  return typeof window.MediaStreamTrackProcessor === "function";
}
</script>
<style></style>
