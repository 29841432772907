<template>
  <v-card>
    <v-card-title class="grey lighten-1 white--text">
      <h2>
        {{ $t("word.headers.form") }}
      </h2>
    </v-card-title>
    <v-card-text class="mt-4">
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="word.name"
              :label="$t('word.prop.name')"
              :rules="[(v) => !!v || $t('word.error.required')]"
              dense
              outlined
              class="required"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :label="pronunciationLabel"
              @input="convertToPinyin"
              dense
              outlined
              type="text"
              :suffix="isPinyin ? word.pronunciation : ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="word.definition"
              :label="$t('word.prop.definition')"
              dense
              outlined
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="selectedGrammarTopics"
              clearable
              :items="grammarTopics"
              :label="$t('word.prop.grammar_topics')"
              :loading="topicsLoading"
              item-text="name"
              dense
              multiple
              outlined
              return-object
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="selectedVocabTopics"
              clearable
              :items="vocabTopics"
              :label="$t('word.prop.vocab_topics')"
              :loading="topicsLoading"
              item-text="name"
              dense
              multiple
              outlined
              return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" justify-sm="end">
          <v-btn @click.native="$emit('cancel')" class="mr-2">
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn color="success" @click="saveWord" class="mr-4">
            {{ $t("actions.save") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const toneConvert = require("pinyin-tone-convert");

/**
 * This component is used by WordlistForm.
 * Check that everything works correctly there if you modified this component.
 * **/
export default {
  name: "WordForm",
  props: {
    wordProp: {
      type: Object,
      required: false,
    },
    language: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      word: {},
      grammarTopics: [],
      vocabTopics: [],
      selectedVocabTopics: [],
      selectedGrammarTopics: [],
      topicsLoading: false,
    };
  },
  created() {
    if (this.wordProp) {
      this.word = { ...this.wordProp };
    }
    this.fetchTopics();
  },
  computed: {
    pronunciationLabel() {
      return this.language && this.language.name == "CHINESE"
        ? this.$t("word.prop.pronunciation.pinyin")
        : this.$t("word.prop.pronunciation.latin");
    },
    isPinyin() {
      return this.language && this.language.name == "CHINESE";
    },
  },
  methods: {
    fetchTopics() {
      this.topicsLoading = true;
      // We add a filter (if it is specified) to get only topics related with the selected language
      // and get only GRAMMAR and VOCABULARY topics
      let options = {
        params: {
          languages: this.langIds,
          types: [2, 3],
        },
      };
      return TopicEntityRepository.getAll(options)
        .then((res) => {
          res.content.forEach((item) => {
            if (item.topicType.name == "GRAMMAR") {
              this.grammarTopics.push(item);
              if (
                this.word.wordTopics &&
                this.word.wordTopics.length !== 0 &&
                this.grammarTopics
              ) {
                this.selectedGrammarTopics = this.grammarTopics.filter(
                  (topic) =>
                    this.word.wordTopics.findIndex(
                      (wt) => wt.topicId === topic.id
                    ) !== -1
                );
              }
            } else if (item.topicType.name == "VOCABULARY") {
              this.vocabTopics.push(item);
              if (
                this.word.wordTopics &&
                this.word.wordTopics.length !== 0 &&
                this.vocabTopics
              ) {
                this.selectedVocabTopics = this.vocabTopics.filter(
                  (topic) =>
                    this.word.wordTopics.findIndex(
                      (wt) => wt.topicId === topic.id
                    ) !== -1
                );
              }
            }
          });
        })
        .finally(() => (this.topicsLoading = false));
    },
    saveWord() {
      if (this.$refs.form.validate()) {
        const oldWordTopics = this.word.wordTopics || [];
        this.word.wordTopics = [];
        this.selectedGrammarTopics.forEach((topic) => {
          const oldWordTopic = oldWordTopics.find(
            (wt) => wt.topicId === topic.id
          );
          if (oldWordTopic) {
            this.word.wordTopics.push(oldWordTopic);
          } else {
            this.word.wordTopics.push({
              topicId: topic.id,
              topicName: topic.name,
            });
          }
        });

        this.selectedVocabTopics.forEach((topic) => {
          const oldWordTopic = oldWordTopics.find(
            (wt) => wt.topicId === topic.id
          );
          if (oldWordTopic) {
            this.word.wordTopics.push(oldWordTopic);
          } else {
            this.word.wordTopics.push({
              topicId: topic.id,
              topicName: topic.name,
            });
          }
        });
        this.$emit("save", this.word);
      }
    },
    convertToPinyin(pronunciation) {
      if (this.isPinyin) {
        this.$set(this.word, "pronunciation", toneConvert(pronunciation));
      } else {
        this.$set(this.word, "pronunciation", pronunciation);
      }
    },
  },
};
</script>

<style scoped></style>
