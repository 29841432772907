<template>
  <component :is="defaultStyle">
    <hsc-window
      class="chat__window"
      :resizable="true"
      overflow="hidden"
      :width.sync="width"
      :height.sync="height"
      :left.sync="xpos"
      :top.sync="ypos"
      :isScrollable="true"
      :minWidth="340"
      :minHeight="420"
      style="z-index: 1000"
    >
      <div class="chat__content">
        <div class="chat__header" @mousedown.stop.prevent="mousedown">
          <div
            class="chat__header__back"
            v-if="chatOpened || formOpened || search"
          >
            <button type="button" class="chat__header__button" @click="back()">
              <icon
                name="chevron-left"
                width="14"
                height="14"
                :title="$t('chat.close')"
              ></icon>
            </button>
          </div>
          <div class="chat__header__title">
            <span v-if="chatOpened">{{ openedName }}</span>
            <span v-if="!chatOpened && !formOpened && !search">{{
              $t("chat.list")
            }}</span>
            <span v-if="formOpened">{{ $t("chat.form-title") }}</span>
            <span v-if="search">{{ $t("chat.search-chats.search") }}</span>
          </div>
          <v-btn
            v-if="showRemoveFilterIcon"
            icon
            color="white"
            @click="clearFilters"
          >
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
          <v-btn
            v-if="!search && !chatOpened && !formOpened"
            icon
            color="white"
            @click="search = true"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
          <div class="chat__header__close">
            <button
              type="button"
              class="chat__header__button"
              @click="closeWindow()"
            >
              <icon
                name="close"
                width="16"
                height="16"
                :title="$t('chat.back')"
              ></icon>
            </button>
          </div>
        </div>

        <div class="chat__working" v-if="showChatList">
          <div
            class="chat__roomlist"
            :class="{ 'chat__roomlist--withactions': isAdmin }"
            id="chat__roomlist"
          >
            <ChatList
              :chatRoomList="chatRoomList"
              :getChat="getChat"
              @change="getChatsWithSearch(undefined, true)"
            />
          </div>
          <div class="chat__footer" v-if="isAdmin">
            <div class="chat__actions align-right">
              <button
                type="button"
                class="chat__actions__create btn btn-align-right btn-icon"
                @click="formOpened = true"
              >
                <icon
                  name="plus"
                  width="20"
                  height="20"
                  :title="$t('chat.close')"
                ></icon>
                {{ $t("chat.actions.new-chat") }}
              </button>
            </div>
          </div>
        </div>
        <ChatWindow
          class="chat__working rr-block"
          v-if="chatOpened"
          :chatId="selectedChat"
          :newMessage="newMessage"
          :notifications="chatNotifications"
          v-on:close-chat="onCloseChat"
          @updateChatName="updateChatName"
        />
        <ChatForm v-if="formOpened" v-on:form-submited="onFormSubmit" />
        <ChatSearchWindow
          v-if="search"
          :chatTypeFilterValue="chatTypeFilterValue"
          :userFilterValue="userFilterValue"
          :archivedFilterValue="archivedFilterValue"
          @change="setFilter"
        />
      </div>
    </hsc-window>
  </component>
</template>
<script>
import VueWindow from "@hscmap/vue-window";
import ChatRepository from "@/repositories/components/ChatRepository";
import ChatForm from "./ChatForm.vue";
import ChatWindow from "./ChatWindow.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import ChatList from "./ChatList.vue";
import ChatSearchWindow from "@/components/chat/ChatSearchWindow";
import { renderChatList } from "@/components/chat/chat";

export default {
  components: {
    ChatForm,
    ChatWindow,
    ChatList,
    ChatSearchWindow,
  },
  props: {
    initialWidth: {
      type: Number,
    },
    notifications: {
      type: Number,
    },
    notificationMessage: {
      type: Object,
    },
    chatId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      pagination: {
        page: 0,
        itemsPerPage: 20,
      },
      last: false,
      firstLoad: true,
      notificationChannelsCount: 0,
      selectedChat: null,
      defaultStyle: null,
      openedName: null,
      formOpened: false,
      chatOpened: false,
      width: null,
      height: null,
      xpos: null,
      ypos: null,
      chatRoomList: [],
      newMessage: null,
      ro: null,
      mainEl: null,
      chatNotifications: null,
      search: false,
      userFilterValue: [],
      archivedFilterValue: false,
      chatTypeFilterValue: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    showRemoveFilterIcon() {
      return (
        !this.chatOpened &&
        !this.formOpened &&
        (this.userFilterValue.length !== 0 ||
          this.chatTypeFilterValue ||
          this.archivedFilterValue === null ||
          this.archivedFilterValue)
      );
    },
    showChatList() {
      return !this.chatOpened && !this.formOpened && !this.search;
    },
  },
  watch: {
    notifications() {
      if (!this.chatOpened && !this.firstLoad) {
        this.reloadChats();
      } else {
        if (this.notificationMessage != null) {
          if (this.selectedChat === this.notificationMessage.chat) {
            this.newMessage = this.notificationMessage;
          }
        }
      }
    },
    chatId(newValue) {
      this.selectedChat = newValue;
      if (newValue) {
        this.chatOpened = true;
      }
    },
    showChatList(val) {
      if (val) {
        this.$nextTick(() => this.infiniteScroll());
      }
    },
  },
  async mounted() {
    if (this.chatId) {
      this.chatOpened = true;
      this.selectedChat = this.chatId;
    }
    this.mainEl = document.getElementById("main");
    if (!this.mainEl) {
      this.mainEl = document.getElementById("classroom-layout");
    }
    this.resetWindowPosAndSize();
    // this.ro = new ResizeObserver(
    //   debounce(() => {
    //     this.resetWindowPosAndSize();
    //   }, 300)
    // );
    // this.ro.observe(this.mainEl);
    this.defaultStyle = VueWindow.StyleFactory({
      window: {
        color: "#000",
        boxShadow: "0 2pt 4pt rgba(0, 0, 0, 0.5)",
        backgroundColor: "transparent",
      },
      titlebar: {
        pointerEvents: "none",
      },
      content: {},
      button: {
        color: "#000",
      },
      buttonHover: {
        backgroundColor: "rgba(0, 0, 0, 0.25)",
      },
      buttonActive: {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    });

    // If we have a chat opened, we don't download the chatList
    // ... (it is done in the updateChatName function)
    if (!this.chatOpened) {
      await this.getNotificationChannels();
      await this.getChatsWithSearch(undefined, true);
      this.firstLoad = false;
    }
    this.infiniteScroll();
  },
  methods: {
    async mousedown(e) {
      const baseLeft = this.xpos;
      const baseTop = this.ypos;
      const mousemove = (e2) => {
        this.xpos = baseLeft + e2.clientX - e.clientX;
        this.ypos = baseTop + e2.clientY - e.clientY;
        if (this.ypos < -9) {
          this.ypos = -9;
        }
      };
      const mouseup = async () => {
        document.removeEventListener("mousemove", mousemove);
        document.removeEventListener("mouseup", mouseup);
      };
      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);
    },
    async getNotificationChannels() {
      this.chatRoomList = await ChatRepository.getNotificationChannels();
      this.notificationChannelsCount = this.chatRoomList.length;
    },
    async getChat(chat) {
      this.selectedChat = chat.id;
      this.chatNotifications = chat.notifications;
      this.chatOpened = true;
      this.openedName = chat.chatName;
    },
    async getChats(pagination = this.pagination, overwrite = false) {
      const options = {
        params: {
          page: pagination.page,
          size: pagination.itemsPerPage,
        },
      };
      if (overwrite) {
        await this.getNotificationChannels();
      }
      await ChatRepository.getChatRoomList(options).then((data) => {
        this.last = data.last;
        this.chatRoomList = this.chatRoomList.concat(data.content);
      });
    },
    onFormSubmit() {
      this.formOpened = false;
    },
    onCloseChat() {
      this.getChatsWithSearch(undefined, true);
      this.chatOpened = false;
    },
    closeWindow() {
      this.$emit("close-window");
    },
    reloadChats() {
      this.getChatsWithSearch(
        {
          page: 0,
          itemsPerPage:
            this.chatRoomList.length - this.notificationChannelsCount,
        },
        true
      );
    },
    back() {
      if (this.chatOpened) {
        this.chatOpened = false;
        this.$emit("back-action");
      } else if (this.formOpened) {
        this.formOpened = false;
      } else if (this.search) {
        return (this.search = false);
      }
      this.reloadChats();
    },
    resetWindowPosAndSize() {
      this.height = 420;
      this.ypos = window.innerHeight - this.height - 20;
      this.width = this.initialWidth;
      this.xpos = window.innerWidth - this.width - 20;
    },
    infiniteScroll() {
      const element = document.getElementById("chat__roomlist");
      if (element) {
        element.onscroll = () => {
          let bottomOfWindow =
            element.scrollHeight - element.scrollTop === element.offsetHeight;
          if (bottomOfWindow && !this.last) {
            this.pagination.page++;
            this.getChatsWithSearch();
          }
        };
      }
    },
    async getChatsWithSearch(pagination = this.pagination, overwrite = false) {
      this.search = false;
      if (
        this.userFilterValue.length === 0 &&
        !this.chatTypeFilterValue &&
        !this.archivedFilterValue &&
        this.archivedFilterValue !== null
      ) {
        await this.getChats(pagination, overwrite);
      } else {
        const options = {
          params: {
            page: pagination.page,
            size: pagination.itemsPerPage,
            userIds: this.userFilterValue,
            type: this.chatTypeFilterValue,
            archived:
              this.archivedFilterValue === true ||
              this.archivedFilterValue === false
                ? this.archivedFilterValue.toString()
                : this.archivedFilterValue,
          },
        };
        if (overwrite) {
          this.chatRoomList = [];
        }
        await ChatRepository.getChannelsByUserAndType(options).then((data) => {
          this.last = data.last;
          this.chatRoomList = this.chatRoomList.concat(data.content);
        });
      }
    },
    clearFilters() {
      this.$set(this, "userFilterValue", []);
      this.$set(this, "chatTypeFilterValue", null);
      this.$set(this, "archivedFilterValue", false);
      // reset pagination and overwrite current chat list
      this.pagination.page = 0;
      this.getChats(this.pagination, true);
    },
    setFilter(userFilter = [], chatTypeFilter, archivedFilter) {
      this.$set(this, "userFilterValue", userFilter);
      this.$set(this, "chatTypeFilterValue", chatTypeFilter);
      this.$set(this, "archivedFilterValue", archivedFilter);
      // reset pagination and overwrite current chat list
      this.pagination.page = 0;
      this.getChatsWithSearch(this.pagination, true);
    },
    async updateChatName(chat) {
      this.openedName = chat?.chatName || renderChatList([chat])[0].chatName;
    },
  },
};
</script>
