<template>
  <div class="clrm-tab clrm-tab--student">
    <div class="clrm-tabheader">
      <ul>
        <li class="clrm-tablist__item clrm-tablist__item--active">
          <button class="clrm-tablist__item__link no-pointer">
            <icon :name="tab.icon" class="clrm-tablist__item__icon"></icon>
            <span class="clrm-tablist__item__title">{{ tab.title }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="clrm-tabcontent">
      <component
        :is="tab.component"
        :zoom="zoom"
        :activity="tab.activity"
        class="clrm-tabcontent__container"
      ></component>
    </div>
  </div>
</template>

<script>
// TODO: Implementación no correcta, usada para la generación de estilos
import ClrmLesson from "@/components/clrm/clrm-lesson/ClrmLesson.vue";
import ClrmWhiteboard from "@/components/clrm/clrm-whiteboard/ClrmWhiteboard.vue";
import ClrmShareScreen from "@/components/clrm/clrm-share-screen/ClrmShareScreen.vue";
import ClrmPdf from "@/components/clrm/clrm-pdf/ClrmPdf.vue";
import ClrmImageViewer from "@/components/clrm/clrm-image-viewer/ClrmImageViewer.vue";
import ClrmAudioPlayer from "@/components/clrm/clrm-audio-player/ClrmAudioPlayer";
import ClrmVideoPlayer from "@/components/clrm/clrm-video-player/ClrmVideoPlayer";
import ClrmPlayH5PActivity from "@/components/clrm/clrm-play-h5p-activity/ClrmPlayH5PActivity.vue";

export default {
  name: "ClrmTabs",
  components: {
    ClrmPdf,
    ClrmLesson,
    ClrmWhiteboard,
    ClrmImageViewer,
    ClrmShareScreen,
    ClrmAudioPlayer,
    ClrmVideoPlayer,
    ClrmPlayH5PActivity,
  },
  props: {
    zoom: {
      type: Object,
      required: true,
    },
    tab: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.no-pointer {
  cursor: default !important;
}
</style>
