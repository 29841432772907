var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center text-h2",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.levelTestCount),"content":_vm.levelTestCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Level Test Request List')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-ab-testing ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_level_tests"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.registrationRequestCount),"content":_vm.registrationRequestCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Admin Product Request List')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-account-plus ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_registrations"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.lectureRatingsCount),"content":_vm.lectureRatingsCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Admin Lecture Evaluations')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-star ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_lecture_ratings"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.studentCandidateCount),"content":_vm.studentCandidateCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Candidate students list')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-account-group ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_candidate_students"))+" ")])])],1)]}}])})],1)],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.teacherCandidateCount),"content":_vm.teacherCandidateCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Candidate teachers list')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-human-male-board ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_candidate_teachers"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.teacherLevelCount),"content":_vm.teacherLevelCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Pending teacher levels')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-certificate ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.pending_teacher_levels"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.teacherReferenceCount),"content":_vm.teacherReferenceCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Preliminary teacher references')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mark_email_read ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.preliminary_teacher_references"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.editionsUnderStudentsCount),"content":_vm.editionsUnderStudentsCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Admin Edition List', {
                    underMinimumStudents: 'true',
                  })}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-account-arrow-down ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.editions_under_students"))+" ")])])],1)]}}])})],1)],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center px-0",attrs:{"cols":"12","md":"6"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.teacherDiplomasCount),"content":_vm.teacherDiplomasCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.navigate('PendingDiplomasList')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" history_edu ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("teacher.headers.pending-diplomas"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center px-0",attrs:{"cols":"12","md":"6"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.usersWithoutBillingIdCount),"content":_vm.usersWithoutBillingIdCount,"overlap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.navigate('UsersWithoutBillingIdList')}}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" history_edu ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("wallet.headers.users-without-billing-id"))+" ")])])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }