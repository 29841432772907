<template>
  <div class="h5p" ref="playerWrapper">
    <div class="text-center" v-if="loading || error">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-if="error">
        {{ this.$t("classroom.error.h5p_activity_not_found") }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import XApiStatementsManagement from "@/mockups/student/components/XApiStatementsManagement";

try {
  const H5PWebcomponents = require("@lumieducation/h5p-webcomponents");
  H5PWebcomponents.defineElements("h5p-player");
} catch {}

export default Vue.extend({
  name: "H5PPlayer",
  props: ["exercise", "onXApiStatement"],
  data() {
    return {
      loading: true,
      error: false,
      componentDefined: false,
    };
  },
  async mounted() {
    try {
      //XApiStatementsManagement.clearCompletedActivities();
      this.$log.debug(
        `ContentService: Getting information to play ${this.exercise}...`
      );
      const res = await fetch("/h5p/" + this.exercise + "/play");
      if (!res || !res.ok) {
        this.$log.debug("Error getting the activity with id " + this.exercise);
        this.error = true;
        this.$emit("error", res);
        return;
      }

      const h5pPlayer = document.createElement("h5p-player");
      this.$refs.playerWrapper.appendChild(h5pPlayer);

      h5pPlayer.addEventListener("initialized", () => {
        this.$emit("loaded");
        if (this.onXApiStatement) {
          h5pPlayer.h5pObject.externalDispatcher.on("xAPI", (event) =>
            this.onXApiStatement(event)
          );
        }
      });
      h5pPlayer.setAttribute("content-id", this.exercise);
      h5pPlayer.loadContentCallback = async (contentId) => {
        this.$log.debug(`h5pPlayer: Loading activity ${contentId}...`);
        return res.json();
      };
    } catch (e) {
      this.$log.debug(e);
    } finally {
      this.loading = false;
    }
  },
});
</script>
<style scoped lang="css">
.h5p {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-center {
  text-align: center;
  height: 100%;
  padding-top: 200px;
}

::v-deep .h5p-joubelui-button {
  color: white;
  text-decoration: none;
}
</style>
