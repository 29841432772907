<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-select
          dense
          no-filter
          solo
          clearable
          :debouncing="300"
          @change="redirectOnFilterChange"
          :items="students.items"
          :loading="students.loading"
          v-model="studentFilter"
          :label="$t('lectureEvaluation.prop.student')"
          item-text="name"
          item-value="id"
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          dense
          no-filter
          solo
          clearable
          :debouncing="300"
          @change="redirectOnFilterChange"
          :items="products.items"
          :loading="products.loading"
          v-model="productFilter"
          :label="$t('lectureEvaluation.prop.course')"
          item-text="title"
          item-value="id"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="lectureEvaluations"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.productTitle`]="{ item }">
            {{ item.productTitle || $t("lecture.levelTest") }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateTimeWithTz("medium") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const LectureEvaluationEntityRepository = RepositoryFactory.get(
  "LectureEvaluationEntityRepository"
);

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

export default {
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      lectureEvaluations: [],
      studentFilter: null,
      productFilter: null,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      students: {
        items: [],
        loading: false,
      },
      products: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lectureEvaluation.prop.course"),
          value: "productTitle",
        },
        {
          text: this.$t("lectureEvaluation.prop.student"),
          value: "studentName",
        },
        {
          text: this.$t("lectureEvaluation.prop.comments"),
          value: "comments",
        },
        {
          text: this.$t("lectureEvaluation.prop.date"),
          value: "date",
        },
      ];
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.student) {
      let value = parseFloat(this.$route.query.student);
      this.studentFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.product) {
      let value = parseFloat(this.$route.query.product);
      this.productFilter = isNaN(value) ? null : value;
    }

    this.getItems();
    this.getSelectorsItems(
      this.students,
      StudentEntityRepository.getAllOfTeacher
    );
    this.getSelectorsItems(
      this.products,
      ProductEntityRepository.getAllOfTeacher
    );
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        productTitle: "attendance.lecture.edition.product.title",
        studentName: "attendance.student.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          student: this.studentFilter,
          product: this.productFilter,
        },
      };
      LectureEvaluationEntityRepository.getAllByTeacherId(
        this.teacherId,
        options
      )
        .then((response) => {
          this.lectureEvaluations = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    async getSelectorsItems(entity, repositoryMethod) {
      entity["loading"] = true;
      entity["items"] = await repositoryMethod({
        params: {
          teacherId: this.teacherId,
        },
      });
      entity["loading"] = false;
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.student =
        this.studentFilter != null ? this.studentFilter : undefined;
      query.product =
        this.productFilter != null ? this.productFilter : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
  },
};
</script>
