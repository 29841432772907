<template>
  <v-container fluid v-if="items">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <debounced-text-field
              append-icon="search"
              class="d-md-inline-block"
              dense
              hide-details
              v-model="search"
              :label="$t('search')"
              @input="onSearchChange"
            ></debounced-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              class="rows-clickable"
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :options="entitiesPage"
              :server-items-length="totalItems"
              :loading="loading"
              @update:options="redirectOnTableChange"
              @click:row="goToStudentBalanceTab"
            >
              <template v-slot:[`item.expiration`]="{ item }">
                {{ item.expiration | dateTimeWithoutTz }}
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                {{ $n(item.balance, "currency") }}
              </template>

              <template v-slot:[`item.userData`]="{ item }">
                <router-link
                  v-if="item.userData"
                  :to="{
                    name: 'UserData FormDetail',
                    params: { id: item.userData.id },
                  }"
                >
                  {{ item.userData.id }}
                </router-link>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      @click.stop="goToStudentBalanceTab(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      description
                    </v-icon>
                  </template>
                  <span>{{ $t("actions.detail") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");

export default {
  name: "WalletList",
  components: {
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      search: this.$route.query.search,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("wallet.prop.displayName"),
          value: "displayName",
        },
        {
          text: this.$t("wallet.prop.firstName"),
          value: "firstName",
        },
        {
          text: this.$t("wallet.prop.lastName"),
          value: "lastName",
        },
        {
          text: this.$t("wallet.prop.balance"),
          value: "balance",
        },
        {
          text: this.$t("wallet.prop.expiration"),
          value: "expiration",
        },
        {
          text: this.$t("wallet.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        displayName: "userData.displayName",
        firstName: "userData.user.firstName",
        lastName: "userData.user.lastName",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage, sortMapping),
          search: this.search ? this.search : undefined,
        },
      };
      WalletEntityRepository.getAll(options).then((response) => {
        this.items = response.content;
        this.totalItems = response.totalElements;
        this.loading = false;
      });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    onSearchChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.search = this.search ? this.search : undefined;
      query.sort = generateSort(this.entitiesPage);
      this.redirect(query);
    },
    goToStudentBalanceTab(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "StudentBalanceTab",
          params: { id: entity.studentId, backPrevious: true },
        });
      }
    },
  },
};
</script>
