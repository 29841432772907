import ActivityInteractionEntityRepository from "@/repositories/entities/ActivityInteractionEntityRepository";
import VirtualClassroomActivityRepository from "@/repositories/components/VirtualClassroomActivityRepository";

let recordedEvents = [];
let completedActivities = [];
let initDate = null;
let endDate = null;

function processStatement(lessonId, activityInteractionId, onSaved, event) {
  let statement = event.data.statement;
  if (completedActivities.some((ca) => ca === statement.object.id)) {
    return;
  }
  let save = false;
  let data =
    activityInteractionId === undefined
      ? { lessonId: lessonId, grade: event.getScore() }
      : {
        id: activityInteractionId,
        lectureId: lessonId,
        grade: event.getScore(statement.object.id),
      };
  data.fileName = statement.object.id;

  if (statement.verb.display["en-US"] === "completed") {
    if (statement.context.contextActivities.parent == null) {
      if (statement.result.duration) {
        data.timeConsumed = statement.result.duration.slice(2).split(".")[0];
      }
      save = true;
    }
  } else if (
    statement.result != null &&
    statement.context.contextActivities.parent == null
  ) {
    if (statement.result.duration) {
      data.timeConsumed = statement.result.duration.slice(2, -4).slice(0, 2);
    }
    save = true;
  }
  if (save) {
    ActivityInteractionEntityRepository.save(data)
      .then(onSaved(), completedActivities.push(statement.object.id))
      .catch((err) =>
        this.$log.debug("Error saving activity interaction: " + err)
      );
  }
}

function processClassroomStatement(
  lectureActivityId,
  //startRecorder,
  //stopRecorder,
  event
) {
  let statement = event.data.statement;
  let timeConsumed = null;
  // if (
  //   recordedEvents.length == 0 &&
  //   statement.verb.display["en-US"] === "interacted"
  // ) {
  //   startRecorder();
  // } else
  if (
    statement.verb.display["en-US"] === "completed" ||
    statement.result != null
  ) {
    if (statement.context.contextActivities.parent == null) {
      if (statement.result.duration) {
        timeConsumed = statement.result.duration.slice(2).split(".")[0];
      }
      saveResults(lectureActivityId, event.getScore(), timeConsumed);
      //stopRecorder();
    }
  }
}

function pushEvent(event) {
  if (initDate != null) {
    recordedEvents.push(event);
  }
}

function removeEvents() {
  recordedEvents = [];
}

function saveResults(lectureActivityId, grade, timeConsumed) {
  VirtualClassroomActivityRepository.saveResults({
    timeConsumed: timeConsumed,
    lectureActivityId: lectureActivityId,
    grade: grade,
    recordings: null,
  });
  endDate = null;
  initDate = null;
}

function clearCompletedActivities() {
  completedActivities = [];
}

export default {
  processStatement,
  clearCompletedActivities,
  processClassroomStatement,
  pushEvent,
  removeEvents,
};
