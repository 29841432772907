import VirtualClassroomLectureList from "../VirtualClassroomLectureList";

const routes = [
  {
    path: "/lectures-vc",
    name: "VirtualClassroomLectureList",
    component: VirtualClassroomLectureList,
    meta: {
      public: false,
    },
  },
];

export default routes;
