<template>
  <div class="clrm-tab clrm-tab--no-toolbar">
    <div class="clrm-tabheader">
      <ul class="clrm-tablist" ref="tabList">
        <li
          v-for="tab in tabHandler.state.tabs"
          :key="tab.name"
          class="clrm-tablist__item"
          :class="[
            {
              'clrm-tablist__item--active':
                tabHandler.state.currentTab.name === tab.name,
              'clrm-tablist__item--shared': tab.shared,
            },
          ]"
          @mouseenter="tabMouseEnter($event)"
          @mouseleave="tabMouseLeave($event)"
          @click="changeTab(tab)"
        >
          <button
            class="clrm-tablist__item__link"
            :class="{
              'clrm-tablist__item__link--simple':
                shrinkTabs && notActiveTabWidth < 80,
            }"
            :style="[
              tabHandler.state.currentTab.name !== tab.name && shrinkTabs
                ? { width: notActiveTabWidth + 'px' }
                : null,
            ]"
          >
            <icon :name="tab.icon" class="clrm-tablist__item__icon"></icon>
            <span class="clrm-tablist__item__title">{{ tab.title }}</span>
          </button>
          <button
            class="clrm-tablist__item__close"
            @click.prevent="closeTab(tab)"
          >
            <icon
              name="close"
              width="12"
              height="12"
              :title="$t('classroom.global.close')"
            ></icon>
          </button>
        </li>
      </ul>
      <div class="clrm-tab__toolbar">
        <button
          class="clrm-tab__toolbar__button clrm-tab__toolbar__button__share"
          v-if="
            !tabHandler.state.currentTab.shared &&
            tabHandler.state.tabs.length > 0
          "
          @click="shareTab()"
        >
          <icon
            name="arrow-top"
            width="14"
            height="14"
            :title="$t('classroom.global.share-tab')"
          ></icon>
          {{ $t("classroom.global.share-tab") }}
        </button>
        <button
          class="clrm-tab__toolbar__button clrm-tab__toolbar__button__unshare"
          v-if="
            tabHandler.state.currentTab.shared &&
            tabHandler.state.tabs.length > 0
          "
          @click="unshareTab()"
        >
          <icon
            name="arrow-down"
            width="14"
            height="14"
            :title="$t('classroom.global.unshare-tab')"
          ></icon>
          {{ $t("classroom.global.unshare-tab") }}
        </button>
        <button
          class="clrm-tab__toolbar__button clrm-tab__toolbar__button__add"
          v-if="
            tabHandler.state.currentTab.activity &&
            tabHandler.state.currentTab.activity.teacherNotes
          "
          @click="openTeacherNotes = true"
        >
          <icon name="description" width="14" height="14"></icon>
        </button>
        <modal-dialog
          :dialog="openTeacherNotes"
          v-if="openTeacherNotes"
          :title="$t('activity.prop.teacher-notes')"
          :content="tabHandler.state.currentTab.activity.teacherNotes"
          :cancelText="$t('actions.close')"
          @cancel="openTeacherNotes = false"
          dialogWith="75%"
        >
        </modal-dialog>
        <button
          v-if="!isTeacherCandidate"
          class="clrm-tab__toolbar__button clrm-tab__toolbar__button__add"
          :class="{
            'clrm-tab__toolbar__button--disabled':
              tabHandler.state.disableAddTabs,
          }"
          @click="openActivityList()"
        >
          <icon
            name="plus"
            width="14"
            height="14"
            :title="$t('classroom.global.add-more')"
          ></icon>
        </button>
      </div>
    </div>
    <div class="clrm-tabcontent" v-if="tabHandler.state.tabs.length > 0">
      <component
        :is="tabHandler.state.currentTab.component"
        :zoom="zoom"
        :activity="tabHandler.state.currentTab.activity"
        class="clrm-tabcontent__container"
      ></component>
    </div>
    <ClassroomActivitiesList
      ref="classroomActivitiesList"
      :tabHandler="tabHandler"
      :language="classroomInfo.languageId"
      :level="classroomInfo.levelId"
      @add-activity="addActivity"
    />
  </div>
</template>

<script>
// TODO: Implementación no correcta, usada para la generación de estilos
import ClrmLesson from "@/components/clrm/clrm-lesson/ClrmLesson.vue";
import ClrmWhiteboard from "@/components/clrm/clrm-whiteboard/ClrmWhiteboard.vue";
import ClrmShareScreen from "@/components/clrm/clrm-share-screen/ClrmShareScreen.vue";
import ClassroomActivitiesList from "@/components/classroom/ClassroomActivitiesList";
import VirtualClassroomActivityRepository from "@/repositories/components/VirtualClassroomActivityRepository";
import ClrmPdf from "@/components/clrm/clrm-pdf/ClrmPdf.vue";
import ClrmImageViewer from "@/components/clrm/clrm-image-viewer/ClrmImageViewer.vue";
import ClrmPlayH5PActivity from "@/components/clrm/clrm-play-h5p-activity/ClrmPlayH5PActivity.vue";
import ClrmAudioPlayer from "@/components/clrm/clrm-audio-player/ClrmAudioPlayer";
import ClrmVideoPlayer from "@/components/clrm/clrm-video-player/ClrmVideoPlayer";
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LectureActivityEntityRepository from "@/repositories/entities/LectureActivityEntityRepository";
import ModalDialog from "@/components/modal_dialog/ModalDialog.vue";

export default {
  name: "ClrmTabs",
  components: {
    ClrmPdf,
    ClrmLesson,
    ClrmWhiteboard,
    ClrmImageViewer,
    ClrmShareScreen,
    ClrmAudioPlayer,
    ClrmVideoPlayer,
    ClassroomActivitiesList,
    ClrmPlayH5PActivity,
    ModalDialog,
  },
  props: {
    zoom: {
      type: Object,
      required: true,
    },
    tabHandler: {
      type: Object,
      required: true,
    },
    currentSessionActivity: {
      type: Number,
      required: false,
    },
    classroomInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabListContainerWidth: null,
      tabListWidth: null,
      notActiveTabWidth: null,
      shrinkTabs: false,
      defaultActivities: [],
      user: auth.getUser(),
      openTeacherNotes: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isTeacherCandidate"]),
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.$refs.tabList) {
        this.tabListContainerWidth = this.$refs.tabList.offsetWidth;
      }
    });
    this.computeRealTabsWidth();
    this.calcTabWidth();
    window.addEventListener("resize", this.resizeWindow);
    if (this.tabHandler.state.tabs.length === 0) {
      this.getDefaultActivities();
    }
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeWindow);
  },
  methods: {
    openActivityList() {
      this.$refs.classroomActivitiesList.init();
    },
    resizeWindow() {
      if (this.$refs.tabList) {
        this.tabListContainerWidth = this.$refs.tabList.offsetWidth;
      }
      this.computeRealTabsWidth();
      this.calcTabWidth();
    },
    computeRealTabsWidth() {
      if (!this.shrinkTabs) {
        this.$nextTick(function () {
          let tabsWidth = 0;
          if (this.$refs.tabList) {
            this.$refs.tabList
              .querySelectorAll(".clrm-tablist__item")
              .forEach((tabItem) => {
                tabsWidth += Math.max(
                  tabItem.offsetWidth,
                  tabItem.children[0].offsetWidth
                );
              });
            this.tabListWidth = tabsWidth;
            if (this.tabListWidth > this.tabListContainerWidth) {
              this.shrinkTabs = true;
            }
          }
        });
      }
    },
    calcTabWidth() {
      this.$nextTick(function () {
        if (this.shrinkTabs) {
          let activeTabWidth = 180;
          if (this.$refs.tabList) {
            this.$refs.tabList
              .querySelectorAll(".clrm-tablist__item")
              .forEach((tabItem) => {
                if (tabItem.classList.contains("clrm-tablist__item--active")) {
                  activeTabWidth = tabItem.children[0].offsetWidth;
                }
              });
          }
          const securitySpace = 4 * this.tabHandler.state.tabs.length; // Espacio de los márgenes
          this.notActiveTabWidth = Math.round(
            (this.tabListContainerWidth - activeTabWidth - securitySpace) /
              (this.tabHandler.state.tabs.length - 1)
          );
        }
      });
    },
    tabMouseEnter(event) {
      if (
        !event.currentTarget.classList.contains("clrm-tablist__item--active")
      ) {
        event.currentTarget.classList.add("clrm-tablist__item--hover");
      }
    },
    tabMouseLeave(event) {
      event.currentTarget.classList.remove("clrm-tablist__item--hover");
    },
    changeTab(tab) {
      if (this.tabHandler.changeTab(tab)) {
        this.calcTabWidth();
      }
    },
    closeTab(tab) {
      event.cancelBubble = true;
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      this.tabHandler.closeTab(tab, this.$route.params.id);
      this.computeRealTabsWidth();
      this.calcTabWidth();
    },
    async getDefaultActivities() {
      this.defaultActivities =
        await VirtualClassroomActivityRepository.getByLectureId(
          this.$route.params.id
        );
      this.defaultActivities.forEach((a) => {
        if (a.content != null) {
          this.addTab(a);
        }
      });
    },
    async addActivity(lectureActivity) {
      const activityFound = this.defaultActivities.find(
        (a) => a.activityId === lectureActivity.id
      );
      if (!activityFound) {
        lectureActivity = await LectureActivityEntityRepository.save({
          activityId: lectureActivity.id,
          lectureId: this.$route.params.id,
        });
        this.defaultActivities.push(lectureActivity);
      } else {
        lectureActivity = activityFound;
      }
      this.addTab(lectureActivity);
    },
    addTab(lectureActivity) {
      let component = "";
      if (lectureActivity.resourceType === "ACTIVITY") {
        component = lectureActivity.typeH5P ? "ClrmPlayH5PActivity" : "ClrmPdf";
      } else if (lectureActivity.resourceType === "DOCUMENT") {
        component = "ClrmPdf";
      } else if (lectureActivity.resourceType === "IMAGE") {
        component = "ClrmImageViewer";
      } else if (lectureActivity.resourceType === "VIDEO") {
        component = "ClrmVideoPlayer";
      } else if (lectureActivity.resourceType === "AUDIO") {
        component = "ClrmAudioPlayer";
      }

      const newTab = {
        name: "tab-" + new Date().getTime() + Math.random(),
        component: component,
        title: lectureActivity.title,
        icon: "lesson",
        shared: this.currentSessionActivity === lectureActivity.id,
        activity: {
          id: lectureActivity.id,
          activityId: lectureActivity.activityId,
          type_h5p: lectureActivity.typeH5P,
          activityH5PId: lectureActivity.content
            ? lectureActivity.content.fileName
            : null,
          title: lectureActivity.title,
          resourceType: lectureActivity.resourceType,
          teacherNotes: lectureActivity.teacherNotes,
        },
      };

      this.tabHandler.addTab(newTab);

      if (this.currentSessionActivity === lectureActivity.id) {
        this.tabHandler.changeTab(newTab);
      }

      this.computeRealTabsWidth();
      this.calcTabWidth();
    },
    shareTab() {
      this.tabHandler.shareCurrentTab(this.$route.params.id);
    },
    unshareTab() {
      this.tabHandler.unshareCurrentTab(this.$route.params.id);
    },
  },
};
</script>

<style scoped>
.no-pointer {
  cursor: default !important;
}
</style>
