/**
 * Method which sets value of showed in a navbar item
 * @param {*} navBarItems navigation drawer entries
 * @param {*} itemKey title of item to hide
 * @returns navbar copy updated
 */
function setShowed(navBarItems, itemKey, value) {
  let tabIndex = -1;
  const navBarItemsCopy = JSON.parse(JSON.stringify(navBarItems));
  const index = navBarItemsCopy.findIndex((el) => {
    if (el.title === itemKey) {
      return el;
    } else if (el.tabs != null) {
      tabIndex = el.tabs.findIndex((tabs) => tabs.title === itemKey);
      if (tabIndex != -1) {
        return el;
      }
    }
  });
  tabIndex != -1
    ? (navBarItemsCopy[index].tabs[tabIndex].showed = value)
    : (navBarItemsCopy[index].showed = value);

  return navBarItemsCopy;
}

/**
 * Update notifications in navbarItems
 * @param {string} entityType
 * @param {teacher or stundet object} entity
 * @param {array} navBarItems
 * @returns
 */
function updateNavigationDrawer(entityType, entity, navBarItems) {
  return entityType == "teacher"
    ? updateTeacherDrawer(entity, navBarItems)
    : updateStudentDrawer(entity, navBarItems);
}

/**
 * Update notifications in navbarItems for teacher
 * @param {teacher object} entity
 * @param {array} navBarItems
 * @returns
 */
function updateTeacherDrawer(entity, navBarItems) {
  let newNabVar = navBarItems;
  if (!entity.degrees) {
    const foundIndex = navBarItems.findIndex(
      (el) => el.title == "teacher.navigationBar.profile"
    );
    // Change profile notification and children
    newNabVar[foundIndex].notification = true;
    // Change children
    const childIndex = navBarItems[foundIndex].tabs.findIndex(
      (el) => el.title == "teacher.navigationBar.education"
    );
    newNabVar[foundIndex].tabs[childIndex].notification = true;
  }
  return newNabVar;
}

/**
 * Update notifications in navbarItems for student
 * @param {student object} entity
 * @param {array} navBarItems
 * @returns
 */
function updateStudentDrawer(entity, navBarItems) {
  return navBarItems;
}

export { updateNavigationDrawer, setShowed };
