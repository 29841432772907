import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/userDatas";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllWithInvoice(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/getAllWithInvoice`,
      options
    );
    return response.data;
  },

  async getAllFromUserChats(id) {
    const response = await HTTP.get(`${RESOURCE_NAME}/${id}/chats/users`);
    return response.data;
  },

  async getByUserId(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/getByUserId/${id}`)).data;
  },

  async usersWithoutBillingId(options = {}) {
    return (await HTTP.get(`${RESOURCE_NAME}/usersWithoutBillingId`, options))
      .data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async getByUser() {
    return (await HTTP.get(`${RESOURCE_NAME}/getByUser`)).data;
  },

  async getByUserLogin() {
    return (await HTTP.get(`${RESOURCE_NAME}/getByUserLogin`)).data;
  },

  async getPhoto(id) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/download/photo`, {
        responseType: "blob",
      })
    ).data;
  },

  async updateUser(entity) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/updateUser/${entity.userId}`, entity)
    ).data;
  },

  async deactivateUser(entity) {
    return (await HTTP.put(`${RESOURCE_NAME}/${entity.userId}/deactivateUser`))
      .data;
  },
};
