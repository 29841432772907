<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col order="1" order-md="2" class="text-right">
            <v-btn
              color="success ml-2"
              :to="{ name: 'LevelTest Create', params: { backPrevious: true } }"
            >
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <debounced-text-field
              append-icon="search"
              class="d-md-inline-block"
              dense
              hide-details
              v-model="nameFilter"
              :label="$t('levelTestRequest.prop.name')"
              @input="redirectOnFilterChange"
            ></debounced-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              :label="$t('lesson.headers.incomplete_level_test')"
              v-model="checked"
              @change="redirectOnFilterChange"
              class="mx-3"
            />
          </v-col>
          <v-col cols="12" md="4">
            <language-selector
              v-model="language"
              class="mx-3 selector"
              clearable
              dense
              :label="$t('lesson.headers.language')"
              outlined
              :return-object="false"
              show-chips
              @change="handleLanguageChange"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              class="rows-clickable"
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :options="pagination"
              :server-items-length="totalItems"
              @click:row="entityDetail"
              @update:options="onPaginationChange"
            >
              <template v-slot:[`item.level.language.name`]="{ item }">
                <span>
                  {{ $t(`languages.${item.level.language.name}`) }}
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        @click.stop="entityDetail(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        description
                      </v-icon>
                    </template>
                    <span>{{ $t("actions.detail") }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        @click.stop="startDeleteAction(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        delete
                      </v-icon>
                    </template>
                    <span>{{ $t("actions.delete") }}</span>
                  </v-tooltip>
                  <v-edit-dialog
                    :cancel-text="$t('actions.cancel')"
                    large
                    persistent
                    :save-text="$t('actions.save')"
                    @save="duplicateLesson(item)"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="primary">
                          content_copy
                        </v-icon>
                      </template>
                      <span>{{ $t("lesson.actions.duplicate") }}</span>
                    </v-tooltip>
                    <template v-slot:input>
                      <span class="my-8 text-h6">
                        {{ $t("lesson.actions.duplicate") }}
                      </span>
                      <v-container class="mt-2">
                        <v-text-field
                          v-model="duplicatedLessonName"
                          class="required"
                          dense
                          :label="$t('lesson.prop.name')"
                          outlined
                          :rules="[(v) => !!v || $t('lesson.error.required')]"
                        ></v-text-field>
                      </v-container>
                    </template>
                  </v-edit-dialog>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="dialog"
      :loading="deleteLoading"
      :title="$t('lesson.messages.confirmation_delete')"
      @cancel="stopDeleteAction"
      @submit="deleteLesson"
    >
    </delete-dialog>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");

export default {
  name: "LevelTestList",
  components: {
    DebouncedTextField,
    DeleteDialog,
    LanguageSelector,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      nameFilter: this.$route.query.nameFilter,
      selectedEntity: null,
      dialog: false,
      checked: this.$route.query.incomplete === "true",
      duplicatedLessonName: null,
      language: this.$route.query.language
        ? parseInt(this.$route.query.language)
        : null,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lesson.prop.name"),
          value: "name",
        },
        {
          text: this.$t("levelTestRequest.prop.language"),
          sortable: false,
          value: "level.language.name",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    filters() {
      let filterString = "isLevelTest:true";

      filterString +=
        this.nameFilter != null && this.nameFilter !== ""
          ? ",name:" + this.nameFilter.toString()
          : "";
      return filterString;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          filters: this.filters,
          incomplete: this.checked,
          language: this.language,
        },
      };
      LessonEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching level test list with params: " + options
          )
        )
        .finally(() => (this.isLoading = false));
    },
    duplicateLesson(lesson) {
      this.isLoading = true;
      const duplicateLessonObj = {
        id: lesson.id,
        name: this.duplicatedLessonName,
      };
      LessonEntityRepository.duplicate(duplicateLessonObj)
        .then((res) => this.entityDetail(res))
        .catch(() =>
          this.$log.debug("Error duplicating lesson with id " + lesson.id)
        )
        .finally(() => (this.duplicatedLessonName = null));
    },
    deleteLesson() {
      this.deleteLoading = true;
      LessonEntityRepository.delete(this.selectedEntity.id)
        .then(() => {
          this.fetchData();
          this.$notify({
            title: this.$t("actions.delete"),
            type: "success",
            text: this.$t("account.notifications.successfull_deleted"),
          });
        })
        .catch(() =>
          this.$log.debug(
            "Error deleting lesson with ID " + this.selectedEntity.id
          )
        )
        .finally(() => this.stopDeleteAction());
    },
    entityDetail(entity) {
      this.$router.push({
        name: "LevelTest Detail",
        params: { id: entity.id, backPrevious: true },
      });
    },
    startDeleteAction(entity) {
      this.selectedEntity = entity;
      this.dialog = true;
    },
    stopDeleteAction() {
      this.selectedEntity = null;
      this.dialog = false;
      this.deleteLoading = false;
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this.fetchData();
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.nameFilter = this.nameFilter != null ? this.nameFilter : undefined;
      query.language = this.language ? this.language.toString() : undefined;
      query.incomplete = this.checked ? this.checked.toString() : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    handleLanguageChange(val) {
      this.language = val;
      this.redirectOnFilterChange();
    },
  },
};
</script>
<style scoped>
.selector {
  max-width: 300px;
}
</style>
