<template>
  <form
    ref="redsys_form"
    action="https://sis-t.redsys.es:25443/sis/realizarPago"
    method="post"
    id="redsys_form"
    name="redsys_form"
  >
    <input
      type="text"
      name="Ds_SignatureVersion"
      v-model="Ds_SignatureVersion"
      hidden
    />
    <input
      type="text"
      name="Ds_MerchantParameters"
      v-model="Ds_MerchantParameters"
      hidden
    />
    <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden />

    <v-btn
      :loading="loading"
      @click.prevent="chargeWallet()"
      class="mx-4 success"
      >{{ $t("wallet.actions.exec_charge") }}</v-btn
    >
  </form>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
export default {
  props: {
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: "CHARGE",
    },
    walletId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Ds_MerchantParameters: null,
      Ds_Signature: null,
      Ds_SignatureVersion: null,
      loading: false,
    };
  },
  methods: {
    chargeWallet() {
      let charge = {
        amount: this.amount,
        type: "CREDIT_CARD",
      };
      this.loading = true;
      return WalletEntityRepository.chargeWalletRedSys(this.walletId, charge)
        .then((data) => {
          this.Ds_MerchantParameters = data.Ds_MerchantParameters;
          this.Ds_Signature = data.Ds_Signature;
          this.Ds_SignatureVersion = data.Ds_SignatureVersion;
          localStorage.setItem("balance_route", this.$route.path);
          // NOTE: Exec submit action AFTER data is set on form
          this.$nextTick(() => this.$refs.redsys_form.submit());
        })
        .catch(() => this.$log.debug("Error creating order"))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.button {
  max-width: 200px;
  margin: auto;
}
</style>
