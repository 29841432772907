<template>
  <div>
    <v-card class="text-center" @click="detailsDialog = true">
      <v-img
        :src="student.photo"
        height="150"
        style="margin: auto"
        width="150"
      ></v-img>
      <span class="subtitle-2">{{ student.name }}</span>
      <v-col cols="12" v-if="lecture.levelTest && student.level != null">
        <v-chip
          :color="getChipBackColor(student.level)"
          :text-color="getChipTextColor(student.level)"
        >
          <span>{{ student.level.level.name }}</span>
        </v-chip>
        <v-chip
          :color="getChipBackColor(student.level)"
          :text-color="getChipTextColor(student.level)"
        >
          <span>
            {{
              $t("languages." + student.level.level.language.name) | uppercase
            }}
          </span>
        </v-chip>
      </v-col>
      <v-btn
        v-if="lecture.levelTest && isAnyTeacher"
        color="primary"
        class="pr-2"
        block
        tile
        @click.stop="associateLevelDialog = true"
      >
        {{
          student.level != null
            ? $t("lecture.changeLevel")
            : $t("lecture.associateLevel")
        }}
      </v-btn>
      <v-row class="ma-0">
        <v-col v-if="student.chatId">
          <v-btn
            color="accent"
            icon
            tile
            @click.stop="$EventBus.$emit('open-chat', student.chatId)"
          >
            <icon name="chat-simple" width="28" height="28" title="chat"></icon>
          </v-btn>
        </v-col>
        <v-col v-if="isSameTeacher && lecture.state === 'DONE'">
          <v-btn
            v-if="!student.evaluation"
            color="primary"
            @click.stop="evaluationDialog = true"
          >
            <v-icon>mdi-star</v-icon>
            <span>{{ $t("studentEvaluation.messages.create") }}</span>
          </v-btn>

          <v-btn v-else max-width="90%" @click.stop="evaluationDialog = true">
            <v-icon :color="student.canEditEvaluation ? 'warning' : 'primary'">
              {{ student.canEditEvaluation ? "mdi-pencil" : "mdi-star" }}
            </v-icon>
            {{
              student.canEditEvaluation
                ? $t("studentEvaluation.messages.edit")
                : $t("studentEvaluation.messages.view")
            }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            v-if="(isAnyTeacher || isAdmin) && !lecture.levelTest"
            @click.stop="evaluationListDialog = true"
          >
            <v-icon color="primary">mdi-book</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="detailsDialog"
      width="500px"
      @click:outside="detailsDialog = false"
    >
      <student-lecture-details
        v-if="isAnyTeacher || isAdmin"
        :studentId="student.id"
        :photo="student.photo"
        @close="detailsDialog = false"
      >
      </student-lecture-details>
    </v-dialog>
    <v-dialog
      v-model="evaluationListDialog"
      v-if="evaluationListDialog"
      width="80%"
      @click:outside="evaluationListDialog = false"
    >
      <student-evaluation-list
        :student="student"
        :lecture="lecture"
        @cancel="evaluationListDialog = false"
      >
      </student-evaluation-list>
    </v-dialog>
    <v-dialog
      v-model="evaluationDialog"
      v-if="evaluationDialog"
      width="80%"
      @click:outside="closeEvaluationDialog(false)"
    >
      <student-evaluation-form
        :editable="student.canEditEvaluation || student.evaluation == null"
        :lectureId="lecture.id"
        :studentId="student.id"
        :value="student.evaluation"
        @cancel="closeEvaluationDialog"
        @save="closeEvaluationDialog"
      >
      </student-evaluation-form>
    </v-dialog>
    <associate-level-dialog
      v-if="associateLevelDialog"
      :dialog="associateLevelDialog"
      :languageFilter="lecture && lecture.languageId"
      :level="student.level"
      @cancel="associateLevelDialog = false"
      @submit="saveLevel"
    ></associate-level-dialog>
  </div>
</template>

<script>
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import { mapAuthGetter } from "@/common/mapAuthGetter";

import StudentLectureDetails from "@/mockups/admin/lectures/lecture-detail/StudentLectureDetails";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import AssociateLevelDialog from "@/mockups/admin/lectures/lecture-detail/AssociateLevelDialog";
import StudentEvaluationList from "@/mockups/admin/studentEvaluations/StudentEvaluationList";
import StudentEvaluationForm from "@/mockups/admin/studentEvaluations/StudentEvaluationForm";

const StudentLevelEntityRepository = RepositoryFactory.get(
  "StudentLevelEntityRepository"
);

export default {
  name: "StudentLectureDetailsPreview",
  props: {
    lecture: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    isSameTeacher: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AssociateLevelDialog,
    StudentLectureDetails,
    StudentEvaluationList,
    StudentEvaluationForm,
  },
  data() {
    return {
      associateLevelDialog: false,
      detailsDialog: false,
      evaluationListDialog: false,
      evaluationDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAnyTeacher"]),
  },
  methods: {
    saveLevel(level) {
      const studentLevel = {
        level: level,
        studentId: this.student.id,
        lectureId: this.lecture.id,
      };
      StudentLevelEntityRepository.save(studentLevel)
        .then((res) => {
          this.$notify({
            title: this.$t("studentLevel.messages.create-success"),
            type: "success",
          });
          this.$emit("saveLevel", this.student.id, res);
        })
        .catch(() => this.$log.debug("Error fetching student level"))
        .finally(() => (this.associateLevelDialog = false));
    },
    closeEvaluationDialog(newEvaluation) {
      if (newEvaluation) {
        this.$emit("saveEvaluation", this.student.id, newEvaluation);
      }
      this.evaluationDialog = false;
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
