const icons = {
  NOTIFICATION: "mdi-alert-octagon",
  ADMINS_NOTIFICATIONS: "mdi-alert-octagon",
  TEACHERS: "mdi-coffee",
  EDITION_COURSE: "mdi-human-male-board",
  USER_ADMINS: "mdi-help-circle",
};

function getChatIcon(chat) {
  const currentUser = auth.getUser();
  if (icons[chat.channelType]) {
    if (
      chat.channelType === "USER_ADMINS" &&
      currentUser.authorities.includes("ROLE_ADMIN")
    ) {
      return null;
    }
    return icons[chat.channelType];
  } else if (chat.users.length > 2) {
    return "mdi-account-group";
  } else return null;
}

export { getChatIcon };

import auth from "@/common/auth";
