<template>
  <v-container fluid v-if="schedule" class="pt-0">
    <!-- Action buttons refering to filters and edit availabilities -->
    <v-row align="center" justify="center" no-gutters>
      <!-- Edit availability button action -->
      <v-col cols="12" class="text-center">
        <v-btn
          v-if="!editingAvailabilities"
          color="success"
          @click="editingAvailabilities = true"
        >
          <v-icon class="mr-2">event_available</v-icon>
          {{ $t("teacher.tabs.schedules.editAvailabilities") }}
        </v-btn>
        <v-btn
          v-if="editingAvailabilities"
          color="secondary"
          @click="clearEditVariables()"
        >
          <v-icon class="mr-2">arrow_back</v-icon>
          {{ $t("teacher.tabs.schedules.backSchedule") }}
        </v-btn>
      </v-col>

      <!-- Add unavailability button action -->
      <!-- Temporary disabled: https://gitlab.lbd.org.es/lms4.0/webapp/-/issues/387  -->
      <v-col cols="6" md="3" lg="3" offset-md="1" v-if="false">
        <v-btn
          block
          color="error darken-1"
          @click="unavailabilityDialogShow = true"
        >
          <v-icon class="mr-2">event_busy</v-icon>
          {{ $t("teacher.tabs.schedules.addUnavailability") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>
    <!-- Calendar row -->
    <v-row no-gutters>
      <!-- Availability Calendar -->
      <availability-calendar
        v-if="editingAvailabilities"
        height="650"
        entityType="teacher"
        :entityId="teacherId"
        @availabilityChanged="fetchDataWithoutId"
        @calendar-change="updateData"
        :schedule="schedule"
      ></availability-calendar>

      <!-- Unavailability dialog -->
      <unavailability-dialog
        v-if="unavailabilityDialogShow"
        entityType="teacher"
        :entityId="teacherId"
        :timezone="schedule.timeZone"
        :unavailabilities="schedule.unavailabilities"
        @unavailabilityChanged="fetchDataWithoutId"
        v-model="unavailabilityDialogShow"
      ></unavailability-dialog>

      <!-- General Calendar -->
      <schedule-calendar
        v-if="!editingAvailabilities"
        entityType="teacher"
        height="650"
        :schedule="schedule"
        @scheduleChanged="fetchDataWithoutId"
        @calendar-change="updateData"
      ></schedule-calendar>
    </v-row>
  </v-container>
</template>

<script>
import AvailabilityCalendar from "@/components/calendar/AvailabilityCalendar";
import ScheduleCalendar from "@/components/calendar/ScheduleCalendar";
import UnavailabilityDialog from "@/components/calendar/UnavailabilityDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  components: {
    UnavailabilityDialog,
    AvailabilityCalendar,
    ScheduleCalendar,
  },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editingAvailabilities: false,
      calendar: null,
      focus: new Date(),
      schedule: {
        availabilities: [],
        lectures: [],
        unavailabilities: [],
        timeZone: [],
      },
      unavailabilityDialogShow: false,
    };
  },
  methods: {
    _fetchData(id, start, end) {
      return TeacherEntityRepository.getSchedule(
        id,
        start.toISOString().substring(0, 16),
        end.toISOString().substring(0, 16)
      )
        .then((response) => (this.schedule = response))
        .catch(() =>
          this.$log.debug("Error fetching schedule for teacher with ID " + id)
        );
    },
    fetchDataWithoutId(start, end) {
      this._fetchData(this.teacherId, start, end);
    },
    clearEditVariables() {
      this.editingAvailabilities = false;
    },
    updateData(start, end) {
      this.fetchDataWithoutId(start, end);
    },
  },
};
</script>

<style></style>
