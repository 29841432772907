var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center text-h2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"8"}},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("teacher.dashboard.your_information")))]),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.nextLectures.length < 1},on:{"click":function($event){_vm.expandNextLectures = !_vm.expandNextLectures}}},[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.next_lectures"))+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.expandNextLectures ? "expand_less" : "expand_more")+" ")])],1),(_vm.loading)?_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandNextLectures && !_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.nextLectures),function(lecture){return _c('v-row',{key:lecture.id,staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:lecture.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"300px"},on:{"click":function($event){return _vm.navigate('Lecture Detail', lecture.id)}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(lecture.productName)+" ")])])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(lecture.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(lecture.endTime,"time"))+" ")]),(_vm.userCanJoin(lecture))?_c('v-container',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(lecture)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("login")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))])])],1):_vm._e()],1)],1)]}}],null,true)})],1)}),(!_vm.nextLectures || _vm.nextLectures.length === 0)?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.no_next_lectures"))+" ")])]):_vm._e()],2):_vm._e()],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.nextLevelTest.length < 1},on:{"click":function($event){_vm.expandNextTests = !_vm.expandNextTests}}},[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.next_level_test"))+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.expandNextTests ? "expand_less" : "expand_more")+" ")])],1),(_vm.loading)?_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandNextTests && !_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.nextLevelTest),function(lecture){return _c('v-row',{key:lecture.id,staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:lecture.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"300px"},on:{"click":function($event){return _vm.navigate('Lecture Detail', lecture.id)}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(lecture.language ? _vm.$t("teacher.dashboard.level_test", { lang: _vm.$t( ("languages." + (lecture.language)) ), }) : "-")+" ")])])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(lecture.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(lecture.endTime,"time"))+" ")]),(_vm.userCanJoin(lecture))?_c('v-container',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(lecture)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("login")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))])])],1):_vm._e()],1)],1)]}}],null,true)})],1)}),(!_vm.nextLevelTest || _vm.nextLevelTest.length === 0)?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.no_next_level_test")))])]):_vm._e()],2):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.supervised_information"))+" ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.teachersNextLectures.length < 1},on:{"click":function($event){_vm.expandTeachersNextLectures = !_vm.expandTeachersNextLectures}}},[_vm._v(" "+_vm._s(_vm.$t("teacher.dashboard.next_lectures"))+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.expandTeachersNextLectures ? "expand_less" : "expand_more")+" ")])],1),(_vm.loading)?_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandTeachersNextLectures && !_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.teachersNextLectures),function(lecture){return _c('v-row',{key:lecture.id,staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:lecture.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"300px"},on:{"click":function($event){return _vm.navigate('Lecture Detail', lecture.id)}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(_vm.getTeacherName(lecture))+" ")])])],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center text-subtitle-1"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(lecture.productName)+" ")])]),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(lecture.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(lecture.endTime,"time"))+" ")])],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{
                                      name: 'Supervised Teacher Lecture List',
                                      params: {
                                        teacherId: lecture.teacherId,
                                      },
                                      query: { closeLectures: 'true' },
                                    },"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("list")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("teacher.dashboard.supervised_lectures")))])]),(_vm.userCanJoin(lecture))?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(lecture)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("login")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))])])],1):_vm._e()],1)],1)],1)],1)]}}],null,true)})],1)}),(
                      !_vm.teachersNextLectures ||
                      _vm.teachersNextLectures.length === 0
                    )?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "teacher.dashboard.no_next_lectures_supervised_teachers" )))])]):_vm._e()],2):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }