<template>
  <div>
    <v-row>
      <v-col cols="6" md="8">
        <span class="headline">{{ $t("unit.title") }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="3" lg="2" class="text-right">
        <v-btn @click="openUnitForm()" color="success">
          <v-icon>add</v-icon>
          {{ $t("unit.name") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-col class="text-center" cols="12" v-if="!myunits">
      <span>{{ $t("unit.messages.no_units_avail") }}</span>
    </v-col>

    <v-row no-gutters>
      <v-expansion-panels v-model="panel" focusable>
        <v-expansion-panel
          @click="getUnitInfo(unit)"
          v-for="unit in myunits"
          :key="unit.id"
        >
          <v-expansion-panel-header class="expansion-panel">
            <v-row no-gutters>
              <v-col cols="8">
                <h2>
                  {{ unit.name }}
                </h2>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      @click.stop="openUnitForm(unit)"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.edit") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      @click.stop="openDeleteUnitDialog(unit)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- Lessons -->
            <v-container v-if="unit.lessons">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="openLessonForm(unit)"
                  class="mb-2"
                >
                  <v-icon> add</v-icon>
                  {{ $t("lesson.name") }}
                </v-btn>
              </v-row>
              <v-row>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(lesson, j) in unit.lessons"
                    :key="j"
                    focusable
                  >
                    <v-expansion-panel-header class="expansion-panel-header">
                      <lesson-detail
                        :unit="unit"
                        :lesson="lesson"
                        @changed="updateInfo"
                      ></lesson-detail>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col class="py-0 px-0">
                        <!-- Activities -->
                        <div v-if="lesson.activities.length === 0">
                          <v-row class="text-center" align="center">
                            <v-col cols="12">
                              <h3>
                                {{ $t("activity.messages.no_activities") }}
                              </h3>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                                color="success"
                                @click="manageActivityDialog(unit, lesson)"
                              >
                                <v-icon>add</v-icon>
                                {{ $t("activity.name") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="3" class="text-right">
                              <v-btn
                                class="mr-3"
                                color="success"
                                @click="manageActivityDialog(unit, lesson)"
                                ><v-icon>add</v-icon>{{ $t("activity.name") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>

                        <v-card-text>
                          <v-col
                            v-for="(activity, index) in lesson.activities"
                            :key="activity.id"
                            class="py-0"
                          >
                            <activity-lesson-detail
                              :id="'lesson-activity-row ' + activity.id"
                              :activity="activity"
                              :max-order="maxOrder(lesson)"
                              :loading="loadingOrder"
                              @changed="updateInfo(unit)"
                              @update-order="updateOrder(lesson, ...arguments)"
                            ></activity-lesson-detail>
                            <v-divider
                              v-if="index !== lesson.activities.length - 1"
                              class="grey lighten-1"
                            ></v-divider>
                          </v-col>
                          <span v-if="!lesson.activities">
                            {{ $t("unit.messages.no_activities_avail") }}></span
                          >
                        </v-card-text>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
            <div v-else>
              <v-row class="ma-4" justify="center">
                <span>{{ $t("unit.messages.no_content_avail") }} </span>
              </v-row>

              <v-row justify="center">
                <v-btn color="success" @click="openLessonForm(unit)">
                  <v-icon> add</v-icon>
                  {{ $t("lesson.actions.create") }}
                </v-btn>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- Dialog for adding a new Lesson/Course -->
    <v-dialog
      width="70%"
      v-if="dialogForm"
      v-model="dialogForm"
      @click:outside="cancelEdit"
    >
      <v-card>
        <v-card-title class="headline">{{
          editingType === "lesson"
            ? $t("lesson.actions.create")
            : selectedUnit
            ? $t("unit.actions.edit")
            : $t("unit.actions.create")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-form v-model="validForm" ref="form">
            <v-text-field
              :label="
                editingType === 'lesson'
                  ? $t('lesson.prop.name')
                  : $t('unit.prop.unit_name')
              "
              outlined
              class="required"
              :rules="[(v) => !!v || $t('lesson.error.required')]"
              v-model="nameForm"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-around">
            <v-btn @click="cancelEdit()">{{ $t("actions.cancel") }}</v-btn>
            <v-btn
              :disabled="!validForm"
              color="success"
              @click="editingType === 'lesson' ? saveLesson() : saveUnit()"
              ><v-icon class="mr-2">save</v-icon>{{ $t("actions.save") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create / edit Activity dialog -->
    <v-dialog scrollable width="80%" v-model="activityFormDialog">
      <activity-lesson-form
        v-if="selectedLesson"
        @cancel="manageActivityDialog()"
        @update="updateInfo(selectedUnit)"
        :lesson="selectedLesson"
        :level="course.level.id"
      ></activity-lesson-form>
    </v-dialog>

    <!-- Delete unit confirmation dialog -->
    <delete-dialog
      :dialog="showDeleteDialog"
      :loading="deleteLoading"
      :title="$t('unit.messages.delete_success')"
      @cancel="showDeleteDialog = false"
      @submit="deleteUnit"
    ></delete-dialog>
  </div>
</template>

<script>
import LessonDetail from "@/mockups/content-cloud/_components/LessonDetail";
import ActivityLessonDetail from "@/mockups/content-cloud/_components/ActivityLessonDetail";
import ActivityLessonForm from "@/mockups/content-cloud/_components/ActivityLessonForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

const UnitEntityRepository = RepositoryFactory.get("UnitEntityRepository");
const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");
const ActivityLessonEntityRepository = RepositoryFactory.get(
  "ActivityLessonEntityRepository"
);

export default {
  name: "SimpleUnitList",
  components: {
    DeleteDialog,
    LessonDetail,
    ActivityLessonDetail,
    ActivityLessonForm,
  },
  props: {
    units: {
      type: Array,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteLoading: false,
      panel: null,
      validForm: true,
      myunits: {},
      selectedUnit: null,
      selectedLesson: null,
      // Courses / lessons
      dialogForm: false,
      nameForm: null,
      editingType: null,
      showDeleteDialog: false,
      // Activities
      activityFormDialog: false,
      loadingOrder: false,
      lessonPanels: [],
    };
  },
  watch: {
    units: {
      handler(value) {
        this.myunits = value;
        this.myunits.forEach((unit) => {
          if (unit.lessons) {
            unit.lessons.forEach((lesson) =>
              lesson.activities.sort((a, b) => a.order - b.order)
            );
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    maxOrder(lesson) {
      return Math.max.apply(
        Math,
        lesson.activities.map(function (o) {
          return o.order;
        })
      );
    },
    getUnitInfo(unit) {
      if (!unit.cached) {
        UnitEntityRepository.get(unit.id)
          .then((res) => {
            // Cached true default
            const index = this.myunits.findIndex((el) => el.id === unit.id);
            res.cached = true;
            this.$set(this.myunits, index, res);

            // Order lessons
            this.myunits.forEach((element) => {
              if (element.lessons)
                return element.lessons.sort((a, b) => {
                  return a.lessonOrder - b.lessonOrder;
                });
            });
          })
          .catch(() =>
            this.$log.debug("Error fetching unit with ID " + unit.id)
          );
      }
    },
    updateInfo(unit) {
      unit.cached = false;
      this.getUnitInfo(unit);
      this.selectedUnit = null;
      this.manageActivityDialog();
    },
    // CRUD UNITS
    openUnitForm(unit) {
      if (unit) {
        this.selectedUnit = unit;
        this.nameForm = unit.name;
      }
      this.dialogForm = true;
    },
    cancelEdit() {
      this.dialogForm = false;
      this.selectedUnit = null;
      this.nameForm = null;
      this.editingType = null;
      this.$refs.form.reset();
    },
    saveUnit() {
      if (this.validForm) {
        if (this.selectedUnit) {
          let newEntity = this.selectedUnit;
          newEntity.name = this.nameForm;
          UnitEntityRepository.save(newEntity)
            .then(() => {
              this.selectedUnit.cached = false;
              this.getUnitInfo(this.selectedUnit);
              this.cancelEdit();
            })
            .catch(() => this.$log.debug("Error saving unit: " + newEntity));
        } else {
          UnitEntityRepository.save({
            name: this.nameForm,
            courseId: this.course.id,
          })
            .then((res) => {
              this.$emit("updated");
              this.cancelEdit();
            })
            .catch(() =>
              this.$log.debug(
                "Error saving unit named as " +
                  this.nameForm +
                  " for course with ID " +
                  this.course.id
              )
            );
        }
      }
    },
    openDeleteUnitDialog(unit) {
      this.selectedUnit = unit;
      this.showDeleteDialog = true;
    },
    deleteUnit() {
      this.deleteLoading = true;
      UnitEntityRepository.delete(this.selectedUnit.id)
        .then(() => {
          this.$notify({
            title: this.$t("unit.messages.delete_success"),
            text: this.$t("account.notifications.successfull_deleted"),
            type: "success",
          });
          this.panel = null;
          this.$emit("updated");
          this.selectedUnit = null;
          this.showDeleteDialog = false;
        })
        .catch(() =>
          this.$log.debug("Error deleting unit with ID " + this.selectedUnit.id)
        )
        .finally(() => (this.deleteLoading = false));
    },
    // CRUD LESSONS
    openLessonForm(unit) {
      this.editingType = "lesson";
      this.selectedUnit = unit;
      this.dialogForm = true;
    },
    saveLesson() {
      if (this.validForm) {
        LessonEntityRepository.save({
          name: this.nameForm,
          unitId: this.selectedUnit.id,
        })
          .then(() => {
            this.selectedUnit.cached = false;
            this.getUnitInfo(this.selectedUnit);
            this.cancelEdit();
          })
          .catch(() =>
            this.$log.debug(
              "Error saving lesson: " +
                {
                  name: this.nameForm,
                  unitId: this.selectedUnit.id,
                }
            )
          );
      }
    },
    // CRUD Activities
    manageActivityDialog(unit, lesson) {
      this.selectedLesson = lesson;
      this.selectedUnit = unit;
      // We open the dialog when it's received a lesson and a unit
      this.activityFormDialog = unit && lesson;
    },
    updateOrder(lesson, activity, order, loadingIndex) {
      this.loadingOrder = true;
      return ActivityLessonEntityRepository.updateOrder(activity.id, order)
        .then(() => {
          // Update activities order
          if (activity.order < order) {
            lesson.activities.forEach((el) => {
              if (el.order > activity.order && el.order <= order) {
                el.order--;
              }
            });
          } else {
            lesson.activities.forEach((el) => {
              if (el.order < activity.order && el.order >= order) {
                el.order++;
              }
            });
          }
          lesson.activities.find((el) => el.id === activity.id).order = order;
          // Sort activities again
          lesson.activities.sort((a, b) => a.order - b.order);
          // Next tick for wait data table updates rows order before apply css animations
          this.$nextTick(() => {
            const element = document.getElementById(
              "lesson-activity-row " + activity.id
            );
            element.classList.add("change-out");
            setTimeout(() => {
              element.classList.remove("change-out");
              element.classList.add("change-in");
              setTimeout(() => {
                element.classList.remove("change-in");
                this.loadingOrder = false;
              }, 1000);
            }, 400);
          });
        })
        .catch(() => {
          this.loadingOrder = false;
          this.$log.debug(
            "Error updating order for activity with ID " + activity.activityId
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.expansion-panel-header {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #97d2ff88;
  font-size: large;
}
</style>
