var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar-items',[(false)?_c('span',[_vm._l((_vm.singleItems),function(singleItem){return _c('v-btn',{key:singleItem.title,attrs:{"text":"","to":{
        name: singleItem.link,
        params: singleItem.params,
        query: singleItem.query,
      }}},[_vm._v(" "+_vm._s(_vm.$t(singleItem.title))+" ")])}),_vm._l((_vm.menuItems),function(menuItem){return _c('v-menu',{key:menuItem.title,staticClass:"dropdown-content",attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"height":"100%"},attrs:{"text":"","tile":""}},on),[_vm._v(" "+_vm._s(_vm.$t(menuItem.title))+" "),_c('v-icon',[_vm._v("expand_more")])],1)]}}],null,true)},[_c('v-list',[_vm._l((menuItem.items),function(item){return [_c('v-list-item',{key:item.title,attrs:{"to":{ name: item.link, params: item.params, query: item.query }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1),(item.divider)?_c('v-divider',{key:item.title + '_divider'}):_vm._e()]})],2)],1)})],2):_vm._e(),_c('v-spacer'),_c('span',[(_vm.user.id && !_vm.isGuest)?_c('v-btn',{attrs:{"color":"accent","icon":"","tile":""},on:{"click":function($event){return _vm.$emit('open-chat')}}},[(_vm.user.id && !_vm.isGuest)?_c('v-badge',{attrs:{"content":_vm.notifications,"value":_vm.notifications > 0,"color":"red","overlap":""}},[_c('icon',{attrs:{"name":"chat-simple","width":"28","height":"28","title":"chat"}})],1):_vm._e()],1):_vm._e()],1),_c('span',{staticClass:"profile-menu"},[(_vm.isLogged)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({class:{ 'button-on-collapse': _vm.collapsed },attrs:{"icon":""}},on),[_c('v-avatar',{attrs:{"size":"28"}},[_c('v-img',{attrs:{"src":_vm.photo,"lazy-src":_vm.defaultProfilePhoto}})],1)],1)]}}],null,false,1977440333)},[_c('v-list',{staticClass:"teal"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.photo,"lazy-src":_vm.defaultProfilePhoto}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.user.displayName || _vm.user.firstName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.login))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.loggedAs))])],1)],1),(_vm.user.authorities.length > 1)?_c('v-divider'):_vm._e(),(_vm.user.authorities.length > 1)?_c('v-list-group',{staticClass:"teal lighten-4",attrs:{"no-action":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("user_management.authorities"))+" ")])]},proxy:true}],null,false,3911108607)},_vm._l((_vm.authorities),function(authority){return _c('v-list-item',{key:authority,class:_vm.user.selectedAuthority === authority
                ? 'selected-authority'
                : 'no-selected-authority',on:{"click":function($event){return _vm.changeAuthority(authority)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("user_management.roles." + authority))+" ")])],1)],1)}),1):_vm._e(),(_vm.profileItems.length > 0)?_c('v-divider'):_vm._e(),_c('v-list',{staticClass:"teal lighten-4"},[_vm._l((_vm.profileItems),function(profileItem){return _c('v-list-item',{key:profileItem.title,attrs:{"to":{
              name: profileItem.link,
              params: profileItem.params,
              query: profileItem.query,
            }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(profileItem.title))+" ")])],1)],1)}),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("menuBar.logout"))+" ")])],1)],1)],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }