<template>
  <v-container>
    <v-card v-if="!loading">
      <v-card-title>
        <span class="headline no-split-words">
          {{ activity.activity.title }}
        </span>
        <v-spacer></v-spacer>
        <v-btn :disabled="finished" @click="back">
          <v-icon>arrow_back</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("lectureActivity.actions.leave") }}
          </span>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="activity.activity.resourceType === 'ACTIVITY'">
        <H5PPlayer
          :exercise="activity.activity.content.fileName"
          :onXApiStatement="processStatement"
        />
      </v-card-text>
      <v-card-text v-if="activity.activity.resourceType === 'IMAGE'">
        <v-divider class="primary mb-2"></v-divider>
        <v-img :src="file" contain></v-img>
      </v-card-text>
      <v-card-text v-if="activity.activity.resourceType === 'VIDEO'">
        <video-player v-if="playerSrc" :src="playerSrc" />
      </v-card-text>
      <v-card-text v-if="activity.activity.resourceType === 'AUDIO'">
        <audio-player v-if="playerSrc" :src="playerSrc" />
      </v-card-text>
      <v-card-actions v-if="activity.activity.typeH5p">
        <v-spacer />
        <v-btn :disabled="!finished" color="success" @click="back">
          <span class="d-none d-sm-block">
            {{ $t("lectureActivity.actions.finish") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import AudioPlayer from "@/components/audio-player/AudioPlayer";
import VideoPlayer from "@/components/video-player/VideoPlayer";
import H5PPlayer from "../../content-cloud/activity/H5PPlayer.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import XApiStatementsManagement from "./XApiStatementsManagement";

const LectureActivityEntityRepository = RepositoryFactory.get(
  "LectureActivityEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);

export default {
  name: "PlayHomework",
  components: {
    H5PPlayer,
    AudioPlayer,
    VideoPlayer,
    LoadingPage,
  },
  props: {
    lectureActivityId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      activity: {},
      file: {},
      playerSrc: null,
      activityId: null,
      loading: true,
      finished: false,
      processStatement: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchLectureActivity(to.params.lectureActivityId);
    next();
  },
  created() {
    if (this.$route.params.studentId === auth.getUser().roleId) {
      this._fetchLectureActivity(this.$route.params.lectureActivityId);
    } else {
      this.$log.debug("Error: Unauthorized access");
      this.back();
    }
  },
  methods: {
    _fetchLectureActivity(id) {
      this.loading = true;
      return LectureActivityEntityRepository.getHomework(id)
        .then((response) => {
          this.activity = response;
          if (response.activity.resourceType === "ACTIVITY") {
            this.activityId = this.activity.id;
            this.processStatement =
              XApiStatementsManagement.processStatement.bind(
                this,
                this.$route.params.lectureId,
                this.$route.params.activityInteractionId,
                this.onSaved
              );
          }
          if (response.activity.resourceType === "IMAGE") {
            this.downloadFile(response.activity);
          }
          if (
            ["VIDEO", "AUDIO"].indexOf(response.activity.resourceType) !== -1
          ) {
            this.playerSrc = ActivityEntityRepository.getActivityContentUrl(
              response.activity.id
            );
          }
          if (
            ["IMAGE", "VIDEO", "AUDIO"].indexOf(
              response.activity.resourceType
            ) !== -1 &&
            !response.solved
          ) {
            this.completeHomework(response.activityInteraction);
          }
        })
        .catch(() => {
          this.$log.debug("Error fetching lectureActivity with ID: " + id);
          this.back();
        })
        .finally(() => (this.loading = false));
    },
    async completeHomework(id) {
      await ActivityInteractionEntityRepository.save({
        id: id,
      }).then(() => {
        this.$notify({
          title: this.$t("lectureActivity.messages.homework_saved"),
          text: this.$t("account.notifications.changes_saved"),
          type: "success",
        });
      });
    },
    async downloadFile(activity) {
      await ActivityEntityRepository.downloadActivityFile(activity.id)
        .then((response) => {
          const blob = new Blob([response.data]);
          this.file = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching file from activity with ID " + this.activity.id
          )
        );
    },
    onSaved() {
      // Executed when a H5P activity is completed
      this.finished = true;
    },
    back() {
      if (this.finished) {
        this.$notify({
          title: this.$t("lectureActivity.messages.homework_saved"),
          text: this.$t("lectureActivity.messages.results_saved"),
          type: "success",
        });
      }
      this.$router.push({ name: "ProfileStudentResultsTab" });
    },
  },
};
</script>
