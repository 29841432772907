<template>
  <div class="language-selector">
    <v-select
      :outlined="outlined"
      :dense="dense"
      :items="languages"
      :label="label"
      :rules="rules"
      item-value="value"
      v-model="langSelected"
    >
      <template v-slot:selection="data">
        <v-row no-gutters>
          <v-list-item-icon class="selectable-lang">
            <img :src="data.item.flag" width="35" height="20" />
          </v-list-item-icon>
          <v-list-item-content class="selectable-lang">
            <v-list-item-title v-html="data.item.title"></v-list-item-title>
          </v-list-item-content>
        </v-row>
      </template>

      <template v-slot:item="data">
        <v-list-item-icon>
          <img :src="data.item.flag" width="35" height="20" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.title"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.langSelected = newVal;
        }
      },
      immediate: true,
    },
    langSelected(val) {
      this.$emit("input", val);
    },
  },
  data() {
    return {
      langSelected: this.value,
      languages: [
        {
          flag: require("@/assets/flag_icons/uk_flag.svg"),
          language: "EN",
          title: "English",
          value: "en",
        },
        {
          flag: require("@/assets/flag_icons/es_flag.svg"),
          language: "ES",
          title: "Español",
          value: "es",
        },
        {
          flag: require("@/assets/flag_icons/cn_flag.svg"),
          language: "CN",
          title: "中文",
          value: "cn",
        },
      ],
    };
  },
};
</script>

<style scoped>
.selectable-lang {
  padding: 0;
  margin: 0;
}
.language-selector {
  width: 100%;
}
</style>
