<template>
  <product-form
    v-if="!loading"
    v-model="product"
    @save="save"
    @back="back"
  ></product-form>
  <loading-page v-else></loading-page>
</template>

<script>
import ProductForm from "@/mockups/economy/products/ProductForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

export default {
  name: "ProductCreateForm",
  components: { ProductForm, LoadingPage },
  data() {
    return {
      product: {},
      loading: false,
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: "Admin Product List",
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      ProductEntityRepository.save(this.product)
        .then((res) => {
          this.$router.push({
            name: "Admin Product List",
            params: {
              backAction: this.$route.params.backPrevious,
              id: res.id,
            },
          });
        })
        .catch(() => this.$log.debug("Error saving product: " + this.product))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
