<template>
  <v-row class="fill-height">
    <!-- Buttons with options -->
    <v-col>
      <v-row>
        <v-col class="text-right">
          <span
            >{{ $t("profile.fields.timeZone") }} :
            {{ $t(`timezones.${schedule.timeZone.name}`) }}</span
          >
        </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="6" md="3">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            {{ $t("scheduleCalendar.today") }}
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" md="3"
          ><span class="headline" v-if="$refs.calendar">
            {{ $refs.calendar.title }}</span
          ></v-col
        >
        <v-col cols="6" md="5">
          <v-select
            v-model="selectedFilter"
            :items="availableFilters"
            :item-text="
              (item) =>
                this.$t(`scheduleCalendar.filtersAvailable.${item.name}`)
            "
            return-object
            text
            multiple
            dense
            clearable
            outlined
            @change="formatEvents()"
            :label="$t('scheduleCalendar.filters')"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="1">
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn class="px-1" outlined v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeView('day')">
                <v-list-item-title>{{ $t("day") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeView('week')">
                <v-list-item-title>{{ $t("week") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeView('month')">
                <v-list-item-title>{{ $t("month") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <!-- Calendar component -->
      <v-sheet :height="height">
        <v-calendar
          ref="calendar"
          v-model="focus"
          class="small-calendar"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :weekdays="weekdays"
          @change="updateRange"
          @click:event="showEvent"
          @click:more="onDayClick"
          @click:date="onDayClick"
          @mouseenter:event="showDeleteIcon"
          @mouseleave:event="hideDeleteIcon"
          :locale="$i18n.locale"
        >
          <template v-slot:event="{ event }">
            <v-hover v-slot="{ hover }">
              <div class="event-div">
                <v-row align="center" justify="center" no-gutters>
                  <div
                    v-if="hover"
                    class="v-expand--transition d-flex"
                    style="height: 100%"
                  >
                    <v-btn v-if="event.type == 'lecture'" icon color="white">
                      <v-icon>search</v-icon>
                    </v-btn>
                  </div>
                  <v-col style="height: 100%" cols="12">
                    <span>
                      {{ event.label || event.type }}
                    </span>
                    <br />
                    <span>
                      {{ event.start.split("T")[1] }} /
                      {{ event.end.split("T")[1] }}
                    </span>
                    <br />
                    <!--<v-btn
                      color="white"
                      v-if="event.type == 'unavailability'"
                      icon
                      @click.stop="showDeleteDialog = true"
                    >
                      <v-icon v-if="event.closeMenu">delete</v-icon>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </div>
            </v-hover>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>

    <!-- Confirmation dialog for deleting an unavailability -->
    <!--<delete-dialog
      :dialog="showDeleteDialog"
      :title="$t('scheduleCalendar.confirmationDeleteUnavail')"
      @cancel="showDeleteDialog = false"
      @submit="deleteUnavailability()"
    >
    </delete-dialog>-->
  </v-row>
</template>

<script>
import {
  dateArrayToDate,
  utcDateArrayToDateWithTimezoneFormatted,
} from "@/common/conversion-utils";
import moment from "moment-timezone";
/*import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TeacherUnavailabilityEntityRepository = RepositoryFactory.get(
  "TeacherUnavailabilityEntityRepository"
);
const StudentUnavailabilityEntityRepository = RepositoryFactory.get(
  "StudentUnavailabilityEntityRepository"
);*/
export default {
  props: {
    height: {
      type: String,
      required: false,
      default: "550",
    },
    schedule: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
  },
  /*components: {
    DeleteDialog,
  },*/
  data() {
    return {
      focus: new Date().toISOString().slice(0, 10),
      type: "week",
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      availableFilters: [
        { name: "availability", value: "avail" },
        //{ name: "unavailability", value: "unavail" },
        { name: "lecture", value: "lectures" },
      ],
      selectedFilter: [
        { name: "availability", value: "avail" },
        //{ name: "unavailability", value: "unavail" },
        { name: "lecture", value: "lectures" },
      ],
      //selectedUnavail: null,
      //showDeleteDialog: false,
      events: [],
    };
  },
  computed: {
    typeToLabel() {
      return {
        month: this.$t("month"),
        week: this.$t("week"),
        day: this.$t("day"),
      };
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.$refs.calendar.scrollToTime("09:00");
  },
  created() {
    this.formatEvents();
  },
  watch: {
    schedule() {
      this.formatEvents();
    },
    "$i18n.locale"() {
      this.formatEvents();
    },
  },
  methods: {
    // Events for handling controls in calendar
    onDayClick({ date }) {
      this.focus = date;
      if (this.type == "week") {
        this.type = "day";
      } else if (this.type == "month") {
        this.type = "week";
      }
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = new Date().toISOString().slice(0, 10);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    timeChanged(calendaryType) {
      this.type = calendaryType;
      this.formatEvents();
    },
    updateRange({ start, end }) {
      this.start = moment.utc(start.date);
      this.end = moment.utc(end.date).add(1, "days");
      this.$emit("calendar-change", this.start, this.end);
    },
    changeView(type) {
      this.type = type;
    },
    /**
     * Formats the backend events
     * to be viewed in the calendar
     */
    formatEvents() {
      this.events = [];
      if (this.selectedFilter.find((el) => el.value == "avail")) {
        this.events = [...this.events, ...this.formatAvailabilities()];
      }
      /*if (this.selectedFilter.find((el) => el.value == "unavail")) {
        this.events = [...this.events, ...this.formatUnavailabilities()];
      }*/
      if (this.selectedFilter.find((el) => el.value == "lectures")) {
        this.events = [...this.events, ...this.formatLectures()];
      }
    },
    formatUnavailabilities() {
      if (this.schedule.unavailabilities.lenght != 0) {
        return this.schedule.unavailabilities.map((el) => {
          return {
            id: el.id,
            name: el.id.toString(),
            color: "red",
            type: "unavailability",
            closeMenu: false,
            start: utcDateArrayToDateWithTimezoneFormatted(
              el.startDate,
              this.schedule.timeZone
            ),
            end: utcDateArrayToDateWithTimezoneFormatted(
              el.endDate,
              this.schedule.timeZone
            ),
          };
        });
      } else return [];
    },
    formatAvailabilities() {
      if (this.schedule.availabilities.length != 0) {
        return this._getValidAvailabilities().map((el) => {
          return {
            id: el.id,
            name: el.id.toString(),
            color: "green",
            type: "availability",
            label: this.$t("calendar.availability"),
            start: utcDateArrayToDateWithTimezoneFormatted(
              el.startDate,
              this.schedule.timeZone
            ),
            end: utcDateArrayToDateWithTimezoneFormatted(
              el.endDate,
              this.schedule.timeZone
            ),
          };
        });
      } else return [];
    },
    getLectureColor(el) {
      if (el.state === "PLANNED") return "primary";
      if (el.state === "DOING") return "orange";
      if (el.state === "DONE") return "grey";
      if (el.state === "CANCELLED") return "grey";
      return "primary";
    },
    formatLectures() {
      if (this.schedule.lectures.length != 0) {
        return this.schedule.lectures.map((el) => {
          return {
            id: el.id,
            name: el.id,
            color: this.getLectureColor(el),
            type: "lecture",
            label: el.levelTest
              ? this.$i18n.t("calendar.level_test")
              : el.productName,
            start: utcDateArrayToDateWithTimezoneFormatted(
              el.startTime,
              this.schedule.timeZone
            ),
            end: utcDateArrayToDateWithTimezoneFormatted(
              el.endTime,
              this.schedule.timeZone
            ),
          };
        });
      } else return [];
    },
    /**
     * Filter availabilities out of unavailabilities dates
     */
    _getValidAvailabilities() {
      return this.schedule.availabilities.filter((el) =>
        this._validateAvailability(el.startDate, el.endDate)
      );
    },
    _validateAvailability(startDate, endDate) {
      return (
        // check if slot is in any lecture dates range
        this.schedule.lectures.findIndex((el) => {
          let lectureStart = dateArrayToDate(el.startTime);
          let lectureEnd = dateArrayToDate(el.endTime);
          let availabilityStart = dateArrayToDate(startDate);
          let availabilityEnd = dateArrayToDate(endDate);
          // any slot partially or totally in an unavailability dates range is accepted
          return (
            (availabilityStart >= lectureStart &&
              availabilityStart < lectureEnd) ||
            (availabilityEnd > lectureStart && availabilityEnd <= lectureEnd) ||
            (availabilityStart < lectureStart && availabilityEnd > lectureEnd)
          );
        }) == -1
      );
    },
    //TODO, redireccionar a la lecture probablememte
    showEvent({ event }) {
      if (event.type == "lecture") {
        this.$router.push({
          name: "Lecture Detail",
          params: { id: event.id, backPrevious: true },
        });
      }
    },
    showDeleteIcon(evt) {
      if (evt.event.type == "unavailability") {
        let index = this.events.findIndex((el) => {
          if (el.type == "unavailability" && el.id == evt.event.id) return el;
        });
        let event = this.events[index];
        this.selectedUnavail = event;
        event.closeMenu = true;
        this.$set(this.events, index, event);
      }
    },
    hideDeleteIcon(evt) {
      if (evt.event.type == "unavailability") {
        let index = this.events.findIndex((el) => {
          if (el.type == "unavailability" && el.id == evt.event.id) return el;
        });
        let event = this.events[index];
        if (!this.showDeleteDialog) {
          this.selectedUnavail = null;
          event.closeMenu = false;
        }
        this.$set(this.events, index, event);
      }
    },
    deleteUnavailability() {
      if (this.entityType == "teacher") {
        TeacherUnavailabilityEntityRepository.delete(this.selectedUnavail.id)
          .then(() => {
            this.showDeleteDialog = false;
            this.selectedUnavail = null;
            this.$emit("scheduleChanged", null);
          })
          .catch(() =>
            this.$log.debug(
              "Error removing teacher unavailability with ID " +
                this.selectedUnavail.id
            )
          );
      }
      if (this.entityType == "student") {
        StudentUnavailabilityEntityRepository.delete(this.selectedUnavail.id)
          .then(() => {
            this.showDeleteDialog = false;
            this.selectedUnavail = null;
            this.$emit("scheduleChanged", null);
          })
          .catch(() =>
            this.$log.debug(
              "Error removing student unavailability with ID: " +
                this.selectedUnavail.id
            )
          );
      }
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
</style>
