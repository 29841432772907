<template>
  <v-container>
    <v-card>
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="6" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-row justify="center">
                <span>
                  {{ $t("account.notifications.enter_password") }}
                </span>
              </v-row>
              <v-row>
                <v-text-field
                  class="form-control required"
                  v-model="user.password"
                  name="password"
                  maxLength="100"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword1 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.form.password')"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    (value) =>
                      value.length <= 100 ||
                      $t('account.form.validation.password_less_chars'),
                    (value) =>
                      value.length > 3 ||
                      this.$t('account.form.validation.password_more_chars'),
                  ]"
                  :type="hidePassword1 ? 'password' : 'text'"
                  @click:append="() => (hidePassword1 = !hidePassword1)"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  class="form-control required"
                  v-model="user.password2"
                  name="password2"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword2 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.actions.repeat_password')"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    passwordConfirmationRule,
                  ]"
                  :type="hidePassword2 ? 'password' : 'text'"
                  @click:append="() => (hidePassword2 = !hidePassword2)"
                  @keypress.enter="submitNewPassword"
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn class="primary mt-4" @click="submitNewPassword">
                  {{ $t("account.actions.reset_password") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  name: "ChangePassword",
  data() {
    return {
      user: {
        password: "",
        password2: "",
      },
      validForm: true,
      hidePassword1: true,
      hidePassword2: true,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.user.password === this.user.password2 ||
        this.$t("account.form.validation.password_match");
    },
  },
  methods: {
    submitNewPassword() {
      if (this.validForm) {
        AccountRepository.changePassword(this.user.password).then((res) => {
          if (!res.isAxiosError) {
            this.$notify({
              title: "Saved",
              text: this.$t("account.notifications.changes_saved"),
              type: "success",
            });
          }
          this.$router.back();
        });
      }
    },
  },
};
</script>
