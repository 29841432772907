var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
          data: _vm.startTimeFilter,
          label: 'lecture.prop.startTime',
        }},on:{"update-time":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'startTimeFilter', false ].concat( argsArray ))}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
          data: _vm.endTimeFilter,
          label: 'lecture.prop.endTime',
        }},on:{"update-time":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'endTimeFilter', false ].concat( argsArray ))}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2","align":"right"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary","loading":_vm.isLoadingPDF},on:{"click":_vm.getPDF}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("file_download")]),_vm._v(" "+_vm._s(_vm.$t("lecture.export.pdf"))+" ")],1)],1)],1),_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.lectures,"options":_vm.entitiesPage,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [(item.date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.date,"short"))+" ")]):_vm._e()]}},{key:"item.startTime",fn:function(ref){
        var item = ref.item;
return [(item.startTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.startTime,"time"))+" ")]):_vm._e()]}},{key:"item.endTime",fn:function(ref){
        var item = ref.item;
return [(item.endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.endTime,"time"))+" ")]):_vm._e()]}},{key:"item.settleTime",fn:function(ref){
        var item = ref.item;
return [(item.settleTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.settleTime,"long"))+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }