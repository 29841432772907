<template>
  <v-dialog persistent width="500" :value="dialog && selectedProduct">
    <v-card v-if="dialog && selectedProduct">
      <v-card-title primary-title class="primary white--text headline">
        <v-icon class="primary white--text mr-4"> school </v-icon>
        {{ $t("productRequest.modal.title") }}
      </v-card-title>
      <v-card-text class="mt-4">
        {{ $t("productRequest.modal.limitDate") }}
      </v-card-text>
      <v-card-text class="mt-4"
        ><day-picker
          calendarColor="primary"
          :date="limitDate"
          @change="handleLimitDate"
          outlined
          clearable
          :label="$t('productRequest.prop.limitDate')"
          :futureDates="true"
          :pastDates="false"
        ></day-picker>
      </v-card-text>
      <v-card-text class="mt-4" v-if="canSelectClassesPerWeek">
        {{ $t("productRequest.modal.classesPerWeek") }}</v-card-text
      >
      <v-card-text class="mt-4"
        ><v-text-field
          v-if="canSelectClassesPerWeek"
          v-model="classesPerWeek"
          type="integer"
          clearable
          outlined
          append-icon="mdi-calendar-clock"
          :label="$t('productRequest.prop.classesPerWeek')"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="cancelDialog">
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn class="mx-4 primary" @click="saveDate">
          {{ $t("actions.accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
/**
 * Events emitted: cancel, submit
 */
export default {
  name: "ProductRequestDialog",
  components: { DayPicker },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      limitDate: null,
      classesPerWeek: null,
    };
  },
  computed: {
    canSelectClassesPerWeek() {
      return (
        this.selectedProduct &&
        this.selectedProduct.productType &&
        this.selectedProduct.productType.split("_")[0] === "ADHOC" &&
        this.selectedProduct.classesPerWeek === null
      );
    },
  },
  methods: {
    saveDate() {
      this.$emit("submit", {
        limitDate: this.limitDate,
        classesPerWeek: this.classesPerWeek,
      });
      this.limitDate = null;
      this.classesPerWeek = null;
    },
    cancelDialog() {
      this.$emit("cancel");
      this.limitDate = null;
      this.classesPerWeek = null;
    },
    handleLimitDate(date) {
      this.limitDate = date;
    },
  },
};
</script>
