<template>
  <v-col v-if="uploadableActivities.length > 0" cols="12">
    <span class="title">
      {{ $t("lecture.prop.activities_submit") }}
    </span>
    <a ref="hiddenDownloader" class="d-none" />
    <input
      hidden
      class="d-none"
      type="file"
      ref="uploadableActivityFileLoader"
      :accept="extensions.pdf"
      @change="saveFile"
    />
    <v-data-table
      :headers="uploadableActivitiesHeaders"
      :items="uploadableActivities"
      :loading="loadingInteractions"
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:[`item.action`]="{ item }">
        <span v-if="item.activityInteractions[0].dateSolved != null">
          {{ item.activityInteractions[0].content.fileName }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                color="primary"
                @click="downloadContent(item.activityInteractions[0].id)"
                v-bind="attrs"
                v-on="on"
                >file_download</v-icon
              >
            </template>
            <span>{{ $t("activity.actions.download_file") }}</span>
          </v-tooltip>
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              :color="
                item.activityInteractions[0].dateSolved != null
                  ? 'warning'
                  : 'success'
              "
              @click="chooseFile(item)"
              v-bind="attrs"
              v-on="on"
            >
              file_upload
            </v-icon>
          </template>
          <span>{{
            item.activityInteractions[0].dateSolved != null
              ? $t("activity.actions.modify_file")
              : $t("activity.actions.add_file")
          }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import extensions from "@/common/file-extensions";

import { downloadFile, uploadPdf } from "@/common/file-utils";

import RepositoryFactory from "@/repositories/RepositoryFactory";

const LectureActivityEntityRepository = RepositoryFactory.get(
  "LectureActivityEntityRepository"
);
const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);

export default {
  name: "LectureDetailUploadableActivities",
  props: {
    lectureId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      file: {
        loading: false,
      },
      loadingActivities: false,
      loadingInteractions: false,
      selectedEntity: null,
      uploadableActivities: [],
      extensions,
    };
  },
  computed: {
    uploadableActivitiesHeaders() {
      return [
        {
          text: this.$t("activity.name"),
          value: "title",
        },
        { text: "", value: "action" },
      ];
    },
  },
  created() {
    this._fetchUploadableActivities(this.lectureId);
  },
  methods: {
    _fetchUploadableActivities(lectureId) {
      this.loadingActivities = true;
      return LectureActivityEntityRepository.getAllUploadableBylecture(
        lectureId
      )
        .then((res) => (this.uploadableActivities = res))
        .catch(() => this.$log.debug("Error fetching uploadable activities"))
        .finally(() => (this.loadingActivities = false));
    },
    chooseFile(selectedItem) {
      this.selectedEntity = selectedItem.activityInteractions[0];
      this.$refs.uploadableActivityFileLoader.click();
    },
    downloadContent(id) {
      return ActivityInteractionEntityRepository.getContent(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("activity.messages.downloading_file"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("activity.messages.file_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("activity.messages.not_downloading_file"),
              type: "error",
              duration: 30000,
            });
          }
        });
    },
    saveFile(htmlElement) {
      uploadPdf(this.selectedEntity, "content", this.file, htmlElement).then(
        () => {
          ActivityInteractionEntityRepository.save({
            id: this.selectedEntity.id,
            content: this.selectedEntity.content,
          }).then((res) => {
            this.$notify({
              title: this.$t("activityInteraction.messages.save_success"),
              text: this.$t("account.notifications.changes_saved"),
              type: "success",
            });
            this.$emit("saved", res);
            this._fetchUploadableActivities(this.selectedEntity.lectureId);
            this.selectedEntity = null;
          });
        }
      );
    },
  },
};
</script>
