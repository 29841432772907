<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-block d-lg-none" cols="auto">
        <v-btn icon @click="navDrawer = !navDrawer">
          <v-icon> mdi-menu </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <navigation-drawer
        class="d-block d-lg-none"
        :absolute="true"
        :temporary="true"
        :items="navBarCalculated"
        v-model="navDrawer"
      >
      </navigation-drawer>
      <v-col class="d-none d-lg-block" md="auto">
        <div>
          <div v-if="!isMe">
            <h3 class="mb-4 pl-2 d-inline-flex">
              {{ entity.name }} {{ entity.surname }}
            </h3>
            <v-btn
              v-if="chatId"
              class="mb-2 d-inline-flex"
              color="accent"
              icon
              tile
              @click.stop="$EventBus.$emit('open-chat', chatId)"
            >
              <icon
                name="chat-simple"
                width="28"
                height="28"
                title="chat"
              ></icon>
            </v-btn>
          </div>
          <navigation-drawer :items="navBarCalculated" stateless :value="true">
          </navigation-drawer>
        </div>
      </v-col>
      <v-col>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isMe, mapAuthGetter } from "@/common/mapAuthGetter";
import NavigationDrawer from "@/components/navigation-drawer/NavigationDrawer";
import { navBarItemsAdmin, navBarItemsProfile } from "./tabs/navBarItems";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ChatRepository from "@/repositories/components/ChatRepository";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "ContentEditorDetail",
  components: {
    NavigationDrawer,
  },
  data() {
    return {
      navDrawer: false,
      entity: {},
      chatId: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isMe() {
      return isMe(this.entity.login);
    },
    navBarCalculated() {
      return this.$route.params.id ? navBarItemsAdmin : navBarItemsProfile;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this._fetchData(this.$route.params.id);
    }
  },
  methods: {
    _fetchData(id) {
      return UserDataEntityRepository.getByUserId(id)
        .then((response) => {
          this.entity = response;
          if (this.isAdmin) {
            this.getUserAdminsChat();
          }
        })
        .catch(() => this.$log.debug("Error fetching Admins with ID " + id));
    },
    getUserAdminsChat() {
      const options = {
        params: {
          userIds: this.entity.userId,
          type: "USER_ADMINS",
        },
      };
      ChatRepository.getChannelsByUserAndType(options)
        .then((response) => {
          if (response.length > 0) {
            this.chatId = response[0].id;
          }
        })
        .catch(() => {
          this.$log.debug(
            "Error getting USER_ADMINS chat for user with id " +
              this.entity.userId
          );
        });
    },
  },
};
</script>
