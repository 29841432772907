<template>
  <v-container v-if="!loading">
    <v-card>
      <v-card-title>
        <!-- Activity details -->
        <v-row v-if="!editingDetails" justify="space-between">
          <v-col cols="12" md="6">
            <h2>
              {{ activity.title }}
              <v-chip v-if="activity.level" color="warning" class="ma-2">
                {{ activity.level.language.name }}
              </v-chip>
              <v-chip v-if="activity.level" color="primary" class="ma-2">
                {{ activity.level.name }}
              </v-chip>
              <v-chip v-if="activity.level" color="success" class="ma-2">
                {{ activity.activityType.name }}
              </v-chip>
            </h2>
            <p class="text-h6">
              {{ activity.description }}
            </p>
          </v-col>
          <!-- Button for enter editing details mode -->
          <v-col cols="12" md="6" lg="6" class="text-right">
            <v-row align="end" justify="end">
              <v-btn @click="back" class="ma-2">
                <v-icon>arrow_back</v-icon>
                <span class="d-none d-sm-block">
                  {{ $t("actions.back") }}
                </span>
              </v-btn>
              <v-btn
                v-if="!editingDetails && isContentEditor"
                @click="editingDetails = true"
                color="warning"
                class="ma-2"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>
                {{ $t("actions.edit") }}</v-btn
              >
              <v-btn
                v-if="
                  !editingDetails &&
                  activity.content &&
                  isContentEditor &&
                  activity.typeH5p === true &&
                  activity.resourceType === 'ACTIVITY'
                "
                @click="editH5P"
                color="warning"
                class="ma-2"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>
                {{ $t("activity.actions.edit_h5p") }}</v-btn
              >
              <v-btn
                v-if="!editingDetails && isContentEditor"
                @click="changeDeleteDialog(true)"
                color="error"
                class="ma-2"
              >
                <v-icon class="mr-2">delete</v-icon>
                {{ $t("actions.delete") }}
              </v-btn>
              <v-btn
                v-if="isContentEditor && activity.typeH5p && activity.content"
                color="primary"
                class="ma-2"
                :href="'/h5p/download/' + activity.content.fileName"
              >
                <v-icon class="mr-2">file_download</v-icon>
                {{ $t("activity.actions.download_file") }}
              </v-btn>
              <a ref="hiddenDownloader" class="d-none" />
              <v-btn
                v-if="isContentEditor && !activity.typeH5p && activity.content"
                class="ma-2"
                color="primary"
                @click="downloadActivityFile"
              >
                <v-icon class="mr-2">file_download</v-icon>
                {{ $t("activity.actions.download_file") }}
              </v-btn>
              <input
                hidden
                class="d-none"
                type="file"
                ref="thumbnail"
                :accept="extensions.image"
                @change="addThumbnail"
              />
              <v-btn
                v-if="
                  isContentEditor &&
                  activity.content != null &&
                  activity.thumbnail === null
                "
                class="ma-2"
                color="primary"
                :loading="loadingThumbnail"
                @click="chooseFile"
              >
                <v-icon>file_upload</v-icon>
                {{ $t("activity.actions.add_thumbnail") }}
              </v-btn>
              <v-btn
                v-if="
                  isContentEditor &&
                  activity.content != null &&
                  activity.thumbnail != null
                "
                class="ma-2"
                color="primary"
                :loading="loadingThumbnail"
                @click="chooseFile"
              >
                <v-icon>file_upload</v-icon>
                {{ $t("activity.actions.edit_thumbnail") }}
              </v-btn>
              <v-btn
                v-if="
                  isContentEditor &&
                  activity.content != null &&
                  activity.thumbnail != null
                "
                class="ma-2"
                color="error"
                :loading="loadingThumbnail"
                @click="removeThumbnail"
              >
                <v-icon>delete</v-icon>
                {{ $t("activity.actions.remove_thumbnail") }}
              </v-btn>
            </v-row>
            <v-row align="end" justify="end">
              <v-btn
                v-if="
                  !editingDetails &&
                  !activity.content &&
                  isContentEditor &&
                  activity.typeH5p === true &&
                  activity.resourceType === 'ACTIVITY'
                "
                @click="editH5P"
                color="warning"
                class="ma-2"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>
                {{ $t("activity.actions.create_h5p") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-n12">
        <v-row v-if="!editingDetails" align="center" justify="space-between">
          <v-col cols="12" v-if="!loadingTopics && topics">
            <div v-if="Object.keys(skill).length !== 0">
              <span class="text-h6"> {{ $t("activity.prop.skills") }}: </span>
              {{ topics.find((t) => t.id === skill.topicId).name }}
            </div>
            <div v-if="grammar.length !== 0">
              <span class="text-h6">
                {{ $t("activity.prop.grammar_topics") }}:
              </span>
              <span v-for="(topic, index) in grammar" :key="topic.id">
                {{ topics.find((t) => t.id === topic.topicId).name
                }}<span v-if="index !== grammar.length - 1">, </span>
              </span>
            </div>
            <div v-if="vocab.length !== 0">
              <span class="text-h6">
                {{ $t("activity.prop.vocab_topics") }}:
              </span>
              <span v-for="(topic, index) in vocab" :key="topic.id">
                {{ topics.find((t) => t.id === topic.topicId).name
                }}<span v-if="index !== vocab.length - 1">, </span>
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            v-if="!loadingWords && activity.activityWords.length !== 0"
          >
            <span class="text-h6"> {{ $t("activity.prop.words") }}: </span>
            <span v-for="word in activity.activityWords" :key="word.id">
              <v-chip color="primary" class="ma-2"
                >{{ words.find((w) => w.id === word.wordId).name }}
              </v-chip>
            </span>
          </v-col>
          <v-col cols="12" v-if="activity.teacherNotes && isContentEditor">
            <span class="text-h6">
              {{ $t("activity.prop.teacher-notes") }}:
            </span>
            <div style="white-space: pre-line">
              {{ activity.teacherNotes }}
            </div>
          </v-col>
          <v-col cols="12" v-if="activity.resourceType == 'LINK'">
            <span class="text-h6">
              {{ $t("activity.resource_type.LINK") }}: </span
            ><a :href="activity.link" target="_blank">{{ activity.link }}</a>
          </v-col>
          <v-col
            cols="12"
            v-if="
              activity.content &&
              activity.resourceType === 'ACTIVITY' &&
              activity.typeH5p === true
            "
          >
            <v-divider class="primary"></v-divider>
            <H5PPlayer
              :exercise="activity.content.fileName"
              @loaded="loadingContent = false"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="
              activity.content &&
              (activity.resourceType === 'ACTIVITY' ||
                activity.resourceType === 'DOCUMENT') &&
              activity.typeH5p === false
            "
          >
            <PDFViewer :exercise="activity.id" />
          </v-col>
          <v-col cols="12" v-if="loadingContent && activity.thumbnail != null">
            <v-row justify="center">
              <v-img :src="thumbnail" contain max-height="40em"></v-img>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            v-if="
              !loadingContent &&
              activity.content &&
              activity.resourceType === 'IMAGE'
            "
          >
            <v-divider class="primary mb-2"></v-divider>
            <v-img :src="content" contain></v-img>
          </v-col>
          <v-col
            cols="12"
            v-if="
              !loadingContent &&
              activity.content &&
              activity.resourceType === 'VIDEO'
            "
            align-self="center"
            class="mt-n4"
          >
            <video-player v-if="playerSrc" :src="playerSrc" />
          </v-col>
          <v-col
            cols="12"
            v-if="
              !loadingContent &&
              activity.content &&
              activity.resourceType === 'AUDIO'
            "
            align-self="center"
            class="mt-n4"
          >
            <audio-player v-if="playerSrc" :src="playerSrc" />
          </v-col>
        </v-row>
        <!-- Activity Details Form -->
        <v-row v-else no-gutters>
          <activity-form
            :simplified="true"
            :entity="activity"
            @saved="saveActivityDetails"
            @cancelEdit="cancelActivityEdit"
          ></activity-form>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="changeDeleteDialog"
      @submit="deleteActivity"
    ></delete-dialog>
  </v-container>
  <loading-page v-else></loading-page>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import extensions from "@/common/file-extensions";
import { downloadFile, uploadImage } from "@/common/file-utils";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import H5PPlayer from "./H5PPlayer.vue";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";
import AudioPlayer from "@/components/audio-player/AudioPlayer";
import VideoPlayer from "@/components/video-player/VideoPlayer";
import ActivityForm from "../_components/ActivityForm";
import checkInvalidID from "@/common/checkInvalidID";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LoadingPage from "@/components/loading-page/LoadingPage";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");

export default {
  name: "ActivityDetail",
  components: {
    LoadingPage,
    H5PPlayer,
    ActivityForm,
    DeleteDialog,
    PDFViewer,
    AudioPlayer,
    VideoPlayer,
  },
  data() {
    return {
      loading: false,
      loadingThumbnail: false,
      deleteLoading: false,
      editingDetails: false,
      loadingTopics: false,
      loadingWords: false,
      loadingContent: true,
      deleteDialog: false,
      activity: null,
      skill: {},
      file: {
        loading: false,
      },
      content: {
        loading: false,
      },
      thumbnail: {},
      playerSrc: {},
      grammar: [],
      topics: [],
      words: [],
      vocab: [],
      extensions,
    };
  },
  created() {
    this.fetchTopics();
    if (this.$route.params.id) {
      this._fetchData(this.$route.params.id);
    }
    this.fetchWords();
  },
  computed: {
    ...mapAuthGetter(["isContentEditor"]),
  },
  methods: {
    async _fetchData(id) {
      this.loading = true;
      return ActivityEntityRepository.get(id)
        .then((res) => {
          this.activity = res;
          if (this.isContentEditor) {
            const lastActivity = JSON.stringify({
              id: res.id,
              name: res.title,
            });
            localStorage.setItem("lastActivity", lastActivity);
          }
          if (res.thumbnail != null) {
            this.downloadThumbnail();
          }
          this.divideTopics();
          if (res.resourceType === "IMAGE" && res.content) {
            this.downloadFile(res);
          }
          if (
            ["VIDEO", "AUDIO"].indexOf(res.resourceType) !== -1 &&
            res.content
          ) {
            this.playerSrc = ActivityEntityRepository.getActivityContentUrl(
              res.id
            );
            this.loadingContent = false;
          }
        })
        .catch((err) => {
          this.$log.debug("Error fetching activity with id " + id);
          checkInvalidID(err);
        })
        .finally(() => (this.loading = false));
    },
    fetchTopics() {
      this.loadingTopics = true;
      TopicEntityRepository.getAll()
        .then((res) => (this.topics = res.content))
        .finally(() => (this.loadingTopics = false));
    },
    fetchWords() {
      this.loadingWords = true;
      WordEntityRepository.getAll()
        .then((res) => (this.words = res.content || []))
        .finally(() => (this.loadingWords = false));
    },
    async downloadFile(activity) {
      this.loadingContent = true;
      await ActivityEntityRepository.downloadActivityFile(activity.id)
        .then((response) => {
          const blob = new Blob([response.data]);
          this.content = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching file from activity with ID " + this.activity.id
          )
        )
        .finally(() => (this.loadingContent = false));
    },
    async downloadThumbnail() {
      await ActivityEntityRepository.downloadActivityThumbnail(this.activity.id)
        .then((res) => {
          const blob = new Blob([res.data]);
          this.thumbnail = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching thumbnail photo for activity with ID " +
              this.activity.id
          )
        );
    },
    saveActivityDetails(activity) {
      Object.assign(this.activity, activity);
      this._fetchData(this.activity.id);
      this.editingDetails = false;
    },
    cancelActivityEdit() {
      this.editingDetails = false;
    },
    async deleteH5p() {
      const result = await fetch("/h5p/" + this.activity.content.fileName, {
        method: "delete",
      });
      if (!result.ok) {
        throw new Error(
          "Error while deleting content: " + result.status,
          result.statusText,
          await result.text()
        );
      }
    },
    divideTopics() {
      this.grammar = [];
      this.vocab = [];
      if (this.activity && this.activity.activityTopics.length !== 0) {
        this.activity.activityTopics.forEach((topic) => this.topicType(topic));
      }
    },
    topicType(topic) {
      if (this.topics) {
        const match = this.topics.find((t) => t.id === topic.topicId);
        if (match) {
          if (match.topicType.name === "SKILL") {
            this.skill = topic;
          } else if (match.topicType.name === "GRAMMAR") {
            this.grammar.push(topic);
          } else if (match.topicType.name === "VOCABULARY") {
            this.vocab.push(topic);
          }
        }
      }
    },
    deleteActivity() {
      this.deleteLoading = true;
      ActivityEntityRepository.delete(this.activity.id)
        .then(() => {
          if (this.activity.typeH5p && this.activity.content) {
            this.deleteH5p();
          }
          this.changeDeleteDialog();
          this.back();
        })
        .catch(() =>
          this.$log.debug("Error deleting activity " + this.activity.id)
        )
        .finally(() => (this.deleteLoading = false));
    },
    changeDeleteDialog(value = false) {
      this.deleteDialog = value;
    },
    editH5P() {
      this.$router.push({
        name: "H5PEditor",
        params: {
          id: this.activity.id,
          backPrevious: true,
        },
      });
    },
    downloadActivityFile() {
      return ActivityEntityRepository.downloadActivityFile(this.activity.id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("activity.messages.downloading_file"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("activity.messages.file_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("activity.messages.not_downloading_file"),
              type: "error",
              duration: 30000,
            });
          }
        });
    },
    chooseFile() {
      this.$refs.thumbnail.click();
    },
    updateThumbnail(activity) {
      ActivityEntityRepository.saveContent({
        id: activity.id,
        thumbnail: activity.thumbnail,
      })
        .then((res) => {
          this.activity.thumbnail = res.thumbnail;
          this.$notify({
            title: this.$t("activityInteraction.messages.save_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
        })
        .catch(() => this.$log.debug("Error updating thumbnail"))
        .finally(() => (this.loadingThumbnail = false));
    },
    addThumbnail(htmlElement) {
      this.loadingThumbnail = true;
      uploadImage(this.activity, "thumbnail", this.file, htmlElement).then(() =>
        this.updateThumbnail(this.activity)
      );
    },
    removeThumbnail() {
      this.loadingThumbnail = true;
      this.updateThumbnail({ ...this.activity, thumbnail: null });
    },
    back() {
      const route =
        this.activity.resourceType === "LINK"
          ? "MediaLibrary"
          : "Activity List";
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-row:hover .action-buttons {
  visibility: visible !important;
  opacity: 1;
}

.activity-row:focus .action-buttons {
  visibility: visible !important;
  opacity: 1;
}

.action-buttons {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in, opacity 0.2s linear;
}
</style>
