<template>
  <v-dialog :value="dialog" width="80%" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("product.headers.tariff_form") }}
        </span>
      </v-card-title>
      <v-divider class="mb-6"></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-row justify="space-around" no-gutters>
            <v-col cols="12" md="5">
              <v-text-field
                outlined
                v-model="myTariff.minStudents"
                class="required"
                type="Number"
                :rules="[
                  (v) => !!v || $t('product.error.required'),
                  (v) =>
                    !v ||
                    !outOfTariffRange(v) ||
                    $t('product.error.tariff_range_error'),
                  (v) =>
                    !v ||
                    maxGreaterThanMin(v) ||
                    $t('product.error.min_lower_max'),
                  (v) =>
                    !v ||
                    higherThanGlobalMin(v) ||
                    $t('product.error.min_students_higher_global', {
                      value: this.product.minStudents,
                    }),
                  (v) =>
                    !v ||
                    higherThanGlobalMax(v) ||
                    $t('product.error.min_students_higher_global', {
                      value: this.product.maxStudents,
                    }),
                ]"
                :label="$t('product.prop.min_students')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                outlined
                v-model="myTariff.maxStudents"
                class="required"
                type="Number"
                :rules="[
                  (v) => !!v || $t('product.error.required'),
                  (v) =>
                    !v ||
                    !outOfTariffRange(v) ||
                    $t('product.error.tariff_range_error'),
                  (v) =>
                    !v ||
                    maxGreaterThanMin(v) ||
                    $t('product.error.max_higher_min'),
                  (v) =>
                    !v ||
                    lowerThanGlobalMax(v) ||
                    $t('product.error.max_students_higher_global', {
                      value: this.product.maxStudents,
                    }),
                  (v) =>
                    !v ||
                    higherThanGlobalMin(v) ||
                    $t('product.error.min_students_lower_global', {
                      value: this.product.minStudents,
                    }),
                ]"
                :label="$t('product.prop.max_students')"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="5">
              <payment-type-selector
                outlined
                disabled
                v-model="myTariff.paymentType"
                :label="$t('product.payment_type.label')"
              >
              </payment-type-selector>
            </v-col>

            <v-col cols="12" md="5">
              <number-field
                type="Double"
                outlined
                class="required"
                :dense="false"
                v-model="myTariff.price"
                :label="$t('product.prop.price')"
                :rules="[(v) => !!v || $t('product.error.required')]"
              ></number-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('cancel')">
          <v-icon class="mr-2">close</v-icon>
          {{ $t("actions.cancel") }}</v-btn
        >
        <v-btn
          :disabled="!validForm"
          color="success"
          @click="$emit('save', myTariff)"
        >
          <v-icon class="mr-2">save</v-icon>
          {{ $t("actions.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumberField from "@/components/number-field/NumberField.vue";
import PaymentTypeSelector from "@/components/selectors/PaymentTypeSelector";

export default {
  name: "TariffFormDialog",
  components: {
    NumberField,
    PaymentTypeSelector,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    tariff: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    Object.assign(this.myTariff, this.tariff);
    this.myTariff.paymentType =
      this.product.productType === "COURSE" ? "PERIODIC" : "PER_CLASS";

    if (this.tariff) {
      this.localMin = this.tariff.minStudents;
      this.localMax = this.tariff.maxStudents;
    }
  },
  data() {
    return {
      myTariff: {},
      validForm: true,
      localMin: null,
      localMax: null,
    };
  },
  methods: {
    outOfTariffRange(v) {
      let res = false;
      let num = parseInt(v);
      // Deleting from calculation tariff itself
      // only if editing a previous created one
      let localTariff = JSON.parse(JSON.stringify(this.product.tariffs));
      if (this.tariff) {
        let deletionIndex = localTariff.findIndex((el) => {
          return (
            el.minStudents == this.localMin && el.maxStudents == this.localMax
          );
        });
        localTariff.splice(deletionIndex, 1);
      }

      // Check if is in range of other tariffs
      for (let index = 0; index < localTariff.length; index++) {
        const element = localTariff[index];
        if (
          num >= parseInt(element.minStudents) &&
          num <= parseInt(element.maxStudents)
        ) {
          res = true;
          break;
        }
      }
      return res;
    },
    // Rules for tariff min and max numbers
    maxGreaterThanMin(v) {
      return !v || (this.myTariff.minStudents && this.myTariff.maxStudents)
        ? parseInt(this.myTariff.maxStudents ? this.myTariff.maxStudents : 0) >=
            parseInt(this.myTariff.minStudents ? this.myTariff.minStudents : 0)
        : true;
    },
    lowerThanGlobalMax(v) {
      return (
        !v ||
        !this.product.maxStudents ||
        parseInt(v) <= this.product.maxStudents
      );
    },
    higherThanGlobalMin(v) {
      return (
        !v ||
        !this.product.minStudents ||
        parseInt(v) >= parseInt(this.product.minStudents)
      );
    },
    higherThanGlobalMax(v) {
      return (
        !v ||
        !this.product.maxStudents ||
        parseInt(v) <= parseInt(this.product.maxStudents)
      );
    },
  },
};
</script>

<style></style>
