<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
      <v-col cols="12">
        <v-row class="mt-2">
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(levelTestCount)"
              :content="levelTestCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Level Test Request List')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> mdi-ab-testing </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_level_tests") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(registrationRequestCount)"
              :content="registrationRequestCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Admin Product Request List')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-account-plus
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_registrations") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(lectureRatingsCount)"
              :content="lectureRatingsCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Admin Lecture Evaluations')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> mdi-star </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_lecture_ratings") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(studentCandidateCount)"
              :content="studentCandidateCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Candidate students list')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-account-group
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_candidate_students") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(teacherCandidateCount)"
              :content="teacherCandidateCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Candidate teachers list')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-human-male-board
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_candidate_teachers") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(teacherLevelCount)"
              :content="teacherLevelCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Pending teacher levels')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> mdi-certificate </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.pending_teacher_levels") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(teacherReferenceCount)"
              :content="teacherReferenceCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('Preliminary teacher references')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> mark_email_read </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.preliminary_teacher_references") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(editionsUnderStudentsCount)"
              :content="editionsUnderStudentsCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="
                    navigate('Admin Edition List', {
                      underMinimumStudents: 'true',
                    })
                  "
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-account-arrow-down
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("admin.dashboard.editions_under_students") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
        </v-row>
        <v-row class="mt-2" justify="center">
          <v-col cols="12" md="6" class="text-center px-0">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(teacherDiplomasCount)"
              :content="teacherDiplomasCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('PendingDiplomasList')"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> history_edu </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("teacher.headers.pending-diplomas") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
          <v-col cols="12" md="6" class="text-center px-0">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(usersWithoutBillingIdCount)"
              :content="usersWithoutBillingIdCount"
              overlap
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="100%"
                  :elevation="hover ? 12 : 2"
                  @click="navigate('UsersWithoutBillingIdList')"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> history_edu </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("wallet.headers.users-without-billing-id") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");
export default {
  name: "AdminDashboard",
  data() {
    return {
      levelTestCount: 0,
      registrationRequestCount: 0,
      lectureRatingsCount: 0,
      studentCandidateCount: 0,
      teacherCandidateCount: 0,
      teacherLevelCount: 0,
      teacherReferenceCount: 0,
      editionsUnderStudentsCount: 0,
      teacherDiplomasCount: 0,
      usersWithoutBillingIdCount: 0,
      loading: false,
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      return DashboardRepository.getForAdmin()
        .then((data) => {
          this.levelTestCount = data.levelTestCount;
          this.registrationRequestCount = data.registrationRequestCount;
          this.lectureRatingsCount = data.lectureRatingsCount;
          this.studentCandidateCount = data.studentCandidateCount;
          this.teacherCandidateCount = data.teacherCandidateCount;
          this.teacherLevelCount = data.teacherLevelCount;
          this.teacherReferenceCount = data.teacherReferenceCount;
          this.editionsUnderStudentsCount = data.editionsUnderStudentsCount;
          this.teacherDiplomasCount = data.teacherDiplomasCount;
          this.usersWithoutBillingIdCount = data.usersWithoutBillingIdCount;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    getBadgeColor(count) {
      return count > 0 ? "red" : "green";
    },
    navigate(routeName, query = {}) {
      this.$router.push({ name: routeName, query: query });
    },
  },
};
</script>

<style scoped>
.dashboard-card {
  width: 90%;
  height: 100%;
}
</style>
