// Admin profile access
const navBarItemsAdmin = [
  {
    title: "admin.navbar.profile",
    icon: "mdi-account",
    link: "AdminView Detail",
    notification: false,
    showed: true,
  },
];

const navBarItemsProfile = [
  {
    title: "admin.navbar.profile",
    icon: "mdi-account",
    link: "ProfileAdminView Detail",
    notification: false,
    showed: true,
  },
];

export { navBarItemsAdmin, navBarItemsProfile };
