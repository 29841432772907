<template>
  <div class="timeZone-selector">
    <v-col v-show="inputVal" style="padding: 0">
      <v-autocomplete
        ref="mySelect"
        v-model="inputVal"
        :dense="dense"
        :outlined="outlined"
        :items="timeZones"
        :loading="timeZonesLoading"
        :search-input="searchInput"
        @update:search-input="updateSearchInput"
        @change="(val) => $emit('change', val)"
        :no-filter="noFilter"
        :no-data-text="noDataText"
        :item-text="formatTimeZoneLabel"
        :item-value="itemValue"
        return-object
        :label="label"
        :solo="solo"
        :rules="rules"
        :autocomplete="autocomplete"
      >
      </v-autocomplete>
    </v-col>
  </div>
</template>
<script>
var timerId = null;

import { getDefaultTimeZone, getOffset } from "@/common/timeZoneUtils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TimeZoneEntityRepository = RepositoryFactory.get(
  "TimeZoneEntityRepository"
);

export default {
  name: "TimeZoneSelector",
  props: {
    debouncing: {
      type: Number,
      required: false,
      default: 0,
    },
    autocomplete: {
      type: String,
      required: false,
      default: "on",
    },
    abreviate: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    "search-input": {
      required: false,
      default: undefined,
    },
    "item-value": {
      type: [String, Array, Function],
      required: false,
      default: "value",
    },
    "return-object": {
      type: Boolean,
      required: false,
      default: false,
    },
    "no-data-text": {
      type: String,
      required: false,
      default: "$vuetify.noDataText",
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    "no-filter": {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    solo: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {},
  },
  data() {
    return {
      timeZonesLoading: false,
      timeZones: [],
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.fetchTimeZoneItems();
  },
  methods: {
    autoDetect() {
      if (!this.inputVal) {
        this.inputVal = this.timeZones.find(
          (t) => t.name === getDefaultTimeZone()
        );
      }
    },
    fetchTimeZoneItems() {
      this.timeZonesLoading = true;
      TimeZoneEntityRepository.getAll()
        .then((data) => {
          this.timeZones = data;
          this.autoDetect();
        })
        .catch(() => this.$log.debug("Error fetching timezones"))
        .finally(() => (this.timeZonesLoading = false));
    },
    formatTimeZoneLabel(item) {
      return item.name
        ? this.getOffset(item.name) + " " + this.$t(`timezones.${item.name}`)
        : "";
    },
    getOffset: getOffset,
    updateSearchInput(val) {
      // cancel pending call
      clearTimeout(timerId);

      // delay new call 'this.debouncing'ms
      timerId = setTimeout(() => {
        this.$emit("update:search-input", val);
      }, this.debouncing);
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid rgba(128, 128, 128, 0.801);
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
.custom-border span {
  margin-left: 5%;
}
</style>
