<template>
  <v-menu
    ref="menuPicker"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        :class="required ? 'required' : undefined"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :label="label"
        :outlined="outlined"
        :rules="rules"
        :value="dateFormatted"
        @click:clear="clear"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="inputVal"
      :color="calendarColor"
      :max="maxDate"
      :min="minimumDate ? minimumDate : minDate"
      :locale="localeRoot"
      :first-day-of-week="$t('datePicker.firstDayOfTheWeek')"
      @change="valueChanged"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { localDateToISOString } from "@/common/conversion-utils";
import i18n from "@/plugins/i18n";

const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export default {
  props: {
    calendarColor: {
      type: String,
      default: null,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    futureDates: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    minimumDate: {
      type: String,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    pastDates: {
      type: Boolean,
      required: false,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
      maxDate: null,
      minDate: null,
      innerValue: null,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value
          ? localDateToISOString(this.value).substr(0, 10)
          : null;
      },
      set(val) {
        this.$refs.menuPicker.save(val);
        this.$emit(
          "input",
          val?.split("-").map((v) => parseInt(v))
        );
      },
    },
    dateFormatted() {
      return this.innerValue && this.$d(new Date(this.innerValue), "short");
    },
    localeRoot() {
      return i18n.locale;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    if (this.value) {
      this.innerValue = this.value;
    }

    const today = new Date()
      .toLocaleDateString(undefined, options)
      .split("/")
      .reverse()
      .join("-");

    if (!this.pastDates) {
      this.minDate = today;
    }

    if (!this.futureDates) {
      this.maxDate = today;
    }
  },
  methods: {
    /**
     * This method saves the newValue into "innerValue" to make dateFormatted reactive.
     * After that, emits an event to change the value on the parent's component
     * @param newValue: new date selected by the user in "yyyy-mm-dd" format
     */
    valueChanged(newValue) {
      this.innerValue = newValue;
      this.$emit("change", newValue);
    },
    clear() {
      this.inputVal = null;
      this.innerValue = null;
      this.$refs.menuPicker.save(null);
      this.$emit("change", null);
      this.$emit("input", null);
    },
  },
};
</script>
