<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between">
        <v-col class="mx-4 d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t("studentFinalEvaluation.headers.form") }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="back" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="!validEvaluation"
            class="success mr-2"
            @click="save"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-form v-model="validForm">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" :sm="'12'" class="text-center">
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                  v-model="finalEvaluation.score"
                  :label="$t('studentFinalEvaluation.prop.score')"
                  :rules="scoreRules"
                  type="number"
                  min="1"
                  max="10"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="finalEvaluation.passed"
                  class="required"
                  :items="passedValues"
                  :label="$t('studentFinalEvaluation.prop.passed.title')"
                  :rules="[
                    (v) =>
                      v === true ||
                      v === false ||
                      $t('studentFinalEvaluation.error.required'),
                  ]"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="finalEvaluation.comments"
                  :label="$t('studentFinalEvaluation.prop.comments')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const StudentFinalEvaluationEntityRepository = RepositoryFactory.get(
  "StudentFinalEvaluationEntityRepository"
);

export default {
  name: "StudentFinalEvaluationForm",
  props: {
    studentId: {
      required: true,
    },
    editionId: {
      required: true,
    },
    evaluationId: {
      required: false,
    },
  },
  data() {
    return {
      finalEvaluation: {},
      loading: false,
      validForm: true,
    };
  },
  computed: {
    validEvaluation() {
      return this.validForm;
    },
    passedValues() {
      return [
        {
          text: this.$t("studentFinalEvaluation.prop.passed.passed"),
          value: true,
        },
        {
          text: this.$t("studentFinalEvaluation.prop.passed.not-passed"),
          value: false,
        },
      ];
    },
    scoreRules() {
      return [
        (v) =>
          !v ||
          (v && v > 0) ||
          this.$t("studentFinalEvaluation.error.min-score"),
        (v) =>
          !v ||
          (v && v < 11) ||
          this.$t("studentFinalEvaluation.error.max-score"),
      ];
    },
  },
  created() {
    if (this.evaluationId) {
      this._fetchFinalEvaluation();
    }
  },
  methods: {
    _fetchFinalEvaluation() {
      StudentFinalEvaluationEntityRepository.get(this.evaluationId)
        .then((res) => (this.finalEvaluation = res))
        .catch(() => this.$log.debug("Error fetching final evaluation"));
    },
    back() {
      this.$emit("cancel");
    },
    save() {
      this.loading = true;
      this.finalEvaluation.studentId = parseInt(this.studentId);
      this.finalEvaluation.editionId = parseInt(this.editionId);
      StudentFinalEvaluationEntityRepository.save(this.finalEvaluation)
        .then((res) => {
          this.$notify({
            title: this.$t("studentFinalEvaluation.messages.saved_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", res);
        })
        .catch(() =>
          this.$log.debug(
            "Error saving/updating final evaluation for student with id " +
              this.studentId
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
