<template>
  <v-card flat>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="$emit('back')">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn :disabled="!validForm" @click="save" class="success ml-2">
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              append-icon="title"
              v-model="product.title"
              class="required"
              :label="$t('product.prop.title')"
              :rules="[(v) => !!v || $t('product.error.required')]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-textarea
              outlined
              append-icon="description"
              rows="2"
              v-model="product.description"
              :label="$t('product.prop.description')"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="6">
            <product-status-selector
              outlined
              v-model="product.productStatus"
              :label="$t('product.product_status.label')"
            ></product-status-selector>
          </v-col>

          <v-col cols="12" md="6">
            <level-selector
              v-model="product.level"
              append_icon="school"
              class="required"
              clearable
              outlined
              :label="$t('product.prop.level')"
              :rules="[(v) => !!v || $t('product.error.required')]"
              @input="handleCoursesFilter"
            ></level-selector>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              append-icon="date_range"
              class="required"
              v-model="product.classesPerWeek"
              type="Number"
              :label="$t('product.prop.classes_per_week')"
              :rules="[
                (v) => !!v || $t('product.error.required'),
                (v) =>
                  (v && v > 0) || $t('product.error.value.min', { value: 1 }),
                (v) =>
                  (v && v <= 7) || $t('product.error.value.max', { value: 7 }),
              ]"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <class-duration-selector
              outlined
              append_icon="timer"
              class="required"
              v-model="product.classDuration"
              :label="$t('product.prop.class_duration')"
              :rules="[
                (v) => !!v || $t('product.error.required'),
                (v) =>
                  (v && v > 0) || $t('product.error.value.min', { value: v }),
              ]"
            >
            </class-duration-selector>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <product-type-selector
              outlined
              append_icon="category"
              class="required"
              v-model="product.productType"
              :label="$t('product.product_type.label')"
              @change="updateProductType"
              :rules="[
                (v) => (!!v && v.length != 0) || $t('product.error.required'),
              ]"
              required
            >
              <template v-slot:append="{ on, attrs }">
                <span v-if="showAlert" v-bind="attrs" v-on="on">
                  <v-tooltip top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="error"
                        >mdi-alert-octagon</v-icon
                      >
                    </template>

                    <span class="text-h6">
                      <v-icon color="white">mdi-alert-octagon-outline</v-icon>
                      {{ $t("product.error.tariff_match_product_type") }}
                    </span>
                  </v-tooltip>
                </span>
              </template>
            </product-type-selector>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              outlined
              append_icon="category"
              class="required"
              v-model="product.assistanceType"
              :items="assistanceTypes"
              :label="$t('product.assistance_type.label')"
              :item-text="translate"
              item-value="value"
              :rules="[
                (v) => (!!v && v.length != 0) || $t('product.error.required'),
              ]"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              outlined
              append_icon="category"
              class="required"
              v-model="product.recipientType"
              :items="recipientTypes"
              :label="$t('product.recipient_type.label')"
              :item-text="translate"
              item-value="value"
              @change="onRecipientTypeChange"
              :rules="[
                (v) => (!!v && v.length !== 0) || $t('product.error.required'),
              ]"
              required
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <course-selector
              outlined
              clearable
              append_icon="class"
              v-model="product.course"
              :class="product.productType === 'COURSE' ? 'required' : ''"
              :label="$t('product.prop.course')"
              :rules="[courseSelectorRules]"
              :filters="coursesFilter"
            ></course-selector>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="product.minStudents"
              type="Number"
              @change="updateTariffRanges"
              :label="$t('product.prop.min_students')"
              disabled
              :rules="[(v) => !!v || $t('product.error.required')]"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="product.maxStudents"
              type="Number"
              @change="updateTariffRanges"
              disabled
              :rules="[(v) => !!v || $t('product.error.required')]"
              :label="$t('product.prop.max_students')"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="product.productType === 'COURSE'">
            <day-picker
              v-model="product.startDate"
              :label="$t('product.prop.start_date')"
              :past-dates="false"
              :rules="startDateRules"
              @change="updateDatesErrors"
              clearable
              future-dates
              outlined
              required
            ></day-picker>
          </v-col>

          <v-col cols="12" md="6" v-if="product.productType === 'COURSE'">
            <day-picker
              v-model="product.endDate"
              :label="$t('product.prop.end_date')"
              :minimum-date="minEndDate"
              :rules="endDateRules"
              @change="updateDatesErrors"
              required
              clearable
              future-dates
              outlined
            ></day-picker>
          </v-col>
        </v-row>

        <v-divider class="mb-4" color="secondary"></v-divider>

        <v-row>
          <v-col cols="12" md="12">
            <tariff-list-create-form
              :product="product"
              v-model="product.tariffs"
              :showError="showTariffError"
            ></tariff-list-create-form>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import ProductStatusSelector from "@/components/selectors/ProductStatusSelector";
import ProductTypeSelector from "@/components/selectors/ProductTypeSelector";
import LevelSelector from "@/components/selectors/LevelSelector";
import CourseSelector from "@/components/selectors/CourseSelector";
import ClassDurationSelector from "@/components/selectors/ClassDurationSelector";
import TariffListCreateForm from "./_components/TariffListCreateForm";
import assistanceTypes from "@/enumerates/AssistanceType";
import recipientTypes from "@/enumerates/RecipientType";
import { translate } from "@/common/translation-utils";
import { dateToDateString } from "@/common/conversion-utils";

const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export default {
  components: {
    ProductStatusSelector,
    ProductTypeSelector,
    LevelSelector,
    CourseSelector,
    DayPicker,
    ClassDurationSelector,
    TariffListCreateForm,
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      product: { tariffs: [], course: {} },
      validForm: true,
      showTariffError: null,
      courseSelectorRules: true,
      initProductStatus: null,
      startDateRules: [],
      endDateRules: [],
      coursesFilter: {},
      minEndDate: null,
      assistanceTypes,
      recipientTypes,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.product = newValue;
      },
      immediate: true,
    },
    product: {
      handler(val) {
        // If a productType course is selected
        if (val && val.productType && val.productType !== "COURSE") {
          this.product.startDate = null;
          this.product.endDate = null;
          val.startDate = null;
          val.endDate = null;
          this.$refs.form.resetValidation();
        }
        this.showTariffError = null;
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  computed: {
    showAlert() {
      return !this.checkTariffsFullRange();
    },
  },
  created() {
    if (this.value) {
      this.initProductStatus = this.value.productStatus;
    }
    if (this.value) {
      Object.assign(this.product, this.value);
    }
    if (!this.product.tariffs) {
      this.product.tariffs = [];
    }
    this.updateDatesErrors();
  },
  methods: {
    save() {
      this.$refs.form.validate();
      if (this.validForm) {
        if (!this.checkTariffsEmpty()) {
          this.showTariffError = "product.error.tariffs_not_empty";
        } else if (!this.checkTariffs()) {
          this.showTariffError = "product.error.tariff_fields_not_empty";
        } else if (!this.checkTariffsFullRange()) {
          this.showTariffError = "product.error.tariff_cover_all_range";
        } else if (
          this.initProductStatus &&
          this.initProductStatus !== "DRAFT" &&
          this.product.productStatus === "DRAFT"
        ) {
          this.$notify({
            type: "error",
            text: this.$t("product.messages.update_error"),
            duration: 30000,
          });
        } else {
          this.$emit("save");
        }
      }
    },
    checkTariffsEmpty() {
      if (this.product.tariffs.length === 0) {
        return false;
      } else {
        let valid = false;
        this.product.tariffs.forEach((el) => {
          if (!el.markDelete) valid = true;
        });
        return valid;
      }
    },
    checkTariffs() {
      let valid = true;
      this.product.tariffs.forEach((el, index) => {
        if (!el.minStudents || !el.maxStudents) {
          el.isValid = false;
          this.$set(this.product.tariffs, index, el);
          valid = false;
        }
      });
      return valid;
    },
    checkTariffsFullRange() {
      let minSt = this.product.minStudents;
      let maxSt = this.product.maxStudents;
      if (!!minSt && !!maxSt) {
        let myArr = Array.from(
          {
            length: parseInt(maxSt) - parseInt(minSt) + 1,
          },
          (x, i) => i + parseInt(minSt)
        );
        this.product.tariffs.forEach((el) => {
          let start = myArr.findIndex(
            (num) => num === parseInt(el.minStudents)
          );
          if (start !== -1) {
            myArr.splice(
              start,
              parseInt(el.maxStudents) - parseInt(el.minStudents) + 1
            );
          }
        });
        return myArr.length === 0;
      } else {
        return true;
      }
    },
    updateProductType(productType) {
      this.$set(this.product, "productType", productType);
      // Change tariff types to match the product
      this.product.tariffs.forEach((tariff) => {
        tariff.paymentType =
          productType === "COURSE" ? "PERIODIC" : "PER_CLASS";
      });
      this.$set(this.product, "course", null);

      // Add /delete rules depending on productType
      if (productType === "COURSE") {
        this.courseSelectorRules = (v) =>
          !!v || this.$t("product.error.required");
      } else {
        this.courseSelectorRules = true;
        this.$set(this.product, "recipientType", "GENERAL");
      }

      // Change min/max students if adhoc selected
      if (productType === "ADHOC_ONE") {
        this.$set(this.product, "minStudents", 1);
        this.$set(this.product, "maxStudents", 1);
        this.updateTariffRanges();
      } else if (productType === "ADHOC_TWO") {
        this.$set(this.product, "minStudents", 2);
        this.$set(this.product, "maxStudents", 2);
        this.updateTariffRanges();
      } else {
        this.$set(this.product, "minStudents", 3);
        this.$set(this.product, "maxStudents", 5);
        this.updateTariffRanges();
      }
    },
    onRecipientTypeChange(recipientType) {
      if (recipientType === "COMPANY") {
        this.$set(this.product, "productType", "COURSE");
        this.$set(this.product, "minStudents", 3);
        this.$set(this.product, "maxStudents", 5);
        this.updateTariffRanges();
      }
    },
    updateTariffRanges() {
      this.product.tariffs.forEach((tariff, index) => {
        tariff.minStudents = null;
        tariff.maxStudents = null;
        this.$set(this.product.tariffs, index, tariff);
      });
    },
    /**
     * This function checks for date picker errors (startDate and endDate pickers)
     */
    updateDatesErrors() {
      if (this.product.startDate) {
        let newDate = new Date(this.product.startDate);
        if (
          this.product.course &&
          this.product.course.lessonNum &&
          this.product.classesPerWeek
        ) {
          const day =
            newDate.getDate() +
            7 *
              Math.ceil(
                this.product.course.lessonNum / this.product.classesPerWeek
              );
          newDate.setUTCDate(day);
        }
        this.minEndDate = dateToDateString(newDate);
      } else {
        this.minEndDate = dateToDateString(new Date());
      }
      if (this.product.startDate && this.product.endDate) {
        if (!this._compareDates(this.product.endDate, this.product.startDate)) {
          this.startDateRules = [this.$t("product.error.date_start_error")];
          this.endDateRules = [this.$t("product.error.date_end_error")];
        } else {
          this.startDateRules = [];
          if (
            !this._compareDates(
              this.product.endDate,
              this.minEndDate.split("-").map((el) => parseInt(el))
            )
          ) {
            this.endDateRules = [this.$t("product.error.date_end_early")];
          } else {
            this.endDateRules = [];
          }
        }
      } else {
        this.startDateRules = [(v) => !!v || this.$t("product.error.required")];
        this.endDateRules = [(v) => !!v || this.$t("product.error.required")];
      }
    },
    handleCoursesFilter() {
      this.coursesFilter = this.product.level
        ? { langId: this.product.level.language.id }
        : {};
    },
    _compareDates(dateArray1, dateArray2) {
      for (let index = 0; index < dateArray1.length; index++) {
        const el = dateArray1[index];
        if (el !== dateArray2[index]) {
          return el > dateArray2[index];
        }
      }
      return true;
    },
    translate,
  },
};
</script>

<style></style>
