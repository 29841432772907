<template>
  <div>
    <v-data-table
      class="elevation-1"
      :footer-props="tableFooterProps"
      :headers="headers"
      :items="files"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.type`]="{ item }">
        <span>
          {{ $t(`userFile.type.${item.type}`) }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top v-if="!loadingRow[item.id]">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              @click.stop="downloadFile(item)"
              v-bind="attrs"
              v-on="on"
            >
              download
            </v-icon>
          </template>
          <span>{{ $t("actions.download") }}</span>
        </v-tooltip>
        <loading-spinner v-else></loading-spinner>
      </template>
    </v-data-table>
    <a ref="hiddenDownloader" class="d-none" />
  </div>
</template>

<script>
import { downloadFile } from "@/common/file-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const UserFileEntityRepository = RepositoryFactory.get(
  "UserFileEntityRepository"
);
export default {
  name: "FileList",
  components: { LoadingSpinner },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      loading: false,
      loadingRow: [],
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("userFile.prop.type"),
          value: "type",
        },
        {
          text: this.$t("userFile.prop.description"),
          value: "description",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.onPaginationChange();
      },
      deep: true,
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          id: this.userId,
        },
      };
      UserFileEntityRepository.getAll(options)
        .then((data) => {
          this.files = data.content;
          this.totalItems = data.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching user file list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this._fetchData();
      }
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
    downloadFile(item) {
      this.$set(this.loadingRow, item.id, true);
      UserFileEntityRepository.downloadFile(item.id)
        .then((response) => downloadFile(response, this.$refs.hiddenDownloader))
        .catch(() =>
          this.$log.debug("Error downloading file with ID " + item.id)
        )
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
  },
};
</script>
