var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.fields.timeZone"))+" : "+_vm._s(_vm.$t(("timezones." + (_vm.schedule.timeZone.name)))))])])],1),_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" "+_vm._s(_vm.$t("scheduleCalendar.today"))+" ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[(_vm.$refs.calendar)?_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title))]):_vm._e()]),_c('v-col',{attrs:{"cols":"6","md":"5"}},[_c('v-select',{attrs:{"items":_vm.availableFilters,"item-text":function (item) { return this$1.$t(("scheduleCalendar.filtersAvailable." + (item.name))); },"return-object":"","text":"","multiple":"","dense":"","clearable":"","outlined":"","label":_vm.$t('scheduleCalendar.filters')},on:{"change":function($event){return _vm.formatEvents()}},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1),_c('v-col',{attrs:{"cols":"6","md":"1"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-1",attrs:{"outlined":""}},on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeView('day')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("day")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeView('week')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("week")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeView('month')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("month")))])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":_vm.height}},[_c('v-calendar',{ref:"calendar",staticClass:"small-calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type,"weekdays":_vm.weekdays,"locale":_vm.$i18n.locale},on:{"change":_vm.updateRange,"click:event":_vm.showEvent,"click:more":_vm.onDayClick,"click:date":_vm.onDayClick,"mouseenter:event":_vm.showDeleteIcon,"mouseleave:event":_vm.hideDeleteIcon},scopedSlots:_vm._u([{key:"event",fn:function(ref){
              var event = ref.event;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('div',{staticClass:"event-div"},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[(hover)?_c('div',{staticClass:"v-expand--transition d-flex",staticStyle:{"height":"100%"}},[(event.type == 'lecture')?_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',[_vm._v("search")])],1):_vm._e()],1):_vm._e(),_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(event.label || event.type)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(event.start.split("T")[1])+" / "+_vm._s(event.end.split("T")[1])+" ")]),_c('br')])],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }