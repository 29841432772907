const store = {
  state: {
    user: {
      id: null,
      roleId: null,
      authorities: [],
      login: "",
      logged: false,
      langKey: "",
      email: null,
      firstName: null,
      lastName: null,
      displayName: null,
      isAdult: false,
      timeZone: null,
      selectedAuthority: null,
      photo: null,
    },
    websocket: {
      connected: false,
    },
    chat: {
      notifications: 0,
      newMessage: null,
    },
  },
};

export default store;
