<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col order="1" order-md="2" class="text-right">
        <v-btn
          color="success ml-2"
          :to="{ name: 'Create Course', params: { backPrevious: true } }"
        >
          <v-icon>add</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" md="3" class="py-0">
        <debounced-text-field
          append-icon="search"
          class="d-md-inline-block"
          dense
          hide-details
          v-model="search"
          :label="$t('search')"
          @input="onSearchChange"
        ></debounced-text-field>
      </v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-checkbox
          :label="$t('course.headers.incomplete_courses')"
          v-model="checked"
          @change="onSearchChange"
          class="mx-3"
        />
      </v-col>

      <v-col cols="12" md="3">
        <language-selector
          v-model="languageFilter"
          :clearable="!levelFilter"
          dense
          outlined
          :debouncing="300"
          :label="$t('activity.prop.language')"
          show-chips
          @input="onSelectorChange('onLanguageChange', ...arguments)"
        ></language-selector>
      </v-col>

      <v-col cols="12" md="3">
        <level-selector
          v-model="levelFilter"
          clearable
          :debouncing="300"
          dense
          :label="$t('activity.prop.level')"
          :language-filter="languageFilter ? languageFilter.id : null"
          outlined
          :show-language="false"
          @input="onSelectorChange('onLevelChange', ...arguments)"
        ></level-selector>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1 rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :options="pagination"
          :server-items-length="totalItems"
          @click:row="entityDetail"
          @update:options="onPaginationChange"
        >
          <template v-slot:[`item.level.language.name`]="{ item }">
            <v-chip
              v-if="item.level.language"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ $t(`languages.${item.level.language.name}`) | uppercase }}
            </v-chip>
          </template>

          <template v-slot:[`item.level.name`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ item.level.name | uppercase }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click.stop="entityDetail(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    description
                  </v-icon>
                </template>
                <span>{{ $t("actions.detail") }}</span>
              </v-tooltip>
              <v-edit-dialog
                :cancel-text="$t('actions.cancel')"
                large
                persistent
                :save-text="$t('actions.save')"
                @save="duplicateCourse(item)"
              >
                <v-tooltip open-delay="400" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" color="primary">
                      content_copy
                    </v-icon>
                  </template>
                  <span>{{ $t("course.actions.duplicate") }}</span>
                </v-tooltip>
                <template v-slot:input>
                  <span class="my-8 text-h6">
                    {{ $t("course.actions.duplicate") }}
                  </span>
                  <v-container class="mt-2">
                    <v-text-field
                      v-model="duplicatedCourseName"
                      class="required"
                      dense
                      :label="$t('course.prop.title')"
                      outlined
                      :rules="[(v) => !!v || $t('course.error.required')]"
                    ></v-text-field>
                  </v-container>
                </template>
              </v-edit-dialog>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "CourseList",
  components: {
    DebouncedTextField,
    LevelSelector,
    LanguageSelector,
  },
  data() {
    return {
      items: [],
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      search: this.$route.query.search,
      checked: this.$route.query.incomplete === "true",
      duplicatedCourseName: null,
      tableFooterProps,
      levelFilter: null,
      languageFilter: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("course.prop.title"),
          value: "title",
        },
        {
          text: this.$t("course.prop.description"),
          value: "description",
        },
        {
          text: this.$t("level.prop.language"),
          value: "level.language.name",
        },
        {
          text: this.$t("course.prop.level"),
          sortable: false,
          value: "level.name",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    if (this.$route.query.levelFilter) {
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.languageFilter) {
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          search: this.search ? this.search : undefined,
          incomplete: this.checked,
          levelId: this.levelFilter ? this.levelFilter.id : undefined,
          langId: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      CourseEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching course list with params: " + options)
        )
        .finally(() => (this.isLoading = false));
    },

    duplicateCourse(course) {
      this.isLoading = true;
      const duplicateCourseObj = {
        id: course.id,
        name: this.duplicatedCourseName,
      };
      CourseEntityRepository.duplicate(duplicateCourseObj)
        .then((res) => this.entityDetail(res))
        .catch(() =>
          this.$log.debug("Error duplicating course with id " + course.id)
        )
        .finally(() => {
          this.duplicatedCourseName = null;
          this.isLoading = false;
        });
    },
    entityDetail(entity) {
      this.$router.push({
        name: "Course Detail",
        params: { id: entity.id, backPrevious: true },
      });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.search = this.search ? this.search : undefined;
      query.incomplete = this.checked?.toString();
      query.levelFilter = this.levelFilter?.id.toString();
      query.languageFilter =
        this.levelFilter == null
          ? this.languageFilter?.id?.toString()
          : undefined;
      this.redirect(query);
    },
    onSearchChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    onSelectorChange(op, value) {
      if (op === "onLevelChange" && value) {
        this.languageFilter = value.language;
      }
      if (
        op === "onLanguageChange" &&
        this.levelFilter != null &&
        this.levelFilter.language.id === value?.id
      ) {
        return;
      }
      this.onSearchChange();
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
