var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center text-h2",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"8"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-12",attrs:{"elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('ProfileTutorView Balance')}}},[_c('v-card-title',[_c('v-col',{staticClass:"py-0 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-cash ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.walletBalance, "currency"))+" ")])],1)],1),_c('v-card-text',{staticClass:"py-0 pb-6"},[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("tutor.dashboard.wallet_balance"))+" ")])])],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[(_vm.loading)?_c('v-row',{staticClass:"mt-12",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._l((_vm.studentsInfo),function(student,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-6",attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.navigate('Tutor StudentBalanceTab', student.studentId)}}},[_c('v-card-title',[_c('v-col',{staticClass:"py-0 text-center"},[_c('span',[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(student.studentName)+" ")],1)])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("tutor.dashboard.wallet_balance"))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$n(student.walletBalance, "currency"))+" ")]),_c('br'),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.next_payment"))+": ")]),_c('span',{staticClass:"payment"},[_vm._v(" "+_vm._s("-")+" "+_vm._s(_vm.$n(student.nextPayment, "currency"))+" ")]),_c('span',[_vm._v(" ("+_vm._s(_vm._f("dateTimeWithTz")(_vm.lastDayOfMonth,"short"))+") ")])])],1)]}}],null,true)})],1)})],2)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.next_lectures"))+" "),(!_vm.expandNextLectures)?_c('v-icon',{on:{"click":function($event){_vm.expandNextLectures = true}}},[_vm._v(" expand_more ")]):_c('v-icon',{on:{"click":function($event){_vm.expandNextLectures = false}}},[_vm._v(" expand_less ")])],1),(_vm.loading)?_c('v-row',{staticClass:"mt-12",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-container',_vm._l((_vm.studentsInfo),function(student,i){return _c('v-row',{key:i},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandNextLectures)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((student.nextLectures),function(lecture){return _c('v-row',{key:lecture.id,staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:lecture.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"300px"},on:{"click":function($event){$event.stopPropagation();return _vm.navigate(
                            'Lecture Detail',
                            lecture.id,
                            student.studentId
                          )}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(lecture.productName)+" ")])])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_c('v-icon',[_vm._v("mdi-human-male-board")]),_vm._v(" "+_vm._s(lecture.teacherName)+" ")],1),_c('br'),_c('router-link',{attrs:{"to":{
                              name: 'Tutor StudentView Detail',
                              params: { id: student.studentId },
                            }}},[_c('span',{staticClass:"text-overline"},[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(student.studentName)+" ")],1)]),_c('br'),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(lecture.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(lecture.endTime,"time"))+" ")])],1)],1)]}}],null,true)})],1)}),(
                      !student.nextLectures ||
                      student.nextLectures.length === 0
                    )?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tutor.dashboard.no_next_lectures"))+" "+_vm._s(student.studentName))])]):_vm._e()],2):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }