import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/teachers";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllWithFullNameSearch(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/findWithFullNameSearch`,
      options
    );
    return response.data;
  },

  async getAllTeacherWithLectures() {
    return (await HTTP.get(`${RESOURCE_NAME}/getAllTeacherWithLectures`)).data;
  },

  async getAllWithLecturesNotPaid(options = {}) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/getTeachersWithLecturesNotPaid`, options)
    ).data;
  },

  async getLectureTeacherDetail(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/lecture`)).data;
  },

  async getByLanguageAndStudentAvailabilities(
    languageId,
    studentId,
    startDate,
    endDate
  ) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/findByLanguageAndStudent`,
      {
        params: {
          language: languageId,
          student: studentId,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async getAllOfStudent(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/selector`, options);
    return response.data;
  },

  async getCV(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/cv`)).data;
  },

  async downloadCVFile(id) {
    return HTTP.get(`${RESOURCE_NAME}/${id}/cv/download`, {
      responseType: "blob",
    });
  },

  async getSchedule(id, init, end) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/schedules`, {
        params: {
          init: init,
          end: end,
        },
      })
    ).data;
  },

  async getByUser() {
    return (await HTTP.get(`${RESOURCE_NAME}/getByUser`)).data;
  },

  async getAvailableTeachers(studentIds, productRequestId) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/getAvailableTeachers`, {
        params: {
          students: studentIds,
          productRequest: productRequestId,
        },
      })
    ).data;
  },

  async getAvailableTeachersForLecture(lectureId) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/getAvailableTeachersForLecture`, {
        params: {
          lecture: lectureId,
        },
      })
    ).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async saveCandidate(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}/candidate`, entity)).data;
  },

  async getSupervisedByHeadmaster(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/supervised`)).data;
  },
  async setHeadmasterRole(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/setHeadmaster`)).data;
  },

  async removeHeadmasterRole(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/removeHeadmaster`)).data;
  },

  async addSupervising(id, supervisedId) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/${id}/addSupervised/${supervisedId}`)
    ).data;
  },

  async deleteSupervising(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/deleteSupervised`)).data;
  },

  async deleteCV(id) {
    return (await HTTP.delete(`${RESOURCE_NAME}/${id}/cv`)).data;
  },

  async updateCV(id, cv) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/cv`, cv)).data;
  },

  async approve(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/accept`)).data;
  },
};
