<template>
  <div
    class="clrm-timer clrm-timer--border-radius rr-block"
    v-bind:class="{ blink: timerBlink }"
  >
    <img
      class="clrm-timer__icon"
      width="22"
      height="22"
      :src="require('@/assets/images/clock.svg')"
      alt=""
    />
    <div class="clrm-timer__time" :class="'text-length-' + lengthTextFormated">
      {{ minutesFormated }}:{{ secondsFormated }}
    </div>
  </div>
</template>

<script>
let intervalId = null;
let endingIntervalId = null;

export default {
  name: "ClrmTimer",
  data() {
    return {
      minutes: 0,
      seconds: 0,
      classEnding: false,
      timerBlink: false,
      minutesLeft: 3,
    };
  },
  props: {
    startTime: {
      type: Date,
      required: true,
    },
    endTime: {
      type: Date,
      required: true,
    },
  },
  computed: {
    minutesFormated() {
      return str_pad_left(this.minutes);
    },
    secondsFormated() {
      return str_pad_left(this.seconds);
    },
    lengthTextFormated() {
      return this.minutesFormated.length;
    },
  },
  watch: {
    classEnding() {
      endingIntervalId = setInterval(() => {
        this.timerBlink = true;
        setTimeout(() => (this.timerBlink = false), 600);
      }, 5000);
    },
  },
  mounted() {
    const startTime = ~~((new Date() - this.startTime) / 1000);
    this.minutes = Math.floor(startTime / 60);
    this.seconds = startTime - this.minutes * 60;

    let minutesToEnd = Math.floor(~~((this.endTime - new Date()) / 1000) / 60);
    if (!this.classEnding && minutesToEnd <= this.minutesLeft) {
      this.classEnding = true;
    }

    intervalId = setInterval(() => {
      if (this.minutes >= 0) {
        this.seconds++;
        if (this.seconds == 60) {
          this.seconds = 0;
          this.minutes++;
          minutesToEnd--;
          if (!this.classEnding && minutesToEnd <= this.minutesLeft) {
            this.classEnding = true;
          }
        }
      } else {
        this.seconds--;
        if (this.seconds == -1) {
          this.seconds = 59;
          this.minutes++;
        }
      }
    }, 1000);
  },
  beforeDestroy() {
    if (intervalId) clearInterval(intervalId);
    if (endingIntervalId) clearInterval(endingIntervalId);
  },
};

function str_pad_left(theNumber) {
  return (new Array(3).join("0") + theNumber).slice(theNumber > 99 ? -3 : -2);
}
</script>
<style>
.blink {
  background-color: rgb(219, 18, 18) !important;
}
</style>
