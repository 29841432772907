var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-card d-flex flex-column align-center"},[_c('v-card',{staticClass:"mt-10 mb-10 ml-3 mr-3"},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline no-split-words pb-4 pt-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-wrap pl-4 pr-4"},[_c('v-btn',{staticClass:"ml-1 mr-1 mb-1 primary",attrs:{"small":"","to":{ name: 'Company Register' }}},[_vm._v(" "+_vm._s(_vm.$t("account.form.validation.register.company"))+" ")]),_c('v-btn',{staticClass:"ml-1 mr-1 mb-1 primary",attrs:{"small":"","to":{ name: 'Register' }}},[_vm._v(" "+_vm._s(_vm.$t("account.form.validation.register.name"))+" ")]),_c('v-btn',{staticClass:"ml-1 mr-1 mb-1",attrs:{"small":"","to":{ name: 'ResetPasswordRequest' }}},[_vm._v(_vm._s(_vm.$t("account.actions.forgot_password"))+" ")])],1),_c('v-form',{ref:"form",staticClass:"pt-4 pb-4",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"username","name":"username","type":"text","maxlength":50,"prepend-inner-icon":"person","label":_vm.$t('profile.fields.login'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.username_required'); },
                function (v) { return !v ||
                  this$1.regex.LOGIN_REGEX.test(v) ||
                  _vm.$t('user_management.loginValid'); } ]},on:{"input":function($event){_vm.username = _vm.username.toLowerCase()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"current-password","name":"password","maxlength":"100","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword ? 'visibility' : 'visibility_off',"label":_vm.$t('account.form.password'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                function (value) { return value.length <= 100 ||
                  _vm.$t('account.form.validation.password_less_chars'); },
                function (value) { return value.length > 3 ||
                  this$1.$t('account.form.validation.password_more_chars'); } ],"type":_vm.hidePassword ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword = !_vm.hidePassword); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.userLogin()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('account.actions.remember_me')},model:{value:(_vm.remember_me),callback:function ($$v) {_vm.remember_me=$$v},expression:"remember_me"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"primary",on:{"click":_vm.userLogin}},[_vm._v(" "+_vm._s(_vm.$t("account.actions.log_in"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }