import store from "./store";

const state = store.state;

function setSessionState(newState) {
  state.sessionState = newState;
}

function setClassroomViewMode(mode) {
  state.classroomViewMode = mode;
}

function setRecording(isRecording) {
  state.recording = isRecording;
}

function _normalize(str, upper) {
  return str
    .normalize("NFKD")
    .replace(/[\u0300-\u036F]/g, "")
    .replace(/[-_]+/g, " ");
}

function _sortParticipants(a, b) {
  if ((a.cameraOnId && b.cameraOnId) || (!a.cameraOnId && !b.cameraOnId)) {
    return _normalize(a.name) < _normalize(b.name) ? -1 : 1;
  } else {
    a.cameraOnId ? -1 : 1;
  }
}

function setParticipants(participants) {
  participants.sort(_sortParticipants);
  state.participants = participants;
}

function setCurrentDevice(device) {
  state.currentDevice = device;
}

function setCameras(cameras) {
  state.hardwareDevices.cameras = cameras;
}

function setMicrophones(microphones) {
  state.hardwareDevices.microphones = microphones;
}

function setSelectedCamera(camera) {
  state.hardwareDevices.selectedCamera = camera;
}

function setSelectedMicrophone(microphone) {
  state.hardwareDevices.selectedMicrophone = microphone;
}

function setDeviceSharing(id) {
  state.deviceSharing = id;
}

function setUserImages(userImages) {
  state.userImages = userImages;
}

function setSessions(sessions) {
  state.sessions = sessions;
}

function setAudioActive(isActive) {
  state.audioActive = isActive;
}

function clearClassroomState() {
  state.sessionState = null;
  state.classroomViewMod = null;
  state.recording = false;
  state.deviceSharing = null;
  state.participants = [];
  state.currentDevice = null;
  state.hardwareDevices = {
    microphones: [],
    selectedMicrophone: null,
    cameras: [],
    selectedCamera: null,
  };
  state.userImages = {};
  state.sessions = [];
  state.audioActive = false;
}

export default {
  setSessionState,
  setClassroomViewMode,
  setRecording,
  setParticipants,
  setCurrentDevice,
  setCameras,
  setMicrophones,
  setSelectedCamera,
  setSelectedMicrophone,
  setDeviceSharing,
  clearClassroomState,
  setUserImages,
  setSessions,
  setAudioActive
};
