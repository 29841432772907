import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const localeEs = require.context("../locale/es", true, /\.json$/);
const localeEn = require.context("../locale/en", true, /\.json$/);
const localeCn = require.context("../locale/cn", true, /\.json$/);

const messages = {
  ES: {},
  EN: {},
  CN: {},
};

const dateTimeFormats = {
  EN: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    medium: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    schedule: {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    monthAndYear: {
      month: "long",
      year: "numeric",
    },
  },
  ES: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    medium: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    schedule: {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    monthAndYear: {
      month: "long",
      year: "numeric",
    },
  },
  CN: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    medium: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    schedule: {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    monthAndYear: {
      month: "long",
      year: "numeric",
    },
  },
};

const numberFormats = {
  EN: {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
      currencyDisplay: "symbol",
    },
  },
  ES: {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
      currencyDisplay: "symbol",
    },
  },
  CN: {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
      currencyDisplay: "symbol",
    },
  },
};

localeEs.keys().forEach((filename) => {
  Object.keys(localeEs(filename)).forEach((key) => {
    messages.ES[key] = localeEs(filename)[key];
  });
});
localeEn.keys().forEach((filename) => {
  Object.keys(localeEn(filename)).forEach((key) => {
    messages.EN[key] = localeEn(filename)[key];
  });
});
localeCn.keys().forEach((filename) => {
  Object.keys(localeCn(filename)).forEach((key) => {
    messages.CN[key] = localeCn(filename)[key];
  });
});

const i18n = new VueI18n({
  locale: "ES", // set locale
  fallbackLocale: "ES", // set fallback locale
  messages, // set locale messages
  dateTimeFormats,
  numberFormats,
});

export default i18n;
