var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"order":"1","order-md":"2"}},[_c('v-btn',{attrs:{"color":"success ml-2","to":{ name: 'Create Course', params: { backPrevious: true } }}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.new"))+" ")])],1)],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{staticClass:"mx-3",attrs:{"label":_vm.$t('course.headers.incomplete_courses')},on:{"change":_vm.onSearchChange},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('language-selector',{attrs:{"clearable":!_vm.levelFilter,"dense":"","outlined":"","debouncing":300,"label":_vm.$t('activity.prop.language'),"show-chips":""},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLanguageChange' ].concat( argsArray ))}},model:{value:(_vm.languageFilter),callback:function ($$v) {_vm.languageFilter=$$v},expression:"languageFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('level-selector',{attrs:{"clearable":"","debouncing":300,"dense":"","label":_vm.$t('activity.prop.level'),"language-filter":_vm.languageFilter ? _vm.languageFilter.id : null,"outlined":"","show-language":false},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLevelChange' ].concat( argsArray ))}},model:{value:(_vm.levelFilter),callback:function ($$v) {_vm.levelFilter=$$v},expression:"levelFilter"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"click:row":_vm.entityDetail,"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.level.language.name",fn:function(ref){
var item = ref.item;
return [(item.level.language)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t(("languages." + (item.level.language.name)))))+" ")]):_vm._e()]}},{key:"item.level.name",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(item.level.name))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.entityDetail(item)}}},'v-icon',attrs,false),on),[_vm._v(" description ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.detail")))])]),_c('v-edit-dialog',{attrs:{"cancel-text":_vm.$t('actions.cancel'),"large":"","persistent":"","save-text":_vm.$t('actions.save')},on:{"save":function($event){return _vm.duplicateCourse(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('span',{staticClass:"my-8 text-h6"},[_vm._v(" "+_vm._s(_vm.$t("course.actions.duplicate"))+" ")]),_c('v-container',{staticClass:"mt-2"},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","label":_vm.$t('course.prop.title'),"outlined":"","rules":[function (v) { return !!v || _vm.$t('course.error.required'); }]},model:{value:(_vm.duplicatedCourseName),callback:function ($$v) {_vm.duplicatedCourseName=$$v},expression:"duplicatedCourseName"}})],1)]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" content_copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("course.actions.duplicate")))])])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }