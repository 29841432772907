<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-row>
          <v-col cols="12" md="6" class="text-center">
            <v-row justify="center">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mt-4 mt-md-13"
                  :elevation="hover ? 12 : 2"
                  width="300px"
                  @click="navigate('ProfileCompanyView Balance')"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="py-0 text-center">
                      <v-icon x-large color="primary"> mdi-cash </v-icon>
                      <br />
                      <span>
                        {{ $n(walletBalance, "currency") }}
                      </span>
                    </v-col>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <span class="text-overline">
                      {{ $t("company.dashboard.wallet_balance") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <span class="text-h6">
              {{ $t("company.dashboard.active_courses") }}
              <v-icon
                v-if="!expandActiveCourses && editions.length > 0"
                @click="expandActiveCourses = true"
              >
                expand_more
              </v-icon>
              <v-icon
                v-else-if="editions.length > 0"
                @click="expandActiveCourses = false"
              >
                expand_less
              </v-icon>
            </span>
            <br v-if="loading" />
            <br v-if="loading" />
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <transition name="slide-fade">
              <v-container v-if="expandActiveCourses && !loading" fluid>
                <v-row
                  v-for="edition in editions"
                  :key="edition.id"
                  class="mt-2"
                  justify="center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 12 : 2" width="300px">
                      <v-card-title class="py-0">
                        <v-col class="text-center">
                          <span class="no-split-words">
                            {{ edition.title }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <span class="text-overline"
                          >{{ $t("product.prop.start_date") }}:
                        </span>
                        <span class="text-overline" v-if="edition.startDate">
                          {{ edition.startDate | dateTimeWithTz("short") }}
                        </span>
                        <span v-else>-</span>
                        <br />
                        <span class="text-overline"
                          >{{ $t("product.prop.end_date") }}:
                        </span>
                        <span class="text-overline" v-if="edition.endDate">
                          {{ edition.endDate | dateTimeWithTz("short") }}
                        </span>
                        <span v-else>-</span>
                        <br />
                        <span class="text-overline">
                          <v-icon>mdi-account-group</v-icon>
                          {{ edition.numStudents }}
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-row>
                <v-row
                  v-if="!editions || editions.length === 0"
                  class="mt-2"
                  justify="center"
                >
                  <span>{{ $t("company.dashboard.no_active_courses") }}</span>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");
export default {
  data() {
    return {
      walletBalance: 0,
      editions: [],
      expandActiveCourses: true,
      loading: false,
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      DashboardRepository.getForCompany()
        .then((data) => {
          this.walletBalance = data.walletBalance;
          this.editions = data.editions;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    getBadgeColor(count) {
      return count > 0 ? "red" : "green";
    },
    navigate(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
