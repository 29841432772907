// Admin profile access
const navBarItemsAdmin = [
  {
    title: "student.navbar.profile",
    icon: "mdi-account",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.info",
        link: "StudentView Detail",
        icon: "mdi-account",
        notification: false,
        showed: true,
      },
      {
        title: "student.navbar.tutors",
        link: "StudentTutorsTab",
        icon: "escalator_warning",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.documents",
    link: "StudentDocumentsTab",
    icon: "mdi-file-document",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.schedule",
    icon: "mdi-calendar",
    link: "StudentSchedulesTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.levels",
    icon: "mdi-school",
    link: "StudentLevelsTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.registrations",
    link: "StudentRegistrationsTab",
    icon: "mdi-school",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.balance",
        link: "StudentBalanceTab",
        icon: "mdi-cash",
        notification: false,
        showed: true,
      },
      {
        title: "student.navbar.movements",
        link: "StudentMovementsTab",
        icon: "mdi-swap-horizontal",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.feedback",
    link: "StudentFeedbackTab",
    icon: "mdi-star",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.results",
    link: "StudentResultsTab",
    icon: "mdi-briefcase",
    notification: false,
    showed: true,
  },
];

export default navBarItemsAdmin;
