<template>
  <v-container fluid>
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col>
                <debounced-text-field
                  append-icon="search"
                  class="d-md-inline-block"
                  dense
                  hide-details
                  v-model="search"
                  :label="$t('search')"
                  @input="onFilterChange"
                ></debounced-text-field>
              </v-col>
              <v-col v-if="isRegisteredView">
                <v-switch
                  dense
                  v-model="headmastersFilter"
                  :label="$t('teacher.lists.headmaster_filter')"
                  @change="onFilterChange"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="2" class="text-right">
            <v-btn
              color="success ml-2"
              :to="{ name: 'Create Teacher', params: { backPrevious: true } }"
            >
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              class="data-table rows-clickable"
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :options="pagination"
              :server-items-length="totalItems"
              :item-class="setRowClass"
              @click:row="entityDetail"
              @update:options="onPaginationChange"
            >
              <template v-if="isRegisteredView" v-slot:top>
                <modal-dialog
                  :dialog="setHeadmasterDialog"
                  :title="$t('teacher.modals.set_headmaster.title')"
                  title-class="warning white--text"
                  title-icon="mdi-alert"
                  :content="$t('teacher.modals.set_headmaster.content')"
                  :submit-text="$t('actions.continue')"
                  :cancel-text="$t('actions.cancel')"
                  submit-class="warning"
                  cancel-class=""
                  @submit="setTeacherHeadmasterRole"
                  @cancel="closeHeadmasterRoleDialog('setHeadmasterDialog')"
                ></modal-dialog>
                <modal-dialog
                  :dialog="removeHeadmasterDialog"
                  :title="$t('teacher.modals.remove_headmaster.title')"
                  title-class="warning white--text"
                  title-icon="mdi-alert"
                  :content="$t('teacher.modals.remove_headmaster.content')"
                  :submit-text="$t('actions.continue')"
                  :cancel-text="$t('actions.cancel')"
                  submit-class="warning"
                  cancel-class=""
                  @submit="removeTeacherHeadmasterRole"
                  @cancel="closeHeadmasterRoleDialog('removeHeadmasterDialog')"
                ></modal-dialog>
              </template>
              <template v-slot:[`item.dateStart`]="{ item }">
                <span>
                  {{ item.dateStart | dateTimeWithTz }}
                </span>
              </template>
              <template v-if="isRegisteredView" v-slot:[`item.job`]="{ item }">
                <v-chip
                  v-if="item.authorities && isHeadmaster(item)"
                  :text-color="stringToColourText('ROLE_HEADMASTER')"
                  :color="stringToColour('ROLE_HEADMASTER')"
                >
                  {{ $t("user_management.roles.ROLE_HEADMASTER") }}
                </v-chip>
                <v-chip
                  v-else-if="item.authorities"
                  :text-color="stringToColourText('ROLE_TEACHER')"
                  :color="stringToColour('ROLE_TEACHER')"
                >
                  {{ $t("user_management.roles.ROLE_TEACHER") }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip
                  top
                  open-delay="400"
                  v-if="
                    isRegisteredView && item.authorities && !isHeadmaster(item)
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Botón para la convertir un profesor en jefe de estudios -->

                    <v-btn
                      icon
                      :color="stringToColour('ROLE_HEADMASTER')"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="
                        showHeadmasterRoleDialog('setHeadmasterDialog', item)
                      "
                    >
                      <v-icon> mdi-account-supervisor </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("teacher.modals.set_headmaster.tooltip") }}</span>
                </v-tooltip>

                <v-tooltip
                  top
                  open-delay="400"
                  v-if="
                    isRegisteredView && item.authorities && isHeadmaster(item)
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Botón para que un profesor deje de convertirse en jefe de estudios -->
                    <v-btn
                      icon
                      :color="stringToColour('ROLE_TEACHER')"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="
                        showHeadmasterRoleDialog('removeHeadmasterDialog', item)
                      "
                    >
                      <v-icon> mdi-account-supervisor </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("teacher.modals.remove_headmaster.tooltip")
                  }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      @click.stop="entityDetail(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> description </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.detail") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      @click.stop="selectDeactivateUser(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialogo para la eliminacion de usuarios -->
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="deactivateUser"
    ></delete-dialog>
  </v-container>
</template>

<script>
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { stringToColour, stringToColourText } from "@/common/customization";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "TeacherList",
  components: {
    DebouncedTextField,
    ModalDialog,
    DeleteDialog,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      isLoading: false,
      selected: null,
      setHeadmasterDialog: false,
      removeHeadmasterDialog: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      search: this.$route.query.search,
      headmastersFilter: this.$route.query.job === "ROLE_HEADMASTER",
      selectedEntity: null,
      deleteDialog: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$t("profile.fields.display_name"),
          value: "displayName",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "mainPhone",
        },
        {
          text: this.$t("profile.fields.date_start"),
          value: "dateStart",
        },
      ];
      if (this.$route.name === "Registered teachers list") {
        headers.push({
          text: this.$t("teacher.lists.job"),
          sortable: false,
          value: "job",
        });
      }
      headers.push({
        text: "",
        sortable: false,
        value: "action",
      });
      return headers;
    },
    isRegisteredView() {
      return this.$route.name === "Registered teachers list";
    },
  },
  watch: {
    "$route.name"() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const sortMapping = {
        displayName: "userData.displayName",
        surname: "userData.user.lastName",
        name: "userData.user.firstName",
        mainEmail: "userData.user.email",
        mainPhone: "userData.mainPhone",
        dateStart: "userData.dateStart",
      };
      let authority = "ROLE_TEACHER_CANDIDATE";
      if (this.isRegisteredView) {
        authority = this.headmastersFilter ? "ROLE_HEADMASTER" : "ROLE_TEACHER";
      }
      const options = {
        params: {
          page: this.pagination.page - 1,
          authority: authority,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          search: this.search ? this.search : undefined,
        },
      };
      TeacherEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching teachers with params: " + options)
        )
        .finally(() => (this.isLoading = false));
    },
    setRowClass(row) {
      return row.authorities.indexOf("ROLE_HEADMASTER") !== -1
        ? "headmaster"
        : "";
    },
    showHeadmasterRoleDialog(dialog, entity) {
      this.selected = entity;
      this[dialog] = true;
    },
    closeHeadmasterRoleDialog(dialog) {
      this.selected = null;
      this[dialog] = false;
    },
    setTeacherHeadmasterRole() {
      TeacherEntityRepository.setHeadmasterRole(this.selected.id)
        .then(() => {
          this.selected.authorities.push("ROLE_HEADMASTER");
        })
        .catch(() =>
          this.$log.debug(
            "Error setting teachers with ID " +
              this.selected.id +
              " as headmaster"
          )
        )
        .finally(() => this.closeHeadmasterRoleDialog("setHeadmasterDialog"));
    },
    removeTeacherHeadmasterRole() {
      TeacherEntityRepository.removeHeadmasterRole(this.selected.id)
        .then(() => {
          this.selected.authorities.splice(
            this.selected.authorities.indexOf("ROLE_HEADMASTER"),
            1
          );
        })
        .catch(() =>
          this.$log.debug(
            "Error removing teacher with ID " +
              this.selected.id +
              " from headmaster"
          )
        )
        .finally(() =>
          this.closeHeadmasterRoleDialog("removeHeadmasterDialog")
        );
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "TeacherView Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.search = this.search ? this.search : undefined;
      query.job = this.headmastersFilter ? "ROLE_HEADMASTER" : undefined;
      this.redirect(query);
    },
    onFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    deactivateUser() {
      this.deleteLoading = true;
      UserDataEntityRepository.deactivateUser(this.selectedEntity)
        .then(() => this.fetchData())
        .catch(() =>
          this.$log.debug("Error deactivating user: " + this.selectedEntity)
        )
        .finally(() => {
          this.selectedEntity = null;
          this.deleteDialog = false;
          this.deleteLoading = false;
        });
    },
    selectDeactivateUser(entity) {
      this.selectedEntity = entity;
      this.deleteDialog = true;
    },
    isHeadmaster(item) {
      return item.authorities.indexOf("ROLE_HEADMASTER") !== -1;
    },
    stringToColour,
    stringToColourText,
  },
};
</script>
