<template>
  <div>
    <v-card class="text-center" @click.stop="detailsDialog = true">
      <v-img
        :src="lecture.teacher.photo"
        height="150"
        style="margin: auto"
        width="150"
      ></v-img>
      <span class="subtitle-2">{{ lecture.teacher.name }}</span>
      <v-row>
        <v-col v-if="lecture.teacherChatId">
          <v-btn
            color="accent"
            icon
            tile
            @click.stop="$EventBus.$emit('open-chat', lecture.teacherChatId)"
          >
            <icon name="chat-simple" width="28" height="28" title="chat"></icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="detailsDialog"
      width="500px"
      @click:outside="detailsDialog = false"
    >
      <teacher-lecture-details
        v-if="isAnyStudent || isTutor || isAdmin || isSupervisorOfTeacher"
        :teacherId="lecture.teacher.id"
        :photo="lecture.teacher.photo"
        @close="detailsDialog = false"
      >
      </teacher-lecture-details>
    </v-dialog>
  </div>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import TeacherLectureDetails from "@/mockups/admin/lectures/lecture-detail/TeacherLectureDetails";

export default {
  name: "TeacherLectureDetailsPreview",
  props: {
    isSupervisorOfTeacher: {
      type: Boolean,
      required: true,
    },
    lecture: {
      type: Object,
      required: true,
    },
  },
  components: {
    TeacherLectureDetails,
  },
  data() {
    return {
      detailsDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAnyStudent", "isTutor"]),
  },
};
</script>
