import RegistrationDetail from "../components/RegistrationDetail";
import StudentDetail from "../StudentDetail";
import BalanceTab from "../tabs/BalanceTab";
import DocumentFormTab from "../tabs/DocumentFormTab";
import DocumentsTab from "../tabs/DocumentsTab";
import FeedbackTab from "../tabs/FeedbackTab";
import LevelsTab from "../tabs/LevelsTab";
import MovementsTab from "../tabs/MovementsTab";
import PersonalInfoFormTab from "../tabs/PersonalInfoFormTab";
import PersonalInfoTab from "../tabs/PersonalInfoTab";
import RegistrationsTab from "../tabs/RegistrationsTab";
import ResultsTab from "../tabs/ResultsTab";
import SchedulesTab from "../tabs/ScheduleTab";

// ADMIN ROUTES DEFINITION
const routes = [
  {
    path: "/tutor/students/:id",
    name: "Tutor StudentView",
    component: StudentDetail,
    children: [
      {
        path: "info",
        name: "Tutor StudentView Detail",
        component: PersonalInfoTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.info",
        },
      },
      {
        path: "edit",
        name: "Tutor StudentView Edit",
        component: PersonalInfoFormTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "profile.your_profile",
        },
      },
      {
        path: "schedules",
        name: "Tutor StudentSchedulesTab",
        component: SchedulesTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.schedule",
        },
      },
      {
        path: "levels",
        name: "Tutor StudentLevelsTab",
        component: LevelsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.levels",
        },
      },
      {
        path: "documents",
        name: "Tutor StudentDocumentsTab",
        component: DocumentsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "documents/new",
        name: "Tutor StudentDocumentFormTab",
        component: DocumentFormTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "registrations",
        name: "Tutor StudentRegistrationsTab",
        component: RegistrationsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.registrations",
        },
      },
      {
        path: "registrations/:registrationId",
        name: "Tutor StudentRegistrationDetail",
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "registration.name",
        },
        component: RegistrationDetail,
      },
      {
        path: "balance",
        name: "Tutor StudentBalanceTab",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "Tutor StudentMovementsTab",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.movements",
        },
      },
      {
        path: "feedback",
        name: "Tutor StudentFeedbackTab",
        component: FeedbackTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.feedback",
        },
      },
      {
        path: "results",
        name: "Tutor StudentResultsTab",
        component: ResultsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "student.navbar.results",
        },
      },
    ],
  },
];

export default routes;
