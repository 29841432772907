<template>
  <lecture-list
    close-lectures-check
    :dates-filtering="pagination"
    :pagination="pagination"
    :lectures="lectures"
    :loading="loading"
    :total-items="totalItems"
    @click:row="lectureDetail"
    @update:params="fetchLectures"
  ></lecture-list>
</template>

<script>
import LectureList from "@/mockups/components/LectureList";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

export default {
  name: "TeacherLectureList",
  components: {
    LectureList,
  },
  data() {
    return {
      lectures: [],
      loading: false,
      totalItems: -1,
      pagination: false,
    };
  },
  methods: {
    fetchLectures(options = {}, closeLectures = true) {
      this.loading = true;
      this.pagination = !closeLectures;
      if (closeLectures) {
        LectureEntityRepository.getCloseForTeacher(this.$route.params.teacherId)
          .then((data) => {
            this.lectures = data;
            this.totalItems = -1;
          })
          .finally(() => (this.loading = false));
      } else {
        options.params.teacherId = this.$route.params.teacherId;
        LectureEntityRepository.getAll(options)
          .then((response) => {
            this.lectures = response.content;
            this.totalItems = response.totalElements;
          })
          .finally(() => (this.loading = false));
      }
    },
    lectureDetail(lecture) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lecture.id,
            backPrevious: true,
          },
        });
      }
    },
  },
};
</script>
