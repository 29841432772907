import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/activityInteractions";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllResultsByStudent(id, options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/student/${id}/results`,
      options
    );
    return response.data;
  },

  async getAllHomeworkByStudent(id, options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/student/${id}/homework`,
      options
    );
    return response.data;
  },

  async getAllByLectureAndStudent(id, options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/lecture/${id}`, options);
    return response.data;
  },

  async getByStudentInLevelTest(studentId, lectureId) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/student/${studentId}/lecture/${lectureId}`
    );
    return response.data;
  },

  async getContent(id) {
    return HTTP.get(`${RESOURCE_NAME}/${id}/content`, {
      responseType: "blob",
    });
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async updateGrade(entity) {
    return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}/grade`, entity)).data;
  },

  async getRecording(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/recording`)).data;
  },
};
