<template>
  <v-container v-if="!loading && lesson">
    <v-card>
      <v-card-title>
        <!-- Current level test -->
        <v-row align="center" no-gutters>
          <v-col cols="12" md="6">
            <h2>
              {{ lesson.name }}
              <v-chip color="warning" class="ma-2">
                {{
                  (lesson.level.name +
                    " - " +
                    $t("languages." + lesson.level.language.name))
                    | uppercase
                }}
              </v-chip>
            </h2>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="text-right d-md-inline-flex justify-md-end"
          >
            <v-btn class="mt-1" @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
            <v-btn
              v-if="lesson.editable"
              class="warning ml-2 mt-1"
              @click="editLesson()"
            >
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.edit") }} </span>
            </v-btn>
            <v-btn class="error ml-2 mt-1" @click="dialog = true">
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.delete") }}
              </span>
            </v-btn>
            <v-edit-dialog
              :cancel-text="$t('actions.cancel')"
              large
              persistent
              :save-text="$t('actions.save')"
              @save="duplicateLesson"
            >
              <v-btn class="primary ml-2 mt-1">
                <v-icon>content_copy</v-icon>
                <span class="d-none d-sm-block">
                  {{ $t("lesson.actions.duplicate") }}
                </span>
              </v-btn>
              <template v-slot:input>
                <span class="my-8 text-h6">
                  {{ $t("lesson.actions.duplicate") }}
                </span>
                <v-container class="mt-2">
                  <v-text-field
                    v-model="duplicatedLessonName"
                    class="required"
                    dense
                    :label="$t('lesson.prop.name')"
                    outlined
                    :rules="[(v) => !!v || $t('lesson.error.required')]"
                  ></v-text-field>
                </v-container>
              </template>
            </v-edit-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="lesson.activities"
            disable-pagination
            disable-sort
          >
            <template v-slot:[`item.thumbnail`]="{ item }">
              <v-img
                :src="item.activity.thumbnail"
                contain
                max-width="150px"
              ></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.activity.content"
                    color="primary"
                    @click.stop="previewActivity(item.activity)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("actions.preview") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <delete-dialog
      :dialog="dialog"
      :loading="deleteLoading"
      :title="$t('lesson.messages.confirmation_delete')"
      @cancel="dialog = false"
      @submit="deleteLesson"
    >
    </delete-dialog>

    <v-dialog width="50%" v-model="previewDialog" @click:outside="closePreview">
      <activity-preview
        v-if="preview"
        :activity="preview"
        @cancel="closePreview"
      ></activity-preview>
    </v-dialog>
  </v-container>
  <loading-page v-else></loading-page>
</template>

<script>
import ActivityPreview from "../_components/ActivityPreview.vue";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import checkInvalidID from "@/common/checkInvalidID";
import LoadingPage from "@/components/loading-page/LoadingPage";

const LessonEntityRepository = RepositoryFactory.get("LessonEntityRepository");
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "LevelTestDetail",
  components: { ActivityPreview, LoadingPage, DeleteDialog },
  data() {
    return {
      lesson: null,
      dialog: false,
      deleteLoading: false,
      preview: {},
      previewDialog: false,
      duplicatedLessonName: null,
      loading: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("activity.prop.title"),
          value: "activity.title",
        },
        {
          text: this.$t("activity.prop.description"),
          value: "activity.description",
        },
        { text: "", sortable: false, value: "thumbnail" },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  mounted() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(lessonId) {
      this.loading = true;
      LessonEntityRepository.get(lessonId)
        .then((res) => {
          this.lesson = res;
          this.lesson.activities.sort((a, b) => a.order - b.order);
          this.lesson.activities.forEach((act) => {
            if (act.activity.thumbnail) {
              this.downloadThumbnail(act.activity);
            }
          });
        })
        .catch((err) => {
          this.$log.debug("Error fetching lesson with ID " + lessonId);
          checkInvalidID(err);
        })
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "LevelTest List",
        params: { backAction: true },
      });
    },
    editLesson() {
      this.$router.push({
        name: "LevelTest Form",
        params: { id: this.lesson.id, backPrevious: true },
      });
    },
    deleteLesson() {
      this.deleteLoading = true;
      LessonEntityRepository.delete(this.lesson.id)
        .then(() => {
          this.$notify({
            text: this.$t("account.notifications.successfull_deleted"),
            type: "success",
          });
          this.dialog = false;
        })
        .catch(() =>
          this.$log.debug("Error deleting lesson with ID " + this.lesson.id)
        )
        .finally(() => {
          this.deleteLoading = false;
          this.$router.push({
            name: "LevelTest List",
            params: { backAction: true },
          });
        });
    },
    downloadThumbnail(activity) {
      ActivityEntityRepository.downloadActivityThumbnail(activity.id)
        .then((res) => {
          const blob = new Blob([res.data]);
          activity.thumbnail = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching thumbnail photo for activity with ID " + activity.id
          )
        );
    },
    previewActivity(activity) {
      this.preview = activity;
      this.previewDialog = true;
    },
    closePreview() {
      this.previewDialog = false;
      this.preview = null;
    },
    duplicateLesson() {
      this.loading = true;
      const duplicateLessonObj = {
        id: this.lesson.id,
        name: this.duplicatedLessonName,
      };
      LessonEntityRepository.duplicate(duplicateLessonObj)
        .then((res) =>
          this.$router.push({
            name: "LevelTest Detail",
            params: { id: res.id, backPrevious: true },
          })
        )
        .catch(() =>
          this.$log.debug("Error duplicating lesson with id " + this.lesson.id)
        )
        .finally(() => {
          this.duplicatedLessonName = null;
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
