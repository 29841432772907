<template>
  <v-container>
    <company-form
      v-if="!loading"
      v-model="entity"
      account_details
      @save="save"
      @back="back"
    ></company-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage";
import CompanyForm from "@/mockups/company/components/CompanyForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const CompanyEntityRepository = RepositoryFactory.get(
  "CompanyEntityRepository"
);
export default {
  name: "CompanyCreateForm",
  components: {
    LoadingPage,
    CompanyForm,
  },
  data() {
    return {
      loading: false,
      entity: {
        login: "",
        password: "",
        password2: "",
        langKey: "en",
        acceptRGPD: true,
      },
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.checkSecondaryEmail();
      CompanyEntityRepository.save(this.entity)
        .then(() => this.back())
        .catch(() => this.$log.debug("Error saving new company"))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({ name: "Company list" });
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>

<style></style>
