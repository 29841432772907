<template>
  <v-container v-if="items">
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="4"
            lg="6"
            xl="8"
            order="2"
            order-sm="1"
            class="text-center text-sm-right mt-4 mt-sm-0"
          >
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="isContentEditor"
            order="1"
            order-md="2"
            class="text-right"
          >
            <v-btn color="success ml-2" :to="{ name: 'Activity Create' }">
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          align="center"
          v-show="showFilters"
          justify="space-between"
          class=""
        >
          <v-col cols="12" md="4">
            <v-checkbox
              :label="$t('activity.headers.incomplete_activities')"
              v-model="checked"
              @change="redirectOnFilterChange"
              :disabled="!isContentEditor"
              class="mx-3"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="resourceTypeFilter"
              clearable
              :debouncing="300"
              :items="resourceTypes"
              :item-text="translate"
              :search-input.sync="resourceTypeSearch"
              item-value="value"
              :label="$t('activity.prop.resource_type')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <debounced-text-field
              dense
              v-model="titleFilter"
              :label="$t('activity.prop.title')"
            ></debounced-text-field>
          </v-col>
        </v-row>
        <v-row
          align="center"
          v-show="showFilters"
          justify="space-between"
          class=""
        >
          <v-col cols="12" md="4">
            <debounced-text-field
              dense
              v-model="descriptionFilter"
              :label="$t('activity.prop.description')"
            ></debounced-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <LanguageSelector
              v-model="languageFilter"
              dense
              :clearable="!levelFilter"
              outlined
              :debouncing="300"
              :label="$t('activity.prop.language')"
              show-chips
              @input="onSelectorChange('onLanguageChange', ...arguments)"
            ></LanguageSelector>
          </v-col>
          <v-col cols="12" md="4">
            <LevelSelector
              v-model="levelFilter"
              dense
              clearable
              outlined
              :debouncing="300"
              :label="$t('activity.prop.level')"
              :language-filter="languageFilter ? languageFilter.id : null"
              :show-language="false"
              @input="onSelectorChange('onLevelChange', ...arguments)"
            ></LevelSelector
          ></v-col>
        </v-row>
        <v-row
          align="center"
          v-show="showFilters"
          justify="space-between"
          class=""
        >
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTypes.items"
              :loading="activityTypes.loading"
              :search-input.sync="activityTypeSearch"
              v-model="activityTypeFilter"
              :label="$t('activity.prop.activity_type')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activitySkills.items"
              :loading="activitySkills.loading"
              :search-input.sync="activitySkillSearch"
              v-model="activitySkillFilter"
              :label="$t('activity.prop.skills')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTopics.items"
              :loading="activityTopics.loading"
              :search-input.sync="activityTopicSearch"
              v-model="activityTopicFilter"
              :label="$t('activity.prop.topics')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              small-chips
              deletable-chips
              no-filter
              multiple
              :debouncing="300"
              :items="activityWords.items"
              :loading="activityWords.loading"
              :search-input.sync="activityWordSearch"
              v-model="activityWordFilter"
              :label="$t('activity.prop.words')"
              item-text="name"
              :item-value="(item) => parseInt(item.id)"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          class="rows-clickable"
          :headers="headers"
          :items="items"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="tableFooterProps"
          @click:row="entityDetail"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.resourceType`]="{ item }">
            {{ $t("activity.resource_type." + item.resourceType) }}
          </template>
          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ item.level.name | uppercase }}
            </v-chip>
          </template>
          <template v-slot:[`item.level.language.name`]="{ item }">
            <v-chip
              v-if="item.level.language"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ $t(`languages.${item.level.language.name}`) | uppercase }}
            </v-chip>
          </template>
          <template v-slot:[`item.activityType`]="{ item }">
            <v-chip
              v-if="item.activityType"
              :color="getChipBackColor(item.activityType)"
              :text-color="getChipTextColor(item.activityType)"
            >
              {{ item.activityType.name }}
            </v-chip>
          </template>
          <template
            v-slot:[`item.activitySkills`]="{ item }"
            v-if="!activitySkills.loading"
          >
            <div v-for="topic in item.activityTopics" :key="topic.id">
              {{
                activitySkills.items.find(
                  (t) => t.id === topic.topicId && t.topicType.name === "SKILL"
                ) &&
                activitySkills.items.find((t) => t.id === topic.topicId).name
              }}
            </div>
          </template>
          <template
            v-slot:[`item.activityTopics`]="{ item }"
            v-if="!activityTopics.loading"
          >
            <div v-for="topic in item.activityTopics" :key="topic.id">
              {{
                activityTopics.items.find(
                  (t) => t.id === topic.topicId && t.topicType.name !== "SKILL"
                ) &&
                activityTopics.items.find((t) => t.id === topic.topicId).name
              }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.content != null"
                  color="primary"
                  @click.stop="previewActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t("actions.preview") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click.stop="entityDetail(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  description
                </v-icon>
              </template>
              <span>{{ $t("actions.detail") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  v-if="isContentEditor"
                  @click.stop="showDeleteDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="stopDeleteAction"
      @submit="deleteActivity"
    ></delete-dialog>
    <v-dialog width="50%" v-model="previewDialog" v-if="previewDialog">
      <activity-preview
        v-if="preview"
        :activity="preview"
        @cancel="closePreview"
      ></activity-preview>
    </v-dialog>

    <v-dialog
      v-model="pdfDialog"
      v-if="pdfDialog"
      width="80%"
      @click:outside="closePreview"
    >
      <PDFViewer
        :exercise="preview.id"
        :homework="false"
        @closeDialog="closePreview"
      >
      </PDFViewer>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import { translate } from "@/common/translation-utils.js";
import ActivityPreview from "../_components/ActivityPreview.vue";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";
import ResourceTypes from "@/enumerates/ResourceType";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const ActivityTypeEntityRepository = RepositoryFactory.get(
  "ActivityTypeEntityRepository"
);
const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");

const resourceTypesWithoutLink = ResourceTypes.filter(
  (i) => i.value !== "LINK"
);

try {
  const H5PWebcomponents = require("@lumieducation/h5p-webcomponents");
  H5PWebcomponents.defineElements("h5p-editor");
} catch {}

export default {
  name: "ActivityList",
  components: {
    ActivityPreview,
    PDFViewer,
    DeleteDialog,
    DebouncedTextField,
    LanguageSelector,
    LevelSelector,
  },
  data() {
    return {
      items: [],
      showFilters: false,
      checked: this.$route.query.incomplete === "true",
      activityTypeSearch: null,
      activityTopicSearch: null,
      activitySkillSearch: null,
      activityWordSearch: null,
      deleteDialog: false,
      selected: null,
      resourceTypes: resourceTypesWithoutLink,
      titleFilter: null,
      descriptionFilter: null,
      levelFilter: null,
      languageFilter: null,
      activityTypeFilter: null,
      activityWordFilter: [],
      activityTopicFilter: null,
      activitySkillFilter: null,
      resourceTypeFilter: null,
      resourceTypeSearch: "",
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      activityTypes: {
        items: [],
        loading: false,
      },
      activityTopics: {
        items: [],
        loading: false,
      },
      activitySkills: {
        items: [],
        loading: false,
      },
      activityWords: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
      preview: {},
      previewDialog: false,
      pdfDialog: false,
      deleteLoading: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isContentEditor"]),
    headers() {
      return [
        {
          text: this.$t("activity.prop.resource_type"),
          value: "resourceType",
        },
        {
          text: this.$t("activity.prop.title"),
          value: "title",
        },
        {
          text: this.$t("activity.prop.description"),
          value: "description",
        },
        {
          text: this.$t("level.prop.language"),
          value: "level.language.name",
        },
        {
          text: this.$t("activity.prop.level"),
          value: "level",
        },
        {
          text: this.$t("activity.prop.activity_type"),
          value: "activityType",
        },
        {
          text: this.$t("activity.prop.skills"),
          sortable: false,
          value: "activitySkills",
        },
        {
          text: this.$t("activity.prop.topics"),
          sortable: false,
          value: "activityTopics",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    wordFilter() {
      return this.activityWordFilter.join();
    },
    filters() {
      let filters = "";
      filters =
        filters +
        (this.titleFilter != null && this.titleFilter !== ""
          ? "title:" + this.titleFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.descriptionFilter != null && this.descriptionFilter !== ""
          ? "description:" + this.descriptionFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.activityTypeFilter
          ? "activityType.id:" + this.activityTypeFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.resourceTypeFilter
          ? "resourceType:" + this.resourceTypeFilter.toString() + ","
          : "");
      return filters !== "" ? filters : null;
    },
  },
  watch: {
    activityTypeSearch() {
      this.getActivityTypeItems();
    },
    activityTopicSearch() {
      this.getActivityTopicItems();
    },
    activitySkillSearch() {
      this.getActivitySkillItems();
    },
    activityWordSearch() {
      this.getActivityWordItems();
    },
    resourceTypeSearch(newVal) {
      this.resourceTypes = newVal
        ? resourceTypesWithoutLink.filter(
            (item) =>
              this.$t(item.text).toLowerCase().indexOf(newVal.toLowerCase()) !==
              -1
          )
        : resourceTypesWithoutLink;
    },
    activityTopicFilter() {
      this.redirectOnFilterChange();
    },
    activitySkillFilter() {
      this.redirectOnFilterChange();
    },
    wordFilter() {
      this.activityWordSearch = null;
      this.redirectOnFilterChange();
    },
    filters() {
      this.redirectOnFilterChange();
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.titleFilter) {
      this.showFilters = true;
      this.titleFilter = this.$route.query.titleFilter;
    }
    if (this.$route.query.descriptionFilter) {
      this.showFilters = true;
      this.descriptionFilter = this.$route.query.descriptionFilter;
    }
    if (this.$route.query.levelFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.activityTypeFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.activityTypeFilter);
      this.activityTypeFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.activityTopicFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.activityTopicFilter);
      this.activityTopicFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.activitySkillFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.activitySkillFilter);
      this.activitySkillFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.activityWordFilter) {
      this.showFilters = true;
      const filter = this.$route.query.activityWordFilter.split(",");
      filter.forEach((item) => {
        let value = parseFloat(item);
        this.activityWordFilter.push(isNaN(value) ? null : value);
      });
    }
    if (this.$route.query.resourceTypeFilter) {
      this.showFilters = true;
      this.resourceTypeFilter = this.$route.query.resourceTypeFilter;
    }
    if (this.$route.query.levelFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.languageFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }

    this.getItems();

    this.getActivityTypeItems();
    this.getActivityTopicItems();
    this.getActivitySkillItems();
    this.getActivityWordItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        "level.language.name": "level.name",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage, sortMapping),
          topics: this.activityTopicFilter,
          skills: this.activitySkillFilter,
          resourceTypes: this.resourceTypeFilter,
          activityWords: this.wordFilter,
          incomplete: this.checked,
          active: true,
          excludeLinks: true,
          level: this.levelFilter ? this.levelFilter.id : undefined,
          language: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      ActivityEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching activity list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    getActivityTypeItems() {
      this.activityTypes.loading = true;
      const options = {
        params: {
          search: this.activityTypeSearch,
        },
      };
      ActivityTypeEntityRepository.getAll(options)
        .then((response) => (this.activityTypes.items = response))
        .catch(() =>
          this.$log.debug(
            "Error fetching activity types with params: " + options
          )
        )
        .finally(() => (this.activityTypes.loading = false));
    },
    getActivityTopicItems() {
      this.activityTopics.loading = true;
      const options = {
        params: {
          languages: this.languageFilter ? this.languageFilter.id : undefined,
          search: this.activityTopicSearch,
          types: "2,3",
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.activityTopics.items = response.content;
        })
        .catch(() =>
          this.$log.debug("Error fetching topics with params: " + options)
        )
        .finally(() => {
          this.activityTopics.loading = false;
          if (
            this.activityTopicFilter != null &&
            !this.activityTopics.items.find(
              (s) => s.id === this.activityTopicFilter
            ) //Si activityTopicFilter no está en la lista, se pone a null
          )
            this.activityTopicFilter = null;
        });
    },
    getActivitySkillItems() {
      this.activitySkills.loading = true;
      const options = {
        params: {
          languages: this.languageFilter ? this.languageFilter.id : undefined,
          search: this.activitySkillSearch,
          types: "1",
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.activitySkills.items = response.content;
        })
        .catch(() =>
          this.$log.debug("Error fetching topics with params: " + options)
        )
        .finally(() => {
          this.activitySkills.loading = false;
          if (
            this.activitySkillFilter != null &&
            !this.activitySkills.items.find(
              (s) => s.id === this.activitySkillFilter
            )
          )
            //Si activitySkillFilter no está en la lista, se pone a null
            this.activitySkillFilter = null;
        });
    },
    getActivityWordItems() {
      this.activityWords.loading = true;
      const options = {
        params: {
          search: this.activityWordSearch,
          level: this.levelFilter ? this.levelFilter.id : undefined,
          language: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      return WordEntityRepository.getAllByLevelAndLanguage(options)
        .then((data) => {
          this.activityWords.items = data.content;
        })
        .finally(() => (this.activityWords.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "Activity List",
          query: query,
        });
        this.getItems();
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Activity Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    changeQueryFilters(query) {
      query.titleFilter =
        this.titleFilter != null && this.titleFilter !== ""
          ? this.titleFilter
          : undefined;
      query.descriptionFilter =
        this.descriptionFilter != null && this.descriptionFilter !== ""
          ? this.descriptionFilter
          : undefined;
      query.levelFilter = this.levelFilter?.id.toString();
      query.languageFilter =
        this.levelFilter == null
          ? this.languageFilter?.id.toString()
          : undefined;
      query.activityTypeFilter = this.activityTypeFilter
        ? this.activityTypeFilter.toString()
        : undefined;
      query.activityTopicFilter = this.activityTopicFilter
        ? this.activityTopicFilter.toString()
        : undefined;
      query.activitySkillFilter = this.activitySkillFilter
        ? this.activitySkillFilter.toString()
        : undefined;
      query.activityWordFilter = this.wordFilter ? this.wordFilter : undefined;
      query.resourceTypeFilter = this.resourceTypeFilter
        ? this.resourceTypeFilter.toString()
        : undefined;
      query.incomplete = this.checked ? this.checked.toString() : undefined;
    },
    onSelectorChange(op, value) {
      if (op === "onLevelChange" && value) {
        this.languageFilter = value.language;
      }
      if (op === "onLanguageChange") {
        if (
          this.levelFilter != null &&
          this.levelFilter.language.id === value?.id
        ) {
          return;
        }
      }
      this.getActivityTopicItems();
      this.getActivitySkillItems();
      this.getActivityWordItems();
      this.redirectOnFilterChange();
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.deleteDialog = true;
    },
    stopDeleteAction() {
      this.selected = null;
      this.deleteDialog = false;
    },
    previewActivity(activity) {
      this.preview = activity;
      if (
        (activity.resourceType === "DOCUMENT" ||
          activity.resourceType === "ACTIVITY") &&
        !activity.typeH5p
      ) {
        this.pdfDialog = true;
      } else {
        this.previewDialog = true;
      }
    },
    closePreview() {
      if (
        (this.preview.resourceType === "DOCUMENT" ||
          this.preview.resourceType === "ACTIVITY") &&
        !this.preview.typeH5p
      ) {
        this.pdfDialog = false;
      } else {
        this.previewDialog = false;
      }
      this.preview = null;
    },
    async deleteH5p() {
      this.$log.debug(
        "ContentService: deleting " + this.selected.content.fileName + "..."
      );
      const result = await fetch("/h5p/" + this.selected.content.fileName, {
        method: "delete",
      });
      if (!result.ok) {
        throw new Error(
          "Error while deleting content: " + result.status,
          result.statusText,
          await result.text()
        );
      }
    },
    deleteActivity() {
      this.deleteLoading = true;
      ActivityEntityRepository.delete(this.selected.id)
        .then(() => {
          if (this.selected.content && this.selected.typeH5p) {
            this.deleteH5p();
          }
          if (this.entitiesPage.page !== 1 && this.items.length === 1) {
            this.entitiesPage.page -= 1;
          } else {
            this.getItems();
          }
        })
        .catch(() =>
          this.$log.debug("Error deleting activity " + this.selected.id)
        )
        .finally(() => {
          this.deleteLoading = false;
          this.stopDeleteAction();
        });
    },
    filterTopics(list, lost) {
      return list.filter((t) => lost.includes(t));
    },
    getChipBackColor,
    getChipTextColor,
    translate,
  },
};
</script>
