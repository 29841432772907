<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-block d-lg-none" cols="auto">
        <v-btn icon @click="navDrawer = !navDrawer">
          <v-icon> mdi-menu </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <navigation-drawer
        class="d-block d-lg-none"
        :absolute="true"
        :temporary="true"
        :items="navBarCalculated"
        v-model="navDrawer"
      >
      </navigation-drawer>
      <v-col class="d-none d-lg-block" md="auto">
        <div>
          <div v-if="!isMe">
            <h3 class="mb-4 pl-2" v-if="!isMe">
              {{ entity.name }} {{ entity.surname }}
              <v-btn
                v-if="chatId"
                class="mb-4"
                color="accent"
                icon
                tile
                @click.stop="$EventBus.$emit('open-chat', chatId)"
              >
                <icon
                  name="chat-simple"
                  width="28"
                  height="28"
                  title="chat"
                ></icon>
              </v-btn>
            </h3>
          </div>
          <navigation-drawer :items="navBarCalculated" stateless :value="true">
          </navigation-drawer>
        </div>
      </v-col>
      <v-col>
        <v-row v-if="isAdmin && isCandidate">
          <approve-alert
            :alert-message="$t('student.messages.alert_message')"
            :approved-message="$t('student.messages.alert_approved')"
            :rejected-message="$t('student.messages.alert_rejected')"
            :status="alertStatus"
            :loading="loading"
            @approve="approve()"
          ></approve-alert>
        </v-row>
        <v-row>
          <router-view
            v-if="(studentId || $route.params.id) && userId"
            :studentId="
              $route.params.id
                ? parseInt($route.params.id)
                : parseInt(studentId)
            "
            :userId="userId"
          ></router-view>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApproveAlert from "@/components/approve-alert/ApproveAlert";
import NavigationDrawer from "@/components/navigation-drawer/NavigationDrawer";
import {
  navBarItemsAdmin,
  navBarItemsProfile,
  navBarItemsTutor,
} from "./navBar/navBarItems";
import checkInvalidID from "@/common/checkInvalidID";
import { isMe, mapAuthGetter } from "@/common/mapAuthGetter";
import { setShowed } from "@/common/navigationDrawer-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ChatRepository from "@/repositories/components/ChatRepository";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

const fullAge = 18;

export default {
  name: "StudentDetail",
  components: {
    NavigationDrawer,
    ApproveAlert,
  },
  data() {
    return {
      navDrawer: false,
      entity: {},
      loading: false,
      alertStatus: "PENDING",
      studentId: null,
      userId: null,
      chatId: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isTutor"]),
    isMe() {
      return isMe(this.entity.login);
    },
    isCandidate() {
      return this.entity.authorities
        ? this.entity.authorities.indexOf("ROLE_STUDENT_CANDIDATE") !== -1
        : false;
    },
    isAdult() {
      if (this.entity.birthdate) {
        let birthdate = this.entity.birthdate;
        let today = new Date();
        let monthDiff = today.getMonth() - (birthdate[1] - 1);
        let age = today.getFullYear() - birthdate[0];
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() > birthdate[2])
        )
          age--;
        return age >= fullAge;
      } else return true;
    },
    navBarCalculated() {
      if (this.isAdmin) {
        if (this.isAdult) {
          return setShowed(navBarItemsAdmin, "student.navbar.tutors", false);
        } else {
          return navBarItemsAdmin;
        }
      } else if (this.isTutor) {
        return navBarItemsTutor;
      } else {
        if (!this.entity.id) return [];
        // hide entries if user is non-adult student
        else {
          if (this.isAdult) {
            return setShowed(
              navBarItemsProfile,
              "student.navbar.tutors",
              false
            );
          } else {
            let minorAgeItems = setShowed(
              navBarItemsProfile,
              "student.navbar.wallet",
              false
            );
            minorAgeItems = setShowed(
              minorAgeItems,
              "student.navbar.documents",
              false
            );
            minorAgeItems = setShowed(
              minorAgeItems,
              "student.navbar.feedback",
              false
            );
            return setShowed(
              minorAgeItems,
              "student.navbar.registrations",
              false
            );
          }
        }
      }
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      StudentEntityRepository.getByUser()
        .then((response) => {
          this.entity = response;
          this.studentId = response.id;
          this.userId = response.userId;
        })
        .catch((err) => {
          this.$log.debug("Error fetching student for current user");
          checkInvalidID(err);
        });
    } else {
      this._fetchData(this.$route.params.id);
    }
  },
  methods: {
    _fetchData(id) {
      return StudentEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          this.userId = response.userId;
          if (this.isAdmin) {
            this.getUserAdminsChat();
          }
        })
        .catch((err) => {
          this.$log.debug("Error fetching student with ID " + id);
          checkInvalidID(err);
        });
    },
    getUserAdminsChat() {
      const options = {
        params: {
          userIds: this.userId,
          type: "USER_ADMINS",
        },
      };
      ChatRepository.getChannelsByUserAndType(options)
        .then((response) => {
          if (response.length > 0) {
            this.chatId = response[0].id;
          }
        })
        .catch(() => {
          this.$log.debug(
            "Error gettin USER_ADMINS chat for user with id " + this.userId
          );
        });
    },
    approve() {
      this.loading = true;
      StudentEntityRepository.approve(this.$route.params.id)
        .then(() => {
          this.alertStatus = "APPROVED";
        })
        .catch(() =>
          this.$log.debug(
            "Error approving student with ID " + this.$route.params.id
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
