<template>
  <div class="login-card d-flex flex-column align-center">
    <v-card class="mt-10 mb-10 ml-3 mr-3">
      <v-card-title class="justify-center">
        <span class="headline no-split-words pb-4 pt-4">
          {{ $t($route.meta.label) }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap pl-4 pr-4">
          <v-btn
            class="ml-1 mr-1 mb-1 primary"
            small
            :to="{ name: 'Company Register' }"
          >
            {{ $t("account.form.validation.register.company") }}
          </v-btn>
          <v-btn
            class="ml-1 mr-1 mb-1 primary"
            small
            :to="{ name: 'Register' }"
          >
            {{ $t("account.form.validation.register.name") }}
          </v-btn>
          <v-btn
            class="ml-1 mr-1 mb-1"
            small
            :to="{ name: 'ResetPasswordRequest' }"
            >{{ $t("account.actions.forgot_password") }}
          </v-btn>
        </div>

        <v-form class="pt-4 pb-4" v-model="validForm" ref="form">
          <v-row align="center" justify="center">
            <v-col cols="12" md="10">
              <v-text-field
                v-model="username"
                autocomplete="username"
                name="username"
                type="text"
                :maxlength="50"
                prepend-inner-icon="person"
                :label="$t('profile.fields.login')"
                class="required"
                :rules="[
                  (v) => !!v || $t('account.form.validation.username_required'),
                  (v) =>
                    !v ||
                    this.regex.LOGIN_REGEX.test(v) ||
                    $t('user_management.loginValid'),
                ]"
                @input="username = username.toLowerCase()"
              ></v-text-field>
              <v-text-field
                v-model="password"
                autocomplete="current-password"
                name="password"
                maxlength="100"
                prepend-inner-icon="vpn_key"
                :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                :label="$t('account.form.password')"
                class="required"
                :rules="[
                  (v) => !!v || $t('account.form.validation.password_required'),
                  (value) =>
                    value.length <= 100 ||
                    $t('account.form.validation.password_less_chars'),
                  (value) =>
                    value.length > 3 ||
                    this.$t('account.form.validation.password_more_chars'),
                ]"
                :type="hidePassword ? 'password' : 'text'"
                @click:append="() => (hidePassword = !hidePassword)"
                @keydown.enter="userLogin()"
              ></v-text-field>
              <v-checkbox
                v-model="remember_me"
                :label="$t('account.actions.remember_me')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="10" class="text-center">
              <v-btn class="primary" @click="userLogin">
                {{ $t("account.actions.log_in") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import auth from "@/common/auth";
import regex from "@/common/regex-validation";

export default {
  data() {
    return {
      validForm: true,
      username: "",
      password: "",
      hidePassword: true,
      remember_me: false,
      regex,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$refs.form.resetValidation();
    },
  },
  methods: {
    async userLogin() {
      if (this.validForm) {
        try {
          await auth.login({
            login: this.username,
            password: this.password,
            remember_me: this.remember_me,
          });
          this.$router.goToUserHome();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>
<style></style>
