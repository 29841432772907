<template>
  <v-dialog persistent :width="dialogWith" :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline" :class="titleClass">
        <v-icon class="mr-4" v-if="titleIcon" :class="titleClass">
          {{ titleIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text v-if="!loading" class="mt-4">
        <div style="white-space: pre-line">{{ content }}</div>
      </v-card-text>
      <v-card-text v-else>
        <v-row class="my-4" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn :class="cancelClass" :disabled="loading" @click="emit('cancel')">
          {{ cancelText }}
        </v-btn>
        <!-- Only sends the event if the component is not loading -->
        <v-btn
          class="mx-4"
          :class="submitClass"
          :disabled="loading"
          @click="emit('submit')"
          v-if="submitText"
        >
          {{ submitText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";

/**
 * Events emitted: cancel, submit
 */
export default {
  name: "ModalDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    titleIcon: {
      type: String,
      required: false,
      default: null,
    },
    titleClass: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: false,
      default: i18n.t("actions.cancel"),
    },
    cancelClass: {
      type: String,
      required: false,
    },
    submitText: {
      type: String,
      required: false,
      default: null,
    },
    submitClass: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogWith: {
      type: String,
      required: false,
      default: "500",
    },
  },
  methods: {
    emit(eventName) {
      if (!this.loading) {
        this.$emit(eventName);
      }
    },
  },
};
</script>
