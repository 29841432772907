var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',[_c('v-checkbox',{staticClass:"mt-0 no-split-words",attrs:{"dense":"","label":_vm.$t('edition.filters.show_past_courses')},on:{"change":_vm.redirectOnFilterChange},model:{value:(_vm.showPast),callback:function ($$v) {_vm.showPast=$$v},expression:"showPast"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.registrations,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems,"item-class":_vm.rowClass},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.entityDetail},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("registration.status." + (item.status)), { date: _vm.$d(_vm.dateArrayToDate(item.withdrawReqDate)), }))+" ")])]}},{key:"item.productType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("registration.product_type." + (item.productType))))+" ")])]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("registration.payment_type." + (item.paymentType))))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$n(item.price, "currency"))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status == 'ACTIVE' && _vm.isAdult)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectEntityToDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1),(_vm.dropRegistrationDialog)?_c('withdraw-dialog',{attrs:{"registration":_vm.selectedRegistration},on:{"requested":_vm.closeDropRegistrationDialog,"cancel":_vm.closeDropRegistrationDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }