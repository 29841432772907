var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"6"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t("companyStudent.headers.form"))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"success ml-3",attrs:{"disabled":!_vm.validForm},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("save")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","type":"text","rules":[
              function (v) { return !!v || _vm.$t('companyStudent.form.validation.name_required'); } ],"label":_vm.$t('profile.fields.name')},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","type":"text","rules":[
              function (v) { return !!v || _vm.$t('companyStudent.form.validation.email_required'); },

              function (v) { return !v ||
                this$1.regex.EMAIL_REGEX.test(v) ||
                _vm.$t('companyStudent.form.validation.emailPattern'); } ],"label":_vm.$t('profile.fields.main_email')},model:{value:(_vm.entity.email),callback:function ($$v) {_vm.$set(_vm.entity, "email", $$v)},expression:"entity.email"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }