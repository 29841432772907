<template>
  <v-footer color="white" padless inset>
    <v-container class="pt-2 pb-0" fluid>
      <v-row no-gutters>
        <v-col cols="12" md="4" class="text-center text-md-left">
          <div class="image-logo">
            <img
              v-if="!coloredImages"
              width="158"
              height="64"
              class="image-logo--off"
              :src="require('/public/img/logos/logo-xunta-off.png')"
            />
            <img
              width="158"
              height="64"
              :class="{ 'image-logo--on': !coloredImages }"
              :src="require('/public/img/logos/logo-xunta-on.png')"
            />
          </div>
          <div class="image-logo">
            <img
              v-if="!coloredImages"
              width="68"
              height="64"
              class="image-logo--off"
              :src="require('/public/img/logos/ue-off.png')"
            />
            <img
              width="68"
              height="64"
              :class="{ 'image-logo--on': !coloredImages }"
              :src="require('/public/img/logos/ue-on.png')"
              contain
            />
          </div>
        </v-col>
        <v-col cols="12" md="8" class="text-center text-md-right pr-md-6">
          <div class="image-logo">
            <img
              v-if="!coloredImages"
              width="107"
              height="64"
              class="image-logo--off"
              :src="require('/public/img/logos/igape-off.png')"
            />
            <img
              width="107"
              height="64"
              :class="{ 'image-logo--on': !coloredImages }"
              :src="require('/public/img/logos/igape-on.png')"
            />
          </div>
          <div class="image-logo">
            <img
              v-if="!coloredImages"
              width="157"
              height="64"
              class="image-logo--off"
              :src="require('/public/img/logos/xacobeo-off.png')"
            />
            <img
              width="157"
              height="64"
              :class="{ 'image-logo--on': !coloredImages }"
              :src="require('/public/img/logos/xacobeo-on.png')"
            />
          </div>
          <div class="image-logo">
            <img
              v-if="!coloredImages"
              width="86"
              height="64"
              class="image-logo--off"
              :src="require('/public/img/logos/galicia-off.png')"
            />
            <img
              width="86"
              height="64"
              :class="{ 'image-logo--on': !coloredImages }"
              :src="require('/public/img/logos/galicia-on.png')"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="build-number">v. {{ buildNumber }}</div>
  </v-footer>
</template>

<script>
import buildNumber from "@/buildNumber";

export default {
  name: "Footer",
  props: {
    coloredImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buildNumber: buildNumber,
    };
  },
};
</script>

<style scoped>
.build-number {
  font-size: 0.75rem;
  color: #999;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
</style>
