import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/tutors";

export default {
  async getByKey(key) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/findByKey`, {
        params: {
          key: key,
        },
      })
    ).data;
  },

  async getByStudent(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/student/${id}`)).data;
  },

  async linkToStudent(userId, key) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/link-to-student`, null, {
        params: {
          user_id: userId,
          key: key,
        },
      })
    ).data;
  },

  async newLinkToStudent(tutorUserId, studentId, relationshipId) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/new-link-to-student`, null, {
        params: {
          tutorUserId: tutorUserId,
          studentId: studentId,
          relationshipId: relationshipId,
        },
      })
    ).data;
  },

  async save(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
  },

  async deleteFromTutor(tutorUserId, studentId) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/delete-from-tutor`, null, {
        params: {
          tutorUserId: tutorUserId,
          studentId: studentId,
        },
      })
    ).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
