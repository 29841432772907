var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"6"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-center text-md-right",attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{staticClass:"d-md-inline-block mr-10 mt-5",attrs:{"label":_vm.$t('user_management.checkbox')},on:{"change":_vm.onSearchChange},model:{value:(_vm.activatedCheckbox),callback:function ($$v) {_vm.activatedCheckbox=$$v},expression:"activatedCheckbox"}})],1),_c('v-col',{staticClass:"text-center text-md-right",attrs:{"cols":"12","md":"2"}},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('user_management.search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.users,"server-items-length":_vm.totalItems,"options":_vm.pagination,"search":_vm.search,"loading":_vm.loading},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateTimeWithTz")(item.created_date))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showResetpasswordDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lock-reset")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("account.actions.reset_password")))])])]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"green"},on:{"change":function($event){return _vm.activate(item)}},model:{value:(item.activated),callback:function ($$v) {_vm.$set(item, "activated", $$v)},expression:"item.activated"}})]}},{key:"item.authorities",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.authorities),function(auth){return _c('v-chip',{key:auth,attrs:{"text-color":_vm.stringToColourText(auth),"color":_vm.stringToColour(auth)}},[_vm._v(" "+_vm._s(_vm.$t(("user_management.roles." + auth)))+" ")])}),1)]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("zonedDateFormat")(item.createdDate)))])]}}],null,true)})],1)],1),_c('modal-dialog',{attrs:{"dialog":_vm.resetPasswordDialog,"titleClass":"primary white--text","titleIcon":"mdi-lock-reset","submitClass":"success","submitText":_vm.$t('user_management.continue'),"title":_vm.$t('user_management.resetUserPassword'),"content":_vm.$t('user_management.confirmation')},on:{"cancel":function($event){_vm.resetPasswordDialog = false},"submit":function($event){return _vm.resetUserPassword()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }