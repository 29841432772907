<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col order="1" order-md="2" class="text-right">
        <v-btn color="success ml-2" @click="newFile">
          <v-icon>add</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <file-list :userId="userId"></file-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import FileList from "@/mockups/components/FileList";

export default {
  name: "StudentDocumentsTab",
  components: { FileList },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isTutor"]),
  },
  methods: {
    newFile() {
      if (this.isAdmin) {
        this.$router.push({
          name: "StudentDocumentFormTab",
          params: { backPrevious: true },
        });
      } else if (this.isTutor) {
        this.$router.push({
          name: "Tutor StudentDocumentFormTab",
          params: { backPrevious: true },
        });
      } else {
        this.$router.push({
          name: "ProfileStudentDocumentFormTab",
          params: { backPrevious: true },
        });
      }
    },
  },
};
</script>
