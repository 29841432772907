import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "/dashboard";

export default {
  async getForAdmin() {
    return (await HTTP.get(`${RESOURCE_NAME}/admin`)).data;
  },
  async getForTeacher() {
    return (await HTTP.get(`${RESOURCE_NAME}/teacher`)).data;
  },
  async getForStudent() {
    return (await HTTP.get(`${RESOURCE_NAME}/student`)).data;
  },
  async getForCompany() {
    return (await HTTP.get(`${RESOURCE_NAME}/company`)).data;
  },
  async getForTutor() {
    return (await HTTP.get(`${RESOURCE_NAME}/tutor`)).data;
  },
  async getForHeadmaster() {
    return (await HTTP.get(`${RESOURCE_NAME}/headmaster`)).data;
  },
  async getForContentEditor(options = {}) {
    return (await HTTP.get(`${RESOURCE_NAME}/contentEditor`, options)).data;
  },
};
