<template>
  <v-alert
    prominent
    outlined
    border="left"
    icon="mdi-alert"
    color="warning"
    text
    dismissible
    v-if="!hasAll"
  >
    <ul>
      <li v-if="!hasDegrees">
        <span class="text-overline">
          {{ $t("teacher.dashboard.information_panel.degrees") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileTeacherEducationTab' }"
        >
          {{ $t("teacher.dashboard.information_panel.link.diplomas") }}
        </v-btn>
      </li>
      <li v-if="!hasAvailabilities">
        <span class="text-overline">
          {{ $t("teacher.dashboard.information_panel.availabilities") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileTeacherSchedulesTab' }"
        >
          {{ $t("teacher.dashboard.information_panel.link.schedule") }}
        </v-btn>
      </li>
      <li v-if="!hasLevels">
        <span class="text-overline">
          {{ $t("teacher.dashboard.information_panel.levels") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileTeacherEducationTab' }"
        >
          {{ $t("teacher.dashboard.information_panel.link.levels") }}
        </v-btn>
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: {
    hasDegrees: {
      type: Boolean,
      required: true,
    },
    hasAvailabilities: {
      type: Boolean,
      required: true,
    },
    hasLevels: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasAll() {
      return this.hasDegrees && this.hasAvailabilities && this.hasLevels;
    },
  },
};
</script>

<style scoped>
li {
  text-align: initial !important;
}
.v-btn {
  margin-left: 4px;
}
</style>
