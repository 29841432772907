<template>
  <v-container>
    <a ref="hiddenDownloader" class="d-none" />
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1s"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
        >
          <template v-slot:[`item.teacher`]="{ item }">
            <router-link
              v-if="item.teacher"
              :to="{
                name: 'TeacherEducationTab',
                params: { id: item.teacher.id },
              }"
            >
              {{ teacherFullName(item.teacher) }}
            </router-link>
          </template>
          <template v-slot:[`item.file`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  :disabled="item.file === null"
                  icon
                  small
                  @click="downloadFile(item.id)"
                  :loading="loadingDownloadFile"
                >
                  <v-icon>preview</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("merit.actions.download") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="success"
                  :loading="loading"
                  icon
                  small
                  @click="validateDiploma(item)"
                >
                  <v-icon small> thumb_up </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("merit.actions.approve") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import { downloadFile } from "@/common/file-utils";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const MeritEntityRepository = RepositoryFactory.get("MeritEntityRepository");

export default {
  name: "PendingDiplomasList",
  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
      loadingDownloadFile: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("merit.prop.teacher"),
          value: "teacher",
        },
        {
          text: this.$t("merit.prop.name"),
          value: "name",
        },
        {
          text: this.$t("merit.prop.organization"),
          value: "organization",
        },
        {
          text: this.$t("merit.prop.year"),
          value: "year",
        },
        {
          text: this.$t("merit.prop.file"),
          value: "file",
          sortable: false,
        },
        {
          text: this.$t("merit.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.onPaginationChange();
      },
      deep: true,
    },
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      const sortMapping = {
        teacher: "teacher.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      return MeritEntityRepository.getAllPending(options)
        .then((res) => {
          this.items = res.content;
          this.totalItems = res.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching teacher with ID " + id))
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this._fetchData();
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
    teacherFullName(teacher) {
      return teacher.name + " " + teacher.surname;
    },
    downloadFile(id) {
      this.loadingDownloadFile = true;
      return MeritEntityRepository.downloadMeritFile(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("merit.messages.download-file"),
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t("merit.error.file-not-loaded"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.loadingDownloadFile = false));
    },
    validateDiploma(item) {
      this.loading = true;
      MeritEntityRepository.changeStatus(item.id, "CHECKED")
        .then(() => {
          this.$notify({
            title: this.$t("merit.messages.approve-validation"),
            type: "success",
          });
          return this._fetchData();
        })
        .catch(() =>
          this.$log.debug(
            "Error changing status of merit with ID " + item.id + " to CHECKED"
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
