var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-datatable"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.lectureEvaluations,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.date,"medium"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog('approveDialog', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" thumb_up ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.approve")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog('rejectDialog', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" thumb_down ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.reject")))])])]}}],null,true)})],1)],1),_c('modal-dialog',{attrs:{"dialog":_vm.approveDialog,"title":_vm.$t('lectureEvaluation.headers.approveDialog'),"titleClass":"success white--text","titleIcon":"warning","submitClass":"success","submitText":_vm.$t('actions.approve'),"cancelText":_vm.$t('actions.cancel'),"content":_vm.$t('lectureEvaluation.messages.approve'),"retain-focus":false},on:{"cancel":function($event){_vm.approveDialog = false},"submit":function($event){return _vm.updateState('PUBLIC')}}}),_c('modal-dialog',{attrs:{"dialog":_vm.rejectDialog,"title":_vm.$t('lectureEvaluation.headers.rejectDialog'),"titleClass":"error white--text","titleIcon":"warning","submitClass":"error","submitText":_vm.$t('actions.reject'),"cancelText":_vm.$t('actions.cancel'),"content":_vm.$t('lectureEvaluation.messages.reject'),"retain-focus":false},on:{"cancel":function($event){_vm.rejectDialog = false},"submit":function($event){return _vm.updateState('REJECTED')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }