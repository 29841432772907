<template>
  <v-container fluid>
    <student-form
      :back-btn="false"
      include-tutors
      v-if="!loading"
      v-model="entity"
      @save="save"
    ></student-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>
<script>
import auth from "@/common/auth";
import StudentForm from "@/mockups/student/components/StudentForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { deleteTempFile } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
export default {
  name: "StudentGuestForm",
  components: { StudentForm, LoadingPage },
  data() {
    return {
      entity: {
        name: auth.getUser().firstName,
        surname: auth.getUser().lastName,
        mainEmail: auth.getUser().email,
        langKey: auth.getUser().langKey.toLowerCase(),
      },
      loading: false,
    };
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  methods: {
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      StudentEntityRepository.save(this.entity)
        .then(() => {
          this.entity.photo = null;
          auth.reloadUserData().finally(() => {
            this.$router.goToUserHome();
          });
        })
        .catch(() => this.$log.debug("Error saving student: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
