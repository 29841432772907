var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center text-h2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.incompleteActivityCount),"content":_vm.incompleteActivityCount}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"max-height",attrs:{"elevation":hover ? 12 : 2,"to":{
                  name: 'Activity List',
                  query: {
                    incomplete: 'true',
                    languageFilter: _vm.language,
                  },
                },"loading":_vm.loading}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-notebook-edit ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("contentEditor.dashboard.incomplete_activities"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.incompleteCourseCount),"content":_vm.incompleteCourseCount}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{staticClass:"max-height",attrs:{"elevation":hover ? 12 : 2,"to":{
                  name: 'Course List',
                  query: { incomplete: 'true', languageFilter: _vm.language },
                },"loading":_vm.loading}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-school ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("contentEditor.dashboard.incomplete_courses"))+" ")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-badge',{staticClass:"dashboard-card",attrs:{"color":_vm.getBadgeColor(_vm.incompleteLevelTestCount),"content":_vm.incompleteLevelTestCount}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{staticClass:"max-height",attrs:{"elevation":hover ? 12 : 2,"to":{
                  name: 'LevelTest List',
                  query: { incomplete: 'true', language: _vm.language },
                },"loading":_vm.loading}},[_c('v-card-title',[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-clipboard-text ")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("contentEditor.dashboard.incomplete_level_test"))+" ")])])],1)]}}])})],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[(_vm.lastCourse)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-6 mx-md-0",attrs:{"to":{
              name: 'Course Detail',
              params: { id: _vm.lastCourse.id, backPrevious: true },
            },"loading":_vm.loading}},[_c('v-card-title',{staticClass:"px-0 pt-2"},[_c('v-col',{staticClass:"text-center px-0 py-0"},[_vm._v(" "+_vm._s(_vm.$t("contentEditor.dashboard.last_course"))+" ")])],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.lastCourse.name)+" ")])])],1)],1):_vm._e(),(_vm.lastActivity)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-6 mx-md-0",attrs:{"to":{
              name: 'Activity Detail',
              query: { incomplete: 'true' },
              params: { id: _vm.lastActivity.id, backPrevious: true },
            },"loading":_vm.loading}},[_c('v-card-title',{staticClass:"px-0 pt-2"},[_c('v-col',{staticClass:"text-center px-0 py-0"},[_vm._v(" "+_vm._s(_vm.$t("contentEditor.dashboard.last_activity"))+" ")])],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.lastActivity.name)+" ")])])],1)],1):_vm._e()],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"5"}},[_c('language-and-all-selector',{attrs:{"all":"","label":_vm.$t('contentEditor.dashboard.language_selector_label')},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }