// Admin profile access
const navBarItemsAdmin = [
  {
    title: "company.navbar.profile",
    icon: "mdi-account",
    link: "CompanyView Detail",
    notification: false,
    showed: true,
  },
  {
    title: "company.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "company.navbar.balance",
        icon: "mdi-cash",
        link: "CompanyView Balance",
        notification: false,
        showed: true,
      },
      {
        title: "company.navbar.movements",
        icon: "mdi-swap-horizontal",
        link: "CompanyView Movements",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "company.navbar.students",
    icon: "mdi-account-group",
    link: "CompanyView Students",
    notification: false,
    showed: true,
  },
];

const navBarItemsProfile = [
  {
    title: "company.navbar.profile",
    icon: "mdi-account",
    link: "ProfileCompanyView Detail",
    notification: false,
    showed: true,
  },
  {
    title: "company.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "company.navbar.balance",
        icon: "mdi-cash",
        link: "ProfileCompanyView Balance",
        notification: false,
        showed: true,
      },
      {
        title: "company.navbar.movements",
        icon: "mdi-swap-horizontal",
        link: "ProfileCompanyView Movements",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "company.navbar.students",
    icon: "mdi-account-group",
    link: "ProfileCompanyView Students",
    notification: false,
    showed: true,
  },
];

export { navBarItemsAdmin, navBarItemsProfile };
