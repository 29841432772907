<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <v-btn icon @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" class="text-center">
            {{ $t("levelTestRequest.program_dialog.title") }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <autocomplete
            v-model="teacher"
            :clearable="false"
            :items="availableTeachers"
            :item-text="getTeacherName"
            :label="$t('levelTestRequest.program_dialog.teacher')"
            :loading="loadingTeachers"
            :rules="[
              (v) =>
                !!v ||
                $t('levelTestRequest.program_dialog.errors.teacher_required'),
            ]"
            return-object
            outlined
            @change="updateAvailabilities"
          ></autocomplete>
        </v-row>
        <v-row v-if="teacher" no-gutters>
          <v-col cols="12">
            <v-row align="center" justify="space-between" no-gutters>
              <v-col cols="12" md="9" v-if="selectedSlot">
                <span>
                  {{ $t("levelTestRequest.program_dialog.selected_slot") }}:
                </span>
                <span>
                  {{ $d(new Date(selectedSlot.start), "long") }}
                  -
                  {{ $d(new Date(selectedSlot.end), "time") }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn color="success" :disabled="!selectedSlot" @click="save">
                  <v-icon small> save </v-icon>
                  <span>{{ $t("actions.save") }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters align="start">
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-progress-circular
                v-if="loadingAvailabilities"
                indeterminate
                color="primary"
              >
              </v-progress-circular>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-sheet :height="height">
              <v-calendar
                v-model="focus"
                color="primary"
                :events="availabilities"
                :event-color="getEventColor"
                :interval-height="25"
                :interval-minutes="30"
                :interval-count="48"
                type="week"
                ref="calendar"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                @click:event="selectSlot"
                :locale="$i18n.locale"
              >
                <template v-slot:event="{ event }">
                  <div class="event-div">
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols="12">
                        <span>
                          {{ event.name }}
                        </span>
                      </v-col>
                      <v-col cols="12" v-if="event.checked">
                        <v-icon small>mdi-check</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  formatDateInverse,
  utcDateArrayToDateWithTimezoneFormatted,
} from "@/common/conversion-utils";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
const LevelTestRequestEntityRepository = RepositoryFactory.get(
  "LevelTestRequestEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
export default {
  components: { Autocomplete },
  props: {
    levelTestRequest: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: "550",
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      availabilities: [],
      availableTeachers: [],
      dialog: false,
      focus: new Date().toISOString().slice(0, 10),
      loadingAvailabilities: false,
      loadingTeachers: false,
      selectedSlot: null,
      teacher: null,
    };
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
      if (val) {
        this._fetchTeachers();
      } else {
        this.teacher = null;
      }
    },
    value: {
      handler(newVal) {
        this.dialog = newVal;
      },
      immediate: true,
    },
    teacher() {
      if (this.teacher) {
        this.$nextTick(() => {
          this.$refs.calendar.scrollToTime("09:00");
        });
      }
    },
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    _computeEndDate(limitDate) {
      if (limitDate) {
        return limitDate
          .map((el) => (String(el).length < 2 ? "0" + el : String(el)))
          .join("-");
      } else {
        return formatDateInverse(new Date().setDate(new Date().getDate() + 7));
      }
    },
    _fetchTeachers() {
      this.loadingTeachers = true;

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const endDate = this._computeEndDate(this.levelTestRequest.limitDate);

      return TeacherEntityRepository.getByLanguageAndStudentAvailabilities(
        this.levelTestRequest.level.language.id,
        this.levelTestRequest.studentId,
        formatDateInverse(today),
        endDate
      )
        .then((data) => {
          this.availableTeachers = data;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching teachers for language with ID " +
              this.levelTestRequest.level.language.id +
              " and student with ID " +
              this.levelTestRequest.student.id
          )
        )
        .finally(() => (this.loadingTeachers = false));
    },
    _formatAvailabilities(data) {
      return data.map((el) => {
        return {
          name: this.$t("levelTestRequest.program_dialog.available"),
          start: utcDateArrayToDateWithTimezoneFormatted(el.start, null),
          end: utcDateArrayToDateWithTimezoneFormatted(el.end, null),
          startArray: el.start,
          endArray: el.end,
          checked: false,
        };
      });
    },
    getEventColor(event) {
      return event.checked ? "green" : "primary";
    },
    getTeacherName(item) {
      return item.name + " " + item.surname;
    },
    save() {
      this.loadingLecture = true;
      return LectureEntityRepository.createLevelTest({
        startDate: this.selectedSlot.startArray,
        endDate: this.selectedSlot.endArray,
        studentId: this.levelTestRequest.studentId,
        teacherId: this.teacher.id,
        levelTestRequestId: this.levelTestRequest.id,
      })
        .then(() => {
          this.dialog = false;
          this.$emit("scheduled", this.levelTestRequest.id);
          this.$notify({
            title: this.$t("lecture.messages.level_test_programmed"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
        })
        .catch(() => this.$log.debug("Fail to save lecture"))
        .finally(() => (this.loadingLecture = false));
    },
    selectSlot(event) {
      if (this.selectedSlot) this.selectedSlot.checked = false;
      event.event.checked = true;
      this.selectedSlot = event.event;
    },
    updateAvailabilities() {
      this.selectedSlot = null;
      this.loadingAvailabilities = true;

      // Calcular el día de mañana
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const endDate = this._computeEndDate(this.levelTestRequest.limitDate);
      return LevelTestRequestEntityRepository.getCommonAvailabilities(
        this.teacher.id,
        this.levelTestRequest.studentId,
        formatDateInverse(today),
        endDate
      )
        .then((data) => {
          this.availabilities = this._formatAvailabilities(data);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching availabilities for teacher with ID " +
              this.teacher.id +
              " and student with ID " +
              this.levelTestRequest.studentId
          )
        )
        .finally(() => (this.loadingAvailabilities = false));
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
</style>
