<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-row>
          <!-- wallets information -->
          <v-col cols="12" md="8" class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="6" class="text-center">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      class="mt-12"
                      :elevation="hover ? 12 : 2"
                      @click="navigate('ProfileTutorView Balance')"
                      :loading="loading"
                    >
                      <v-card-title>
                        <v-col class="py-0 text-center">
                          <v-icon x-large color="primary"> mdi-cash </v-icon>
                          <br />
                          <span>
                            {{ $n(walletBalance, "currency") }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text class="py-0 pb-6">
                        <span class="text-overline">
                          {{ $t("tutor.dashboard.wallet_balance") }}
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-row v-if="loading" class="mt-12" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <v-col
                  v-else
                  cols="12"
                  md="6"
                  v-for="(student, i) in studentsInfo"
                  :key="i"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      class="mt-6"
                      :elevation="hover ? 12 : 2"
                      @click="
                        navigate('Tutor StudentBalanceTab', student.studentId)
                      "
                    >
                      <v-card-title>
                        <v-col class="py-0 text-center">
                          <span>
                            <v-icon x-large color="primary">mdi-account</v-icon>
                            {{ student.studentName }}
                          </span>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <span class="text-overline">
                          {{ $t("tutor.dashboard.wallet_balance") }}:
                        </span>
                        <span>
                          {{ $n(student.walletBalance, "currency") }}
                        </span>
                        <br />
                        <span class="text-overline">
                          {{ $t("student.dashboard.next_payment") }}:
                        </span>
                        <span class="payment">
                          {{ "-" }}
                          {{ $n(student.nextPayment, "currency") }}
                        </span>
                        <span>
                          ({{ lastDayOfMonth | dateTimeWithTz("short") }})
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- students next lectures -->
          <v-col cols="12" md="4" class="text-center">
            <span class="text-h6">
              {{ $t("student.dashboard.next_lectures") }}
              <v-icon
                v-if="!expandNextLectures"
                @click="expandNextLectures = true"
              >
                expand_more
              </v-icon>
              <v-icon v-else @click="expandNextLectures = false">
                expand_less
              </v-icon>
            </span>
            <v-row v-if="loading" class="mt-12" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <v-container v-else>
              <v-row v-for="(student, i) in studentsInfo" :key="i">
                <transition name="slide-fade">
                  <v-container v-if="expandNextLectures" fluid>
                    <v-row
                      v-for="lecture in student.nextLectures"
                      :key="lecture.id"
                      class="mt-2"
                      justify="center"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :elevation="hover ? 12 : 2"
                          :class="lecture.state === 'DOING' ? 'doing' : ''"
                          width="300px"
                          @click.stop="
                            navigate(
                              'Lecture Detail',
                              lecture.id,
                              student.studentId
                            )
                          "
                        >
                          <v-card-title class="py-0">
                            <v-col class="text-center">
                              <span class="no-split-words">
                                {{ lecture.productName }}
                              </span>
                            </v-col>
                          </v-card-title>
                          <v-card-text>
                            <span class="text-overline">
                              <v-icon>mdi-human-male-board</v-icon>
                              {{ lecture.teacherName }}
                            </span>
                            <br />
                            <router-link
                              :to="{
                                name: 'Tutor StudentView Detail',
                                params: { id: student.studentId },
                              }"
                            >
                              <span class="text-overline">
                                <v-icon>mdi-account</v-icon>
                                {{ student.studentName }}
                              </span>
                            </router-link>
                            <br />
                            <span class="text-overline">
                              {{ lecture.startTime | dateTimeWithTz("medium") }}
                              -
                              {{ lecture.endTime | dateTimeWithTz("time") }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-row>
                    <v-row
                      v-if="
                        !student.nextLectures ||
                        student.nextLectures.length === 0
                      "
                      class="mt-2"
                      justify="center"
                    >
                      <span>
                        {{ $t("tutor.dashboard.no_next_lectures") }}
                        {{ student.studentName }}</span
                      >
                    </v-row>
                  </v-container>
                </transition>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateToDateArray } from "@/common/conversion-utils";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");
export default {
  data() {
    return {
      walletBalance: null,
      studentsInfo: [],
      expandNextLectures: true,
      lastDayOfMonth: null,
      loading: false,
    };
  },
  created() {
    const today = new Date();
    this.lastDayOfMonth = dateToDateArray(
      new Date(today.getFullYear(), today.getMonth() + 1, 0)
    );
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      DashboardRepository.getForTutor()
        .then((data) => {
          this.walletBalance = data.walletBalance;
          this.studentsInfo = data.studentsInfo;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    navigate(routeName, entityId, studentId) {
      this.$router.push({
        name: routeName,
        params: { id: entityId, studentId: studentId, backPrevious: true },
      });
    },
  },
};
</script>

<style scoped>
.payment {
  color: red;
}
.doing {
  border: 2px solid #00a451;
}
</style>
