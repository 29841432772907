import UserManagementList from "../UserManagementList";

const routes = [
  {
    path: "/user-management/users",
    name: "UserManagementList",
    component: UserManagementList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "menuBar.userManagement",
    },
  },
];

export default routes;
