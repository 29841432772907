<template>
  <v-card class="pa-5">
    <v-row>
      <v-spacer />
      <v-icon large class="mt-n1 mr-2" @click="closePreview()">
        mdi-close-box
      </v-icon>
    </v-row>
    <div v-if="loadingContent && !thumbnail" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="loadingContent">
      <v-img :src="thumbnail"></v-img>
    </div>
    <div v-if="activity.typeH5p">
      <H5PPlayer
        :exercise="activity.content.fileName"
        @loaded="loadingContent = false"
        @error="loadingContent = false"
      />
    </div>
    <div v-if="!loadingContent && activity.resourceType === 'IMAGE'">
      <v-img :src="content" contain></v-img>
    </div>
    <div v-if="!loadingContent && activity.resourceType === 'VIDEO'">
      <video-player v-if="playerSrc" :src="playerSrc" />
    </div>
    <div v-if="!loadingContent && activity.resourceType === 'AUDIO'">
      <audio-player v-if="playerSrc" :src="playerSrc" />
    </div>
  </v-card>
</template>

<script>
import H5PPlayer from "@/mockups/content-cloud/activity/H5PPlayer.vue";
import AudioPlayer from "@/components/audio-player/AudioPlayer";
import VideoPlayer from "@/components/video-player/VideoPlayer";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ActivityPreview",
  components: { H5PPlayer, AudioPlayer, VideoPlayer },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playerSrc: null,
      thumbnail: null,
      content: {},
      loadingThumbnail: false,
      loadingContent: true,
    };
  },
  mounted() {
    if (this.activity.thumbnail != null) {
      this.downloadThumbnail();
    }
    if (this.activity.typeH5p) {
      this.loadingContent = false;
    }
    if (this.activity.resourceType === "IMAGE") {
      this.downloadFile(this.activity);
    }
    if (["VIDEO", "AUDIO"].indexOf(this.activity.resourceType) !== -1) {
      this.playerSrc = ActivityEntityRepository.getActivityContentUrl(
        this.activity.id
      );
      this.loadingContent = false;
    }
  },
  methods: {
    closePreview() {
      this.$emit("cancel");
    },
    downloadFile(activity) {
      this.loadingContent = true;
      return ActivityEntityRepository.downloadActivityFile(activity.id)
        .then((response) => {
          const blob = new Blob([response.data]);
          this.content = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching file from activity with ID " + this.activity.id
          )
        )
        .finally(() => (this.loadingContent = false));
    },
    downloadThumbnail() {
      return ActivityEntityRepository.downloadActivityThumbnail(
        this.activity.id
      )
        .then((res) => {
          const blob = new Blob([res.data]);
          this.thumbnail = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching thumbnail photo for activity with ID " +
              this.activity.id
          )
        );
    },
  },
};
</script>
<style></style>
