var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center text-h2",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-center text-h2",attrs:{"cols":"12"}},[_c('information-panel',{attrs:{"hasStudentLevelEvaluation":_vm.hasStudentLevelEvaluation,"hasLevelTestRequested":_vm.hasLevelTestRequested,"hasAvailabilities":_vm.hasAvailabilities,"hasBalance":_vm.hasBalance,"hasCourses":_vm.hasCourses}})],1),(_vm.isAdult)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-13",attrs:{"width":"75%","elevation":hover ? 12 : 2,"loading":_vm.loading},on:{"click":function($event){return _vm.navigate('ProfileStudentBalanceTab')}}},[_c('v-card-title',[_c('v-col',{staticClass:"py-0 text-center"},[_c('span',[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-cash")]),_vm._v(" "+_vm._s(_vm.$t("student.dashboard.wallet"))+" ")],1)])],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-black text-overline text-md-body-2"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.wallet_balance"))+": ")]),_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$n(_vm.walletBalance, "currency"))+" ")]),_c('br'),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.next_payment"))+": ")]),_c('span',{staticClass:"payment"},[_vm._v(" "+_vm._s("-")+" "+_vm._s(_vm.$n(_vm.nextPayment, "currency"))+" ")]),_c('span',[_vm._v(" ("+_vm._s(_vm._f("dateTimeWithTz")(_vm.lastDayOfMonth,"short"))+") ")])])],1)]}}],null,false,3023390353)})],1)],1):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":_vm.isAdult ? 6 : 12}},[(_vm.nextLevelTest)?_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.next_level_test"))+" "),(!_vm.expandNextLevelTest && _vm.nextLevelTest)?_c('v-icon',{on:{"click":function($event){_vm.expandNextLevelTest = true}}},[_vm._v(" expand_more ")]):(_vm.nextLevelTest)?_c('v-icon',{on:{"click":function($event){_vm.expandNextLevelTest = false}}},[_vm._v(" expand_less ")]):_vm._e()],1):_vm._e(),(_vm.nextLevelTest)?_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandNextLevelTest)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:_vm.nextLevelTest.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"75%","loading":_vm.loading},on:{"click":function($event){return _vm.navigate('Lecture Detail', _vm.nextLevelTest.id)}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(_vm.nextLevelTest.language ? _vm.$t("student.dashboard.level_test", { lang: _vm.$t( ("languages." + (_vm.nextLevelTest.language)) ), }) : "-")+" ")])])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_c('v-icon',[_vm._v("mdi-human-male-board")]),_vm._v(" "+_vm._s(_vm.nextLevelTest.teacherName)+" ")],1),_c('br'),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(_vm.nextLevelTest.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(_vm.nextLevelTest.endTime,"time"))+" ")]),(_vm.userCanJoin(_vm.nextLevelTest))?_c('v-container',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(_vm.nextLevelTest)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("login")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))])])],1):_vm._e()],1)],1)]}}],null,false,1982485910)})],1)],1):_vm._e()],1):_vm._e(),(_vm.nextLevelTest)?_c('br'):_vm._e(),_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.next_lectures"))+" "),(!_vm.expandNextLectures && _vm.nextLectures.length > 0)?_c('v-icon',{on:{"click":function($event){_vm.expandNextLectures = true}}},[_vm._v(" expand_more ")]):(_vm.nextLectures.length > 0)?_c('v-icon',{on:{"click":function($event){_vm.expandNextLectures = false}}},[_vm._v(" expand_less ")]):_vm._e()],1),(_vm.loading)?_c('v-row',{staticClass:"mt-12",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.expandNextLectures && !_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.nextLectures),function(lecture){return _c('v-row',{key:lecture.id,staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:lecture.state === 'DOING' ? 'doing' : '',attrs:{"elevation":hover ? 12 : 2,"width":"75%"},on:{"click":function($event){return _vm.navigate('Lecture Detail', lecture.id)}}},[_c('v-card-title',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"no-split-words"},[_vm._v(" "+_vm._s(lecture.productName)+" ")])])],1),_c('v-card-text',[_c('span',{staticClass:"text-overline"},[_c('v-icon',[_vm._v("mdi-human-male-board")]),_vm._v(" "+_vm._s(lecture.teacherName)+" ")],1),_c('br'),_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(lecture.startTime,"medium"))+" - "+_vm._s(_vm._f("dateTimeWithTz")(lecture.endTime,"time"))+" ")]),_c('br'),(lecture.homeworkCount > 0)?_c('router-link',{attrs:{"to":{
                          name: 'ProfileStudentResultsTab',
                          query: { editionFilter: lecture.editionId },
                        }}},[_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.pending_homework"))+": "+_vm._s(lecture.homeworkCount)+" ")])]):_vm._e(),(_vm.userCanJoin(lecture))?_c('v-container',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.joinToLecture(lecture)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("login")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lecture.join")))])])],1):_vm._e()],1)],1)]}}],null,true)})],1)}),(!_vm.nextLectures || _vm.nextLectures.length === 0)?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("student.dashboard.no_next_lectures"))+" ")])]):_vm._e()],2):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }