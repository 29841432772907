import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/userFiles";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async downloadFile(id) {
    return HTTP.get(`${RESOURCE_NAME}/${id}/file`, {
      responseType: "blob",
    });
  },

  async save(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
  },
};
