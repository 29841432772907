// Time slots
const timeSlots = [
  {
    day: 1,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 1,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 1,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 2,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 2,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 2,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 3,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 3,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 3,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 4,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 4,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 4,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 5,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 5,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 5,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 6,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 6,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 6,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
  {
    day: 0,
    startTimeHour: 0,
    startTimeMinute: 0,
    endTimeHour: 0,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 0,
    startTimeMinute: 30,
    endTimeHour: 1,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 1,
    startTimeMinute: 0,
    endTimeHour: 1,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 1,
    startTimeMinute: 30,
    endTimeHour: 2,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 2,
    startTimeMinute: 0,
    endTimeHour: 2,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 2,
    startTimeMinute: 30,
    endTimeHour: 3,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 3,
    startTimeMinute: 0,
    endTimeHour: 3,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 3,
    startTimeMinute: 30,
    endTimeHour: 4,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 4,
    startTimeMinute: 0,
    endTimeHour: 4,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 4,
    startTimeMinute: 30,
    endTimeHour: 5,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 5,
    startTimeMinute: 0,
    endTimeHour: 5,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 5,
    startTimeMinute: 30,
    endTimeHour: 6,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 6,
    startTimeMinute: 0,
    endTimeHour: 6,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 6,
    startTimeMinute: 30,
    endTimeHour: 7,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 7,
    startTimeMinute: 0,
    endTimeHour: 7,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 7,
    startTimeMinute: 30,
    endTimeHour: 8,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 8,
    startTimeMinute: 0,
    endTimeHour: 8,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 8,
    startTimeMinute: 30,
    endTimeHour: 9,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 9,
    startTimeMinute: 0,
    endTimeHour: 9,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 9,
    startTimeMinute: 30,
    endTimeHour: 10,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 10,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 10,
    startTimeMinute: 30,
    endTimeHour: 11,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 11,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 11,
    startTimeMinute: 30,
    endTimeHour: 12,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 12,
    startTimeMinute: 0,
    endTimeHour: 12,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 12,
    startTimeMinute: 30,
    endTimeHour: 13,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 13,
    startTimeMinute: 0,
    endTimeHour: 13,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 13,
    startTimeMinute: 30,
    endTimeHour: 14,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 14,
    startTimeMinute: 0,
    endTimeHour: 14,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 14,
    startTimeMinute: 30,
    endTimeHour: 15,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 15,
    startTimeMinute: 0,
    endTimeHour: 15,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 15,
    startTimeMinute: 30,
    endTimeHour: 16,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 16,
    startTimeMinute: 0,
    endTimeHour: 16,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 16,
    startTimeMinute: 30,
    endTimeHour: 17,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 17,
    startTimeMinute: 0,
    endTimeHour: 17,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 17,
    startTimeMinute: 30,
    endTimeHour: 18,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 18,
    startTimeMinute: 0,
    endTimeHour: 18,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 18,
    startTimeMinute: 30,
    endTimeHour: 19,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 19,
    startTimeMinute: 0,
    endTimeHour: 19,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 19,
    startTimeMinute: 30,
    endTimeHour: 20,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 20,
    startTimeMinute: 0,
    endTimeHour: 20,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 20,
    startTimeMinute: 30,
    endTimeHour: 21,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 21,
    startTimeMinute: 0,
    endTimeHour: 21,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 21,
    startTimeMinute: 30,
    endTimeHour: 22,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 22,
    startTimeMinute: 0,
    endTimeHour: 22,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 22,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 0,
  },
  {
    day: 0,
    startTimeHour: 23,
    startTimeMinute: 0,
    endTimeHour: 23,
    endTimeMinute: 30,
  },
  {
    day: 0,
    startTimeHour: 23,
    startTimeMinute: 30,
    endTimeHour: 23,
    endTimeMinute: 59,
  },
];

export default timeSlots;
