var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-datatable"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"order":"1","order-md":"2"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-magnify")]),_c('span',[_vm._v(" "+_vm._s(_vm.showFilters ? _vm.$t("actions.hideFilters") : _vm.$t("actions.showFilters"))+" ")]),(_vm.showFilters)?_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-chevron-up")]):_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
              data: _vm.dateFilter,
              label: 'levelTestRequest.prop.date',
            }},on:{"update-time":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'dateFilter', false ].concat( argsArray ))}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
              data: _vm.limitDateFilter,
              label: 'levelTestRequest.prop.limitDate',
            }},on:{"update-time":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'limitDateFilter', false ].concat( argsArray ))}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","xl":"1"}},[_c('v-select',{attrs:{"clearable":"","dense":"","disabled":"","items":_vm.levelTestRequestStates,"item-text":_vm.translate,"label":_vm.$t('levelTestRequest.prop.state'),"menu-props":{ offsetY: true }},on:{"change":_vm.redirectOnFilterChange},model:{value:(_vm.stateFilter),callback:function ($$v) {_vm.stateFilter=$$v},expression:"stateFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","xl":"1"}},[_c('autocomplete',{attrs:{"debouncing":300,"dense":"","items":_vm.students.items,"label":_vm.$t('levelTestRequest.prop.student'),"loading":_vm.students.loading,"item-text":_vm.getFullName,"item-value":"id","no-filter":"","search-input":_vm.studentSearch,"solo":""},on:{"update:searchInput":function($event){_vm.studentSearch=$event},"update:search-input":function($event){_vm.studentSearch=$event},"change":_vm.redirectOnFilterChange},model:{value:(_vm.studentFilter),callback:function ($$v) {_vm.studentFilter=$$v},expression:"studentFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","xl":"1"}},[_c('autocomplete',{attrs:{"dense":"","no-filter":"","solo":"","debouncing":300,"items":_vm.filteredLanguages,"loading":_vm.languages.loading,"search-input":_vm.languageSearch,"label":_vm.$t('levelTestRequest.prop.language')},on:{"update:searchInput":function($event){_vm.languageSearch=$event},"update:search-input":function($event){_vm.languageSearch=$event},"change":_vm.redirectOnFilterChange},model:{value:(_vm.languageFilter),callback:function ($$v) {_vm.languageFilter=$$v},expression:"languageFilter"}})],1)],1),_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.entitiesPage,"server-items-length":_vm.totalItems},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [(item.date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(item.date))+" ")]):_vm._e()]}},{key:"item.limitDate",fn:function(ref){
            var item = ref.item;
return [(item.limitDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(item.limitDate))+" ")]):_vm._e()]}},{key:"item.level",fn:function(ref){
            var item = ref.item;
return [(item.level)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("languages." + (item.level.language.name))))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('program-level-test-dialog',{attrs:{"level-test-request":item},on:{"scheduled":_vm.getItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-calendar ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.accept")))])])],1)]}}],null,true)}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.reject")))])])]}}],null,true)})],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"loading":_vm.deleteLoading},on:{"cancel":function($event){_vm.deleteDialog = false},"submit":function($event){return _vm.rejectRequest()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }