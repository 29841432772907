<template>
  <v-card>
    <v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="6" class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t("companyStudent.headers.form") }}
          </span>
        </v-col>

        <v-col cols="12" md="6" class="text-right">
          <v-btn class="success ml-3" :disabled="!validForm" @click="save">
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-form v-model="validForm" ref="form">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="entity.name"
              type="text"
              class="required"
              :rules="[
                (v) =>
                  !!v || $t('companyStudent.form.validation.name_required'),
              ]"
              :label="$t('profile.fields.name')"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="entity.email"
              type="text"
              class="required"
              :rules="[
                (v) =>
                  !!v || $t('companyStudent.form.validation.email_required'),

                (v) =>
                  !v ||
                  this.regex.EMAIL_REGEX.test(v) ||
                  $t('companyStudent.form.validation.emailPattern'),
              ]"
              :label="$t('profile.fields.main_email')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import regex from "@/common/regex-validation";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const CompanyStudentEntityRepository = RepositoryFactory.get(
  "CompanyStudentEntityRepository"
);

export default {
  name: "CompanyStudentForm",
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      validForm: true,
      entity: {},
      loading: false,
      regex,
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.entity.companyId = this.companyId;
      CompanyStudentEntityRepository.save(this.entity)
        .then(() => {
          this.$emit("saved");
          this.entity = {};
          this.$refs.form.reset();
        })
        .catch(() =>
          this.$log.debug("Error saving company student: " + this.entity)
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
