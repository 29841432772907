<template>
  <v-dialog v-model="dialog" width="90%">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <v-btn icon @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" class="text-center">
            {{ $t("productRequest.program_dialog.title") }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="productRequest">
          <v-col class="text-center">
            {{ $t("productRequest.program_dialog.product.title") }}:
            {{ productRequest.product.title }}</v-col
          >
          <v-col class="text-center">
            {{ $t("productRequest.program_dialog.product.type") }}:
            {{
              $t(
                `registration.product_type.${productRequest.product.productType}`
              )
            }}</v-col
          >
          <v-col class="text-center">
            {{ $t("productRequest.prop.classesPerWeek") }}:
            {{ productRequest.classesPerWeek }}
          </v-col>
          <v-col class="text-center">
            {{
              $t("productRequest.program_dialog.class_duration", {
                value: productRequest.product.classDuration,
              })
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <autocomplete
              v-model="teacher"
              :clearable="false"
              :items="availableTeachers"
              :item-text="getPersonName"
              :label="$t('productRequest.program_dialog.teacher')"
              :loading="loadingTeachers"
              :rules="[
                (v) =>
                  !!v ||
                  $t('productRequest.program_dialog.errors.teacher_required'),
              ]"
              return-object
              outlined
            ></autocomplete>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-list width="100%">
            <v-subheader>{{
              $t("productRequest.program_dialog.selected_slot")
            }}</v-subheader>
            <v-chip
              color="primary"
              text-color="white"
              class="list-items"
              v-for="(schedule, i) in schedules"
              :key="i"
            >
              <span class="text-overline">
                <v-icon>mdi-clock-outline</v-icon>
                {{ schedule }}
              </span>
            </v-chip>
          </v-list>
        </v-row>

        <v-row justify="center" align-content="center">
          <v-list width="100%">
            <v-subheader>{{
              $t("productRequest.modal.selected_students")
            }}</v-subheader>
            <v-chip
              color="secondary"
              text-color="white"
              class="list-items"
              v-for="student in selectedStudents"
              :key="student.id"
            >
              <span class="text-overline">
                <v-avatar>
                  <v-img :src="student.photo"></v-img>
                </v-avatar>
                {{ getPersonName(student) }}
              </span>
            </v-chip>
          </v-list>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-center">
          <v-btn
            color="success"
            :disabled="!teacher"
            :loading="loadingEdition"
            @click="save"
          >
            <v-icon small> save </v-icon>
            <span>{{ $t("actions.save") }}</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getOffset, getUserTimeZone } from "@/common/timeZoneUtils";
import moment from "moment-timezone";

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const ProductRequestEntityRepository = RepositoryFactory.get(
  "ProductRequestEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: { Autocomplete },
  props: {
    productRequest: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      availableTeachers: [],
      dialog: false,
      loadingTeachers: false,
      loadingEdition: false,
      teacher: null,
      selectedStudents: [],
      schedules: [],
    };
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
      if (val) {
        this._fetchTeachers();
        this._fetchProductRequestInfo();
      } else {
        this.teacher = null;
      }
    },
    value: {
      handler(newVal) {
        this.dialog = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    _fetchProductRequestInfo() {
      return ProductRequestEntityRepository.getCompanyProductRequestById(
        this.productRequest.id
      )
        .then((data) => {
          this.selectedStudents = data.students;
          this.schedules = this.selectedDays(data.schedules);
          this.downloadStudentPhotos();
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching product request with ID " + this.productRequest.id
          )
        );
    },
    _fetchTeachers() {
      this.loadingTeachers = true;
      return TeacherEntityRepository.getAvailableTeachers(
        null,
        this.productRequest.id
      )
        .then((data) => {
          if (data.length < 1) {
            this.$notify({
              title: this.$t(
                "productRequest.program_dialog.errors.teachers_not_found.title"
              ),
              text: this.$t(
                "productRequest.program_dialog.errors.teachers_not_found.text"
              ),
              type: "warning",
            });
          }
          this.availableTeachers = data;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching teachers for product with ID " +
              this.productRequest.product.id
          )
        )
        .finally(() => (this.loadingTeachers = false));
    },
    getPersonName(item) {
      return item.name + " " + item.surname;
    },
    save() {
      this.loadingEdition = true;
      let courseSchedule = {
        productRequestId: this.productRequest.id,
        teacherId: this.teacher.id,
      };
      return EditionEntityRepository.createCompanyCourseEdition(courseSchedule)
        .then(() => {
          this.dialog = false;
          this.$emit("scheduled", this.productRequest.id);
          this.$notify({
            title: this.$t("course.messages.programmed"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
        })
        .catch((e) => this.$log.debug("Fail to save edition", e.response))
        .finally(() => (this.loadingEdition = false));
    },
    downloadStudentPhotos() {
      this.selectedStudents.forEach(
        async (s) => (s.photo = await this.fetchPhoto(s.userId))
      );
    },
    async fetchPhoto(userId) {
      if (userId) {
        userId = parseInt(userId);
        return await UserDataEntityRepository.getPhoto(userId)
          .then((data) => {
            if (data.size > 0) {
              return URL.createObjectURL(data);
            }
          })
          .catch(() =>
            this.$log.debug(
              "Error fetching profile photo for user with ID " + userId
            )
          );
      }
      return require("@/assets/icon_visits.png");
    },
    selectedDays(schedules) {
      const slotsPerClass = Math.ceil(
        this.productRequest.product.classDuration / 30
      );
      const groupByClass = schedules.reduce(function (
        result,
        _value,
        index,
        array
      ) {
        if (index % slotsPerClass === 0)
          result.push(array.slice(index, index + slotsPerClass));
        return result;
      },
      []);
      let formattedSchedules = [];
      groupByClass.forEach((el) =>
        formattedSchedules.push(
          this.convertDateToString(el[0], el[el.length - 1])
        )
      );
      return formattedSchedules;
    },
    convertDateToString(start, end) {
      // Calculo el offset del usuario el minutos
      let offset = moment.tz(this.getUserTimeZone()).utcOffset();

      // Calculo hora inicio y hora fin del intervalo (en minutos) aplicándole el offset de la zona horaria del usuario
      let startTimeWithOffsetInMinutes =
        start.startTimeHour * 60 + start.startTimeMinute + offset;
      let endTimeWithOffsetInMinutes =
        end.endTimeHour * 60 + end.endTimeMinute + offset;

      if (startTimeWithOffsetInMinutes >= 1440) {
        // Caso de que al aplicar el offset de la zona horaria se cambie de día para uno más
        start.day += 1;
        if (start.day > 6) {
          start.day -= 7;
        }
        startTimeWithOffsetInMinutes -= 1440;
      }
      if (startTimeWithOffsetInMinutes < 0) {
        // Caso de que al aplicar el offset de la zona horaria se cambie de día para uno menos
        start.day -= 1;
        if (start.day < 0) {
          start.day += 7;
        }
        startTimeWithOffsetInMinutes += 1440;
      }

      if (endTimeWithOffsetInMinutes >= 1440) {
        end.day += 1;
        if (end.day > 6) {
          end.day -= 7;
        }
        endTimeWithOffsetInMinutes -= 1440;
      }
      if (endTimeWithOffsetInMinutes < 0) {
        end.day -= 1;
        if (end.day < 0) {
          end.day += 7;
        }
        endTimeWithOffsetInMinutes += 1440;
      }

      start.startTimeHour = Math.trunc(startTimeWithOffsetInMinutes / 60);
      start.startTimeMinute = startTimeWithOffsetInMinutes % 60;
      end.endTimeHour = Math.trunc(endTimeWithOffsetInMinutes / 60);
      end.endTimeMinute = endTimeWithOffsetInMinutes % 60;
      let stringDate =
        this.$t(`calendar.weekdays.${start.day}`) +
        " " +
        this._addZero(start.startTimeHour) +
        ":" +
        this._addZero(start.startTimeMinute) +
        "-";
      if (end.day != start.day) {
        stringDate += " " + this.$t(`calendar.weekdays.${end.day}`) + " ";
      }
      stringDate +=
        this._addZero(end.endTimeHour) + ":" + this._addZero(end.endTimeMinute);
      return stringDate;
    },
    _addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getOffset,
    getUserTimeZone,
  },
};
</script>
<style scoped>
.v-calendar >>> .v-calendar-daily_head-day-label {
  display: none;
}
.list-items {
  margin-left: 14px;
  margin-top: 4px;
}
</style>
