<template>
  <v-container>
    <company-form
      v-if="!loading"
      :back-btn="false"
      register-fields
      v-model="entity"
      @save="save"
    ></company-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage";
import CompanyForm from "@/mockups/company/components/CompanyForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
const AccountRepository = RepositoryFactory.get("AccountRepository");
export default {
  components: {
    LoadingPage,
    CompanyForm,
  },
  data() {
    return {
      loading: false,
      entity: {
        login: "",
        password: "",
        password2: "",
        langKey: "en",
      },
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
  },
  created() {
    if (this.isLogged) {
      this.$notify({
        title: "Error",
        text: this.$t("account.errors.forbidden_access"),
        type: "error",
        duration: 30000,
      });
      this.$router.push({ name: "Unauthorized" });
    }
  },
  methods: {
    save() {
      this.loading = true;
      this.checkSecondaryEmail();
      AccountRepository.registerCompany(this.entity)
        .then(() => {
          this.$router.push({ name: "Activate account" });
        })
        .catch(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>

<style></style>
