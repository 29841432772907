var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"65%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","md":"11"}},[_c('h1',{staticClass:"title ml-4"},[_vm._v(" "+_vm._s(_vm.$t("teacher.headers.set_supervisor"))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.headmasters,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.loadingRow[item.id])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.setSupervising(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.add")))])]):_c('loading-spinner')]}},{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('debounced-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('search')},on:{"input":_vm.onSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }