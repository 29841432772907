import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/products";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllPublic(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/public`, options);
    return response.data;
  },

  async getAllOfStudent(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/selector-student`,
      options
    );
    return response.data;
  },

  async getAllProductsWithLectures() {
    return (await HTTP.get(`${RESOURCE_NAME}/findAllProductsWithLectures`))
      .data;
  },

  async getAllOfTeacher(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/selector`, options);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async updateState(entity) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/${entity.id}/update-state`, entity)
    ).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
