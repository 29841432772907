const ChatMessageType = {
  NOTIFICATION: "NOTIFICATION",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  SUBSCRIBED: "SUBSCRIBED",
};

const ClassroomMessageType = {
  SET_NEW_MODE: "SET_NEW_MODE",
  RECORDING_STARTED: "RECORDING_STARTED",
  RECORDING_ENDED: "RECORDING_ENDED",
  SCREEN_SHARE_STARTED: "SCREEN_SHARE_STARTED",
  SCREEN_SHARE_ENDED: "SCREEN_SHARE_ENDED",
  UPDATE_CLASSROOM_STATE: "UPDATE_CLASSROOM_STATE",
  DEVICE_LEFT: "DEVICE_LEFT",
  NEW_USER_OFFLINE: "NEW_USER_OFFLINE"
};

const OfflineClassroomMessageType = {
  SHARE_TAB: "SHARE_TAB",
  END_LECTURE: "END_LECTURE",
  UNSHARE_TAB: "UNSHARE_TAB",
  UPDATE_PARTICIPANTS: "UPDATE_PARTICIPANTS",
  KICK: "KICK",
  SUBSCRIBED: "SUBSCRIBED",
  NEW_PARTICIPANT: "NEW_PARTICIPANT",
  PARTICIPANT_LEFT: "PARTICIPANT_LEFT",
};

const ClassroomDeviceMessageType = {
  MUTE: "MUTE",
  STOP_CAMERA: "STOP_CAMERA",
  STOP_SCREEN_SHARE: "STOP_SCREEN_SHARE",
  START_SCREEN_SHARE: "START_SCREEN_SHARE",
  UNMUTE: "UNMUTE",
  START_CAMERA: "START_CAMERA",
  SUBSCRIBED_CLASSROOM: "SUBSCRIBED_CLASSROOM",
  LEAVE: "LEAVE",
  UPDATE_DEVICE_STATE: "UPDATE_DEVICE_STATE",
  START_AUDIO: "START_AUDIO",
};

const ClassroomParticipantMessageType = {
  MUTE_USER: "MUTE_USER",
  STOP_USER_CAMERA: "STOP_USER_CAMERA",
};

export {
  OfflineClassroomMessageType,
  ClassroomMessageType,
  ClassroomDeviceMessageType,
  ClassroomParticipantMessageType,
  ChatMessageType,
};
