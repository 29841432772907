import AdminList from "@/mockups/admin/AdminList";
import BasicProfileForm from "@/mockups/admin/BasicProfileForm";
import ContentEditorList from "@/mockups/admin/contentEditor/ContentEditorList";
import StudentList from "@/mockups/admin/students/StudentList";
import StudentCreateForm from "@/mockups/admin/students/StudentCreateForm";
import TeacherList from "@/mockups/admin/teachers/TeacherList";
import TeacherCreateForm from "@/mockups/admin/teachers/TeacherCreateForm";
import TutorList from "@/mockups/admin/tutors/TutorList";
import LevelTestRequestList from "@/mockups/admin/levelTests/LevelTestRequestList";
import ProductRequestsList from "@/mockups/admin/productRequests/ProductRequestList";
import LectureEvaluations from "@/mockups/admin/lectureEvaluations/LectureEvaluations";
import LectureDetail from "@/mockups/admin/lectures/lecture-detail/LectureDetail";
import WalletList from "@/mockups/admin/accounting/WalletList";
import LecturePaymentList from "@/mockups/admin/accounting/LecturePaymentList";
import LectureList from "@/mockups/admin/lectures/LectureList";
import CloseLectureList from "@/mockups/admin/lectures/CloseLectureList";
import AdminEditionList from "@/mockups/admin/editions/AdminEditionList";
import AdminEditionDetail from "@/mockups/admin/editions/AdminEditionDetail";
import TeacherLecture from "@/mockups/admin/accounting/TeacherLecture";
import AdminDashboard from "@/mockups/admin/views/AdminDashboard";
import CompanyList from "@/mockups/admin/companies/CompanyList";
import CompanyCreateForm from "@/mockups/admin/companies/CompanyCreateForm";
import InvoiceList from "@/mockups/admin/accounting/InvoiceList";
import PendingTeacherLevelApprovalList from "@/mockups/admin/teachers/PendingTeacherLevelApprovalList";
import PreliminaryTeacherReferenceList from "@/mockups/admin/teachers/PreliminaryTeacherReferenceList";
import PendingDiplomasList from "@/mockups/admin/teachers/PendingDiplomasList";
import UsersWithoutBillingIdList from "@/mockups/admin/accounting/UsersWithoutBillingId";

const routes = [
  {
    path: "/admin/dashboard",
    name: "Admin Home",
    component: AdminDashboard,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "admin.headers.dashboard",
    },
  },
  {
    path: "/admin/teachers-list/candidates",
    name: "Candidate teachers list",
    component: TeacherList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.candidates-list",
    },
  },
  {
    path: "/admin/teachers-list/registered",
    name: "Registered teachers list",
    component: TeacherList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.registered-list",
    },
  },
  {
    path: "/admin/teachers-list/pending-level-approvals",
    name: "Pending teacher levels",
    component: PendingTeacherLevelApprovalList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.level_list",
    },
  },
  {
    path: "/admin/teachers-list/preliminary-reference-approvals",
    name: "Preliminary teacher references",
    component: PreliminaryTeacherReferenceList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.reference_list",
    },
  },
  {
    path: "/admin/teachers-list/pending-diplomas",
    name: "PendingDiplomasList",
    component: PendingDiplomasList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.pending-diplomas",
    },
  },
  {
    path: "/admin/accounting/users-without-billing-id",
    name: "UsersWithoutBillingIdList",
    component: UsersWithoutBillingIdList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "wallet.headers.users-without-billing-id",
    },
  },
  {
    path: "/admin/students-list/registered",
    name: "Registered students list",
    component: StudentList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "student.headers.registered-list",
    },
  },
  {
    path: "/admin/students-list/candidates",
    name: "Candidate students list",
    component: StudentList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "student.headers.candidates-list",
    },
  },
  {
    path: "/admin/students/form/new",
    name: "Create Student",
    component: StudentCreateForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "student.headers.candidates-form",
    },
  },
  {
    path: "/admin/teachers/form/new",
    name: "Create Teacher",
    component: TeacherCreateForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.headers.candidates-form",
    },
  },
  {
    path: "/admin/content-editors",
    name: "Content editor list",
    component: ContentEditorList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "contentEditor.headers.list",
    },
  },
  {
    path: "/admin/companies-list",
    name: "Company list",
    component: CompanyList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "company.headers.list",
    },
  },
  {
    path: "/admin/companies/form/new",
    name: "Create Company",
    component: CompanyCreateForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "company.headers.create",
    },
  },
  {
    path: "/admin/admins",
    name: "Admin List",
    component: AdminList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "userData.headers.admin-list",
    },
  },
  {
    path: "/admin/tutors",
    name: "Tutor list",
    component: TutorList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "tutor.headers.list",
    },
  },
  {
    path: "/admin/account/new",
    name: "Create Basic Profile",
    component: BasicProfileForm,
    query: {
      role: null,
    },
    meta: {
      authority: "ROLE_ADMIN",
      label: "userData.headers.basic-profile-form",
    },
  },
  {
    path: "/admin/level-test-requests",
    name: "Level Test Request List",
    component: LevelTestRequestList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "levelTestRequest.headers.list",
    },
  },
  {
    path: "/admin/product-requests",
    name: "Admin Product Request List",
    component: ProductRequestsList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "productRequest.headers.list",
    },
  },
  {
    path: "/admin/lecture-evaluations",
    name: "Admin Lecture Evaluations",
    component: LectureEvaluations,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "lectureEvaluation.headers.list",
    },
  },
  {
    path: "/admin/editions",
    name: "Admin Edition List",
    component: AdminEditionList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "edition.headers.list",
    },
  },
  {
    path: "/admin/editions/:editionId",
    name: "Admin Edition Detail",
    component: AdminEditionDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacher.tabs.editions.detail",
    },
  },
  {
    path: "/lectures",
    name: "Lecture List",
    component: LectureList,
    meta: {
      authority: ["ROLE_ADMIN", "ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
      label: "lecture.headers.list",
    },
  },
  {
    path: "/lectures/close",
    name: "Close Lecture List",
    component: CloseLectureList,
    meta: {
      authority: [
        "ROLE_TEACHER",
        "ROLE_TEACHER_CANDIDATE",
        "ROLE_STUDENT",
        "ROLE_STUDENT_CANDIDATE",
      ],
      label: "lecture.headers.close-list",
    },
  },
  {
    path: "/lectures/:id",
    name: "Lecture Detail",
    component: LectureDetail,
    meta: {
      authority: [
        "ROLE_ADMIN",
        "ROLE_TEACHER",
        "ROLE_TEACHER_CANDIDATE",
        "ROLE_STUDENT",
        "ROLE_STUDENT_CANDIDATE",
        "ROLE_TUTOR",
      ],
      label: "lecture.headers.detail",
    },
    props: true,
  },
  {
    path: "/accounting",
    name: "Wallet List",
    component: WalletList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "wallet.headers.list",
    },
  },
  {
    path: "/teacher-lecture",
    name: "Teacher Lecture",
    component: TeacherLecture,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "teacherLecture.headers.list",
    },
  },
  {
    path: "/invoices",
    name: "Invoice List",
    component: InvoiceList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "invoice.headers.list",
    },
  },
  {
    path: "/lecture-payment",
    name: "Lecture Payment List",
    component: LecturePaymentList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "lecture.headers.payment-list",
    },
  },
];

export default routes;
