import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "entities/lectureChanges";

export default {
  async getReport() {
    const response = await HTTP.get(`${RESOURCE_NAME}/report`, {
      responseType: "blob",
    });
    return response.data;
  },
};
