<template>
  <v-container v-if="!isLoading">
    <v-row>
      <v-col cols="6" md="4">
        <span class="headline text--darken-2">{{
          $t("student.prop.tutors")
        }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="2" class="text-right">
        <v-btn @click="tutorFormDialog = true" color="success">
          <v-icon class="mr-2">add</v-icon>
          {{ $t("actions.new") }}
        </v-btn>
      </v-col>
      <v-col cols="12" v-for="tutor in entities" :key="tutor.id">
        <tutor-info
          :editable="true"
          :entity="tutor"
          @delete="showDeleteDialog"
        ></tutor-info>
      </v-col>
    </v-row>

    <!-- New Tutor Form logic -->
    <v-dialog v-model="tutorFormDialog" width="900">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="8" md="4">
              <h1 class="headline">Add a new tutor</h1>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" md="1">
              <v-btn icon @click="closeEditDialog()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <tutor-form
            v-if="tutorFormDialog"
            @validation-update="updateTutorValidation"
            v-model="newEntity"
          ></tutor-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-around">
            <v-col cols="6" md="4">
              <v-btn @click="closeEditDialog()" block>cancel</v-btn>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn
                :disabled="!validButton"
                :loading="loadingSave"
                @click="createTutor()"
                color="success"
                block
              >
                <v-icon class="mr-2">add</v-icon>
                add</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete confirmation tutor -->
    <delete-dialog
      :dialog="tutorDeleteDialog"
      :loading="deleteLoading"
      :title="$t('tutor.form.remove_tutor')"
      @cancel="tutorDeleteDialog = false"
      @submit="deleteTutor"
    ></delete-dialog>

    <!-- Loading Page Component -->
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import TutorInfo from "@/mockups/tutor/components/TutorInfo";
import TutorForm from "@/mockups/tutor/components/TutorForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");

export default {
  components: {
    LoadingPage,
    TutorInfo,
    TutorForm,
    DeleteDialog,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      deleteLoading: false,
      loadingSave: false,
      entities: {},
      // Form variables
      newEntity: {},
      tutorFormDialog: false,
      validButton: false,
      // Deletion variables
      tutorSelected: null,
      tutorDeleteDialog: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  mounted() {
    this._fetchData(this.studentId);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return TutorEntityRepository.getByStudent(id)
        .then((response) => {
          this.entities = response;
        })
        .catch(() => this.$log.debug("Error fetching student with ID: " + id))
        .finally(() => (this.loading = false));
    },
    // Edit logic
    createTutor() {
      this.newEntity.studentId = this.studentId;
      this.loadingSave = true;
      TutorEntityRepository.save(this.newEntity)
        .then(() => {
          this.$notify({
            title: "Tutor Created",
            text: this.$t("account.notifications.successfull_registered"),
            type: "success",
          });
          this._fetchData(this.studentId);
        })
        .catch(() =>
          this.$notify({
            title: "Error creating tutor",
            text: this.$t("account.notifications.error_register"),
            type: "error",
            duration: 30000,
          })
        )
        .finally(() => {
          this.loadingSave = false;
          this.closeEditDialog();
        });
    },
    updateTutorValidation(valid) {
      this.validButton = valid;
    },
    closeEditDialog() {
      this.newEntity = {};
      this.tutorFormDialog = false;
    },
    // Deletion logic
    showDeleteDialog(tutor) {
      this.tutorSelected = tutor;
      this.tutorDeleteDialog = true;
    },
    deleteTutor() {
      if (this.tutorSelected) {
        this.deleteLoading = true;
        TutorEntityRepository.delete(this.tutorSelected.id)
          .then(() => {
            this.entities.splice(this.entities.indexOf(this.tutorSelected), 1);
            this.$notify({
              title: this.$t("account.notifications.successfull"),
              text: this.$t("account.notifications.successfull_deleted"),
              type: "success",
            });
          })
          .catch(() => this.$log.debug("Error deleting tutor"))
          .finally(() => {
            this.tutorSelected = null;
            this.tutorDeleteDialog = false;
            this.deleteLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
