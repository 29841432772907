<template>
  <v-container>
    <v-row>
      <v-col class="text-center text-h2">
        {{ $t($route.meta.label) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(incompleteActivityCount)"
              :content="incompleteActivityCount"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="max-height"
                  :elevation="hover ? 12 : 2"
                  :to="{
                    name: 'Activity List',
                    query: {
                      incomplete: 'true',
                      languageFilter: language,
                    },
                  }"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-notebook-edit
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("contentEditor.dashboard.incomplete_activities") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>

          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(incompleteCourseCount)"
              :content="incompleteCourseCount"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="max-height"
                  :elevation="hover ? 12 : 2"
                  :to="{
                    name: 'Course List',
                    query: { incomplete: 'true', languageFilter: language },
                  }"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary"> mdi-school </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("contentEditor.dashboard.incomplete_courses") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>

          <v-col cols="12" md="3" class="text-center">
            <v-badge
              class="dashboard-card"
              :color="getBadgeColor(incompleteLevelTestCount)"
              :content="incompleteLevelTestCount"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="max-height"
                  :elevation="hover ? 12 : 2"
                  :to="{
                    name: 'LevelTest List',
                    query: { incomplete: 'true', language: language },
                  }"
                  :loading="loading"
                >
                  <v-card-title>
                    <v-col class="text-center">
                      <v-icon x-large color="primary">
                        mdi-clipboard-text
                      </v-icon>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <span class="text-overline">
                      {{ $t("contentEditor.dashboard.incomplete_level_test") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-badge>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center" v-if="lastCourse">
            <v-card
              class="mx-6 mx-md-0"
              :to="{
                name: 'Course Detail',
                params: { id: lastCourse.id, backPrevious: true },
              }"
              :loading="loading"
            >
              <v-card-title class="px-0 pt-2">
                <v-col class="text-center px-0 py-0">
                  {{ $t("contentEditor.dashboard.last_course") }}
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 py-0">
                <span class="text-overline">
                  {{ lastCourse.name }}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="text-center" v-if="lastActivity">
            <v-card
              class="mx-6 mx-md-0"
              :to="{
                name: 'Activity Detail',
                query: { incomplete: 'true' },
                params: { id: lastActivity.id, backPrevious: true },
              }"
              :loading="loading"
            >
              <v-card-title class="px-0 pt-2">
                <v-col class="text-center px-0 py-0">
                  {{ $t("contentEditor.dashboard.last_activity") }}
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 py-0">
                <span class="text-overline">
                  {{ lastActivity.name }}
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" md="5" class="text-center">
            <language-and-all-selector
              all
              v-model="language"
              :label="$t('contentEditor.dashboard.language_selector_label')"
            ></language-and-all-selector>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LanguageAndAllSelector from "@/components/debouncing-inputs/LanguageAndAllSelector.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const DashboardRepository = RepositoryFactory.get("DashboardRepository");
export default {
  components: { LanguageAndAllSelector },
  data() {
    return {
      incompleteActivityCount: 0,
      incompleteCourseCount: 0,
      incompleteLevelTestCount: 0,
      loading: false,
      lastCourse: null,
      lastActivity: null,
      language: null,
    };
  },
  watch: {
    language: {
      handler(newVal) {
        this.language = newVal;
        const parsed = JSON.stringify(this.language);
        localStorage.setItem("dashboardLanguage", parsed);
        this._fetchData();
      },
    },
  },
  created() {
    this._fetchData();
  },
  mounted() {
    if (localStorage.getItem("lastCourse")) {
      try {
        this.lastCourse = JSON.parse(localStorage.getItem("lastCourse"));
      } catch (e) {
        localStorage.removeItem("lastCourse");
      }
    }
    if (localStorage.getItem("lastActivity")) {
      try {
        this.lastActivity = JSON.parse(localStorage.getItem("lastActivity"));
      } catch (e) {
        localStorage.removeItem("lastActivity");
      }
    }
    if (localStorage.getItem("dashboardLanguage")) {
      try {
        this.language = JSON.parse(localStorage.getItem("dashboardLanguage"));
      } catch (e) {
        localStorage.removeItem("dashboardLanguage");
      }
    }
  },

  methods: {
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          language: this.language,
        },
      };
      DashboardRepository.getForContentEditor(options)
        .then((data) => {
          this.incompleteActivityCount = data.incompleteActivityCount;
          this.incompleteCourseCount = data.incompleteCourseCount;
          this.incompleteLevelTestCount = data.incompleteLevelTestCount;
        })
        .catch(() => this.$log.debug("Error fetching dashboard information"))
        .finally(() => (this.loading = false));
    },
    getBadgeColor(count) {
      return count > 0 ? "red" : "green";
    },
  },
};
</script>
<style scoped>
.dashboard-card {
  width: 90%;
  height: 100%;
}
.max-height {
  height: 100%;
}
</style>
