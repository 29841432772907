<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <v-btn icon @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" class="text-center">
            {{ $t("productRequest.program_dialog.title") }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="productRequest">
          <v-col class="text-center">
            {{ $t("productRequest.program_dialog.product.title") }}:
            {{ productRequest.product.title }}</v-col
          >
          <v-col class="text-center">
            {{ $t("productRequest.program_dialog.product.type") }}:
            {{
              $t(
                `registration.product_type.${productRequest.product.productType}`
              )
            }}</v-col
          >
          <v-col class="text-center">
            {{ $t("productRequest.prop.classesPerWeek") }}:
            {{ productRequest.classesPerWeek }}
          </v-col>
          <v-col class="text-center">
            {{
              $t("productRequest.program_dialog.class_duration", {
                value: productRequest.product.classDuration,
              })
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col class="text-center text-h6">
            {{ $t("productRequest.program_dialog.student") }}:
            {{ getPersonName(productRequest.student) }}
          </v-col>
        </v-row>
        <v-row v-if="!singlePersonCourse">
          <autocomplete
            v-model="students"
            :items="availableStudents"
            :item-text="getPersonName"
            :label="$t('productRequest.program_dialog.group')"
            :loading="loadingStudents"
            :rules="[
              (v) =>
                v.length >= productRequest.product.minStudents ||
                $t('productRequest.program_dialog.errors.min_students', {
                  value: productRequest.product.minStudents - 1,
                }),
              (v) =>
                v.length <= productRequest.product.maxStudents ||
                $t('productRequest.program_dialog.errors.max_students', {
                  value: productRequest.product.maxStudents - 1,
                }),
            ]"
            item-value="id"
            :clearable="false"
            outlined
            multiple
            @change="getPersons"
          ></autocomplete>
        </v-row>
        <v-row>
          <autocomplete
            v-model="teacher"
            :clearable="false"
            :disabled="!validStudentGroup"
            :items="availableTeachers"
            :item-text="getPersonName"
            :label="$t('productRequest.program_dialog.teacher')"
            :loading="loadingTeachers"
            :rules="[
              (v) =>
                !!v ||
                $t('productRequest.program_dialog.errors.teacher_required'),
            ]"
            return-object
            outlined
            @change="updateAvailabilities"
          ></autocomplete>
        </v-row>
        <v-row v-if="teacher" no-gutters>
          <v-col cols="12">
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="9" v-if="selectedSchedule">
                <span>
                  {{ $t("productRequest.program_dialog.selected_slot") }}:
                </span>
                <v-row v-for="(slot, i) in selectedSlots" :key="i">
                  {{ $d(new Date(slot.start), "schedule") }}
                  -
                  {{ $d(new Date(slot.end), "time") }}
                </v-row>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  :loading="loadingEdition"
                  color="success"
                  :disabled="!selectedSchedule"
                  @click="save"
                >
                  <v-icon small> save </v-icon>
                  <span>{{ $t("actions.save") }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="start">
              <v-progress-circular
                v-if="loadingAvailabilities"
                indeterminate
                color="primary"
              >
              </v-progress-circular>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-sheet :height="height">
              <v-calendar
                :value="focus"
                color="primary"
                :events="availabilities"
                :event-color="getEventColor"
                :interval-height="25"
                :interval-minutes="30"
                :interval-count="48"
                type="week"
                ref="calendar"
                :weekdays="[1, 2, 3, 4, 5, 6, 7, 0]"
                @click:event="selectSlot"
                :locale="$i18n.locale"
              >
                <template v-slot:event="{ event }">
                  <div class="event-div">
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols="12">
                        <span>
                          {{ event.name }}
                        </span>
                      </v-col>
                      <v-col cols="12" v-if="event.checked">
                        <v-icon small>mdi-check</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  dateArrayToDate,
  dateToDateArray,
  localDateToVCalendarString,
  utcDateArrayToDateWithTimezoneFormatted,
} from "@/common/conversion-utils";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);
const ProductRequestEntityRepository = RepositoryFactory.get(
  "ProductRequestEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
export default {
  components: { Autocomplete },
  props: {
    productRequest: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: "550",
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      availabilities: [],
      availableStudents: [],
      availableTeachers: [],
      dialog: false,
      focus: new Date().toISOString().slice(0, 10),
      loadingEdition: false,
      loadingAvailabilities: false,
      loadingStudents: false,
      loadingTeachers: false,
      selectedSlots: [],
      students: [],
      teacher: null,
    };
  },
  computed: {
    selectedSchedule() {
      return this.selectedSlots.length == this.productRequest.classesPerWeek;
    },
    singlePersonCourse() {
      return this.productRequest.product.productType == "ADHOC_ONE";
    },
    validStudentGroup() {
      return (
        this.students.length >= this.productRequest.product.minStudents &&
        this.students.length <= this.productRequest.product.maxStudents
      );
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
      if (val) {
        this._fetchData();
      } else {
        this.teacher = null;
      }
    },
    value: {
      handler(newVal) {
        this.dialog = newVal;
      },
      immediate: true,
    },
    teacher() {
      if (this.teacher) {
        this.$nextTick(() => {
          this.$refs.calendar.scrollToTime("09:00");
        });
      }
    },
  },
  methods: {
    getPersons() {
      this._fetchStudents();
      this._fetchTeachers();
    },
    _fetchData() {
      this.students = [this.productRequest.student.id];
      if (this.singlePersonCourse) {
        this._fetchTeachers();
      } else {
        this._fetchStudents();
      }
    },
    _fetchStudents() {
      this.loadingStudents = true;
      return StudentEntityRepository.getAvailableTeachers(
        this.students,
        this.productRequest.id
      )
        .then((data) => {
          if (data.length < this.productRequest.product.minStudents) {
            this.$notify({
              title: this.$t(
                "productRequest.program_dialog.errors.min_students_not_found.title"
              ),
              text: this.$t(
                "productRequest.program_dialog.errors.min_students_not_found.text"
              ),
              type: "warning",
            });
          }
          this.availableStudents = data.filter(
            (el) => el.id != this.productRequest.student.id
          );
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching students for product with ID " +
              this.productRequest.product.id
          )
        )
        .finally(() => (this.loadingStudents = false));
    },
    _fetchTeachers() {
      this.loadingTeachers = true;
      return TeacherEntityRepository.getAvailableTeachers(
        this.students,
        this.productRequest.id
      )
        .then((data) => {
          if (data.length < 1) {
            this.$notify({
              title: this.$t(
                "productRequest.program_dialog.errors.teachers_not_found.title"
              ),
              text: this.$t(
                "productRequest.program_dialog.errors.teachers_not_found.text"
              ),
              type: "warning",
            });
          }
          this.availableTeachers = data;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching teachers for product with ID " +
              this.productRequest.product.id +
              " and student with ID " +
              this.productRequest.student.id
          )
        )
        .finally(() => (this.loadingTeachers = false));
    },
    _formatAvailabilities(data) {
      return data.map((el) => {
        return {
          name: this.$t("levelTestRequest.program_dialog.available"),
          start: utcDateArrayToDateWithTimezoneFormatted(el.start, null),
          end: utcDateArrayToDateWithTimezoneFormatted(el.end, null),
          startArray: el.start,
          endArray: el.end,
          checked: false,
        };
      });
    },
    getEventColor(event) {
      return event.checked ? "green" : "primary";
    },
    getPersonName(item) {
      return item.name + " " + item.surname;
    },
    save() {
      this.loadingEdition = true;
      let limitDate = dateArrayToDate(this.availabilities[0].startArray);
      limitDate.setDate(limitDate.getDate() + 28);
      let courseSchedule = {
        limitDate: dateToDateArray(limitDate),
        productRequestId: this.productRequest.id,
        schedule: this.selectedSlots.map((el) => {
          return { start: el.startArray, end: el.endArray };
        }),
        studentsIds: this.students,
        teacherId: this.teacher.id,
      };
      return EditionEntityRepository.createCourseEdition(courseSchedule)
        .then(() => {
          this.dialog = false;
          this.$emit("scheduled", this.productRequest.id);
          this.$notify({
            title: this.$t("course.messages.programmed"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
        })
        .catch((e) => this.$log.debug("Fail to save edition", e.response))
        .finally(() => (this.loadingEdition = false));
    },
    /**
     * Handler for slot selection: selects clicked slot if there are classes left
     * or there's a class selected on same day
     */
    selectSlot(event) {
      let index = this.selectedSlots.findIndex(
        (el) =>
          el.endArray[0] == event.event.endArray[0] &&
          el.endArray[1] == event.event.endArray[1] &&
          el.endArray[2] == event.event.endArray[2]
      );
      if (event.event.checked) {
        event.event.checked = false;
        this.selectedSlots.splice(index, 1);
      } else {
        if (this.selectedSchedule && index == -1) {
          this.$notify();
        } else {
          if (index != -1) {
            this.selectedSlots[index].checked = false;
            this.selectedSlots.splice(index, 1);
          }
          event.event.checked = true;
          this.selectedSlots.push(event.event);
        }
      }
    },
    updateAvailabilities() {
      this.selectedSlots = [];
      this.loadingAvailabilities = true;
      return ProductRequestEntityRepository.getCommonAvailabilities(
        this.teacher.id,
        this.students,
        this.productRequest.id
      )
        .then((data) => {
          this.availabilities = this._formatAvailabilities(data);
          this.focus = localDateToVCalendarString(
            this.availabilities[0].startArray
          );
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching availabilities for teacher with ID " +
              this.teacher.id +
              " and student with ID " +
              this.productRequest.student.id
          )
        )
        .finally(() => (this.loadingAvailabilities = false));
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
.v-calendar >>> .v-calendar-daily_head-day-label {
  display: none;
}
.v-calendar >>> .v-calendar-daily_head-weekday {
  color: black !important;
}
</style>
