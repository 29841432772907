export default [
  {
    text: "company.types.COMPANY",
    value: "COMPANY",
    color: "success",
  },
  {
    text: "company.types.SCHOOL",
    value: "SCHOOL",
    color: "warning",
  },
  {
    text: "company.types.INSTITUTION",
    value: "INSTITUTION",
    color: "error",
  },
];
