<template>
  <v-container>
    <v-row align="center" justify="space-between">
      <v-col class="mx-4 d-none d-md-block">
        <span class="headline no-split-words">
          {{
            !simplified
              ? $t($route.meta.label)
              : $t("course.actions.edit_details")
          }}
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn @click="back">
          <v-icon>mdi-arrow-left</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("actions.cancel") }}
          </span>
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!validCourse"
          class="success ml-2"
          @click="save"
        >
          <v-icon>save</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
        </v-btn>
      </v-col>
    </v-row>

    <v-form v-model="validForm">
      <v-row justify="center">
        <v-col cols="12" :sm="!simplified ? '6 ' : '12'" class="text-center">
          <v-row :no-gutters="!simplified">
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <v-text-field
                dense
                outlined
                v-model="course.title"
                class="required"
                :rules="[(v) => !!v || $t('course.error.required')]"
                :label="$t('course.prop.title')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <language-selector
                v-model="language"
                class="required"
                dense
                :disabled="entity != null && entity.id != null"
                :label="$t('language.name')"
                outlined
                @input="onLanguageChange"
              ></language-selector>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <level-selector
                v-model="course.level"
                class="required"
                dense
                :label="$t('course.prop.level')"
                :language-filter="language ? language.id : null"
                outlined
                :rules="[(v) => !!v || $t('course.error.required')]"
                @input="onLevelChange"
              ></level-selector>
            </v-col>

            <v-col cols="12" md="12" lg="12">
              <v-textarea
                dense
                outlined
                v-model="course.description"
                :rules="[]"
                :label="$t('course.prop.description')"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="!simplified">
            <v-col cols="12">
              <unit-list-form
                v-model="course.units"
                @validation-update="(val) => (validUnits = val)"
              >
              </unit-list-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import UnitListForm from "./UnitListForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "CourseForm",
  components: {
    LanguageSelector,
    LevelSelector,
    UnitListForm,
  },
  props: {
    entity: {
      type: Object,
      required: false,
    },
    simplified: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      course: {},
      language: {},
      loading: false,
      validForm: true,
      validUnits: true,
    };
  },
  computed: {
    validCourse() {
      return this.validForm && this.validUnits;
    },
  },
  created() {
    if (this.entity) {
      Object.assign(this.course, this.entity);
      Object.assign(this.language, this.entity.level.language);
    }
  },
  methods: {
    onLanguageChange(newLanguage) {
      if (newLanguage && newLanguage.id !== this.course.level?.language.id) {
        this.course.level = null;
      }
    },
    onLevelChange(newLevel) {
      if (newLevel) {
        this.language = newLevel.language;
      }
    },
    back() {
      this.$emit("cancelEdit");
    },
    save() {
      this.loading = true;
      CourseEntityRepository.save(this.course)
        .then((res) => {
          this.$notify({
            title: "Course Saved",
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("saved", res);
        })
        .catch(() => this.$log.debug("Error saving course: " + this.course));
    },
  },
};
</script>

<style></style>
