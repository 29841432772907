<template>
  <lesson-form
    :simplified="false"
    @cancelEdit="cancelCreation"
    @saved="savedLevelTest"
  ></lesson-form>
</template>

<script>
import LessonForm from "@/mockups/content-cloud/_components/LessonForm.vue";

export default {
  name: "LevelTestForm",
  components: {
    LessonForm,
  },
  methods: {
    cancelCreation() {
      this.$router.push({
        name: "LevelTest List",
        params: { backAction: true },
      });
    },
    savedLevelTest(entity) {
      this.$router.push({
        name: "LevelTest Detail",
        params: { id: entity.id },
      });
    },
  },
};
</script>
