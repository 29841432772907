var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"6"}},[(_vm.custom_title)?_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.custom_title)+" ")]):_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[(_vm.backBtn)?_c('v-btn',{on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")])],1):_vm._e(),_c('v-btn',{staticClass:"success ml-3",attrs:{"disabled":!_vm.validForm || _vm.image.loading},on:{"click":function($event){return _vm.$emit('save')}}},[_c('v-icon',[_vm._v("save")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"3","xl":"3"}},[_c('image-edit',{model:{value:(_vm.entity),callback:function ($$v) {_vm.entity=$$v},expression:"entity"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isAdmin)?_c('number-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('profile.fields.billing_id'),"rules":[
                  function (v) { return !v ||
                    v < 10000 ||
                    this$1.$t('profile.fields.validation.billing-id-size'); } ]},model:{value:(_vm.entity.billingId),callback:function ($$v) {_vm.$set(_vm.entity, "billingId", $$v)},expression:"entity.billingId"}}):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"9","lg":"9","xl":"9"}},[_c('v-row',{attrs:{"dense":"","outlined":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"domain","rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.firstName_required'); } ],"label":_vm.$t('company.prop.name')},model:{value:(_vm.entity.companyName),callback:function ($$v) {_vm.$set(_vm.entity, "companyName", $$v)},expression:"entity.companyName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.username_required'); } ],"label":_vm.$t('profile.fields.display_name')},model:{value:(_vm.entity.displayName),callback:function ($$v) {_vm.$set(_vm.entity, "displayName", $$v)},expression:"entity.displayName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"person","rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.firstName_required'); } ],"label":_vm.$t('company.prop.representative_name')},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"person","rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.lastName_required'); } ],"label":_vm.$t('company.prop.representative_surname')},model:{value:(_vm.entity.surname),callback:function ($$v) {_vm.$set(_vm.entity, "surname", $$v)},expression:"entity.surname"}})],1),(_vm.account_details || _vm.registerFields)?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"person","type":"text","maxlength":"50","rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.username_required'); },
                  function (v) { return !v ||
                    this$1.regex.LOGIN_REGEX.test(v) ||
                    _vm.$t('user_management.loginValid'); } ],"label":_vm.$t('profile.fields.login')},on:{"input":function($event){_vm.entity.login = _vm.entity.login.toLowerCase()}},model:{value:(_vm.entity.login),callback:function ($$v) {_vm.$set(_vm.entity, "login", $$v)},expression:"entity.login"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":_vm.account_details || _vm.registerFields ? 4 : 6}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"email","rules":_vm.emailRules,"label":_vm.$t('profile.fields.main_email')},model:{value:(_vm.entity.mainEmail),callback:function ($$v) {_vm.$set(_vm.entity, "mainEmail", $$v)},expression:"entity.mainEmail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":_vm.account_details || _vm.registerFields ? 4 : 6}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"email","rules":[
                  function (v) { return !v ||
                    this$1.regex.EMAIL_REGEX.test(v) ||
                    this$1.$t('account.form.validation.email_valid'); } ],"label":_vm.$t('profile.fields.secondary_email')},model:{value:(_vm.entity.secondaryEmail),callback:function ($$v) {_vm.$set(_vm.entity, "secondaryEmail", $$v)},expression:"entity.secondaryEmail"}})],1),(_vm.registerFields)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"vpn_key","maxLength":"100","append-icon":_vm.hidePassword1 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.form.password'),"rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                  function (value) { return value.length <= 100 ||
                    _vm.$t('account.form.validation.password_less_chars'); },
                  function (value) { return value.length > 3 ||
                    this$1.$t('account.form.validation.password_more_chars'); } ],"type":_vm.hidePassword1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword1 = !_vm.hidePassword1); }},model:{value:(_vm.entity.password),callback:function ($$v) {_vm.$set(_vm.entity, "password", $$v)},expression:"entity.password"}})],1):_vm._e(),(_vm.registerFields)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword2 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.actions.repeat_password'),"rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                  _vm.passwordConfirmationRule ],"type":_vm.hidePassword1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword2 = !_vm.hidePassword2); }},model:{value:(_vm.entity.password2),callback:function ($$v) {_vm.$set(_vm.entity, "password2", $$v)},expression:"entity.password2"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.phone_required'); } ],"append-icon":"phone","label":_vm.$t('profile.fields.main_phone')},model:{value:(_vm.entity.mainPhone),callback:function ($$v) {_vm.$set(_vm.entity, "mainPhone", $$v)},expression:"entity.mainPhone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"phone","label":_vm.$t('profile.fields.secondary_phone')},model:{value:(_vm.entity.secondaryPhone),callback:function ($$v) {_vm.$set(_vm.entity, "secondaryPhone", $$v)},expression:"entity.secondaryPhone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","outlined":"","label":_vm.$t('company.prop.type'),"rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.type_required'); } ],"items":_vm.companyTypes,"item-text":_vm.translate},model:{value:(_vm.entity.type),callback:function ($$v) {_vm.$set(_vm.entity, "type", $$v)},expression:"entity.type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.address_required'); } ],"append-icon":"mdi-home-city","label":_vm.$t('profile.fields.address')},model:{value:(_vm.entity.address),callback:function ($$v) {_vm.$set(_vm.entity, "address", $$v)},expression:"entity.address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.town_required'); } ],"append-icon":"mdi-city","label":_vm.$t('profile.fields.town')},model:{value:(_vm.entity.town),callback:function ($$v) {_vm.$set(_vm.entity, "town", $$v)},expression:"entity.town"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.postal_code_required'); } ],"label":_vm.$t('profile.fields.postalcode')},model:{value:(_vm.entity.postalcode),callback:function ($$v) {_vm.$set(_vm.entity, "postalcode", $$v)},expression:"entity.postalcode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.country_required'); } ],"label":_vm.$t('profile.fields.country')},model:{value:(_vm.entity.country),callback:function ($$v) {_vm.$set(_vm.entity, "country", $$v)},expression:"entity.country"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.cif_required'); } ],"append-icon":"mdi-badge-account-horizontal","label":_vm.$t('company.prop.cif')},model:{value:(_vm.entity.nif),callback:function ($$v) {_vm.$set(_vm.entity, "nif", $$v)},expression:"entity.nif"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4"}},[_c('time-zone-selector',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.timezone_required'); } ],"label":_vm.$t('profile.fields.timeZone'),"autocomplete":"no"},model:{value:(_vm.entity.timeZone),callback:function ($$v) {_vm.$set(_vm.entity, "timeZone", $$v)},expression:"entity.timeZone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('language-selector',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                  function (v) { return !!v || _vm.$t('company.form.validation.language_required'); } ],"label":_vm.$t('profile.fields.language')},model:{value:(_vm.entity.langKey),callback:function ($$v) {_vm.$set(_vm.entity, "langKey", $$v)},expression:"entity.langKey"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('v-switch',{staticClass:"ma-2 tp-0",attrs:{"dense":"","outlined":"","label":_vm.$t('profile.fields.accept_emails')},model:{value:(_vm.entity.acceptEmails),callback:function ($$v) {_vm.$set(_vm.entity, "acceptEmails", $$v)},expression:"entity.acceptEmails"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('v-switch',{staticClass:"ma-2 tp-0",attrs:{"dense":"","outlined":"","label":_vm.$t('profile.fields.accept_whats')},model:{value:(_vm.entity.acceptWhats),callback:function ($$v) {_vm.$set(_vm.entity, "acceptWhats", $$v)},expression:"entity.acceptWhats"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4"}},[_c('v-switch',{staticClass:"ma-2 tp-0",attrs:{"dense":"","outlined":"","label":_vm.$t('profile.fields.accept_calls')},model:{value:(_vm.entity.acceptCalls),callback:function ($$v) {_vm.$set(_vm.entity, "acceptCalls", $$v)},expression:"entity.acceptCalls"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"dense":"","outlined":"","rules":[],"label":_vm.$t('profile.fields.accept_commercial_communication')},model:{value:(_vm.entity.acceptCommercialCommunications),callback:function ($$v) {_vm.$set(_vm.entity, "acceptCommercialCommunications", $$v)},expression:"entity.acceptCommercialCommunications"}})],1),(!_vm.entity.id)?_c('span',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"required",attrs:{"dense":"","outlined":"","rules":[
                function (v) { return v || _vm.$t('account.form.validation.rgpd_required'); } ],"label":_vm.$t('profile.fields.accept_rgpd')},model:{value:(_vm.entity.acceptRGPD),callback:function ($$v) {_vm.$set(_vm.entity, "acceptRGPD", $$v)},expression:"entity.acceptRGPD"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(_vm.$t('profile.legal_text.rgpd'))}})])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }