<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col>
        <v-checkbox
          dense
          v-model="showPast"
          :label="$t('edition.filters.show_past_courses')"
          class="mt-0 no-split-words"
          @change="redirectOnFilterChange"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1 rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="registrations"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          @click:row="entityDetail"
          :item-class="rowClass"
        >
          <template v-slot:[`item.status`]="{ item }">
            <span>
              {{
                $t(`registration.status.${item.status}`, {
                  date: $d(dateArrayToDate(item.withdrawReqDate)),
                })
              }}
            </span>
          </template>
          <template v-slot:[`item.productType`]="{ item }">
            <span>
              {{ $t(`registration.product_type.${item.productType}`) }}
            </span>
          </template>
          <template v-slot:[`item.paymentType`]="{ item }">
            <span>
              {{ $t(`registration.payment_type.${item.paymentType}`) }}
            </span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span>
              {{ $n(item.price, "currency") }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status == 'ACTIVE' && isAdult"
                  icon
                  @click.stop="selectEntityToDelete(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">delete</v-icon></v-btn
                >
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo de confirmacion de baja de un curso -->
    <withdraw-dialog
      v-if="dropRegistrationDialog"
      :registration="selectedRegistration"
      @requested="closeDropRegistrationDialog"
      @cancel="closeDropRegistrationDialog"
    ></withdraw-dialog>
  </v-container>
</template>

<script>
import {
  dateArrayToDate,
  localDateToISOString,
} from "@/common/conversion-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import auth from "@/common/auth";
import WithdrawDialog from "@/mockups/student/components/WithdrawDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import moment from "moment-timezone";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const RegistrationEntityRepository = RepositoryFactory.get(
  "RegistrationEntityRepository"
);
export default {
  name: "RegistrationTab",
  components: {
    WithdrawDialog,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort) || [
          "registrationDate",
        ],
        sortDesc: parseStringToSortDesc(this.$route.query.sort) || [false],
      },
      registrations: [],
      selectedRegistration: null,
      dropRegistrationDialog: false,
      tableFooterProps,
      currentDate: moment
        .tz(auth.getUser().timeZone)
        .toISOString()
        .slice(0, 10),
      showPast: this.$route.query.showPast === "true",
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isTutor", "isAnyStudent"]),
    headers() {
      return [
        {
          text: this.$t("registration.prop.status"),
          value: "status",
        },
        {
          text: this.$t("registration.prop.title"),
          value: "title",
        },
        {
          text: this.$t("registration.prop.product_type"),
          value: "productType",
        },
        {
          text: this.$t("registration.prop.payment_type"),
          value: "paymentType",
        },
        {
          text: this.$t("registration.prop.price"),
          value: "price",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    isAdult() {
      return auth.getUser() ? auth.getUser().isAdult : false;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.onPaginationChange();
      },
      deep: true,
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const sortMapping = {
        title: "edition.product.title",
        productType: "edition.product.productType",
        paymentType: "edition.paymentType",
        price: "edition.price",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          id: this.studentId,
          sort: generateSort(this.pagination, sortMapping),
          size: this.pagination.itemsPerPage,
        },
      };
      const request = this.showPast
        ? RegistrationEntityRepository.getAll
        : RegistrationEntityRepository.getActiveByStudent;
      request(options)
        .then((data) => {
          this.registrations = data.content;
          this.totalItems = data.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching registrations with params:  " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this._fetchData();
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.showPast = this.showPast ? this.showPast.toString() : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    rowClass(item) {
      let formattedEndDate;
      if (item.edition.endDate) {
        formattedEndDate = localDateToISOString(item.edition.endDate).slice(
          0,
          10
        );
      }
      if (formattedEndDate < this.currentDate || item.status === "INACTIVE") {
        return "table-row-secondary-color";
      }
    },
    selectEntityToDelete(entity) {
      if (entity.status !== "ACTIVE") {
        return this.$notify({
          title: this.$t("registration.error.status-invalid"),
          type: "error",
          duration: 30000,
        });
      }
      this.selectedRegistration = entity;
      this.dropRegistrationDialog = true;
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        const route = this.isAnyStudent
          ? "ProfileStudentRegistrationDetail"
          : this.isAdmin
          ? "StudentRegistrationDetail"
          : this.isTutor
          ? "Tutor StudentRegistrationDetail"
          : null;
        if (route !== null) {
          this.$router.push({
            name: route,
            params: { registrationId: entity.id, backPrevious: true },
          });
        }
      }
    },
    closeDropRegistrationDialog(registrationChanged) {
      if (registrationChanged) {
        this.selectedRegistration.status = registrationChanged.status;
        this.selectedRegistration.withdrawConfDate =
          registrationChanged.withdrawConfDate;
        this.selectedRegistration.withdrawReqDate =
          registrationChanged.withdrawReqDate;
      }
      this.selectedRegistration = null;
      this.dropRegistrationDialog = false;
    },
    dateArrayToDate,
  },
};
</script>
