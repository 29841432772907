<template>
  <v-container fluid v-if="items">
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>

            <v-btn color="success ml-2" :to="{ name: 'Create Product' }">
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="space-between">
          <v-col cols="6" md="2" xl="1">
            <debounced-text-field
              dense
              v-model="titleFilter"
              :label="$t('product.prop.title')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <debounced-text-field
              dense
              v-model="descriptionFilter"
              :label="$t('product.prop.description')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <v-select
              dense
              clearable
              :items="productTypes"
              :item-text="translate"
              :menu-props="{ offsetY: true }"
              v-model="productTypeFilter"
              :label="$t('product.prop.productType')"
            >
            </v-select>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <number-field
              :debouncing="300"
              v-model="minStudentsFilter"
              type="integer"
              :label="$t('product.prop.min_students')"
            ></number-field>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <number-field
              :debouncing="300"
              v-model="maxStudentsFilter"
              type="integer"
              :label="$t('product.prop.max_students')"
            ></number-field>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <v-select
              dense
              clearable
              :items="productStatus"
              :item-text="translate"
              :menu-props="{ offsetY: true }"
              v-model="statusFilter"
              :label="$t('product.prop.status')"
            >
            </v-select>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <number-field
              :debouncing="300"
              v-model="classDurationFilter"
              type="long"
              :label="$t('product.prop.class_duration')"
            ></number-field>
          </v-col>

          <v-col cols="6" md="2" xl="1">
            <number-field
              :debouncing="300"
              v-model="classesPerWeekFilter"
              type="integer"
              :label="$t('product.prop.classes_per_week')"
            ></number-field>
          </v-col>

          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: startDateFilter,
                label: 'product.prop.start_date',
              }"
              @update-time="
                updateDateTime('startDateFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: endDateFilter,
                label: 'product.prop.end_date',
              }"
              @update-time="
                updateDateTime('endDateFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <autocomplete
              dense
              no-filter
              solo
              :debouncing="300"
              :items="courses.items"
              :loading="courses.loading"
              :search-input.sync="courseSearch"
              v-model="courseFilter"
              :label="$t('product.prop.course')"
              item-text="title"
              item-value="id"
            >
            </autocomplete>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <level-selector
              v-model="levelFilter"
              append_icon="school"
              clearable
              dense
              :label="$t('product.prop.level')"
              outlined
            ></level-selector>
          </v-col>
        </v-row>

        <v-data-table
          class="rows-clickable"
          :headers="headers"
          :items="items"
          @click:row="entityDetail"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="tableFooterProps"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span v-if="item.startDate">
              {{ item.startDate | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            <span v-if="item.endDate">
              {{ item.endDate | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.productStatus`]="{ item }">
            <span v-if="item.productStatus">
              {{ $t(`product.product_status.${item.productStatus}`) }}
            </span>
          </template>

          <template v-slot:[`item.productType`]="{ item }">
            <span v-if="item.productType">
              <v-icon
                v-if="item.recipientType == 'COMPANY'"
                color="primary"
                class="mr-2"
                >mdi-domain</v-icon
              >
              <v-icon
                v-if="item.recipientType == 'GENERAL'"
                color="primary"
                class="mr-2"
                >mdi-account</v-icon
              >
              {{ $t(`product.product_type.${item.productType}`) }}
            </span>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{
                (item.level.name +
                  " - " +
                  $t("languages." + item.level.language.name))
                  | uppercase
              }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-row v-if="!loadingRow[item.id]">
              <v-tooltip
                v-if="item.productStatus !== 'PUBLIC'"
                top
                open-delay="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.stop="updateState(item, 'PUBLIC')"
                    color="primary"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> visibility </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("product.messages.enable_public") }}</span>
              </v-tooltip>

              <v-tooltip v-else top open-delay="150">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.stop="updateState(item, 'DISABLED')"
                    color="secondary"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> visibility_off </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("product.messages.disable_public") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="warning"
                    v-if="item.productStatus !== 'PUBLIC'"
                    @click.stop="editEntity(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> edit </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("actions.edit") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-if="item.productStatus !== 'PUBLIC'"
                    @click.stop="showDeleteDialog(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> delete </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("actions.delete") }}</span>
              </v-tooltip>
            </v-row>
            <loading-spinner v-else></loading-spinner>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-dialog
      :dialog="dialog"
      :loading="deleteLoading"
      @cancel="dialog = false"
      @submit="deleteEntity"
    ></delete-dialog>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import { dateArrayToDateString } from "@/common/conversion-utils";
import { translate } from "@/common/translation-utils";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import NumberField from "@/components/number-field/NumberField.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import productTypes from "@/enumerates/ProductType";
import productStatus from "@/enumerates/ProductStatus";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";
import LevelSelector from "@/components/selectors/LevelSelector";

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "AdminProductList",
  components: {
    LevelSelector,
    LoadingSpinner,
    DeleteDialog,
    Autocomplete,
    DebouncedTextField,
    NumberField,
    DateAndHourPicker,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      showFilters: false,
      productTypes,
      productStatus,
      courseSearch: null,
      dialog: false,
      selected: null,
      titleFilter: null,
      descriptionFilter: null,
      productTypeFilter: null,
      minStudentsFilter: null,
      maxStudentsFilter: null,
      statusFilter: null,
      classDurationFilter: null,
      classesPerWeekFilter: null,
      startDateFilter: null,
      endDateFilter: null,
      courseFilter: null,
      levelFilter: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      courses: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
      loadingRow: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("product.prop.title"),
          value: "title",
        },
        {
          text: this.$t("product.prop.productType"),
          value: "productType",
        },
        {
          text: this.$t("product.prop.status"),
          value: "productStatus",
        },
        {
          text: this.$t("product.prop.start_date"),
          value: "startDate",
        },
        {
          text: this.$t("product.prop.end_date"),
          value: "endDate",
        },
        {
          text: this.$t("product.prop.level"),
          value: "level",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    filters() {
      let filters = "";

      filters =
        filters +
        (this.titleFilter != null && this.titleFilter !== ""
          ? "title:" + this.titleFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.descriptionFilter != null && this.descriptionFilter !== ""
          ? "description:" + this.descriptionFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.productTypeFilter != null && this.productTypeFilter !== ""
          ? "productType:" + this.productTypeFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.minStudentsFilter != null && this.minStudentsFilter !== ""
          ? "minStudents:" + this.minStudentsFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.maxStudentsFilter != null && this.maxStudentsFilter !== ""
          ? "maxStudents:" + this.maxStudentsFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.statusFilter != null && this.statusFilter !== ""
          ? "status:" + this.statusFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.classDurationFilter != null && this.classDurationFilter !== ""
          ? "classDuration:" + this.classDurationFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.classesPerWeekFilter != null && this.classesPerWeekFilter !== ""
          ? "classesPerWeek:" + this.classesPerWeekFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.startDateFilter
          ? "startDate:" + dateArrayToDateString(this.startDateFilter)
          : "");

      filters = filters + (this.startDateFilter ? "," : "");

      filters =
        filters +
        (this.endDateFilter
          ? "endDate:" + dateArrayToDateString(this.endDateFilter)
          : "");
      filters = filters + (this.endDateFilter ? "," : "");

      filters =
        filters +
        (this.courseFilter
          ? "course.id:" + this.courseFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.levelFilter
          ? "level.id:" + this.levelFilter.id.toString() + ","
          : "");

      filters = filters + "deletionDate:null,";

      return filters !== "" ? filters : null;
    },
  },
  watch: {
    courseSearch() {
      this.getCourseItems();
    },
    filters() {
      this.redirectOnFilterChange();
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.titleFilter) {
      this.showFilters = true;
      this.titleFilter = this.$route.query.titleFilter;
    }
    if (this.$route.query.descriptionFilter) {
      this.showFilters = true;
      this.descriptionFilter = this.$route.query.descriptionFilter;
    }
    if (this.$route.query.productTypeFilter) {
      this.showFilters = true;
      this.productTypeFilter = this.$route.query.productTypeFilter;
    }
    if (this.$route.query.minStudentsFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.minStudentsFilter);
      this.minStudentsFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.maxStudentsFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.maxStudentsFilter);
      this.maxStudentsFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.statusFilter) {
      this.showFilters = true;
      this.statusFilter = this.$route.query.statusFilter;
    }
    if (this.$route.query.classDurationFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.classDurationFilter);
      this.classDurationFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.classesPerWeekFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.classesPerWeekFilter);
      this.classesPerWeekFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.startDateFilter) {
      this.showFilters = true;
      this.startDateFilter = this.$route.query.startDateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.endDateFilter) {
      this.showFilters = true;
      this.endDateFilter = this.$route.query.endDateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.courseFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.courseFilter);
      this.courseFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.levelFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    this.getCourseItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        productStatus: "status",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          sort: generateSort(this.entitiesPage, sortMapping),
          size: this.entitiesPage.itemsPerPage,
        },
      };
      ProductEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching product list with params: " + options)
        )
        .finally(() => (this.loading = false));
    },
    getCourseItems() {
      this.courses.loading = true;
      const options = {
        params: {
          search: this.courseSearch,
        },
      };
      CourseEntityRepository.getAll(options)
        .then((response) => (this.courses.items = response.content))
        .catch(() =>
          this.$log.debug("Error fetching courses with params: " + options)
        )
        .finally(() => (this.courses.loading = false));
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Product Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0 && entity.productStatus !== "PUBLIC") {
        this.$router.push({
          name: "Edit Product",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.$set(this.loadingRow, this.selected.id, false);
      this.selected = null;
      this.dialog = false;
      this.deleteLoading = false;
    },
    deleteEntity() {
      this.deleteLoading = true;
      this.$set(this.loadingRow, this.selected.id, true);
      ProductEntityRepository.delete(this.selected.id)
        .then((res) => {
          res.data !== ""
            ? this.$notify({
                text: this.$t("product.messages.delete_success"),
                type: "success",
              })
            : this.$notify({
                text: this.$t("product.error.delete_error"),
                type: "error",
                duration: 30000,
              });
        })
        .catch(() =>
          this.$log.debug("Error deleting product with ID " + this.selected.id)
        )
        .finally(() => {
          this.getItems();
          this.closeDeleteDialog();
        });
    },
    updateState(item, state) {
      this.$set(this.loadingRow, item.id, true);

      // Make a local copy
      let itemCpy = JSON.parse(JSON.stringify(item));
      itemCpy.productStatus = state;

      // Try to persist data
      ProductEntityRepository.updateState(itemCpy)
        .then(() => {
          item.productStatus = state;
          this.$notify({
            title: this.$t("product.messages.update_success"),
            type: "success",
          });
        })
        .catch(() =>
          this.$notify({
            title: this.$t("product.messages.update_error"),
            type: "error",
            duration: 30000,
          })
        )
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this.getItems();
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    changeQueryFilters(query) {
      query.titleFilter =
        this.titleFilter != null ? this.titleFilter : undefined;

      query.descriptionFilter =
        this.descriptionFilter != null ? this.descriptionFilter : undefined;

      query.productTypeFilter =
        this.productTypeFilter != null ? this.productTypeFilter : undefined;

      query.minStudentsFilter = this.minStudentsFilter?.toString();

      query.maxStudentsFilter = this.maxStudentsFilter?.toString();

      query.statusFilter = this.statusFilter?.toString();

      query.classDurationFilter = this.classDurationFilter?.toString();

      query.classesPerWeekFilter = this.classesPerWeekFilter?.toString();

      query.startDateFilter = this.startDateFilter
        ? dateArrayToDateString(this.startDateFilter)
        : undefined;

      query.endDateFilter = this.endDateFilter
        ? dateArrayToDateString(this.endDateFilter)
        : undefined;

      query.courseFilter = this.courseFilter?.toString();

      query.levelFilter = this.levelFilter?.toString();
    },
    translate,
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
