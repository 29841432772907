<template>
  <component :is="defaultStyle" v-if="activityListOpened">
    <hsc-window
      class="list-activities__window"
      :isScrollable="true"
      overflow="hidden"
      :width.sync="width"
      :height.sync="height"
      :left.sync="xpos"
      :top.sync="ypos"
    >
      <div class="list-activities__content">
        <div
          class="list-activities__header"
          @mousedown.stop.prevent="mousedown"
        >
          <div class="list-activities__header__title">
            <span>{{ $t("classroom.activities.list") }}</span>
          </div>
          <div class="list-activities__header__close">
            <button
              type="button"
              class="list-activities__header__button"
              @click="close()"
            >
              <icon
                name="close"
                width="16"
                height="16"
                :title="$t('chat.back')"
              ></icon>
            </button>
          </div>
        </div>
        <v-row>
          <v-col>
            <debounced-text-field
              dense
              v-model="titleFilter"
              :label="$t('activity.prop.title-description')"
            ></debounced-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-autocomplete
              dense
              clearable
              no-filter
              :debouncing="300"
              :items="resourceType"
              :item-text="translate"
              :search-input.sync="resourceTypeSearch"
              v-model="resourceTypeFilter"
              item-value="value"
              :label="$t('activity.prop.resource_type')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="7">
            <v-autocomplete
              v-model="levelFilter"
              clearable
              :debouncing="300"
              dense
              :items="levels.items"
              item-value="id"
              :label="$t('activity.prop.level')"
              :loading="levels.loading"
              no-filter
              chips
              small-chips
              return-object
              :search-input.sync="levelSearch"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.language.name }}</span>
                </v-chip>
              </template>
              <template
                v-slot:selection="{ item, index }"
                class="centered-chips"
              >
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                  v-if="index === 0"
                  small
                >
                  <span>{{ item.name }}</span>
                </v-chip>
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                  v-if="index === 0"
                  small
                >
                  <span>
                    {{ $t(`languages.${item.language.name}`) | uppercase }}
                  </span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">{{
                  $t("autocomplete.items_selected", {
                    count: inputVal.length - 1,
                  })
                }}</span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <div
          class="list-activities__working"
          v-if="filteredActivitiesList.length > 0"
        >
          <div
            class="list-activities__roomlist__item"
            v-for="activity in filteredActivitiesList"
            v-bind:key="activity.id"
          >
            <div class="list-activities__roomlist__item__content">
              <div class="list-activities__roomlist__item__title">
                {{ activity.title }}
              </div>
              <div class="list-activities__roomlist__item__action">
                <button @click="$emit('add-activity', activity)">
                  <icon
                    name="plus"
                    width="14"
                    height="14"
                    :title="$t('classroom.global.add-more')"
                  ></icon>
                </button>
              </div>
              <div class="list-activities__roomlist__item__subtitle">
                {{ activity.description }}
              </div>
            </div>
          </div>
        </div>
        <div class="list-activities__working" v-else>
          <div class="list-activities__roomlist__item">
            <div class="list-activities__roomlist__item__content">
              <div class="list-activities__roomlist__item__title">
                {{ $t("classroom.activities.no-activities") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </hsc-window>
  </component>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ResourceTypes from "@/enumerates/ResourceType";
import VueWindow from "@hscmap/vue-window";
import { translate } from "@/common/translation-utils.js";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import {
  getChipBackColor,
  getChipTextColor,
  stringToColour,
  stringToColourText,
} from "@/common/customization";
// TODO: cambiar a la definicion hecha en @/enumerates/ResourceTypes
// cuando ya haya visores de todos los tipos de actividad
const resourceTypesWithoutLink = ResourceTypes.filter(
  (i) => i.value !== "LINK"
);

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const LevelEntityRepository = RepositoryFactory.get("LevelEntityRepository");

export default {
  name: "ClassroomActivitiesList",
  props: {
    addActivity: { type: Function },
    tabHandler: {
      type: Object,
      required: true,
    },
    language: {
      type: Number,
      required: false,
    },
    level: {
      type: Number,
      requited: false,
    },
  },
  components: {
    DebouncedTextField,
  },
  data() {
    return {
      allActivities: [],
      activityListOpened: false,
      defaultStyle: null,
      width: null,
      height: null,
      xpos: null,
      ypos: null,
      resourceType: resourceTypesWithoutLink,
      resourceTypeFilter: null,
      resourceTypeSearch: null,
      titleFilter: null,
      levelFilter: null,
      levelSearch: null,
      levels: {
        items: [],
        loading: false,
      },
    };
  },
  mounted() {
    this.mainEl = document.getElementById("main");
    if (!this.mainEl) {
      this.mainEl = document.getElementById("classroom-layout");
    }
    this.resetWindowPosAndSize();
    this.defaultStyle = VueWindow.StyleFactory({
      window: {
        color: "#000",
        backgroundColor: "transparent",
        borderRadius: "12px",
      },
      titlebar: {
        pointerEvents: "none",
      },
    });
    this.resetWindowPosAndSize();
  },
  computed: {
    tabs() {
      return this.tabHandler.state.tabs;
    },
    filteredActivitiesList() {
      return this.allActivities.filter((activity) => {
        return !this.tabs.find(
          (tab) => tab.activity && tab.activity.activityId == activity.id
        );
      });
    },
  },
  watch: {
    levelSearch() {
      this.getLevelItems();
    },
    resourceTypeSearch(newVal) {
      this.resourceTypes = newVal
        ? resourceTypesWithoutLink.filter(
            (item) =>
              this.$t(item.text).toLowerCase().indexOf(newVal.toLowerCase()) !==
              -1
          )
        : resourceTypesWithoutLink;
    },
    resourceTypeFilter() {
      this.getItems();
    },
    titleFilter() {
      this.getItems();
    },
    levelFilter() {
      this.getItems();
    },
  },
  methods: {
    async mousedown(e) {
      const baseLeft = this.xpos;
      const baseTop = this.ypos;
      const mousemove = (e2) => {
        this.xpos = baseLeft + e2.clientX - e.clientX;
        this.ypos = baseTop + e2.clientY - e.clientY;
        if (this.ypos < -9) {
          this.ypos = -9;
        }
      };
      const mouseup = async () => {
        document.removeEventListener("mousemove", mousemove);
        document.removeEventListener("mouseup", mouseup);
      };
      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);
    },
    async init() {
      this.$log.debug("ClassroomActivitiesList.init");
      if (this.level) {
        let value = parseFloat(this.level);
        this.levelFilter = isNaN(value) ? null : { id: value };
      }
      this.getLevelItems();
      this.resetWindowPosAndSize();
      this.activityListOpened = true;
    },
    resetWindowPosAndSize() {
      // Centramos en la ventana
      this.height = 420;
      this.width = 430;
      // Centramos en la ventana
      this.xpos = (this.mainEl.clientWidth - this.width) / 2;
      this.ypos = (this.mainEl.clientHeight - this.height) / 2;
    },
    close() {
      this.activityListOpened = false;
    },
    getLevelItems() {
      this.levels.loading = true;
      const options = {
        params: {
          search: this.levelSearch,
          language: this.language,
        },
      };
      LevelEntityRepository.getAll(options)
        .then((response) => {
          this.levels.items = response.content;
          if (this.levelFilter) {
            this.levelFilter = this.levels.items.find(
              (i) => i.id === this.levelFilter.id
            );
          }
        })
        .catch(() =>
          this.$log.debug("Error fetching levels with params " + options)
        )
        .finally(() => (this.levels.loading = false));
    },
    getItems() {
      const options = {
        params: {
          resourceType: this.resourceTypeFilter,
          search: this.titleFilter,
          excludeLinks: true,
          language: this.language,
          level: this.levelFilter?.id,
          active: true,
        },
      };
      ActivityEntityRepository.getAll(options)
        .then((response) => {
          this.allActivities = response.content;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching activities list with params: " + options
          )
        );
    },
    translate,
    getChipBackColor,
    getChipTextColor,
    stringToColour,
    stringToColourText,
  },
};
</script>
