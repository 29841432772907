import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "entities/files";

export default {
  async save(entity) {
    if (entity.id) {
      throw "File already exists";
    } else {
      return (
        await HTTP.post(`${RESOURCE_NAME}`, entity, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;
    }
  },
  async saveH5P(entity) {
    if (entity.id) {
      throw "File already exists";
    } else {
      return (
        await HTTP.post(`${RESOURCE_NAME}/h5p`, entity, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;
    }
  },
  async deleteTempFile(uuid) {
    return HTTP.delete(`${RESOURCE_NAME}/${uuid}`);
  },
};
