<template>
  <div>
    <!-- Previsualización antes join -->
    <div
      class="clrm-classroom__preview"
      v-if="sessionState === sessionStatusEnum.PREVIEW"
    >
      <div class="clrm-classroom__preview__content">
        <PreviewDevices
          :classroomMode="classroomInfo.classroomMode"
          :zoom="zoom"
          @cancel-preview="cancelPreview"
          @end-preview="endPreview"
        />
      </div>
    </div>
    <div
      class="clrm-classroom"
      :class="{
        'clrm-classroom--student': classroomInfo.classroomMode === 'student',
        'clrm-classroom--full-video': onlyCameras,
      }"
    >
      <div class="clrm-classroom__discussion">
        <ClassroomSettings
          class="mt-3"
          v-if="showSettings"
          :classroomMode="classroomInfo.classroomMode"
          :zoom="zoom"
          @close="showSettings = false"
        />
        <ClassroomParticipants
          ref="ClassroomParticipants"
          :classroomInfo="classroomInfo"
          :zoom="zoom"
        ></ClassroomParticipants>
        <div
          class="clrm-classroom__discussion__toggle"
          v-if="
            classroomInfo.classroomMode === 'teacher' &&
            sessionState == sessionStatusEnum.JOINED
          "
        >
          <button
            type="button"
            class="clrm-classroom__discussion__toggle__button"
            @click="switchOnlyCamera()"
          >
            <icon
              name="chevron-right"
              class="clrm-classroom__discussion__toggle__button__right"
              width="12"
              height="12"
            ></icon>
            <icon
              name="chevron-left"
              class="clrm-classroom__discussion__toggle__button__left"
              width="12"
              height="12"
            ></icon>
          </button>
        </div>
      </div>
      <div class="clrm-classroom__work">
        <ClrmTabs
          v-if="
            classroomInfo.classroomMode === 'teacher' && sessionState != null
          "
          :currentSessionActivity="currentActivity"
          :zoom="zoom"
          :tabHandler="tabHandler"
          :classroomInfo="classroomInfo"
        ></ClrmTabs>
        <ClrmTabsStudent
          v-if="
            classroomInfo.classroomMode === 'student' &&
            tabHandler.state.currentSharedTab
          "
          :zoom="zoom"
          :tab="tabHandler.state.currentSharedTab"
        ></ClrmTabsStudent>
      </div>
    </div>
  </div>
</template>

<script>
import ClrmTabs from "@/components/clrm/clrm-tabs/ClrmTabs.vue";
import ClrmTabsStudent from "@/components/clrm/clrm-tabs/ClrmTabsStudent.vue";
import TabHandler from "./TabHandler";
import PreviewDevices from "./preview/PreviewDevices.vue";
import stomp from "@/common/StompClient";
import {
  ClassroomMessageType,
  OfflineClassroomMessageType,
} from "@/common/StompClientActions";
import checkInvalidID from "@/common/checkInvalidID";
import RepositoryFactory from "@/repositories/RepositoryFactory";

import {
  mapClassroomStateGetter,
  getClassroomState,
} from "./store/mapClassroomStateGetter";
import ParticipantSessionStatus from "@/enumerates/ParticipantSessionStatus";
import classroomStateSetter from "./store/classroomStateSetter";
import classroomStateGetter from "./store/classroomStateGetter";
import ClassroomParticipants from "./participants/ClassroomParticipants";
import ClassroomSettings from "@/components/classroom/ClassroomSettings";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "Classroom",
  components: {
    ClassroomParticipants,
    ClrmTabs,
    ClrmTabsStudent,
    PreviewDevices,
    ClassroomSettings,
  },
  data() {
    return {
      tabHandler: new TabHandler(),
      showSettings: false,
      state: getClassroomState(),
      sessionStatusEnum: ParticipantSessionStatus,
      currentActivity: null,
    };
  },
  mounted() {
    this.subscribeToOfflineClassroom();
  },
  computed: {
    somebodyIsSharing() {
      if (this.deviceSharing && classroomStateGetter.getCurrentDevice()) {
        return (
          this.deviceSharing != classroomStateGetter.getCurrentDevice().deviceId
        );
      }
      return false;
    },
    ...mapClassroomStateGetter([
      "sessionState",
      "deviceSharing",
      "classroomViewMode",
    ]),
    onlyCameras() {
      return this.classroomViewMode === "CAMERAS";
    },
  },
  watch: {
    somebodyIsSharing(newValue) {
      this.tabHandler.setShareScreen(newValue);
    },
  },
  props: {
    classroomInfo: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async subscribeToOfflineClassroom() {
      await stomp.subscribeToOfflineClassroomUser(
        this.onMessageReceived,
        this.$route.params.id
      );
      await stomp.subscribeToOfflineClassroom(
        this.onMessageReceived,
        this.$route.params.id,
        classroomStateGetter.getCurrentDevice()?.deviceId
      );
    },
    cancelPreview() {
      this.$log.debug("startPreview");
      classroomStateSetter.setSessionState(ParticipantSessionStatus.OFFLINE);
    },
    endPreview() {
      this.$log.debug(`Classroom.endPreview`);
      classroomStateSetter.setSessionState(ParticipantSessionStatus.JOINING);
      this.join();
    },
    async join() {
      try {
        await this.zoom.join(this.classroomInfo);
        classroomStateSetter.setSessionState(ParticipantSessionStatus.JOINED);
      } catch (err) {
        if (err && err.response && err.response.status == 404) {
          checkInvalidID(err);
        } else {
          throw err;
        }
      }
    },
    async switchOnlyCamera() {
      await stomp.sendMessageToClassroom(this.$route.params.id, {
        type: ClassroomMessageType.SET_NEW_MODE,
        viewMode: this.onlyCameras ? "MIXED" : "CAMERAS",
      });
    },
    openSettings() {
      this.showSettings = true;
    },
    setActivity(activity) {
      if (activity.resourceType === "ACTIVITY") {
        if (activity.type_h5p) {
          this.tabHandler.setH5PActivityTab(activity, this.somebodyIsSharing);
        } else {
          this.tabHandler.setPdfActivityTab(activity, this.somebodyIsSharing);
        }
      } else if (activity.resourceType === "IMAGE") {
        this.tabHandler.setImageActivityTab(activity, this.somebodyIsSharing);
      } else if (activity.resourceType === "VIDEO") {
        this.tabHandler.setVideoActivityTab(activity, this.somebodyIsSharing);
      } else if (activity.resourceType === "AUDIO") {
        this.tabHandler.setAudioActivityTab(activity, this.somebodyIsSharing);
      } else if (activity.resourceType === "DOCUMENT") {
        this.tabHandler.setPdfActivityTab(activity, this.somebodyIsSharing);
      }
    },
    async onMessageReceived(message) {
      switch (message.type) {
        case OfflineClassroomMessageType.SHARE_TAB:
          this.setActivity(message.activity);
          break;
        case OfflineClassroomMessageType.UNSHARE_TAB:
          if (this.classroomInfo.classroomMode === "student") {
            this.$log.debug("show cameras view");
          }
          if (!this.somebodyIsSharing) {
            this.tabHandler.state.currentSharedTab = null;
          } else {
            this.tabHandler.removeLastSharedTab();
          }
          break;
        case OfflineClassroomMessageType.END_LECTURE:
          this.$router.push(`/lectures/${this.$route.params.id}`);
          break;
        case OfflineClassroomMessageType.NEW_PARTICIPANT:
          if (
            message.joinedUserName &&
            this.classroomInfo.classroomMode === "teacher"
          ) {
            this.$notify({
              title: this.$t("classroom.global.student-joined", {
                name: message.joinedUserName,
              }),
              duration: 5000,
              classes: "teacher-notification",
            });
          }
          break;
        case OfflineClassroomMessageType.UPDATE_PARTICIPANTS:
          if (
            classroomStateGetter.getSessionState() !=
            ParticipantSessionStatus.JOINED
          ) {
            classroomStateSetter.setParticipants(message.participants);
          }
          break;
        case OfflineClassroomMessageType.SUBSCRIBED:
          if (classroomStateGetter.getSessionState() != null) {
            //la conexion se ha perdido en algun momento y estamos resubscribiendo
            if (this.classroomInfo.classroomMode === "teacher") {
              //seteamos la actividad que tenemos compartida
              this.tabHandler.sendCurrentSharedTab(this.$route.params.id);
            }
            if (
              classroomStateGetter.getSessionState() ===
              ParticipantSessionStatus.JOINED
            ) {
              this.zoom.createClassroomSubscription();
            }
          } else {
            classroomStateSetter.setParticipants(message.participants);
            if (message.activity != null) {
              this.currentActivity = message.activity.lectureActivityId;
              this.setActivity(message.activity);
            }
            if (classroomStateGetter.getSessionState() == null) {
              classroomStateSetter.setSessionState(
                ParticipantSessionStatus.OFFLINE
              );
            }
          }
          break;
        case OfflineClassroomMessageType.PARTICIPANT_LEFT:
          let participantsAux = classroomStateGetter
            .getParticipants()
            .filter((el) => el.userId != message.participantLeavingId);
          classroomStateSetter.setParticipants(participantsAux);
          break;
        case OfflineClassroomMessageType.KICK:
          this.zoom.leave();
          this.$router.push(`/lectures/${this.$route.params.id}`);
          break;
      }
    },
  },
  beforeDestroy() {
    stomp.unsubscribeFromOfflineClassroom();
    stomp.unsubscribeFromClassroom();
  },
};
</script>

<style scoped>
.theme--light.v-sheet {
  color: inherit;
}

.alert-student {
  color: #4d7c96 !important;
}

.teacher-notification {
  background: #134d83;
}
</style>
