<template>
  <v-container fluid>
    <v-card flat :loading="isLoading">
      <v-row v-if="isLoading" class="mt-16 mb-16" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-card-title v-if="!isLoading">
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col cols="12" md="7" class="text-center text-sm-right">
            <v-btn class="warning" @click="edit()">
              <v-icon>edit</v-icon>
              {{ $t("actions.edit") }}
            </v-btn>
            <v-btn
              class="primary ml-3"
              :to="{ name: 'ChangePassword' }"
              v-if="isSameUser"
            >
              <v-icon>vpn_key</v-icon>
              {{ $t("account.actions.change_password") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!isLoading">
        <v-row align="start" justify="start">
          <!-- Photo, name and about me -->
          <v-col cols="12" md="3" lg="3">
            <!-- Title for mobile version -->
            <v-row
              class="d-sm-block d-md-none"
              align="center"
              justify="space-between"
              no-gutters
            >
              <div class="headline text-center">{{ fullName }}</div>
              <v-col class="text-right">
                <v-btn @click="edit()" class="warning">
                  <v-icon class="ml-2">edit</v-icon>
                  <span class="d-none d-sm-block">
                    {{ $t("actions.edit") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="centered-element">
                <v-img
                  max-height="250"
                  style="margin: auto"
                  max-width="250"
                  :src="photo"
                ></v-img>
              </v-col>
              <v-col cols="12" v-if="isAdmin">
                <span>
                  <v-icon class="mr-1">receipt</v-icon>
                  <b class="mr-4"> {{ $t("profile.fields.billing_id") }}: </b>
                  {{ entity.billingId }}
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-3 d-none d-md-block"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="pb-0">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.fields.about_me") }}
                </div>
              </v-col>
              <v-col cols="12">
                <div class="aboutme-field">
                  {{ entity.aboutMe }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- Another fields -->
          <v-col cols="12" md="9" lg="9">
            <v-row
              class="d-none d-md-block"
              align="center"
              justify="space-between"
              no-gutters
            >
              <v-col cols="12">
                <div class="headline">
                  {{ fullName }}
                  <v-chip class="ml-4" color="primary" small>{{
                    $t("student.name")
                  }}</v-chip>
                </div>
                <div class="caption">
                  {{ entity.displayName }}
                </div>
                <div class="caption">
                  {{ entity.login }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="start" no-gutters>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.contact_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.main_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.mainPhone }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.secondary_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.secondaryPhone }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.main_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.mainEmail }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.secondary_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.secondaryEmail }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-info field-info--icon field-info--multiline">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">mdi-home-city</v-icon>
                    {{ $t("profile.fields.address") }}:
                  </label>
                  <span>{{ fullAddress }}</span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_emails") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.acceptEmails ? $t("yes") : $t("no") }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_whats") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.acceptWhats ? $t("yes") : $t("no") }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" lg="4">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_calls") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.acceptCalls ? $t("yes") : $t("no") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1 pt-6">
                  {{ $t("profile.personal_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.birthdate") }}:</label
                  >
                  <span class="field-info__content" v-if="entity.birthdate">
                    {{ entity.birthdate | dateTimeWithoutTz }}
                  </span>
                </div>
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.genre") }}:</label
                  >
                  <span class="field-info__content" v-if="entity.genre">
                    {{ $t(`genre.values.${entity.genre}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div v-if="entity">
                  <div class="field-info">
                    <label class="field-info__label"
                      >{{ $t("profile.fields.nif") }}:</label
                    >
                    <span class="field-info__content" v-if="entity.nif">
                      {{ entity.nif }}
                    </span>
                  </div>

                  <div class="field-info">
                    <label class="field-info__label"
                      >{{ $t("profile.fields.nif_expiration") }}:</label
                    >
                    <span
                      class="field-info__content"
                      v-if="entity.nifExpiration"
                    >
                      {{ entity.nifExpiration | dateTimeWithoutTz }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="field-info" v-if="entity.timeZone">
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{
                      getOffset(entity.timeZone.name) +
                      " " +
                      $t(`timezones.${entity.timeZone.name}`)
                    }}
                  </span>
                </div>
                <div class="field-info" v-else>
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{ $t("profile.fields.timeZone_automatic") }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!isLoading">
        <v-col class="text-right">
          <v-btn
            @click="showDialog = true"
            class="warning"
            v-if="canUnregister()"
          >
            <span class="d-none d-sm-block">
              {{ $t("profile.unregister.button_label") }}
            </span>
          </v-btn>
          <unregister-dialog
            v-if="showDialog"
            :userId="entity.userId"
            :studentId="entity.id"
            :dialog="showDialog"
            @cancel="showDialog = false"
          ></unregister-dialog>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getOffset } from "@/common/timeZoneUtils";
import UnregisterDialog from "@/mockups/components/UnregisterDialog";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    UnregisterDialog,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      user: auth.getUser(),
      entity: {},
      loading: false,
      photo: require("@/assets/icon_visits.png"),
      showDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isTutor", "isAnyStudent", "isAdult"]),
    isLoading() {
      return this.loading;
    },
    fullName() {
      return (
        (this.entity.name ? this.entity.name : "") +
        " " +
        (this.entity.surname ? this.entity.surname : "")
      );
    },
    isSameUser() {
      return this.entity ? this.entity.userId === this.user.id : false;
    },
    fullAddress() {
      const aux = [
        this.entity.address,
        this.entity.postalcode,
        this.entity.town,
        this.entity.country,
      ].filter((i) => i && i !== "");
      return aux.join(", ");
    },
  },
  mounted() {
    this._fetchData(this.studentId);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return StudentEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          let aux = [];
          if (response) {
            UserDataEntityRepository.getPhoto(response.userId).then((data) => {
              if (data.size > 0) {
                this.photo = URL.createObjectURL(data);
              }
            });
          }
        })
        .catch(() => this.$log.debug("Error fetching student with ID: " + id))
        .finally(() => (this.loading = false));
    },
    edit() {
      if (this.$route.params.id) {
        if (this.isTutor) {
          this.$router.push({
            name: "Tutor StudentView Edit",
            params: { backPrevious: true },
          });
        } else {
          //isAdmin
          this.$router.push({
            name: "StudentView Edit",
            params: { backPrevious: true },
          });
        }
      } else {
        this.$router.push({
          name: "ProfileStudentView Edit",
          params: { backPrevious: true },
        });
      }
    },
    canUnregister() {
      return this.isTutor || (this.isAnyStudent && this.isAdult);
    },
    getOffset: getOffset,
  },
};
</script>

<style></style>
