<template>
  <v-container>
    <video-player v-if="videoSrc" :src="videoSrc" />
    <br />
  </v-container>
</template>
<script>
import VideoPlayer from "@/components/video-player/VideoPlayer";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ClrmVideoPlayer",
  components: {
    VideoPlayer,
  },
  props: {
    activity: {
      type: Object,
    },
  },
  computed: {
    videoSrc() {
      return ActivityEntityRepository.getActivityContentUrl(
        this.activity.activityId
      );
    },
  },
};
</script>
