<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="12" md="11">
          <span class="headline no-split-words">
            {{ entity.name }} {{ entity.surname }}
            <v-icon class="ml-4" v-if="entity.userId" color="primary"
              >how_to_reg
            </v-icon>
          </span>
          <div class="caption">
            {{ entity.displayName }}
          </div>
        </v-col>
        <v-col
          v-if="editable && (!entity.userId || isAdmin)"
          cols="12"
          md="1"
          class="text-right"
        >
          <v-btn color="error" @click.native="$emit('delete', entity)" icon>
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.main_phone") }}:
        </v-col>
        <v-col cols="6" md="9">
          {{ entity.mainPhone }}
        </v-col>

        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.secondary_phone") }}:
        </v-col>
        <v-col cols="6" md="9">
          {{ entity.secondaryPhone }}
        </v-col>

        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.main_email") }}:
        </v-col>
        <v-col cols="6" md="9">
          {{ entity.mainEmail }}
        </v-col>

        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.secondary_email") }}:
        </v-col>
        <v-col cols="6" md="9">
          {{ entity.secondaryEmail }}
        </v-col>

        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("tutor.prop.relationship") }}:
        </v-col>
        <v-col cols="6" md="9">
          {{ $t(`relationship.values.${entity.relationship.name}`) }}
        </v-col>

        <v-col cols="6" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.address") }}:
        </v-col>
        <v-col cols="6" md="9">
          <span>{{ fullAddress }}</span>
        </v-col>
        <v-col cols="3" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.birthdate") }}:
        </v-col>
        <v-col cols="9" md="9">
          <span v-if="entity.birthdate">
            {{ entity.birthdate | dateTimeWithoutTz }}
          </span>
        </v-col>
        <v-col cols="3" md="3" class="text-left font-weight-bold">
          {{ $t("profile.fields.genre") }}:
        </v-col>
        <v-col cols="9" md="9">
          <span v-if="entity.genre">
            {{ $t(`genre.values.${entity.genre}`) }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "TutorInfo",
  props: {
    entity: {
      type: Object,
      required: false,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    fullAddress() {
      const aux = [
        this.entity.address,
        this.entity.postalcode,
        this.entity.town,
        this.entity.country,
      ].filter((i) => i && i !== "");
      return aux.join(", ");
    },
  },
};
</script>
