<template>
  <v-container>
    <generic-profile-form
      :back-btn="false"
      register-fields
      v-if="!loading"
      v-model="entity"
      @save="save"
      include-legal
    >
    </generic-profile-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import GenericProfileForm from "@/account/profile/GenericProfileForm.vue";
import auth from "@/common/auth.js";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const AccountRepository = RepositoryFactory.get("AccountRepository");
const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");

export default {
  components: {
    LoadingPage,
    GenericProfileForm,
  },
  data() {
    return {
      loading: false,
      entity: {
        login: "",
        password: "",
        password2: "",
        langKey: "en",
      },
    };
  },
  created() {
    this._fetchData(this.$route.query.key);
  },
  methods: {
    _fetchData(key) {
      this.loading = true;
      return TutorEntityRepository.getByKey(key)
        .then((response) => {
          // Map response fields to tutor fields
          this.$set(this.entity, "mainEmail", response.tutorMainEmail);
          this.$set(
            this.entity,
            "secondaryEmail",
            response.tutorSecondaryEmail
          );
          this.$set(this.entity, "mainPhone", response.tutorMainPhone);
          this.$set(
            this.entity,
            "secondaryPhone",
            response.tutorSecondaryPhone
          );
          this.$set(this.entity, "relationship", response.relationship);
        })
        .catch(() => {
          this.$log.debug("Error fetching tutor with key " + key);
          this.$router.goToUserHome();
        })
        .finally(() => (this.loading = false));
    },
    save() {
      this.loading = true;
      AccountRepository.registerTutor(this.entity, this.$route.query.key)
        .then(() => {
          this.$notify({
            title: "Registered",
            text: this.$t("account.notifications.successfull_registered"),
            type: "success",
          });
          auth
            .login({
              login: this.entity.login,
              password: this.entity.password,
              remember_me: false,
            })
            .then(() => {
              this.$router.goToUserHome();
            })
            .catch(() => (this.loading = false));
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
