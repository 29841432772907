var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.loading)?_c('input',{ref:"interactionFileLoader",staticClass:"d-none",attrs:{"hidden":"","type":"file","accept":_vm.extensions.pdf},on:{"change":_vm.saveFile}}):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.$t("student.tabs.results_homework.title")))]),(!_vm.loading)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","solo":"","clearable":"","items":_vm.editions,"loading":_vm.loadingEditions,"label":_vm.$t('activityInteraction.prop.edition'),"item-text":"title","item-value":"id"},on:{"change":_vm.onFilterChange},model:{value:(_vm.editionFilter),callback:function ($$v) {_vm.editionFilter=$$v},expression:"editionFilter"}})],1),_c('v-col',[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
          data: _vm.dateSolvedFilterStart,
          label: 'activityInteraction.filters.dateSolvedSince',
        }},on:{"update-time":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'dateSolvedFilterStart', false ].concat( argsArray ))}}})],1),_c('v-col',[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
          data: _vm.dateSolvedFilterEnd,
          label: 'activityInteraction.filters.dateSolvedTill',
        }},on:{"update-time":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'dateSolvedFilterEnd', false ].concat( argsArray ))}}})],1)],1):_vm._e(),(!_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.homework.length === 0)?_c('v-card',{staticClass:"mx-4"},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$t("student.tabs.results_homework.no_homework"))+" ")])],1):_c('v-data-table',{attrs:{"headers":_vm.homeworkHeaders,"items":_vm.homework,"server-items-length":_vm.totalItems,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("student.tabs.results_homework.homework"))+" ")])],1)]},proxy:true},{key:"item.edition",fn:function(ref){
        var item = ref.item;
return [(item.edition && _vm.isAdult)?_c('a',{on:{"click":function($event){return _vm.goToEdition(item)}}},[_c('span',[_vm._v(_vm._s(item.edition.title))])]):_vm._e(),(item.edition && !_vm.isAdult)?_c('span',[_vm._v(_vm._s(item.edition.title))]):_vm._e(),(!item.edition)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("activityInteraction.level_test"))+" ")]):_vm._e()]}},{key:"item.dueDate",fn:function(ref){
        var item = ref.item;
return [(item.dueDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dueDate,"medium"))+" ")]):_vm._e()]}},(!_vm.isTutor)?{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(
              item.activity.resourceType === 'ACTIVITY' &&
              item.activity.typeH5p
            )?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.playActivity(item)}}},[_vm._v(" mdi-play-circle ")]):_vm._e(),(
              ((item.activity.resourceType === 'ACTIVITY' &&
                !item.activity.typeH5p) ||
                item.activity.resourceType === 'DOCUMENT') &&
              !_vm.loadingHomeworkRow[item.id]
            )?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadStatement(
                      item.activity.id,
                      _vm.loadingHomeworkRow,
                      item.id
                    )}}},'v-icon',attrs,false),on),[_vm._v(" file_download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download_statement")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.chooseFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" file_upload ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.add_file")))])])],1):_vm._e(),(_vm.loadingHomeworkRow[item.id])?_c('loading-spinner'):_vm._e(),(item.activity.resourceType === 'LINK')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.pastTime(item.dueDate) ? 'primary' : 'dark'},on:{"click":function($event){return _vm.openLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.activity.link))])]):_vm._e(),(item.activity.resourceType === 'IMAGE')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.pastTime(item.dueDate) ? 'primary' : 'dark'},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.watch_image")))])]):_vm._e(),(item.activity.resourceType === 'VIDEO')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.pastTime(item.dueDate) ? 'primary' : 'dark'},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-video ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.watch_video")))])]):_vm._e(),(item.activity.resourceType === 'AUDIO')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.pastTime(item.dueDate) ? 'primary' : 'dark'},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-music-box ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.play_audio")))])]):_vm._e()]}}:null],null,true)})],1)],1):_vm._e(),(!_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ma-4 mx-0",attrs:{"headers":_vm.resultsHeaders,"items":_vm.results,"footer-props":_vm.tableFooterProps,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("student.tabs.results_homework.results"))+" ")])],1)]},proxy:true},{key:"item.edition",fn:function(ref){
                    var item = ref.item;
return [(item.edition && _vm.isAdult)?_c('a',{on:{"click":function($event){return _vm.goToEdition(item)}}},[_c('span',[_vm._v(_vm._s(item.edition.title))])]):_vm._e(),(item.edition && !_vm.isAdult)?_c('span',[_vm._v(" "+_vm._s(item.edition.title)+" ")]):_vm._e(),(!item.edition)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("activityInteraction.level_test"))+" ")]):_vm._e()]}},{key:"item.grade",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.grade != null ? item.grade : _vm.$t("activityInteraction.messages.no_grade"))+" ")])]}},{key:"item.timeConsumed",fn:function(ref){
                    var item = ref.item;
return [(item.timeConsumed)?_c('span',[_vm._v(" "+_vm._s(item.timeConsumed)+" ")]):_c('span',[_vm._v("--")])]}},{key:"item.dateSolved",fn:function(ref){
                    var item = ref.item;
return [(item.dateSolved)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dateSolved,"medium"))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),(
              ((item.activity.resourceType == 'ACTIVITY' &&
                !item.activity.typeH5p) ||
                item.activity.resourceType == 'DOCUMENT') &&
              !_vm.loadingRow[item.id]
            )?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.downloadStatement(item.activity.id, _vm.loadingRow, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" file_download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download_statement")))])]),(item.content)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadContent(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" file_download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download_file")))])])],1):_vm._e()],1):_vm._e(),(_vm.loadingRow[item.id])?_c('loading-spinner'):_vm._e(),(item.activity.resourceType === 'IMAGE')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark"},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.watch_image")))])]):_vm._e(),(item.activity.resourceType === 'VIDEO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark"},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-video ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.watch_video")))])]):_vm._e(),(item.activity.resourceType === 'AUDIO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark"},on:{"click":function($event){return _vm.playActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-music-box ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.play_audio")))])]):_vm._e(),(item.activity.resourceType === 'LINK')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark"},on:{"click":function($event){return _vm.openLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.activity.link))])]):_vm._e()]}}],null,true)})],1)],1):_vm._e(),(_vm.loading)?_c('loading-page'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }