import RegistrationDetail from "../components/RegistrationDetail";
import StudentDetail from "../StudentDetail";
import BalanceTab from "../tabs/BalanceTab";
import DocumentsTab from "../tabs/DocumentsTab";
import DocumentFormTab from "../tabs/DocumentFormTab";
import FeedbackTab from "../tabs/FeedbackTab";
import LevelsTab from "../tabs/LevelsTab";
import MovementsTab from "../tabs/MovementsTab";
import PersonalInfoFormTab from "../tabs/PersonalInfoFormTab";
import PersonalInfoTab from "../tabs/PersonalInfoTab";
import ResultsTab from "../tabs/ResultsTab";
import RegistrationsTab from "../tabs/RegistrationsTab";
import SchedulesTab from "../tabs/ScheduleTab";
import TutorsTab from "../tabs/TutorsTab";

// ADMIN ROUTES DEFINITION
const routes = [
  {
    path: "/admin/students/:id",
    name: "StudentView",
    component: StudentDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "StudentView Detail",
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.info",
        },
        component: PersonalInfoTab,
      },
      {
        path: "edit",
        name: "StudentView Edit",
        component: PersonalInfoFormTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "profile.your_profile",
        },
      },
      {
        path: "tutors",
        name: "StudentTutorsTab",
        component: TutorsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.tutors",
        },
      },
      {
        path: "schedules",
        name: "StudentSchedulesTab",
        component: SchedulesTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.schedule",
        },
      },
      {
        path: "levels",
        name: "StudentLevelsTab",
        component: LevelsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.levels",
        },
      },
      {
        path: "documents",
        name: "StudentDocumentsTab",
        component: DocumentsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "documents/new",
        name: "StudentDocumentFormTab",
        component: DocumentFormTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.documents",
        },
      },
      {
        path: "registrations",
        name: "StudentRegistrationsTab",
        component: RegistrationsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.registrations",
        },
      },
      {
        path: "registrations/:registrationId",
        name: "StudentRegistrationDetail",
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "registration.name",
        },
        component: RegistrationDetail,
      },
      {
        path: "balance",
        name: "StudentBalanceTab",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "StudentMovementsTab",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.movements",
        },
      },
      {
        path: "feedback",
        name: "StudentFeedbackTab",
        component: FeedbackTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.feedback",
        },
      },
      {
        path: "results",
        name: "StudentResultsTab",
        component: ResultsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "student.navbar.results",
        },
      },
    ],
  },
];

export default routes;
