<template>
  <v-container fluid v-if="items">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col order="1" order-md="2" class="text-right">
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="space-between">
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: dateFilter,
                label: 'levelTestRequest.prop.date',
              }"
              @update-time="updateDateTime('dateFilter', false, ...arguments)"
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: limitDateFilter,
                label: 'levelTestRequest.prop.limitDate',
              }"
              @update-time="
                updateDateTime('limitDateFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <v-select
              v-model="stateFilter"
              clearable
              dense
              disabled
              :items="levelTestRequestStates"
              :item-text="translate"
              :label="$t('levelTestRequest.prop.state')"
              :menu-props="{ offsetY: true }"
              @change="redirectOnFilterChange"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <autocomplete
              v-model="studentFilter"
              :debouncing="300"
              dense
              :items="students.items"
              :label="$t('levelTestRequest.prop.student')"
              :loading="students.loading"
              :item-text="getFullName"
              item-value="id"
              no-filter
              :search-input.sync="studentSearch"
              solo
              @change="redirectOnFilterChange"
            >
            </autocomplete>
          </v-col>

          <v-col cols="12" md="2" xl="1">
            <autocomplete
              v-model="languageFilter"
              dense
              no-filter
              solo
              :debouncing="300"
              :items="filteredLanguages"
              :loading="languages.loading"
              :search-input.sync="languageSearch"
              :label="$t('levelTestRequest.prop.language')"
              @change="redirectOnFilterChange"
            >
            </autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options="entitiesPage"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span v-if="item.date">
              {{ item.date | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.limitDate`]="{ item }">
            <span v-if="item.limitDate">
              {{ item.limitDate | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <span v-if="item.level">
              {{ $t(`languages.${item.level.language.name}`) }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <program-level-test-dialog
              :level-test-request="item"
              @scheduled="getItems"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        large
                        icon
                        color="primary"
                      >
                        <v-icon> mdi-calendar </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("actions.accept") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </program-level-test-dialog>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  color="error"
                  @click.stop="showDeleteDialog(item)"
                >
                  <v-icon> cancel </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.reject") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="rejectRequest()"
    ></delete-dialog>
  </v-container>
</template>

<script>
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import ProgramLevelTestDialog from "./components/ProgramLevelTestDialog";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import { dateArrayToDateString } from "@/common/conversion-utils";
import { translate, translateWithLocale } from "@/common/translation-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import levelTestRequestStates from "@/enumerates/LevelTestRequestState";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const LevelTestRequestEntityRepository = RepositoryFactory.get(
  "LevelTestRequestEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const LanguageEntityRepository = RepositoryFactory.get(
  "LanguageEntityRepository"
);

export default {
  name: "levelTestRequestListList",
  components: {
    DateAndHourPicker,
    Autocomplete,
    ProgramLevelTestDialog,
    DeleteDialog,
  },
  data() {
    return {
      deleteLoading: false,
      items: [],
      showFilters: false,
      studentSearch: null,
      languageSearch: "",
      dateFilter: null,
      limitDateFilter: null,
      stateFilter: "REQUESTED",
      studentFilter: null,
      languageFilter: null,
      deleteDialog: false,
      selected: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      students: {
        items: [],
        loading: false,
      },
      languages: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      levelTestRequestStates,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("levelTestRequest.prop.student"),
          value: "studentDisplayName",
        },
        {
          text: this.$t("levelTestRequest.prop.date"),
          value: "date",
        },
        {
          text: this.$t("levelTestRequest.prop.limitDate"),
          value: "limitDate",
        },
        {
          text: this.$t("levelTestRequest.prop.language"),
          value: "level",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    filters() {
      let filters = "";
      filters =
        filters +
        (this.dateFilter
          ? "date:" + dateArrayToDateString(this.dateFilter)
          : "");
      filters = filters + (this.dateFilter ? "," : "");
      filters =
        filters +
        (this.limitDateFilter
          ? "limitDate:" + dateArrayToDateString(this.limitDateFilter)
          : "");
      filters = filters + (this.limitDateFilter ? "," : "");
      filters =
        filters +
        (this.stateFilter != null && this.stateFilter !== ""
          ? "state:" + this.stateFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.studentFilter
          ? "student.id:" + this.studentFilter.toString() + ","
          : "");
      return filters !== "" ? filters : null;
    },
    filteredLanguages() {
      const translatedLanguages = this.languages.items.map((el) => {
        return {
          value: el.id,
          text: translateWithLocale("languages", el, "name"),
        };
      });
      return this.languageSearch
        ? translatedLanguages.filter((item) =>
            item.text
              .replace(/\s+/g, "")
              .toUpperCase()
              .includes(this.languageSearch.replace(/\s+/g, "").toUpperCase())
          )
        : translatedLanguages;
    },
  },
  watch: {
    studentSearch() {
      this.getStudentItems();
    },
  },
  created() {
    if (this.$route.query.dateFilter) {
      this.showFilters = true;
      this.dateFilter = this.$route.query.dateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.limitDateFilter) {
      this.showFilters = true;
      this.limitDateFilter = this.$route.query.limitDateFilter
        .split("-")
        .map((e) => parseInt(e));
    }

    if (this.$route.query.stateFilter) {
      this.showFilters = true;
      this.stateFilter = this.$route.query.stateFilter;
    }

    if (this.$route.query.studentFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.studentFilter);
      this.studentFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.languageFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : value;
    }

    this.getItems();
    this.getStudentItems();
    this.getLanguageItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        studentDisplayName: "student.userData.displayName",
        language: "language.name",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          sort: generateSort(this.entitiesPage, sortMapping),
          filters: this.filters,
          language: this.languageFilter?.toString(),
          size: this.entitiesPage.itemsPerPage,
        },
      };
      LevelTestRequestEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching level test request list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    getStudentItems() {
      this.students.loading = true;
      const options = {
        params: {
          search: this.studentSearch,
        },
      };
      StudentEntityRepository.getAllWithFullNameSearch(options)
        .then((response) => (this.students.items = response))
        .catch(() =>
          this.$log.debug("Error fetching students with params:  " + options)
        )
        .finally(() => (this.students.loading = false));
    },
    getLanguageItems() {
      this.languages.loading = true;
      LanguageEntityRepository.getAll()
        .then((response) => (this.languages.items = response))
        .catch(() => this.$log.debug("Error fetching languages"))
        .finally(() => (this.languages.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "Level Test Request List",
          query: query,
        });
        this.getItems();
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "LevelTestRequest FormForm",
          params: { id: entity.id, backPrevious: true },
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    changeQueryFilters(query) {
      query.dateFilter = this.dateFilter
        ? dateArrayToDateString(this.dateFilter)
        : undefined;
      query.limitDateFilter = this.limitDateFilter
        ? dateArrayToDateString(this.limitDateFilter)
        : undefined;
      query.stateFilter =
        this.stateFilter != null ? this.stateFilter : undefined;
      query.studentFilter = this.studentFilter ? this.studentFilter : undefined;
      query.languageFilter = this.languageFilter
        ? this.languageFilter
        : undefined;
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    rejectRequest() {
      this.deleteLoading = true;
      this.selected.state = "REJECTED";
      LevelTestRequestEntityRepository.save(this.selected)
        .then(() => {
          this.$notify({
            title: this.$t("levelTestRequest.messages.rejected_success"),
            type: "success",
          });
          this.getItems();
        })
        .catch(() =>
          this.$log.debug("Error saving level test request: " + this.selected)
        )
        .finally(() => this.closeDeleteDialog());
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.deleteDialog = false;
      this.deleteLoading = false;
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    getFullName(item) {
      return item.name + " " + item.surname;
    },
    translate,
  },
};
</script>
