import stomp from "@/common/StompClient";
import { OfflineClassroomMessageType } from "@/common/StompClientActions";

const MAX_NUM_TABS = 10;

export default class TabHandler {
  constructor() {
    this._lastSharedTab = null;
    this._sharing = false;
    this.state = {
      disableAddTabs: false,
      tabs: [],
      currentTab: {},
      currentSharedTab: null,
    };
  }

  addTab(tab) {
    if (this.state.tabs.length < MAX_NUM_TABS) {
      this.state.tabs.push(tab);
      if (this.state.tabs.length == 1) {
        this.changeTab(tab);
      }
    }
    if (this.state.tabs.length == MAX_NUM_TABS) {
      this.state.disableAddTabs = true;
    }
  }

  closeTab(tab, clrmId) {
    if (tab.shared) {
      this.unshareCurrentTab(clrmId);
    }
    if (this.state.tabs.length > 1) {
      if (tab.name === this.state.currentTab.name) {
        let actualIndex = this.state.tabs.findIndex(
          (item) => item.name === tab.name
        );
        actualIndex =
          actualIndex === this.state.tabs.length - 1
            ? this.state.tabs.length - 2
            : actualIndex + 1;
        this.changeTab(this.state.tabs[actualIndex]);
      }
    }
    if (this.state.tabs.length == 1) {
      this.state.currentTab = {};
    }
    this.state.tabs.splice(
      this.state.tabs.findIndex((item) => item.name === tab.name),
      1
    );
    this.state.disableAddTabs = false;
  }

  changeTab(tab) {
    if (tab.name !== this.state.currentTab.name) {
      this.state.currentTab = tab;
      return true;
    }
    return false;
  }

  async shareCurrentTab(clrmId) {
    let activity = this.state.currentTab.activity;
    if (activity) {
      await stomp.sendMessageToOfflineClassroom(clrmId, {
        type: OfflineClassroomMessageType.SHARE_TAB,
        activity: {
          lectureActivityId: activity.id,
          activityId: activity.activityId,
          activityH5PId: activity.activityH5PId,
          title: activity.title,
          type_h5p: activity.type_h5p,
          resourceType: activity.resourceType,
        },
      });
    }
    this.state.currentTab.shared = true;
    this.state.tabs.forEach((t) => {
      if (t.name == this.state.currentTab.name) {
        t.shared = true;
      } else {
        t.shared = false;
      }
    });
  }

  async unshareCurrentTab(clrmId) {
    await stomp.sendMessageToOfflineClassroom(clrmId, {
      type: OfflineClassroomMessageType.UNSHARE_TAB,
    });
    this.state.currentTab.shared = false;
    this.state.tabs.find(
      (t) => t.name == this.state.currentTab.name
    ).shared = false;
  }

  setShareScreen(isSharing) {
    this._sharing = isSharing;
    if (isSharing) {
      this._lastSharedTab = this.state.currentSharedTab;
      this.state.currentSharedTab = {
        name: "ShareScreen",
        component: "ClrmShareScreen",
        title: "Share Screen",
        icon: "share-screen",
        shared: false,
      };
    } else {
      this.state.currentSharedTab = this._lastSharedTab;
      this._lastSharedTab = null;
    }
  }

  _createH5PActivityTab(activity) {
    return {
      name: "H5PActivity",
      component: "ClrmPlayH5PActivity",
      title: activity.title,
      icon: "lesson",
      shared: false,
      activity: activity,
    };
  }

  setH5PActivityTab(activity, somebodyIsSharing) {
    somebodyIsSharing
      ? (this._lastSharedTab = this._createH5PActivityTab(activity))
      : (this.state.currentSharedTab = this._createH5PActivityTab(activity));
  }

  _createPdfActivityTab(activity) {
    return {
      name: "PDFActivity",
      component: "ClrmPdf",
      title: activity.title,
      icon: "lesson",
      shared: false,
      activity: activity,
    };
  }

  setPdfActivityTab(activity, somebodyIsSharing) {
    somebodyIsSharing
      ? (this._lastSharedTab = this._createPdfActivityTab(activity))
      : (this.state.currentSharedTab = this._createPdfActivityTab(activity));
  }

  _createImageActivityTab(activity) {
    return {
      name: "ImageActivity",
      component: "ClrmImageViewer",
      title: activity.title,
      icon: "lesson",
      shared: false,
      activity: activity,
    };
  }

  setImageActivityTab(activity, somebodyIsSharing) {
    somebodyIsSharing
      ? (this._lastSharedTab = this._createImageActivityTab(activity))
      : (this.state.currentSharedTab = this._createImageActivityTab(activity));
  }

  _createVideoActivityTab(activity) {
    return {
      name: "VideoActivity",
      component: "ClrmVideoPlayer",
      title: activity.title,
      icon: "lesson",
      shared: false,
      activity: activity,
    };
  }

  setVideoActivityTab(activity, somebodyIsSharing) {
    somebodyIsSharing
      ? (this._lastSharedTab = this._createVideoActivityTab(activity))
      : (this.state.currentSharedTab = this._createVideoActivityTab(activity));
  }

  _createAudioActivityTab(activity) {
    return {
      name: "AudioActivity",
      component: "ClrmAudioPlayer",
      title: activity.title,
      icon: "lesson",
      shared: false,
      activity: activity,
    };
  }

  setAudioActivityTab(activity, somebodyIsSharing) {
    somebodyIsSharing
      ? (this._lastSharedTab = this._createAudioActivityTab(activity))
      : (this.state.currentSharedTab = this._createAudioActivityTab(activity));
  }

  setActivityTab(activity) {
    this.state.currentSharedTab = {
      name: "Activity",
      component: "ClrmLesson",
      title: activity.title,
      icon: "lesson",
      shared: false,
      //activity: activity
    };
  }

  //metodo para reenviar la tab que estaba compartida
  async sendCurrentSharedTab(clrmId) {
    let tab = this.state.tabs.find((t) => t.shared);
    if (tab != null) {
      let activity = tab.activity;
      if (activity) {
        await stomp.sendMessageToOfflineClassroom(clrmId, {
          type: OfflineClassroomMessageType.SHARE_TAB,
          activity: {
            lectureActivityId: activity.id,
            activityId: activity.activityId,
            activityH5PId: activity.activityH5PId,
            title: activity.title,
            type_h5p: activity.type_h5p,
            resourceType: activity.resourceType,
          },
        });
      }
    }
  }

  removeLastSharedTab() {
    this._lastSharedTab = null;
  }
}
