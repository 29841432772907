import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/invoices";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getXml(options = {}) {
    options.responseType = "blob";
    return HTTP.get(`${RESOURCE_NAME}/xml`, options);
  },

  async allPDF(options = {}) {
    options.responseType = "blob";
    return HTTP.get(`${RESOURCE_NAME}/allPDF`, options);
  },

  async downloadPDF(invoiceId) {
    return HTTP.get(`${RESOURCE_NAME}/pdf/${invoiceId}`, {
      responseType: "blob",
    });
  },
};
