import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#1d8383",
        accent: "#275c68",
        // success: "#40a6a6",
        // success: "#40a6a6",
        // secondary: '#b0bec5',
        // error: '#b71c1c',
      },
    },
  },
});
