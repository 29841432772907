<template>
  <v-app>
    <v-main id="main" class="layout-login">
      <div class="button-help--right mb-6 mb-md-0">
        <div class="button-help d-inline-flex align-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="mr-2">
                <img :src="selectedLanguage.flag" width="35" height="20" />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(entry, index) in languages"
                :key="index"
                @click="changeLocale(entry)"
              >
                <v-list-item-icon>
                  <img :src="entry.flag" width="35" height="20" />
                </v-list-item-icon>
                <v-list-item-title>{{ entry.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <icon
            class="button-help__icon"
            name="help"
            width="32"
            height="32"
          ></icon>
          <div class="button-help__text ml-2">
            <p class="button-help__line1 ma-0">
              {{ $t("account.login.help") }}
            </p>
            <p class="button-help__line2 ma-0">
              <a href="tel:+34657466420">657 466 420</a>
            </p>
          </div>
        </div>
      </div>

      <div class="mb-2">
        <router-link
          to="/"
          class="text-no-wrap toolbar-title"
          custom
          v-slot="{ navigate }"
        >
          <img
            @click="navigate"
            class="logo-dashboard d-block ml-auto mr-auto"
            width="170"
            height="56"
            :src="require('@/assets/images/lemsi-logo.svg')"
            alt=""
          />
        </router-link>
      </div>
      <slot class="content" />
      <div class="horizontal-links text-center mb-6">
        <a
          class="horizontal-links__link"
          target="_blank"
          rel="noopener"
          href="https://institutointerculturas.com/aviso-legal/"
        >
          {{ $t("account.login.legal-notice") }}
        </a>
        <a
          class="horizontal-links__link"
          target="_blank"
          rel="noopener"
          href="https://institutointerculturas.com/politica-de-privacidad-2/"
        >
          {{ $t("account.login.privacy-policy") }}
        </a>
      </div>
    </v-main>
    <Footer :coloredImages="true"></Footer>
  </v-app>
</template>

<script>
import Footer from "@/components/footer/Footer";
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "AppLayoutDefault",
  components: {
    Footer,
  },
  data() {
    return {
      user: auth.getUser(),
      websocket: auth.getWebsocket(),
      languages: [
        {
          flag: require("@/assets/flag_icons/uk_flag.svg"),
          language: "EN",
          title: "English",
        },
        {
          flag: require("@/assets/flag_icons/es_flag.svg"),
          language: "ES",
          title: "Español",
        },
        {
          flag: require("@/assets/flag_icons/cn_flag.svg"),
          language: "CN",
          title: "中文",
        },
      ],
      selectedLanguage: null,
    };
  },
  created() {
    this.selectedLanguage = this.languages.find(
      (el) => el.language === this.$i18n.locale
    );
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    ...mapAuthGetter(["isGuest"]),
  },
  methods: {
    openChat() {
      this.$refs.chatLayer.init();
    },
    changeLocale(entry) {
      this.selectedLanguage = entry;
      this.$i18n.locale = entry.language;
    },
  },
};
</script>
