import VideoSDK from "@zoom/videosdk";
import { VideoActiveState } from "@zoom/videosdk";
import StreamingLibrary from "./StreamingLibrary";
import stomp from "@/common/StompClient";
import {
  ClassroomMessageType,
  ClassroomDeviceMessageType,
  OfflineClassroomMessageType,
  ClassroomParticipantMessageType,
} from "@/common/StompClientActions";
import Vue from "vue";
import classroomStateSetter from "@/components/classroom/store/classroomStateSetter";
import classroomStateGetter from "@/components/classroom/store/classroomStateGetter";
import auth from "@/common/auth";
import { getExploreName } from "@/components/classroom/participants/util/platform";

let audioEncode = false;
let audioDecode = false;

export default class Zoom extends StreamingLibrary {
  constructor(config) {
    super();
    // id de Zoom / id del Device
    this._deviceId = null;
    this._client = null; // zoom client
    this._mediaStream = null;
    this._config = config;
    this._updateDevices();
    this._roomId = null;
    this._participantRole = null;
    this._commandChannel = null;
  }

  async _updateDevices() {
    if (!this._mediaStream) {
      const devices = await VideoSDK.getDevices();
      classroomStateSetter.setCameras(
        devices.filter((d) => d.kind == "videoinput")
      );
      classroomStateSetter.setMicrophones(
        devices.filter((d) => d.kind == "audioinput")
      );
    } else {
      classroomStateSetter.setCameras(this._mediaStream.getCameraList());
      classroomStateSetter.setMicrophones(this._mediaStream.getMicList());
    }

    if (
      !classroomStateGetter.getSelectedCamera() &&
      classroomStateGetter.getCameras().length > 0
    ) {
      classroomStateSetter.setSelectedCamera(
        classroomStateGetter.getCameras()[0]
      );
    }
    if (
      !classroomStateGetter.getSelectedMicrophone() &&
      classroomStateGetter.getMicrophones().length > 0
    ) {
      classroomStateSetter.setSelectedMicrophone(
        classroomStateGetter.getMicrophones()[0]
      );
    }
  }

  isSupportMultipleVideos() {
    return this._mediaStream.isSupportMultipleVideos();
  }

  userCanSeeTheLocalCamera() {
    // if isSupportMultipleVideos, the local video seems not to be working
    // on firefox, isSupportMultipleVideos but the camera does not work
    return !(this.isSupportMultipleVideos() || getExploreName() == "Firefox");
  }

  getDeviceId() {
    return this._deviceId;
  }

  getRoom() {
    return this._roomId;
  }

  getParticipantRole() {
    return this._participantRole;
  }

  _onMediaChange(payload) {
    if (payload.result != "success") {
      Vue.$log.error(payload);
    }
  }

  async init(lectureId) {
    this._roomId = lectureId;
    this._client = VideoSDK.createClient();
    try {
      const promise = await this._client.init(
        "en-US",
        this._config.ZOOM_ASSETS_URL
      );
      this._client.on("media-sdk-change", this._onMediaChange);
      this._client.on("user-added", (payload) =>
        this.participantJoin(payload[0])
      );
      this._client.on("user-removed", (payload) =>
        this.participantLeave(payload[0])
      );
      this._client.on("active-share-change", (payload) =>
        this._changeDeviceSharing(payload)
      );
      return promise;
    } catch (error) {
      Vue.$log.error("init", error);
    }
  }

  async join(virtualClassroomInfo) {
    Vue.$log.debug("Zoom.join");
    try {
      const zoomClientPromise = await this._client.join(
        virtualClassroomInfo.lectureId + "",
        virtualClassroomInfo.token,
        virtualClassroomInfo.userId + "",
        null
      );
      this._mediaStream = this._client.getMediaStream();
      this._commandChannel = this._client.getCommandClient();

      this._setClientListeners();

      Vue.$log.debug(
        `${virtualClassroomInfo.userId} joined ${virtualClassroomInfo.lectureId}`
      );

      this._deviceId = this._client.getCurrentUserInfo().userId;
      classroomStateSetter.setCurrentDevice(this._client.getCurrentUserInfo());

      if (this._client.getCurrentUserInfo().isHost) {
        classroomStateGetter.getParticipants().forEach((p) => {
          p.devices.forEach((d) => {
            // si el dispositivo esta en la lista de usuarios pero no en la de zoom avisamos al servidor
            if (!this._client.getAllUser().find((zoomP) => zoomP.userId == d)) {
              stomp.sendMessageToClassroom(this.getRoom(), {
                type: ClassroomMessageType.DEVICE_LEFT,
                deviceLeavingId: d,
              });
            }
          });
        });
      }

      await stomp.subscribeToClassroomUser(
        this.onMessageReceived.bind(this),
        this.getRoom(),
        this.getDeviceId()
      );

      await stomp.subscribeToClassroomDevice(
        this.onMessageReceived.bind(this),
        this.getRoom(),
        this.getDeviceId()
      );

      await stomp.subscribeToClassroom(
        this.onMessageReceived.bind(this),
        this.getRoom(),
        this.getDeviceId()
      );

      this._participantRole = virtualClassroomInfo.classroomMode;

      return zoomClientPromise;
    } catch (e) {
      Vue.$log.error("Zoon.join", e);
    }
  }

  _setClientListeners() {
    this._client.on("device-change", () => {
      Vue.$log.info("device-change");
      this._updateDevices();
    });

    this._client.on("auto-play-audio-failed", () => {
      Vue.$log.debug("auto play failed, waiting user interaction");
    });

    this._client.on("passively-stop-share", () => {
      this.stopShareScreen();
    });

    this._client.on("command-channel-message", (payload) =>
      this.onCommandReceived(payload)
    );

    this._client.on("peer-video-state-change", async (payload) => {
      await sleep(1000);
      const { action, userId } = payload;
      let participant = classroomStateGetter
        .getParticipants()
        .find((p) => p.devices.includes(userId));
      if (participant && action === "Start") {
        this._setParticipantCameraOnId(participant.userId, userId);
      } else if (participant && action === "Stop") {
        if (participant.cameraOnId == userId) {
          this._setParticipantCameraOnId(participant.userId, null);
        }
      }
    });
  }

  async startAudio() {
    Vue.$log.debug("Zoom.startAudio");
    await this._mediaStream.startAudio();
    Vue.$log.debug("this._mediaStream.startAudio");
    await sleep(500);
    if (classroomStateGetter.getSelectedMicrophone()) {
      this.switchMicrophone(classroomStateGetter.getSelectedMicrophone());
    }
    await this._mediaStream.muteAudio(this.getDeviceId());
    classroomStateSetter.setCurrentDevice(this._client.getCurrentUserInfo());

    classroomStateSetter.setAudioActive(true);
    return true;
  }

  async mute() {
    await this._mediaStream.muteAudio(this.getDeviceId());
    this._changeMutedState(true);
    this.sendCommand(ClassroomDeviceMessageType.MUTE);
    await stomp.sendMessageToDevice(
      this._roomId,
      auth.getUser().id,
      this._deviceId,
      {
        type: ClassroomDeviceMessageType.MUTE,
      }
    );
  }

  async unmute() {
    let participant = classroomStateGetter
      .getParticipants()
      .find((p) => p.userId === auth.getUser().id);
    if (participant.unmutedId && participant.unmutedId != this._deviceId) {
      this.sendCommand(
        ClassroomParticipantMessageType.MUTE_USER,
        participant.unmutedId
      );
    }
    await this._mediaStream.unmuteAudio(this.getDeviceId());
    this._changeMutedState(false);
    this.sendCommand(ClassroomDeviceMessageType.UNMUTE);
    await stomp.sendMessageToDevice(
      this._roomId,
      auth.getUser().id,
      this._deviceId,
      {
        type: ClassroomDeviceMessageType.UNMUTE,
      }
    );
  }

  _changeMutedState(newValue) {
    classroomStateSetter.setCurrentDevice(this._client.getCurrentUserInfo());
    this._setParticipantUmutedId(
      auth.getUser().id,
      newValue ? null : this._deviceId
    );
  }

  async leave() {
    try {
      if (this._client) {
        await this._client.leave();
      }
      classroomStateSetter.setSessionState(null);
    } catch (e) {
      Vue.$log.error("Zoom.leave", e);
    }
  }

  async startCamera(videoElement) {
    try {
      let participant = classroomStateGetter
        .getParticipants()
        .find((p) => p.userId === auth.getUser().id);
      if (participant.cameraOnId && participant.cameraOnId != this._deviceId) {
        this.sendCommand(
          ClassroomParticipantMessageType.STOP_USER_CAMERA,
          participant.cameraOnId
        );
        this._setParticipantCameraOnId(auth.getUser().id, null);
      }

      await this._mediaStream.startVideo({
        videoElement: videoElement,
        mirrored: true,
      });
      if (classroomStateGetter.getSelectedCamera()) {
        this.switchCamera(classroomStateGetter.getSelectedCamera());
      }
      this._changeCameraOnState(true);

      //avisamos a los usuarios offline a traves de nuestro servidor del cambio de estado
      await stomp.sendMessageToDevice(
        this._roomId,
        auth.getUser().id,
        this._deviceId,
        {
          type: ClassroomDeviceMessageType.START_CAMERA,
        }
      );
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  async stopCamera() {
    await this._mediaStream.stopVideo();
    this._changeCameraOnState(false);

    await stomp.sendMessageToDevice(
      this._roomId,
      auth.getUser().id,
      this._deviceId,
      {
        type: ClassroomDeviceMessageType.STOP_CAMERA,
      }
    );
  }

  async _changeCameraOnState(newValue) {
    classroomStateSetter.setCurrentDevice(this._client.getCurrentUserInfo());
    this._setParticipantCameraOnId(
      auth.getUser().id,
      newValue ? this._deviceId : null
    );
  }

  async renderVideo(container, userId, options) {
    return this._mediaStream.renderVideo(
      container,
      userId,
      options.canvasSize.width,
      options.canvasSize.height,
      options.position[0],
      options.position[1],
      0
    );
  }

  stopRenderVideo(container, userId) {
    return this._mediaStream.stopRenderVideo(container, userId);
  }

  async startShareScreen(container) {
    await this._mediaStream.startShareScreen(container);
    await stomp.sendMessageToDevice(
      this._roomId,
      auth.getUser().id,
      this._deviceId,
      {
        type: ClassroomDeviceMessageType.START_SCREEN_SHARE,
      }
    );
    classroomStateSetter.setDeviceSharing(this._deviceId);
    this._setParticipantSharingId(auth.getUser().id, this._deviceId);
  }

  async stopShareScreen() {
    await this._mediaStream.stopShareScreen();
    await stomp.sendMessageToDevice(
      this._roomId,
      auth.getUser().id,
      this._deviceId,
      {
        type: ClassroomDeviceMessageType.STOP_SCREEN_SHARE,
      }
    );
    classroomStateSetter.setDeviceSharing(null);
    this._setParticipantSharingId(auth.getUser().id, null);
  }

  async startShareView(container) {
    return this._mediaStream.startShareView(
      container,
      classroomStateGetter.getDeviceSharing()
    );
  }

  stopShareView() {
    return this._mediaStream.stopShareView();
  }

  async adjustRenderedVideoPosition(container, userId, options) {
    await sleep(500);
    return this._mediaStream.adjustRenderedVideoPosition(
      container,
      userId,
      options.canvasSize.width,
      options.canvasSize.height,
      options.position[0],
      options.position[1]
    );
  }

  updateVideoCanvasDimension(container, newCanvasSize) {
    return this._mediaStream.updateVideoCanvasDimension(
      container,
      newCanvasSize.width,
      newCanvasSize.height
    );
  }

  switchCamera(newCamera) {
    Vue.$log.debug(
      `Zoom.switchCamera.before: camera changed to ${newCamera.label}`
    );
    if (this._mediaStream) {
      const activeCameraId = this._mediaStream.getActiveCamera();
      if (newCamera.deviceId !== activeCameraId) {
        this._mediaStream.switchCamera(newCamera.deviceId);
        Vue.$log.debug(
          `Zoom.switchCamera.after: camera changed to ${newCamera.label}`
        );
      }
    }
    classroomStateSetter.setSelectedCamera(newCamera);
  }

  switchMicrophone(newMic) {
    Vue.$log.debug(
      `Zoom.switchMicrophone.before: mic changed to ${newMic.label}`
    );
    if (this._mediaStream) {
      const activeMicId = this._mediaStream.getActiveMicrophone();
      if (newMic.deviceId !== activeMicId) {
        this._mediaStream.switchMicrophone(newMic.deviceId);
        Vue.$log.debug(
          `Zoom.switchMicrophone.after: mic changed to ${newMic.label}`
        );
      }
    }
    classroomStateSetter.setSelectedMicrophone(newMic);
  }

  async currentDeviceLeave() {
    return new Promise(async (resolve) => {
      await stomp.sendMessageToOfflineClassroom(this.getRoom(), {
        type: ClassroomDeviceMessageType.LEAVE,
      });
      this.leave();
      resolve();
    });
  }

  async kickUser(userId) {
    await stomp.sendMessageToUser(this.getRoom(), userId, {
      type: OfflineClassroomMessageType.KICK,
    });
  }

  async muteUser(userId) {
    await stomp.sendMessageToParticipant(this.getRoom(), userId, {
      type: ClassroomParticipantMessageType.MUTE_USER,
    });
  }

  async stopUserCamera(userId) {
    await stomp.sendMessageToParticipant(this.getRoom(), userId, {
      type: ClassroomParticipantMessageType.STOP_USER_CAMERA,
    });
  }

  async createClassroomSubscription() {
    await stomp.subscribeToClassroom(
      this.onMessageReceived,
      this.getRoom(),
      this.getDeviceId()
    );
  }

  participantJoin(zoomParticipant) {
    if (!zoomParticipant) return;
    if (zoomParticipant.displayName == auth.getUser().id) {
      let sessions = classroomStateGetter.getSessions();
      if (!sessions.includes(zoomParticipant.userId)) {
        sessions.push(zoomParticipant.userId);
        classroomStateSetter.setSessions(sessions);
      }
    }
    let participants = classroomStateGetter.getParticipants();
    const idx = participants.findIndex(
      (el) => el.userId == zoomParticipant.displayName
    );
    if (
      idx >= 0 &&
      !participants[idx].devices.includes(zoomParticipant.userId)
    ) {
      participants[idx].devices.push(zoomParticipant.userId);
      classroomStateSetter.setParticipants(participants);
    }
  }

  participantLeave(zoomParticipant) {
    if (!zoomParticipant) return;
    let participants = classroomStateGetter.getParticipants();
    if (zoomParticipant.displayName) {
      if (zoomParticipant.displayName == auth.getUser().id) {
        let sessions = classroomStateGetter.getSessions();
        classroomStateSetter.setSessions(
          sessions.filter((el) => el != zoomParticipant.userId)
        );
      }
      const idx = participants.findIndex(
        (el) => el.userId == parseInt(zoomParticipant.displayName)
      );
      if (idx >= 0) {
        participants[idx].devices = participants[idx].devices.filter(
          (el) => el != zoomParticipant.userId
        );
        classroomStateSetter.setParticipants(participants);
      }
    } else {
      if (zoomParticipant.userId != null) {
        let participant = participants.find((p) =>
          p.devices.includes(zoomParticipant.userId)
        );
        if (participant) {
          if (this._client.getCurrentUserInfo()) {
            stomp.sendMessageToClassroom(this.getRoom(), {
              type: ClassroomMessageType.DEVICE_LEFT,
              deviceLeavingId: zoomParticipant.userId,
            });
          }
          participant.devices = participant.devices.filter(
            (d) => d != zoomParticipant.userId
          );
          if (participant.cameraOnId == zoomParticipant.userId) {
            participant.cameraOnId = null;
          }
          if (participant.sharerOnId == zoomParticipant.userId) {
            participant.sharerOnId = null;
          }
          if (participant.unmutedId == zoomParticipant.userId) {
            participant.unmutedId = null;
          }
          participants.splice(
            participants.findIndex((p) => p.userId == participant.userId),
            1,
            participant
          );
          classroomStateSetter.setParticipants(participants);
        }
      }
    }
  }

  _changeDeviceSharing(payload) {
    let newId;
    if (payload.state === VideoActiveState.Active) {
      newId = payload.userId;
      classroomStateSetter.setDeviceSharing(payload.userId);
    } else if (payload.state === VideoActiveState.Inactive) {
      newId = null;
      classroomStateSetter.setDeviceSharing(null);
    }
    let participants = classroomStateGetter.getParticipants();
    let participant = participants.find((p) =>
      p.devices.includes(payload.userId)
    );
    if (participant) {
      participant.sharerOnId = newId;
      participants.splice(
        participants.findIndex((p) => p.userId == participant.userId),
        1,
        participant
      );
      classroomStateSetter.setParticipants(participants);
    }
  }

  sendCommand(command, userId) {
    this._commandChannel.send(command, userId);
  }

  async onCommandReceived(command) {
    const { senderId, senderName, text } = command;
    switch (text) {
      case ClassroomDeviceMessageType.UNMUTE:
        this._setParticipantUmutedId(senderName, senderId);
        break;
      case ClassroomDeviceMessageType.MUTE:
        this._setParticipantUmutedId(senderName, null);
        break;
      case ClassroomParticipantMessageType.MUTE_USER:
        if (!classroomStateGetter.getCurrentDevice().muted) {
          await this._mediaStream.muteAudio(this.getDeviceId());
          this._changeMutedState(false);
        }
        break;
      case ClassroomParticipantMessageType.STOP_USER_CAMERA:
        if (classroomStateGetter.getCurrentDevice().bVideoOn) {
          await this._mediaStream.stopVideo();
          this._changeCameraOnState(false);
        }
        break;
    }
  }

  _setParticipantCameraOnId(userId, deviceId) {
    let participants = classroomStateGetter.getParticipants();
    const idx = participants.findIndex((el) => el.userId == userId);
    if (idx >= 0) {
      participants[idx].cameraOnId = deviceId;
      classroomStateSetter.setParticipants(participants);
    }
  }

  _setParticipantUmutedId(userId, deviceId) {
    let participants = classroomStateGetter.getParticipants();
    const idx = participants.findIndex((el) => el.userId == userId);
    participants[idx].unmutedId = deviceId;
    classroomStateSetter.setParticipants(participants);
  }

  _setParticipantSharingId(userId, deviceId) {
    let participants = classroomStateGetter.getParticipants();
    const idx = participants.findIndex((el) => el.userId == userId);
    if (idx >= 0) {
      participants[idx].sharerOnId = deviceId;
      classroomStateSetter.setParticipants(participants);
    }
  }

  async onMessageReceived(message) {
    switch (message.type) {
      case ClassroomDeviceMessageType.LEAVE:
        this.leave();
        break;
      case ClassroomMessageType.NEW_USER_OFFLINE:
        let participants = classroomStateGetter.getParticipants();
        participants.push(message.newParticipant);
        classroomStateSetter.setParticipants(participants);
        break;
      case ClassroomDeviceMessageType.MUTE:
        if (!classroomStateGetter.getCurrentDevice().muted) {
          this.mute();
        }
        break;
      case ClassroomMessageType.SET_NEW_MODE:
        classroomStateSetter.setClassroomViewMode(message.viewMode);
        break;
      case ClassroomMessageType.RECORDING_STARTED:
        classroomStateSetter.setRecording(true);
        break;
      case ClassroomMessageType.RECORDING_ENDED:
        classroomStateSetter.setRecording(false);
        break;
      case ClassroomDeviceMessageType.SUBSCRIBED_CLASSROOM:
        if (classroomStateGetter.getCurrentDevice() != null) {
          const currentDev = classroomStateGetter.getCurrentDevice();
          if (currentDev.bVideoOn) {
            await stomp.sendMessageToDevice(
              this._roomId,
              auth.getUser().id,
              this._deviceId,
              {
                type: ClassroomDeviceMessageType.START_CAMERA,
              }
            );
          }
          if (currentDev.audio != "" && !currentDev.muted) {
            await stomp.sendMessageToDevice(
              this._roomId,
              auth.getUser().id,
              this._deviceId,
              {
                type: ClassroomDeviceMessageType.UNMUTE,
              }
            );
          }
          if (currentDev.sharerOn) {
            await stomp.sendMessageToDevice(
              this._roomId,
              auth.getUser().id,
              this._deviceId,
              {
                type: ClassroomDeviceMessageType.START_SCREEN_SHARE,
              }
            );
          }
          if (this.getParticipantRole() == "teacher") {
            //profesor setea viewMode, recording, deviceSharing
            await stomp.sendMessageToClassroom(this.getRoom(), {
              type: ClassroomMessageType.UPDATE_CLASSROOM_STATE,
              deviceSharingId: classroomStateGetter.getDeviceSharing(),
              viewMode: classroomStateGetter.getClassroomViewMode(),
              recording: classroomStateGetter.getRecording(),
            });
          }
        } else {
          classroomStateSetter.setRecording(message.recording);
          classroomStateSetter.setClassroomViewMode(message.viewMode);
        }
        break;
      case ClassroomParticipantMessageType.MUTE_USER:
        if (!classroomStateGetter.getCurrentDevice().muted) {
          this.mute();
        }
        break;
      case ClassroomParticipantMessageType.STOP_USER_CAMERA:
        if (classroomStateGetter.getCurrentDevice().bVideoOn) {
          this.stopCamera();
        }
        break;
    }
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
