<template>
  <v-card>
    <activity-form
      :simplified="true"
      @saved="saveActivity"
      @cancelEdit="cancelCreate"
    ></activity-form>
  </v-card>
</template>

<script>
import ActivityForm from "../_components/ActivityForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ActivityCreate",
  components: {
    ActivityForm,
  },
  data() {
    return {
      activity: null,
    };
  },
  methods: {
    cancelCreate() {
      this.$router.back(-1);
    },
    saveActivity(entity) {
      this.$router.push({
        name: "Activity Detail",
        params: { id: entity.id },
      });
    },
  },
};
</script>

<style></style>
