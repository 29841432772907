<template>
  <course-form
    :simplified="false"
    @cancelEdit="cancelCreation"
    @saved="savedCourse"
  ></course-form>
</template>

<script>
import CourseForm from "@/mockups/content-cloud/_components/CourseForm.vue";

export default {
  name: "CourseCreate",
  components: {
    CourseForm,
  },
  methods: {
    cancelCreation() {
      this.$router.push({ name: "Course List", params: { backAction: true } });
    },
    savedCourse(entity) {
      this.$router.push({
        name: "Course Detail",
        params: { id: entity.id },
      });
    },
  },
};
</script>
