<template>
  <div class="chat__container rr-block" v-if="chatOpened">
    <ChatListWindow
      :initialWidth="400"
      :notifications="notifications"
      :notificationMessage="newMessage"
      :chatId="chatIdIntern"
      v-on:close-window="onCloseWindow"
      v-on:back-action="closeChat"
    />
  </div>
</template>
<script>
import Vue from "vue";
import VueWindow from "@hscmap/vue-window";
import ChatListWindow from "./ChatListWindow.vue";
import stomp from "@/common/StompClient";
import { ChatMessageType } from "@/common/StompClientActions";
import ChatRepository from "@/repositories/components/ChatRepository";

import {
  getChat,
  mapNotificationsGetter,
} from "@/common/mapNotificationsGetter";
// import debounce from "lodash.debounce";

Vue.use(VueWindow);
export default {
  name: "ChatWindow",
  components: {
    ChatListWindow,
  },
  props: {
    chatId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      chatOpened: false,
      chatStore: getChat(),
      chatIdIntern: this.chatId || null,
    };
  },
  computed: {
    ...mapNotificationsGetter(["notifications", "newMessage"]),
  },
  mounted() {
    this.initStomp();
  },
  watch: {
    chatId(newValue) {
      if (newValue) {
        this.$set(this, "chatIdIntern", newValue);
      }
    },
  },
  methods: {
    init(chatId) {
      if (chatId) {
        this.$set(this, "chatIdIntern", chatId);
        ChatRepository.updateNotifications(chatId);
      }
      this.chatOpened = true;
    },
    async initStomp() {
      await stomp.subscribeToNotifications(this.onMessageReceived);
    },
    async onCloseWindow() {
      this.closeChat();
      this.chatOpened = false;
      this.chatStore.notifications = await ChatRepository.getNotifications();
    },
    closeChat() {
      this.$set(this, "chatIdIntern", null);
    },
    async onMessageReceived(body) {
      switch (body.type) {
        case ChatMessageType.SUBSCRIBED:
          if (body.notifications == null) {
            this.chatStore.notifications = 0;
          } else {
            this.chatStore.notifications = body.notifications;
          }
          break;
        case ChatMessageType.NOTIFICATION:
          this.chatStore.newMessage = body.message;
          if (this.chatStore.newMessage != null) {
            this.chatStore.newMessage.chat = body.chat;
          }
          this.chatStore.notifications =
            this.chatStore.notifications + body.notifications;
          break;
        case ChatMessageType.UPDATE_NOTIFICATIONS:
          this.chatStore.newMessage = null;
          this.chatStore.notifications =
            await ChatRepository.getNotifications();
          break;
      }
    },
    isOpened() {
      return this.chatOpened;
    },
  },
  beforeDestroy() {
    stomp.unsubscribeFromChat();
  },
};
</script>
