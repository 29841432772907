<template>
  <v-container fluid>
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="auto" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-row>
              <v-col cols="3" offset="4">
                <debounced-text-field
                  append-icon="search"
                  class="d-block"
                  dense
                  hide-details
                  v-model="search"
                  :label="$t('search')"
                  @input="onSearchChange"
                ></debounced-text-field>
              </v-col>
              <v-col cols="3">
                <autocomplete
                  v-model="typeFilter"
                  class="pt-0"
                  :label="$t('company.prop.type')"
                  :items="companyTypes"
                  :item-text="translate"
                  dense
                  @change="onSearchChange"
                ></autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="success ml-2"
                  :to="{
                    name: 'Create Company',
                    params: { backPrevious: true },
                  }"
                >
                  <v-icon>add</v-icon>
                  <span class="d-none d-sm-block">
                    {{ $t("actions.new") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              class="rows-clickable"
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :options="pagination"
              :server-items-length="totalItems"
              @update:options="onPaginationChange"
              @click:row="entityDetail"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span>
                  {{ item.name + " " + item.surname }}
                </span>
              </template>
              <template v-slot:[`item.dateStart`]="{ item }">
                <span>
                  {{ item.dateStart | dateTimeWithTz }}
                </span>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <v-chip :color="getTypeColor(item)" small>
                  {{ $t(`company.types.${item.type}`) }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      @click.stop="entityDetail(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      description
                    </v-icon>
                  </template>
                  <span>{{ $t("actions.detail") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      @click.stop="selectDeactivateCompany(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialogo para la eliminacion de usuarios -->
    <delete-dialog
      :dialog="deactivateDialog"
      :loading="deleteLoading"
      @cancel="deactivateDialog = false"
      @submit="deactivateCompany"
    ></delete-dialog>
  </v-container>
</template>

<script>
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import companyTypes from "@/enumerates/CompanyType";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { translate } from "@/common/translation-utils";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const CompanyEntityRepository = RepositoryFactory.get(
  "CompanyEntityRepository"
);

export default {
  name: "CompanyList",
  components: {
    Autocomplete,
    DebouncedTextField,
    DeleteDialog,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      search: this.$route.query.search,
      typeFilter: this.$route.query.type,
      selectedEntity: null,
      deactivateDialog: false,
      companyTypes,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("profile.fields.display_name"),
          value: "displayName",
        },
        {
          text: this.$t("company.prop.representative_name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "mainPhone",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        {
          text: this.$t("company.prop.type"),
          value: "type",
        },
        {
          text: this.$t("profile.fields.date_start"),
          value: "dateStart",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.isLoading = true;
      const sortMapping = {
        displayName: "userData.displayName",
        mainPhone: "userData.mainPhone",
        mainEmail: "userData.user.email",
        dateStart: "userData.dateStart",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          search: this.search,
          companyType: this.typeFilter,
        },
      };
      CompanyEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching companies list with params: " + options
          )
        )
        .finally(() => (this.isLoading = false));
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "CompanyView Detail",
          params: {
            id: entity.id,
            backPrevious: true,
          },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this._fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.search = this.search ? this.search : undefined;
      query.type = this.typeFilter ? this.typeFilter : undefined;
      this.redirect(query);
    },
    onSearchChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    deactivateCompany() {
      this.deleteLoading = true;
      CompanyEntityRepository.deactivate(this.selectedEntity.id)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$t("company.messages.deactivate_success"),
          });
          this._fetchData();
        })
        .catch(() =>
          this.$log.debug(
            "Error deactivating company " + this.selectedEntity.companyName
          )
        )
        .finally(() => {
          this.selectedEntity = null;
          this.deactivateDialog = false;
          this.deleteLoading = false;
        });
    },
    selectDeactivateCompany(entity) {
      this.selectedEntity = entity;
      this.deactivateDialog = true;
    },
    getTypeColor(item) {
      return this.companyTypes.find((i) => i.value === item.type).color;
    },
    translate,
  },
};
</script>
