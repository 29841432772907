<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>

            <v-btn class="warning ml-2" @click="edit">
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.edit") }} </span>
            </v-btn>

            <v-btn class="error ml-2" @click="dialog = true">
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.delete") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("topic.prop.name") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ entity.name }}
          </v-col>

          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("topic.prop.language") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ $t(`languages.${entity.language.name}`) }}
          </v-col>

          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("topic.prop.type") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ $t(`topicType.types.${entity.topicType.name}`) }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>

    <delete-dialog
      :dialog="dialog"
      :loading="isLoading"
      @cancel="dialog = false"
      @submit="deleteEntity"
    ></delete-dialog>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");

export default {
  name: "TopicDetail",
  components: { LoadingPage, DeleteDialog },
  data() {
    return {
      loading: false,
      entity: null,
      dialog: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return TopicEntityRepository.get(id)
        .then((res) => (this.entity = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({ name: "Topic List", params: { backAction: true } });
    },
    edit() {
      this.$router.push({
        name: "Topic Form",
        params: { id: this.entity.id, backPrevious: true },
      });
    },
    deleteEntity() {
      this.loading = true;
      return TopicEntityRepository.delete(this.entity.id)
        .then(() => this.$router.push({ name: "Topic List" }))
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
};
</script>
