var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.isLoading)?_c('v-container',[_c('v-data-table',{attrs:{"items":_vm.entity.levels,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("student.tabs.levels.title"))+" ")]),_c('v-spacer'),(_vm.isAnyStudent)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){_vm.levelSelectorDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("student.tabs.exams.headers.new_language_exam"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(item.date))+" ")]):_vm._e()]}},{key:"item.levelName",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level),"text-color":_vm.getChipTextColor(item.level)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")((item.level.name + " - " + _vm.$t(("languages." + (item.level.language.name))))))+" ")]):_vm._e()]}}],null,true)}),(_vm.tests.length > 0)?_c('v-data-table',{staticClass:"mt-2",attrs:{"items":_vm.tests,"headers":_vm.testHeaders,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("student.tabs.exams.title"))+" ")])],1)]},proxy:true},{key:"item.lesson.level",fn:function(ref){
var item = ref.item;
return [(item.lesson.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.lesson.level),"text-color":_vm.getChipTextColor(item.lesson.level)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")((item.lesson.level.name + " - " + _vm.$t(("languages." + (item.lesson.level.language.name))))))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isAnyStudent && item.state === 'ASSIGNED')?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.startTest(item)}}},[_vm._v(" "+_vm._s(_vm.$t("lesson.actions.start"))+" ")]):_vm._e(),(_vm.isAnyStudent && item.state === 'STARTED')?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.startTest(item)}}},[_vm._v(" "+_vm._s(_vm.$t("lesson.actions.resume"))+" ")]):_vm._e(),(item.state === 'COMPLETED')?_c('v-btn',{attrs:{"disabled":"","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("lesson.actions.done"))+" ")]):_vm._e()]}},{key:"item.action2",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAnyStudent)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteLevelTest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)}):_vm._e(),_c('v-data-table',{staticClass:"mt-4",attrs:{"items":_vm.levelsRequested,"headers":_vm.headersLevelRequest,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("student.tabs.levels.request"))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,4026887447)},[_c('span',[_vm._v(_vm._s(_vm.$t("student.tabs.levels.headers.info")))])]),_c('v-spacer'),(_vm.isAnyStudent)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){_vm.languageTrialDialog = true}}},[_vm._v(_vm._s(_vm.$t("student.tabs.levels.headers.new_language_trial"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level),"text-color":_vm.getChipTextColor(item.level)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")((item.level.name + " - " + _vm.$t(("languages." + (item.level.language.name))))))+" ")]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("student.tabs.levels." + (item.state))))+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.date[0], item.date[1] - 1, item.date[2])))+" ")]):_vm._e()]}},{key:"item.limitDate",fn:function(ref){
var item = ref.item;
return [(item.limitDate)?_c('span',[_vm._v(" "+_vm._s(_vm.$d( new Date( item.limitDate[0], item.limitDate[1] - 1, item.limitDate[2] ) ))+" ")]):_vm._e()]}}],null,true)}),(_vm.languageTrialDialog)?_c('language-selector-dialog',{attrs:{"dialog":_vm.languageTrialDialog,"hiddenLanguages":_vm.entityLevels},on:{"close":function($event){_vm.languageTrialDialog = false},"save":_vm.saveLevelTrial}}):_vm._e(),(_vm.levelSelectorDialog)?_c('level-selector-dialog',{attrs:{"dialog":_vm.levelSelectorDialog},on:{"close":function($event){_vm.levelSelectorDialog = false},"save":_vm.requestLevelTest}}):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('loading-page'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }