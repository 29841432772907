<template>
  <div>
    <v-data-table
      :footer-props="tableFooterProps"
      :headers="headers"
      :items="interactions"
      :loading="loading"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.grade`]="{ item }">
        <span>
          {{
            item.grade != null
              ? item.grade
              : $t("activityInteraction.messages.no_grade")
          }}
        </span>
      </template>
      <template v-slot:[`item.timeConsumed`]="{ item }">
        <span>
          {{ item.timeConsumed || "--" }}
        </span>
      </template>
      <template v-slot:[`item.dateSolved`]="{ item }">
        <span v-if="item.dateSolved">
          {{ item.dateSolved | dateTimeWithTz("medium") }}
        </span>
        <span v-else>
          {{ $t("activityInteraction.messages.not_solved") }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import tableFooterProps from "@/common/table-footer-props";

const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);

export default {
  name: "StudentInteractionsTable",
  props: {
    lectureId: {
      required: true,
    },
    studentId: {
      required: false,
    },
  },
  data() {
    return {
      interactions: [],
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("activityInteraction.prop.activity"),
          value: "activity.title",
        },
        {
          text: this.$t("activityInteraction.prop.grade"),
          value: "grade",
        },
        {
          text: this.$t("activityInteraction.prop.timeConsumed"),
          value: "timeConsumed",
        },
        {
          text: this.$t("activityInteraction.prop.dateSolved"),
          value: "dateSolved",
        },
      ];
    },
  },
  created() {
    this.loading = true;
    if (this.studentId != null) {
      this._fetchStudentLevelQuestionnaireInteractions();
    } else {
      this._fetchStudentInteractions();
    }
  },
  methods: {
    _fetchStudentInteractions() {
      return ActivityInteractionEntityRepository.getAllByLectureAndStudent(
        this.lectureId
      )
        .then((response) => (this.interactions = response.content))
        .catch(() => this.$log.debug("Error fetching results for student"))
        .finally(() => (this.loading = false));
    },
    _fetchStudentLevelQuestionnaireInteractions() {
      return ActivityInteractionEntityRepository.getByStudentInLevelTest(
        this.studentId,
        this.lectureId
      )
        .then((res) => (this.interactions = res))
        .catch(() =>
          this.$log.debug("Error fetching student level test results")
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
