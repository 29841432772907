import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "account";
const AUTHENTICATION_RESOURCE_NAME = "authenticate";
const RESET_PASSWORD_RESOURCE_NAME = `${RESOURCE_NAME}/reset_password`;

const REGISTER_RESOURCE_NAME = "register";

const ACTIVATE_RESOURCE_NAME = "activate";

const ACTIVATION_MAIL_RESOURCE_NAME = "activation_mail";

const CHANGE_PASSWORD_RESOURCE_NAME = `${RESOURCE_NAME}/change_password`;

export default {
  async login(credentials) {
    return await HTTP.post(AUTHENTICATION_RESOURCE_NAME, credentials, {
      params: { remember_me: credentials.remember_me },
    }).then((response) => response.data);
  },

  async resetPasswordInit(login) {
    const loginDTO = { login: login };
    const response = await HTTP.post(
      `${RESET_PASSWORD_RESOURCE_NAME}/init`,
      loginDTO
    );
    return response.data;
  },
  async resetPasswordFinish(keyAndPassword) {
    const response = await HTTP.post(
      `${RESET_PASSWORD_RESOURCE_NAME}/finish`,
      keyAndPassword
    );
    return response.data;
  },

  async register(user, role) {
    const response = await HTTP.post(REGISTER_RESOURCE_NAME, user, {
      params: { role: role },
    });
    return response.data;
  },

  async registerTutor(tutor, key) {
    const response = await HTTP.post(`${REGISTER_RESOURCE_NAME}/tutor`, tutor, {
      params: { key: key },
    });
    return response.data;
  },

  async registerCompany(company) {
    const response = await HTTP.post(
      `${REGISTER_RESOURCE_NAME}/company`,
      company
    );
    return response.data;
  },

  async registerBasicUser(user, authority) {
    const response = await HTTP.post(`${REGISTER_RESOURCE_NAME}/user`, user, {
      params: { authority: authority },
    });
    return response.data;
  },

  async activateUser(key) {
    const response = await HTTP.get(ACTIVATE_RESOURCE_NAME, {
      params: { key: key },
    });
    return response.data;
  },

  async getActivationMail(options) {
    const response = await HTTP.get(
      `${ACTIVATION_MAIL_RESOURCE_NAME}`,
      options
    );
    return response.data;
  },

  async changePassword(password) {
    const response = await HTTP.post(CHANGE_PASSWORD_RESOURCE_NAME, {
      password,
    });
    return response.data;
  },
};
