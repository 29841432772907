<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col>
        <v-checkbox
          dense
          v-model="showPast"
          :label="$t('edition.filters.show_past_courses')"
          class="mt-0 no-split-words"
          @change="redirectOnFilterChange"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="rows-clickable elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="teacherEditions"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="redirectOnTableChange"
          @click:row="entityDetail"
          :item-class="rowClass"
        >
          <template v-slot:[`item.product.productType`]="{ item }">
            <span v-if="item.product.productType">
              {{ $t(`product.product_type.${item.product.productType}`) }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import auth from "@/common/auth";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import moment from "moment-timezone";
import { localDateToISOString } from "@/common/conversion-utils";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);

export default {
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      teacherEditions: [],
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: true,
      tableFooterProps,
      showPast: this.$route.query.showPast === "true",
      currentDate: moment
        .tz(auth.getUser().timeZone)
        .toISOString()
        .slice(0, 10),
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),

    headers() {
      return [
        {
          text: this.$t("product.prop.title"),
          value: "product.title",
        },
        {
          text: this.$t("product.prop.description"),
          value: "product.description",
        },
        {
          text: this.$t("product.prop.courseType"),
          value: "product.productType",
        },
      ];
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          currentDate: this.showPast ? undefined : this.currentDate,
        },
      };
      EditionEntityRepository.getAllByTeacher(this.teacherId, options)
        .then((response) => {
          this.teacherEditions = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching editions"))
        .finally(() => {
          this.loading = false;
        });
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        const route = this.isAdmin
          ? "EditionsDetailTab"
          : "ProfileEditionsDetailTab";
        this.$router.push({
          name: route,
          params: { editionId: entity.id, backPrevious: true },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.showPast = this.showPast ? this.showPast.toString() : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    rowClass(item) {
      if (item.endDate) {
        const formattedEndDate = localDateToISOString(item.endDate).slice(
          0,
          10
        );
        if (formattedEndDate < this.currentDate) {
          return "table-row-secondary-color";
        }
      }
    },
  },
};
</script>
