import {
  localDateToTimezone,
  utcDateArrayToDateWithTimezone,
} from "@/common/conversion-utils";

function userCanJoin(lecture) {
  // The user can only join the class if it is in progress or there are less than 10 minutes left to start and if its state is 'PLANNED' or 'DOING'
  if (lecture.state !== "PLANNED" && lecture.state !== "DOING") {
    return false;
  }
  let startTime = utcDateArrayToDateWithTimezone(lecture.startTime);
  startTime.setMinutes(startTime.getMinutes() - 10);
  const now = localDateToTimezone(Date.now());
  return now > startTime;
}

function joinToLecture(lecture) {
  this.$router.push(`/classroom/${lecture.id}`);
}

export { userCanJoin, joinToLecture };
