<template>
  <v-container v-if="items">
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="4"
            lg="6"
            xl="8"
            order="2"
            order-sm="1"
            class="text-center text-sm-right mt-4 mt-sm-0"
          >
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="isContentEditor"
            order="1"
            order-md="2"
            class="text-right"
          >
            <v-btn color="success ml-2" @click="createMedia()">
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="start" class="">
          <v-col cols="12" md="3">
            <debounced-text-field
              dense
              v-model="titleFilter"
              :label="$t('activity.prop.title')"
            ></debounced-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <debounced-text-field
              dense
              v-model="descriptionFilter"
              :label="$t('activity.prop.description')"
            ></debounced-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <LanguageSelector
              v-model="languageFilter"
              dense
              :clearable="!levelFilter"
              outlined
              :debouncing="300"
              :label="$t('activity.prop.language')"
              show-chips
              @input="onSelectorChange('onLanguageChange', ...arguments)"
            ></LanguageSelector>
          </v-col>
          <v-col cols="12" md="3">
            <LevelSelector
              v-model="levelFilter"
              dense
              clearable
              outlined
              :debouncing="300"
              :label="$t('activity.prop.level')"
              :language-filter="languageFilter ? languageFilter.id : null"
              :show-language="false"
              @input="onSelectorChange('onLevelChange', ...arguments)"
            ></LevelSelector
          ></v-col>
        </v-row>
        <v-row
          align="center"
          v-show="showFilters"
          justify="space-between"
          class=""
        >
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTypes.items"
              :loading="activityTypes.loading"
              :search-input.sync="activityTypeSearch"
              v-model="activityTypeFilter"
              :label="$t('activity.prop.activity_type')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              no-filter
              :debouncing="300"
              :items="activityTopics.items"
              :loading="activityTopics.loading"
              :search-input.sync="activityTopicSearch"
              v-model="activityTopicFilter"
              :label="$t('activity.prop.topics')"
              item-text="id"
              :item-value="(item) => parseInt(item.id)"
            >
              <template v-slot:item="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  :color="getChipBackColor(item)"
                  :text-color="getChipTextColor(item)"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              small-chips
              deletable-chips
              no-filter
              multiple
              :debouncing="300"
              :items="activityWords.items"
              :loading="activityWords.loading"
              :search-input.sync="activityWordSearch"
              v-model="activityWordFilter"
              :label="$t('activity.prop.words')"
              item-text="name"
              :item-value="(item) => parseInt(item.id)"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @click:row="entityDetail"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.level.language.name`]="{ item }">
            <v-chip
              v-if="item.level.language"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ $t(`languages.${item.level.language.name}`) | uppercase }}
            </v-chip>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
              >{{ item.level.name }}</v-chip
            >
          </template>

          <template v-slot:[`item.activityType`]="{ item }">
            <v-chip
              v-if="item.activityType"
              :color="getChipBackColor(item.activityType)"
              :text-color="getChipTextColor(item.activityType)"
              >{{ item.activityType.name }}</v-chip
            >
          </template>
          <template
            v-slot:[`item.activityTopics`]="{ item }"
            v-if="!loadingTopics"
          >
            <div v-for="topic in item.activityTopics" :key="topic.id">
              {{ topics.find((t) => t.id === topic.topicId).name }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click.stop="openExternal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-link
                </v-icon>
              </template>
              <span>{{ item.link }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click.stop="entityDetail(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  description
                </v-icon>
              </template>
              <span>{{ $t("actions.detail") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isContentEditor">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  @click.stop="showDeleteDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="stopDeleteAction"
      @submit="deleteActivity"
    ></delete-dialog>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const ActivityTypeEntityRepository = RepositoryFactory.get(
  "ActivityTypeEntityRepository"
);
const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");

export default {
  name: "MediaLibrary",
  components: {
    DeleteDialog,
    DebouncedTextField,
    LevelSelector,
    LanguageSelector,
  },
  data() {
    return {
      items: [],
      showFilters: false,
      deleteLoading: false,
      loadingTopics: false,
      loadingWords: false,
      activityTypeSearch: null,
      activityTopicSearch: null,
      activityWordSearch: null,
      deleteDialog: false,
      selected: null,
      idFilter: null,
      topics: [],
      titleFilter: null,
      descriptionFilter: null,
      languageFilter: null,
      levelFilter: null,
      activityTypeFilter: null,
      activityTopicFilter: null,
      activityWordFilter: [],
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      activityTypes: {
        items: [],
        loading: false,
      },
      activityTopics: {
        items: [],
        loading: false,
      },
      activityWords: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("activity.prop.title"),
          value: "title",
        },
        {
          text: this.$t("activity.prop.description"),
          value: "description",
        },
        {
          text: this.$t("level.prop.language"),
          value: "level.language.name",
        },
        {
          text: this.$t("activity.prop.level"),
          value: "level",
        },
        {
          text: this.$t("activity.prop.activity_type"),
          value: "activityType",
        },
        {
          text: this.$t("activity.prop.topics"),
          sortable: false,
          value: "activityTopics",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    ...mapAuthGetter(["isContentEditor"]),
    activityFilter() {
      let activityFilter = "";
      activityFilter =
        activityFilter +
        (this.activityTopicFilter ? this.activityTopicFilter.toString() : "");
      return activityFilter !== "" ? activityFilter : null;
    },
    wordFilter() {
      return this.activityWordFilter.join();
    },
    filters() {
      let filters = "";
      filters =
        filters +
        (this.idFilter != null && this.idFilter !== ""
          ? "id:" + this.idFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.titleFilter != null && this.titleFilter !== ""
          ? "title:" + this.titleFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.descriptionFilter != null && this.descriptionFilter !== ""
          ? "description:" + this.descriptionFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.activityTypeFilter
          ? "activityType.id:" + this.activityTypeFilter.toString() + ","
          : "");
      return filters !== "" ? filters : null;
    },
  },
  watch: {
    activityTypeSearch() {
      this.getActivityTypeItems();
    },
    activityTopicSearch() {
      this.getActivityTopicItems();
    },
    activityWordSearch() {
      this.getActivityWordItems();
    },
    activityFilter() {
      this.redirectOnFilterChange();
    },
    wordFilter() {
      this.activityWordSearch = null;
      this.redirectOnFilterChange();
    },
    filters() {
      this.redirectOnFilterChange();
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.titleFilter) {
      this.showFilters = true;
      this.titleFilter = this.$route.query.titleFilter;
    }
    if (this.$route.query.descriptionFilter) {
      this.showFilters = true;
      this.descriptionFilter = this.$route.query.descriptionFilter;
    }
    if (this.$route.query.levelFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.languageFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.activityTypeFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.activityTypeFilter);
      this.activityTypeFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.activityTopicFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.activityTopicFilter);
      this.activityTopicFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.activityWordFilter) {
      this.showFilters = true;
      const filter = this.$route.query.activityWordFilter.split(",");
      filter.forEach((item) => {
        let value = parseFloat(item);
        this.activityWordFilter.push(isNaN(value) ? null : value);
      });
    }
    this.getActivityTypeItems();
    this.getActivityTopicItems();
    this.getActivityWordItems();
    this.fetchTopics();
    this.getItems();
  },
  methods: {
    fetchTopics() {
      this.loadingTopics = true;
      return TopicEntityRepository.getAll()
        .then((res) => (this.topics = res.content))
        .finally(() => (this.loadingTopics = false));
    },
    getItems() {
      this.loading = true;
      const sortMapping = {
        level: "level.name",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          sort: generateSort(this.entitiesPage, sortMapping),
          size: this.entitiesPage.itemsPerPage,
          topics: this.activityFilter,
          activityWords: this.wordFilter,
          level: this.levelFilter ? this.levelFilter.id : undefined,
          active: true,
          language: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      ActivityEntityRepository.getAllLinks(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching activity list with params: " + options
          )
        )
        .finally(() => (this.loading = false));
    },
    getActivityTypeItems() {
      this.activityTypes.loading = true;
      const options = {
        params: {
          search: this.activityTypeSearch,
        },
      };
      ActivityTypeEntityRepository.getAll(options)
        .then((response) => (this.activityTypes.items = response))
        .catch(() =>
          this.$log.debug(
            "Error fetching activity types with params: " + options
          )
        )
        .finally(() => (this.activityTypes.loading = false));
    },
    getActivityTopicItems() {
      this.activityTopics.loading = true;
      const options = {
        params: {
          languages: this.levelFilter?.language?.id || this.languageFilter?.id,
          search: this.activityTopicSearch,
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.activityTopics.items = response.content;
        })
        .catch(() =>
          this.$log.debug("Error fetching topics with params: " + options)
        )
        .finally(() => (this.activityTopics.loading = false));
    },
    getActivityWordItems() {
      this.activityWords.loading = true;
      const options = {
        params: {
          search: this.activityWordSearch,
          level: this.levelFilter ? this.levelFilter.id : undefined,
          language: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      return WordEntityRepository.getAllByLevelAndLanguage(options)
        .then((data) => {
          this.activityWords.items = data.content;
        })
        .finally(() => (this.activityWords.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "MediaLibrary",
          query: query,
        });
        this.getItems();
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Activity Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    changeQueryFilters(query) {
      query.titleFilter =
        this.titleFilter != null && this.titleFilter !== ""
          ? this.titleFilter
          : undefined;
      query.descriptionFilter =
        this.descriptionFilter != null && this.descriptionFilter !== ""
          ? this.descriptionFilter
          : undefined;
      query.levelFilter = this.levelFilter?.id.toString();
      query.languageFilter =
        this.levelFilter == null
          ? this.languageFilter?.id.toString()
          : undefined;
      query.activityTypeFilter = this.activityTypeFilter
        ? this.activityTypeFilter.toString()
        : undefined;
      query.activityTopicFilter = this.activityTopicFilter
        ? this.activityTopicFilter.toString()
        : undefined;
      query.activityWordFilter = this.wordFilter ? this.wordFilter : undefined;
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    onSelectorChange(op, value) {
      if (op === "onLevelChange" && value) {
        this.languageFilter = value.language;
      }
      if (op === "onLanguageChange") {
        if (
          this.levelFilter != null &&
          this.levelFilter.language.id === value?.id
        ) {
          return;
        }
        this.getActivityTopicItems();
      }
      this.getActivityWordItems();
      this.redirectOnFilterChange();
    },
    createMedia() {
      this.$router.push({
        name: "Activity Create",
        params: { resourceType: "LINK", backPrevious: true },
      });
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.deleteDialog = true;
    },
    stopDeleteAction() {
      this.selected = null;
      this.deleteDialog = false;
      this.deleteLoading = false;
    },
    deleteActivity() {
      ActivityEntityRepository.delete(this.selected.id)
        .then(() => {
          if (this.entitiesPage.page !== 1 && this.items.length === 1) {
            this.entitiesPage.page -= 1;
          } else {
            this.getItems();
          }
        })
        .catch(() =>
          this.$log.debug("Error deleting activity " + this.selected.id)
        )
        .finally(() => this.stopDeleteAction());
    },
    openExternal(item) {
      window.open(item.link);
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
