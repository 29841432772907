var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('v-data-table',{attrs:{"headers":_vm.activityTableHeaders,"items":_vm.homework ? _vm.value.homework : _vm.value.inclassActivities,"show-expand":"","single-expand":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',{class:isExpanded
            ? 'v-data-table__expanded v-data-table__expanded__row'
            : '',attrs:{"id":'lecture-activity-row ' + item.id}},[_c('td',[_c('button',{class:'v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-down theme--light'.concat(
                isExpanded ? ' v-data-table__expand-icon--active' : ''
              ),attrs:{"type":"button"},on:{"click":function($event){return expand(!isExpanded)}}})]),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[(
              !_vm.loadingRow[item.id] && (_vm.isSameTeacher || _vm.isSupervisorOfTeacher)
            )?_c('v-row',[(!_vm.homework && _vm.isTeacher)?_c('v-col',{staticStyle:{"justify-content":"right","display":"inline-flex"}},[(item.order > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.loadingOrder},on:{"click":function($event){$event.stopPropagation();return _vm.updateOrder(item, item.order - 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-menu-up ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.order-up"))+" ")])]):_vm._e(),(item.order < _vm.maxOrder)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.loadingOrder},on:{"click":function($event){$event.stopPropagation();return _vm.updateOrder(item, item.order + 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.order-down"))+" ")])]):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.content != null)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.previewActivity(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.preview")))])]),(item.teacherNotes)?_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.openTeacherNotes = true}}},'v-icon',attrs,false),on),[_vm._v(" description ")])]}}],null,true)},[(_vm.openTeacherNotes && item.teacherNotes)?_c('modal-dialog',{attrs:{"dialog":_vm.openTeacherNotes,"title":_vm.$t('activity.prop.teacher-notes'),"content":item.teacherNotes,"cancelText":_vm.$t('actions.close'),"cancelClass":"v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text","dialogWith":"75%"},on:{"cancel":function($event){_vm.openTeacherNotes = false}}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.show-teacher-notes")))])],1):_vm._e(),(
                  (_vm.homework ||
                    _vm.value.homework.findIndex(
                      function (h) { return h.activityId === item.activityId; }
                    ) === -1) &&
                  !_vm.value.levelTest
                )?_c('v-edit-dialog',{attrs:{"cancel-text":_vm.$t('actions.cancel'),"large":"","persistent":"","save-text":_vm.$t('actions.save')},on:{"save":function($event){return _vm.assignHomework(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('span',{staticClass:"my-8 text-h6"},[_vm._v(" "+_vm._s(_vm.$t("activity.actions.assign_homework"))+" ")]),_c('v-container',{staticClass:"mt-2"},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
                        data: item.activityInteractions[0].dueDate
                          ? _vm.dateToDateTimeArray(
                              _vm.utcDateArrayToDateWithTimezone(
                                item.activityInteractions[0].dueDate
                              )
                            )
                          : _vm.date.endDate,
                        label: 'teacherLecture.prop.endTime',
                        mandatory: true,
                        clearable: false,
                        minDate: _vm.todayISOString,
                      },"timePickerProp":{
                        data: item.activityInteractions[0].dueDate
                          ? _vm.dateToDateTimeArray(
                              _vm.utcDateArrayToDateWithTimezone(
                                item.activityInteractions[0].dueDate
                              )
                            ).slice(3, 5)
                          : _vm.date.endTime,
                        label:
                          'teacher.tabs.schedules.unavailabilityDialog.timeEnd',
                      }},on:{"update-time":_vm.updateDateTimeEnd}})],1)]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-calendar ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.assign_homework"))+" ")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"400","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.isTeacher)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.manageDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("actions.delete"))+" ")])])],1)],1):_c('v-row',{staticClass:"d-inline-flex",attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"21"}})],1)],1)])]}},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('tr',{staticClass:"v-data-table__expanded v-data-table__expanded__content"},[_c('td',{attrs:{"colspan":headers.length}},[(item.activityInteractions.length > 0)?_c('v-data-table',{staticClass:"ma-4",attrs:{"headers":_vm.activityInteractionHeaders,"items":item.activityInteractions,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.grade",fn:function(ref){
                      var item = ref.item;
return [(_vm.isSameTeacher && item.dateSolved)?_c('v-edit-dialog',{attrs:{"cancel-text":_vm.$t('actions.cancel'),"large":"","persistent":"","return-value":item.grade,"save-text":_vm.$t('actions.save')},on:{"update:returnValue":function($event){return _vm.$set(item, "grade", $event)},"update:return-value":function($event){return _vm.$set(item, "grade", $event)},"save":function($event){return _vm.updateGrade(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" "+_vm._s(_vm.$t("activityInteraction.actions.update_grade"))+" ")]),_c('number-field',{attrs:{"label":_vm.$t('activityInteraction.prop.grade'),"single-line":"","type":"float"},model:{value:(item.grade),callback:function ($$v) {_vm.$set(item, "grade", $$v)},expression:"item.grade"}})]},proxy:true}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.grade != null ? item.grade : _vm.$t("activityInteraction.messages.no_grade"))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]):(!item.dateSolved)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("activityInteraction.messages.not_solved"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.grade != null ? item.grade : _vm.$t("activityInteraction.messages.no_grade"))+" ")])]}},{key:"item.timeConsumed",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.timeConsumed || "--")+" ")])]}},{key:"item.dateSolved",fn:function(ref){
                      var item = ref.item;
return [(item.dateSolved)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dateSolved,"medium"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("activityInteraction.messages.not_solved"))+" ")])]}},{key:"item.dueDate",fn:function(ref){
                      var item = ref.item;
return [(item.dueDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dueDate,"medium"))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [(item.content)?_c('v-icon',{on:{"click":function($event){return _vm.openPDFDialog(item)}}},[_vm._v(" mdi-eye ")]):_vm._e(),(item.content)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadContent(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" file_download ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download_file")))])]):_vm._e()]}}],null,true)}):_vm._e(),(item.activityInteractions.length === 0)?_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("lecture.noResults"))+" ")]):_vm._e()],1)])]}}],null,true)}),_c('delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"loading":_vm.deleteLoading},on:{"cancel":function($event){_vm.deleteDialog = false},"submit":_vm.deleteActivity}}),(_vm.previewDialog)?_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[(_vm.preview)?_c('activity-preview',{attrs:{"activity":_vm.preview},on:{"cancel":_vm.closePreview}}):_vm._e()],1):_vm._e(),(_vm.recordingDialog)?_c('PlayRecordingDialog',{attrs:{"activityInteractionId":_vm.selectedRecording},on:{"close-dialog":function($event){_vm.recordingDialog = false}}}):_vm._e(),(_vm.pdfDialog)?_c('v-dialog',{attrs:{"width":"80%"},on:{"click:outside":_vm.closePDFDialog},model:{value:(_vm.pdfDialog),callback:function ($$v) {_vm.pdfDialog=$$v},expression:"pdfDialog"}},[_c('PDFViewer',{attrs:{"exercise":_vm.selectedEntity,"homework":_vm.pdfHomework},on:{"closeDialog":_vm.closePDFDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }