<template>
  <div class="image-edit">
    <v-row v-if="entity.photo" no-gutters>
      <v-col cols="12" class="text-right">
        <v-icon @click="deletePhoto"> mdi-close </v-icon>
      </v-col>
    </v-row>
    <input
      ref="fileLoader"
      type="file"
      hidden
      :accept="extensions.image"
      @change="uploadPhoto(entity, 'photo', entity.name, image, ...arguments)"
    />
    <v-hover v-slot="{ hover }">
      <v-img
        max-height="250"
        max-width="250"
        style="margin: auto; cursor: pointer"
        :src="image.photo"
        @click="choosePhoto"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="v-expand--transition d-flex"
            style="height: 100%"
          >
            <v-icon x-large color="primary">mdi-pencil</v-icon>
          </div>
        </v-expand-transition>
      </v-img>
    </v-hover>
  </div>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { uploadPhoto } from "@/common/file-utils";
import extensions from "@/common/file-extensions";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "ImageEdit",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
      image: {
        loading: false,
        photo: require("@/assets/icon_visits.png"),
      },
      extensions,
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.entity = newVal;
          if (newVal.userId && (!oldVal || oldVal.userId != newVal.userId))
            UserDataEntityRepository.getPhoto(newVal.userId)
              .then((data) => {
                if (data.size > 0) {
                  this.image.photo = URL.createObjectURL(data);
                }
              })
              .catch(() =>
                this.$log.debug(
                  "Error fetching profile photo for user: " + newVal.userId
                )
              );
        }
      },
      immediate: true,
    },
  },
  methods: {
    choosePhoto() {
      this.$refs.fileLoader.click();
    },
    deletePhoto() {
      this.entity.photo = null;
      this.image.photo = require("@/assets/icon_visits.png");
    },
    uploadPhoto,
  },
};
</script>

<style>
.v-expand--transition {
  background-color: grey;
  opacity: 0.7;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
