import AdminDetail from "@/mockups/admin-profile/AdminDetail";
import Profile from "@/account/profile/Profile";
import ProfileEdit from "@/account/profile/ProfileEdit";

const routes = [
  // ADMIN ROUTES DEFINITION
  {
    path: "/admin/admins/:id",
    name: "AdminView",
    component: AdminDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "AdminView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "admin.navbar.info",
        },
      },
      {
        path: "edit",
        name: "AdminView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "admin.navbar.edit",
        },
      },
    ],
  },
  // MY ACCOUNT ROUTES DEFINITION
  {
    path: "/account/profile/admin",
    name: "ProfileAdminView",
    component: AdminDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "ProfileAdminView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "admin.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ProfileAdminView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "admin.navbar.edit",
        },
      },
    ],
  },
];

export default routes;
