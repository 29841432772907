import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "/virtual-classroom";

export default {
  async getVirtualClassroomInfo(topic) {
    return (await HTTP.get(RESOURCE_NAME + "/" + topic)).data;
  },

  async endLecture(id) {
    const response = await HTTP.delete(`${RESOURCE_NAME}/${id}`);
    return response.data;
  },

  async refreshToken() {
    const response = await HTTP.post(`${RESOURCE_NAME}/refreshToken`);
    return response.data;
  },
};
