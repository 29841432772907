<template>
  <v-container>
    <v-img :src="image"></v-img>
  </v-container>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ClrmImageViewer",
  props: {
    activity: {
      type: Object,
    },
  },
  data() {
    return {
      image: {},
    };
  },
  mounted() {
    this._fetchImage();
  },
  methods: {
    async _fetchImage() {
      await ActivityEntityRepository.downloadActivityFile(
        this.activity.activityId
      )
        .then((response) => {
          const blob = new Blob([response.data]);
          this.image = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching image from activity with ID " + this.activity.id
          )
        );
    },
  },
};
</script>
