import colors from "./colors";

/**
 * Function that returns a colour code depending on the income string
 * same strings return same colour always
 * @param {Input String} str
 * @returns hex colour
 */
function stringToColour(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

/**
 * With a given string, gives text colour white or black depending on how
 * bright is the colour
 * @param {Input string} str
 * @returns "black or white"
 */
function stringToColourText(str) {
  let rgb = hexToRGB(stringToColour(str));

  let brightness = Math.round(
    (parseInt(rgb["r"]) * 299 +
      parseInt(rgb["g"]) * 587 +
      parseInt(rgb["b"]) * 114) /
      1000
  );
  return brightness > 125 ? "black" : "white";
}

/**
 * Returns RGB value of a Hex value color
 * @param {Input color} hex
 * @returns color in RGB array
 */
function hexToRGB(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 *
 * @param {Index of what color to pick} index
 * @returns object with color {backgroundColor, textColor}
 */
function getColor(index) {
  const newIndex = index % colors.length;
  return colors[newIndex];
}

function getChipBackColor(item) {
  const l = getColor(item.language ? item.id + item.language.id : item.id);
  return l.backgroundColor;
}

function getChipTextColor(item) {
  const l = getColor(item.language ? item.id + item.language.id : item.id);
  return l.textColor;
}

export {
  stringToColour,
  stringToColourText,
  getColor,
  getChipBackColor,
  getChipTextColor,
};
