var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lectureList)?_c('div',[_c('div',{staticClass:"text-right mr-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"mx-3",attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"change":[function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}},_vm.check]}}),_c('label',{attrs:{"for":"checkbox"}},[_vm._v(_vm._s(_vm.$t("lecture.headers.past-lectures")))])]),_c('v-data-table',{staticClass:"rows-clickable",attrs:{"items":_vm.lectureList,"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"item-class":_vm.itemRowBackground},on:{"click:row":_vm.lectureDetail},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.startTime,"medium"))+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.endTime,"medium"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.lectureDetail(item)}}},'v-icon',attrs,false),on),[_vm._v(" description ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.detail")))])])]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }