<template>
  <div v-if="lectureList">
    <div class="text-right mr-6">
      <input
        type="checkbox"
        id="checkbox"
        v-model="checked"
        @change="check"
        class="mx-3"
      />
      <label for="checkbox">{{ $t("lecture.headers.past-lectures") }}</label>
    </div>
    <v-data-table
      class="rows-clickable"
      :items="lectureList"
      disable-pagination
      hide-default-footer
      :headers="headers"
      :item-class="itemRowBackground"
      @click:row="lectureDetail"
    >
      <template v-slot:[`item.startTime`]="{ item }">
        {{ item.startTime | dateTimeWithTz("medium") }}
      </template>
      <template v-slot:[`item.endTime`]="{ item }">
        {{ item.endTime | dateTimeWithTz("medium") }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              @click.stop="lectureDetail(item)"
              v-bind="attrs"
              v-on="on"
            >
              description
            </v-icon>
          </template>
          <span>{{ $t("actions.detail") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateArrayToDate } from "@/common/conversion-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

export default {
  name: "LectureList",
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    registrationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      checked: false,
      tableFooterProps,
    };
  },
  created() {
    this.getLectures(false);
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lecture.prop.lesson"),
          value: "lesson.name",
        },
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
    lectureList() {
      return this.items;
    },
  },
  methods: {
    getLectures(past) {
      LectureEntityRepository.getAllToAttend({
        params: {
          past: past,
          studentId: parseInt(this.studentId),
          registrationId: parseInt(this.registrationId),
        },
      }).then((res) => (this.items = res));
    },
    check() {
      this.getLectures(this.checked);
    },
    itemRowBackground(item) {
      const dateTimeNow = new Date();
      item.endTime[5] = 0;
      const itemEndTime = dateArrayToDate(item.endTime);
      if (dateTimeNow > itemEndTime && !item.accessible)
        return "not-accessible";
    },
    lectureDetail(lecture) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lecture.id,
            studentId: this.studentId,
            backPrevious: true,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.not-accessible {
  background-color: lightgrey;
}
</style>
