var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sessionState === _vm.sessionStatusEnum.PREVIEW)?_c('div',{staticClass:"clrm-classroom__preview"},[_c('div',{staticClass:"clrm-classroom__preview__content"},[_c('PreviewDevices',{attrs:{"classroomMode":_vm.classroomInfo.classroomMode,"zoom":_vm.zoom},on:{"cancel-preview":_vm.cancelPreview,"end-preview":_vm.endPreview}})],1)]):_vm._e(),_c('div',{staticClass:"clrm-classroom",class:{
      'clrm-classroom--student': _vm.classroomInfo.classroomMode === 'student',
      'clrm-classroom--full-video': _vm.onlyCameras,
    }},[_c('div',{staticClass:"clrm-classroom__discussion"},[(_vm.showSettings)?_c('ClassroomSettings',{staticClass:"mt-3",attrs:{"classroomMode":_vm.classroomInfo.classroomMode,"zoom":_vm.zoom},on:{"close":function($event){_vm.showSettings = false}}}):_vm._e(),_c('ClassroomParticipants',{ref:"ClassroomParticipants",attrs:{"classroomInfo":_vm.classroomInfo,"zoom":_vm.zoom}}),(
          _vm.classroomInfo.classroomMode === 'teacher' &&
          _vm.sessionState == _vm.sessionStatusEnum.JOINED
        )?_c('div',{staticClass:"clrm-classroom__discussion__toggle"},[_c('button',{staticClass:"clrm-classroom__discussion__toggle__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.switchOnlyCamera()}}},[_c('icon',{staticClass:"clrm-classroom__discussion__toggle__button__right",attrs:{"name":"chevron-right","width":"12","height":"12"}}),_c('icon',{staticClass:"clrm-classroom__discussion__toggle__button__left",attrs:{"name":"chevron-left","width":"12","height":"12"}})],1)]):_vm._e()],1),_c('div',{staticClass:"clrm-classroom__work"},[(
          _vm.classroomInfo.classroomMode === 'teacher' && _vm.sessionState != null
        )?_c('ClrmTabs',{attrs:{"currentSessionActivity":_vm.currentActivity,"zoom":_vm.zoom,"tabHandler":_vm.tabHandler,"classroomInfo":_vm.classroomInfo}}):_vm._e(),(
          _vm.classroomInfo.classroomMode === 'student' &&
          _vm.tabHandler.state.currentSharedTab
        )?_c('ClrmTabsStudent',{attrs:{"zoom":_vm.zoom,"tab":_vm.tabHandler.state.currentSharedTab}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }