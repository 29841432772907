var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.title"))+" ")])],1)],1),_c('v-card-text',[(_vm.productRequest)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.product.title"))+": "+_vm._s(_vm.productRequest.product.title))]),_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.product.type"))+": "+_vm._s(_vm.$t( ("registration.product_type." + (_vm.productRequest.product.productType)) )))]),_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("productRequest.prop.classesPerWeek"))+": "+_vm._s(_vm.productRequest.classesPerWeek)+" ")]),_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.class_duration", { value: _vm.productRequest.product.classDuration, }))+" ")])],1):_vm._e(),_c('v-divider'),_c('v-row',[_c('v-col',{staticClass:"text-center text-h6"},[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.student"))+": "+_vm._s(_vm.getPersonName(_vm.productRequest.student))+" ")])],1),(!_vm.singlePersonCourse)?_c('v-row',[_c('autocomplete',{attrs:{"items":_vm.availableStudents,"item-text":_vm.getPersonName,"label":_vm.$t('productRequest.program_dialog.group'),"loading":_vm.loadingStudents,"rules":[
            function (v) { return v.length >= _vm.productRequest.product.minStudents ||
              _vm.$t('productRequest.program_dialog.errors.min_students', {
                value: _vm.productRequest.product.minStudents - 1,
              }); },
            function (v) { return v.length <= _vm.productRequest.product.maxStudents ||
              _vm.$t('productRequest.program_dialog.errors.max_students', {
                value: _vm.productRequest.product.maxStudents - 1,
              }); } ],"item-value":"id","clearable":false,"outlined":"","multiple":""},on:{"change":_vm.getPersons},model:{value:(_vm.students),callback:function ($$v) {_vm.students=$$v},expression:"students"}})],1):_vm._e(),_c('v-row',[_c('autocomplete',{attrs:{"clearable":false,"disabled":!_vm.validStudentGroup,"items":_vm.availableTeachers,"item-text":_vm.getPersonName,"label":_vm.$t('productRequest.program_dialog.teacher'),"loading":_vm.loadingTeachers,"rules":[
            function (v) { return !!v ||
              _vm.$t('productRequest.program_dialog.errors.teacher_required'); } ],"return-object":"","outlined":""},on:{"change":_vm.updateAvailabilities},model:{value:(_vm.teacher),callback:function ($$v) {_vm.teacher=$$v},expression:"teacher"}})],1),(_vm.teacher)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[(_vm.selectedSchedule)?_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("productRequest.program_dialog.selected_slot"))+": ")]),_vm._l((_vm.selectedSlots),function(slot,i){return _c('v-row',{key:i},[_vm._v(" "+_vm._s(_vm.$d(new Date(slot.start), "schedule"))+" - "+_vm._s(_vm.$d(new Date(slot.end), "time"))+" ")])})],2):_vm._e(),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"loading":_vm.loadingEdition,"color":"success","disabled":!_vm.selectedSchedule},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" save ")]),_c('span',[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"start"}},[(_vm.loadingAvailabilities)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"height":_vm.height}},[_c('v-calendar',{ref:"calendar",attrs:{"value":_vm.focus,"color":"primary","events":_vm.availabilities,"event-color":_vm.getEventColor,"interval-height":25,"interval-minutes":30,"interval-count":48,"type":"week","weekdays":[1, 2, 3, 4, 5, 6, 7, 0],"locale":_vm.$i18n.locale},on:{"click:event":_vm.selectSlot},scopedSlots:_vm._u([{key:"event",fn:function(ref){
          var event = ref.event;
return [_c('div',{staticClass:"event-div"},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])]),(event.checked)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()],1)],1)]}}],null,false,1895411995)})],1)],1)],1):_vm._e()],1),_c('v-card-actions')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }