import CompanyDetail from "@/mockups/company/CompanyDetail";
import CompanyInfoTab from "@/mockups/company/tabs/CompanyInfoTab";
import CompanyInfoFormTab from "@/mockups/company/tabs/CompanyInfoFormTab";
import BalanceTab from "@/mockups/company/tabs/BalanceTab";
import MovementsTab from "@/mockups/company/tabs/MovementsTab";
import CompanyStudentListTab from "@/mockups/company/tabs/CompanyStudentListTab";
import CompanyDashboard from "@/mockups/company/views/CompanyDashboard";

const routes = [
  // ADMIN ROUTES DEFINITION
  {
    path: "/admin/companies/:id",
    name: "CompanyView",
    component: CompanyDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "CompanyView Detail",
        component: CompanyInfoTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "company.navbar.info",
        },
      },
      {
        path: "edit",
        name: "CompanyView Edit",
        component: CompanyInfoFormTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "company.navbar.edit",
        },
      },
      {
        path: "balance",
        name: "CompanyView Balance",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "company.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "CompanyView Movements",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "company.navbar.movements",
        },
      },
      {
        path: "students",
        name: "CompanyView Students",
        component: CompanyStudentListTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "company.navbar.students",
        },
      },
    ],
  },
  // MY ACCOUNT ROUTES DEFINITION
  {
    path: "/company/dashboard",
    name: "Company Home",
    component: CompanyDashboard,
    meta: {
      authority: ["ROLE_COMPANY"],
      label: "company.headers.dashboard",
    },
  },
  {
    path: "/account/profile/company",
    name: "ProfileCompanyView",
    component: CompanyDetail,
    meta: {
      authority: ["ROLE_COMPANY"],
    },
    children: [
      {
        path: "info",
        name: "ProfileCompanyView Detail",
        component: CompanyInfoTab,
        meta: {
          authority: ["ROLE_COMPANY"],
          label: "company.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ProfileCompanyView Edit",
        component: CompanyInfoFormTab,
        meta: {
          authority: ["ROLE_COMPANY"],
          label: "company.navbar.edit",
        },
      },
      {
        path: "balance",
        name: "ProfileCompanyView Balance",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_COMPANY"],
          label: "company.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "ProfileCompanyView Movements",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_COMPANY"],
          label: "company.navbar.movements",
        },
      },
      {
        path: "students",
        name: "ProfileCompanyView Students",
        component: CompanyStudentListTab,
        meta: {
          authority: ["ROLE_COMPANY"],
          label: "company.navbar.students",
        },
      },
    ],
  },
];

export default routes;
