<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col order="1" order-md="2" class="text-right">
        <v-btn
          color="success ml-2"
          :to="{ name: 'Wordlist Create', params: { backPrevious: true } }"
        >
          <v-icon>add</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <debounced-text-field
          append-icon="search"
          class="d-md-inline-block"
          dense
          hide-details
          v-model="search"
          :label="$t('search')"
          @input="onSearchChange"
        ></debounced-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <LanguageSelector
          v-model="languageFilter"
          dense
          :clearable="!levelFilter"
          outlined
          :debouncing="300"
          :label="$t('activity.prop.language')"
          show-chips
          @input="onSelectorChange('onLanguageChange', ...arguments)"
        ></LanguageSelector>
      </v-col>

      <v-col cols="12" md="4">
        <LevelSelector
          v-model="levelFilter"
          dense
          clearable
          outlined
          :debouncing="300"
          :label="$t('activity.prop.level')"
          :language-filter="languageFilter ? languageFilter.id : null"
          :show-language="false"
          @input="onSelectorChange('onLevelChange', ...arguments)"
        ></LevelSelector
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="rows-clickable elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :options="pagination"
          :server-items-length="totalItems"
          @click:row="entityDetail"
          @update:options="onPaginationChange"
        >
          <template v-slot:[`item.language`]="{ item }">
            <v-chip
              v-if="item.level.language"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ $t("languages." + item.level.language.name) | uppercase }}
            </v-chip>
          </template>
          <template v-slot:[`item.level`]="{ item }">
            <v-chip
              v-if="item.level"
              :color="getChipBackColor(item.level.language)"
              :text-color="getChipTextColor(item.level.language)"
            >
              {{ item.level.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  @click.stop="entityDetail(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>description</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.detail") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="orange"
                  @click.stop="edit(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.edit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click.stop="startDeleteAction(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Confirmation dialog for deleting a wordlist -->
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="stopDeleteAction"
      @submit="deleteWordlist"
    >
    </delete-dialog>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const WordlistEntityRepository = RepositoryFactory.get(
  "WordlistEntityRepository"
);

export default {
  name: "WordlistList",
  components: {
    DebouncedTextField,
    DeleteDialog,
    LevelSelector,
    LanguageSelector,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      search: this.$route.query.search,
      selectedEntity: null,
      deleteDialog: false,
      tableFooterProps,
      levelFilter: null,
      languageFilter: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("wordlist.prop.name"),
          value: "name",
        },
        {
          text: this.$t("wordlist.prop.language"),
          value: "language",
        },
        {
          text: this.$t("wordlist.prop.level"),
          value: "level",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    if (this.$route.query.levelFilter) {
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.languageFilter) {
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const sortMapping = {
        level: "level.name",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          sort: generateSort(this.pagination, sortMapping),
          size: this.pagination.itemsPerPage,
          search: this.search ? this.search : undefined,
          levelId: this.levelFilter ? this.levelFilter.id : undefined,
          langId: this.languageFilter ? this.languageFilter.id : undefined,
        },
      };
      WordlistEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error saving wordlist list with params: " + options)
        )
        .finally(() => (this.isLoading = false));
    },
    entityDetail(entity) {
      this.$router.push({
        name: "Wordlist Detail",
        params: {
          id: entity.id,
          backPrevious: true,
        },
      });
    },
    edit(item) {
      this.$router.push({
        name: "Wordlist Form",
        params: { id: item.id, backPrevious: true },
      });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.search = this.search ? this.search : undefined;
      query.levelFilter = this.levelFilter?.id.toString();
      query.languageFilter =
        this.levelFilter == null
          ? this.languageFilter?.id.toString()
          : undefined;
      this.redirect(query);
    },
    onSearchChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    onSelectorChange(op, value) {
      if (op === "onLevelChange" && value) {
        this.languageFilter = value.language;
      }
      if (
        op === "onLanguageChange" &&
        this.levelFilter != null &&
        this.levelFilter.language.id === value?.id
      ) {
        return;
      }
      this.onSearchChange();
    },
    startDeleteAction(entity) {
      this.selectedEntity = entity;
      this.deleteDialog = true;
    },
    stopDeleteAction() {
      this.selectedEntity = null;
      this.deleteDialog = false;
    },
    deleteWordlist() {
      this.deleteLoading = true;
      WordlistEntityRepository.delete(this.selectedEntity.id)
        .then(() => {
          this.items.splice(this.items.indexOf(this.selectedEntity), 1);
          this.totalItems = this.totalItems - 1;
          this.stopDeleteAction();
        })
        .catch(() =>
          this.$log.debug(
            "Error removing wordlist with ID " + this.selectedEntity.id
          )
        )
        .finally(() => (this.deleteLoading = false));
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
