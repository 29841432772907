<template>
  <v-container>
    <v-card v-if="!loading">
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-row align="center" no-gutters>
              <v-col cols="12" class="text-center">
                <span class="headline no-split-words">
                  {{ $t("student.student_info") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field
                v-model="student.name"
                dense
                disabled
                readonly
                prepend-inner-icon="notes"
                :label="$t('profile.fields.name')"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="student.surname"
                dense
                disabled
                readonly
                prepend-inner-icon="notes"
                :label="$t('profile.fields.surname')"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="birthDateFormatted"
                dense
                disabled
                readonly
                prepend-icon="mdi-calendar"
                :label="$t('profile.fields.birthdate')"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="relationshipName"
                dense
                disabled
                readonly
                prepend-icon="mdi-link"
                :label="$t('tutor.prop.relationship')"
              >
              </v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn
            class="primary mt-4"
            :disabled="loadingRegistry || selfStudent"
            :loading="loadingRegistry"
            @click="confirm"
          >
            {{ $t("account.actions.link_student") }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");

export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      loading: false,
      loadingRegistry: false,
      student: {},
      relationship: {},
      user: auth.getUser(),
      selfStudent: false,
    };
  },
  created() {
    this._fetchData(this.$route.query.key);
  },
  computed: {
    relationshipName() {
      return this.$t(`relationship.values.${this.relationship.name}`);
    },
    birthDateFormatted() {
      const date = this.student.birthdate;
      return date ? this.$d(new Date(date[0], date[1] - 1, date[2])) : null;
    },
  },
  methods: {
    _fetchData(key) {
      this.loading = true;
      return TutorEntityRepository.getByKey(key)
        .then((response) => {
          this.student = response.student;
          this.relationship = response.relationship;
          if (this.student.id === this.user.roleId) {
            this.$notify({
              title: this.$t("tutor.error.student_linking_itself.title"),
              text: this.$t("tutor.error.student_linking_itself.text"),
              type: "error",
              duration: 30000,
            });
            this.selfStudent = true;
          }
        })
        .catch(() => this.$router.goToUserHome())
        .finally(() => (this.loading = false));
    },
    confirm() {
      if (this.student.userId === this.user.id) {
        this.$notify({
          title: "Link Error",
          text: this.$t("account.notifications.error_link"),
          type: "error",
          duration: 30000,
        });
      } else {
        this.loadingRegistry = true;
        TutorEntityRepository.linkToStudent(this.user.id, this.$route.query.key)
          .then(() => {
            this.$notify({
              title: this.$t("account.notifications.successfull"),
              text: this.$t("account.notifications.successfull_linked"),
              type: "success",
            });
            // Updates userData to get the new role
            return auth
              .reloadUserData()
              .then(() => this.$router.goToUserHome());
          })
          .catch(() => this.$log.debug("Error linking tutor to student"))
          .finally(() => (this.loadingRegistry = false));
      }
    },
  },
};
</script>
