<template>
  <v-container>
    <v-row v-if="!loading">
      <v-col cols="12" v-if="value.type === 'CHARGE'" class="text-center">
        <span v-if="value.source">
          <h4>
            {{
              $t("wallet.movements.detail.transfer_detail", {
                source: userName(value.source),
              })
            }}
          </h4>
        </span>
        <span v-else-if="value && value.method">
          <h4>
            {{
              $t("wallet.movements.detail.charge_detail", {
                method: $t(`wallet.methods.${value.method}`),
              })
            }}
          </h4>
        </span>
      </v-col>
      <v-col cols="12" v-else-if="value.type === 'PAYMENT'" class="text-center">
        <span v-if="value.edition">
          <h4>
            {{ $t("wallet.movements.detail.payment.course") }}:
            {{ value.edition.product.title }}
          </h4>
        </span>
        <span v-else-if="value.lecture">
          <h4>
            {{ $t("wallet.movements.detail.payment.lecture") }}:
            {{ value.lecture.startTime | dateTimeWithTz("long") }}
          </h4>
        </span>
        <span v-else-if="value.target">
          <h4>
            {{ $t("wallet.movements.detail.payment.transfer") }}:
            {{ userName(value.target) }}
          </h4>
        </span>
      </v-col>
      <v-col cols="12" v-else-if="value.type == 'REFUND'" class="text-center">
        <span v-if="value.lecture">
          <h4>
            {{
              $t("wallet.movements.detail.refund.lecture_cancellation", {
                lecture: value.lecture.productName,
              })
            }}
          </h4>
          <h4>
            {{
              $t(
                "wallet.movements.detail.refund.lecture_dates_cancellation.title"
              )
            }}
            {{ value.lecture.startTime | dateTimeWithTz("short") }}
            {{
              $t(
                "wallet.movements.detail.refund.lecture_dates_cancellation.from"
              )
            }}
            {{ value.lecture.startTime | dateTimeWithTz("time") }}
            {{
              $t("wallet.movements.detail.refund.lecture_dates_cancellation.to")
            }}
            {{ value.lecture.endTime | dateTimeWithTz("time") }}
          </h4>
        </span>
        <span v-else-if="value.edition"
          ><h4>
            {{
              $t("wallet.movements.detail.refund.edition_refund", {
                edition: value.edition,
              })
            }}{{ value.date | dateTimeWithTz("medium") }}
          </h4>
        </span>
        <span v-else
          ><h4>
            {{ $t("wallet.movements.detail.refund.payment_cancellation") }}
            {{ value.date | dateTimeWithTz("medium") }}
          </h4>
        </span>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-progress-circular
        class="mx-auto"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MovementDetail",
  props: {
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    userName(user) {
      return user.name + " " + user.surname;
    },
  },
};
</script>

<style></style>
