import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/topicTypes";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },
};
