import MeritForm from "@/mockups/teachers/components/MeritForm";
import TeacherDetail from "@/mockups/teachers/TeacherDetail";
import ReferenceForm from "./components/ReferenceForm";
import TeacherLectureList from "./components/TeacherLectureList";
import EditionsDetailTab from "./tabs/EditionsDetailTab";
import EditionsTab from "./tabs/EditionsTab";
import EducationTab from "./tabs/EducationTab";
import PersonalInfoFormTab from "./tabs/PersonalInfoFormTab";
import PersonalInfoTab from "./tabs/PersonalInfoTab";
import ReferencesTab from "./tabs/ReferencesTab";
import SchedulesTab from "./tabs/ScheduleTab";
import SupervisingTab from "./tabs/SupervisingTab";
import TeacherFeedbackTab from "./tabs/TeacherFeedbackTab";
import TeacherDashboard from "@/mockups/teachers/views/TeacherDashboard";
import SupervisorReviewTab from "@/mockups/teachers/tabs/SupervisorReviewTab";
import HeadmasterDashboard from "@/mockups/teachers/views/HeadmasterDashboard";
import TeacherSettledLecturesTab from "./tabs/TeacherSettledLecturesTab";

const routes = [
  // ADMIN ROUTES DEFINITION
  {
    path: "/admin/teachers/:id",
    name: "TeacherView",
    component: TeacherDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "education",
        name: "TeacherEducationTab",
        component: EducationTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.education",
        },
      },
      {
        path: "feedback",
        name: "TeacherFeedbackTab",
        component: TeacherFeedbackTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.lectureRatings",
        },
      },
      {
        path: "supervisor-review",
        name: "TeacherSupervisorReviewTab",
        component: SupervisorReviewTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.supervisors-feedback",
        },
      },
      {
        path: "info",
        name: "TeacherView Detail",
        component: PersonalInfoTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.info",
        },
      },
      {
        path: "edit",
        name: "TeacherView Edit",
        component: PersonalInfoFormTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.personalInfoForm",
        },
      },
      {
        path: "references",
        name: "TeacherReferencesTab",
        component: ReferencesTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.references",
        },
      },
      {
        path: "schedules",
        name: "TeacherSchedulesTab",
        component: SchedulesTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.schedule",
        },
      },
      {
        path: "merits/new",
        name: "TeacherMeritFormNew",
        component: MeritForm,
        meta: {
          userIsLogged: true,
          authority: "ROLE_ADMIN",
          label: "teacher.tabs.education.addDiploma",
        },
      },
      {
        path: "merits/:meritId/edit",
        name: "TeacherMeritFormEdit",
        component: MeritForm,
        meta: {
          userIsLogged: true,
          authority: "ROLE_ADMIN",
          label: "teacher.tabs.education.editDiploma",
        },
      },
      {
        path: "references/new",
        name: "TeacherReferenceFormNew",
        component: ReferenceForm,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_ADMIN"],
          label: "teacher.tabs.references.titleAdd",
        },
      },
      {
        path: "references/:referenceId/edit",
        name: "TeacherReferenceFormEdit",
        component: ReferenceForm,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_ADMIN"],
          label: "teacher.tabs.references.titleEdit",
        },
      },
      {
        path: "supervising",
        name: "TeacherSupervisingTab",
        component: SupervisingTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "teacher.navbar.supervising",
        },
      },
      {
        path: "supervising/:teacherId/lectures",
        name: "Admin Supervised Teacher Lecture List",
        component: TeacherLectureList,
        meta: {
          authority: ["ROLE_ADMIN"],
        },
      },
      {
        path: "editions",
        name: "EditionsTab",
        component: EditionsTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_ADMIN"],
          label: "teacher.tabs.editions.title",
        },
      },
      {
        path: "editions/:editionId",
        name: "EditionsDetailTab",
        component: EditionsDetailTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_ADMIN"],
          label: "teacher.tabs.editions.detail",
        },
      },
      {
        path: "settled-lectures",
        name: "TeacherSettledLecturesTab",
        component: TeacherSettledLecturesTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_ADMIN"],
          label: "lecture.headers.settled-lectures",
        },
      },
    ],
  },
  // MY ACCOUNT ROUTES DEFINITION
  {
    path: "/teacher/dashboard",
    name: "Teacher Home",
    component: TeacherDashboard,
    meta: {
      authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
      label: "teacher.dashboard.title",
    },
  },
  {
    path: "/headmaster/dashboard",
    name: "Headmaster Home",
    component: HeadmasterDashboard,
    meta: {
      authority: ["ROLE_HEADMASTER"],
      label: "teacher.dashboard.title",
    },
  },
  {
    path: "/account/profile/teacher",
    name: "ProfileTeacherView",
    component: TeacherDetail,
    children: [
      {
        path: "education",
        name: "ProfileTeacherEducationTab",
        component: EducationTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.education",
        },
      },
      {
        path: "feedback",
        name: "ProfileTeacherFeedbackTab",
        component: TeacherFeedbackTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.lectureRatings",
        },
      },
      {
        path: "info",
        name: "ProfileTeacherView Detail",
        component: PersonalInfoTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ProfileTeacherView Edit",
        component: PersonalInfoFormTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.personalInfoForm",
        },
      },
      {
        path: "references",
        name: "ProfileTeacherReferencesTab",
        component: ReferencesTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.references",
        },
      },
      {
        path: "schedules",
        name: "ProfileTeacherSchedulesTab",
        component: SchedulesTab,
        meta: {
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.navbar.schedule",
        },
      },
      {
        path: "merits/new",
        name: "ProfileMeritFormNew",
        component: MeritForm,
        meta: {
          userIsLogged: true,
          label: "teacher.tabs.education.addDiploma",
        },
      },
      {
        path: "merits/:meritId/edit",
        name: "ProfileMeritFormEdit",
        component: MeritForm,
        meta: {
          userIsLogged: true,
          label: "teacher.tabs.education.editDiploma",
        },
      },
      {
        path: "references/new",
        name: "ProfileReferenceFormNew",
        component: ReferenceForm,
        meta: {
          userIsLogged: true,
          label: "teacher.tabs.references.titleAdd",
        },
      },
      {
        path: "references/:referenceId/edit",
        name: "ProfileReferenceFormEdit",
        component: ReferenceForm,
        meta: {
          userIsLogged: true,
          label: "teacher.tabs.references.titleEdit",
        },
      },
      {
        path: "supervising",
        name: "ProfileTeacherSupervisingTab",
        component: SupervisingTab,
        meta: {
          authority: ["ROLE_HEADMASTER"],
          label: "teacher.navbar.supervising",
        },
      },
      {
        path: "supervising/:teacherId/lectures",
        name: "Supervised Teacher Lecture List",
        component: TeacherLectureList,
        meta: {
          authority: ["ROLE_HEADMASTER"],
        },
      },
      {
        path: "editions",
        name: "ProfileEditionsTab",
        component: EditionsTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.tabs.editions.title",
        },
      },
      {
        path: "editions/:editionId",
        name: "ProfileEditionsDetailTab",
        component: EditionsDetailTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "teacher.tabs.editions.detail",
        },
      },
      {
        path: "settled-lectures",
        name: "ProfileTeacherSettledLecturesTab",
        component: TeacherSettledLecturesTab,
        meta: {
          userIsLogged: true,
          authority: ["ROLE_TEACHER", "ROLE_TEACHER_CANDIDATE"],
          label: "lecture.headers.settled-lectures",
        },
      },
    ],
  },
];

export default routes;
