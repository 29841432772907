var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"pb-0 pt-0 pt-md-4"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"6"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("account.notifications.enter_password"))+" ")])]),_c('v-row',[_c('v-text-field',{staticClass:"form-control required",attrs:{"name":"password","maxLength":"100","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword1 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.form.password'),"rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                  function (value) { return value.length <= 100 ||
                    _vm.$t('account.form.validation.password_less_chars'); },
                  function (value) { return value.length > 3 ||
                    this$1.$t('account.form.validation.password_more_chars'); } ],"type":_vm.hidePassword1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword1 = !_vm.hidePassword1); }},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-row',[_c('v-text-field',{staticClass:"form-control required",attrs:{"name":"password2","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword2 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.actions.repeat_password'),"rules":[
                  function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                  _vm.passwordConfirmationRule ],"type":_vm.hidePassword2 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword2 = !_vm.hidePassword2); },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitNewPassword.apply(null, arguments)}},model:{value:(_vm.user.password2),callback:function ($$v) {_vm.$set(_vm.user, "password2", $$v)},expression:"user.password2"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary mt-4",on:{"click":_vm.submitNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("account.actions.reset_password"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }