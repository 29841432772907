<template>
  <div>
    <balance-detail
      v-show="!loading"
      v-model="wallet"
      multiple-targets
      :sourceId="userId"
      :targets="students"
    ></balance-detail>
    <loading-page v-if="loading"></loading-page>
  </div>
</template>

<script>
import BalanceDetail from "@/mockups/economy/wallet/BalanceDetail";
import LoadingPage from "@/components/loading-page/LoadingPage";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");

export default {
  name: "CompanyBalanceTab",
  components: {
    BalanceDetail,
    LoadingPage,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingStudents: false,
      loadingWallet: false,
      students: [],
      wallet: {},
    };
  },
  computed: {
    loading() {
      return this.loadingWallet || this.loadingStudents;
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loadingWallet = true;
      this.loadingStudents = true;
      WalletEntityRepository.getByUserId(this.userId)
        .then((data) => (this.wallet = data))
        .catch(() =>
          this.$log.debug(
            "Error fetching wallet for user with ID " + this.userId
          )
        )
        .finally(() => (this.loadingWallet = false));
      StudentEntityRepository.getAllStudentsOfCompany(this.userId)
        .then((res) => {
          this.students = res;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching students of company with user ID: " + this.userId
          )
        )
        .finally(() => (this.loadingStudents = false));
    },
  },
};
</script>
