<template>
  <lecture-list
    full-filtering
    pagination
    :link-to-session="false"
    :lectures="lectures"
    :loading="loading"
    :total-items="totalItems"
    @click:row="lectureDetail"
    @update:params="fetchLectures"
  ></lecture-list>
</template>

<script>
import LectureList from "@/mockups/components/LectureList";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

export default {
  name: "AdminLectureList",
  components: {
    LectureList,
  },
  data() {
    return {
      lectures: [],
      totalItems: 0,
      loading: false,
    };
  },
  methods: {
    fetchLectures(options) {
      this.loading = true;
      LectureEntityRepository.getAll(options)
        .then((response) => {
          this.lectures = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching lectures"))
        .finally(() => (this.loading = false));
    },
    lectureDetail(lecture) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lecture.id,
            backPrevious: true,
          },
        });
      }
    },
  },
};
</script>
