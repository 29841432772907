<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <!-- Current wordlist -->
        <v-row align="center" no-gutters>
          <v-col cols="12" md="6" lg="6">
            <h2>
              {{ wordlist.name }}
              <v-chip color="warning" class="ma-2">
                {{
                  $t("languages." + wordlist.level.language.name) | uppercase
                }}
              </v-chip>
              <v-chip color="primary" class="ma-2">
                {{ wordlist.level.name }}
              </v-chip>
            </h2>
          </v-col>

          <v-col cols="6" class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
            <v-btn class="warning ml-2" @click="edit">
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.edit") }} </span>
            </v-btn>
            <v-btn class="error ml-2" @click="dialog = true">
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.delete") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- Parent wordlists -->
        <v-row
          v-if="wordlist.parentWordlists"
          align="center"
          justify="space-between"
          no-gutters
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            v-if="wordlist.parentWordlists.length > 0"
          >
            <v-icon>mdi-arrow-up-thick</v-icon>
            <v-container
              class="d-inline"
              v-for="parentWordlist in wordlist.parentWordlists"
              :key="parentWordlist.id"
            >
              <router-link
                :to="{
                  name: 'Wordlist Detail',
                  params: { id: parentWordlist.id },
                }"
              >
                <h4 class="mt-2" style="display: contents">
                  {{ parentWordlist.name }}
                </h4>
              </router-link>
            </v-container>
          </v-col>
        </v-row>

        <!-- Child wordlist -->
        <v-row
          v-if="wordlist.childWordlist"
          align="center"
          justify="space-between"
          no-gutters
        >
          <v-col cols="12" md="6" lg="6">
            <v-icon>mdi-arrow-down-thick</v-icon>
            <v-container class="d-inline">
              <router-link
                :to="{
                  name: 'Wordlist Detail',
                  params: { id: wordlist.childWordlist.id },
                }"
              >
                <h4 style="display: contents">
                  {{ wordlist.childWordlist.name }}
                  <v-chip color="primary" class="ma-2">
                    {{ wordlist.childWordlist.level.name }}
                  </v-chip>
                </h4>
              </router-link>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="ml-2 text-center text-md-left">
              {{ $t("wordlist.prop.words") }}
            </h3>
            <word-list
              :wordlist="wordlist.id"
              :language="wordlist.level.language"
            ></word-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="dialog"
      :loading="isLoading"
      @cancel="dialog = false"
      @submit="deleteWordlist"
    ></delete-dialog>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import WordList from "@/mockups/content-cloud/wordlist/WordList";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WordlistEntityRepository = RepositoryFactory.get(
  "WordlistEntityRepository"
);

export default {
  name: "WordlistDetail",
  components: { LoadingPage, WordList, DeleteDialog },
  data() {
    return {
      loading: false,
      dialog: false,
      wordlist: null,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Update data only on wordlist change
    if (to.params.id !== from.params.id) {
      this._fetchData(this.$route.params.id);
    }
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(wordlistId) {
      this.loading = true;
      return WordlistEntityRepository.get(wordlistId)
        .then((res) => (this.wordlist = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    deleteWordlist() {
      this.loading = true;
      return WordlistEntityRepository.delete(this.wordlist.id)
        .then(() => {
          this.dialog = false;
          this.$router.push({ name: "Wordlist List" });
        })
        .catch(() =>
          this.$log.debug("Error removing wordlist with ID " + this.wordlist.id)
        )
        .finally(() => (this.loading = false));
    },

    edit() {
      this.$router.push({
        name: "Wordlist Form",
        params: { id: this.wordlist.id, backPrevious: true },
      });
    },
    back() {
      this.$router.push({
        name: "Wordlist List",
        params: { backAction: true },
      });
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.v-card__text {
  font-size: 16px !important;
  color: black !important;
}
</style>
