<template>
  <v-container v-if="!loading">
    <v-row no-gutters>
      <v-col v-if="isAdmin" align="right">
        <strong>{{ $t("tutor.student.add_student") }}</strong>
        <v-btn icon>
          <v-icon color="green" @click.stop="_fetchStudents">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <student-info
      v-for="student in students"
      :userId="userId"
      :key="student.id"
      :entity="student"
      @studentChanged="_fetchData"
    ></student-info>

    <!-- Not  search and add dialog -->
    <v-dialog
      v-model="addStudentDialog"
      v-if="addStudentDialog"
      width="75%"
      @click:outside="closeAddStudentsDialog"
    >
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10" md="11" lg="11">
              <h1 class="title ml-4">{{ $t("tutor.student.add_student") }}</h1>
            </v-col>

            <v-col cols="2" md="1" lg="1">
              <v-btn icon @click="closeAddStudentsDialog">
                <v-icon>close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-data-table
              :search="searchUnlinkedStudents"
              :headers="headersStudents"
              :items="unlinkedStudentsList"
              disable-pagination
              hide-default-footer
            >
              <template v-if="isAdmin" v-slot:[`item.action`]="{ item }">
                <v-tooltip top v-if="!loadingRow[item.id]">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="success"
                      @click.stop="handleValidation(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.add") }}</span>
                </v-tooltip>
                <loading-spinner v-else></loading-spinner>
              </template>

              <template v-slot:top>
                <v-row no-gutters>
                  <v-col cols="6" md="6" lg="6">
                    <v-text-field
                      v-model="searchUnlinkedStudents"
                      :label="$t('search')"
                      class="mx-4"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" lg="6">
                    <v-select
                      :items="relationships"
                      :loading="loadingRelationships"
                      dense
                      outlined
                      :label="$t('relationship.name')"
                      v-model="studentRelationship"
                      :item-text="
                        (item) =>
                          item.name
                            ? $t(`relationship.values.${item.name}`)
                            : item.name
                      "
                      item-id="id"
                      :rules="[
                        (v) =>
                          (v.id != 0 && v.name != null) ||
                          $t('relationship.error.required'),
                      ]"
                      return-object
                      @change="handleSelectRelationship"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  <loading-page v-else></loading-page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import StudentInfo from "./StudentInfo";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const RelationshipEntityRepository = RepositoryFactory.get(
  "RelationshipEntityRepository"
);
const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

export default {
  name: "StudentListTab",
  components: { LoadingPage, LoadingSpinner, StudentInfo },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      students: [],
      addStudentDialog: false,
      unlinkedStudentsList: [],
      searchUnlinkedStudents: "",
      relationships: [],
      loadingRelationships: false,
      studentRelationship: {},
      loadingRow: [],
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    headersStudents() {
      return [
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.nif"),
          value: "nif",
        },
        {
          text: this.$t("profile.fields.address"),
          value: "address",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "mainPhone",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      StudentEntityRepository.getAllStudentsOfTutor(this.userId)
        .then((res) => {
          this.students = res;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching students of tutor with user ID: " + this.userId
          )
        )
        .finally(() => (this.loading = false));
    },
    _fetchStudents() {
      const options = {
        params: {
          ids: this.students.map((element) => element.id),
        },
      };
      StudentEntityRepository.getAvailableStudentsForNewLink(options)
        .then((res) => {
          this.unlinkedStudentsList = res;
        })
        .catch(() => this.$log.debug("Error fetching students"));

      this._fetchRelationships();
      this.addStudentDialog = true;
    },
    _fetchRelationships() {
      this.loadingRelationships = true;
      RelationshipEntityRepository.getAll()
        .then((res) => (this.relationships = res))
        .catch(() => this.$log.debug("Error fetching relationships"))
        .finally(() => (this.loadingRelationships = false));
    },

    handleSelectRelationship(item) {
      this.studentRelationship = item;
    },
    newLinkToStudent(item) {
      this.$set(this.loadingRow, item.id, true);
      TutorEntityRepository.newLinkToStudent(
        this.userId,
        item.id,
        this.studentRelationship.id
      )
        .then(() => {
          this.$notify({
            title: this.$t("account.notifications.successfull"),
            text: this.$t("account.notifications.successfull_linked"),
            type: "success",
          });
          this.$emit("studentChanged");
          this._fetchData();
          this.closeAddStudentsDialog();
        })
        .catch(() => this.$log.debug("Error linking new tutor to student"))
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
    handleValidation(item) {
      if (this.$refs.form.validate()) {
        this.newLinkToStudent(item);
      }
    },
    closeAddStudentsDialog() {
      this.addStudentDialog = false;
      this.studentRelationship = {};
    },
  },
};
</script>
