var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6 mb-8 pl-3 pr-3"},[_c('v-card',{staticClass:"ml-auto mr-auto",attrs:{"max-width":"800px","min-width":"260px"}},[_c('v-card-title',{staticClass:"pb-0 pt-0 pt-md-4"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"5"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"username","name":"login","prepend-inner-icon":"person","type":"text","maxlength":50,"disabled":_vm.isLoading,"label":_vm.$t('profile.fields.login'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.username_required'); },
                function (v) { return !v ||
                  this$1.regex.LOGIN_REGEX.test(v) ||
                  _vm.$t('user_management.loginValid'); } ]},on:{"input":function($event){_vm.user.login = _vm.user.login.toLowerCase()}},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}}),_c('v-text-field',{staticClass:"required",attrs:{"name":"firstname","prepend-inner-icon":"notes","type":"text","disabled":_vm.isLoading,"label":_vm.$t('profile.fields.name'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.firstName_required'); } ]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('v-text-field',{staticClass:"required",attrs:{"name":"lastname","prepend-inner-icon":"notes","type":"text","disabled":_vm.isLoading,"label":_vm.$t('profile.fields.surname'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.lastName_required'); } ]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('language-selector',{staticClass:"required",attrs:{"label":_vm.$t('profile.fields.language'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.language_required'); } ]},model:{value:(_vm.user.langKey),callback:function ($$v) {_vm.$set(_vm.user, "langKey", $$v)},expression:"user.langKey"}}),_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"email","name":"email","prepend-inner-icon":"email","type":"email","disabled":_vm.isLoading,"label":_vm.$t('profile.fields.main_email'),"rules":_vm.emailRules},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"new-password","name":"password","prepend-inner-icon":"vpn_key","maxLength":"100","append-icon":_vm.hidePassword1 ? 'visibility' : 'visibility_off',"disabled":_vm.isLoading,"label":_vm.$t('account.form.password'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                function (value) { return value.length <= 100 ||
                  _vm.$t('account.form.validation.password_less_chars'); },
                function (value) { return value.length > 3 ||
                  this$1.$t('account.form.validation.password_more_chars'); } ],"type":_vm.hidePassword1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword1 = !_vm.hidePassword1); }},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"new-password","name":"password2","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword2 ? 'visibility' : 'visibility_off',"disabled":_vm.isLoading,"label":_vm.$t('account.actions.repeat_password'),"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.password_required'); },
                _vm.passwordConfirmationRule ],"type":_vm.hidePassword2 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hidePassword2 = !_vm.hidePassword2); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register.apply(null, arguments)}},model:{value:(_vm.user.password2),callback:function ($$v) {_vm.$set(_vm.user, "password2", $$v)},expression:"user.password2"}}),_c('v-radio-group',{attrs:{"rules":[
                function (v) { return !!v || _vm.$t('account.form.validation.role_required'); } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("account.form.role"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]},proxy:true}]),model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},_vm._l((_vm.options),function(option,i){return _c('v-radio',{key:i,attrs:{"label":_vm.$t(option.key),"value":option.value}})}),1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"primary mt-4",attrs:{"disabled":_vm.isLoading || !_vm.validForm,"loading":_vm.isLoading},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.$t("account.form.validation.register.name"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }