<template>
  <v-container>
    <v-form v-model="validForm">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6">
          <v-row>
            <v-select
              v-model="entity.type"
              outlined
              :items="userFileTypes"
              :item-text="translate"
              item-value="value"
              class="required"
              :label="$t('userFile.prop.type')"
              :rules="[
                (v) => !!v || $t('account.form.validation.username_required'),
              ]"
            ></v-select>
          </v-row>
          <v-row>
            <v-textarea
              v-model="entity.description"
              outlined
              :class="descriptionClass"
              :label="$t('userFile.prop.description')"
              :rules="descriptionRules"
            ></v-textarea>
          </v-row>
          <v-row @click="chooseFile">
            <input
              hidden
              class="d-none"
              type="file"
              ref="fileLoader"
              :accept="extensions.document"
              @change="uploadDocument(entity, 'file', file, ...arguments)"
              :rules="[(v) => !!v || $t('reference.error.required')]"
            />
            <v-icon>folder</v-icon>
            <span v-if="entity.file && !file.loading">
              {{ entity.file.fileName }}
            </span>
            <v-col v-else-if="file.loading" cols="4" class="d-inline-flex">
              <v-progress-linear
                indeterminate
                color="light-blue"
                height="10"
                striped
              ></v-progress-linear>
            </v-col>
            <span v-else>
              {{ $t("merit.messages.select-file") }}
              <span style="color: red">*</span>
            </span>
          </v-row>
          <v-row justify="center">
            <v-btn
              class="primary mt-4"
              :disabled="!validForm || !entity.file || file.loading"
              :loading="loading"
              @click="save"
              absolute
            >
              {{ $t("actions.save") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { deleteTempFile, uploadDocument } from "@/common/file-utils";
import { translate } from "@/common/translation-utils";
import extensions from "@/common/file-extensions";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const UserFileEntityRepository = RepositoryFactory.get(
  "UserFileEntityRepository"
);

export default {
  name: "FileForm",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
      // We put "loading" inside an object to pass it as a reference to file-utils
      file: {
        loading: false,
      },
      loading: false,
      userFileTypes: [
        {
          text: "userFile.type.GDPR",
          value: "GDPR",
        },
        {
          text: "userFile.type.OTHER",
          value: "OTHER",
        },
      ],
      validForm: true,
      extensions,
    };
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "file");
  },
  computed: {
    descriptionClass() {
      return this.entity.type === "OTHER" ? "required" : "";
    },
    descriptionRules() {
      return this.entity.type === "OTHER"
        ? [(v) => !!v || this.$t("userFile.error.description_required")]
        : [];
    },
  },
  methods: {
    chooseFile() {
      this.$refs.fileLoader.click();
    },
    uploadDocument,
    save() {
      this.loading = true;
      this.entity.userId = this.userId;
      UserFileEntityRepository.save(this.entity)
        .then(() => {
          this.entity.file = null;
          this.$emit("save");
        })
        .catch(() => this.$log.debug("Error saving user file: " + this.entity))
        .finally(() => (this.loading = false));
    },
    translate,
  },
};
</script>
