import store from "./store";

const sessionState = () => {
  return store.state.sessionState;
};

const classroomViewMode = () => {
  return store.state.classroomViewMode;
};

const isRecording = () => {
  return store.state.recording;
};

const participants = () => {
  return store.state.participants;
};

const currentDevice = () => {
  return store.state.currentDevice;
};

const hardwareDevices = () => {
  return store.state.hardwareDevices;
};

const cameras = () => {
  return store.state.hardwareDevices.cameras;
};

const microphones = () => {
  return store.state.hardwareDevices.microphones;
};

const selectedCamera = () => {
  return store.state.hardwareDevices.selectedCamera;
};

const selectedMicrophone = () => {
  return store.state.hardwareDevices.selectedMicrophone;
};

const deviceSharing = () => {
  return store.state.deviceSharing;
};

const userImages = () => {
  return store.state.userImages;
};

const audioActive = () => {
  return store.state.audioActive;
};

export function mapClassroomStateGetter(options) {
  const object = {};
  options.forEach((option) => {
    object[option] = eval(option);
  });
  return object;
}

export function getClassroomState() {
  return store.state;
}
