// Admin profile access
const navBarItemsAdmin = [
  {
    title: "tutor.navbar.profile",
    icon: "mdi-account",
    link: "TutorView Detail",
    notification: false,
    showed: true,
  },
  {
    title: "tutor.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "tutor.navbar.balance",
        icon: "mdi-cash",
        link: "TutorView Balance",
        notification: false,
        showed: true,
      },
      {
        title: "tutor.navbar.movements",
        icon: "mdi-swap-horizontal",
        link: "TutorView Movements",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "tutor.navbar.students",
    icon: "mdi-account-supervisor",
    link: "TutorView Students",
    notification: false,
    showed: true,
  },
];

const navBarItemsProfile = [
  {
    title: "tutor.navbar.profile",
    icon: "mdi-account",
    link: "ProfileTutorView Detail",
    notification: false,
    showed: true,
  },
  {
    title: "tutor.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "tutor.navbar.balance",
        icon: "mdi-cash",
        link: "ProfileTutorView Balance",
        notification: false,
        showed: true,
      },
      {
        title: "tutor.navbar.movements",
        icon: "mdi-swap-horizontal",
        link: "ProfileTutorView Movements",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "tutor.navbar.students",
    icon: "mdi-account-supervisor",
    link: "ProfileTutorView Students",
    notification: false,
    showed: true,
  },
];

export { navBarItemsAdmin, navBarItemsProfile };
