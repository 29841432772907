<template>
  <v-container class="px-0 py-0">
    <v-card flat tile>
      <v-row v-show="showPdf">
        <v-col class="px-0 py-0">
          <pdf
            :src="src"
            :page="page"
            style="display: inline-block; width: 100%"
            @loaded="onLoadedPdf"
            @error="onErrorLoadingPdf"
          ></pdf>
          <v-row justify="center" no-gutters>
            <v-col align="right">
              <v-icon @click="previousPage">mdi-arrow-left-bold</v-icon>
            </v-col>
            <v-col class="mt-n2">
              <v-row>
                <v-spacer />
                <v-col sm="6" md="2" class="mx-n4 mt-n1">
                  <v-text-field
                    v-model.number="page"
                    dense
                    :max="numPages"
                    maxLength="3"
                    min="1"
                    type="Number"
                  ></v-text-field>
                </v-col>
                <v-col align="left">
                  <span style="font-size: 17px"> /{{ numPages }} </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-icon @click="nextPage"> mdi-arrow-right-bold </v-icon>
              <a ref="hiddenDownloader" class="d-none" />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    @click="downloadContent()"
                    v-bind="attrs"
                    v-on="on"
                    >file_download</v-icon
                  >
                </template>
                <span>{{ $t("activity.actions.download_file") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-container v-if="!showPdf">
        <v-row justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import { downloadFile } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);

export default {
  name: "PDFViewer",
  components: {
    pdf,
  },
  props: ["exercise", "homework"],
  data() {
    return {
      page: 1,
      src: null,
      numPages: null,
      showPdf: false,
    };
  },
  mounted() {
    this.fileUrl();
  },
  watch: {
    src() {
      if (this.src != null) {
        this.src.promise.then((pdf) => {
          this.numPages = pdf.numPages;
          this.page = 1;
        });
      }
    },
    page(newValue, oldValue) {
      this.page = newValue === "" ? oldValue : newValue;
    },
  },
  methods: {
    fileUrl() {
      if (!this.homework) {
        ActivityEntityRepository.downloadActivityFile(this.exercise).then(
          (res) => {
            const blob = new Blob([res.data]);
            const objectURL = URL.createObjectURL(blob);
            this.src = pdf.createLoadingTask(objectURL);
            this.page = 1;
          }
        );
      } else {
        ActivityInteractionEntityRepository.getContent(this.exercise).then(
          (res) => {
            const blob = new Blob([res.data]);
            const objectURL = URL.createObjectURL(blob);
            this.src = pdf.createLoadingTask(objectURL);
            this.page = 1;
          }
        );
      }
    },
    downloadContent() {
      if (!this.homework) {
        ActivityEntityRepository.downloadActivityFile(this.exercise).then(
          (res) => {
            downloadFile(res, this.$refs.hiddenDownloader);
            this.$notify({
              title: this.$t("activity.messages.downloading_file"),
              type: "success",
            });
          }
        );
      } else {
        ActivityInteractionEntityRepository.getContent(this.exercise).then(
          (res) => {
            downloadFile(res, this.$refs.hiddenDownloader);
            this.$notify({
              title: this.$t("activity.messages.downloading_file"),
              type: "success",
            });
          }
        );
      }
    },
    onLoadedPdf() {
      this.showPdf = true;
    },
    onErrorLoadingPdf(err) {
      this.$log.debug(
        "There was an error loading the PDF file: " + err.message
      );
      const errorRoute =
        err.message === "Invalid page request"
          ? "activity.error.changing-page-pdf"
          : "activity.error.loading-pdf";
      this.$notify({
        title: this.$t(errorRoute + ".title"),
        text: this.$t(errorRoute + ".body"),
        type: "error",
        duration: 30000,
      });
    },
    nextPage() {
      if (this.page < this.numPages) {
        this.page += 1;
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    goToPage(page) {
      this.page = page;
    },
  },
};
</script>
