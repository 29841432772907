<template>
  <v-container>
    <v-form v-model="validForm">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="entity.fullName"
            type="text"
            class="required"
            :rules="[(v) => !!v || $t('tutor.error.required')]"
            :label="$t('profile.fields.fullName')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="entity.mainPhone"
            type="text"
            class="required"
            :rules="[(v) => !!v || $t('tutor.error.required')]"
            :label="$t('profile.fields.main_phone')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="entity.secondaryPhone"
            type="text"
            :rules="[]"
            :label="$t('profile.fields.secondary_phone')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="entity.mainEmail"
            type="text"
            class="required"
            :rules="[
              (v) => !!v || $t('tutor.error.required'),

              (v) =>
                !v ||
                this.regex.EMAIL_REGEX.test(v) ||
                $t('tutor.error.regex.emailPattern'),
            ]"
            :label="$t('profile.fields.main_email')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="entity.secondaryEmail"
            type="text"
            :rules="[
              (v) =>
                !v ||
                this.regex.EMAIL_REGEX.test(v) ||
                $t('tutor.error.regex.emailPattern'),
            ]"
            :label="$t('profile.fields.secondary_email')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            dense
            :items="relationship"
            :loading="relationshipLoading"
            v-model="entity.relationship"
            class="required"
            :rules="[(v) => !!v || $t('tutor.error.required')]"
            :label="$t('tutor.prop.relationship')"
            :item-text="(item) => $t(`relationship.values.${item.name}`)"
            return-object
          >
          </v-select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import regex from "@/common/regex-validation";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const RelationshipEntityRepository = RepositoryFactory.get(
  "RelationshipEntityRepository"
);

export default {
  name: "tutorForm",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validForm: true,
      loading: false,
      entity: {},

      relationship: [],
      relationshipLoading: false,

      regex,
    };
  },
  created() {
    this.fetchRelationshipItems();
  },
  watch: {
    validForm(newVal, oldVal) {
      if (newVal != oldVal) this.$emit("validation-update", newVal);
    },
    value(val) {
      if (val) this.entity = val;
    },
    entity(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    back() {
      this.$router.push({ name: "Tutor List", params: { backAction: true } });
    },
    fetchRelationshipItems() {
      this.relationshipLoading = true;
      RelationshipEntityRepository.getAll()
        .then((data) => (this.relationship = data))
        .catch(() => this.$log.debug("Error fetching relationships"))
        .finally(() => (this.relationshipLoading = false));
    },
  },
};
</script>
