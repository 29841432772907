import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/merits";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async downloadMeritFile(id) {
    return HTTP.get(`${RESOURCE_NAME}/${id}/file`, {
      responseType: "blob",
    });
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async changeStatus(id, status) {
    return (
      await HTTP.patch(`${RESOURCE_NAME}/${id}/changeStatus`, null, {
        params: {
          status: status,
        },
      })
    ).data;
  },
  async getAllPending(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/pending`, options);
    return response.data;
  },
};
