import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "entities/teacherLevelStatuss";

export default {
  async getReport() {
    const response = await HTTP.get(`${RESOURCE_NAME}/report`, {
      responseType: "blob",
    });
    return response.data;
  },
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllWithout(entityName) {
    const response = await HTTP.get(`${RESOURCE_NAME}/without/${entityName}`);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
