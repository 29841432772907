<template>
  <div class="clrm-settings__dropdown">
    <select
      v-model="auxSelected"
      class="form-select"
      @change="$emit('switch-device', auxSelected)"
    >
      <option v-for="(dev, index) in devices" :key="dev.deviceId" :value="dev">
        {{ getLabel(dev.label, index) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "DeviceDropDown",
  props: {
    devices: {
      type: Array,
      required: true,
    },
    selected: {
      required: true,
    },
  },
  data() {
    return {
      auxSelected: null,
    };
  },
  mounted() {
    this.auxSelected = this.devices.find(
      (d) => d.deviceId == this.selected.deviceId
    );
  },
  methods: {
    getLabel(label, index) {
      if (!label) label = `Device ${index + 1}`;
      return label;
    },
  },
};
</script>

<style>
select {
  border: 1px solid;
}
</style>
