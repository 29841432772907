<template>
  <div class="paddings">
    <canvas ref="shareViewCanvas"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    zoom: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.zoom.startShareView(this.$refs.shareViewCanvas);
  },
  beforeDestroy() {
    this.zoom.stopShareView();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  max-height: 100%;
}
</style>
