<template>
  <div class="clrm-header__toolbar-secondary">
    <ClrmButton
      @click="$emit('click', 'support')"
      class="clrm-button--support clrm-button--border-radius"
      icon="help"
      iconWidth="18"
      iconHeight="18"
      align="left"
      >{{ $t("classroom.toolbar.support") }}</ClrmButton
    >
    <ClrmButton
      v-if="classroomMode != 'teacher'"
      class="clrm-button--exit clrm-button--border-radius"
      @click="$emit('click', 'exit')"
      icon="exit"
      iconWidth="18"
      iconHeight="18"
      align="left"
      >{{ $t("classroom.toolbar.exit") }}</ClrmButton
    >
    <div class="clrm-button__dropdown">
      <ClrmButton
        v-if="classroomMode == 'teacher'"
        class="clrm-button--exit clrm-button--border-radius"
        :class="{ active: isExitOpen, 'dropdown-active': isExitOpen }"
        @click="isExitOpen = !isExitOpen"
        icon="exit"
        iconWidth="18"
        iconHeight="18"
        align="left"
        >{{ $t("classroom.toolbar.exit") }}
        <icon
          class="icon-button"
          name="chevron-bottom"
          width="11"
          height="11"
          :title="$t('chat.close')"
        ></icon>
      </ClrmButton>
      <div class="clrm-button__dropdown__content" v-show="isExitOpen">
        <ClrmButton
          class="clrm-button--exit"
          icon="exit"
          iconWidth="18"
          iconHeight="18"
          align="right"
          @click="$emit('click', 'exit')"
          >{{ $t("classroom.toolbar.exit") }}</ClrmButton
        >
        <ClrmButton
          class="clrm-button--exit"
          icon="exit"
          iconWidth="18"
          iconHeight="18"
          align="right"
          @click="$emit('click', 'end-lecture')"
          >{{ $t("classroom.toolbar.end-lecture") }}</ClrmButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import ClrmButton from "@/components/clrm/clrm-button/ClrmButton.vue";

export default {
  name: "ClrmToolbarSecondary",
  components: { ClrmButton },
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
  },
  data() {
    return {
      isExitOpen: false,
    };
  },
};
</script>
<style>
.icon-button {
  margin-left: 3px;
  margin-top: -2px;
}
</style>
