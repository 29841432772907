<template>
  <v-container>
    <v-card @click="goToDetail()">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ entity.name }} {{ entity.surname }}
            </span>
          </v-col>
          <v-col class="d-md-block" align="right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="dialogOpener"
                  small
                >
                  <v-icon color="red">person_remove</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tutor.messages.unlink") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("profile.fields.main_phone") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ entity.mainPhone }}
          </v-col>

          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("profile.fields.main_email") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ entity.mainEmail }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      :title="$t('tutor.messages.unlink')"
      @cancel="deleteDialog = false"
      @submit="deleteFromTutor()"
    >
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TutorEntityRepository = RepositoryFactory.get("TutorEntityRepository");
export default {
  name: "StudentInfo",
  components: { DeleteDialog },
  props: {
    entity: {
      type: Object,
      required: false,
      default: () => {},
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      deleteLoading: false,
      deleteDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
  },
  methods: {
    goToDetail() {
      if (this.isAdmin) {
        this.$router.push({
          name: "StudentView Detail",
          params: { id: this.entity.id, backPrevious: true },
        });
      } else {
        this.$router.push({
          name: "Tutor StudentView Detail",
          params: { id: this.entity.id, backPrevious: true },
        });
      }
    },
    dialogOpener() {
      this.deleteDialog = true;
    },
    deleteFromTutor() {
      this.deleteLoading = true;
      TutorEntityRepository.deleteFromTutor(this.userId, this.entity.id)
        .then(() => {
          this.$notify({
            title: this.$t("account.notifications.successfull"),
            text: this.$t("account.notifications.successfull_unlinked"),
            type: "success",
          });
          this.$emit("studentChanged");
        })
        .catch(() => this.$log.debug("Error unlinking student from tutor"))
        .finally(() => {
          this.deleteLoading = false;
          this.deleteDialog = false;
        });
    },
  },
};
</script>
