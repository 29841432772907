<template>
  <v-container fluid>
    <a ref="hiddenDownloader" class="d-none" />
    <v-card v-if="registration">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-row align="end">
          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.title") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.title }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.description") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.description }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.courseType") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{
              $t(
                `product.product_type.${registration.edition.product.productType}`
              )
            }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("registration.prop.status") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{
              $t(`registration.status.${registration.status}`, {
                date: $d(dateArrayToDate(registration.withdrawReqDate)),
              })
            }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.course") }}:
          </v-col>
          <v-col cols="8" md="4">
            <router-link
              :to="{
                name: 'Course Simple Detail',
                params: {
                  id: registration.edition.product.course.id,
                  backPrevious: true,
                },
              }"
              v-if="registration.edition.product.course"
            >
              <span>
                {{
                  registration.edition.product.course.title +
                  ", " +
                  registration.edition.product.course.description
                }}
              </span>
            </router-link>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.level") }}:
          </v-col>
          <v-col cols="8" md="4">
            <v-chip
              v-if="registration.edition.product.course.level"
              color="primary"
            >
              {{ registration.edition.product.course.level.name }}
            </v-chip>
            <v-chip
              v-if="
                registration.edition.product.course.level &&
                registration.edition.product.course.level.language
              "
              color="warning"
            >
              {{ registration.edition.product.course.level.language.name }}
            </v-chip>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2" light></v-divider>
        <v-row>
          <v-col cols="12" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t("edition.name") }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.start_date") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span v-if="registration.edition.startDate">
              {{ registration.edition.startDate | dateTimeWithoutTz }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.end_date") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span v-if="registration.edition.endDate">
              {{ registration.edition.endDate | dateTimeWithoutTz }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.min_students") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.minStudents }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.max_students") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.maxStudents }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.class_duration") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.classDuration }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.classes_per_week") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ registration.edition.product.classesPerWeek }}
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2" light></v-divider>

        <v-row v-if="registration.edition.finalEvaluation && isAdult">
          <v-col cols="8" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t("studentFinalEvaluation.name") }}
            </span>
          </v-col>

          <v-col cols="4" class="text-right">
            <span>
              <v-btn
                class="ml-2"
                outlined
                color="primary"
                :loading="isLoadingFile"
                @click="downloadCertificate"
              >
                <v-icon class="mr-2">file_download</v-icon>
                {{ $t("registration.certificate.download") }}
              </v-btn>
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("studentFinalEvaluation.prop.passed.title") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span>
              {{ passedValue(registration.edition.finalEvaluation.passed) }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("studentFinalEvaluation.prop.score") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span>
              {{ registration.edition.finalEvaluation.score }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("studentFinalEvaluation.prop.comments") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span>
              {{ registration.edition.finalEvaluation.comments }}
            </span>
          </v-col>
        </v-row>

        <v-divider
          class="mt-2 mb-2"
          light
          v-if="registration.edition.finalEvaluation && isAdult"
        ></v-divider>

        <v-row>
          <v-col cols="12" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t("teacher.name") }}
            </span>
          </v-col>
          <v-row>
            <v-col cols="6" class="centered-element">
              <v-img
                max-height="250"
                style="margin: auto"
                max-width="250"
                :src="teacherPhoto"
              ></v-img>
            </v-col>
            <v-col class="d-md-block" cols="6">
              <h1 v-if="registration.edition.teacher.displayName">
                {{ registration.edition.teacher.displayName }}
              </h1>
              <h1 v-else>
                {{
                  registration.edition.teacher.name +
                  " " +
                  registration.edition.teacher.surname
                }}
              </h1>

              <v-row>
                <v-col class="d-md-block" cols="12">
                  <p>
                    {{ registration.edition.teacher.aboutMe }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>

        <v-divider class="mt-2 mb-2" light></v-divider>

        <v-row>
          <v-col cols="12" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t("student.headers.classmates") }}
            </span>
          </v-col>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense v-if="studentsFromEdition.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("profile.fields.name") }}
                      </th>
                      <th class="text-left">
                        {{ $t("profile.fields.surname") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="student in studentsFromEdition"
                      :key="student.id"
                    >
                      <td>{{ student.name }}</td>
                      <td>{{ student.surname }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p class="text-center" v-else>
                {{ $t("student.headers.no-classmates") }}
              </p>
            </v-col>
          </v-row>
        </v-row>
        <v-divider class="mt-2 mb-2" light></v-divider>

        <v-row>
          <v-col cols="12" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t("lecture.headers.list") }}
            </span>
          </v-col>
          <v-row
            ><v-col cols="12" class="d-none d-md-block">
              <LectureList
                :studentId="this.$attrs.studentId"
                :registrationId="this.registration.id"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateArrayToDate } from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";
import auth from "@/common/auth";
import LectureList from "./LectureList";

const RegistrationEntityRepository = RepositoryFactory.get(
  "RegistrationEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "RegistrationDetail",
  components: {
    LectureList,
    LoadingPage,
  },
  data() {
    return {
      registration: null,
      studentsFromEdition: [],
      teacherPhoto: require("@/assets/icon_visits.png"),
      loading: false,
      loadingFile: false,
    };
  },
  created() {
    if (this.$route.params.registrationId) {
      this._fetchData(this.$route.params.registrationId).then((res) => {
        this._fetchStudents(res.edition.id);
      });
    }
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    isLoadingFile() {
      return this.loadingFile;
    },
    isAdult() {
      return auth.getUser() ? auth.getUser().isAdult : false;
    },
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return RegistrationEntityRepository.get(id)
        .then((res) => {
          this.$set(this, "registration", res);
          UserDataEntityRepository.getPhoto(res.edition.teacher.userId).then(
            (data) => {
              if (data.size > 0) {
                this.teacherPhoto = URL.createObjectURL(data);
              }
            }
          );
          return res;
        })
        .finally(() => (this.loading = false));
    },
    _fetchStudents(id) {
      StudentEntityRepository.getAllByEdition(id).then((res) =>
        this.$set(this, "studentsFromEdition", res)
      );
    },
    passedValue(value) {
      return value
        ? this.$t("studentFinalEvaluation.prop.passed.passed")
        : this.$t("studentFinalEvaluation.prop.passed.not-passed");
    },
    back() {
      this.$router.go(-1);
    },
    downloadCertificate() {
      this.loadingFile = true;
      return RegistrationEntityRepository.downloadCertificate(
        this.registration.id
      )
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("registration.certificate.download_success"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("registration.certificate.not_found"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("registration.certificate.download_error"),
              type: "error",
              duration: 30000,
            });
          }
        })
        .finally(() => (this.loadingFile = false));
    },
    dateArrayToDate,
  },
};
</script>

<style></style>
