<template>
  <edition-detail
    :lectures="lectures"
    :students="students"
    :evaluations="evaluations"
    :hideHeader="hide"
    :edition-title="edition.title"
    :start-date="edition.startDate"
    :end-date="edition.endDate"
    @back="back"
    @change-lecture-pagination="getItems"
    @change-evaluation-pagination="getEvaluationItems"
  ></edition-detail>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import checkInvalidID from "@/common/checkInvalidID";
import EditionDetail from "@/mockups/components/EditionDetail";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

const LectureEvaluationEntityRepository = RepositoryFactory.get(
  "LectureEvaluationEntityRepository"
);

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);

export default {
  components: { EditionDetail },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      edition: {},
      students: {
        loading: true,
        items: [],
      },
      lectures: {
        loading: true,
        items: [],
        totalItems: 0,
      },
      evaluations: {
        loading: true,
        items: [],
        totalItems: 0,
      },
      hide: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
  },
  created() {
    this.hide = !this.isAdmin;
    StudentEntityRepository.getAllByEdition(this.$route.params.editionId)
      .then((response) => (this.students.items = response))
      .catch(() =>
        this.$log.debug(
          "Error fetching all students from edition with id " +
            this.$route.params.editionId
        )
      )
      .finally(() => (this.students.loading = false));
    this.getEdition();
  },
  methods: {
    getEdition() {
      EditionEntityRepository.get(this.$route.params.editionId)
        .then((data) => (this.edition = data))
        .catch((err) => {
          this.$log.debug(
            "Edition with id " + this.$route.params.editionId + " not found"
          );
          checkInvalidID(err);
        });
    },
    async getItems(pagination) {
      const options = {
        params: {
          page: pagination.page - 1,
          size: pagination.itemsPerPage,
          filters: this.isAdmin
            ? "edition.id:" +
              this.$route.params.editionId +
              ",teacher.id:" +
              this.teacherId
            : "edition.id:" + this.$route.params.editionId,
        },
      };
      LectureEntityRepository.getAll(options)
        .then((response) => {
          this.lectures.items = response.content;
          this.lectures.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching all lectures"))
        .finally(() => (this.lectures.loading = false));
    },
    getEvaluationItems(pagination) {
      const options = {
        params: {
          page: pagination.page - 1,
          size: pagination.itemsPerPage,
        },
      };
      LectureEvaluationEntityRepository.getAllByTeacherId(
        this.teacherId,
        options
      )
        .then((response) => {
          this.evaluations.items = response.content;
          this.evaluations.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching lecture evaluations of teacher with id " +
              this.teacherId
          )
        )
        .finally(() => (this.evaluations.loading = false));
    },
    back() {
      const route = this.isAdmin ? "EditionsTab" : "ProfileEditionsTab";
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
  },
};
</script>
