// Admin profile access
const navBarItemsAdmin = [
  {
    title: "teacher.navbar.profile",
    icon: "mdi-account",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "teacher.navbar.info",
        icon: "mdi-account",
        link: "TeacherView Detail",
        notification: false,
        showed: true,
      },
      {
        title: "teacher.navbar.education",
        icon: "mdi-certificate",
        link: "TeacherEducationTab",
        notification: false,
        showed: true,
      },
      {
        title: "teacher.navbar.references",
        icon: "mdi-account-multiple",
        link: "TeacherReferencesTab",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "teacher.navbar.schedule",
    icon: "mdi-calendar",
    link: "TeacherSchedulesTab",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.feedback",
    icon: "mdi-star",
    link: "TeacherFeedbackTab",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.supervisors-feedback",
    icon: "mdi-star",
    link: "TeacherSupervisorReviewTab",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.courses",
    link: "EditionsTab",
    icon: "mdi-school",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.supervising",
    link: "TeacherSupervisingTab",
    icon: "supervisor_account",
    notification: false,
    showed: false,
  },
  {
    title: "teacher.navbar.settledLectures",
    link: "TeacherSettledLecturesTab",
    icon: "paid",
    notification: false,
    showed: true,
  },
];

const navBarItemsProfile = [
  {
    title: "teacher.navbar.profile",
    icon: "mdi-account",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "teacher.navbar.info",
        icon: "mdi-account",
        link: "ProfileTeacherView Detail",
        notification: false,
        showed: true,
      },
      {
        title: "teacher.navbar.education",
        icon: "mdi-certificate",
        link: "ProfileTeacherEducationTab",
        notification: false,
        showed: true,
      },
      {
        title: "teacher.navbar.references",
        icon: "mdi-account-multiple",
        link: "ProfileTeacherReferencesTab",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "teacher.navbar.schedule",
    icon: "mdi-calendar",
    link: "ProfileTeacherSchedulesTab",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.feedback",
    icon: "mdi-star",
    link: "ProfileTeacherFeedbackTab",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.courses",
    link: "ProfileEditionsTab",
    icon: "mdi-school",
    notification: false,
    showed: true,
  },
  {
    title: "teacher.navbar.supervising",
    link: "ProfileTeacherSupervisingTab",
    icon: "supervisor_account",
    notification: false,
    showed: false,
  },
  {
    title: "teacher.navbar.settledLectures",
    link: "ProfileTeacherSettledLecturesTab",
    icon: "paid",
    notification: false,
    showed: true,
  },
];

export { navBarItemsAdmin, navBarItemsProfile };
