<template>
  <div>
    <input type="checkbox" id="checkbox" v-model="checked" @change="check()" />
    <label for="checkbox">Show past lectures</label>
    <v-data-table
      class="data-table"
      disable-pagination
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="lectureList"
      :item-class="itemRowBackground"
    >
      <template v-slot:[`item.startTime`]="{ item }">
        {{ item.startTime | dateTimeWithTz("medium") }}
      </template>
      <template v-slot:[`item.endTime`]="{ item }">
        {{ item.endTime | dateTimeWithTz("medium") }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn v-if="item.accessible" @click.stop="join(item)">{{
          $t("virtual-classroom.join")
        }}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import LectureRepository from "@/repositories/components/VirtualClassroomLectureRepository";
import { dateArrayToDate } from "@/common/conversion-utils";

export default {
  name: "VirtualClassroomLectureList",
  data() {
    return {
      items: [],
      checked: false,
      headers: [
        {
          text: this.$t("lecture.prop.lesson"),
          value: "lessonName",
        },
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.teacher"),
          value: "teacherName",
        },
        {
          text: this.$t("virtual-classroom.convokedStudents"),
          value: "convokedStudents",
          align: "center",
        },
        {
          text: "",
          value: "action",
        },
      ],
    };
  },
  created() {
    this.getLectures(false);
  },
  computed: {
    lectureList() {
      return this.items;
    },
  },
  methods: {
    async getLectures(past) {
      this.items = await LectureRepository.getAllToAttend(past);
    },
    join(item) {
      this.$router.push(`virtual-classroom/${item.lectureId}`);
    },
    check() {
      this.getLectures(this.checked);
    },
    itemRowBackground(item) {
      const dateTimeNow = new Date();
      item.endTime[5] = 0;
      const itemEndTime = dateArrayToDate(item.endTime);
      if (dateTimeNow > itemEndTime && !item.accessible)
        return "not-accessible";
    },
  },
};
</script>
<style>
.not-accessible {
  background-color: lightgrey;
}

.data-table >>> .v-data-table__wrapper table tbody tr:hover {
  cursor: pointer !important;
}
</style>
