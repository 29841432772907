<template>
  <v-container>
    <v-card v-if="!loading">
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-row align="center" no-gutters>
              <v-col cols="12" class="text-center">
                <span class="headline no-split-words">
                  {{ $t("companyStudent.company_student_info") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field
                v-model="student.name"
                dense
                disabled
                readonly
                prepend-inner-icon="notes"
                :label="$t('profile.fields.name')"
              >
              </v-text-field>
            </v-row>

            <v-row>
              <v-text-field
                v-model="student.email"
                dense
                disabled
                readonly
                prepend-inner-icon="notes"
                :label="$t('profile.fields.main_email')"
              >
              </v-text-field>
            </v-row>

            <v-row>
              <v-text-field
                v-model="student.companyName"
                dense
                disabled
                readonly
                prepend-inner-icon="notes"
                :label="$t('company.name')"
              >
              </v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn
            class="primary mt-4"
            :disabled="loadingRegistry"
            :loading="loadingRegistry"
            @click="confirm"
          >
            {{ $t("account.actions.link_student") }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const CompanyStudentEntityRepository = RepositoryFactory.get(
  "CompanyStudentEntityRepository"
);

export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      loading: false,
      loadingRegistry: false,
      student: {},
      user: auth.getUser(),
    };
  },
  computed: {
    ...mapAuthGetter(["isAnyStudent"]),
  },
  created() {
    if (this.isAnyStudent) {
      this._fetchData(this.$route.query.key);
    } else {
      this.$notify({
        title: "Error",
        text: this.$t("account.errors.forbidden_access"),
        type: "error",
        duration: 30000,
      });
      this.$router.push({ name: "Unauthorized" });
    }
  },
  methods: {
    _fetchData(key) {
      this.loading = true;
      return CompanyStudentEntityRepository.getByKey(key)
        .then((response) => {
          this.student = response;
        })
        .catch(() => {
          this.$notify({
            title: this.$t("companyStudent.error.key_not_found.title"),
            text: this.$t("companyStudent.error.key_not_found.body"),
            type: "error",
            duration: 30000,
          });
          this.$log.debug("Error fetching company student with key " + key);
        })
        .finally(() => (this.loading = false));
    },
    confirm() {
      this.loadingRegistry = true;
      CompanyStudentEntityRepository.linkToStudent(
        this.user.id,
        this.$route.query.key
      )
        .then(() => {
          this.$notify({
            title: this.$t("account.notifications.successfull"),
            text: this.$t("account.notifications.successfull_linked"),
            type: "success",
          });
          // Updates userData to get the new role
          return auth.reloadUserData().then(() => this.$router.goToUserHome());
        })
        .catch(() => this.$log.debug("Error linking student to company"))
        .finally(() => (this.loadingRegistry = false));
    },
  },
};
</script>
