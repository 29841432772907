<template>
  <v-container class="py-0">
    <v-row v-if="loading" class="mb-4" justify="center">
      <span>
        {{ $t("wallet.messages.charge_dialog.wait-for-paypal") }}
      </span>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="success"
        :disabled="!amount || amount < 5"
        :loading="loading"
        @click="openPaypalWindow"
      >
        {{ $t("wallet.actions.pay-with-paypal") }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import auth from "@/common/auth";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
export default {
  props: {
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: "CHARGE",
    },
    walletId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      channel: null,
      reference: null,
    };
  },
  beforeDestroy() {
    // When the component is destroyed, we close the channel with the PayPal tab and remove the window event listener
    this.beforeRouteLeaveHandler();
    window.removeEventListener("beforeunload", this.beforeRouteLeaveHandler);
  },
  created() {
    // Event listener to close the PayPal window when the user refresh this tab
    window.addEventListener("beforeunload", this.beforeRouteLeaveHandler);

    // We create a channel to establish communication with the PayPal tab
    this.channel = new BroadcastChannel("paypal-channel");
    this.channel.addEventListener("message", (e) => {
      switch (e.data.event) {
        case "onApprove":
          this.doApproveOrder(e.data.data.orderID);
          break;
        case "onCancel":
          this.onCancel(e.data.data);
          break;
        case "onError":
          this.onError(e.data.data);
          break;
      }
    });
  },
  methods: {
    openPaypalWindow() {
      this.loading = true;
      this.$emit("processingPayment");
      this.doCreateOrder().then((reference) => {
        window.open(
          "/PayPalButton.html?reference=" +
            reference +
            "&locale=" +
            auth.getUser().langKey
        );
      });
    },
    finishedPaymentProcess() {
      this.loading = false;
      this.$emit("processingPayment", false);
    },
    doCreateOrder() {
      const charge = {
        amount: this.amount,
        type: "PAYPAL",
      };
      return WalletEntityRepository.chargeWallet(this.walletId, charge)
        .then((data) => {
          this.reference = data.reference;
          return data.reference;
        })
        .catch(() => this.$log.debug("Error creating PayPal order"));
    },
    doApproveOrder(reference) {
      // We check that the event received owns to the current user
      if (this.reference === reference) {
        this.loading = true;
        return WalletEntityRepository.captureOrder(reference, "PAYPAL")
          .then((response) => {
            this.$emit("completed", response);
            return response;
          })
          .catch(() => this.$log.debug("Error capturing order " + reference))
          .finally(() => this.finishedPaymentProcess());
      } else {
        this.onError();
      }
    },
    onCancel(reference) {
      // We check that the event received owns to the current user
      if (this.reference === reference) {
        this.$notify({
          title: this.$t("wallet.messages.charge_dialog.paypal_error.title"),
          text: this.$t("wallet.messages.charge_dialog.paypal_error.abort"),
          type: "warning",
        });
        this.finishedPaymentProcess();
      }
    },
    onError(reference) {
      // We check that the event received owns to the current user
      if (this.reference === reference) {
        this.$notify({
          title: this.$t("wallet.messages.charge_dialog.paypal_error.title"),
          text: this.$t("wallet.messages.charge_dialog.paypal_error.error"),
          type: "error",
          duration: 30000,
        });
        this.finishedPaymentProcess();
      }
    },
    beforeRouteLeaveHandler() {
      this.channel.postMessage("Main window closed");
      this.channel.close();
    },
  },
};
</script>
