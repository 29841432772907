var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isAdmin)?_c('v-col',{attrs:{"align":"right"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("tutor.student.add_student")))]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm._fetchStudents.apply(null, arguments)}}},[_vm._v("mdi-plus")])],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"my-2"}),_vm._l((_vm.students),function(student){return _c('student-info',{key:student.id,attrs:{"userId":_vm.userId,"entity":student},on:{"studentChanged":_vm._fetchData}})}),(_vm.addStudentDialog)?_c('v-dialog',{attrs:{"width":"75%"},on:{"click:outside":_vm.closeAddStudentsDialog},model:{value:(_vm.addStudentDialog),callback:function ($$v) {_vm.addStudentDialog=$$v},expression:"addStudentDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","md":"11","lg":"11"}},[_c('h1',{staticClass:"title ml-4"},[_vm._v(_vm._s(_vm.$t("tutor.student.add_student")))])]),_c('v-col',{attrs:{"cols":"2","md":"1","lg":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeAddStudentsDialog}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-data-table',{attrs:{"search":_vm.searchUnlinkedStudents,"headers":_vm.headersStudents,"items":_vm.unlinkedStudentsList,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([(_vm.isAdmin)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.loadingRow[item.id])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.handleValidation(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.add")))])]):_c('loading-spinner')]}}:null,{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('search')},model:{value:(_vm.searchUnlinkedStudents),callback:function ($$v) {_vm.searchUnlinkedStudents=$$v},expression:"searchUnlinkedStudents"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.relationships,"loading":_vm.loadingRelationships,"dense":"","outlined":"","label":_vm.$t('relationship.name'),"item-text":function (item) { return item.name
                          ? _vm.$t(("relationship.values." + (item.name)))
                          : item.name; },"item-id":"id","rules":[
                      function (v) { return (v.id != 0 && v.name != null) ||
                        _vm.$t('relationship.error.required'); } ],"return-object":"","required":""},on:{"change":_vm.handleSelectRelationship},model:{value:(_vm.studentRelationship),callback:function ($$v) {_vm.studentRelationship=$$v},expression:"studentRelationship"}})],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1):_vm._e()],2):_c('loading-page')}
var staticRenderFns = []

export { render, staticRenderFns }