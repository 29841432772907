<template>
  <div class="clrm-video clrm-video--border-radius">
    <div class="clrm-video__wrapper" :class="{ loading: loadingVideo }">
      <video
        class="clrm-video__source"
        ref="videoCanvas"
        width="640"
        height="360"
      />
    </div>
  </div>
</template>

<script>
import ZoomVideo from "@zoom/videosdk";

export default {
  name: "PreviewOwnCameraReproduction",
  props: {
    device: {
      required: true,
    },
  },
  data() {
    return {
      localVideoTrack: null,
      loadingVideo: true,
    };
  },
  watch: {
    device(newValue) {
      if (this.localVideoTrack) {
        this.stop();
      }
      if (newValue) {
        setTimeout(this.start, 1500);
      }
    },
  },
  created() {
    // Esperamos a que exista <video>
    setTimeout(() => {
      this.start();
      this.removeLoading();
    }, 1500);
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    start() {
      this.localVideoTrack = ZoomVideo.createLocalVideoTrack(
        this.device.deviceId
      );
      if (this.$refs.videoCanvas) {
        this.localVideoTrack.start(this.$refs.videoCanvas);
      }
    },
    stop() {
      if (this.localVideoTrack != null) {
        this.localVideoTrack.stop();
        this.localVideoTrack = null;
      }
    },
    removeLoading() {
      this.loadingVideo = false;
    },
  },
};
</script>

<style></style>
