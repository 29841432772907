import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/studentEvaluations";

export default {
  async getAllByStudentId(id, options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/student/${id}`, options);
    return response.data;
  },

  async getByStudentIdAndEdition(studentId, editionId) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/student/${studentId}/edition/${editionId}`
    );
    return response.data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },
};
