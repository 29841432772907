<template>
  <div class="height-100">
    <notifications :max="3" :width="300" position="top center" />
    <component :is="layout">
      <slot />
    </component>
  </div>
</template>

<script>
import AppLayoutDefault from "./AppLayoutDefault.vue";
import AppLayoutClassroom from "./AppLayoutClassroom.vue";
import AppLayoutLogin from "./AppLayoutLogin.vue";

export default {
  name: "AppLayout",
  computed: {
    layout() {
      switch (this.$route.meta.layout) {
        case "AppLayoutClassroom":
          return AppLayoutClassroom;
        case "AppLayoutLogin":
          return AppLayoutLogin;
      }
      return AppLayoutDefault;
    },
  },
};
</script>
