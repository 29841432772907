<template>
  <v-card flat>
    <v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="6" class="d-none d-md-block">
          <span v-if="custom_title" class="headline no-split-words">
            {{ custom_title }}
          </span>
          <span v-else class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn @click="$emit('back')" v-if="backBtn">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            class="success ml-3"
            :disabled="!validForm || image.loading"
            @click="$emit('save')"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form v-model="validForm">
        <v-row>
          <v-col cols="12" sm="4" md="3" lg="3" xl="3">
            <image-edit v-model="entity"></image-edit>
            <v-row>
              <v-col cols="12">
                <number-field
                  v-if="isAdmin && billingId"
                  dense
                  outlined
                  v-model="entity.billingId"
                  :label="$t('profile.fields.billing_id')"
                  :rules="[
                    (v) =>
                      !v ||
                      v < 10000 ||
                      this.$t('profile.fields.validation.billing-id-size'),
                  ]"
                ></number-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9" xl="9">
            <v-row dense outlined>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.name"
                  class="required"
                  prepend-inner-icon="person"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.firstName_required'),
                  ]"
                  :label="$t('profile.fields.name')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  prepend-inner-icon="person"
                  v-model="entity.surname"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.lastName_required'),
                  ]"
                  :label="$t('profile.fields.surname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.displayName"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.username_required'),
                  ]"
                  :label="$t('profile.fields.display_name')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <day-picker
                  v-model="entity.birthdate"
                  :future-dates="false"
                  :label="$t('profile.fields.birthdate')"
                  :rules="[(v) => !!v || $t('profile.errors.required')]"
                  dense
                  outlined
                  pastDates
                  required
                ></day-picker>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="genres"
                  dense
                  outlined
                  :label="$t('profile.fields.genre')"
                  v-model="entity.genre"
                  :item-text="translate"
                ></v-select>
              </v-col>

              <v-col
                v-if="account_details || registerFields"
                cols="12"
                sm="6"
                lg="4"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="entity.login"
                  prepend-inner-icon="person"
                  type="text"
                  maxlength="50"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.username_required'),
                    (v) =>
                      !v ||
                      this.regex.LOGIN_REGEX.test(v) ||
                      $t('user_management.loginValid'),
                  ]"
                  :label="$t('profile.fields.login')"
                  @input="entity.login = entity.login.toLowerCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                :lg="account_details || registerFields ? 4 : 6"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="entity.mainEmail"
                  class="required"
                  prepend-inner-icon="email"
                  :rules="emailRules"
                  :label="$t('profile.fields.main_email')"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                :lg="account_details || registerFields ? 4 : 6"
              >
                <v-text-field
                  dense
                  outlined
                  append-icon="email"
                  v-model="entity.secondaryEmail"
                  :rules="[
                    (v) =>
                      !v ||
                      this.regex.EMAIL_REGEX.test(v) ||
                      $t('account.form.validation.email_valid'),
                  ]"
                  :label="$t('profile.fields.secondary_email')"
                ></v-text-field>
              </v-col>

              <v-col v-if="registerFields" cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.password"
                  prepend-inner-icon="vpn_key"
                  maxLength="100"
                  :append-icon="hidePassword1 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.form.password')"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    (value) =>
                      value.length <= 100 ||
                      $t('account.form.validation.password_less_chars'),
                    (value) =>
                      value.length > 3 ||
                      this.$t('account.form.validation.password_more_chars'),
                  ]"
                  :type="hidePassword1 ? 'password' : 'text'"
                  @click:append="() => (hidePassword1 = !hidePassword1)"
                ></v-text-field>
              </v-col>

              <v-col v-if="registerFields" cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.password2"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword2 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.actions.repeat_password')"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    passwordConfirmationRule,
                  ]"
                  :type="hidePassword2 ? 'password' : 'text'"
                  @click:append="() => (hidePassword2 = !hidePassword2)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="phone"
                  v-model="entity.mainPhone"
                  :label="$t('profile.fields.main_phone')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="phone"
                  v-model="entity.secondaryPhone"
                  :label="$t('profile.fields.secondary_phone')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-home-city"
                  v-model="entity.address"
                  :label="$t('profile.fields.address')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-city"
                  v-model="entity.town"
                  :label="$t('profile.fields.town')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.postalcode"
                  :label="$t('profile.fields.postalcode')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.country"
                  :label="$t('profile.fields.country')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-badge-account-horizontal"
                  v-model="entity.nif"
                  :label="$t('profile.fields.nif')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <day-picker
                  v-model="entity.nifExpiration"
                  :label="$t('profile.fields.nif_expiration')"
                  :pastDates="false"
                  dense
                  future-dates
                  outlined
                ></day-picker>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <time-zone-selector
                  dense
                  outlined
                  v-model="entity.timeZone"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.timezone_required'),
                  ]"
                  :label="$t('profile.fields.timeZone')"
                  autocomplete="no"
                >
                </time-zone-selector>
              </v-col>

              <v-col cols="12" md="6">
                <language-selector
                  dense
                  outlined
                  v-model="entity.langKey"
                  :label="$t('profile.fields.language')"
                ></language-selector>
              </v-col>

              <v-col cols="12" sm="4" lg="4">
                <v-switch
                  dense
                  outlined
                  v-model="entity.acceptEmails"
                  class="ma-2 tp-0"
                  :label="$t('profile.fields.accept_emails')"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" lg="4">
                <v-switch
                  dense
                  outlined
                  v-model="entity.acceptWhats"
                  class="ma-2 tp-0"
                  :label="$t('profile.fields.accept_whats')"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="4" lg="4">
                <v-switch
                  dense
                  outlined
                  v-model="entity.acceptCalls"
                  class="ma-2 tp-0"
                  :label="$t('profile.fields.accept_calls')"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              outlined
              v-model="entity.aboutMe"
              :label="$t('profile.fields.about_me')"
            ></v-textarea>
          </v-col>
          <span v-if="includeLegal">
            <v-col cols="12">
              <v-switch
                dense
                outlined
                v-model="entity.acceptCommercialCommunications"
                :rules="[]"
                :label="$t('profile.fields.accept_commercial_communication')"
              ></v-switch>
            </v-col>
            <span v-if="!entity.id && !entity.userId">
              <v-col cols="12">
                <v-switch
                  class="required"
                  dense
                  outlined
                  v-model="entity.acceptRGPD"
                  :rules="[
                    (v) => v || $t('account.form.validation.rgpd_required'),
                  ]"
                  :label="$t('profile.fields.accept_rgpd')"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <span
                  class="text-caption"
                  v-html="$t('profile.legal_text.rgpd')"
                >
                </span>
              </v-col>
            </span>
          </span>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DayPicker from "@/components/calendar/DayPicker";
import regex from "@/common/regex-validation";
import TimeZoneSelector from "@/components/debouncing-inputs/TimeZoneSelector.vue";
import LanguageSelector from "@/components/debouncing-inputs/LanguageSelector.vue";
import ImageEdit from "@/components/profile/ImageEdit.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { translate } from "@/common/translation-utils";
import genres from "@/enumerates/Genre";
import NumberField from "@/components/number-field/NumberField";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "GenericProfileForm",
  components: {
    DayPicker,
    TimeZoneSelector,
    LanguageSelector,
    ImageEdit,
    NumberField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    accountFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    account_details: {
      type: Boolean,
      required: false,
      default: false,
    },
    backBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    billingId: {
      type: Boolean,
      required: false,
      default: false,
    },
    custom_title: {
      type: String,
      required: false,
      default: null,
    },
    includeLegal: {
      type: Boolean,
      required: false,
      default: false,
    },
    registerFields: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      validForm: true,
      entity: {},
      regex,
      hidePassword1: true,
      hidePassword2: true,
      genres: genres,
      image: {
        loading: false,
      },
      emailRules: [
        (v) => !!v || this.$t("account.form.validation.email_required"),
        (v) =>
          regex.EMAIL_REGEX.test(v) ||
          this.$t("account.form.validation.email_valid"),
      ],
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.entity = newVal;
          if (newVal.userId && (!oldVal || oldVal.userId != newVal.userId))
            UserDataEntityRepository.getPhoto(newVal.userId)
              .then((data) => {
                if (data.size > 0) {
                  this.image.photo = URL.createObjectURL(data);
                }
              })
              .catch(() =>
                this.$log.debug(
                  "Error fetching profile photo for user: " + newVal.userId
                )
              );
        }
      },
      immediate: true,
    },
    entity(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    passwordConfirmationRule() {
      return () =>
        this.entity.password === this.entity.password2 ||
        this.$t("account.form.validation.password_match");
    },
  },
  methods: {
    translate,
  },
};
</script>

<style></style>
