<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12" class="text-right">
          <v-btn icon @click="$emit('closeDialog')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t("lectureChange.action.change-schedule") }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="9" v-if="selectedSchedule">
              <v-row> {{ $t("lectureChange.dialog.selected_slot") }}: </v-row>
              <v-row v-for="(slot, i) in selectedSlots" :key="i">
                {{ slot.startArray | dateTimeWithTz("long") }}
                -
                {{ slot.endArray | dateTimeWithTz("long") }}
              </v-row>
            </v-col>
            <v-col class="text-right">
              <v-btn
                color="success"
                :disabled="!selectedSchedule"
                @click="save"
              >
                <v-icon small> save </v-icon>
                <span>{{ $t("actions.save") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="start">
            <v-progress-circular
              v-if="loadingAvailabilities"
              indeterminate
              color="primary"
            >
            </v-progress-circular>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-col cols="12" md="3">
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t("scheduleCalendar.today") }}
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <span class="headline"> {{ calendarTitle }}</span>
          </v-col>
          <v-sheet>
            <v-calendar
              v-model="focus"
              color="primary"
              :events="availabilities"
              :event-color="getEventColor"
              :interval-height="25"
              :interval-minutes="30"
              :interval-count="48"
              type="week"
              ref="calendar"
              :weekdays="[1, 2, 3, 4, 5, 6, 7, 0]"
              @click:event="selectSlot"
              @change="updateRange"
              :locale="localeRoot"
            >
              <template v-slot:event="{ event }">
                <div class="event-div">
                  <v-row align="center" justify="center" no-gutters>
                    <v-col cols="12">
                      <span>
                        {{ event.name }}
                      </span>
                    </v-col>
                    <v-col cols="12" v-if="event.checked">
                      <v-icon small>mdi-check</v-icon>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import {
  utcDateArrayToDateWithTimezoneFormatted,
  localDateToVCalendarString,
  formatDateInverse,
} from "@/common/conversion-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import moment from "moment-timezone";
const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);
export default {
  props: {
    lecture: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availabilities: [],
      focus: null,
      loading: false,
      loadingAvailabilities: false,
      selectedSlots: [],
      lectureChange: {},
      timezone: null,
      start: null,
      currentDate: null,
      calendarTitle: null,
    };
  },
  computed: {
    selectedSchedule() {
      return this.selectedSlots.length === 1;
    },
    localeRoot() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.timezone = { name: moment.tz.guess() };
    this.currentDate = moment.tz(this.timezone.name);
    this.focus = this.currentDate.format().substring(0, 10);
  },
  mounted() {
    this.calendarTitle = this.$refs.calendar.title;
  },
  methods: {
    _fetchData() {
      this.loadingAvailabilities = true;
      const options = {
        params: {
          teacher: this.lecture.teacher.id,
          students: this.lecture.students.map((student) => student.id),
          init: this.start,
        },
      };
      LectureEntityRepository.getCommonAvailabilities(this.lecture.id, options)
        .then((res) => (this.availabilities = this._formatAvailabilities(res)))
        .finally((this.loadingAvailabilities = false));
    },
    _formatAvailabilities(data) {
      return data.map((el) => {
        return {
          name: this.$t("levelTestRequest.program_dialog.available"),
          start: utcDateArrayToDateWithTimezoneFormatted(el.start, null),
          end: utcDateArrayToDateWithTimezoneFormatted(el.end, null),
          startArray: el.start,
          endArray: el.end,
          checked: false,
        };
      });
    },
    getEventColor(event) {
      return event.checked ? "green" : "primary";
    },
    setToday() {
      this.currentDate = moment.tz(this.timezone.name);
      this.focus = this.currentDate.format().substring(0, 10);
    },
    prev() {
      this.$refs.calendar.prev();
      this.selectedSlots = [];
    },
    next() {
      this.$refs.calendar.next();
      this.selectedSlots = [];
    },
    updateRange({ start }) {
      this.currentDate = moment.tz(this.focus, this.timezone.name);
      let startDate = new Date(start.date);

      // Calcular el día de mañana
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);

      this.start =
        startDate <= tomorrow ? formatDateInverse(tomorrow) : start.date;
      this.calendarTitle = this.$refs.calendar.title;
      this._fetchData();
    },
    save() {
      this.loading = true;
      this.lectureChange.lectureId = this.lecture.id;
      this.lectureChange.start = this.selectedSlots[0].startArray;
      this.lectureChange.end = this.selectedSlots[0].endArray;
      LectureEntityRepository.changeSchedule(
        this.lecture.id,
        this.lectureChange
      )
        .then((res) => {
          this.$notify({
            title: this.$t("lectureChange.messages.create_success.title"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", res);
        })
        .catch(() =>
          this.$log.debug("Error changing lecture: " + this.lectureChange)
        )
        .finally(() => (this.loading = false));
    },
    /**
     * Handler for slot selection: selects/deselects clicked slot
     */
    selectSlot(event) {
      event.event.checked = !event.event.checked;
      if (this.selectedSchedule) {
        this.selectedSlots[0].checked = false;
        this.selectedSlots.pop();
      }
      if (event.event.checked) this.selectedSlots.push(event.event);
    },
    updateAvailabilities() {
      this.selectedSlots = [];
      this.loadingAvailabilities = true;
      return ProductRequestEntityRepository.getCommonAvailabilities(
        this.teacher.id,
        this.students,
        this.productRequest.id
      )
        .then((data) => {
          this.availabilities = this._formatAvailabilities(data);
          this.focus = localDateToVCalendarString(
            this.availabilities[0].startArray
          );
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching availabilities for teacher with ID " +
              this.teacher.id +
              " and student with ID " +
              this.productRequest.student.id
          )
        )
        .finally(() => (this.loadingAvailabilities = false));
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
</style>
