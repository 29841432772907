import auth from "@/common/auth";
import store from "@/common/store";
import i18n from "@/plugins/i18n";
import ActivationRequired from "@/views/ActivationRequired.vue";
import NotFound from "@/views/NotFound.vue";
import Vue from "vue";
import Router from "vue-router";
import Account_Router from "../src/account/router";
import Login from "./account/login/Login.vue";
import User_ManagementRouter from "./components/user-management/router";
import Virtual_Classroom_Lecture from "./components/virtual-classroom-lecture/router";
import Classroom from "./components/classroom/router";
import adminMockupRouter from "./mockups/admin-profile/router";
import adminRouter from "./mockups/admin/router";
import contentCloudRouter from "./mockups/content-cloud/router";
import contentEditorMockupRouter from "./mockups/content-editor/router";
import economyRouter from "./mockups/economy/router";
import studentMockupRouter from "./mockups/student/router/index";
import teacherMockupRouter from "./mockups/teachers/router";
import tutorMockupRouter from "./mockups/tutor/router";
import Home from "./views/Home.vue";
import stomp from "@/common/StompClient";
import Unauthorized from "@/views/Unauthorized";
import companyRouter from "@/mockups/company/router";

import usersHome from "@/enumerates/UsersHome";

const user = store.state.user;

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      public: false,
      label: "menuBar.home",
    },
  },
  {
    path: "/activation-requried",
    name: "Activate account",
    component: ActivationRequired,
    meta: {
      public: true,
      label: "activation_required.title",
    },
  },
  {
    path: "/about",
    name: "about",
    meta: {
      label: "menuBar.about",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("./views/About.vue"),
  },
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    meta: {
      public: true,
      isLoginPage: true,
      label: "account.actions.log_in",
      layout: "AppLayoutLogin",
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: {
      public: true,
      label: "errors.unauthorized.title",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    props: true,
    meta: {
      public: true,
      label: "errors.page_not_found.title",
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
    .concat(User_ManagementRouter)
    .concat(Account_Router)
    .concat(Classroom)
    .concat(Virtual_Classroom_Lecture)
    .concat(
      adminMockupRouter,
      contentEditorMockupRouter,
      teacherMockupRouter,
      tutorMockupRouter,
      studentMockupRouter,
      contentCloudRouter,
      economyRouter,
      adminRouter,
      companyRouter
    ),
});

const backPreviousStack = [];

const checkRedirectToPrevious = (to, from, next) => {
  // if backPrevious prop, add from route to stack and go next
  const backPrevLocalStorage = localStorage.getItem("backPrevious");
  if (to.params.backPrevious || backPrevLocalStorage) {
    if (to.params.backAction) {
      // eslint-disable-next-line
      console.warn(
        "Set route with backAction and backPrevious flags is not allowed"
      );
    } else if (backPrevLocalStorage) {
      backPreviousStack.push(JSON.parse(backPrevLocalStorage));
      localStorage.removeItem("backPrevious");
    } else {
      backPreviousStack.push(from);
    }
    next();
  } else if (to.params.backAction) {
    // if backAction prop (should only be present when route from back button)
    // get last item from stack if not empty
    let length = backPreviousStack.length;
    if (length < 1) next();
    else {
      let stackTop = {};
      Object.assign(stackTop, backPreviousStack[length - 1]);
      // check if redirecting to stack top route and if so,
      // remove it from the stack
      if (to.fullPath == stackTop.fullPath) {
        backPreviousStack.pop();
        next();
      } else {
        // call router.push() will trigger again this function
        // so is necessary set backAction and backPrevious params
        // in order to reach this block again
        stackTop.params.backAction = true;
        stackTop.params.backPrevious = false;
        router.push(stackTop); // calling router.push() instead of next() to prevent console error
      }
    }
  } else {
    // clean stack if no backPrevious neither backAction props are present and route next
    backPreviousStack.splice(0, backPreviousStack.length);
    next();
  }
};

function goToUserHome(options = {}, replace = false) {
  const fn = replace ? router.replace.bind(this) : router.push.bind(this);
  redirectToUserHome(options, fn);
}

router.goToUserHome = goToUserHome;

function redirectToUserHome(to, next, replace = false) {
  const authority =
    auth.getAuthorities().indexOf("ROLE_HEADMASTER") !== -1
      ? "ROLE_HEADMASTER"
      : auth.getSelectedAuthority();
  const route = usersHome[authority];
  if (route) {
    next({ name: route, replace: replace, params: to.params });
  } else if (replace) {
    next({ name: "Home", replace: true, params: to.params });
  } else {
    next();
  }
}

var lastRoute = null;

function checkAuthentication(to, from, next) {
  auth.isAuthenticationChecked.finally(() => {
    // por defecto las rutas restringen el acceso a usuario autenticados
    const userIsLogged = user.logged;
    const redirecting = from.name === "Login";
    const toHome = to.name === "Home";
    let toRoute = null;

    // redirigir a la ruta guardada
    if (lastRoute && userIsLogged) {
      toRoute = lastRoute;
      lastRoute = null;
    }

    const requiresAuth = !to.meta.public;
    const requiredAuthorities = to.meta.authority;
    const loggedUserAuthorities = user.authorities;

    // en primer lugar, se comprueba si el usuario tiene pendiente rellenar sus datos
    if (userIsLogged) {
      if (loggedUserAuthorities.indexOf("ROLE_STUDENT_GUEST") !== -1) {
        if (to.name === "StudentProfile Create") next();
        else next({ name: "StudentProfile Create" });
      } else if (loggedUserAuthorities.indexOf("ROLE_TEACHER_GUEST") !== -1) {
        if (to.name === "TeacherProfile Create") next();
        else next({ name: "TeacherProfile Create" });
      }
    }

    if (requiresAuth) {
      if (userIsLogged) {
        if (
          requiredAuthorities &&
          !loggedUserAuthorities.some((item) =>
            requiredAuthorities.includes(item)
          )
        ) {
          if (redirecting) {
            redirectToUserHome(to, next);
          } else {
            // usuario logueado pero sin permisos
            next({ name: "Unauthorized" });
          }
        } else {
          // usuario logueado y con permisos adecuados
          //nos conectamos al websocket en caso de que la conexion no este ya abierta
          if (!stomp.isConnected()) {
            stomp.connect().then(() => {
              if (toRoute) next(toRoute);
              else {
                if (toHome) redirectToUserHome(to, next);
                else next();
              }
            });
          } else {
            if (toRoute) next(toRoute);
            else {
              if (toHome) redirectToUserHome(to, next);
              else next();
            }
          }
        }
      } else {
        // usuario no está logueado
        if (to.name !== "Home") {
          Vue.notify({
            title: i18n.t("errors.auth_required.title"),
            text: i18n.t("errors.auth_required.body"),
            type: "error",
            duration: 30000,
          });
        }
        // guardar ruta para redireccionar después
        lastRoute = to;
        next("/account/login");
      }
    } else {
      // página pública
      if (userIsLogged && !stomp.isConnected()) {
        stomp.connect();
      }
      if (userIsLogged && to.meta.isLoginPage) {
        // si estamos logueados no hace falta volver a mostrar el login
        redirectToUserHome(to, next, true);
      } else {
        if (toRoute) next(toRoute);
        else {
          if (toHome) redirectToUserHome(to, next);
          else next();
        }
      }
    }
  });
}

router.beforeEach(checkAuthentication);
router.beforeEach(checkRedirectToPrevious);

export default router;
