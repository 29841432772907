<template>
  <div>
    <v-app-bar app color="#FFFFFF">
      <v-row no-gutters align="center">
        <v-col>
          <v-app-bar-nav-icon
            class="menu-icon"
            @click.stop="app.drawer = !app.drawer"
          ></v-app-bar-nav-icon>
        </v-col>
        <v-col align-self="center" class="text-center">
          <router-link
            to="/"
            class="text-no-wrap toolbar-title"
            custom
            v-slot="{ navigate }"
          >
            <img
              @click="navigate"
              class="logo-dashboard d-md-block ml-auto mr-auto"
              width="92"
              height="46"
              :src="require('@/assets/images/lemsi-campus.svg')"
              alt=""
            />
          </router-link>
          <!-- We show the route name only when the screen is small -->
          <router-link
            :to="currentRoutePath"
            class="d-md-none toolbar-title"
            custom
            v-slot="{ navigate }"
          >
            <span @click="navigate">{{ currentRouteName }}</span>
          </router-link>
        </v-col>
        <v-col>
          <span ref="bar" class="bar">
            <MenuBarItems
              :collapsed="false"
              @open-chat="$emit('open-chat')"
            ></MenuBarItems>
          </span>
        </v-col>
      </v-row>
      <LateralMenuBar
        :drawer="app.drawer"
        @drawer-changed="updateDrawer"
      ></LateralMenuBar>
    </v-app-bar>
  </div>
</template>

<script>
import LateralMenuBar from "./LateralMenuBarCustomized";
import MenuBarItems from "./MenuBarItemsCustomized";

export default {
  name: "MenuBar",
  components: {
    LateralMenuBar,
    MenuBarItems,
  },
  computed: {
    currentRouteName() {
      return this.$t(this.$route.meta.label);
    },
    currentRoutePath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      app: {
        drawer: false,
      },
      rtime: new Date(),
      timeout: false,
      delta: 300,
      overflow: false,
    };
  },
  methods: {
    updateDrawer(newVal) {
      this.app.drawer = newVal;
    },
    resizeEnd() {
      this.overflow = false;
      if (new Date() - this.rtime < this.delta) {
        setTimeout(this.resizeEnd, this.delta);
      } else {
        this.timeout = false;
        if (this.$refs.bar) {
          this.overflow =
            this.$refs.bar.offsetHeight < this.$refs.bar.scrollHeight ||
            this.$refs.bar.offsetHeight === 0;
        }
      }
    },
  },
};
</script>
