// Admin profile access
const navBarItemsTutor = [
  {
    title: "student.navbar.profile",
    icon: "mdi-account",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.info",
        link: "Tutor StudentView Detail",
        icon: "mdi-account",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.documents",
    link: "Tutor StudentDocumentsTab",
    icon: "mdi-file-document",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.schedule",
    icon: "mdi-calendar",
    link: "Tutor StudentSchedulesTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.levels",
    icon: "mdi-school",
    link: "Tutor StudentLevelsTab",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.registrations",
    link: "Tutor StudentRegistrationsTab",
    icon: "mdi-school",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.wallet",
    icon: "mdi-wallet",
    notification: false,
    showed: true,
    tabs: [
      {
        title: "student.navbar.balance",
        link: "Tutor StudentBalanceTab",
        icon: "mdi-cash",
        notification: false,
        showed: true,
      },
      {
        title: "student.navbar.movements",
        link: "Tutor StudentMovementsTab",
        icon: "mdi-swap-horizontal",
        notification: false,
        showed: true,
      },
    ],
  },
  {
    title: "student.navbar.feedback",
    link: "Tutor StudentFeedbackTab",
    icon: "mdi-star",
    notification: false,
    showed: true,
  },
  {
    title: "student.navbar.results",
    link: "Tutor StudentResultsTab",
    icon: "mdi-briefcase",
    notification: false,
    showed: true,
  },
];

export default navBarItemsTutor;
