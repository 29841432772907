var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clrm-tab clrm-tab--no-toolbar"},[_c('div',{staticClass:"clrm-tabheader"},[_c('ul',{ref:"tabList",staticClass:"clrm-tablist"},_vm._l((_vm.tabHandler.state.tabs),function(tab){return _c('li',{key:tab.name,staticClass:"clrm-tablist__item",class:[
          {
            'clrm-tablist__item--active':
              _vm.tabHandler.state.currentTab.name === tab.name,
            'clrm-tablist__item--shared': tab.shared,
          } ],on:{"mouseenter":function($event){return _vm.tabMouseEnter($event)},"mouseleave":function($event){return _vm.tabMouseLeave($event)},"click":function($event){return _vm.changeTab(tab)}}},[_c('button',{staticClass:"clrm-tablist__item__link",class:{
            'clrm-tablist__item__link--simple':
              _vm.shrinkTabs && _vm.notActiveTabWidth < 80,
          },style:([
            _vm.tabHandler.state.currentTab.name !== tab.name && _vm.shrinkTabs
              ? { width: _vm.notActiveTabWidth + 'px' }
              : null ])},[_c('icon',{staticClass:"clrm-tablist__item__icon",attrs:{"name":tab.icon}}),_c('span',{staticClass:"clrm-tablist__item__title"},[_vm._v(_vm._s(tab.title))])],1),_c('button',{staticClass:"clrm-tablist__item__close",on:{"click":function($event){$event.preventDefault();return _vm.closeTab(tab)}}},[_c('icon',{attrs:{"name":"close","width":"12","height":"12","title":_vm.$t('classroom.global.close')}})],1)])}),0),_c('div',{staticClass:"clrm-tab__toolbar"},[(
          !_vm.tabHandler.state.currentTab.shared &&
          _vm.tabHandler.state.tabs.length > 0
        )?_c('button',{staticClass:"clrm-tab__toolbar__button clrm-tab__toolbar__button__share",on:{"click":function($event){return _vm.shareTab()}}},[_c('icon',{attrs:{"name":"arrow-top","width":"14","height":"14","title":_vm.$t('classroom.global.share-tab')}}),_vm._v(" "+_vm._s(_vm.$t("classroom.global.share-tab"))+" ")],1):_vm._e(),(
          _vm.tabHandler.state.currentTab.shared &&
          _vm.tabHandler.state.tabs.length > 0
        )?_c('button',{staticClass:"clrm-tab__toolbar__button clrm-tab__toolbar__button__unshare",on:{"click":function($event){return _vm.unshareTab()}}},[_c('icon',{attrs:{"name":"arrow-down","width":"14","height":"14","title":_vm.$t('classroom.global.unshare-tab')}}),_vm._v(" "+_vm._s(_vm.$t("classroom.global.unshare-tab"))+" ")],1):_vm._e(),(
          _vm.tabHandler.state.currentTab.activity &&
          _vm.tabHandler.state.currentTab.activity.teacherNotes
        )?_c('button',{staticClass:"clrm-tab__toolbar__button clrm-tab__toolbar__button__add",on:{"click":function($event){_vm.openTeacherNotes = true}}},[_c('icon',{attrs:{"name":"description","width":"14","height":"14"}})],1):_vm._e(),(_vm.openTeacherNotes)?_c('modal-dialog',{attrs:{"dialog":_vm.openTeacherNotes,"title":_vm.$t('activity.prop.teacher-notes'),"content":_vm.tabHandler.state.currentTab.activity.teacherNotes,"cancelText":_vm.$t('actions.close'),"dialogWith":"75%"},on:{"cancel":function($event){_vm.openTeacherNotes = false}}}):_vm._e(),(!_vm.isTeacherCandidate)?_c('button',{staticClass:"clrm-tab__toolbar__button clrm-tab__toolbar__button__add",class:{
          'clrm-tab__toolbar__button--disabled':
            _vm.tabHandler.state.disableAddTabs,
        },on:{"click":function($event){return _vm.openActivityList()}}},[_c('icon',{attrs:{"name":"plus","width":"14","height":"14","title":_vm.$t('classroom.global.add-more')}})],1):_vm._e()],1)]),(_vm.tabHandler.state.tabs.length > 0)?_c('div',{staticClass:"clrm-tabcontent"},[_c(_vm.tabHandler.state.currentTab.component,{tag:"component",staticClass:"clrm-tabcontent__container",attrs:{"zoom":_vm.zoom,"activity":_vm.tabHandler.state.currentTab.activity}})],1):_vm._e(),_c('ClassroomActivitiesList',{ref:"classroomActivitiesList",attrs:{"tabHandler":_vm.tabHandler,"language":_vm.classroomInfo.languageId,"level":_vm.classroomInfo.levelId},on:{"add-activity":_vm.addActivity}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }