<template>
  <v-container>
    <student-form
      v-if="!loading"
      v-model="entity"
      @save="save"
      @back="back"
    ></student-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import StudentForm from "@/mockups/student/components/StudentForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { deleteTempFile } from "@/common/file-utils";
import auth from "@/common/auth.js";

import { mapAuthGetter } from "@/common/mapAuthGetter";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

export default {
  name: "PersonalInfoFormTab",
  components: {
    LoadingPage,
    StudentForm,
  },
  props: {
    studentId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      entity: {},
      loading: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isTutor"]),
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  created() {
    this._fetchData(this.studentId);
  },
  methods: {
    _fetchData(id) {
      if (id) {
        this.loading = true;
        return StudentEntityRepository.get(id)
          .then((response) => {
            this.entity = response;
          })
          .catch(() => this.$log.debug("Error fetching student with ID: " + id))
          .finally(() => (this.loading = false));
      } else {
        this.entity = {
          name: this.user.firstName,
          surname: this.user.lastName,
          mainEmail: this.user.email,
        };
      }
    },
    back() {
      const route = this.$route.params.id
        ? this.isTutor
          ? "Tutor StudentView Detail" // Is tutor
          : "StudentView Detail" // Is admin
        : "ProfileStudentView Detail"; // Is student
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      StudentEntityRepository.save(this.entity)
        .then(() => {
          let route = this.$route.params.id
            ? this.isTutor
              ? "Tutor StudentView Detail"
              : "StudentView Detail"
            : "ProfileStudentView Detail";
          this.entity.photo = null;
          return auth.reloadUserData().finally(() =>
            this.$router.push({
              name: route,
              params: {
                backAction: this.$route.params.backPrevious,
              },
            })
          );
        })
        .catch(() => this.$log.debug("Error saving student: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail === ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
