<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between">
        <v-col class="mx-4 d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t(title) }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn v-if="editable" @click="back" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            v-if="editable"
            :loading="loading"
            :disabled="!validEvaluation"
            class="success mr-2"
            @click="save"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
          <v-btn v-else class="mr-2" @click="back">
            <v-icon>close</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.close") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-form v-model="validForm">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" :sm="'12'" class="text-center">
            <v-row no-gutters>
              <v-col cols="12" :md="'12'">
                <v-textarea
                  v-model="evaluation.comments"
                  :class="editable ? 'required' : ''"
                  :readonly="!editable"
                  :label="$t(label)"
                  outlined
                  :rules="[
                    (v) => !!v || $t('studentEvaluation.error.required'),
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: "EvaluationForm",
  props: {
    title: {
      required: true,
    },
    label: {
      required: true,
    },
    editable: {
      required: false,
      default: true,
    },
    loading: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      evaluation: this.value || {},
      validForm: true,
    };
  },
  computed: {
    validEvaluation() {
      return this.validForm;
    },
  },
  methods: {
    back() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>
