var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("levelTestRequest.program_dialog.title"))+" ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('autocomplete',{attrs:{"clearable":false,"items":_vm.availableTeachers,"item-text":_vm.getTeacherName,"label":_vm.$t('levelTestRequest.program_dialog.teacher'),"loading":_vm.loadingTeachers,"rules":[
            function (v) { return !!v ||
              _vm.$t('levelTestRequest.program_dialog.errors.teacher_required'); } ],"return-object":"","outlined":""},on:{"change":_vm.updateAvailabilities},model:{value:(_vm.teacher),callback:function ($$v) {_vm.teacher=$$v},expression:"teacher"}})],1),(_vm.teacher)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[(_vm.selectedSlot)?_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("levelTestRequest.program_dialog.selected_slot"))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.selectedSlot.start), "long"))+" - "+_vm._s(_vm.$d(new Date(_vm.selectedSlot.end), "time"))+" ")])]):_vm._e(),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"success","disabled":!_vm.selectedSlot},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" save ")]),_c('span',[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.loadingAvailabilities)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"height":_vm.height}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.availabilities,"event-color":_vm.getEventColor,"interval-height":25,"interval-minutes":30,"interval-count":48,"type":"week","weekdays":[1, 2, 3, 4, 5, 6, 0],"locale":_vm.$i18n.locale},on:{"click:event":_vm.selectSlot},scopedSlots:_vm._u([{key:"event",fn:function(ref){
          var event = ref.event;
return [_c('div',{staticClass:"event-div"},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])]),(event.checked)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()],1)],1)]}}],null,false,1895411995),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1):_vm._e()],1),_c('v-card-actions')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }