<template>
  <div class="clrm-classroom__discussion__participants">
    <div class="clrm-classroom__discussion__items">
      <div v-for="user in participantsToShowImage" :key="user.userId">
        <div class="clrm-video clrm-video--border-radius">
          <div class="clrm-video__wrapper" ref="videoContainer">
            <img
              v-if="userImages[user.userId]"
              class="clrm-video__userimage"
              width="320"
              height="180"
              :src="userImages[user.userId]"
            />
          </div>
          <div class="clrm-video__title">
            {{ user.name }}
            <span v-show="user.devices.length == 0"> (offline)</span>
          </div>
        </div>
      </div>
      <ClassroomVideoMultiple
        ref="ClassroomVideoMultiple"
        :zoom="zoom"
      ></ClassroomVideoMultiple>
      <ClassroomVideoSplitted
        ref="ClassroomVideoSplitted"
        :zoom="zoom"
      ></ClassroomVideoSplitted>
    </div>
    <div class="clrm-classroom__discussion__participants__operations">
      <div
        class="clrm-classroom__discussion__toolbar"
        v-if="sessionState !== sessionStatusEnum.JOINED"
      >
        <button
          class="
            clrm-classroom__discussion__toolbar__button
            clrm-classroom__discussion__toolbar__button__join
          "
          @click="startPreview()"
        >
          {{ $t("classroom.toolbar.join") }}
        </button>
      </div>
      <div class="clrm-classroom__discussion__teacher">
        <OwnCameraReproduction
          :controls="sessionState === sessionStatusEnum.JOINED"
          :zoom="zoom"
          @switch-audio="switchAudio"
          @start-audio="startAudio"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClassroomVideoMultiple from "@/components/classroom/participants/ClassroomVideoMultiple";
import ClassroomVideoSplitted from "@/components/classroom/participants/ClassroomVideoSplitted";
import OwnCameraReproduction from "@/components/classroom/participants/OwnCameraReproduction.vue";
import { mapClassroomStateGetter } from "@/components/classroom/store/mapClassroomStateGetter";
import ParticipantSessionStatus from "@/enumerates/ParticipantSessionStatus";
import classroomStateGetter from "@/components/classroom/store/classroomStateGetter";
import classroomStateSetter from "@/components/classroom/store/classroomStateSetter";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

const defaultImage = require("@/assets/icon_visits.png");

export default {
  name: "ClassroomParticipants",
  components: {
    OwnCameraReproduction,
    ClassroomVideoMultiple,
    ClassroomVideoSplitted,
  },
  props: {
    classroomInfo: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentVideoComponent() {
      if (this.zoom.isSupportMultipleVideos()) {
        return this.$refs.ClassroomVideoSplitted;
      } else {
        return this.$refs.ClassroomVideoMultiple;
      }
    },
    sessionStatusEnum() {
      return ParticipantSessionStatus;
    },
    ...mapClassroomStateGetter(["sessionState", "participants", "userImages"]),
    participantsExceptHeadmasters() {
      return this.participants.filter((u) => u.role != "HEADMASTER");
    },
    participantsExceptMeOrHeadmasters() {
      return this.participantsExceptHeadmasters.filter(
        (u) => u.userId != this.classroomInfo.userId
      );
    },
    participantsToShowImage() {
      if (this.sessionState !== ParticipantSessionStatus.JOINED) {
        return this.participantsExceptMeOrHeadmasters;
      }
      return this.participantsExceptMeOrHeadmasters.filter(
        (p) => !p.cameraOnId
      );
    },
    participantsToShowVideo() {
      if (this.sessionState !== ParticipantSessionStatus.JOINED) {
        return [];
      }

      let auxParticipants = this.participantsExceptMeOrHeadmasters;
      if (!this.zoom?.userCanSeeTheLocalCamera()) {
        // tenemos que meter nuestro participante porque no se ve la camara con el local
        auxParticipants = this.participantsExceptHeadmasters;
      }
      return JSON.stringify(auxParticipants.filter((p) => p.cameraOnId));
    },
  },
  watch: {
    participants(newValue) {
      newValue.forEach((p) => {
        if (!this.userImages[p.userId]) {
          this.getUserImageSrc(p.userId);
        }
      });
    },
    participantsToShowVideo: {
      handler(newValJSON, oldValJSON) {
        if (
          newValJSON?.length == oldValJSON?.length ||
          classroomStateGetter.getSessionState() !=
            ParticipantSessionStatus.JOINED
        ) {
          return;
        }

        let newValue = newValJSON.length > 0 ? JSON.parse(newValJSON) : [];
        let oldValue = oldValJSON.length > 0 ? JSON.parse(oldValJSON) : [];
        this.$log.warn("participantsToShowVideo");
        oldValue
          .filter(
            (p) => !newValue.map((a) => a.cameraOnId).includes(p.cameraOnId)
          )
          .forEach((p) => this.currentVideoComponent.removeParticipant(p));
        newValue
          .filter(
            (p) => !oldValue.map((a) => a.cameraOnId).includes(p.cameraOnId)
          )
          .forEach((p) => this.currentVideoComponent.addParticipant(p));
      },
    },
  },
  methods: {
    switchAudio() {
      if (classroomStateGetter.getCurrentDevice().muted) {
        this.zoom.unmute();
      } else {
        this.zoom.mute();
      }
    },
    startAudio() {
      this.zoom.startAudio();
    },
    startPreview() {
      this.$log.debug("startPreview");
      classroomStateSetter.setSessionState(ParticipantSessionStatus.PREVIEW);
    },
    async getUserImageSrc(userId) {
      let aux = this.userImages;
      if (!this.userImages[userId]) {
        UserDataEntityRepository.getPhoto(userId)
          .then((data) => {
            if (data?.size > 0) {
              this.$set(aux, userId, URL.createObjectURL(data));
            }
          })
          .catch((e) => {})
          .finally(() => {
            if (!this.userImages[userId]) {
              this.$set(aux, userId, defaultImage);
            }
          });
      }
      classroomStateSetter.setUserImages(aux);
    },
  },
};
</script>
