import TutorDetail from "@/mockups/tutor/TutorDetail";
import BalanceTab from "./tabs/BalanceTab";
import MovementsTab from "./tabs/MovementsTab";
import Profile from "@/account/profile/Profile";
import ProfileEdit from "@/account/profile/ProfileEdit";
import StudentListTab from "./tabs/StudentListTab";
import TutorDashboard from "@/mockups/tutor/views/TutorDashboard";

const routes = [
  // ADMIN ROUTES DEFINITION
  {
    path: "/admin/tutors/:id",
    name: "TutorView",
    component: TutorDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "TutorView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "tutor.navbar.info",
        },
      },
      {
        path: "balance",
        name: "TutorView Balance",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "tutor.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "TutorView Movements",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "tutor.navbar.movements",
        },
      },
      {
        path: "edit",
        name: "TutorView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "tutor.navbar.edit",
        },
      },
      {
        path: "students",
        name: "TutorView Students",
        component: StudentListTab,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "tutor.navbar.students",
        },
      },
    ],
  },
  // MY ACCOUNT ROUTES DEFINITION
  {
    path: "/tutor/dashboard",
    name: "Tutor Home",
    component: TutorDashboard,
    meta: {
      authority: ["ROLE_TUTOR"],
      label: "tutor.dashboard.title",
    },
  },
  {
    path: "/account/profile/tutor",
    name: "ProfileTutorView",
    component: TutorDetail,
    meta: {
      authority: ["ROLE_TUTOR"],
    },
    children: [
      {
        path: "info",
        name: "ProfileTutorView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "tutor.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ProfileTutorView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "tutor.navbar.edit",
        },
      },
      {
        path: "balance",
        name: "ProfileTutorView Balance",
        component: BalanceTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "tutor.navbar.balance",
        },
      },
      {
        path: "movements",
        name: "ProfileTutorView Movements",
        component: MovementsTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "tutor.navbar.movements",
        },
      },
      {
        path: "students",
        name: "ProfileTutorView Students",
        component: StudentListTab,
        meta: {
          authority: ["ROLE_TUTOR"],
          label: "tutor.navbar.students",
        },
      },
    ],
  },
];

export default routes;
