<template>
  <div class="clrm-settings clrm-settings--inside">
    <div v-if="hasCameras">
      <div class="clrm-settings__field">
        <div
          class="clrm-settings__label"
          :class="{
            'clrm-settings__label--student': classroomMode === 'student',
          }"
        >
          {{ $t("virtual-classroom.cameras") }}
        </div>
        <DeviceDropDown
          :devices="hardwareDevices.cameras"
          :selected="hardwareDevices.selectedCamera"
          @switch-device="switchCamera"
        />
      </div>
    </div>
    <div v-else>{{ $t("classroom.discussion.no-cams") }}</div>
    <div v-if="hasMics">
      <div class="clrm-settings__field">
        <div
          class="clrm-settings__label"
          :class="{
            'clrm-settings__label--student': classroomMode === 'student',
          }"
        >
          {{ $t("virtual-classroom.microphones") }}
        </div>
        <DeviceDropDown
          :devices="hardwareDevices.microphones"
          :selected="hardwareDevices.selectedMicrophone"
          @switch-device="switchMic"
        />
      </div>
    </div>
    <div v-else>{{ $t("classroom.discussion.no-mics") }}</div>
    <v-btn @click="$emit('close')">{{ $t("classroom.global.close") }}</v-btn>
  </div>
</template>

<script>
import DeviceDropDown from "./DeviceDropDown.vue";
import {
  mapClassroomStateGetter,
  getClassroomState,
} from "./store/mapClassroomStateGetter";

export default {
  name: "ClassroomSettings",
  components: { DeviceDropDown },
  data() {
    return {
      state: getClassroomState(),
    };
  },
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapClassroomStateGetter(["hardwareDevices"]),
    hasCameras() {
      return this.hardwareDevices.cameras.length > 0;
    },
    hasMics() {
      return this.hardwareDevices.microphones.length > 0;
    },
  },
  methods: {
    switchMic(mic) {
      this.zoom.switchMicrophone(mic);
    },
    switchCamera(cam) {
      this.zoom.switchCamera(cam);
    },
  },
};
</script>
