<template>
  <v-autocomplete
    :items="courseItems"
    v-model="courseSelected"
    :label="label"
    :filter="customFilter"
    :append-icon="append_icon"
    :outlined="outlined"
    :clearable="clearable"
    :dense="dense"
    :rules="rules"
    :loading="loading"
    item-value="id"
    return-object
  >
    <template v-slot:selection="{ item }">
      <v-row align="center">
        <v-col cols="auto">
          <b>{{ item.title }}</b>
        </v-col>
        <v-col cols="auto">
          <span>{{ item.description }}</span>
        </v-col>
        <v-col cols="auto">
          <v-chip color="success">{{ item.level.name }}</v-chip>
          <v-chip color="warning">{{ item.level.language.name }}</v-chip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item="{ item }">
      <v-row no-gutters align="center">
        <v-col>
          <b>{{ item.title }}</b>
        </v-col>
        <v-col>
          <span>{{ item.description }}</span>
        </v-col>
        <v-col>
          <v-chip color="success">{{ item.level.name }}</v-chip>
          <v-chip color="warning">{{ item.level.language.name }}</v-chip>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "CourseSelector",
  props: {
    value: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      default: function () {
        this.$t("course.name");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      required: false,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.courseSelected = newValue;
      },
      immediate: true,
    },
    filters: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },

    courseSelected(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.courseSelected = this.value;
    this.fetchData();
  },
  data() {
    return {
      courseItems: [],
      courseSelected: {},
      loading: false,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;

      const options = {
        params: this.filters,
      };

      CourseEntityRepository.getAll(options)
        .then((res) => {
          this.courseItems = res.content;
        })
        .catch(() => this.$log.debug("Error fetching courses"))
        .finally(() => {
          this.loading = false;
        });
    },
    customFilter(item, queryText) {
      const textOne = item.title.toLowerCase();
      const textTwo = item.description.toLowerCase();
      const textThree = item.level.name.toLowerCase();
      const textFour = item.level.language.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1 ||
        textFour.indexOf(searchText) > -1
      );
    },
  },
};
</script>

<style></style>
