<template>
  <v-container>
    <v-row align="center" justify="space-between">
      <v-col class="mx-4 d-none d-md-block">
        <span v-if="edit" class="headline no-split-words">
          {{
            !simplified
              ? $t($route.meta.label)
              : $t("activity.actions.edit_details")
          }}
        </span>
        <span v-if="!edit" class="headline no-split-words">
          {{
            !simplified ? $t($route.meta.label) : $t("activity.actions.create")
          }}
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn @click="back">
          <v-icon>mdi-arrow-left</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("actions.cancel") }}
          </span>
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!validActivity"
          class="success ml-2"
          @click="save"
        >
          <v-icon>save</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
        </v-btn>
      </v-col>
    </v-row>

    <v-form ref="validForm">
      <v-row>
        <v-col cols="12" :md="!simplified ? '12' : '4'">
          <v-select
            v-model="activity.resourceType"
            :items="resourceTypes"
            :label="$t('activity.prop.resource_type')"
            :item-text="translate"
            outlined
            dense
            :disabled="edit || media"
            class="required"
            :rules="[(v) => !!v || $t('activity.error.required')]"
            @change="checkType"
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" :sm="!simplified ? '6 ' : '12'" class="text-center">
          <v-row :no-gutters="!simplified">
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <v-text-field
                dense
                outlined
                v-model="activity.title"
                class="required"
                :rules="[(v) => !!v || $t('activity.error.required')]"
                :label="$t('activity.prop.title')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <v-text-field
                dense
                outlined
                v-model="activity.description"
                :rules="[]"
                :label="$t('activity.prop.description')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <autocomplete
                dense
                outlined
                :loading="loadingTypes"
                :items="activityTypes"
                item-text="name"
                v-model="activity.activityType"
                :label="$t('activity.prop.activity_type')"
                class="required"
                :rules="[(v) => !!v || $t('activity.error.required')]"
                return-object
              ></autocomplete>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <language-selector
                v-model="selectedLanguage"
                class="required"
                dense
                :label="$t('activity.prop.language')"
                outlined
                :rules="[(v) => !!v || $t('activity.error.required')]"
                show-chips
                @input="onLanguageChange"
              ></language-selector>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <level-selector
                v-model="activity.level"
                class="required"
                clearable
                dense
                :language-filter="selectedLanguageId"
                :label="$t('activity.prop.level')"
                :rules="[(v) => !!v || $t('activity.error.required')]"
                outlined
                @input="onLevelChange"
              ></level-selector>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <autocomplete
                dense
                outlined
                multiple
                :loading="loadingTopics"
                :items="vocabTopics"
                item-text="name"
                v-model="selectedVocabTopics"
                :label="$t('activity.prop.vocab_topics')"
                return-object
              ></autocomplete>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <autocomplete
                dense
                outlined
                :loading="loadingTopics"
                :items="skills"
                item-text="name"
                v-model="selectedSkillTopics"
                :label="$t('activity.prop.skills')"
                return-object
              ></autocomplete>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <autocomplete
                dense
                outlined
                multiple
                :loading="loadingTopics"
                :items="grammarTopics"
                item-text="name"
                v-model="selectedGrammarTopics"
                :label="$t('activity.prop.grammar_topics')"
                return-object
              ></autocomplete>
            </v-col>
            <v-col cols="12" :md="!simplified ? '12' : '4'">
              <autocomplete
                dense
                outlined
                multiple
                :loading="loadingWords"
                :items="words"
                item-text="name"
                v-model="selectedWords"
                :label="$t('activity.prop.words')"
                return-object
              ></autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                v-model="activity.teacherNotes"
                :rules="[]"
                :label="$t('activity.prop.teacher-notes')"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" class="text-left">
              <v-switch
                v-if="!edit && activity.resourceType === 'ACTIVITY'"
                class="mx-3"
                v-model="type_h5p"
                inset
                :label="$t('activity.prop.type_h5p')"
              ></v-switch>
              <div v-if="!type_h5p && activity.content">
                <span>
                  <v-btn
                    class="ml-2"
                    outlined
                    color="primary"
                    @click="chooseFile"
                    :loading="file.loading"
                  >
                    <v-icon class="mr-2">file_upload</v-icon>
                    {{ $t("activity.actions.modify_file") }}
                  </v-btn>
                  {{ activity.content.fileName }}</span
                >
              </div>
              <div v-else-if="!type_h5p && !activity.content">
                <div v-if="activity.resourceType === 'LINK'">
                  <v-text-field
                    dense
                    outlined
                    color="success"
                    v-model="activity.link"
                    class="required"
                    :rules="[(v) => !!v || $t('activity.error.required')]"
                    :label="$t('activity.resource_type.LINK')"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-btn
                    class="ml-2"
                    outlined
                    color="success"
                    @click="chooseFile"
                    :disabled="!activity.resourceType"
                    :loading="file.loading"
                  >
                    <v-icon class="mr-2">file_upload</v-icon>
                    {{ $t("activity.actions.add_file") }}
                  </v-btn>
                </div>
              </div>
              <div
                v-if="
                  (activity.resourceType === 'DOCUMENT' ||
                    activity.resourceType === 'ACTIVITY') &&
                  !type_h5p
                "
                class="body-2 ml-2"
              >
                {{ $t("activity.messages.pdf_extensions") }}
              </div>
              <div v-if="activity.resourceType === 'IMAGE'" class="body-2 ml-2">
                {{ $t("activity.messages.image_extensions") }}
              </div>
              <div v-if="activity.resourceType === 'VIDEO'" class="body-2 ml-2">
                {{ $t("activity.messages.video_extensions") }}
              </div>
              <div v-if="activity.resourceType === 'AUDIO'" class="body-2 ml-2">
                {{ $t("activity.messages.audio_extensions") }}
              </div>
              <input
                hidden
                class="d-none"
                type="file"
                ref="fileLoader"
                :accept="extensions"
                @change="uploadFile"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  deleteTempFile,
  uploadAudio,
  uploadImage,
  uploadPdf,
  uploadVideo,
} from "@/common/file-utils";
import { translate } from "@/common/translation-utils";
import resourceTypes from "@/enumerates/ResourceType";
import LevelSelector from "@/components/selectors/LevelSelector";
import LanguageSelector from "@/components/selectors/LanguageSelector";

const ActivityTypeEntityRepository = RepositoryFactory.get(
  "ActivityTypeEntityRepository"
);
const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");
const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const FileEntityRepository = RepositoryFactory.get("FileEntityRepository");

export default {
  name: "ActivityForm",
  components: {
    LanguageSelector,
    Autocomplete,
    LevelSelector,
  },
  props: {
    entity: {
      type: Object,
      required: false,
    },
    simplified: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      activity: {},
      file: {
        loading: false,
      },
      resourceTypes: resourceTypes,
      skills: [],
      grammarTopics: [],
      vocabTopics: [],
      words: [],
      activityTypes: [],
      selectedVocabTopics: [],
      selectedGrammarTopics: [],
      selectedSkillTopics: {},
      selectedWords: [],
      selectedTopics: [],
      selectedLanguage: null,
      validForm: true,
      edit: false,
      media: false,
      type_h5p: false,
      loading: false,
      loadingTypes: true,
      loadingTopics: true,
      loadingWords: true,
    };
  },
  computed: {
    selectedLanguageId() {
      return this.selectedLanguage?.id;
    },
    validActivity() {
      return this.validForm && !this.file.loading;
    },
    extensions() {
      if (
        (this.activity.resourceType === "DOCUMENT" ||
          this.activity.resourceType === "ACTIVITY") &&
        !this.type_h5p
      ) {
        return ".pdf";
      } else if (this.activity.resourceType === "IMAGE") {
        return ".jpg, .jpeg, .gif, .png, .apng, .webp, .bmp, .ico";
      } else if (this.activity.resourceType === "VIDEO") {
        return ".mp4, .webm, .mov, .ogv, .ogg, .ogx";
      } else if (this.activity.resourceType === "AUDIO") {
        return ".mp3, .wav, .ogg";
      } else {
        return "*.*";
      }
    },
  },
  created() {
    if (this.$route.params.resourceType) {
      this.activity.resourceType = "LINK";
      this.media = true;
    }
    if (this.entity) {
      this.edit = true;
      this.activity = this.entity;
      this.selectedLanguage = this.entity.level.language;
      this.type_h5p = this.activity.typeH5p;
    }
    this._fetchTypes();
    this._fetchTopics();
    this._fetchWords();
  },
  methods: {
    _fetchTypes() {
      this.loadingTypes = true;
      ActivityTypeEntityRepository.getAll()
        .then((data) => (this.activityTypes = data))
        .catch(() => this.$log.debug("Error fetching activity types"))
        .finally(() => (this.loadingTypes = false));
    },
    _fetchTopics() {
      this.loadingTopics = true;
      return TopicEntityRepository.getAll()
        .then((data) => {
          data.content.forEach((item) => {
            if (item.topicType.name === "GRAMMAR") {
              this.grammarTopics.push(item);
              if (
                this.activity.activityTopics &&
                this.activity.activityTopics.length !== 0 &&
                this.grammarTopics
              ) {
                this.selectedGrammarTopics = this.grammarTopics.filter(
                  (topic) =>
                    this.activity.activityTopics.findIndex(
                      (at) => at.topicId === topic.id
                    ) !== -1
                );
              }
            } else if (item.topicType.name === "VOCABULARY") {
              this.vocabTopics.push(item);
              if (
                this.activity.activityTopics &&
                this.activity.activityTopics.length !== 0 &&
                this.vocabTopics
              ) {
                this.selectedVocabTopics = this.vocabTopics.filter(
                  (topic) =>
                    this.activity.activityTopics.findIndex(
                      (at) => at.topicId === topic.id
                    ) !== -1
                );
              }
            } else if (item.topicType.name === "SKILL") {
              this.skills.push(item);
              if (
                this.activity.activityTopics &&
                this.activity.activityTopics.length !== 0 &&
                this.skills
              ) {
                this.selectedSkillTopics = this.skills.find(
                  (topic) =>
                    this.activity.activityTopics.findIndex(
                      (at) => at.topicId === topic.id
                    ) !== -1
                );
              }
            }
          });
        })
        .finally(() => (this.loadingTopics = false));
    },
    _fetchWords() {
      this.loadingWords = true;
      const options = {
        params: {
          language: this.selectedLanguage?.id,
          level: this.activity.level?.id,
        },
      };
      return WordEntityRepository.getAllByLevelAndLanguage(options)
        .then((data) => {
          this.words = data.content;
          if (this.activity.activityWords) {
            this.selectedWords = this.words.filter(
              (word) =>
                this.activity.activityWords.findIndex(
                  (aw) => aw.wordId === word.id
                ) !== -1
            );
          }
        })
        .finally(() => (this.loadingWords = false));
    },
    onLanguageChange() {
      if (
        this.selectedLanguage &&
        this.activity.level &&
        this.selectedLanguage?.id !== this.activity.level?.language?.id
      ) {
        this.$set(this.activity, "level", null);
      } else {
        this._fetchWords();
      }
    },
    onLevelChange() {
      this._fetchWords();
      if (this.activity.level) {
        this.selectedLanguage = this.activity.level?.language;
      }
      if (this.selectedWords) this.selectedWords = [];
    },
    checkType() {
      this.type_h5p = this.activity.resourceType === "ACTIVITY";
      if (this.activity.content != null) {
        deleteTempFile(this.activity, "content").then(
          () => (this.activity.content = null)
        );
      }
    },
    chooseFile() {
      this.$refs.fileLoader.click();
    },
    back() {
      this.$emit("cancelEdit");
    },
    save() {
      if (this.$refs.validForm.validate()) {
        if (
          !this.type_h5p &&
          !this.activity.content &&
          this.activity.resourceType !== "LINK"
        ) {
          this.$notify({
            title: this.$t("activity.messages.save_fail"),
            text: this.$t("activity.messages.file_not_exist"),
            type: "error",
            duration: 30000,
          });
        } else {
          this.loading = true;
          this.activity.typeH5p = this.type_h5p;
          if (this.selectedTopics) {
            const oldActivityTopics = this.activity.activityTopics || [];
            this.activity.activityTopics = [];
            if (
              this.selectedSkillTopics != null &&
              Object.keys(this.selectedSkillTopics).length !== 0
            ) {
              const topic = this.selectedSkillTopics;
              const oldActivityTopic = oldActivityTopics.find(
                (at) => at.topicId === topic.id
              );
              if (oldActivityTopic) {
                this.activity.activityTopics.push(oldActivityTopic);
              } else {
                this.activity.activityTopics.push({
                  topicId: topic.id,
                });
              }
            }
            this.selectedVocabTopics.forEach((topic) => {
              const oldActivityTopic = oldActivityTopics.find(
                (at) => at.topicId === topic.id
              );
              if (oldActivityTopic) {
                this.activity.activityTopics.push(oldActivityTopic);
              } else {
                this.activity.activityTopics.push({
                  topicId: topic.id,
                });
              }
            });
            this.selectedGrammarTopics.forEach((topic) => {
              const oldActivityTopic = oldActivityTopics.find(
                (at) => at.topicId === topic.id
              );
              if (oldActivityTopic) {
                this.activity.activityTopics.push(oldActivityTopic);
              } else {
                this.activity.activityTopics.push({
                  topicId: topic.id,
                });
              }
            });
          }
          if (this.selectedWords) {
            const oldActivityWords = this.activity.activityWords || [];
            this.activity.activityWords = [];
            this.selectedWords.forEach((word) => {
              const oldActivityWord = oldActivityWords.find(
                (aw) => aw.wordId === word.id
              );
              if (oldActivityWord) {
                this.activity.activityWords.push(oldActivityWord);
              } else {
                this.activity.activityWords.push({
                  wordId: word.id,
                });
              }
            });
          }
          ActivityEntityRepository.save(this.activity)
            .then((res) => {
              this.$notify({
                title: this.$t("activity.messages.save_success"),
                text: this.$t("account.notifications.changes_saved"),
                type: "success",
              });
              this.$emit("saved", res);
            })
            .catch(() => this.$log.debug("Error saving activity"));
        }
      }
    },
    uploadFile(htmlElement) {
      if (this.activity.resourceType === "IMAGE") {
        uploadImage(this.activity, "content", this.file, htmlElement);
      } else if (this.activity.resourceType === "VIDEO") {
        uploadVideo(this.activity, "content", this.file, htmlElement);
      } else if (this.activity.resourceType === "AUDIO") {
        uploadAudio(this.activity, "content", this.file, htmlElement);
      } else if (
        (this.activity.resourceType === "DOCUMENT" ||
          this.activity.resourceType === "ACTIVITY") &&
        !this.type_h5p
      ) {
        uploadPdf(this.activity, "content", this.file, htmlElement);
      } else {
        this.$notify({
          title: this.$t("activity.messages.type_missing"),
          text: this.$t("activity.messages.no_type_selected"),
          type: "error",
          duration: 30000,
        });
      }
    },
    translate,
  },
};
</script>
