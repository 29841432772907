<template>
  <v-card align="left">
    <v-card-title>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t("course.prop.units") }}
        </span>
      </v-col>
      <v-col order="1" order-md="2" class="text-right">
        <v-btn small fab color="primary" @click="addUnit">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-form v-model="validForm">
        <v-row v-for="(unit, i) in units" :key="i">
          <v-col cols="9">
            <v-text-field
              :disabled="!editable[i]"
              dense
              outlined
              v-model="unit.name"
              class="required"
              :rules="[(v) => !!v || $t('unit.error.required')]"
              :label="$t('unit.prop.name')"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              icon
              color="success"
              v-show="editable[i]"
              @click="disableEdit(i)"
              ><v-icon> mdi-check </v-icon></v-btn
            >
            <v-btn
              icon
              color="warning"
              v-show="!editable[i]"
              @click="enableEdit(i)"
              ><v-icon> mdi-pencil </v-icon></v-btn
            >
            <v-btn
              icon
              color="error"
              :disabled="editable[i]"
              @click="deleteUnit(i)"
              ><v-icon> mdi-delete </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UnitCourseList",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      editable: [],
      units: [],
      validForm: true,
    };
  },
  watch: {
    validForm(newVal, oldVal) {
      if (newVal != oldVal) this.$emit("validation-update", newVal);
    },
    value(val) {
      if (val) this.$set(this, "units", val);
    },
    units(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    addUnit() {
      this.units.push({});
      this.editable.push(true);
    },
    deleteUnit(index) {
      this.units.splice(index, 1);
      this.editable.splice(index, 1);
    },
    disableEdit(index) {
      this.editable.splice(index, 1, false);
    },
    enableEdit(index) {
      this.editable.splice(index, 1, true);
    },
  },
};
</script>

<style></style>
