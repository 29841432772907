<template>
  <v-container>
    <v-container v-if="!isLoading">
      <!-- levels  -->
      <v-data-table
        :items="entity.levels"
        :headers="headers"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("student.tabs.levels.title") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isAnyStudent"
              class="mx-2"
              color="success"
              @click="levelSelectorDialog = true"
            >
              {{ $t("student.tabs.exams.headers.new_language_exam") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <span v-if="item.date">
            {{ item.date | dateTimeWithoutTz }}
          </span>
        </template>

        <template v-slot:[`item.levelName`]="{ item }">
          <v-chip
            v-if="item.level"
            :color="getChipBackColor(item.level)"
            :text-color="getChipTextColor(item.level)"
          >
            {{
              (item.level.name +
                " - " +
                $t(`languages.${item.level.language.name}`))
                | uppercase
            }}
          </v-chip>
        </template>
      </v-data-table>

      <v-data-table
        v-if="tests.length > 0"
        class="mt-2"
        :items="tests"
        :headers="testHeaders"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("student.tabs.exams.title") }}
            </v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:[`item.lesson.level`]="{ item }">
          <v-chip
            v-if="item.lesson.level"
            :color="getChipBackColor(item.lesson.level)"
            :text-color="getChipTextColor(item.lesson.level)"
          >
            {{
              (item.lesson.level.name +
                " - " +
                $t(`languages.${item.lesson.level.language.name}`))
                | uppercase
            }}
          </v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            v-if="isAnyStudent && item.state === 'ASSIGNED'"
            color="primary"
            depressed
            @click.stop="startTest(item)"
          >
            {{ $t("lesson.actions.start") }}
          </v-btn>
          <v-btn
            v-if="isAnyStudent && item.state === 'STARTED'"
            color="primary"
            depressed
            @click.stop="startTest(item)"
          >
            {{ $t("lesson.actions.resume") }}
          </v-btn>
          <v-btn v-if="item.state === 'COMPLETED'" disabled depressed>
            {{ $t("lesson.actions.done") }}
          </v-btn>
        </template>

        <template v-slot:[`item.action2`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="isAnyStudent"
                icon
                color="error"
                @click.stop="deleteLevelTest(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("actions.delete") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- levels request  -->
      <v-data-table
        :items="levelsRequested"
        :headers="headersLevelRequest"
        hide-default-footer
        class="mt-4"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("student.tabs.levels.request") }}
            </v-toolbar-title>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" class="ml-2" v-bind="attrs" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <span>{{ $t("student.tabs.levels.headers.info") }}</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-btn
              v-if="isAnyStudent"
              class="mx-2"
              color="success"
              @click="languageTrialDialog = true"
              >{{ $t("student.tabs.levels.headers.new_language_trial") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.level`]="{ item }">
          <v-chip
            v-if="item.level"
            :color="getChipBackColor(item.level)"
            :text-color="getChipTextColor(item.level)"
          >
            {{
              (item.level.name +
                " - " +
                $t(`languages.${item.level.language.name}`))
                | uppercase
            }}
          </v-chip>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <span v-if="item.state">
            {{ $t(`student.tabs.levels.${item.state}`) }}
          </span>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <span v-if="item.date">
            {{ $d(new Date(item.date[0], item.date[1] - 1, item.date[2])) }}
          </span>
        </template>

        <template v-slot:[`item.limitDate`]="{ item }">
          <span v-if="item.limitDate">
            {{
              $d(
                new Date(
                  item.limitDate[0],
                  item.limitDate[1] - 1,
                  item.limitDate[2]
                )
              )
            }}
          </span>
        </template>
      </v-data-table>

      <language-selector-dialog
        v-if="languageTrialDialog"
        :dialog="languageTrialDialog"
        :hiddenLanguages="entityLevels"
        @close="languageTrialDialog = false"
        @save="saveLevelTrial"
      >
      </language-selector-dialog>
      <level-selector-dialog
        v-if="levelSelectorDialog"
        :dialog="levelSelectorDialog"
        @close="levelSelectorDialog = false"
        @save="requestLevelTest"
      >
      </level-selector-dialog>
    </v-container>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import LanguageSelectorDialog from "@/mockups/student/components/LanguageSelectorDialog";
import LevelSelectorDialog from "@/mockups/student/components/LevelSelectorDialog";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const StudentLevelEvaluationEntityRepository = RepositoryFactory.get(
  "StudentLevelEvaluationEntityRepository"
);
const LevelTestRequestEntityRepository = RepositoryFactory.get(
  "LevelTestRequestEntityRepository"
);

export default {
  components: {
    LoadingPage,
    LanguageSelectorDialog,
    LevelSelectorDialog,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
      tests: [],
      loading: false,
      languageTrialDialog: false,
      levelSelectorDialog: false,
      levelsRequested: [],
    };
  },
  computed: {
    ...mapAuthGetter(["isAnyStudent"]),
    isLoading() {
      return this.loading;
    },
    headers() {
      return [
        {
          text: this.$t("student.tabs.levels.headers.levelName"),
          value: "levelName",
        },
        {
          text: this.$t("student.tabs.levels.headers.date"),
          value: "date",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    testHeaders() {
      return [
        {
          text: this.$t("student.tabs.exams.headers.exam_name"),
          value: "lesson.name",
        },
        {
          text: this.$t("student.tabs.exams.headers.level"),
          value: "lesson.level",
        },
        { text: "", sortable: false, value: "action" },
        { text: "", sortable: false, value: "action2" },
      ];
    },
    headersLevelRequest() {
      return [
        {
          text: this.$t("student.tabs.levels.headers.levelName"),
          value: "level",
        },
        {
          text: this.$t("student.tabs.levels.headers.state"),
          value: "state",
        },
        {
          text: this.$t("student.tabs.levels.headers.request_date"),
          value: "date",
        },
        {
          text: this.$t("student.tabs.levels.headers.limit_date"),
          value: "limitDate",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    entityLevels() {
      if (this.entity.levels) {
        let levels = this.entity.levels.map((el) => {
          return el.level.language.name;
        });
        return [...new Set(levels)];
      }
      return [];
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this._fetchData(this.studentId),
      this._fetchLevelTest(this.studentId),
      this._fetchLevelTrials(this.studentId),
    ]).then(() => (this.loading = false));
  },
  methods: {
    _fetchData(id) {
      return StudentEntityRepository.get(id)
        .then((response) => {
          if (!response.levels) response.levels = [];
          this.entity = response;
        })
        .catch(() => this.$log.debug("Error fetching student with ID: " + id));
    },
    _fetchLevelTest(id) {
      return StudentLevelEvaluationEntityRepository.getAllOfStudent(id)
        .then((response) => {
          this.tests = response;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching studentLevelEvaluation for student with ID: " + id
          )
        );
    },
    _fetchLevelTrials(id) {
      return LevelTestRequestEntityRepository.getByStudentId(this.studentId)
        .then((e) => (this.levelsRequested = e))
        .catch(() =>
          this.$log.debug(
            "Error fetching level test requests with student ID: " + id
          )
        );
    },
    requestLevelTest(data) {
      const lesson = { level: data.level };
      this.loading = true;
      StudentLevelEvaluationEntityRepository.save({
        studentId: this.studentId,
        lesson: lesson,
      })
        .then(() => {
          this.$notify({
            title: this.$t("student.tabs.exams.headers.exam_sent"),
            type: "success",
          });
          return this._fetchLevelTest(this.studentId);
        })
        .catch(() =>
          this.$log.debug(
            "Error saving level exam request: " +
              JSON.stringify({
                studentId: this.studentId,
                language: data.language,
              })
          )
        )
        .finally(() => {
          this.levelSelectorDialog = false;
          this.loading = false;
        });
    },
    startTest(item) {
      const route = {
        name: "LevelTest",
        params: {
          studentId: this.studentId,
        },
        query: {
          lessonId: item.lesson.id,
          studentLevelEvaluationId: item.id,
        },
      };
      if (item.state === "ASSIGNED") {
        this.loading = true;
        item.state = "STARTED";
        StudentLevelEvaluationEntityRepository.save(item)
          .then((res) => {
            if (res.state === "STARTED") {
              this.$router.push(route);
            }
          })
          .catch(() =>
            this.$log.debug("Error starting level test " + JSON.stringify(item))
          )
          .finally(() => (this.loading = false));
      } else {
        this.$router.push(route);
      }
    },
    saveLevelTrial(data) {
      this.loading = true;
      LevelTestRequestEntityRepository.save({
        limitDate: data.limitDate,
        level: data.level,
        studentId: this.studentId,
      })
        .then((data) => {
          this.$notify({
            title: this.$t("student.tabs.levels.headers.trial_sent"),
            type: "success",
          });
          this.levelsRequested.push(data);
          // return this._fetchLevelTest(this.studentId);
        })
        .catch(() =>
          this.$log.debug(
            "Error saving level test request: " +
              JSON.stringify({
                limitDate: data.limitDate,
                language: data.language,
                studentId: this.studentId,
              })
          )
        )
        .finally(() => {
          this.languageTrialDialog = false;
          this.loading = false;
        });
    },
    deleteLevelTest(levelTestId) {
      this.loading = true;
      StudentLevelEvaluationEntityRepository.delete(levelTestId)
        .then(() => {
          this.$notify({
            title: this.$t("student.tabs.levels.delete-success"),
            type: "success",
          });
          return this._fetchLevelTest(this.studentId);
        })
        .catch(() =>
          this.$log.debug(
            "Error deleting level test request: " + this.studentId
          )
        )
        .finally(() => (this.loading = false));
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
