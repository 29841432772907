var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',{attrs:{"fluid":""}},[_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('v-card',{staticClass:"card-datatable"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"7","md":"4","lg":"6","xl":"8","order":"2","order-sm":"1"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary","loading":_vm.loadingAllPDF},on:{"click":_vm.allToPDF}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("picture_as_pdf")]),_vm._v(" "+_vm._s(_vm.$t("invoice.export.pdf"))+" ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary","loading":_vm.loadingXml},on:{"click":_vm.getXml}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("file_download")]),_vm._v(" "+_vm._s(_vm.$t("invoice.export.xml"))+" ")],1)],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('autocomplete',{attrs:{"debouncing":300,"dense":"","items":_vm.filteredUsers,"item-text":_vm.getFullName,"item-value":"userId","label":_vm.$t('invoice.prop.user'),"loading":_vm.users.loading,"no-filter":"","solo":"","search-input":_vm.userSearch},on:{"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event},"change":_vm.redirectOnFilterChange},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
              data: _vm.startTimeFilter,
              label: 'invoice.prop.startTime',
            }},on:{"update-time":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'startTimeFilter', false ].concat( argsArray ))}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('dateAndHourPicker',{attrs:{"datePickerProp":{
              data: _vm.endTimeFilter,
              label: 'invoice.prop.endTime',
            }},on:{"update-time":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateDateTime.apply(void 0, [ 'endTimeFilter', false ].concat( argsArray ))}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.entitiesPage,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [(item.studentId)?_c('router-link',{attrs:{"to":{
                  name: 'StudentBalanceTab',
                  params: { id: item.studentId, backPrevious: true },
                }}},[_vm._v(" "+_vm._s(item.name)+" ")]):(item.userId)?_c('router-link',{attrs:{"to":{
                  name: 'TutorView Balance',
                  params: { id: item.userId, backPrevious: true },
                }}},[_vm._v(" "+_vm._s(item.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(item.date))+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("invoice.state." + (item.status))))+" ")]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","loading":_vm.loadingPDF,"disabled":_vm.loadingPDF},on:{"click":function($event){$event.stopPropagation();return _vm.printPDF(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" picture_as_pdf ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.export_pdf")))])])]}}],null,true)})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }