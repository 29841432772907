import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/editions";

export default {
  async getAllByTeacher(teacherId, options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/teacher/${teacherId}`,
      options
    );
    return response.data;
  },
  async getAllByStudent(studentId, options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/students/${studentId}`,
      options
    );
    return response.data;
  },
  async getAllByAdmin(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async closeEdition(id, endDate) {
    return (await HTTP.patch(`${RESOURCE_NAME}/${id}/close`, endDate)).data;
  },

  async createCourseEdition(edition) {
    return (await HTTP.post(`${RESOURCE_NAME}/course`, edition)).data;
  },

  async createCompanyCourseEdition(edition) {
    return (await HTTP.post(`${RESOURCE_NAME}/course/company`, edition)).data;
  },
};
