<template>
  <v-container fluid>
    <generic-profile-form
      v-if="!loading"
      v-model="userData"
      :billing-id="entityIsTutor"
      :include-legal="isTutor"
      @save="save"
      @back="back"
    >
    </generic-profile-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import GenericProfileForm from "./GenericProfileForm";
import { deleteTempFile } from "@/common/file-utils";
import auth from "@/common/auth.js";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    LoadingPage,
    GenericProfileForm,
  },
  name: "ProfileEdit",
  data() {
    return {
      loading: false,
      userData: {},
    };
  },
  computed: {
    ...mapAuthGetter(["isTutor"]),
    entityIsTutor() {
      return this.userData.authorities
        ? this.userData.authorities.indexOf("ROLE_TUTOR") !== -1
        : false;
    },
  },
  beforeDestroy() {
    deleteTempFile(this.userData, "photo");
  },
  mounted() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const request = this.$route.params.id
        ? UserDataEntityRepository.getByUserId(this.$route.params.id)
        : UserDataEntityRepository.getByUser();
      request
        .then((res) => (this.userData = res))
        .catch(() =>
          this.$log.debug("Error fetching user data for current user")
        )
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({ name: "Profile", params: { backAction: true } });
    },
    save() {
      this.loading = true;
      this.checkSecondaryEmail();
      UserDataEntityRepository.updateUser(this.userData)
        .then(() => {
          this.$notify({
            title: "Saved",
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.userData.photo = null;
          return auth.reloadUserData().finally(() => this.back());
        })
        .catch(() =>
          this.$log.debug("Error saving user data: " + this.userData)
        )
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.userData.secondaryEmail =
        this.userData.secondaryEmail === ""
          ? undefined
          : this.userData.secondaryEmail;
    },
  },
};
</script>
