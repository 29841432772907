import store from "./store";
import stomp from "@/common/StompClient";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Vue from "vue";
import i18n from "@/plugins/i18n";

const AccountRepository = RepositoryFactory.get("AccountRepository");
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

const user = store.state.user;

function login(credentials) {
  return AccountRepository.login(credentials)
    .then((response) => {
      _saveToken(response.token);
      return _authenticate();
    })
    .catch(() => {
      Vue.$log.debug("Error trying to login");
      Vue.notify({
        title: i18n.t("account.errors.login.title"),
        text: i18n.t("account.errors.login.body"),
        type: "error",
        duration: 30000,
      });
    });
}

function logout() {
  return new Promise((res) => {
    _removeToken();
    stomp.disconnect();
    user.id = null;
    user.roleId = null;
    user.login = "";
    user.authorities = [];
    user.logged = false;
    user.email = null;
    user.firstName = null;
    user.lastName = null;
    user.displayName = null;
    user.langKey = "";
    user.isAdult = false;
    user.timeZone = null;
    user.selectedAuthority = null;
    Vue.set(user, "photo", null);
    res(true);
  });
}

function getUser() {
  return user;
}

function getWebsocket() {
  return store.state.websocket;
}

function getToken() {
  return localStorage.getItem("token");
}

function reloadUserData() {
  return new Promise((res) => {
    _authenticate().finally(() => res(true));
  });
}

function _saveUserData(data) {
  user.id = data.userId;
  user.roleId = data.roleId;
  user.login = data.login;
  user.authorities = data.authorities;
  user.logged = true;
  user.email = data.email;
  user.firstName = data.name;
  user.lastName = data.surname;
  user.displayName = data.displayName;
  user.langKey = data.langKey.toUpperCase();
  user.timeZone = data.timeZone ? data.timeZone.name : null;
  // If there is not selected authority or the selected one is not in the authorities array received
  //    we set as the selected authority the first one of the array
  const selectedAuthority =
    !user.selectedAuthority ||
    (user.selectedAuthority &&
      data.authorities.indexOf(user.selectedAuthority) === -1)
      ? data.authorities[0]
      : user.selectedAuthority;
  Vue.set(user, "selectedAuthority", selectedAuthority);
  Vue.set(user, "isAdult", _isAdult(data.birthdate));
  i18n.locale = user.langKey;
  _downloadPhoto();
}

function _downloadPhoto() {
  UserDataEntityRepository.getPhoto(user.id)
    .then((data) => {
      if (data.size > 0) {
        Vue.set(user, "photo", URL.createObjectURL(data));
      } else {
        Vue.set(user, "photo", null);
      }
    })
    .catch(() =>
      Vue.$log.debug("Error fetching profile photo for current user")
    );
}

function _isAdult(birthdate) {
  const fullAge = 18;
  if (birthdate) {
    const today = new Date();
    const monthDiff = today.getMonth() - (birthdate[1] - 1);
    let age = today.getFullYear() - birthdate[0];
    if (monthDiff < 0 || (monthDiff == 0 && today.getDate() > birthdate[2])) {
      age--;
    }
    return age >= fullAge;
  } else {
    return true;
  }
}

// usamos localStorage para guardar el token, de forma
// que sea persistente (se inhabilita con el tiempo o
// al hacer logout)
function _saveToken(token) {
  return localStorage.setItem("token", token);
}

function _removeToken() {
  localStorage.removeItem("token");
  // Removes token from cookie too
  document.cookie =
    "authorization=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// si tenemos el token guardado, esta petición se hará
// con el filtro definido en http-common y por tanto nos
// devolverá el usuario logueado
function _authenticate() {
  return UserDataEntityRepository.getByUserLogin()
    .then((response) => {
      _saveUserData(response);
      return user;
    })
    .catch((e) => Vue.$log.debug("Could not get user data"));
}

// este método devuelve una promesa que se resuelve cuando
// se haya comprobado si el token, de existir, es válido o no
function isAuthenticationChecked() {
  return new Promise((res) => {
    if (getToken()) {
      _authenticate()
        .catch(() => logout())
        .finally(() => res(true));
    } else {
      res(true);
    }
  });
}

function getSelectedAuthority() {
  return user.selectedAuthority;
}

function setSelectedAuthority(authority) {
  user.selectedAuthority = authority;
}

function getAuthorities() {
  return user.authorities;
}

export default {
  getUser,
  login,
  logout,
  getToken,
  isAuthenticationChecked: isAuthenticationChecked(),
  getWebsocket,
  reloadUserData,
  getSelectedAuthority,
  setSelectedAuthority,
  getAuthorities,
};
