<template>
  <v-container fluid v-if="items">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="center">
          <v-col cols="6" md="3" xl="2">
            <debounced-text-field
              dense
              @input="redirectOnFilterChange"
              v-model="titleFilter"
              :label="$t('product.prop.title')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="6" md="3" xl="2">
            <debounced-text-field
              dense
              @input="redirectOnFilterChange"
              v-model="descriptionFilter"
              :label="$t('product.prop.description')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="12" md="3" xl="2" class="mb-5">
            <product-type-selector
              v-model="productTypeFilter"
              append_icon="category"
              clearable
              @change="onProductTypeChange"
            ></product-type-selector>
          </v-col>

          <v-col cols="12" md="3" xl="2" class="mb-5">
            <level-selector
              v-model="levelFilter"
              append_icon="school"
              clearable
              :label="$t('product.prop.level')"
              :language-filter="languageFilter"
              @input="redirectOnFilterChange"
            ></level-selector>
          </v-col>
        </v-row>

        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @click:row="entityDetail"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span v-if="item.startDate">
              {{ item.startDate | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            <span v-if="item.endDate">
              {{ item.endDate | dateTimeWithoutTz }}
            </span>
          </template>

          <template v-slot:[`item.productType`]="{ item }">
            <span v-if="item.productType">
              {{ $t(`product.product_type.${item.productType}`) }}
            </span>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <v-container>
              <v-chip
                v-if="item.level"
                :color="getChipBackColor(item.level)"
                :text-color="getChipTextColor(item.level)"
                small
              >
                {{ item.level.name }}
              </v-chip>
              <v-chip
                v-if="item.level && item.level.language"
                :color="getChipBackColor(item.level)"
                :text-color="getChipTextColor(item.level)"
                small
              >
                {{ $t(`languages.${item.level.language.name}`) }}
              </v-chip>
            </v-container>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="entityDetail(item)"
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>description</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.detail") }}</span>
            </v-tooltip>
            <span
              v-if="
                (isCompany && item.recipientType === 'COMPANY') ||
                (isAnyStudent && item.recipientType !== 'COMPANY')
              "
            >
              <v-tooltip top v-if="item.state === null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="showProductRequestDialog(item)"
                    color="primary"
                    icon
                  >
                    <v-icon>mdi-pencil-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("productRequest.enroll-button") }}</span>
              </v-tooltip>
              <span>
                <v-tooltip top v-if="item.state === 'REQUESTED'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" icon color="warning">
                      pending_actions
                    </v-icon>
                  </template>
                  <span>{{ $t("productRequest.state.REQUESTED") }}</span>
                </v-tooltip>
              </span>

              <v-tooltip top v-if="item.state === 'APPROVED'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" icon color="success">
                    check_circle_outline
                  </v-icon>
                </template>
                <span>{{ $t("productRequest.state.APPROVED") }}</span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialogo para la petición de un curso -->
    <product-request-dialog
      v-if="productRequestDialog"
      :dialog="productRequestDialog"
      :selectedProduct="selectedProduct"
      @cancel="cancelProductRequestDialog"
      @submit="saveProductRequest"
    ></product-request-dialog>
    <!-- Dialogo para la petición de un curso como empresa -->
    <company-product-request-dialog
      v-if="companyProductRequestDialog"
      :dialog="companyProductRequestDialog"
      :loading="loadingRequest"
      :selectedProduct="selectedProduct"
      @cancel="cancelCompanyProductRequestDialog"
      @submit="saveCompanyProductRequest"
    ></company-product-request-dialog>
    <modal-dialog
      v-if="errorDialog"
      :content="
        $t('product.error.low_balance.detailed', {
          student: student,
          value: neededAmount,
        })
      "
      :dialog="errorDialog"
      :cancelText="$t('actions.close')"
      :title="$t('product.error.low_balance.title')"
      titleClass="warning white--text"
      titleIcon="mdi-alert"
      @cancel="errorDialog = false"
    ></modal-dialog>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import ProductRequestDialog from "@/mockups/economy/products/_components/ProductRequestDialog.vue";
import CompanyProductRequestDialog from "@/mockups/economy/products/_components/CompanyProductRequestDialog.vue";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import auth from "@/common/auth";
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import ProductTypeSelector from "@/components/selectors/ProductTypeSelector";
import LevelSelector from "@/components/selectors/LevelSelector";
import { timeSlotToUTCTimeSlot } from "@/common/conversion-utils";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);
const ProductRequestEntityRepository = RepositoryFactory.get(
  "ProductRequestEntityRepository"
);

export default {
  name: "ProductList",
  components: {
    ModalDialog,
    DebouncedTextField,
    ProductRequestDialog,
    CompanyProductRequestDialog,
    ProductTypeSelector,
    LevelSelector,
  },
  data() {
    return {
      items: [],
      showFilters: false,
      titleFilter: null,
      descriptionFilter: null,
      productTypeFilter: null,
      levelFilter: null,
      languageFilter: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      loadingRequest: false,
      companyProductRequestDialog: false,
      productRequestDialog: false,
      selectedProduct: {},
      neededAmount: null,
      student: null,
      errorDialog: false,
      tableFooterProps,
    };
  },
  computed: {
    ...mapAuthGetter(["isAnyStudent", "isCompany", "isAdmin"]),
    headers() {
      return [
        {
          text: this.$t("product.prop.title"),
          value: "title",
        },
        {
          text: this.$t("product.prop.description"),
          value: "description",
        },
        {
          text: this.isAdmin
            ? this.$t("product.prop.productType")
            : this.$t("product.prop.courseType"),
          value: "productType",
        },
        {
          text: this.$t("product.prop.start_date"),
          value: "startDate",
        },
        {
          text: this.$t("product.prop.end_date"),
          value: "endDate",
        },
        {
          text: this.$t("product.prop.level"),
          value: "level",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
    filters() {
      let filters = "";

      filters =
        filters +
        (this.titleFilter != null && this.titleFilter !== ""
          ? "title:" + this.titleFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.descriptionFilter != null && this.descriptionFilter !== ""
          ? "description:" + this.descriptionFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.productTypeFilter != null && this.productTypeFilter !== ""
          ? "productType:" + this.productTypeFilter.toString() + ","
          : "");

      filters =
        filters +
        (this.levelFilter
          ? "level.id:" + this.levelFilter.id.toString() + ","
          : "");

      filters =
        filters +
        "recipientType:" +
        (this.isCompany ? "COMPANY" : "GENERAL") +
        ",";

      return filters !== "" ? filters : null;
    },
  },
  watch: {
    "$route.query.languageId": {
      handler: function (languageId) {
        if (this.languageFilter !== languageId) {
          this.languageFilter = languageId;
          if (this.levelFilter == null) this.redirectOnFilterChange();
          else this.levelFilter = null; //Cambiando de valor de levelFilter, LevelSelector.vue solicita un redirect
        }
      },
    },
  },
  created() {
    //Setting route params
    if (this.$route.query.titleFilter) {
      this.showFilters = true;
      this.titleFilter = this.$route.query.titleFilter;
    }
    if (this.$route.query.descriptionFilter) {
      this.showFilters = true;
      this.descriptionFilter = this.$route.query.descriptionFilter;
    }
    if (this.$route.query.productTypeFilter) {
      this.showFilters = true;
      this.productTypeFilter = this.$route.query.productTypeFilter;
    }
    if (this.$route.query.levelFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.levelFilter);
      this.levelFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.languageId) {
      let value = parseFloat(this.$route.query.languageId);
      this.languageFilter = isNaN(value) ? null : value;
    }
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage),
          filters: this.filters,
          languageId: this.languageFilter ? this.languageFilter : undefined,
        },
      };
      ProductEntityRepository.getAllPublic(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching product list with params: " +
              JSON.stringify(options)
          )
        )
        .finally(() => (this.loading = false));
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Product Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();

      query.sort = generateSort(this.entitiesPage);

      query.titleFilter =
        this.titleFilter != null && this.titleFilter !== ""
          ? this.titleFilter
          : undefined;
      query.descriptionFilter =
        this.descriptionFilter != null && this.titleFilter !== ""
          ? this.descriptionFilter
          : undefined;
      query.productTypeFilter =
        this.productTypeFilter != null ? this.productTypeFilter : undefined;
      query.levelFilter = this.levelFilter ? this.levelFilter.id : undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    onProductTypeChange(productType) {
      this.productTypeFilter = productType;
      this.redirectOnFilterChange();
    },
    showProductRequestDialog(entity) {
      if (entity.recipientType === "COMPANY") {
        this.companyProductRequestDialog = true;
      } else {
        this.productRequestDialog = true;
      }
      this.selectedProduct = entity;
    },
    cancelProductRequestDialog() {
      this.productRequestDialog = false;
      this.selectedProduct = {};
    },
    async saveProductRequest(productRequest) {
      await this._saveProductRequest(productRequest);
      this.getItems();
      this.cancelProductRequestDialog();
    },
    showCompanyProductRequestDialog(entity) {
      this.companyProductRequestDialog = true;
      this.companySelectedProduct = entity;
    },
    cancelCompanyProductRequestDialog() {
      this.companyProductRequestDialog = false;
      this.companySelectedProduct = {};
    },
    async saveCompanyProductRequest(productRequest) {
      productRequest.schedules = productRequest.schedules.map((el) =>
        timeSlotToUTCTimeSlot(el, auth.getUser().timeZone)
      );
      productRequest.timeZone = auth.getUser().timeZone;
      productRequest.timeZoneOffset = moment()
        .tz(auth.getUser().timeZone)
        .utcOffset();
      await this._saveProductRequest(productRequest, "COMPANY");
      this.getItems();
      this.cancelCompanyProductRequestDialog();
    },
    _saveProductRequest(productRequest, type = "GENERAL") {
      let saveMethod = ProductRequestEntityRepository.save;
      if (type === "COMPANY") {
        saveMethod = ProductRequestEntityRepository.saveForCompany;
      }
      productRequest.productId = this.selectedProduct.id;
      productRequest.userId = auth.getUser().id;
      this.loadingRequest = true;
      return saveMethod(productRequest)
        .then(() => {
          this.$notify({
            title: this.$t("productRequest.messages.create-success"),
            type: "success",
          });
        })
        .catch((e) => {
          this.$log.debug("Could not create product request");
          if (
            e.response.headers["x-app-error"] ===
            "product.error.low_balance.notify"
          ) {
            const error = JSON.parse(e.response.headers["x-app-params"]).param;
            if (error != null) {
              this.student = error.substring(
                error.indexOf("of ") + 3,
                error.indexOf(" is not")
              );
              this.neededAmount = this.$n(
                error.substring(
                  error.indexOf(". ") + 2,
                  error.indexOf(" needed")
                ),
                "currency"
              );
              this.errorDialog = true;
            }
          }
        })
        .finally(() => (this.loadingRequest = false));
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
