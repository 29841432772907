<template>
  <v-container v-if="!isLoading">
    <a ref="hiddenDownloader" class="d-none" />
    <v-row justify="space-between" no-gutters>
      <v-col cols="12" md="6" lg="2" align="left">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="2"
        class="text-left"
        align="right"
        v-if="movementsList.length > 0"
      >
        <v-select
          :label="$t('wallet.movements.items_per_page')"
          :items="pageItemsOptions"
          item-text="text"
          item-value="value"
          v-model="pagination.itemsPerPage"
          @input="onItemsPerPageChange"
        ></v-select>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" v-if="movementsList.length > 0">
      <v-expansion-panel v-for="(movement, i) in movementsList" :key="i">
        <v-expansion-panel-header @click="fetchMovementDetail(i)">
          <v-row class="align-center">
            <v-col cols="1">
              <v-icon>{{
                movement.type === "PAYMENT" ? "mdi-cash-minus" : "mdi-cash-plus"
              }}</v-icon>
            </v-col>
            <v-col cols="5">
              <v-row class="align-center">
                <v-col cols="6" class="text-right">
                  {{ $t("wallet.movements.amount") }}:
                </v-col>
                <v-col cols="6" :class="movement.type.toLowerCase()">
                  {{ movement.type === "PAYMENT" ? "-" : "+" }}
                  {{ $n(movement.amount, "currency") }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              {{ movement.date | dateTimeWithTz("medium") }}
            </v-col>
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="movement.invoiceId"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loadingRegistry"
                    :disabled="loadingRegistry"
                    fab
                    dark
                    small
                    color="primary"
                    @click.stop="print(movement.invoiceId)"
                  >
                    <v-icon> picture_as_pdf </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("wallet.actions.download-invoice") }}</span>
              </v-tooltip>
              <v-tooltip v-if="isAdmin && movement.refundable" top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn
                      :loading="loadingRegistry"
                      fab
                      dark
                      small
                      color="warning"
                      @click.stop="dialog = true"
                    >
                      <v-icon> mdi-cash-plus </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{ $t("wallet.refund.tooltip-text") }}</span>
              </v-tooltip>
              <modal-dialog
                v-if="dialog"
                :content="$t('wallet.refund.dialog-text')"
                :dialog="dialog"
                submitClass="success"
                :submitText="$t('wallet.refund.dialog-submit')"
                :title="$t('wallet.refund.dialog-title')"
                titleClass="warning white--text"
                titleIcon="mdi-alert"
                @submit="refund(movement.id)"
                @cancel="dialog = false"
              ></modal-dialog>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <movement-detail
            :value="movementsList[i]"
            :loading="loading[i]"
          ></movement-detail>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-col cols="12" class="text-center" v-if="movementsList.length > 0">
      <v-pagination
        v-model="pagination.page"
        :length="totalPages"
        @input="onPaginationChange"
      ></v-pagination>
    </v-col>
    <v-alert v-else text type="info" class="mt-4">
      {{ $t("wallet.movements.noDataText") }}
    </v-alert>
  </v-container>
  <loading-page v-else></loading-page>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import MovementDetail from "./components/MovementDetail";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { downloadFile } from "@/common/file-utils";
import ModalDialog from "@/components/modal_dialog/ModalDialog";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
const InvoiceEntityRepository = RepositoryFactory.get(
  "InvoiceEntityRepository"
);
export default {
  name: "MovementsList",
  components: { MovementDetail, ModalDialog, LoadingPage },
  props: {
    movements: {
      type: Array,
      required: true,
    },
    totalPages: {
      type: Number,
      required: false,
      default: 1,
    },
    pageSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loadingMovements: true, // Loader for the whole list
      loadingRegistry: false, // Loader for the export function
      loading: [], // Loader for specific movements
      movementsList: [],
      pagination: JSON.parse(JSON.stringify(this.pageSettings)),
      panel: [],
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isLoading() {
      return this.loadingMovements;
    },
    pageItemsOptions() {
      return [
        { text: "10", value: 10 },
        { text: "100", value: 100 },
        { text: this.$t("$vuetify.dataFooter.itemsPerPageAll"), value: -1 },
      ];
    },
  },
  watch: {
    movements: {
      handler(val) {
        this.loadingMovements = true;
        this.movementsList = JSON.parse(JSON.stringify(val));
        this.movementsList.forEach(() => this.loading.push(false));
        this.loadingMovements = false;
      },
      immediate: false,
    },
  },
  created() {
    if (this.$route.query.page) {
      this.pagination.page = parseInt(this.$route.query.page);
    }
    if (this.$route.query.pageSize) {
      this.pagination.itemsPerPage = parseInt(this.$route.query.pageSize);
    }
  },
  methods: {
    fetchMovementDetail(index) {
      let id = this.movementsList[index].id;
      let type = this.movementsList[index].type;
      this.$set(this.loading, index, true);
      WalletEntityRepository.getMovementById(id, type)
        .then((data) => this.$set(this.movementsList, index, data))
        .catch(() =>
          this.$log.debug("Error fetching movement " + type + " with ID " + id)
        )
        .finally(() => this.$set(this.loading, index, false));
    },
    onItemsPerPageChange() {
      if (this.$route.query.pageSize !== this.pagination.itemsPerPage) {
        this.pagination.page = 1;
      }
      this.panel = [];
      this.onPaginationChange();
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      this.redirect(query);
      this.$emit("change", this.pagination);
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
    },
    print(invoiceId) {
      this.loadingRegistry = true;
      return InvoiceEntityRepository.downloadPDF(invoiceId)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("wallet.invoice.download_success"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("wallet.invoice.not_found"),
              type: "warning",
            });
          }
          this.$log.debug("Error downloading invoice with id " + invoiceId);
        })
        .finally(() => (this.loadingRegistry = false));
    },
    refund(movementId) {
      this.loadingRegistry = true;
      WalletEntityRepository.refund(movementId)
        .then(() => {
          this.$emit("change", this.pagination);
          this.$notify({
            title: this.$t("wallet.refund.success"),
            type: "success",
          });
        })
        .catch((error) => {
          this.$log.debug("Error on payment refund: " + error);
          this.$notify({
            title: this.$t("wallet.refund.error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => {
          this.loadingRegistry = false;
          this.dialog = false;
        });
    },
  },
};
</script>

<style scoped>
.charge,
.refund {
  color: green;
}
.payment {
  color: red;
}
</style>
