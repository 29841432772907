export default [
  {
    text: "levelTestRequest.state.REQUESTED",
    value: "REQUESTED",
  },
  {
    text: "levelTestRequest.state.APPROVED",
    value: "APPROVED",
  },
  {
    text: "levelTestRequest.state.REJECTED",
    value: "REJECTED",
  },
];
