import Vue from "vue";
import i18n from "@/plugins/i18n";
import {
  dateArrayToDateWithTimezone,
  utcDateArrayToDateWithTimezone,
} from "@/common/conversion-utils";

// Date filter
// TODO: REMOVE IT!! Only used in modules folder
const dateFormat = function (date) {
  if (!date) return "";
  return date.slice().reverse().join("/");
};

// TODO: REMOVE IT!! Only used in Usermanagementlist component, because the date is a timestamp (not a LocalDateTime)
const zonedDateFormat = function timeConverter(UNIX_timestamp) {
  if (!UNIX_timestamp) return "";
  return i18n.d(new Date(UNIX_timestamp * 1000));
};

// TODO: REMOVE IT!! Only used in modules folder
const dateTimeFormat = function (dateTime) {
  if (!dateTime) return "";
  return new Date(
    dateTime[0],
    dateTime[1] - 1,
    dateTime[2],
    dateTime[3],
    dateTime[4]
  ).toLocaleString();
};

const uppercase = function (string) {
  return string.toUpperCase();
};

const dateTimeWithTz = function (date, type = "short") {
  return i18n.d(utcDateArrayToDateWithTimezone(date), type);
};

const dateTimeWithoutTz = function (date, type = "short") {
  return i18n.d(dateArrayToDateWithTimezone(date), type);
};

const filters = [
  { name: "dateFormat", execute: dateFormat },
  { name: "dateTimeFormat", execute: dateTimeFormat },
  { name: "zonedDateFormat", execute: zonedDateFormat },
  { name: "uppercase", execute: uppercase },
  { name: "dateTimeWithTz", execute: dateTimeWithTz },
  { name: "dateTimeWithoutTz", execute: dateTimeWithoutTz },
];

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});
