class Grid {
  constructor(row, column) {
    this.row = row;
    this.column = column;
  }
}

class Layout {
  constructor(cellWidth, cellHeight, cellArea, column, row) {
    this.cellWidth = cellWidth;
    this.cellHeight = cellHeight;
    this.cellArea = cellArea;
    this.column = column;
    this.row = row;
  }
}

class CellLayout {
  constructor({ width, height, x, y, quality }) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.quality = quality;
  }

  toOptionsObject() {
    return {
      canvasSize: {
        width: this.width,
        height: this.height,
      },
      position: [this.x, this.y],
      quality: this.quality,
    };
  }
}

const VideoQuality = {
  Video_90P: 0,
  Video_180P: 1,
  Video_360P: 2,
  Video_720P: 3,
};

const layoutCandidates = Array.from({ length: 9 })
  .map((value, index) => {
    const count = index + 1;
    const mid = Math.ceil(count / 2);
    const candidates = Array.from({ length: mid })
      .map((v, i) => {
        const row = i + 1;
        const column = Math.ceil(count / row);
        if (row < column) {
          return [
            {
              row,
              column,
            },
            {
              row: column,
              column: row,
            },
          ];
        }
        if (row === column) {
          return [
            {
              row,
              column,
            },
          ];
        }
        return [];
      })
      .reduce((prev, curr) => [...prev, ...curr], []);
    return { count, candidates };
  })
  .reduce((prev, curr) => ({ ...prev, [curr.count]: curr.candidates }), {});

const aspectRatio = 16 / 9;
//const minCellWidth = 256; valor original
const minCellWidth = 60;
const minCellHeight = minCellWidth / aspectRatio;
const cellOffset = 1;
const maxCount = 9;
const maxRowsColumns = (width, height) => ({
  maxColumns: Math.max(1, Math.floor(width / (minCellWidth + cellOffset * 2))),
  maxRows: Math.max(1, Math.floor(height / (minCellHeight + cellOffset * 2))),
});
export function maxViewportVideoCounts(width, height) {
  const { maxRows, maxColumns } = maxRowsColumns(width, height);
  return maxRows * maxColumns;
}

//: CellLayout[]
export function getVideoLayout(rootWidth, rootHeight, count) {
  /**
   * [1,count]
   */
  if (count > maxCount || count === 0) {
    return [];
  }
  let { maxRows, maxColumns } = maxRowsColumns(rootWidth, rootHeight);
  maxRows = Math.min(maxRows, count);
  maxColumns = Math.min(maxColumns, count);
  const actualCount = Math.min(count, maxRows * maxColumns);
  const layoutOfCount = layoutCandidates[actualCount].filter(
    (item) => item.row <= maxRows && item.column <= maxColumns
  );
  const preferredLayout = layoutOfCount
    .map((item) => {
      const { column, row } = item;
      const canonical = Math.floor(
        Math.min(rootWidth / (16 * column), rootHeight / (9 * row))
      );
      const cellWidth = canonical * 16 - cellOffset * 2;
      const cellHeight = canonical * 9 - cellOffset * 2;
      return {
        cellWidth,
        cellHeight,
        cellArea: cellWidth * cellHeight,
        column,
        row,
      };
    })
    .reduce(
      (prev, curr) => {
        if (curr.cellArea > prev.cellArea) {
          return curr;
        }
        return prev;
      },
      { cellArea: 0, cellHeight: 0, cellWidth: 0, column: 0, row: 0 }
    );
  const { cellWidth, cellHeight, column, row } = preferredLayout;
  const cellBoxWidth = cellWidth + cellOffset * 2;
  const cellBoxHeight = cellHeight + cellOffset * 2;
  const horizontalMargin = (rootWidth - cellBoxWidth * column) / 2 + cellOffset;
  const verticalMargin = (rootHeight - cellBoxHeight * row) / 2 + cellOffset;
  const cellDimensions = [];
  const lastRowColumns = column - ((column * row) % actualCount);
  const lastRowMargin =
    (rootWidth - cellBoxWidth * lastRowColumns) / 2 + cellOffset;
  let quality = VideoQuality.Video_90P;

  if (actualCount <= 4 && cellBoxHeight >= 510) {
    // GROUP HD
    quality = VideoQuality.Video_720P;
  } else if (actualCount <= 4 && cellHeight >= 270) {
    quality = VideoQuality.Video_360P;
  } else if (actualCount > 4 && cellHeight >= 180) {
    quality = VideoQuality.Video_180P;
  }
  for (let i = 0; i < row; i++) {
    for (let j = 0; j < column; j++) {
      const leftMargin = i !== row - 1 ? horizontalMargin : lastRowMargin;
      if (i * column + j < actualCount) {
        cellDimensions.push(
          new CellLayout({
            width: cellWidth,
            height: cellHeight,
            x: Math.floor(leftMargin + j * cellBoxWidth),
            y: Math.floor(verticalMargin + (row - i - 1) * cellBoxHeight),
            quality,
          })
        );
      }
    }
  }
  return cellDimensions;
}

export function getVideoLayoutManual(rootWidth, rootHeight, count) {
  if (count > maxCount || count === 0) {
    return [];
  }
  const cellDimensions = [];

  if (count == 1) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth,
        height: rootHeight,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_360P,
      })
    );
    return cellDimensions;
  }

  if (count == 2) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth,
        height: rootHeight / 2,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_180P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth,
        height: rootHeight / 2,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_180P,
      })
    );
  }

  if (count == 3) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth,
        height: rootHeight / 2,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_180P,
      })
    );
  }

  if (count == 4) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: rootWidth / 2,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
  }

  if (count == 5) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth,
        height: rootHeight / 2,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
  }

  if (count == 6) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 2,
        height: rootHeight / 2,
        x: rootWidth / 2,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
  }

  if (count == 7) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
  }
  if (count == 8) {
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2 + rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 2,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: rootHeight / 4,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: 0,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
    cellDimensions.push(
      new CellLayout({
        width: rootWidth / 4,
        height: rootHeight / 4,
        x: rootWidth / 2,
        y: 0,
        quality: VideoQuality.Video_90P,
      })
    );
  }

  return cellDimensions;
}

// no se usa
function getPosition(canvasSize, numberOfUsers, positionOfUser) {
  if (numberOfUsers < 2)
    return {
      canvasSize,
      position: [0, 0],
    };
  if (numberOfUsers == 2) {
    const userCanvasSize = {
      width: canvasSize.width / 2,
      height: canvasSize.height,
    };
    let userPosition =
      positionOfUser == 1 ? [0, 0] : [userCanvasSize.width + 1, 0];
    if (canvasSize.width / canvasSize.height < 16 / 9) {
      userCanvasSize.width = canvasSize.width;
      userCanvasSize.height = canvasSize.height / 2;
      userPosition =
        positionOfUser == 1 ? [0, userCanvasSize.height + 1] : [0, 0];
    }
    return {
      canvasSize: userCanvasSize,
      position: userPosition,
    };
  }
  if (numberOfUsers == 3) {
    const offsetHeight = canvasSize.height / 2;
    if (positionOfUser < 3) {
      const position = getPosition(
        {
          width: canvasSize.width,
          height: canvasSize.height - offsetHeight,
        },
        2,
        positionOfUser
      );
      position.position[1] += offsetHeight;
      return position;
    }
    return getPosition(
      {
        width: canvasSize.width,
        height: canvasSize.height - offsetHeight,
      },
      1,
      positionOfUser
    );
  }
  if (numberOfUsers == 4) {
    const offsetHeight = canvasSize.height / 2;
    if (positionOfUser < 3) {
      const position = getPosition(
        {
          width: canvasSize.width,
          height: canvasSize.height - offsetHeight,
        },
        2,
        positionOfUser
      );
      position.position[1] += offsetHeight;
      return position;
    }
    return getPosition(
      {
        width: canvasSize.width,
        height: canvasSize.height - offsetHeight,
      },
      2,
      positionOfUser - 2
    );
  }
  if (numberOfUsers < 5) {
    const userCanvasSize = {
      width: canvasSize.width / 2,
      height: canvasSize.height / 2,
    };
    let userPosition;
    switch (positionOfUser) {
      case 1:
        userPosition = [0, userCanvasSize.height + 1];
        break;
      case 2:
        userPosition = [userCanvasSize.width + 1, userCanvasSize.height + 1];
        break;
      case 3:
        userPosition = [0, 0];
        break;
      case 4:
        userPosition = [userCanvasSize.width + 1, 0];
        break;
    }
    return {
      canvasSize: userCanvasSize,
      position: userPosition,
    };
  }
}
