import store from "@/common/store";

const isConnected = () => {
  return store.state.websocket.connected;
};

export function mapWsConnectionGetter(options) {
  const object = {};
  options.forEach((option) => {
    object[option] = eval(option);
  });
  return object;
}
