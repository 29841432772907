<template>
  <v-dialog v-model="dialog" width="40%">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-title class="error white--text">
        <v-row no-gutters>
          <v-col>
            {{ $t("edition.headers.close_edition") }}
          </v-col>
          <v-col class="text-right">
            <v-btn icon>
              <v-icon color="white" @click="dialog = false"> close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <day-picker
              dense
              :label="$t('edition.prop.end_date')"
              outlined
              v-model="date"
              :past-dates="false"
              :future-dates="true"
              :minimum-date="minDate"
            ></day-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto mr-auto error" @click="onSave" :disabled="!date">
          {{ $t("actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import { dateToDateString } from "@/common/conversion-utils";

export default {
  components: { DayPicker },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      date: null,
    };
  },
  computed: {
    minDate() {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      return dateToDateString(date);
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value: {
      handler(newVal) {
        this.dialog = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.$emit("save", this.date);
    },
  },
};
</script>

<style></style>
