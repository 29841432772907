<template>
  <v-dialog v-model="dialog" width="65%">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        {{ $t("wallet.transfer_dialog.title") }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <v-row>
                <number-field
                  type="double"
                  v-model="transfer.amount"
                  :label="$t('wallet.transfer_dialog.amount')"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('wallet.form.validation.amount_required'),
                    (v) =>
                      parseInt(v) >= 5 ||
                      $t('wallet.form.validation.amount_min', {
                        amount: minAmount,
                        currency: currency,
                      }),
                    (v) =>
                      parseInt(v) <= balance ||
                      $t('wallet.form.validation.balance_exceeded'),
                    (v) =>
                      parseInt(v) <= 200 ||
                      $t('wallet.form.validation.amount_max', {
                        amount: maxAmount,
                        currency: currency,
                      }),
                  ]"
                ></number-field>
              </v-row>
              <v-row>
                <autocomplete
                  :items="targets"
                  v-model="transfer.target"
                  :label="$t('wallet.transfer_dialog.student')"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('wallet.form.validation.student_required'),
                  ]"
                  :item-text="(item) => item.name + ' ' + item.surname"
                  item-value="userId"
                  :multiple="multipleTargets"
                ></autocomplete>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!validForm"
          :loading="loading"
          class="mx-4 success"
          @click="$emit('save', transfer)"
        >
          <v-icon>mdi-swap-horizontal</v-icon>
          {{ $t("wallet.actions.exec_transfer") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumberField from "@/components/number-field/NumberField.vue";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";

export default {
  name: "TransferDialog",
  components: { NumberField, Autocomplete },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    balance: {
      type: Number,
      required: true,
    },
    multipleTargets: {
      type: Boolean,
      required: false,
      default: false,
    },
    targets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      transfer: {
        amount: null,
        target: null,
      },
      loading: false,
      currency: "€",
      maxAmount: 200,
      minAmount: 5,
      validForm: false,
    };
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value: {
      handler(newVal, oldVal) {
        this.dialog = newVal;
        // reset form state
        if (newVal != oldVal)
          this.transfer = {
            amount: null,
            target: null,
          };
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
