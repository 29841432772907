<template>
  <div>
    <company-form
      v-if="!loading"
      v-model="entity"
      @save="save"
      @back="back"
    ></company-form>
    <loading-page v-if="loading"></loading-page>
  </div>
</template>

<script>
import CompanyForm from "@/mockups/company/components/CompanyForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { deleteTempFile } from "@/common/file-utils";
import auth from "@/common/auth.js";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const CompanyEntityRepository = RepositoryFactory.get(
  "CompanyEntityRepository"
);

export default {
  name: "CompanyInfoFormTab",
  components: {
    LoadingPage,
    CompanyForm,
  },
  props: {
    companyId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      entity: {},
      loading: false,
    };
  },
  created() {
    this._fetchData(this.companyId);
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "photo");
  },
  methods: {
    _fetchData(id) {
      if (id) {
        this.loading = true;
        return CompanyEntityRepository.get(id)
          .then((response) => {
            this.entity = response;
          })
          .catch(() => this.$log.debug("Error fetching company with ID: " + id))
          .finally(() => (this.loading = false));
      } else {
        this.entity = {
          name: this.user.firstName,
          surname: this.user.lastName,
          mainEmail: this.user.email,
        };
      }
    },
    back() {
      const route = this.$route.params.id
        ? "CompanyView Detail"
        : "ProfileCompanyView Detail";
      this.$router.push({
        name: route,
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      this.checkSecondaryEmail();
      CompanyEntityRepository.save(this.entity)
        .then(() => {
          const route = this.$route.params.id
            ? "CompanyView Detail"
            : "ProfileCompanyView Detail";
          this.entity.photo = null;
          auth.reloadUserData().finally(() => {
            this.$router.push({
              name: route,
              params: {
                backAction: this.$route.params.backPrevious,
              },
            });
          });
        })
        .catch(() => this.$log.debug("Error saving company: " + this.entity))
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.entity.secondaryEmail =
        this.entity.secondaryEmail == ""
          ? undefined
          : this.entity.secondaryEmail;
    },
  },
};
</script>
