<template>
  <v-app>
    <MenuBar v-on:open-chat="openChat"></MenuBar>
    <v-main id="main">
      <slot class="content" />
    </v-main>
    <ChatContainer
      v-if="isLogged && !isGuest && isConnected"
      ref="chatLayer"
      :chatId="chatId"
    ></ChatContainer>
    <Footer></Footer>
  </v-app>
</template>

<script>
import MenuBar from "@/components/menu-bar/MenuBar";
import Footer from "@/components/footer/Footer";
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import { mapWsConnectionGetter } from "@/common/mapWsConnectionGetter";
import ChatContainer from "@/components/chat/ChatContainer.vue";
import usersHome from "@/enumerates/UsersHome";

export default {
  name: "AppLayoutDefault",
  components: {
    MenuBar,
    Footer,
    ChatContainer,
  },
  data() {
    return {
      user: auth.getUser(),
      webSocket: auth.getWebsocket(),
      chatId: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged", "isGuest"]),
    ...mapWsConnectionGetter(["isConnected"]),
  },
  mounted() {
    this.$EventBus.$on("open-chat", (data) => {
      this.openChat(data);
    });
  },
  watch: {
    "$route.name"(val) {
      if (
        Object.values(usersHome).indexOf(val) !== -1 &&
        this.$refs.chatLayer &&
        this.$refs.chatLayer.isOpened()
      ) {
        this.$refs.chatLayer.onCloseWindow();
      }
    },
  },
  methods: {
    openChat(data) {
      if (this.$refs.chatLayer) {
        if (!this.$refs.chatLayer.isOpened() || data != null) {
          this.$refs.chatLayer.init(data);
          this.chatId = data;
        } else {
          this.$refs.chatLayer.onCloseWindow();
        }
      }
    },
  },
};
</script>
