<template>
  <div class="clrm-settings">
    <div class="clrm-settings__camera" v-if="hasCameras">
      <div class="clrm-settings__camera__preview">
        <PreviewOwnCameraReproduction :device="selectedCamera" />
      </div>
      <div class="clrm-settings__field">
        <div
          class="clrm-settings__label"
          :class="{
            'clrm-settings__label--student': classroomMode === 'student',
          }"
        >
          {{ $t("virtual-classroom.cameras") }}
        </div>
        <DeviceDropDown
          :devices="cameras"
          :selected="selectedCamera"
          @switch-device="switchCamera"
        />
      </div>
    </div>
    <div class="clrm-settings__nodevice" v-else>
      {{ $t("classroom.discussion.no-cams") }}
    </div>
    <div class="clrm-settings__micro" v-if="hasMics">
      <div class="clrm-settings__micro__preview">
        <progress
          v-if="localAudioTrack && localAudioTrack.isAudioStarted"
          id="progress"
          max="100"
          color="amber"
          height="25"
        ></progress>
      </div>
      <div class="clrm-settings__field">
        <div
          class="clrm-settings__label"
          :class="{
            'clrm-settings__label--student': classroomMode === 'student',
          }"
        >
          {{ $t("virtual-classroom.microphones") }}
        </div>
        <DeviceDropDown
          :devices="microphones"
          :selected="selectedMicrophone"
          @switch-device="switchMic"
        />
      </div>
    </div>
    <div class="clrm-settings__nodevice" v-else>
      {{ $t("classroom.discussion.no-mics") }}
    </div>
    <div class="clrm-settings__buttons">
      <button class="clrm-settings__button__join" @click="$emit('end-preview')">
        {{ $t("classroom.toolbar.join") }}
      </button>
      <button
        class="clrm-settings__button__join"
        @click="$emit('cancel-preview')"
      >
        {{ $t("actions.cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
import DeviceDropDown from "../DeviceDropDown.vue";
import PreviewOwnCameraReproduction from "./PreviewOwnCameraReproduction.vue";
import VideoSDK from "@zoom/videosdk";
import {
  mapClassroomStateGetter,
  getClassroomState,
} from "../store/mapClassroomStateGetter";
import classroomStateGetter from "../store/classroomStateGetter";

export default {
  name: "PreviewDevices",
  components: {
    DeviceDropDown,
    PreviewOwnCameraReproduction,
  },
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localAudioTrack: null,
      intervalId: null,
      state: getClassroomState(),
    };
  },
  computed: {
    ...mapClassroomStateGetter([
      "cameras",
      "microphones",
      "selectedCamera",
      "selectedMicrophone",
    ]),
    hasCameras() {
      return this.cameras.length > 0;
    },
    hasMics() {
      return this.microphones.length > 0;
    },
  },
  beforeDestroy() {
    if (this.localAudioTrack != null) {
      this.localAudioTrack.stop();
    }
    this.stopInterval();
  },
  methods: {
    async restartMicPreview() {
      if (this.microphones.length <= 0) {
        return;
      }
      if (this.localAudioTrack) {
        this.localAudioTrack.stop();
      }
      this.localAudioTrack = VideoSDK.createLocalAudioTrack(
        classroomStateGetter.getSelectedMicrophone()
      );
      await this.localAudioTrack.start();
      await this.localAudioTrack.unmute();
      this.stopInterval();
      this.intervalId = setInterval(() => {
        document.getElementById("progress").value =
          this.localAudioTrack.getCurrentVolume() * 100;
      }, 500);
    },
    switchCamera(camera) {
      this.zoom.switchCamera(camera);
    },
    switchMic(mic) {
      this.zoom.switchMicrophone(mic);
      this.restartMicPreview();
    },
    stopInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
};
</script>
