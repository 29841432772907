/**
 * Palette colors to use thorough the app
 */
export default [
  {
    backgroundColor: "#50514F",
    textColor: "white",
  },
  {
    backgroundColor: "#F25F5C",
    textColor: "white",
  },
  {
    backgroundColor: "#FFE066",
    textColor: "black",
  },
  {
    backgroundColor: "#247BA0",
    textColor: "white",
  },
  {
    backgroundColor: "#70C1B3",
    textColor: "black",
  },
];
