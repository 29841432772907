<template>
  <v-dialog persistent width="500" :value="dialog">
    <v-card v-if="dialog">
      <v-card-title primary-title class="headline error white--text">
        <v-icon class="mr-4 error white--text"> delete </v-icon>
        {{ $t("registration.modal.date_dialog.title") }}
      </v-card-title>
      <v-card-text class="mt-4">
        <label v-html="$t('registration.modal.date_dialog.content')"></label>
        <label style="color: red"> {{ $t(warning) }}</label>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-form v-model="form" ref="form">
          <day-picker
            v-model="dateSelected"
            calendarColor="error"
            futureDates
            :label="$t('registration.prop.withdrawConfDate')"
            outlined
            :pastDates="false"
            :rules="datePickerRules"
          ></day-picker>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('cancel')">
          {{ $t("actions.cancel") }}
        </v-btn>
        <v-btn
          class="mx-4 error"
          :disabled="!form"
          @click="dropSelectedRegistration"
        >
          {{ $t("actions.accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { dateArrayToDate } from "@/common/conversion-utils";

const RegistrationEntityRepository = RepositoryFactory.get(
  "RegistrationEntityRepository"
);

const textToDateArray = (v) =>
  v
    .split("/")
    .map((el) => parseInt(el))
    .reverse();

/**
 * Events emitted: cancel, submit
 */
export default {
  name: "WithdrawDialog",
  components: { DayPicker },
  props: {
    registration: {
      required: true,
    },
  },
  data() {
    return {
      dateSelected: null,
      dialog: true,
      form: null,
      lastDayOfMonth: null,
      warning: "",
    };
  },
  computed: {
    datePickerRules() {
      let rules = [(v) => !!v || this.$t("registration.error.required")];
      const today = new Date();

      if (today.getUTCDate() > 24) {
        rules.push(
          (v) =>
            !v ||
            new Date(textToDateArray(v)).getUTCMonth() !==
              today.getUTCMonth() ||
            this.$t("registration.error.invalid_date")
        );
      }
      if (this.registration.edition && this.registration.edition.endDate) {
        rules.push(
          (v) =>
            !v ||
            new Date(textToDateArray(v)).getTime() <
              dateArrayToDate(this.registration.edition.endDate).getTime() ||
            this.$t("registration.error.invalid_date_after_end")
        );
      }
      // Rule to check that the selected date is no later than 3 months
      rules.push(
        (v) =>
          !v ||
          today.getTime() +
            7889400000 -
            new Date(textToDateArray(v)).getTime() >
            0 ||
          this.$t("registration.error.invalid_date_too_far")
      );
      return rules;
    },
  },
  created() {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    /**
     * If the withdrawal is requested before the 25th, it is allowed to indicate a date
     * of the current month. Otherwise, you must indicate one of the following month
     */
    const today = new Date();
    if (today.getUTCDate() > 24) {
      this.lastDayOfMonth = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth() + 2,
        0
      );
      this.warning = "registration.modal.warning.cannot_request";
    } else {
      this.lastDayOfMonth = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth() + 1,
        0
      );
      this.warning = "registration.modal.warning.not_refundable";
    }
    this.lastDayOfMonth = this.lastDayOfMonth
      .toLocaleDateString(undefined, options)
      .split("/")
      .reverse()
      .map((v) => parseInt(v));

    this.dateSelected = this.lastDayOfMonth;
  },
  methods: {
    dropSelectedRegistration() {
      const modifiedRegistration = {
        ...this.registration,
        withdrawConfDate: this.dateSelected,
      };
      RegistrationEntityRepository.requestDismissStudentRegistration(
        modifiedRegistration
      )
        .then((res) => {
          this.$notify({
            title: this.$t(
              "registration.messages.withdrawal_requested_success"
            ),
            type: "success",
          });
          this.$emit("requested", res);
        })
        .catch(() => {
          this.$log.debug(
            "Error trying to withdrawal registration with id " +
              modifiedRegistration.id
          );
          this.$notify({
            title: this.$t("registration.messages.withdrawal_requested_error"),
            type: "error",
            duration: 30000,
          });
        });
    },
  },
};
</script>
