<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn @click="back">
          <v-icon>mdi-arrow-left</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("actions.cancel") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <file-form :userId="userId" @save="back"></file-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import FileForm from "@/mockups/components/FileForm";
export default {
  name: "StudentDocumentFormTab",
  components: { FileForm },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isTutor"]),
  },
  methods: {
    back() {
      if (this.isAdmin) {
        this.$router.push({
          name: "StudentDocumentsTab",
          params: { backPrevious: true },
        });
      } else if (this.isTutor) {
        this.$router.push({
          name: "Tutor StudentDocumentsTab",
          params: { backPrevious: true },
        });
      } else {
        this.$router.push({
          name: "ProfileStudentDocumentsTab",
          params: { backPrevious: true },
        });
      }
    },
  },
};
</script>
