<template>
  <v-container v-if="!isLoadingPage" fluid>
    <v-card class="card-datatable">
      <v-card-title class="pb-0">
        <v-row justify="space-between" no-gutters>
          <v-col cols="auto" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-row>
              <v-col cols="12" md="2" offset-md="2">
                <v-checkbox
                  dense
                  v-model="showPast"
                  :disabled="underMinimumStudents"
                  :label="$t('edition.filters.show_past_editions')"
                  class="mt-0 no-split-words"
                  @change="redirectOnFilterChange"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  dense
                  v-model="underMinimumStudents"
                  :label="$t('edition.filters.under_minimum_students')"
                  class="mt-0 no-split-words"
                  @change="redirectOnFilterChange"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  dense
                  no-filter
                  solo
                  clearable
                  :debouncing="300"
                  @input="redirectOnFilterChange"
                  :items="students.items"
                  :loading="students.loading"
                  v-model="studentFilter"
                  :search-input.sync="studentSearch"
                  :label="$t('student.name')"
                  :item-text="fullName"
                  item-value="id"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <autocomplete
                  dense
                  no-filter
                  solo
                  clearable
                  :debouncing="300"
                  @input="redirectOnFilterChange"
                  :items="teachers.items"
                  :loading="teachers.loading"
                  v-model="teacherFilter"
                  :search-input.sync="teacherSearch"
                  :label="$t('teacher.name')"
                  :item-text="fullName"
                  item-value="id"
                >
                </autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <autocomplete
                  dense
                  no-filter
                  solo
                  clearable
                  :debouncing="300"
                  @change="redirectOnFilterChange"
                  :items="products.items"
                  :loading="products.loading"
                  v-model="productFilter"
                  :search-input.sync="productSearch"
                  :label="$t('product.name')"
                  item-text="title"
                  item-value="id"
                >
                </autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-data-table
            class="rows-clickable"
            :footer-props="tableFooterProps"
            :headers="headers"
            :items="editions"
            :item-class="rowClass"
            :loading="isLoadingTable"
            :options="pagination"
            :server-items-length="totalItems"
            @update:options="redirectOnTableChange"
            @click:row="entityDetail"
          >
            <template v-slot:[`item.productType`]="{ item }">
              <span v-if="item.productType">
                {{ $t(`product.product_type.${item.productType}`) }}
              </span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <span v-if="item.startDate">
                {{ item.startDate | dateTimeWithTz("short") }}
              </span>
            </template>
            <template v-slot:[`item.endDate`]="{ item }">
              <span v-if="item.endDate">
                {{ item.endDate | dateTimeWithTz("short") }}
              </span>
            </template>
            <template v-slot:[`item.paymentType`]="{ item }">
              <span>{{ $t(`product.payment_type.${item.paymentType}`) }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
  <loading-page v-else></loading-page>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { localDateToISOString } from "@/common/conversion-utils";
import moment from "moment-timezone";
import auth from "@/common/auth";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

export default {
  components: {
    Autocomplete,
    LoadingPage,
  },
  data() {
    return {
      editions: [],
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      loadingPage: true,
      totalItems: 0,
      loadingTable: false,
      studentFilter: null,
      studentSearch: null,
      teacherFilter: null,
      teacherSearch: null,
      underMinimumStudents: false,
      showPast: false,
      productFilter: null,
      productSearch: null,
      products: {
        items: [],
        loading: false,
      },
      students: {
        items: [],
        loading: false,
      },
      teachers: {
        items: [],
        loading: false,
      },
      currentDate: null,
      user: auth.getUser(),
      tableFooterProps,
    };
  },
  computed: {
    isLoadingPage() {
      return this.loadingPage;
    },
    isLoadingTable() {
      return this.loadingTable;
    },
    headers() {
      return [
        {
          text: this.$t("product.name"),
          sortable: false,
          value: "title",
        },
        {
          text: this.$t("product.prop.productType"),
          sortable: false,
          value: "productType",
        },
        {
          text: this.$t("product.prop.start_date"),
          value: "startDate",
        },
        {
          text: this.$t("product.prop.end_date"),
          value: "endDate",
        },
        {
          text: this.$t("product.prop.classes_per_week"),
          value: "classesPerWeek",
        },
        {
          text: this.$t("product.payment_type.label"),
          value: "paymentType",
        },
        {
          text: this.$t("edition.prop.price"),
          value: "price",
        },
        {
          text: this.$t("teacher.name") + " " + this.$t("profile.fields.name"),
          sortable: false,
          value: "teacherName",
        },
        {
          text:
            this.$t("teacher.name") + " " + this.$t("profile.fields.surname"),
          sortable: false,
          value: "teacherSurname",
        },
      ];
    },
  },
  watch: {
    teacherSearch() {
      this.getTeacherItems();
    },
    studentSearch() {
      this.getStudentItems();
    },
    productSearch() {
      this.getProductItems();
    },
  },
  created() {
    this.loadingPage = true;
    //Setting route params
    if (this.$route.query.page) {
      this.pagination.page = parseInt(this.$route.query.page);
    }
    if (this.$route.query.pageSize) {
      this.pagination.itemsPerPage = parseInt(this.$route.query.pageSize);
    }
    if (this.$route.query.studentFilter) {
      let value = parseFloat(this.$route.query.studentFilter);
      this.studentFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.teacherFilter) {
      let value = parseFloat(this.$route.query.teacherFilter);
      this.teacherFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.productFilter) {
      let value = parseFloat(this.$route.query.productFilter);
      this.productFilter = isNaN(value) ? null : value;
    }
    if (this.$route.query.underMinimumStudents) {
      this.underMinimumStudents =
        this.$route.query.underMinimumStudents === "true";
    }
    if (this.$route.query.showPast) {
      if (this.$route.query.underMinimumStudents) {
        this.showPast = false;
      } else {
        this.showPast = this.$route.query.showPast === "true";
      }
    }

    this.currentDate = moment.tz(this.user.timeZone).toISOString().slice(0, 10);
    Promise.all([
      this.getItems(),
      this.getProductItems(),
      this.getStudentItems(),
      this.getTeacherItems(),
    ]).then(() => (this.loadingPage = false));
  },

  methods: {
    getItems() {
      this.loadingTable = true;
      const sortMapping = {
        paymentType: "payment_type",
        classesPerWeek: "classes_per_week",
        startDate: "start_date",
        endDate: "end_date",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          studentId: this.studentFilter,
          teacherId: this.teacherFilter,
          productId: this.productFilter,
          underMinimumStudents: this.underMinimumStudents,
          showPast: this.showPast,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      return EditionEntityRepository.getAllByAdmin(options)
        .then((response) => {
          this.editions = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error getting all editions"))
        .finally(() => (this.loadingTable = false));
    },
    getProductItems() {
      this.products.loading = true;
      const options = {
        params: {
          search: this.productSearch,
        },
      };
      return ProductEntityRepository.getAll(options)
        .then((response) => (this.products.items = response.content))
        .catch(() =>
          this.$log.debug("Error fetching products with params: " + options)
        )
        .finally(() => (this.products.loading = false));
    },
    getStudentItems() {
      this.students.loading = true;
      const options = {
        params: {
          search: this.studentSearch,
        },
      };
      return StudentEntityRepository.getAllWithFullNameSearch(options)
        .then((response) => (this.students.items = response))
        .catch(() =>
          this.$log.debug("Error fetching students with params: " + options)
        )
        .finally(() => (this.students.loading = false));
    },

    getTeacherItems() {
      this.teachers.loading = true;
      const options = {
        params: {
          search: this.teacherSearch,
        },
      };
      return TeacherEntityRepository.getAllWithFullNameSearch(options)
        .then((response) => (this.teachers.items = response))
        .catch(() =>
          this.$log.debug("Error fetching teachers with params: " + options)
        )
        .finally(() => (this.teachers.loading = false));
    },

    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Admin Edition Detail",
          params: {
            editionId: entity.id,
            backPrevious: true,
          },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      query.studentFilter = this.studentFilter
        ? this.studentFilter.toString()
        : undefined;
      query.teacherFilter = this.teacherFilter
        ? this.teacherFilter.toString()
        : undefined;
      query.productFilter = this.productFilter
        ? this.productFilter.toString()
        : undefined;
      query.underMinimumStudents = this.underMinimumStudents
        ? this.underMinimumStudents.toString()
        : undefined;

      if (this.underMinimumStudents) {
        query.showPast = undefined;
        this.showPast = false;
      } else if (this.showPast) {
        query.showPast = this.showPast.toString();
      } else query.showPast = undefined;

      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    fullName(item) {
      return item.name + " " + item.surname;
    },
    rowClass(item) {
      if (item.endDate) {
        const formattedEndDate = localDateToISOString(item.endDate).slice(
          0,
          10
        );
        if (formattedEndDate < this.currentDate) {
          return "table-row-secondary-color";
        }
      }
    },

    myFunction() {
      alert("Hello");
    },
  },
};
</script>
