<template>
  <v-card v-if="resolved" class="form">
    <v-card-title class="justify-center mt-8" v-bind:class="styleCard">
      <h3>
        {{ $t("account.user_activation") }}
      </h3>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-row align="center" justify="center">
        <div>
          <v-icon v-if="success" color="success" x-large>done</v-icon>
          <v-icon v-if="!success" color="error" x-large>warning</v-icon>
          <span v-bind:class="styleText">{{ response }}</span>
        </div>
      </v-row>
      <v-row v-if="success" justify="center" class="mt-6">
        <span
          >{{ $t("account.notifications.now_you_can") }}
          <router-link to="login">{{
            $t("account.actions.log_in")
          }}</router-link>
          {{ $t("account.notifications.normally") }}
        </span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  data() {
    return {
      response: "",
      resolved: null,
      success: false,
      cardStyle: {
        color: "red",
      },
    };
  },
  created() {
    if (this.isLogged) {
      this.$notify({
        title: "Error",
        text: this.$t("account.errors.forbidden_access"),
        type: "error",
        duration: 30000,
      });
      this.$router.push({ name: "Unauthorized" });
    }
    this.resolved = false;
    this.key = this.$route.query.key;
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    styleCard() {
      if (this.success) {
        return "success white--text";
      } else return "error darken-1 white--text";
    },
    styleText() {
      if (this.success) {
        return "green--text";
      } else return "red--text";
    },
  },
  mounted() {
    AccountRepository.activateUser(this.$route.query.key)
      .then((resp) => {
        if (!resp.isAxiosError) {
          this.success = true;
          this.response = this.$t("account.notifications.account_activated");
        }
      })
      .catch((err) => {
        this.response = err;
      })
      .finally((this.resolved = true));
  },
};
</script>

<style scoped>
.form {
  width: 70%;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .form {
    width: 90%;
  }
}
</style>
