import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/students";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllWithFullNameSearch(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/findWithFullNameSearch`,
      options
    );
    return response.data;
  },

  async getAllStudentsOfTutor(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/getAllStudentsOfTutor/${id}`))
      .data;
  },

  async getAvailableStudentsForNewLink(options = {}) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/getAvailableStudentsForNewLink`, options)
    ).data;
  },

  async getAllStudentsOfCompany(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/getAllStudentsOfCompany/${id}`))
      .data;
  },

  async getAllByEdition(id) {
    const response = await HTTP.get(`${RESOURCE_NAME}/edition/${id}`);
    return response.data;
  },

  async getAvailableTeachers(studentIds, productRequestId) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/getAvailableStudents`, {
        params: {
          students: studentIds,
          productRequest: productRequestId,
        },
      })
    ).data;
  },

  async findAllStudentWithLectures() {
    return (await HTTP.get(`${RESOURCE_NAME}/findAllStudentWithLectures`)).data;
  },

  async getAllOfTeacher(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/selector`, options);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async getLectureStudentDetail(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/lecture`)).data;
  },

  async getSchedule(id, init, end) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/schedules`, {
        params: {
          init: init,
          end: end,
        },
      })
    ).data;
  },

  async getByUser() {
    return (await HTTP.get(`${RESOURCE_NAME}/getByUser`)).data;
  },

  async getLevelEvaluationActivityInteractions(studentId, lessonId) {
    return (
      await HTTP.get(
        `${RESOURCE_NAME}/${studentId}/test-activities/${lessonId}`
      )
    ).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async saveCandidate(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}/candidate`, entity)).data;
  },

  async registerCompanyStudent(student, key) {
    const response = await HTTP.post(
      `${RESOURCE_NAME}/company/student`,
      student,
      {
        params: { key: key },
      }
    );
    return response.data;
  },

  async approve(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/accept`)).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
