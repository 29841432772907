<template>
  <v-card flat>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn v-if="backBtn" @click="$emit('back')">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn
            :disabled="!validForm || image.loading"
            @click="$emit('save')"
            class="success ml-2"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Another fields -->

    <v-card-text>
      <v-form v-model="validForm">
        <v-row outlined>
          <v-col cols="12" sm="4" md="3" lg="3" xl="3">
            <image-edit v-model="entity"></image-edit>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9" xl="9">
            <v-row dense outlined>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  class="required"
                  :label="$t('profile.fields.name')"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                  v-model="entity.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  class="required"
                  :label="$t('profile.fields.surname')"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                  v-model="entity.surname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.displayName"
                  class="required"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                  :label="$t('profile.fields.display_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="6">
                <day-picker
                  v-model="entity.birthdate"
                  :future-dates="false"
                  :label="$t('profile.fields.birthdate')"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                  dense
                  outlined
                  pastDates
                  required
                ></day-picker>
              </v-col>
              <v-col cols="12" md="4" lg="6">
                <v-select
                  :items="genres"
                  dense
                  outlined
                  :label="$t('profile.fields.genre')"
                  v-model="entity.genre"
                  :item-text="translate"
                  class="required"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                ></v-select>
              </v-col>
              <v-col v-if="accountFields" cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.login"
                  maxlength="50"
                  prepend-inner-icon="person"
                  type="text"
                  class="required"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.username_required'),
                    (v) =>
                      !v ||
                      this.regex.LOGIN_REGEX.test(v) ||
                      $t('user_management.loginValid'),
                  ]"
                  :label="$t('profile.fields.login')"
                  @input="entity.login = entity.login.toLowerCase()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" :lg="accountFields ? 4 : 6">
                <v-text-field
                  dense
                  outlined
                  append-icon="email"
                  v-model="entity.mainEmail"
                  :label="$t('profile.fields.main_email')"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('teacher.error.required'),
                    (v) =>
                      !v ||
                      this.regex.EMAIL_REGEX.test(v) ||
                      $t('userData.error.regex.emailPattern'),
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" :lg="accountFields ? 4 : 6">
                <v-text-field
                  dense
                  outlined
                  append-icon="email"
                  v-model="entity.secondaryEmail"
                  :label="$t('profile.fields.secondary_email')"
                  :rules="[
                    (v) =>
                      !v ||
                      this.regex.EMAIL_REGEX.test(v) ||
                      $t('userData.error.regex.emailPattern'),
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="phone"
                  v-model="entity.mainPhone"
                  :label="$t('profile.fields.main_phone')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="phone"
                  v-model="entity.secondaryPhone"
                  :label="$t('profile.fields.secondary_phone')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" lg="12">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-home-city"
                  v-model="entity.address"
                  :label="$t('profile.fields.address')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-city"
                  v-model="entity.town"
                  :label="$t('profile.fields.town')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.postalcode"
                  :label="$t('profile.fields.postalcode')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  dense
                  outlined
                  v-model="entity.country"
                  :label="$t('profile.fields.country')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-badge-account-horizontal"
                  v-model="entity.nif"
                  :label="$t('profile.fields.passport')"
                  class="required"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <day-picker
                  v-model="entity.nifExpiration"
                  :label="$t('profile.fields.passport_validity')"
                  :pastDates="false"
                  dense
                  future-dates
                  outlined
                ></day-picker>
              </v-col>

              <v-col cols="12" md="4" lg="6">
                <time-zone-selector
                  dense
                  outlined
                  v-model="entity.timeZone"
                  class="required"
                  :rules="[(v) => !!v || $t('teacher.error.required')]"
                  :label="$t('profile.fields.timeZone')"
                  autocomplete="no"
                >
                </time-zone-selector>
              </v-col>
              <v-col cols="12" md="4" lg="6">
                <language-selector
                  dense
                  outlined
                  v-model="entity.langKey"
                  :rules="[]"
                  :label="$t('profile.fields.language')"
                  autocomplete="no"
                >
                </language-selector>
              </v-col>
              <v-row dense class="d-none d-md-flex">
                <v-col cols="12" sm="6" lg="6">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptEmails"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_emails')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" lg="6">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptWhats"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_whats')"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="6" lg="6">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.acceptCalls"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_calls')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" lg="6">
                  <v-switch
                    dense
                    outlined
                    v-model="entity.substituteAccepted"
                    class="ma-2 tp-0"
                    :rules="[]"
                    :label="$t('profile.fields.accept_substitution')"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-textarea
              dense
              outlined
              v-model="entity.aboutMe"
              type="text"
              :rules="[]"
              :label="$t('profile.fields.about_me')"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-switch
              dense
              outlined
              v-model="entity.acceptCommercialCommunications"
              :rules="[]"
              :label="$t('profile.fields.accept_commercial_communication')"
            ></v-switch>
          </v-col>
          <span v-if="!entity.id">
            <v-col cols="12">
              <v-switch
                class="required"
                dense
                outlined
                v-model="entity.acceptRGPD"
                :rules="[
                  (v) => v || $t('account.form.validation.rgpd_required'),
                ]"
                :label="$t('profile.fields.accept_rgpd')"
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <span class="text-caption" v-html="$t('profile.legal_text.rgpd')">
              </span>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="required"
                dense
                outlined
                v-model="entity.acceptCV"
                :rules="[(v) => v || $t('account.form.validation.cv_required')]"
                :label="$t('profile.fields.accept_cv')"
              ></v-switch>
            </v-col>
          </span>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import LanguageSelector from "@/components/debouncing-inputs/LanguageSelector.vue";
import regex from "@/common/regex-validation";
import auth from "@/common/auth";
import TimeZoneSelector from "@/components/debouncing-inputs/TimeZoneSelector.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import genres from "@/enumerates/Genre";
import { translate } from "@/common/translation-utils";

import ImageEdit from "@/components/profile/ImageEdit.vue";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    DayPicker,
    ImageEdit,
    TimeZoneSelector,
    LanguageSelector,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    accountFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    backBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      entity: {},
      validForm: true,
      genres: genres,
      user: auth.getUser(),
      image: {
        loading: false,
        photo: require("@/assets/icon_visits.png"),
      },
      regex,
    };
  },
  computed: {
    fullName() {
      return (
        (this.entity.name ? this.entity.name : "") +
        (this.entity.surname ? " " + this.entity.surname : "")
      );
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.entity = newVal;
          if (newVal.userId && (!oldVal || oldVal.userId != newVal.userId))
            UserDataEntityRepository.getPhoto(newVal.userId)
              .then((data) => {
                if (data.size > 0) {
                  this.image.photo = URL.createObjectURL(data);
                }
              })
              .catch(() =>
                this.$log.debug(
                  "Error downloading profile photo for user with ID " +
                    newVal.userId
                )
              );
        }
      },
      immediate: true,
    },
    entity(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    choosePhoto() {
      this.$refs.fileLoader.click();
    },
    deletePhoto() {
      this.entity.photo = null;
      this.image.photo = require("@/assets/icon_visits.png");
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save");
      }
    },
    translate,
  },
};
</script>

<style>
.centered-element {
  margin: auto;
}
</style>
