<template>
  <v-dialog v-model="dialog">
    <v-row justify="center" class="modal-content">
      <div ref="playerContainer" />
      <v-btn
        v-if="playing"
        icon
        @click="closeRecordingDialog()"
        class="close-btn"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-row>
  </v-dialog>
</template>
<script>
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import ActivityInteractionEntityRepository from "@/repositories/entities/ActivityInteractionEntityRepository";

export default {
  name: "PlayRecordingDialog",
  props: {
    activityInteractionId: {
      type: Number,
    },
  },
  data() {
    return {
      dialog: true,
      currentTime: null,
      playing: false,
    };
  },
  mounted() {
    this.playRecording();
  },
  watch: {
    dialog() {
      this.closeRecordingDialog();
    },
  },
  methods: {
    closeRecordingDialog() {
      this.rrwebPlayer = null;
      this.$emit("close-dialog");
    },
    async playRecording() {
      const result = await ActivityInteractionEntityRepository.getRecording(
        this.activityInteractionId
      );
      const events = result.recording;
      this.rrwebPlayer = new rrwebPlayer({
        target: this.$refs.playerContainer,
        props: {
          events,
        },
      });
      this.rrwebPlayer.addEventListener("start", () => {
        this.playing = true;
      });
      this.rrwebPlayer.play();
    },
  },
};
</script>
<style>
.modal-content {
  background-color: transparent;
}
.close-btn {
  background-color: white;
}
</style>
