import auth from "@/common/auth";
import properties from "@/properties";
import Vue from "vue";
import store from "./store";
import { RxStomp, RxStompState } from "@stomp/rx-stomp";

const websocket = store.state.websocket;
var rxStomp = null;

const config = {
  connectHeaders: {
    Authorization: "",
    login: "",
  },
  brokerURL: properties.WEBSOCKET_URL,
  //si se pierde la conexion se intenta reconectar cada 1000ms
  //al hacer la reconexion se vuelven a realizar todas las subscripciones que estaban activas
  reconnectDelay: 5000,
};

if (properties.STOMP_DEBUG) {
  config.debug = function (str) {
    Vue.$log.debug(str);
  };
}

var classroomSubscriptions = [];
var classroomActivitiesSubscriptions = [];
var chatNotificationsSubscriptions = [];

function formatString(str, ...args) {
  return str.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
}

const URLS = {
  SUSCRIPTION: {
    SUBSCRIBE_TO_NOTIFICATIONS: "/ws/queue/{0}/notifications",
    SUBSCRIBE_TO_OFFLINE_CLASSROOM_PER_USER:
      "/ws/queue/classrooms/{0}/users/{1}",
    SUBSCRIBE_TO_OFFLINE_CLASSROOM: "/ws/topic/classrooms/{0}/users",
    SUBSCRIBE_TO_CLASSROOM_PER_USER:
      "/ws/queue/classrooms/{0}/participants/{1}",
    SUBSCRIBE_TO_CLASSROOM_PER_DEVICE:
      "/ws/queue/classrooms/{0}/participants/{1}/devices/{2}",
    SUBSCRIBE_TO_CLASSROOM: "/ws/topic/classrooms/{0}/participants",
  },
  MESSAGE: {
    SEND_MESSAGE_TO_CLASROOM: "/ws/classrooms/{0}/participants",
    SEND_MESSAGE_TO_PARTICIPANT: "/ws/classrooms/{0}/participants/{1}",
    SEND_MESSAGE_TO_CLASSROOM_DEVICE:
      "/ws/classrooms/{0}/participants/{1}/devices/{2}",
    SEND_MESSAGE_TO_OFFLINE_CLASSROOM: "/ws/classrooms/{0}/users",
    SEND_MESSAGE_TO_USER: "/ws/classrooms/{0}/users/{1}",
  },
};

function configureStompClient() {
  rxStomp = new RxStomp();
  rxStomp.configure(config);

  rxStomp.connectionState$.subscribe(
    (newState) => (websocket.connected = newState === RxStompState.OPEN)
  );

  rxStomp._beforeConnect = () => {
    new Promise((resolve) => {
      config.connectHeaders.Authorization = `Bearer ${auth.getToken()}`;
      resolve();
    });
  };
}

async function connect() {
  Vue.$log.debug("RxStomp.connect: starting method");
  configureStompClient();
  rxStomp.activate();
}

function maxWaitTime() {
  //esperamos al receipt 3seg
  return new Promise((resolve) => setTimeout(() => resolve(false), 3000));
}

function watchReception2() {
  return new Promise((resolve) => {
    rxStomp.watchForReceipt(config.connectHeaders.receipt, () => {
      config.connectHeaders.receipt = null;
      resolve(true);
    });
  });
}

function promiseRace(resolve, iterate) {
  Promise.race([maxWaitTime, watchReception2].map((f) => f())).then(
    (received) => {
      if (received) {
        resolve();
      } else {
        //si han pasado mas de 3 segundos reenviamos el mensaje haciendo una llamada recursiva a aux
        iterate();
      }
    }
  );
}

function sendMessageToClassroom(classroomId, action) {
  Vue.$log.debug("StompClient.sendMessageToClassroom");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      rxStomp.publish({
        destination: formatString(
          URLS.MESSAGE.SEND_MESSAGE_TO_CLASROOM,
          classroomId
        ),
        headers: config.connectHeaders,
        body: JSON.stringify(action),
      });
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function sendMessageToParticipant(classroomId, userId, action) {
  Vue.$log.debug("StompClient.sendMessageToParticipant");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      rxStomp.publish({
        destination: formatString(
          URLS.MESSAGE.SEND_MESSAGE_TO_PARTICIPANT,
          classroomId,
          userId
        ),
        headers: config.connectHeaders,
        body: JSON.stringify(action),
      });
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function sendMessageToDevice(classroomId, userId, deviceId, action) {
  Vue.$log.debug("StompClient.sendMessageToDevice");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      rxStomp.publish({
        destination: formatString(
          URLS.MESSAGE.SEND_MESSAGE_TO_CLASSROOM_DEVICE,
          classroomId,
          userId,
          deviceId
        ),
        headers: config.connectHeaders,
        body: JSON.stringify(action),
      });
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToNotifications(onMessageReceived) {
  Vue.$log.debug("RxStomp.subscribeToNotifications");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      chatNotificationsSubscriptions.push(
        rxStomp
          .watch(
            formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_NOTIFICATIONS,
              auth.getUser().id
            ),
            config.connectHeaders
          )
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToOfflineClassroomUser(onMessageReceived, classroomId) {
  Vue.$log.debug("RxStomp.subscribeToOfflineClassroomUser");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      classroomActivitiesSubscriptions.push(
        rxStomp
          .watch(
            formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_OFFLINE_CLASSROOM_PER_USER,
              classroomId,
              auth.getUser().id
            ),
            config.connectHeaders
          )
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToOfflineClassroom(onMessageReceived, classroomId, deviceId) {
  Vue.$log.debug("RxStomp.subscribeToOfflineClassroom");
  setReceiptHeader();
  if (deviceId) {
    config.connectHeaders.login = deviceId;
  }
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      classroomActivitiesSubscriptions.push(
        rxStomp
          .watch(
            formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_OFFLINE_CLASSROOM,
              classroomId
            ),
            config.connectHeaders
          )
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function sendMessageToOfflineClassroom(classroomId, action) {
  Vue.$log.debug("StompClient.sendMessageToOfflineClassroom");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      rxStomp.publish({
        destination: formatString(
          URLS.MESSAGE.SEND_MESSAGE_TO_OFFLINE_CLASSROOM,
          classroomId
        ),
        headers: config.connectHeaders,
        body: JSON.stringify(action),
      });
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function sendMessageToUser(classroomId, userId, action) {
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      rxStomp.publish({
        destination: formatString(
          URLS.MESSAGE.SEND_MESSAGE_TO_USER,
          classroomId,
          userId
        ),
        headers: config.connectHeaders,
        body: JSON.stringify(action),
      });
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToClassroomUser(onMessageReceived, classroomId, deviceId) {
  Vue.$log.debug("StompClient.subscribeToClassroomUser");
  config.connectHeaders.login = deviceId;
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      classroomSubscriptions.push(
        rxStomp
          .watch(
            formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_CLASSROOM_PER_USER,
              classroomId,
              auth.getUser().id
            ),
            config.connectHeaders
          )
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToClassroomDevice(onMessageReceived, classroomId, deviceId) {
  Vue.$log.debug("StompClient.subscribeToClassroomDevice");
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      classroomSubscriptions.push(
        rxStomp
          .watch(
            formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_CLASSROOM_PER_DEVICE,
              classroomId,
              auth.getUser().id,
              deviceId
            ),
            config.connectHeaders
          )
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

function subscribeToClassroom(onMessageReceived, classroomId, deviceId) {
  Vue.$log.debug("StompClient.subscribeToClassroom");
  config.connectHeaders.login = deviceId;
  return new Promise((resolve) => {
    function aux() {
      setReceiptHeader();
      classroomSubscriptions.push(
        rxStomp
          .watch({
            destination: formatString(
              URLS.SUSCRIPTION.SUBSCRIBE_TO_CLASSROOM,
              classroomId
            ),
            subHeaders: config.connectHeaders,
            subscribeOnlyOnce: true,
          })
          .subscribe(function (payload) {
            onMessageReceived(JSON.parse(payload.body));
          })
      );
      promiseRace(resolve, aux);
    }
    aux();
  });
}

async function disconnect() {
  if (rxStomp != null) {
    try {
      await rxStomp.deactivate();
    } catch (e) {
      Vue.$log.error(e);
    }
  }
  config.connectHeaders = {
    Authorization: "",
    login: "",
  };
  rxStomp = null;
}

function unsubscribeFromClassroom() {
  Vue.$log.debug("RxStomp.unsubscribeFromClassroom");
  classroomSubscriptions.forEach((subscription) => subscription.unsubscribe());
  classroomSubscriptions = [];
  config.connectHeaders.login = "";
}

function unsubscribeFromOfflineClassroom() {
  Vue.$log.debug("RxStomp.unsubscribeFromOfflineClassroom");
  classroomActivitiesSubscriptions.forEach((subscription) =>
    subscription.unsubscribe()
  );
  classroomActivitiesSubscriptions = [];
}

function unsubscribeFromChat() {
  Vue.$log.debug("RxStomp.unsubscribeFromChat");
  chatNotificationsSubscriptions.forEach((subscription) =>
    subscription.unsubscribe()
  );
  chatNotificationsSubscriptions = [];
}

function isConnected() {
  return rxStomp != null;
}

function setReceiptHeader() {
  config.connectHeaders.receipt = new Date().getTime() + Math.random();
}

export default {
  connect,
  sendMessageToClassroom,
  sendMessageToParticipant,
  sendMessageToDevice,
  sendMessageToOfflineClassroom,
  subscribeToOfflineClassroomUser,
  subscribeToClassroom,
  disconnect,
  subscribeToNotifications,
  unsubscribeFromClassroom,
  subscribeToOfflineClassroom,
  unsubscribeFromChat,
  unsubscribeFromOfflineClassroom,
  isConnected,
  sendMessageToUser,
  subscribeToClassroomUser,
  subscribeToClassroomDevice,
};
