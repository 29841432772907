<template>
  <div class="mt-8 mb-10 pr-3 pl-3">
    <v-card max-width="480px" min-width="220px" class="ml-auto mr-auto">
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="12" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm" ref="form">
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center">
              <span>
                {{ $t("account.notifications.enter_login") }}
              </span>
              <v-text-field
                v-model="login"
                autocomplete="username"
                name="login"
                prepend-inner-icon="person"
                :label="$t('profile.fields.login')"
                class="required"
                :rules="loginRules"
                @keydown.enter="resetPasswordInit"
              ></v-text-field>
              <div class="text-center">
                <v-btn
                  class="primary mt-4"
                  @click="resetPasswordInit"
                  :loading="loading"
                >
                  {{ $t("account.actions.reset_password") }}
                </v-btn>
                <v-btn
                  class="primary mt-4"
                  @click="sendActivationMail"
                  :loading="emailLoading"
                >
                  {{ $t("account.actions.send_activation_mail") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  data() {
    return {
      validForm: true,
      loading: false,
      emailLoading: false,
      login: "",
      loginRules: [
        (v) => !!v || this.$t("account.form.validation.username_required"),
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$refs.form.resetValidation();
    },
  },
  methods: {
    resetPasswordInit() {
      if (this.validForm) {
        this.loading = true;
        AccountRepository.resetPasswordInit(this.login)
          .then(() => {
            this.$router.replace({ name: "Login" });
            this.$notify({
              title: this.$t("account.notifications.reset_link_sent.title"),
              text: this.$t("account.notifications.reset_link_sent.text"),
              type: "success",
              time: 5000,
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    sendActivationMail() {
      if (this.validForm) {
        this.emailLoading = true;
        const options = {
          params: {
            login: this.login,
          },
        };
        AccountRepository.getActivationMail(options)
          .then(() => {
            this.$router.replace({ name: "Login" });
            this.$notify({
              title: this.$t("account.notifications.activation_mail_sent"),
              type: "success",
            });
          })
          .finally(() => (this.emailLoading = false));
      }
    },
  },
};
</script>
