<template>
  <v-container>
    <product-form
      v-if="!loading"
      v-model="product"
      @save="save"
      @back="back"
    ></product-form>
    <loading-page v-else></loading-page>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import ProductForm from "@/mockups/economy/products/ProductForm";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

export default {
  name: "ProductEditForm",
  components: { ProductForm, LoadingPage },
  data() {
    return {
      product: {},
      loading: false,
    };
  },
  created() {
    this.loading = true;
    ProductEntityRepository.get(this.$route.params.id, true)
      .then((res) => {
        if (res.productStatus != "PUBLIC") {
          this.product = res;
        } else {
          this.$router.push({
            name: "NotFound",
          });
        }
      })
      .catch((err) => checkInvalidID(err))
      .finally(() => (this.loading = false));
  },
  methods: {
    back() {
      this.$router.push({
        name: "Admin Product List",
        params: { backAction: true },
      });
    },
    async save() {
      this.loading = true;
      ProductEntityRepository.save(this.product)
        .then((res) => {
          this.$router.push({
            name: "Product Detail",
            params: {
              backAction: this.$route.params.backPrevious,
              id: res.id,
            },
          });
        })
        .catch(() => this.$log.debug("Error saving product: " + this.product))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
