<template>
  <div v-show="showCanvas" ref="wrapper"></div>
</template>

<script>
import { mapClassroomStateGetter } from "@/components/classroom/store/mapClassroomStateGetter";
import { getVideoLayoutManual } from "@/components/classroom/participants/util/video-layout-helper";

const participants = [];

export default {
  name: "ClassroomVideoMultiple",
  props: {
    zoom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      participants: [],
      showCanvas: false,
      canvas: null,
      canvasSize: {
        width: 313,
        height: 352,
      },
    };
  },
  computed: {
    ...mapClassroomStateGetter(["sessionState"]),
  },
  mounted() {
    this.canvas = document.createElement("canvas");
    this.canvas.width = this.canvasSize.width;
    this.canvas.height = this.canvasSize.height;
    this.$refs.wrapper.appendChild(this.canvas);
  },
  methods: {
    async addParticipant(participant) {
      this.$log.debug(participant);

      const newElement = {
        cameraOnId: participant.cameraOnId,
        participant,
      };

      const layouts = getVideoLayoutManual(
        this.canvas.width,
        this.canvas.height,
        participants.length + 1
      );

      participants.forEach(async (p, idx) => {
        await this.zoom.adjustRenderedVideoPosition(
          this.canvas,
          p.cameraOnId,
          layouts[idx].toOptionsObject()
          // getPosition(this.canvasSize, participants.length + 1, idx + 1)
        );
      });
      participants.push(newElement);
      await this.zoom.renderVideo(
        this.canvas,
        newElement.cameraOnId,
        layouts[participants.length - 1].toOptionsObject()
        // getPosition(this.canvasSize, participants.length, participants.length)
      );
      this.showCanvas = true;
      return true;
    },
    async removeParticipant(participant) {
      this.$log.debug(participant);

      const toRemove = participants.find(
        (p) => p.cameraOnId == participant.cameraOnId
      );
      await this.zoom.stopRenderVideo(this.canvas, toRemove.cameraOnId);

      participants.splice(
        participants.findIndex((p) => p.cameraOnId == participant.cameraOnId),
        1
      );

      if (participants.length == 0) {
        this.showCanvas = false;
      } else {
        this.redraw();
      }

      return true;
    },
    redraw() {
      const layouts = getVideoLayoutManual(
        this.canvas.width,
        this.canvas.height,
        participants.length
      );

      participants.forEach((p, idx) => {
        this.zoom.adjustRenderedVideoPosition(
          this.canvas,
          p.cameraOnId,
          layouts[idx].toOptionsObject()
          // getPosition(this.canvasSize, participants.length, idx + 1)
        );
      });
    },
  },
};
</script>
