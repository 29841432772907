<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col>
          {{ $t("studentEvaluation.headers.list") }}
        </v-col>
        <v-col class="text-right"
          ><v-btn icon @click="$emit('cancel')"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.date`]="{ item }">
          <span v-if="item.date">
            {{ item.date | dateTimeWithoutTz }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEvaluationEntityRepository = RepositoryFactory.get(
  "StudentEvaluationEntityRepository"
);

export default {
  props: {
    lecture: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("studentEvaluation.prop.teacher"),
          value: "teacherName",
        },
        {
          text: this.$t("studentEvaluation.prop.comments"),
          value: "comments",
        },
        {
          text: this.$t("studentEvaluation.prop.date"),
          value: "date",
        },
      ];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      StudentEvaluationEntityRepository.getByStudentIdAndEdition(
        this.student.id,
        this.lecture.editionId
      )
        .then((data) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
