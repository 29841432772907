var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1s",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userFullName(item))+" ")]}},{key:"item.dateStart",fn:function(ref){
var item = ref.item;
return [(item.dateStart)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dateStart))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-md-block",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.goToEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.edit")))])])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }