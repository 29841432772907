<template>
  <v-select
    :items="productStatusItems"
    v-model="productStatus"
    :label="label"
    :outlined="outlined"
    :dense="dense"
  >
    <template v-slot:selection="{ item }">
      <span>{{ $t(`product.product_status.${item}`) }}</span>
    </template>
    <template v-slot:item="{ item }">
      <span>{{ $t(`product.product_status.${item}`) }}</span>
    </template>
  </v-select>
</template>

<script>
import { productStatusConst } from "./productConst";

export default {
  name: "ProductStatusSelector",
  props: {
    value: {
      type: String,
      default: "DRAFT",
    },
    label: {
      type: String,
      default: function () {
        this.$t("product.product_status.label");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.productStatus = newValue;
      },
      immediate: true,
    },
    productStatus(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.productStatus = this.value;
  },
  data() {
    return {
      productStatusItems: productStatusConst,
      productStatus: "",
    };
  },
};
</script>

<style></style>
