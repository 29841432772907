<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row align="center" no-gutters>
          <v-col cols="6" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col cols="12" md="4" class="text-center text-md-right">
            <v-checkbox
              class="d-md-inline-block mr-10 mt-5"
              v-model="activatedCheckbox"
              :label="$t('user_management.checkbox')"
              @change="onSearchChange"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="2" class="text-center text-md-right">
            <debounced-text-field
              v-model="search"
              class="d-md-inline-block"
              append-icon="search"
              dense
              hide-details
              :label="$t('user_management.search')"
              @input="onSearchChange"
            ></debounced-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="users"
          :server-items-length="totalItems"
          :options="pagination"
          @update:options="onPaginationChange"
          :search="search"
          :loading="loading"
        >
          <template v-slot:[`item.created_date`]="{ item }">
            <span>{{ item.created_date | dateTimeWithTz }} </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top open-delay="150">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  @click="showResetpasswordDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("account.actions.reset_password") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.activated`]="{ item }">
            <v-switch
              @change="activate(item)"
              v-model="item.activated"
              color="green"
            >
            </v-switch>
          </template>
          <template v-slot:[`item.authorities`]="{ item }">
            <td>
              <v-chip
                v-for="auth in item.authorities"
                :key="auth"
                :text-color="stringToColourText(auth)"
                :color="stringToColour(auth)"
              >
                {{ $t(`user_management.roles.${auth}`) }}
              </v-chip>
            </td>
          </template>
          <template v-slot:[`item.createdDate`]="{ item }">
            <span>{{ item.createdDate | zonedDateFormat }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialogo de confirmacion de reseteo de contraseña -->
    <modal-dialog
      @cancel="resetPasswordDialog = false"
      @submit="resetUserPassword()"
      :dialog="resetPasswordDialog"
      titleClass="primary white--text"
      titleIcon="mdi-lock-reset"
      submitClass="success"
      :submitText="$t('user_management.continue')"
      :title="$t('user_management.resetUserPassword')"
      :content="$t('user_management.confirmation')"
    ></modal-dialog>
  </v-container>
</template>

<script>
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import UserManagementRepository from "@/repositories/components/UserManagementRepository";
import { stringToColour, stringToColourText } from "@/common/customization";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

export default {
  components: { ModalDialog, DebouncedTextField },
  data() {
    return {
      users: [],
      search: this.$route.query.search || undefined,
      loading: false,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
        sortBy: parseStringToSortBy(this.$route.query.sort),
      },
      totalItems: null,
      resetPasswordDialog: false,
      selectedUserResetPassword: false,
      activatedCheckbox: this.$route.query.check === "true",
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("user_management.login"), value: "login", width: "1%" },
        {
          text: this.$t("user_management.firstName"),
          value: "firstName",
          width: "1%",
        },
        {
          text: this.$t("user_management.lastName"),
          value: "lastName",
          width: "1%",
        },
        { text: this.$t("user_management.email"), value: "email", width: "1%" },
        {
          text: this.$t("user_management.activated"),
          value: "activated",
          align: "start",
          width: "1%",
        },
        {
          text: this.$t("user_management.authorities"),
          value: "authorities",
          sortable: false,
          width: "1%",
        },
        {
          text: this.$t("profile.fields.date_start"),
          value: "createdDate",
          align: "start",
          divider: true,
          width: "1%",
        },
        { text: "", sortable: false, value: "action", width: "1%" },
      ];
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination),
          activated: !this.activatedCheckbox,
          search: this.search,
        },
      };
      UserManagementRepository.getAll(options)
        .then((response) => {
          this.users = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    showResetpasswordDialog(entity) {
      this.selectedUserResetPassword = entity;
      this.resetPasswordDialog = true;
    },
    activate(user) {
      UserManagementRepository.save(user);
    },
    resetUserPassword() {
      UserManagementRepository.resetUserPassword(
        this.selectedUserResetPassword.login
      )
        .then(() => {
          this.$notify({
            title: "Reset Password",
            text: this.$t("user_management.emailSent"),
            type: "success",
          });
        })
        .finally(() => (this.resetPasswordDialog = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this._fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.search = this.search !== "" ? this.search : undefined;
      query.check = this.activatedCheckbox
        ? this.activatedCheckbox.toString()
        : undefined;
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
    onSearchChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    stringToColourText: stringToColourText,
    stringToColour: stringToColour,
  },
};
</script>
<style scoped>
.text-field {
  width: 71%;
  display: inline-block;
}

>>> .v-chip .v-chip__content {
  height: auto !important;
}
</style>
