<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          :footer-props="tableFooterProps"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span v-if="item.date">
              {{ item.date | dateTimeWithTz("medium") }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const TeacherSupervisorReviewEntityRepository = RepositoryFactory.get(
  "TeacherSupervisorReviewEntityRepository"
);

export default {
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("teacherSupervisorReview.prop.supervisor"),
          value: "supervisorName",
        },
        {
          text: this.$t("teacherSupervisorReview.prop.date"),
          value: "date",
        },
        {
          text: this.$t("teacherSupervisorReview.prop.comments"),
          value: "comments",
        },
      ];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.redirectOnTableChange();
      },
      deep: true,
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        supervisorName: "supervisor.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      TeacherSupervisorReviewEntityRepository.getAllBySupervisedTeacher(
        this.teacherId,
        options
      )
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    redirectOnTableChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
  },
};
</script>
