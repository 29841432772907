<template>
  <v-container>
    <v-alert :value="alert" text type="success">
      {{ $t("activity.messages.h5p_saved_success") }}
    </v-alert>
    <v-card>
      <v-card-title>
        <v-row v-if="activity">
          {{ activity.name }}
        </v-row>
        <v-row justify="end" dense>
          <v-btn @click="back" class="ma-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("actions.cancel") }}
            </span>
          </v-btn>
          <v-btn @click="save" color="success" class="ma-2">
            <v-icon class="mr-2">save</v-icon>
            {{ $t("activity.actions.save_h5p") }}</v-btn
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <!--        <v-row v-show="!isLoading" justify="center" dense>-->
        <v-row justify="center" dense>
          <v-col cols="12" md="10">
            <div class="h5p">
              <h5p-editor ref="editor" />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="isSaving" justify="center" dense>
          <v-label>Saving </v-label>
        </v-row>
        <v-row v-if="isLoading || isSaving" justify="center" dense>
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="js">
import RepositoryFactory from "@/repositories/RepositoryFactory";
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);
const FileEntityRepository = RepositoryFactory.get(
  "FileEntityRepository"
);

try {
  const H5PWebcomponents = require("@lumieducation/h5p-webcomponents");
  H5PWebcomponents.defineElements("h5p-editor");
} catch {}

export default {
  name: 'H5PEditor',
  data() {
    return{
      h5pEditor: null,
      activity: null,
      alert: false,
      loading: false,
      saving: false,
      saveError: false,
      saveErrorMessage: ''
    }
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    isSaving() {
      return this.saving;
    }
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  mounted(){
    this.loading = true;
    this.h5pEditor = this.$refs.editor;
    this.h5pEditor.addEventListener("editorloaded", () => {
        this.loading = false;
      }
    );
  },
  methods: {
    _fetchData(id) {
      if (id) {
        ActivityEntityRepository.get(id).then((res) => {
          this.activity = res;
          this.loadEditor();
        });
      } else {
        this.loadEditor();
      }
    },
    loadEditor() {
      this.h5pEditor.setAttribute("content-id", this.activity && this.activity.content ? this.activity.content.fileName : "new");
      this.h5pEditor.loadContentCallback = async (contentId)=> {
         this.$log.debug(
          `ContentService: Getting information to edit ${contentId}...`
        );
        const res = await fetch('/h5p/'+ contentId +'/edit');
        if (!res || !res.ok) {
          throw new Error(`${res.status} ${res.statusText}`);
        }
        this.loading = false;
        return res.json();
      };
      this.h5pEditor.saveContentCallback = async (contentId, requestBody) => {
        if (!contentId) {
           this.$log.debug(`ContentService: Saving new content.`);
        } else {
           this.$log.debug(`ContentService: Saving content ${contentId}`);
        }
        this.saving = true;

        let body = JSON.stringify(requestBody);

        const res = contentId
          ? await fetch('/h5p/' + contentId, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body
          })
          : await fetch('/h5p', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body
          });

        if (!res || !res.ok) {
          throw new Error(
            `${res.status} ${res.statusText} - ${await res.text()}`
          );
        }
        return res.json();
      };
      this.h5pEditor.onSaved = async () => {
        this.saving = false;
        this.$emit("saved");
        this.back();
      };
      this.h5pEditor.onSaveError = async (event) => {
        this.saving = false;
        this.saveError = true;
        this.saveErrorMessage = event.detail.message;
        setTimeout(() => {
          this.saveError = false;
        }, 5000);
      };
    },
    async save() {
      try {
          const returnData = await this.h5pEditor.save();
          if (!this.activity.content && returnData) {
            let file = new FormData();
            file.append("fileName", returnData.contentId);
            file.append("path", returnData.contentId);
            file.append("content", returnData.contentId);
            await FileEntityRepository.saveH5P(file)
            .then((response) => this.saveH5PActivity(response))
            .catch(() => {
                this.$notify({
                type: "error",
                text: this.$t("merit.error.file-not-loaded"),
                duration: 30000,
                });
            });
          } else if (returnData) {
            this.alert = true;
            this.h5pEditor.onSaved();
          } else {
            this.h5pEditor.onSaveError();
          }
      } catch (error) {
          // We ignore the error, as we subscribe to the 'save-error' and
          // 'validation-error' events.
      }
    },
    saveH5PActivity(data) {
      this.activity.content = data;
      ActivityEntityRepository.saveContent(this.activity).then((res) => {
        this.$notify({
          title: this.$t("activity.messages.save_success"),
          text: this.$t("account.notifications.changes_saved"),
          type: "success",
        });
        this.$emit("saved", res);
        this.back();
      });
    },
    back() {
      this.$router.push({
        name: "Activity Detail",
        params: { id: this.activity.id },
      });
    },
  },
};
</script>
<style scoped>
.h5p {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
