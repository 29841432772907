<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row align="end">
          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("course.prop.title") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.title }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("course.prop.description") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.description }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("course.prop.level") }}:
          </v-col>
          <v-col cols="8" md="4">
            <v-chip v-if="entity.level" color="primary">{{
              entity.level.name
            }}</v-chip>
            <v-chip
              v-if="entity.level && entity.level.language"
              color="warning"
              >{{ $t(`languages.${entity.level.language.name}`) }}</v-chip
            >
          </v-col>
        </v-row>
        <v-divider class="my-4" color="grey"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" md="12" class="text-left font-weight-bold">
            <span class="headline"> {{ $t("course.prop.units") }}: </span>
          </v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :items="entity.units"
              :headers="unitHeaders"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "CourseSimpleDetail",
  components: { LoadingPage },
  data() {
    return {
      loading: false,
      entity: null,
      dialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isContentEditor", "isLogged"]),
    unitHeaders() {
      return [
        {
          text: this.$t("unit.prop.unit_name"),
          value: "name",
        },
      ];
    },
    isLoading() {
      return this.loading;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return CourseEntityRepository.get(id)
        .then((res) => (this.entity = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      if (!this.isLogged) {
        this.$router.go(-1);
      } else if (this.isContentEditor) {
        this.$router.push({
          name: "Course List",
          params: { backAction: true },
        });
      } else {
        this.$router.goToUserHome({
          params: { backAction: true },
        });
      }
    },
  },
};
</script>
