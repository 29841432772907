import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/studentAvailabilitys";

export default {
  async saveConfiguration(availabilities, studentId, init, end) {
    return (
      await HTTP.post(
        `${RESOURCE_NAME}/configuration/${studentId}`,
        availabilities,
        {
          params: {
            init: init,
            end: end,
          },
        }
      )
    ).data;
  },
};
