<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between">
        <v-col class="mx-4 d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t("lectureEvaluation.headers.form") }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="back" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block">
              {{ editable ? $t("actions.cancel") : $t("actions.back") }}
            </span>
          </v-btn>
          <v-btn
            v-if="editable"
            :loading="loading"
            :disabled="!validEvaluation"
            class="success mr-2"
            @click="save"
          >
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-form v-model="validForm">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" :sm="'12'" class="text-center">
            <v-row no-gutters>
              <v-col cols="12" :md="'12'">
                <v-textarea
                  outlined
                  v-model="evaluation.comments"
                  class="required"
                  :readonly="!editable"
                  :rules="[
                    (v) => !!v || $t('lectureEvaluation.error.required'),
                  ]"
                  :label="$t('lectureEvaluation.prop.comments')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const LectureEvaluationEntityRepository = RepositoryFactory.get(
  "LectureEvaluationEntityRepository"
);

export default {
  name: "LectureEvaluationForm",
  props: {
    value: {
      required: true,
    },
    studentId: {
      required: true,
    },
    lectureId: {
      required: true,
    },
    editable: {
      required: true,
    },
  },
  data() {
    return {
      evaluation: { ...this.value } || {},
      loading: false,
      validForm: true,
    };
  },
  computed: {
    validEvaluation() {
      return this.validForm;
    },
  },
  methods: {
    back() {
      this.$emit("cancel");
    },
    save() {
      this.loading = true;
      this.evaluation.studentId = this.studentId;
      this.evaluation.lectureId = this.lectureId;
      LectureEvaluationEntityRepository.save(this.evaluation)
        .then((evaluationSaved) => {
          this.$notify({
            title: !this.evaluation.id
              ? this.$t("lectureEvaluation.messages.create_success")
              : this.$t("lectureEvaluation.messages.update_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", evaluationSaved);
        })
        .catch(() =>
          this.$log.debug("Error saving lecture evaluation: " + this.evaluation)
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
