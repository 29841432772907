import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/registrations";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getActiveByStudent(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/active`, options);
    return response.data;
  },

  async get(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async requestDismissStudentRegistration(entity) {
    return (
      await HTTP.put(
        `${RESOURCE_NAME}/requestDismissStudentRegistration`,
        entity
      )
    ).data;
  },

  async unregisterStudentFromCourse(params) {
    return await HTTP.delete(
      `${RESOURCE_NAME}/unregisterStudentFromCourse`,
      params
    );
  },
  async downloadCertificate(id) {
    return HTTP.get(`${RESOURCE_NAME}/${id}/certificate`, {
      responseType: "blob",
    });
  },
  async getAllActive(userId) {
    return (await HTTP.get(`${RESOURCE_NAME}/getAllActive/${userId}`)).data;
  },

  async addStudentsToEdition(id, studentIds) {
    return (
      await HTTP.post(
        `${RESOURCE_NAME}/${id}`,
        {},
        {
          params: {
            students: studentIds,
          },
        }
      )
    ).data;
  },
};
