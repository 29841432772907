<template>
  <div class="chat__working">
    <div class="chat__create-chat">
      <div class="form-control">
        <label for="name" class="form-label">{{ $t("chat.label-name") }}</label>
        <input
          class="form-input"
          type="text"
          placeholder="Nombre del chat"
          v-model="newChat.name"
        />
      </div>
      <div class="form-control">
        <label class="form-label">{{ $t("chat.members") }}</label>
        <div class="chat__create-chat__members">
          <div
            class="chat__create-chat__members__nousers"
            v-if="selectedUsers.length === 0"
          >
            {{ $t("chat.search-users.no-users") }}
          </div>
          <div class="form-error" v-if="onSaveError" :class="onSaveError">
            {{ $t("chat.error.list-error") }}
          </div>
          <div
            class="no-name"
            v-for="user in selectedUsers"
            v-bind:key="user.id"
          >
            <div class="no-name__content">
              <span class="no-name__title"
                >{{ user.fistName }} {{ user.lastName }}</span
              >
              <span class="no-name__subtitle">{{ user.email }}</span>
            </div>
            <button type="button" class="no-name__remove" @click="remove(user)">
              <icon
                name="close"
                width="16"
                height="16"
                :title="$t('chat.remove')"
              ></icon>
            </button>
          </div>
        </div>
      </div>

      <div class="chat__search-users form-block">
        <div class="form-block__title">
          {{ $t("chat.search-users.search") }}
        </div>
        <div class="form-control">
          <input
            class="form-input"
            type="search"
            :placeholder="$t('chat.search-users.placeholder')"
            autocomplete="off"
            v-model="search"
          />
        </div>
        <div v-if="!loading">
          <div
            class="chat__search-users__addusers"
            v-for="user in filteredList"
            v-bind:key="user.id"
          >
            <button
              type="button"
              class="chat__search-users__adduser__button"
              @click="select(user)"
            >
              <icon
                name="plus"
                width="18"
                height="18"
                :title="$t('chat.actions.add-user')"
              ></icon>
              {{ user.fistName }} {{ user.lastName }} ({{ user.email }})
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="chat__footer chat__search-users__buttons">
      <button
        type="button"
        class="btn btn-primary btn-icon bnt-align-right"
        @click="save()"
      >
        <icon
          name="chat-send"
          width="18"
          height="18"
          :title="$t('chat.actions.savechat')"
        ></icon>
        {{ $t("chat.actions.savechat") }}
      </button>
    </div>
  </div>
</template>
<script>
import UserManagementRepository from "@/repositories/components/UserManagementRepository";
import ChatRepository from "@/repositories/components/ChatRepository";
import auth from "@/common/auth";

export default {
  data() {
    return {
      users: [],
      valid: false,
      search: "",
      selectedUsers: [],
      onSaveError: null,
      loading: false,
      newChat: {
        name: null,
        owner: null,
        users: [],
      },
    };
  },
  created() {
    this._fetchData();
  },
  computed: {
    filteredList() {
      return this.users.filter(
        (user) =>
          (user.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
            user.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
            user.email.toLowerCase().includes(this.search.toLowerCase())) &&
          user.id !== auth.getUser().id
      );
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      UserManagementRepository.getRegistrated()
        .then((response) => (this.users = response.content))
        .finally(() => (this.loading = false));
    },
    select(item) {
      this.onSaveError = null;
      this.selectedUsers.push(item);
      this.newChat.users.push(item.id);
      this.users.splice(this.users.indexOf(item), 1);
    },
    remove(item) {
      this.selectedUsers.splice(this.selectedUsers.indexOf(item), 1);
      this.newChat.users.splice(this.newChat.users.indexOf(item.id), 1);
      this.users.push(item);
    },
    async save() {
      if (this.newChat.users == null || this.newChat.users.length < 1) {
        this.onSaveError = "list-error";
      } else {
        this.newChat.owner = auth.getUser().id;
        await ChatRepository.saveChat(this.newChat);
        this.$emit("form-submited");
      }
    },
  },
};
</script>
<style></style>
