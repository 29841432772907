var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.headers.list"))+" ")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","clearable":"","outlined":"","no-filter":"","attach":"","debouncing":300,"items":_vm.resourceType,"item-text":_vm.translate,"search-input":_vm.resourceTypeSearch,"item-value":"value","label":_vm.$t('activity.prop.resource_type')},on:{"update:searchInput":function($event){_vm.resourceTypeSearch=$event},"update:search-input":function($event){_vm.resourceTypeSearch=$event}},model:{value:(_vm.resourceTypeFilter),callback:function ($$v) {_vm.resourceTypeFilter=$$v},expression:"resourceTypeFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('debounced-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('activity.prop.title')},model:{value:(_vm.titleFilter),callback:function ($$v) {_vm.titleFilter=$$v},expression:"titleFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('debounced-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('activity.prop.description')},model:{value:(_vm.descriptionFilter),callback:function ($$v) {_vm.descriptionFilter=$$v},expression:"descriptionFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"attach":"","dense":"","outlined":"","clearable":"","no-filter":"","debouncing":300,"items":_vm.activityTypes.items,"loading":_vm.activityTypes.loading,"search-input":_vm.activityTypeSearch,"label":_vm.$t('activity.prop.activity_type'),"item-text":"id","item-value":function (item) { return parseInt(item.id); }},on:{"update:searchInput":function($event){_vm.activityTypeSearch=$event},"update:search-input":function($event){_vm.activityTypeSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.index === 0)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}],null,false,2974581456),model:{value:(_vm.activityTypeFilter),callback:function ($$v) {_vm.activityTypeFilter=$$v},expression:"activityTypeFilter"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('LanguageSelector',{attrs:{"attach":"","disabled":_vm.language ? true : false,"dense":"","clearable":!_vm.levelFilter,"outlined":"","debouncing":300,"label":_vm.$t('activity.prop.language'),"show-chips":""},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLanguageChange' ].concat( argsArray ))}},model:{value:(_vm.languageFilter),callback:function ($$v) {_vm.languageFilter=$$v},expression:"languageFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('LevelSelector',{attrs:{"attach":"","dense":"","clearable":"","outlined":"","debouncing":300,"label":_vm.$t('activity.prop.level'),"language-filter":_vm.languageFilter ? _vm.languageFilter.id : null,"show-language":false},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLevelChange' ].concat( argsArray ))}},model:{value:(_vm.levelFilter),callback:function ($$v) {_vm.levelFilter=$$v},expression:"levelFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"attach":"","dense":"","outlined":"","clearable":"","no-filter":"","debouncing":300,"items":_vm.activitySkills.items,"loading":_vm.activitySkills.loading,"search-input":_vm.activitySkillSearch,"label":_vm.$t('activity.prop.skills'),"item-text":"id","item-value":function (item) { return parseInt(item.id); }},on:{"update:searchInput":function($event){_vm.activitySkillSearch=$event},"update:search-input":function($event){_vm.activitySkillSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])])]}}],null,false,2271302140),model:{value:(_vm.activitySkillFilter),callback:function ($$v) {_vm.activitySkillFilter=$$v},expression:"activitySkillFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"attach":"","dense":"","outlined":"","clearable":"","no-filter":"","debouncing":300,"items":_vm.activityTopics.items,"loading":_vm.activityTopics.loading,"search-input":_vm.activityTopicSearch,"label":_vm.$t('activity.prop.topics'),"item-text":"id","item-value":function (item) { return parseInt(item.id); }},on:{"update:searchInput":function($event){_vm.activityTopicSearch=$event},"update:search-input":function($event){_vm.activityTopicSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item),"text-color":_vm.getChipTextColor(item)}},[_c('span',[_vm._v(_vm._s(item.name))])])]}}],null,false,2271302140),model:{value:(_vm.activityTopicFilter),callback:function ($$v) {_vm.activityTopicFilter=$$v},expression:"activityTopicFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"attach":"","dense":"","outlined":"","small-chips":"","deletable-chips":"","no-filter":"","multiple":"","debouncing":300,"items":_vm.activityWords.items,"loading":_vm.activityWords.loading,"search-input":_vm.activityWordSearch,"label":_vm.$t('activity.prop.words'),"item-text":"name","item-value":function (item) { return parseInt(item.id); }},on:{"update:searchInput":function($event){_vm.activityWordSearch=$event},"update:search-input":function($event){_vm.activityWordSearch=$event}},model:{value:(_vm.activityWordFilter),callback:function ($$v) {_vm.activityWordFilter=$$v},expression:"activityWordFilter"}})],1)],1),_c('v-data-table',{staticClass:"rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.entitiesPage,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.entitiesPage=$event},"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(item.level.name))+" ")]):_vm._e()]}},{key:"item.activityType",fn:function(ref){
var item = ref.item;
return [(item.activityType)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.activityType),"text-color":_vm.getChipTextColor(item.activityType)}},[_vm._v(_vm._s(item.activityType.name))]):_vm._e()]}},{key:"item.resourceType",fn:function(ref){
var item = ref.item;
return [(item.resourceType)?_c('v-chip',{attrs:{"color":_vm.stringToColour(item.resourceType),"text-color":_vm.stringToColourText(item.resourceType)}},[_vm._v(_vm._s(_vm.$t(("activity.resource_type." + (item.resourceType)))))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.content != null)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.previewActivity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.preview")))])])]}}],null,true)})],1)],1),(_vm.previewDialog)?_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[(_vm.preview)?_c('activity-preview',{attrs:{"activity":_vm.preview},on:{"cancel":function($event){return _vm.closePreview()}}}):_vm._e()],1):_vm._e(),(_vm.pdfDialog)?_c('v-dialog',{attrs:{"width":"80%"},on:{"click:outside":function($event){return _vm.closePreview()}},model:{value:(_vm.pdfDialog),callback:function ($$v) {_vm.pdfDialog=$$v},expression:"pdfDialog"}},[_c('PDFViewer',{attrs:{"exercise":_vm.preview.id,"homework":false},on:{"closeDialog":function($event){return _vm.closePreview()}}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }