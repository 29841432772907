var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.editionTitle)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[(_vm.startDate)?_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("edition.from"))+" "+_vm._s(_vm._f("dateTimeWithTz")(_vm.startDate,"short"))+" ")]):_vm._e(),(_vm.endDate)?_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("edition.to"))+" "+_vm._s(_vm._f("dateTimeWithTz")(_vm.endDate,"short"))+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":!_vm.isFinished ? 'green' : 'red'}},[_vm._v(" "+_vm._s(!_vm.isFinished ? _vm.$t("edition.status.NOT_FINISHED") : _vm.$t("edition.status.FINISHED"))+" ")])],1)],1),_c('v-col',{staticClass:"text-right"},[(_vm.addButton)?_c('add-student-dialog',{attrs:{"edition-id":parseInt(_vm.$route.params.editionId),"max-students":_vm.maxStudents - _vm.numberOfStudentsActive},on:{"added":function($event){return _vm.$emit('update:students')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"success","disabled":_vm.numberOfStudentsActive >= _vm.maxStudents}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.add"))+" ")])],1)]}}],null,false,2647005388)}):_vm._e(),(_vm.finishButton)?_c('span',[(_vm.endDate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("edition.messages.finishes_at"))+": "+_vm._s(_vm._f("dateTimeWithoutTz")(_vm.endDate))+" ")]):_vm._e(),_c('close-edition-dialog',{on:{"save":_vm.closeEdition},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 ml-2 error",attrs:{"disabled":_vm.isFinished,"loading":_vm.loadingClose}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.close"))+" ")])],1)]}}],null,false,1252500525),model:{value:(_vm.closeEditionDialog),callback:function ($$v) {_vm.closeEditionDialog=$$v},expression:"closeEditionDialog"}})],1):_vm._e(),_c('v-btn',{staticClass:"ml-2 mt-2",on:{"click":_vm.back}},[_c('v-icon',[_vm._v("arrow_back")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.back"))+" ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-2",attrs:{"light":""}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("student.headers.list")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{class:_vm.getStudentsTableClass(),attrs:{"headers":_vm.studentHeaders,"items":_vm.students.items,"loading":_vm.students.loading,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.studentDetail},scopedSlots:_vm._u([{key:"item.registrationState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.registrationState === 'ACTIVE' ? 'white--text' : '',attrs:{"color":item.registrationState === 'ACTIVE' ? 'green' : ''}},[_vm._v(" "+_vm._s(_vm.$t(("registration.status." + (item.registrationState)), { date: _vm.$d( _vm.dateArrayToDate(item.registrationWithdrawalConfDate), "short" ), }))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.hideHeader)?_c('v-col',{staticClass:"d-md-block"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:item.registrationState === 'WITHDRAW_REQUESTED'
                      ? 'warning'
                      : 'error',attrs:{"small":"","disabled":item.registrationState === 'INACTIVE'},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog(item, 'unregisterDialog')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" person_remove ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.registrationState === "WITHDRAW_REQUESTED" ? _vm.$t("edition.unregister.tooltip.change_date") : _vm.$t("edition.unregister.tooltip.unregister"))+" ")])])],1):_c('v-col',{staticClass:"d-md-block"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog(item, 'dialog')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.finalEvaluationId ? 'warning' : 'primary'}},[_vm._v("post_add")])],1)]}}],null,true)},[(!item.finalEvaluationId)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("studentFinalEvaluation.messages.make_final_evaluation"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( "studentFinalEvaluation.messages.modify_final_evaluation" ))+" ")])])],1)]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mt-2 mb-2",attrs:{"light":""}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("lecture.headers.list")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.lectureHeaders,"items":_vm.lectures.items,"loading":_vm.lectures.loading,"options":_vm.lecturePagination,"server-items-length":_vm.lectures.totalItems},on:{"update:options":function($event){_vm.lecturePagination=$event},"click:row":_vm.lectureDetail},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
                      var item = ref.item;
return [(item.startTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.startTime,"long"))+" ")]):_vm._e()]}},{key:"item.endTime",fn:function(ref){
                      var item = ref.item;
return [(item.endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.endTime,"long"))+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("lectureEvaluation.headers.list"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.evaluationHeaders,"items":_vm.evaluations.items,"loading":_vm.evaluations.loading,"options":_vm.evaluationPagination,"server-items-length":_vm.evaluations.totalItems},on:{"update:options":function($event){_vm.evaluationPagination=$event},"click:row":_vm.lectureDetailFromEvaluation},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.date,"medium"))+" ")]}}],null,true)})],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"width":"80%"},on:{"click:outside":_vm.closeEvaluationDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('student-final-evaluation-form',{attrs:{"editionId":_vm.editionId,"student-id":_vm.selectedStudent.id,"evaluation-id":_vm.selectedStudent.finalEvaluationId},on:{"save":_vm.closeEvaluationDialog,"cancel":_vm.closeEvaluationDialog}})],1):_vm._e(),(_vm.unregisterDialog)?_c('v-dialog',{on:{"click:outside":_vm.closeUnregisterDialog},model:{value:(_vm.unregisterDialog),callback:function ($$v) {_vm.unregisterDialog=$$v},expression:"unregisterDialog"}},[_c('edition-unregister-dialog',{attrs:{"student":_vm.selectedStudent,"editionId":_vm.editionId},on:{"requested":_vm.submitUnregisterDialog,"cancel":_vm.closeUnregisterDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }