import ContentEditorDetail from "@/mockups/content-editor/ContentEditorDetail";
import Profile from "@/account/profile/Profile";
import ProfileEdit from "@/account/profile/ProfileEdit";
import ContentEditorDashboard from "@/mockups/content-editor/views/ContentEditorDashboard";

const routes = [
  // ADMIN ROUTES DEFINITION
  {
    path: "/admin/content-editors/:id",
    name: "ContentEditorView",
    component: ContentEditorDetail,
    meta: {
      authority: ["ROLE_ADMIN"],
    },
    children: [
      {
        path: "info",
        name: "ContentEditorView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "contentEditor.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ContentEditorView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_ADMIN"],
          label: "contentEditor.navbar.edit",
        },
      },
    ],
  },
  // MY ACCOUNT ROUTES DEFINITION
  {
    path: "/content-editor/dashboard",
    name: "ContentEditor Home",
    component: ContentEditorDashboard,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "contentEditor.headers.dashboard",
    },
  },
  {
    path: "/account/profile/content-editor",
    name: "ProfileContentEditorView",
    component: ContentEditorDetail,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
    },
    children: [
      {
        path: "info",
        name: "ProfileContentEditorView Detail",
        component: Profile,
        meta: {
          authority: ["ROLE_CONTENT_EDITOR"],
          label: "contentEditor.navbar.info",
        },
      },
      {
        path: "edit",
        name: "ProfileContentEditorView Form",
        component: ProfileEdit,
        meta: {
          authority: ["ROLE_CONTENT_EDITOR"],
          label: "contentEditor.navbar.edit",
        },
      },
    ],
  },
];

export default routes;
