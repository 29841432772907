<template>
  <v-dialog v-model="dialog" width="65%">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="10" md="11">
            <h1 class="title ml-4">
              {{ $t("teacher.headers.set_supervisor") }}
            </h1>
          </v-col>

          <v-col cols="2" md="1" class="text-right">
            <v-btn icon @click="dialog = false"> <v-icon>close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="headmasters"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="onPaginationChange"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top v-if="!loadingRow[item.id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="setSupervising(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-multiple-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.add") }}</span>
            </v-tooltip>
            <loading-spinner v-else></loading-spinner>
          </template>

          <template v-slot:top>
            <v-row no-gutters>
              <v-col cols="6">
                <debounced-text-field
                  v-model="search"
                  :label="$t('search')"
                  @input="onSearchInput"
                  class="mx-4"
                ></debounced-text-field>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { generateSort } from "@/common/pagination-utils";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  name: "SetSupervisorDialog",
  components: { DebouncedTextField, LoadingSpinner },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      required: false,
    },
    idSupervisor: {
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      search: null,
      headmasters: [],
      loading: false,
      totalItems: 0,
      pagination: defaultPaginationSettings,
      tableFooterProps,
      loadingRow: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.display_name"),
          value: "displayName",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value: {
      handler(val) {
        this.dialog = val;
        if (val) {
          this._fetchData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const sortMapping = {
        name: "userData.user.firstName",
        surname: "userData.user.lastName",
        displayName: "userData.displayName",
        mainEmail: "userData.user.email",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          authority: "ROLE_HEADMASTER",
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          search: this.search ? this.search : undefined,
          notShowSupervisorsIds: this.idSupervisor
            ? [this.idSupervisor, this.id]
            : [this.id],
        },
      };
      TeacherEntityRepository.getAll(options)
        .then((response) => {
          this.headmasters = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching teachers with params: " + options)
        )
        .finally(() => (this.loading = false));
    },
    setSupervising(item) {
      this.$set(this.loadingRow, item.id, true);
      TeacherEntityRepository.addSupervising(item.id, this.id)
        .then(() => {
          this.$notify({
            title: this.$t("teacher.modals.supervisor_changed.title"),
            text: this.$t("teacher.modals.supervisor_changed.text"),
            type: "success",
          });
          this.$emit("changed", item);
        })
        .catch(() =>
          this.$log.debug(
            "Error adding supervised teacher with ID " +
              item.id +
              " to teacher with ID " +
              this.id
          )
        )
        .finally(() => {
          this.$set(this.loadingRow, item.id, false);
          this.dialog = false;
        });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this._fetchData();
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      this.redirect(query);
    },
    onSearchInput() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
  },
};
</script>
