<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="headline">{{
        $t("teacher.tabs.supervising.title")
      }}</v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="tableFooterProps"
          :headers="headersSupervised"
          :items="supervisedTeachersByHeadmaster"
          :search="searchSupervised"
          :loading="supervisedTeachersLoading"
          disable-pagination
          hide-default-footer
        >
          <!-- Find in table and add new teacher button -->
          <template v-slot:top>
            <v-row align="center" no-gutters justify="start">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="searchSupervised"
                  :label="$t('search')"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col v-if="isAdmin" cols="6" md="3">
                <v-btn
                  color="success"
                  @click="teachersNotSupervisedDialog = true"
                  block
                >
                  {{ $t("actions.add") }}</v-btn
                >
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  @click="toLectureList(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.lecture_list") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdmin"
                  icon
                  color="red"
                  :loading="deleteSupervisingLoading"
                  @click="deleteSupervising(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Not supervised search and add dialog -->
    <v-dialog v-model="teachersNotSupervisedDialog" width="65%">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10" md="11">
              <h1 class="title ml-4">Add teachers supervising</h1>
            </v-col>

            <v-col cols="2" md="1">
              <v-btn icon @click="teachersNotSupervisedDialog = false">
                <v-icon>close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="searchNotSupervised"
            :headers="headersSupervised"
            :items="notSupervisedTeachersList"
            :loading="notSupervisedTeachersLoading"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="isAdmin"
                icon
                color="success"
                @click="addSupervising(item)"
                :loading="addSupervisingLoading"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>

            <template v-slot:top>
              <v-row no-gutters>
                <v-col cols="6" md="6" lg="6">
                  <v-text-field
                    v-model="searchNotSupervised"
                    :label="$t('search')"
                    class="mx-4"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.fetchInitialData();
  },
  data() {
    return {
      supervisedTeachersByHeadmaster: [],
      supervisedTeachersLoading: false,
      notSupervisedTeachersLoading: false,
      searchSupervised: "",
      searchNotSupervised: "",
      notSupervisedTeachersList: [],
      teachersNotSupervisedDialog: false,
      addSupervisingLoading: false,
      deleteSupervisingLoading: false,
      tableFooterProps,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    headersSupervised() {
      return [
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.nif"),
          value: "nif",
        },
        {
          text: this.$t("profile.fields.address"),
          value: "address",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "mainPhone",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  methods: {
    fetchInitialData() {
      const prom1 = this._fetchData(this.teacherId);
      const prom2 = this._fetchNotSupervisedList();
      return Promise.all([prom1, prom2]);
    },
    _fetchData(id) {
      this.supervisedTeachersLoading = true;
      return TeacherEntityRepository.getSupervisedByHeadmaster(id)
        .then((response) => {
          this.supervisedTeachersByHeadmaster = response;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching supervised teachers for headmaster teacher with ID " +
              id
          )
        )
        .finally(() => (this.supervisedTeachersLoading = false));
    },
    // Fetching the not Supervised Teacher List
    _fetchNotSupervisedList() {
      this.notSupervisedTeachersLoading = true;
      return TeacherEntityRepository.getAll({
        params: {
          size: -1,
          notSupervised: true,
        },
      })
        .then(
          (res) =>
            (this.notSupervisedTeachersList = res.content.filter(
              (el) => el.id !== parseInt(this.teacherId)
            ))
        )
        .catch(() => this.$log.debug("Error fetching not supervised teachers"))
        .finally(() => (this.notSupervisedTeachersLoading = false));
    },
    // Actions for add or delete supervised teachers
    addSupervising(item) {
      this.addSupervisingLoading = true;
      TeacherEntityRepository.addSupervising(this.teacherId, item.id)
        .then(() => {
          this.$notify({
            title: this.$t("teacher.messages.supervising.create_success.title"),
            text: this.$t("teacher.messages.supervising.create_success.body"),
            type: "success",
          });
          return this.fetchInitialData();
        })
        .catch(() => {
          this.$notify({
            title: this.$t("teacher.messages.supervising.create_error.title"),
            text: this.$t("teacher.messages.supervising.create_error.body"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.addSupervisingLoading = false));
    },
    deleteSupervising(item) {
      this.deleteSupervisingLoading = true;
      TeacherEntityRepository.deleteSupervising(item.id)
        .then(() => {
          this.$notify({
            title: this.$t("teacher.messages.supervising.delete_success.title"),
            text: this.$t("teacher.messages.supervising.delete_success.body"),
            type: "success",
          });
          return this.fetchInitialData();
        })
        .catch(() => {
          this.$notify({
            title: this.$t("teacher.messages.supervising.create_error.title"),
            text: this.$t("teacher.messages.supervising.create_error.body"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.deleteSupervisingLoading = false));
    },
    toLectureList(item) {
      this.$router.push(this.$route.path + `/${item.id}/lectures`);
    },
  },
};
</script>
