<template>
  <v-container fluid>
    <v-card flat :loading="isLoading">
      <v-row v-if="isLoading" class="mt-16 mb-16" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-card-title v-if="!isLoading">
        <v-row align="center" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col order="1" order-md="2" class="text-right">
            <v-btn class="warning" @click="edit()">
              <v-icon>edit</v-icon>
              <span>{{ $t("actions.edit") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="!isLoading">
        <v-row align="start" justify="start">
          <!-- Photo, name and about me -->
          <v-col cols="12" md="3" lg="3">
            <!-- Title for movile version -->
            <v-row
              class="d-block d-md-none"
              align="center"
              justify="space-between"
              no-gutters
            >
              <div class="headline text-center">{{ fullName }}</div>
            </v-row>
            <v-row>
              <v-col cols="10" class="centered-element">
                <v-img
                  max-height="250"
                  style="margin: auto"
                  max-width="250"
                  :src="photo"
                ></v-img>
              </v-col>
            </v-row>
            <v-divider class="my-3 d-none d-md-block"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="pb-0">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.fields.about_me") }}
                </div>
              </v-col>
              <v-col cols="12">
                <div class="aboutme-field">
                  {{ entity.aboutMe }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- Another fields -->
          <v-col cols="12" md="9" lg="9">
            <v-row
              class="d-none d-md-flex"
              align="center"
              justify="space-between"
              no-gutters
            >
              <v-col class="d-none d-md-block" cols="12" md="6">
                <div class="headline">
                  {{ fullName }}
                  <v-chip class="ml-4" color="primary" small>{{
                    $t("teacher.name")
                  }}</v-chip>
                </div>
                <div class="caption">
                  {{ entity.displayName }}
                </div>
                <div class="caption">
                  {{ entity.login }}
                </div>
              </v-col>
              <v-col cols="12" md="6" class="text-right" v-if="!isHeadmaster">
                <span v-if="entity.supervisingTeacher">
                  <strong>{{ $t("teacher.prop.supervisor") }}:</strong>
                  {{
                    entity.supervisingTeacher.name +
                    " " +
                    entity.supervisingTeacher.surname
                  }}
                  <span v-if="isAdmin">
                    <set-supervisor-dialog
                      v-model="setSupervisorDialogOpen"
                      :id="$route.params.id"
                      :idSupervisor="entity.supervisingTeacher.id"
                      @changed="updateSupervisor"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-tooltip v-bind="attrs" v-on="on" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon color="warning">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("teacher.actions.edit-supervisor")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </set-supervisor-dialog>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="deleteSupervising"
                          :loading="loadingSupervisor"
                        >
                          <v-icon color="error">delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("teacher.actions.remove-supervisor") }}</span>
                    </v-tooltip>
                  </span>
                </span>
                <span v-else-if="isAdmin">
                  <strong>{{ $t("teacher.no_supervisor") }}</strong>
                  <set-supervisor-dialog
                    v-model="setSupervisorDialogOpen"
                    :id="$route.params.id"
                    @changed="updateSupervisor"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-tooltip v-bind="attrs" v-on="on" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="success">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("teacher.actions.add-supervisor") }}</span>
                      </v-tooltip>
                    </template>
                  </set-supervisor-dialog>
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>

            <v-row align="start" no-gutters>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1">
                  {{ $t("profile.fields.contact_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.main_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.mainPhone }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">phone</v-icon>
                    {{ $t("profile.fields.secondary_phone") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.secondaryPhone }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.main_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.mainEmail }}
                  </span>
                </div>
                <div class="field-info field-info--icon">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">email</v-icon>
                    {{ $t("profile.fields.secondary_email") }}:
                  </label>
                  <span class="field-info__content">
                    {{ entity.secondaryEmail }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-info field-info--icon field-info--multiline">
                  <label class="field-info__label">
                    <v-icon class="field-info__icon">mdi-home-city</v-icon>
                    {{ $t("profile.fields.address") }}:
                  </label>
                  <span>{{ fullAddress }}</span>
                </div>
              </v-col>
              <v-col class="" cols="12" md="6">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_emails") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="entity.acceptEmails != null"
                  >
                    {{ $t(`${entity.acceptEmails}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" md="6">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_whats") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="entity.acceptWhats != null"
                  >
                    {{ $t(`${entity.acceptWhats}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" md="6">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_calls") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="entity.acceptCalls != null"
                  >
                    {{ $t(`${entity.acceptCalls}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" md="6">
                <div class="field-info">
                  <label class="field-info__label">
                    {{ $t("profile.fields.accept_substitution") }}:
                  </label>
                  <span
                    class="field-info__content"
                    v-if="entity.substituteAccepted != null"
                  >
                    {{ $t(`${entity.substituteAccepted}`) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-title primary--text subtitle-1 pt-6">
                  {{ $t("profile.personal_info") }}
                </div>
              </v-col>
              <v-col class="" cols="12" sm="5">
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.birthdate") }}:</label
                  >
                  <span class="field-info__content" v-if="entity.birthdate">
                    {{ entity.birthdate | dateTimeWithoutTz }}
                  </span>
                </div>
                <div class="field-info" v-if="entity.genre">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.genre") }}:</label
                  >
                  <span class="field-info__content">
                    {{ $t(`genre.values.${entity.genre}`) }}
                  </span>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="7">
                <div class="field-info">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.passport") }}:</label
                  >
                  <span class="field-info__content">
                    {{ entity.nif }}
                  </span>
                </div>
                <div class="field-info" v-if="entity.nifExpiration">
                  <label class="field-info__label"
                    >{{ $t("profile.fields.passport_validity") }}:</label
                  >
                  <span class="field-info__content">
                    {{ entity.nifExpiration | dateTimeWithoutTz }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="field-info" v-if="entity.timeZone">
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{
                      getOffset(entity.timeZone.name) +
                      " " +
                      $t(`timezones.${entity.timeZone.name}`)
                    }}
                  </span>
                </div>
                <div class="field-info" v-else>
                  <label class="field-info__label">
                    {{ $t("profile.fields.timeZone") }}:</label
                  >
                  <span class="field-info__content">
                    {{ $t("profile.fields.timeZone_automatic") }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!isLoading">
        <v-col class="text-right">
          <v-btn @click="showDialog = true" class="warning" v-if="!isAdmin">
            <span class="d-none d-sm-block">
              {{ $t("profile.unregister.button_label") }}
            </span>
          </v-btn>
          <unregister-dialog
            v-if="showDialog"
            :userId="entity.userId"
            :dialog="showDialog"
            @cancel="showDialog = false"
          ></unregister-dialog>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import auth from "@/common/auth";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import SetSupervisorDialog from "@/mockups/teachers/components/SetSupervisorDialog";
import { getOffset } from "@/common/timeZoneUtils";
import UnregisterDialog from "@/mockups/components/UnregisterDialog";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    SetSupervisorDialog,
    UnregisterDialog,
  },
  props: {
    teacherId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      user: auth.getUser(),
      entity: {},
      loading: false,
      photo: require("@/assets/icon_visits.png"),
      setSupervisorDialogOpen: false,
      showDialog: false,
      loadingSupervisor: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isLoading() {
      return this.loading;
    },
    fullName() {
      return (
        this.entity.name +
        " " +
        (this.entity.surname ? this.entity.surname : "")
      );
    },
    isHeadmaster() {
      return this.entity.authorities
        ? this.entity.authorities.indexOf("ROLE_HEADMASTER") !== -1
        : false;
    },
    isSameUser() {
      return this.entity ? this.entity.userId === this.user.id : false;
    },
    fullAddress() {
      const aux = [
        this.entity.address,
        this.entity.postalcode,
        this.entity.town,
        this.entity.country,
      ].filter((i) => i && i !== "");
      return aux.join(", ");
    },
  },
  mounted() {
    this._fetchData(this.teacherId);
  },
  methods: {
    _fetchData(id) {
      if (id) {
        this.loading = true;
        return TeacherEntityRepository.get(id)
          .then((response) => {
            this.entity = response;
            if (response) {
              UserDataEntityRepository.getPhoto(response.userId)
                .then((data) => {
                  if (data.size > 0) {
                    this.photo = URL.createObjectURL(data);
                  }
                })
                .catch(() =>
                  this.$log.debug(
                    "Error fetching photo for teacher with ID " + id
                  )
                );
            }
          })
          .catch(() => this.$log.debug("Error fetching teacher with ID " + id))
          .finally(() => (this.loading = false));
      } else {
        this.entity = {
          name: this.user.firstName,
          surname: this.user.lastName,
          mainEmail: this.user.email,
        };
      }
    },
    edit() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "TeacherView Edit",
          params: { backPrevious: true },
        });
      } else {
        this.$router.push({
          name: "ProfileTeacherView Edit",
          params: { backPrevious: true },
        });
      }
    },
    updateSupervisor(newSupervisor) {
      this.entity.supervisingTeacher = newSupervisor;
      this.setSupervisorDialogOpen = false;
    },
    deleteSupervising() {
      this.loadingSupervisor = true;
      TeacherEntityRepository.deleteSupervising(this.entity.id)
        .then(() => {
          this.entity.supervisingTeacher = null;
          this.$notify({
            title: this.$t("teacher.modals.supervisor_deleted.title"),
            text: this.$t("teacher.modals.supervisor_deleted.text"),
            type: "success",
          });
        })
        .catch(() =>
          this.$log.debug(
            "Error deleting supervisor from teacher with ID " + this.entity.id
          )
        )
        .finally(() => (this.loadingSupervisor = false));
    },
    getOffset,
  },
};
</script>

<style></style>
