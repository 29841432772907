var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","type":"text","rules":[function (v) { return !!v || _vm.$t('tutor.error.required'); }],"label":_vm.$t('profile.fields.fullName')},model:{value:(_vm.entity.fullName),callback:function ($$v) {_vm.$set(_vm.entity, "fullName", $$v)},expression:"entity.fullName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","type":"text","rules":[function (v) { return !!v || _vm.$t('tutor.error.required'); }],"label":_vm.$t('profile.fields.main_phone')},model:{value:(_vm.entity.mainPhone),callback:function ($$v) {_vm.$set(_vm.entity, "mainPhone", $$v)},expression:"entity.mainPhone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","type":"text","rules":[],"label":_vm.$t('profile.fields.secondary_phone')},model:{value:(_vm.entity.secondaryPhone),callback:function ($$v) {_vm.$set(_vm.entity, "secondaryPhone", $$v)},expression:"entity.secondaryPhone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","type":"text","rules":[
            function (v) { return !!v || _vm.$t('tutor.error.required'); },

            function (v) { return !v ||
              this$1.regex.EMAIL_REGEX.test(v) ||
              _vm.$t('tutor.error.regex.emailPattern'); } ],"label":_vm.$t('profile.fields.main_email')},model:{value:(_vm.entity.mainEmail),callback:function ($$v) {_vm.$set(_vm.entity, "mainEmail", $$v)},expression:"entity.mainEmail"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","type":"text","rules":[
            function (v) { return !v ||
              this$1.regex.EMAIL_REGEX.test(v) ||
              _vm.$t('tutor.error.regex.emailPattern'); } ],"label":_vm.$t('profile.fields.secondary_email')},model:{value:(_vm.entity.secondaryEmail),callback:function ($$v) {_vm.$set(_vm.entity, "secondaryEmail", $$v)},expression:"entity.secondaryEmail"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","items":_vm.relationship,"loading":_vm.relationshipLoading,"rules":[function (v) { return !!v || _vm.$t('tutor.error.required'); }],"label":_vm.$t('tutor.prop.relationship'),"item-text":function (item) { return _vm.$t(("relationship.values." + (item.name))); },"return-object":""},model:{value:(_vm.entity.relationship),callback:function ($$v) {_vm.$set(_vm.entity, "relationship", $$v)},expression:"entity.relationship"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }