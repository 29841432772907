<template>
  <div>
    <movements-list
      :movements="walletMovements"
      :totalPages="totalPages"
      :pageSettings="pagination"
      @change="updatePagination"
    ></movements-list>
    <loading-page v-if="loading"></loading-page>
  </div>
</template>

<script>
import MovementsList from "@/mockups/economy/wallet/MovementsList";
import LoadingPage from "@/components/loading-page/LoadingPage";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
export default {
  name: "TutorMovementsTab",
  components: {
    MovementsList,
    LoadingPage,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      pagination: defaultPaginationSettings,
      totalPages: null,
      walletMovements: [],
    };
  },
  created() {
    this._fetchData();
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
        },
      };
      WalletEntityRepository.getMovementsByUserId(this.userId, options)
        .then((data) => {
          this.totalPages = data.totalPages;
          this.walletMovements = data.content;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching movements for user " +
              this.userId +
              " with params: " +
              options
          )
        )
        .finally((this.loading = false));
    },
    updatePagination(newPagination) {
      this.pagination = newPagination;
      this._fetchData();
    },
  },
};
</script>
