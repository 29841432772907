var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-datatable"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.entitiesPage,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":_vm.redirectOnTableChange,"click:row":_vm.goToStudentBalanceTab},scopedSlots:_vm._u([{key:"item.expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(item.expiration))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.balance, "currency"))+" ")]}},{key:"item.userData",fn:function(ref){
var item = ref.item;
return [(item.userData)?_c('router-link',{attrs:{"to":{
                  name: 'UserData FormDetail',
                  params: { id: item.userData.id },
                }}},[_vm._v(" "+_vm._s(item.userData.id)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.goToStudentBalanceTab(item)}}},'v-icon',attrs,false),on),[_vm._v(" description ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.detail")))])])]}}],null,true)})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }