const store = {
  state: {
    //  OFFLINE: 1, PREVIEW: 2, JOINING: 3, JOINED: 4,
    sessionState: null,
    //MIXED, TABS, CAMERAS
    classroomViewMode: null,
    //flag para saber si la clase está siendo grabada
    recording: false,
    //id del dispositivo compartiendo
    deviceSharing: null,
    // lista de participantes
    // name, userId, role { STUDENT, TEACHER, HEADMASTER }, cameraOnId, online, muted, host
    participants: [],
    // DeviceDto que se corresponde con el ordenador/movil desde el que está conectado
    // deviceId, audioActive, muted, cameraOn, name
    currentDevice: null,
    // dispositivos del device actual, y los seleccionados
    hardwareDevices: {
      microphones: [],
      selectedMicrophone: null,
      cameras: [],
      selectedCamera: null,
    },
    userImages: {},
    sessions: [],
    audioActive: false,
  },
};

export default store;
