var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.uploadableActivities.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("lecture.prop.activities_submit"))+" ")]),_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('input',{ref:"uploadableActivityFileLoader",staticClass:"d-none",attrs:{"hidden":"","type":"file","accept":_vm.extensions.pdf},on:{"change":_vm.saveFile}}),_c('v-data-table',{attrs:{"headers":_vm.uploadableActivitiesHeaders,"items":_vm.uploadableActivities,"loading":_vm.loadingInteractions,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.activityInteractions[0].dateSolved != null)?_c('span',[_vm._v(" "+_vm._s(item.activityInteractions[0].content.fileName)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadContent(item.activityInteractions[0].id)}}},'v-icon',attrs,false),on),[_vm._v("file_download")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download_file")))])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":item.activityInteractions[0].dateSolved != null
                ? 'warning'
                : 'success'},on:{"click":function($event){return _vm.chooseFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" file_upload ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.activityInteractions[0].dateSolved != null ? _vm.$t("activity.actions.modify_file") : _vm.$t("activity.actions.add_file")))])])]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }