var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"container"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{staticClass:"configuration-field",attrs:{"label":_vm.$t('scheduleCalendar.availability_calendar.copy')},model:{value:(_vm.copy),callback:function ($$v) {_vm.copy=$$v},expression:"copy"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('date-and-hour-picker',{staticClass:"configuration-field",attrs:{"datePickerProp":{
              minDate: _vm.currentDate.toISOString(),
              data: _vm.limit,
              label: 'scheduleCalendar.availability_calendar.limit',
            },"disabled":!_vm.copy},on:{"update-time":function($event){return _vm.updateLimit.apply(void 0, arguments)}}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.changed,"loading":_vm.loading},on:{"click":_vm.onSave}},[_c('v-icon',[_vm._v("save")]),_c('span',[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.fields.timeZone"))+" : "+_vm._s(_vm.$t(("timezones." + (_vm.schedule.timeZone.name)))))])])],1),_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" "+_vm._s(_vm.$t("scheduleCalendar.today"))+" ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm._f("dateTimeWithoutTz")(_vm.focus.split("-").map(function (item) { return parseInt(item); }),"monthAndYear"))+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-1",attrs:{"outlined":""}},on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeView('day')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("day")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeView('week')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("week")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeView('month')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("month")))])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":_vm.height}},[_c('v-calendar',{ref:"availability_calendar",attrs:{"weekdays":[1, 2, 3, 4, 5, 6, 0],"events":_vm.availabilities,"event-color":_vm.getEventColor,"short-weekdays":false,"interval-height":25,"interval-minutes":30,"interval-count":48,"color":"primary","type":_vm.type,"locale":_vm.$i18n.locale},on:{"click:more":_vm.onDayClick,"click:date":_vm.onDayClick,"change":_vm.updateRange},scopedSlots:_vm._u([{key:"event",fn:function(ref){
            var event = ref.event;
return [_c('div',{staticClass:"event-div",attrs:{"id":event.start},on:{"mousedown":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.startDrag.apply(void 0, [ event ].concat( argsArray ))},"mouseover":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.checkEvent.apply(void 0, [ event ].concat( argsArray ))},"mouseup":_vm.endDrag}},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])])],1)]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),_c('modal-dialog',{attrs:{"content":_vm.$t('scheduleCalendar.availability_calendar.alert.content'),"dialog":_vm.dialog,"submitClass":"success","submitText":_vm.$t('actions.save'),"title":_vm.$t('scheduleCalendar.availability_calendar.alert.title'),"titleClass":"warning white--text","titleIcon":"mdi-alert"},on:{"submit":_vm.persistConfig,"cancel":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }