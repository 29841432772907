<template>
  <div>
    <div
      ref="messagesListWindow"
      class="chat__messages"
      :class="limitTop ? 'margin-top' : null"
    >
      <div class="chat__messages__loading" v-if="!showChatMessages">
        <div class="chat__messages__loading-animation">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div
        ref="messagesList"
        v-if="chat.messages != null && messageList.length > 0"
      >
        <div
          v-for="m in messageList"
          class="chat__message"
          :class="[
            { 'chat__message--system': isSystemMessage(m) },
            {
              'chat__message--other': !isSystemMessage(m) && !isCurrentUser(m),
            },
          ]"
          :key="m.id"
        >
          <div class="chat__message__line__date">
            {{ m.dateSent }}
          </div>
          <div class="chat__message__line">
            <span v-if="!isSystemMessage(m)" class="chat__message__line__name">
              {{ m.sender ? m.sender.name : $t("chat.system") }}
            </span>

            <span
              v-if="!isSystemMessage(m) || chat.channelType != 'USER_ADMINS'"
              class="chat__message__line__text"
              >{{ m.text }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="chat__footer messages">
      <div class="chat__actions">
        <input
          class="chat__actions__input"
          v-model="text"
          :placeholder="$t('chat.input.placeholder')"
          @keyup.enter="send()"
          :disabled="
            chat.channelType === 'NOTIFICATION' ||
            chat.channelType === 'ADMINS_NOTIFICATIONS' ||
            !chat.canSendMessages
          "
        />
        <button type="button" class="chat__actions__send" @click="send()">
          <icon
            name="chat-send"
            width="24"
            height="24"
            :title="$t('chat.actions.send')"
          ></icon>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ChatRepository from "@/repositories/components/ChatRepository";
import auth from "@/common/auth";

export default {
  props: {
    newMessage: {
      type: Object,
    },
    chatId: {
      type: Number,
    },
    notifications: {
      type: Number,
    },
    limitTop: { type: Boolean },
  },
  data() {
    return {
      showChatMessages: false,
      text: null,
      chat: {
        messages: [],
        name: null,
        id: null,
        messageType: null,
      },
      user: auth.getUser(),
    };
  },
  computed: {
    messageList() {
      return this.chat.messages.map((m) => {
        if (!m.text && this.chat.channelType !== "USER_ADMINS") {
          switch (m.messageType) {
            case "SYSTEM_USER_JOINED":
              m.text = `${this.$i18n.t("chat.user-added", {
                user: `${m.sender.name}`,
              })}`;
              break;
            case "SYSTEM_USER_LEFT":
              m.text = `${this.$i18n.t("chat.user-left", {
                user: `${m.sender.name}`,
              })}`;
              break;
          }
        }
        return m;
      });
    },
  },
  watch: {
    newMessage(newValue) {
      if (newValue) {
        this.chat.messages.push(newValue);
        this.refreshScroll();
        this.updateNotifications();
      }
    },
    chatId: {
      async handler() {
        await this.getChat();
        this.refreshScroll();
      },
      immediate: true,
    },
  },
  methods: {
    refreshScroll() {
      this.$nextTick(
        () =>
          (this.$refs.messagesListWindow.scrollTop =
            this.$refs.messagesList.scrollHeight)
      );
    },
    async getChat() {
      this.showChatMessages = false;

      this.chat = await ChatRepository.getChat(this.chatId);

      // We send an event to the parent component to update the chat name
      this.$emit("updateChatName", this.chat);

      if (this.notifications > 0) {
        this.updateNotifications();
      }

      this.showChatMessages = true;
    },
    async sendMessage() {
      if (this.text != null && this.text !== "") {
        await ChatRepository.sendMessage(this.chatId, {
          text: this.text,
        });
        this.text = null;
      }
    },
    async send() {
      await this.sendMessage();
      this.refreshScroll();
    },
    updateNotifications() {
      ChatRepository.updateNotifications(this.chatId);
    },
    isSystemMessage(message) {
      return (
        message.messageType === "SYSTEM_USER_JOINED" ||
        message.messageType === "SYSTEM_USER_LEFT"
      );
    },
    isCurrentUser(message) {
      return message.sender?.id === this.user.id;
    },
  },
};
</script>
<style scoped>
.margin-top {
  top: 40px;
}
</style>
