<template>
  <v-autocomplete
    :items="filteredLanguages"
    v-model="languageSelected"
    :label="label ? label : $t('languages.label')"
    :outlined="outlined"
    :dense="dense"
    :append-icon="append_icon"
    :attach="attach"
    :clearable="clearable"
    :disabled="disabled"
    :rules="rules"
    :loading="loading"
    :class="required ? 'required' : ''"
    :search-input.sync="languageSearch"
    :item-value="itemValue"
    :return-object="returnObject"
    @change="valueChanged"
  >
    <template v-slot:item="{ item }">
      <v-chip
        v-if="showChips"
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.text | uppercase }}</span>
      </v-chip>
      <span v-else>{{ item.text | uppercase }}</span>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip
        v-if="showChips"
        :color="getChipBackColor(item)"
        :text-color="getChipTextColor(item)"
      >
        <span>{{ item.text | uppercase }} </span>
      </v-chip>
      <span v-else>{{ item.text | uppercase }} </span>
    </template>
  </v-autocomplete>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import { translateWithLocale } from "@/common/translation-utils";

const LanguageEntityRepository = RepositoryFactory.get(
  "LanguageEntityRepository"
);

export default {
  name: "LanguageSelector",
  props: {
    value: {
      required: false,
      default: () => {
        return {};
      },
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    attach: {
      required: false,
      default: false,
    },
    filter: {
      type: Array,
      required: false,
      default: () => [],
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showChips: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectById: {
      type: Number,
      required: false,
    },
    "item-value": {
      type: [String, Array, Function],
      required: false,
      default: "id",
    },
    "return-object": {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      languageItems: [],
      languageSelected: {},
      languageSearch: null,
    };
  },
  computed: {
    filteredLanguages() {
      const translatedLanguages = this.languageItems.map((el) => {
        return {
          id: el.id,
          text: translateWithLocale("languages", el, "name"),
        };
      });
      return this.languageSearch
        ? translatedLanguages.filter((item) =>
            item.text
              .replace(/\s+/g, "")
              .toUpperCase()
              .includes(this.languageSearch.replace(/\s+/g, "").toUpperCase())
          )
        : translatedLanguages;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.languageSelected = newValue;
      },
      immediate: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      LanguageEntityRepository.getAll()
        .then((res) => (this.languageItems = res))
        .catch(() => this.$log.debug("Error fetching languages"))
        .finally(() => (this.loading = false));
    },
    valueChanged(value) {
      this.$emit("change", value);
      this.$emit("input", value);
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
