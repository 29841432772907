import ClassroomView from "../ClassroomView.vue";

const routes = [
  {
    path: "/classroom/:id",
    name: "Classroom",
    component: ClassroomView,
    meta: {
      public: true,
      label: "menuBar.home",
      layout: "AppLayoutClassroom",
    },
  },
];

export default routes;
