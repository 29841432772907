<template>
  <div class="clrm-header__toolbar-primary">
    <ClrmButton
      v-if="classroomMode === 'teacher'"
      @click="$emit('click', 'participants')"
      icon="participants"
      :iconWidth="size"
      :iconHeight="size"
      align="top"
      >{{ $t("classroom.toolbar.participants") }}</ClrmButton
    >
    <ClrmButton
      v-if="classroomMode === 'teacher'"
      @click="$emit('click', 'chat')"
      icon="chat"
      :iconWidth="size"
      :iconHeight="size"
      align="top"
      :badge="notifications"
      >{{ $t("classroom.toolbar.chat") }}</ClrmButton
    >
    <ClrmButton
      v-if="connected && classroomMode === 'teacher'"
      @click="$emit('click', 'share-screen')"
      icon="share-screen-action"
      :iconWidth="size"
      :iconHeight="size"
      align="top"
      :badge="screenSharing"
      >{{ $t("classroom.toolbar.share-screen") }}</ClrmButton
    >
    <!--    <ClrmButton-->
    <!--      v-if="classroomMode === 'teacher'"-->
    <!--      @click="$emit('click', 'evaluation')"-->
    <!--      icon="evaluation"-->
    <!--      :iconWidth="size"-->
    <!--      :iconHeight="size"-->
    <!--      align="top"-->
    <!--      >{{ $t("classroom.toolbar.evaluation") }}</ClrmButton-->
    <!--    >-->
    <ClrmButton
      v-if="connected && classroomMode === 'teacher'"
      class="dot-badge"
      @click="$emit('click', 'record')"
      :iconWidth="size"
      :iconHeight="size"
      icon="record"
      align="top"
      :badge="recording"
      ><span v-if="!recording">{{ $t("classroom.toolbar.record") }}</span
      ><span v-if="recording">{{
        $t("classroom.toolbar.stop-record")
      }}</span></ClrmButton
    >
    <ClrmButton
      v-if="connected && classroomMode === 'teacher'"
      @click="$emit('click', 'settings')"
      icon="settings"
      :iconWidth="size"
      :iconHeight="size"
      align="top"
      >{{ $t("classroom.toolbar.settings") }}</ClrmButton
    >
    <ClrmButton
      v-if="classroomMode === 'student'"
      @click="$emit('click', 'participants')"
      icon="participants-student"
      :iconWidth="42"
      :iconHeight="42"
      align="top"
      >{{ $t("classroom.toolbar.participants") }}</ClrmButton
    >
    <ClrmButton
      v-if="classroomMode === 'student'"
      @click="$emit('click', 'chat')"
      icon="chat-student"
      :iconWidth="42"
      :iconHeight="42"
      align="top"
      :badge="notifications"
      >{{ $t("classroom.toolbar.chat") }}</ClrmButton
    >
    <!--    <ClrmButton-->
    <!--      v-if="classroomMode === 'student'"-->
    <!--      @click="$emit('click', 'evaluation')"-->
    <!--      icon="evaluation-student"-->
    <!--      :iconWidth="42"-->
    <!--      :iconHeight="42"-->
    <!--      align="top"-->
    <!--      >{{ $t("classroom.toolbar.evaluation") }}</ClrmButton-->
    <!--    >-->
    <ClrmButton
      v-if="connected && classroomMode === 'student'"
      @click="$emit('click', 'settings')"
      icon="settings-student"
      :iconWidth="42"
      :iconHeight="42"
      align="top"
      >{{ $t("classroom.toolbar.settings") }}</ClrmButton
    >
  </div>
</template>

<script>
import ClrmButton from "@/components/clrm/clrm-button/ClrmButton.vue";
import {
  mapNotificationsGetter,
  getChat,
} from "@/common/mapNotificationsGetter";

export default {
  name: "ClrmToolbarPrimary",
  components: { ClrmButton },
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
    screenSharing: {
      type: Boolean,
    },
    connected: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    },
    recording: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chat: getChat,
    };
  },
  computed: {
    ...mapNotificationsGetter(["notifications"]),
  },
};
</script>
