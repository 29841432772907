// Admin profile access
const navBarItemsAdmin = [
  {
    title: "contentEditor.navbar.profile",
    icon: "mdi-account",
    link: "ContentEditorView Detail",
    notification: false,
    showed: true,
  },
];

const navBarItemsProfile = [
  {
    title: "contentEditor.navbar.profile",
    icon: "mdi-account",
    link: "ProfileContentEditorView Detail",
    notification: false,
    showed: true,
  },
];

export { navBarItemsAdmin, navBarItemsProfile };
