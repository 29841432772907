<template>
  <v-select
    :items="topicTypeItems"
    item-value="id"
    v-model="topicTypeSelected"
    :label="label"
    :outlined="outlined"
    :solo="solo"
    :dense="dense"
    :append-icon="append_icon"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    :class="required ? 'required' : ''"
    :item-text="(item) => (item.name ? $t(`topicType.types.${item.name}`) : '')"
    return-object
  >
  </v-select>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TopicTypeEntityRepository = RepositoryFactory.get(
  "TopicTypeEntityRepository"
);

export default {
  name: "TopicTypeSelector",
  props: {
    value: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      default: function () {
        this.$t("topic.prop.type");
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.topicTypeSelected = newValue;
      },
      immediate: true,
    },
    topicTypeSelected(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.topicTypeSelected = this.value;
    this.fetchData();
  },
  data() {
    return {
      topicTypeItems: [],
      topicTypeSelected: {},
      loading: false,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      TopicTypeEntityRepository.getAll()
        .then((res) => {
          this.topicTypeItems = res.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
