<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1s"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ userFullName(item) }}
          </template>
          <template v-slot:[`item.dateStart`]="{ item }">
            <span v-if="item.dateStart">
              {{ item.dateStart | dateTimeWithTz }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-col class="d-md-block" align="left">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    icon
                    color="warning"
                    @click="goToEdit(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("actions.edit") }}</span>
              </v-tooltip>
            </v-col>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const UserDataRepository = RepositoryFactory.get("UserDataEntityRepository");

export default {
  name: "UsersWithoutBillingIdList",
  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("wallet.prop.name"),
          value: "name",
        },
        {
          text: this.$t("wallet.prop.email"),
          value: "mainEmail",
        },
        {
          text: this.$t("wallet.prop.mainPhone"),
          value: "mainPhone",
        },
        {
          text: this.$t("wallet.prop.createdDate"),
          value: "dateStart",
        },
        {
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.onPaginationChange();
      },
      deep: true,
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      const sortMapping = {
        name: "user.firstName",
        mainEmail: "user.email",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      return UserDataRepository.usersWithoutBillingId(options)
        .then((res) => {
          this.items = res.content;
          this.totalItems = res.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching teacher with ID " + id))
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this._fetchData();
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
    userFullName(user) {
      return user.name + " " + user.surname;
    },
    goToEdit(item) {
      if (item.studentId) {
        this.$router.push({
          name: "StudentView Edit",
          params: {
            id: item.studentId,
            backPrevious: true,
          },
        });
      } else if (item.companyId) {
        this.$router.push({
          name: "CompanyView Edit",
          params: {
            id: item.companyId,
            backPrevious: true,
          },
        });
      } else {
        this.$router.push({
          name: "AdminEditProfile",
          params: {
            id: item.userId,
            backPrevious: true,
          },
        });
      }
    },
  },
};
</script>

<style></style>
