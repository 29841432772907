<template>
  <v-container fluid v-if="lectures">
    <v-alert type="info" elevation="2" dismissible outlined>
      {{ $t("lecture.headers.close-list-info") }}
    </v-alert>
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="rows-clickable"
          :headers="headers"
          :items="lectures"
          :loading="loading"
          @click:row="lectureDetail"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.productName`]="{ item }">
            <span v-if="item.productName">
              {{
                item.productName === "-"
                  ? $t("lecture.levelTest")
                  : item.productName
              }}
            </span>
          </template>

          <template v-slot:[`item.startTime`]="{ item }">
            <span v-if="item.startTime">
              {{ item.startTime | dateTimeWithTz("medium") }}
            </span>
          </template>

          <template v-slot:[`item.endTime`]="{ item }">
            <span v-if="item.endTime">
              {{ item.endTime | dateTimeWithTz("medium") }}
            </span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <span v-if="item.state">
              {{ $t(`lecture.states.${item.state}`) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip open-delay="400" top>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-btn
                    color="success"
                    :disabled="!userCanJoin(item)"
                    icon
                    small
                    @click.stop="joinToLecture(item)"
                  >
                    <v-icon> login </v-icon>
                  </v-btn>
                </span>
              </template>
              <span v-if="userCanJoin(item)">{{ $t("lecture.join") }}</span>
              <span v-else>{{ $t("lecture.join-not-ready") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { joinToLecture, userCanJoin } from "@/common/lecture-common";

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

export default {
  name: "LectureList",
  data() {
    return {
      lectures: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("lecture.prop.course"),
          value: "productName",
        },
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.state"),
          value: "state",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this.getLectures();
  },
  methods: {
    getLectures() {
      this.loading = true;
      LectureEntityRepository.getClose().then((response) => {
        this.lectures = response;
        this.loading = false;
      });
    },
    lectureDetail(lecture) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Lecture Detail",
          params: {
            id: lecture.id,
            backPrevious: true,
          },
        });
      }
    },
    userCanJoin,
    joinToLecture,
  },
};
</script>
