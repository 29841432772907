import ProductCreateForm from "@/mockups/economy/products/ProductCreateForm";
import ProductEditForm from "@/mockups/economy/products/ProductEditForm";
import AdminProductList from "@/mockups/economy/products/AdminProductList";
import ProductList from "@/mockups/economy/products/ProductList";
import ProductDetail from "@/mockups/economy/products/ProductDetail";
import TpvOk from "@/mockups/economy/wallet/components/TpvOk";
import TpvKo from "@/mockups/economy/wallet/components/TpvKo";

const routes = [
  {
    path: "/admin/products",
    name: "Admin Product List",
    component: AdminProductList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "product.headers.list.title",
    },
  },
  {
    path: "/products",
    name: "Product List",
    component: ProductList,
    meta: {
      public: true,
      label: "product.headers.list.title",
    },
  },
  {
    path: "/products/english",
    name: "Product List English",
    component: ProductList,
    meta: {
      public: true,
      label: "product.headers.list.english",
    },
  },
  {
    path: "/products/spanish",
    name: "Product List Spanish",
    component: ProductList,
    meta: {
      public: true,
      label: "product.headers.list.spanish",
    },
  },
  {
    path: "/products/chinese",
    name: "Product List Chinese",
    component: ProductList,
    meta: {
      public: true,
      label: "product.headers.list.chinese",
    },
  },
  {
    path: "/products/new",
    name: "Create Product",
    component: ProductCreateForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "product.headers.form",
    },
  },
  {
    path: "/products/:id/edit",
    name: "Edit Product",
    component: ProductEditForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "product.headers.form",
    },
  },
  {
    path: "/products/:id",
    name: "Product Detail",
    component: ProductDetail,
    meta: {
      public: true,
      label: "product.headers.product_detail",
    },
  },
  {
    path: "/tpv-pago-ok",
    name: "TpvOk",
    component: TpvOk,
    meta: {
      public: true,
    },
  },
  {
    path: "/tpv-pago-ko",
    name: "TpvKo",
    component: TpvKo,
    meta: {
      public: true,
    },
  },
];

export default routes;
