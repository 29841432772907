import { HTTP } from "@/common/http-common";
const RESOURCE_NAME = "/virtual-classroom/activities";

export default {
  async getByLectureId(id) {
    const response = await HTTP.get(`${RESOURCE_NAME}?lectureId=${id}`);
    return response.data;
  },
  async saveResults(entity) {
    const response = await HTTP.post(`${RESOURCE_NAME}/result`, entity);
    return response.data;
  },
  async updateContent(entity) {
    const response = await HTTP.put(`${RESOURCE_NAME}/content`, entity);
    return response.data;
  },
  async save(entity) {
    const response = await HTTP.post(`${RESOURCE_NAME}`, entity);
    return response.data;
  },
};
