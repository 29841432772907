<template>
  <v-container>
    <v-card>
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="6" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!resolved">
        <v-form v-model="validForm">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-row justify="center">
                <span>
                  {{ $t("account.notifications.enter_password") }}
                </span>
              </v-row>
              <v-row>
                <v-text-field
                  class="form-control required"
                  autocomplete="new-password"
                  v-model="user.password"
                  name="password"
                  maxLength="100"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword1 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.form.password')"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    (value) =>
                      value.length <= 100 ||
                      $t('account.form.validation.password_less_chars'),
                    (value) =>
                      value.length > 3 ||
                      this.$t('account.form.validation.password_more_chars'),
                  ]"
                  :type="hidePassword1 ? 'password' : 'text'"
                  @click:append="() => (hidePassword1 = !hidePassword1)"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  class="form-control required"
                  autocomplete="new-password"
                  v-model="user.password2"
                  name="password2"
                  prepend-inner-icon="vpn_key"
                  :append-icon="hidePassword2 ? 'visibility' : 'visibility_off'"
                  :label="$t('account.actions.repeat_password')"
                  :rules="[
                    (v) =>
                      !!v || $t('account.form.validation.password_required'),
                    passwordConfirmationRule,
                  ]"
                  :type="hidePassword2 ? 'password' : 'text'"
                  @click:append="() => (hidePassword2 = !hidePassword2)"
                  @keypress.enter="submitNewPassword"
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn class="primary mt-4" @click="submitNewPassword">
                  {{ $t("account.actions.reset_password") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-6" v-if="resolved">
        <v-row align="center" justify="center">
          <div>
            <v-icon v-if="success" color="success" x-large>done</v-icon>
            <v-icon v-if="!success" color="error" x-large>warning</v-icon>
            <span>{{ response }}</span>
          </div>
        </v-row>
        <v-row v-if="success" justify="center" class="mt-6">
          <span
            >{{ $t("account.notifications.now_you_can") }}
            <router-link :to="{ name: 'Login' }">{{
              $t("account.actions.log_in")
            }}</router-link>
            {{ $t("account.notifications.normally") }}
          </span>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  data() {
    return {
      user: {
        password: "",
        password2: "",
      },
      validForm: true,
      hidePassword1: true,
      hidePassword2: true,
      resolved: false,
      response: "",
      success: false,
      key: "",
    };
  },
  created() {
    this.resolved = false;
    this.key = this.$route.query.key;
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.user.password === this.user.password2 ||
        this.$t("account.form.validation.password_match");
    },
  },
  methods: {
    submitNewPassword() {
      if (this.validForm) {
        AccountRepository.resetPasswordFinish({
          key: this.key,
          newPassword: this.user.password,
        })
          .then((res) => {
            if (!res.isAxiosError) {
              this.success = true;
              this.response = this.$t("account.notifications.password_changed");
            }
          })
          .catch((e) => {
            if (
              e.response.headers["x-app-error"] ===
              "account.errors.reset_key_expired"
            ) {
              this.$router.push({ name: "ResetPasswordRequest" });
            }
          })
          .finally((this.resolved = true));
      }
    },
  },
};
</script>
