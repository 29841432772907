<template>
  <v-container class="pa-0" fluid>
    <v-card>
      <v-card-text v-if="!isLoading">
        <v-row justify="end" no-gutters>
          <v-icon @click="() => $emit('close')">close</v-icon>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <div class="text-center">
              <v-img
                :src="photo"
                height="150"
                width="150"
                style="display: inline-block"
              ></v-img>
            </div>
          </v-col>
          <v-col class="text-center text-md-left" cols="12" md="8">
            <span class="text-h4">{{ teacher.firstName }}</span>
            <br />
            <span class="text-h5">{{ teacher.lastName }}</span>
            <br />
            <v-icon class="pb-2" v-if="teacher.genre === `MALE`">male</v-icon>
            <v-icon class="pb-2" v-else>female</v-icon>
            <br />
            <span class="text-h6">{{ teacher.country }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <loading-page v-else></loading-page>
    </v-card>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  name: "TeacherLectureDetails",
  components: { LoadingPage },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
    photo: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      teacher: null,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  created() {
    this.loading = true;
    TeacherEntityRepository.getLectureTeacherDetail(this.teacherId)
      .then((res) => (this.teacher = res))
      .finally(() => (this.loading = false));
  },
};
</script>
