<template>
  <evaluation-form
    v-model="evaluation"
    title="studentEvaluation.headers.form"
    label="studentEvaluation.prop.comments"
    :loading="loading"
    :editable="editable"
    @cancel="$emit('cancel')"
    @save="save"
  ></evaluation-form>
</template>
<script>
import EvaluationForm from "@/mockups/admin/components/EvaluationForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEvaluationEntityRepository = RepositoryFactory.get(
  "StudentEvaluationEntityRepository"
);

export default {
  name: "StudentEvaluationForm",
  components: {
    EvaluationForm,
  },
  props: {
    studentId: {
      required: true,
    },
    lectureId: {
      required: true,
    },
    editable: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      evaluation: { ...this.value } || {},
      loading: false,
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.evaluation.studentId = this.studentId;
      this.evaluation.lectureId = this.lectureId;
      StudentEvaluationEntityRepository.save(this.evaluation)
        .then((evaluationSaved) => {
          this.$notify({
            title: !this.evaluation.id
              ? this.$t("studentEvaluation.messages.create_success")
              : this.$t("studentEvaluation.messages.update_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", evaluationSaved);
        })
        .catch(() =>
          this.$log.debug("Error saving student evaluation: " + this.evaluation)
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
