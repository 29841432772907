<template>
  <v-container>
    <generic-profile-form
      v-if="!loading"
      v-model="user"
      :custom_title="title"
      :account_details="true"
      :include-legal="isTutorForm"
      @save="save"
      @back="back"
    ></generic-profile-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import GenericProfileForm from "@/account/profile/GenericProfileForm";
import { deleteTempFile } from "@/common/file-utils";
import auth from "@/common/auth.js";
const AccountRepository = RepositoryFactory.get("AccountRepository");
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    LoadingPage,
    GenericProfileForm,
  },
  name: "BasicProfileForm",
  data() {
    return {
      loading: false,
      user: {
        login: "",
        langKey: "en",
        acceptWhats: false,
        acceptEmails: false,
        acceptCalls: false,
        acceptRGPD: true,
      },
    };
  },
  mounted() {
    this._fetchData();
  },
  beforeDestroy() {
    deleteTempFile(this.user, "photo");
  },
  computed: {
    title() {
      if (this.$route.query.role) {
        return (
          this.$t("actions.new") +
          " " +
          this.$t(`user_management.roles.${this.$route.query.role}`)
        );
      }
      return null;
    },
    isTutorForm() {
      return this.$route.query.role == "ROLE_TUTOR";
    },
  },
  methods: {
    _fetchData() {
      if (this.$route.params.id) {
        this.loading = true;
        UserDataEntityRepository.getByUser()
          .then((res) => {
            this.user = res;
          })
          .catch(() =>
            this.$log.debug("Error fetching user data for current user")
          )
          .finally(() => (this.loading = false));
      }
    },
    back() {
      this.$router.goToUserHome({ params: { backAction: true } });
    },
    save() {
      this.loading = true;
      this.checkSecondaryEmail();
      AccountRepository.registerBasicUser(this.user, this.$route.query.role)
        .then(() => {
          this.$notify({
            title: "Registered",
            text: this.$t("account.notifications.successfull_registered"),
            type: "success",
          });
          this.user.photo = null;
          return auth.reloadUserData().finally(() => {
            this.$router.goToUserHome({ params: { backAction: true } });
          });
        })
        .finally(() => (this.loading = false));
    },
    checkSecondaryEmail() {
      this.user.secondaryEmail =
        this.user.secondaryEmail == "" ? undefined : this.user.secondaryEmail;
    },
  },
};
</script>

<style></style>
