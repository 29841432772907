<template>
  <div>
    <div
      class="chat__roomlist__item"
      :style="backgroundColorChat(chat)"
      v-for="chat in [...renderedChatList]"
      v-bind:key="chat.id"
      @click="getChat(chat)"
    >
      <div class="chat__roomlist__item__icon">
        <v-avatar size="36px">
          <v-icon
            v-if="getChatIcon(chat)"
            class="chat__roomlist__item__icon"
            large
            color="primary"
            >{{ getChatIcon(chat) }}</v-icon
          >
          <img v-else :src="getChatImage(chat)" />
        </v-avatar>
      </div>
      <div class="chat__roomlist__item__content">
        <div class="chat__roomlist__item__title">
          {{ chat.chatName }}
        </div>
        <div class="chat__roomlist__item__date" v-if="chat.lastMessage.text">
          {{ chat.lastMessage.dateSent | dateTimeWithTz("medium") }}
        </div>
        <div class="chat__roomlist__item__lastmessage" v-if="chat.lastMessage">
          <div
            class="chat__roomlist__item__lastmessage__message"
            v-if="chat.lastMessage.messageType === 'USER'"
          >
            <strong>
              <em>{{ chat.lastMessage.sender.name }}:</em>
            </strong>
            <span>{{ (" " + chat.lastMessage.text) | subStr() }}</span>
          </div>
          <div
            class="chat__roomlist__item__lastmessage__message"
            v-else-if="chat.channelType !== 'USER_ADMINS'"
          >
            {{
              chat.lastMessage.text ||
              $t("chat.user-added", {
                user: `${chat.lastMessage.sender.name}`,
              })
            }}
          </div>
        </div>
      </div>
      <div
        class="chat__roomlist__item__badge badge"
        v-if="chat.notifications > 0"
      >
        {{ chat.notifications }}
      </div>
      <v-btn
        v-if="
          chat.channelType !== 'ADMINS_NOTIFICATIONS' &&
          chat.channelType !== 'NOTIFICATION'
        "
        icon
        :loading="archiveLoading"
        @click.stop="updateArchiveStatus(chat)"
      >
        <v-icon color="primary">{{
          chat.archived ? "mdi-archive-minus" : "mdi-archive-plus"
        }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import auth from "@/common/auth";
import ChatRepository from "@/repositories/components/ChatRepository";
import { getChatIcon } from "@/enumerates/ChatIcon";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getStudentNames, renderChatList } from "@/components/chat/chat";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

const defaultImage = require("@/assets/icon_visits.png");

export default {
  name: "ChatList",
  props: {
    chatRoomList: { type: Array },
    getChat: { type: Function },
  },
  data() {
    return {
      archiveLoading: false,
      userImages: {},
      currentUser: auth.getUser(),
    };
  },
  created() {
    this.getImages();
  },
  computed: {
    ...mapAuthGetter(["isTutor"]),
    renderedChatList() {
      return renderChatList(this.chatRoomList);
    },
  },
  filters: {
    subStr(string) {
      return string.length > 26 ? string.substring(0, 26) + "..." : string;
    },
  },
  watch: {
    chatRoomList: {
      handler(newVal, oldVal) {
        let newValIds = JSON.stringify(newVal.map((el) => el.id));
        let oldValIds = JSON.stringify(oldVal.map((el) => el.id));
        if (
          oldVal.length === 0 ||
          Object.keys(this.userImages).length === 0 ||
          newValIds !== oldValIds
        ) {
          this.getImages();
        }
      },
    },
  },
  methods: {
    updateArchiveStatus(chat) {
      this.archiveLoading = true;
      ChatRepository.updateArchivedStatus(chat.userChannelId)
        .then(() => this.$emit("change"))
        .finally(() => (this.archiveLoading = false));
    },
    backgroundColorChat(chat) {
      return chat.archived ? "background-color: #e7e7e7" : "";
    },
    getUserImageSrc(userId) {
      UserDataEntityRepository.getPhoto(userId)
        .then((data) => {
          if (data?.size > 0) {
            this.$set(this.userImages, userId, URL.createObjectURL(data));
          }
        })
        .catch(() => {})
        .finally(() => {
          if (!this.userImages[userId]) {
            this.$set(this.userImages, userId, defaultImage);
          }
        });
    },
    getChatImage(chat) {
      if (chat.channelType === "USER_ADMINS") {
        const user = chat.users.find(
          (u) => !u.authorities.includes("ROLE_ADMIN")
        );
        return user ? this.userImages[user.id] : null;
      } else if (chat.users.length === 2) {
        const user = chat.users.find((u) => u.id !== this.currentUser.id);
        return user ? this.userImages[user.id] : null;
      }
    },
    getImages() {
      let userIds = [];
      for (let i = 0; i < this.chatRoomList.length; i++) {
        if (this.chatRoomList[i].channelType === "USER_ADMINS") {
          let userForImage = this.chatRoomList[i].users.find(
            (user) => !user.authorities.includes("ROLE_ADMIN")
          );
          if (userForImage && !userIds.find((id) => id === userForImage.id)) {
            userIds.push(userForImage.id);
          }
        } else if (this.chatRoomList[i].users.length === 2) {
          for (let j = 0; j < 2; j++) {
            if (
              !userIds.find((id) => id === this.chatRoomList[i].users[j].id) &&
              this.chatRoomList[i].users[j].id !== this.currentUser.id
            ) {
              userIds.push(this.chatRoomList[i].users[j].id);
            }
          }
        }
      }
      userIds.forEach((id) => this.getUserImageSrc(id));
    },
    getChatIcon,
  },
};
</script>
