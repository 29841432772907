const types = {
  DEFAULT: [
    { text: "chat.chat_type.DEFAULT", value: "DEFAULT" },
    { text: "chat.chat_type.NOTIFICATION", value: "NOTIFICATION" },
    { text: "chat.chat_type.USER_ADMINS", value: "USER_ADMINS" },
  ],
  ROLE_ADMIN: [
    {
      text: "chat.chat_type.ADMINS_NOTIFICATIONS",
      value: "ADMINS_NOTIFICATIONS",
    },
  ],
  ROLE_TEACHER: [
    { text: "chat.chat_type.TEACHERS", value: "TEACHERS" },
    { text: "chat.chat_type.STUDENT_TEACHER", value: "STUDENT_TEACHER" },
    { text: "chat.chat_type.EDITION_COURSE", value: "EDITION_COURSE" },
    { text: "chat.chat_type.TUTOR_TEACHER", value: "TUTOR_TEACHER" },
    { text: "chat.chat_type.SUPERVISION", value: "SUPERVISION" },
  ],
  ROLE_TEACHER_CANDIDATE: [
    { text: "chat.chat_type.TEACHERS", value: "TEACHERS" },
    { text: "chat.chat_type.STUDENT_TEACHER", value: "STUDENT_TEACHER" },
    { text: "chat.chat_type.EDITION_COURSE", value: "EDITION_COURSE" },
    { text: "chat.chat_type.TUTOR_TEACHER", value: "TUTOR_TEACHER" },
    { text: "chat.chat_type.SUPERVISION", value: "SUPERVISION" },
  ],
  ROLE_STUDENT: [
    { text: "chat.chat_type.STUDENT_TEACHER", value: "STUDENT_TEACHER" },
    { text: "chat.chat_type.EDITION_COURSE", value: "EDITION_COURSE" },
  ],
  ROLE_STUDENT_CANDIDATE: [
    { text: "chat.chat_type.STUDENT_TEACHER", value: "STUDENT_TEACHER" },
    { text: "chat.chat_type.EDITION_COURSE", value: "EDITION_COURSE" },
  ],
  ROLE_TUTOR: [
    { text: "chat.chat_type.TUTOR_TEACHER", value: "TUTOR_TEACHER" },
  ],
};
function getChannelTypeList(roleList) {
  let typeList = types.DEFAULT;
  roleList.forEach((role) => {
    if (types[role]) {
      typeList = [...typeList, ...types[role]];
    }
  });
  return typeList;
}
export { getChannelTypeList };
