<template>
  <v-autocomplete
    v-model="levelSelected"
    :append-icon="append_icon"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :items="levelItems"
    :label="label"
    :loading="loading"
    :outlined="outlined"
    :rules="rules"
    item-value="id"
    :search-input.sync="searchInput"
    return-object
    no-filter
  >
    <template v-slot:selection="{ item }">
      <v-chip
        :color="getChipBackColor(item.language)"
        :text-color="getChipTextColor(item.language)"
      >
        <span v-if="showLanguage">
          {{
            (item.name + " - " + $t("languages." + item.language.name))
              | uppercase
          }}
        </span>
        <span v-else>{{ item.name }}</span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-chip
        :color="getChipBackColor(item.language)"
        :text-color="getChipTextColor(item.language)"
      >
        <span v-if="showLanguage">
          {{
            (item.name + " - " + $t("languages." + item.language.name))
              | uppercase
          }}
        </span>
        <span v-else>{{ item.name }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { getChipBackColor, getChipTextColor } from "@/common/customization";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LevelEntityRepository = RepositoryFactory.get("LevelEntityRepository");

export default {
  name: "LevelSelector",
  props: {
    value: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      default: function () {
        this.$t("level.name");
      },
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    languageFilter: {
      type: Number,
      required: false,
    },
    showLanguage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      levelItems: [],
      levelSelected: null,
      loading: false,
      searchInput: null,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.levelSelected = newValue;
      },
      immediate: true,
    },
    levelSelected(val) {
      this.$emit("input", val);
    },
    languageFilter() {
      this.fetchData();
    },
    searchInput: {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      const options = {
        params: {
          language: this.languageFilter?.toString(),
          search: this.searchInput,
        },
      };
      LevelEntityRepository.getAll(options)
        .then((res) => {
          this.levelItems = res.content;
          this.levelSelected = this.levelItems.find(
            (level) => level.id === this.levelSelected?.id
          );
        })
        .catch(() => this.$log.debug("Error fetching levels"))
        .finally(() => (this.loading = false));
    },
    getChipBackColor,
    getChipTextColor,
  },
};
</script>
