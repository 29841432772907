<template>
  <v-container>
    <v-row
      ><v-spacer />
      <input
        hidden
        class="d-none"
        type="file"
        ref="interactionFileLoader"
        :accept="extensions.pdf"
        @change="saveFile"
      />
      <v-btn
        v-if="!isTeacher"
        x-large
        @click="chooseFile()"
        :disabled="uploaded"
      >
        {{ $t("activityInteraction.actions.upload") }}
        <v-icon> mdi-upload </v-icon>
      </v-btn>
    </v-row>
    <br />
    <PDFViewer :exercise="activity.activityId" />
  </v-container>
</template>
<script>
import { uploadPdf } from "@/common/file-utils";
import extensions from "@/common/file-extensions";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";
import VirtualClassroomActivityRepository from "@/repositories/components/VirtualClassroomActivityRepository";

export default {
  name: "ClrmPdf",
  components: { PDFViewer },
  props: {
    activity: {
      type: Object,
    },
    zoom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: {
        loading: false,
      },
      uploaded: false,
      extensions,
    };
  },
  computed: {
    isTeacher() {
      return this.zoom?.state?.virtualClassroomInfo?.teacher;
    },
  },
  methods: {
    chooseFile() {
      this.$refs.interactionFileLoader.click();
    },
    saveFile(htmlElement) {
      uploadPdf(this.activity, "content", this.file, htmlElement).then(() => {
        VirtualClassroomActivityRepository.updateContent({
          lectureActivityId: this.activity.lectureActivityId,
          content: this.activity.content,
        }).then((res) => {
          this.uploaded = true;
          this.$notify({
            title: this.$t("activityInteraction.messages.save_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("saved", res);
        });
      });
    },
  },
};
</script>
