var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('a',{ref:"hiddenDownloader",staticClass:"d-none"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("teacher.tabs.references.titleView")))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary","to":_vm.addReferenceRoute()}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-certificate ")]),_vm._v(" "+_vm._s(_vm.$t("teacher.tabs.references.titleAdd"))+" ")],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1s",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor[item.status] || 'yellow'}},[_vm._v(" "+_vm._s(_vm.$t(("reference.status." + (item.status))))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdmin && item.status === 'PRELIMINARY')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loadingEmailSend[item.id]},on:{"click":function($event){return _vm.requestTeacherReference(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("send")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.send")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.isAdmin)?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","disabled":item.file === null,"loading":_vm.loadingDownloadFile},on:{"click":function($event){return _vm.downloadFile(item.id)}}},[_c('v-icon',[_vm._v("preview")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.download")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdmin || item.status === 'PRELIMINARY')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":_vm.editReferenceRoute(item.id)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdmin || item.status === 'PRELIMINARY')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.selectEntityToDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1)],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"loading":_vm.deleteLoading},on:{"cancel":function($event){_vm.deleteDialog = false},"submit":_vm.deleteReference}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }