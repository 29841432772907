<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="goToNextRoute">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.cancel") }}
              </span>
            </v-btn>
            <v-btn
              class="success ml-2"
              @click="save(entity)"
              :disabled="
                !validForm || (isAdmin && !entity.file) || file.loading
              "
            >
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.fullName"
                type="text"
                :rules="[(v) => !!v || $t('reference.error.required')]"
                :label="$t('reference.prop.fullName')"
                class="required"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.positionTitle"
                type="text"
                :label="$t('reference.prop.positionTitle')"
                :rules="[(v) => !!v || $t('reference.error.required')]"
                class="required"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.companyName"
                type="text"
                :label="$t('reference.prop.companyName')"
                :rules="[(v) => !!v || $t('reference.error.required')]"
                class="required"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.email"
                type="text"
                :rules="[
                  (v) => !!v || this.$t('reference.error.required'),
                  (v) =>
                    regex.EMAIL_REGEX.test(v) ||
                    this.$t('account.form.validation.email_valid'),
                ]"
                :label="$t('reference.prop.email')"
                class="required"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.phone"
                type="text"
                :label="$t('reference.prop.phone')"
                :rules="[(v) => !!v || $t('reference.error.required')]"
                class="required"
              ></v-text-field>
            </v-col>

            <v-col
              cols="6"
              md="6"
              class="mt-2"
              v-if="isAdmin"
              @click="chooseFile"
            >
              <input
                hidden
                class="d-none required"
                type="file"
                ref="fileLoader"
                :accept="extensions.document"
                @change="uploadDocument(entity, 'file', file, ...arguments)"
                :rules="[(v) => !!v || $t('reference.error.required')]"
              />
              <v-icon>folder</v-icon>
              <span v-if="entity.file && !file.loading">
                {{
                  entity.file
                    ? entity.file.fileName
                    : $t("merit.messages.select-file")
                }}
              </span>
              <span v-else-if="file.loading">
                <v-col cols="4" class="d-inline-flex">
                  <v-progress-linear
                    indeterminate
                    color="light-blue"
                    height="10"
                    striped
                  ></v-progress-linear>
                </v-col>
              </span>
              <span v-else>
                {{ $t("merit.messages.select-file") }}
                <span style="color: red">*</span>
              </span>
            </v-col>

            <v-col cols="12" v-if="isAdmin && isEditForm">
              <autocomplete
                v-model="entity.status"
                :items="referenceStatus"
                :item-text="translate"
                :label="$t('reference.prop.status')"
                class="required"
              ></autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import regex from "@/common/regex-validation";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import extensions from "@/common/file-extensions";
import checkInvalidID from "@/common/checkInvalidID";
import referenceStatus from "@/enumerates/ReferenceStatus";
import { deleteTempFile, uploadDocument } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { translate } from "@/common/translation-utils";

const ReferenceEntityRepository = RepositoryFactory.get(
  "ReferenceEntityRepository"
);

export default {
  name: "ReferenceForm",
  components: {
    LoadingPage,
    Autocomplete,
  },
  props: {
    teacherId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      validForm: true,
      loading: false,
      entity: {
        file: null,
      },
      file: {
        loading: false,
      },
      referenceStatus,
      regex,
      extensions,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isLoading() {
      return this.loading;
    },
    isEditForm() {
      return !!this.$route.params.referenceId;
    },
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.referenceId) {
      this._fetchData(to.params.referenceId);
    }
    next();
  },
  created() {
    if (this.isEditForm) {
      this._fetchData(this.$route.params.referenceId);
    }
  },
  beforeDestroy() {
    deleteTempFile(this.entity, "file");
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      ReferenceEntityRepository.get(id)
        .then((response) => (this.entity = response))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    chooseFile() {
      this.$refs.fileLoader.click();
    },
    save() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("reference.error.form-errors"),
          duration: 30000,
        });
        return;
      }
      this.loading = true;
      this.entity.teacherId = this.teacherId;
      ReferenceEntityRepository.save(this.entity)
        .then(() => {
          this.entity.file = null;
          this.goToNextRoute();
        })
        .catch(() =>
          this.$notify({
            type: "error",
            text: this.$t("reference.messages.create-error"),
            duration: 30000,
          })
        )
        .finally(() => (this.loading = false));
    },
    goToNextRoute() {
      this.isAdmin
        ? this.$router.push({
            name: "TeacherReferencesTab",
            params: {
              id: this.teacherId,
              backAction: this.$route.params.backPrevious,
            },
          })
        : this.$router.push({
            name: "ProfileTeacherReferencesTab",
            params: {
              backAction: this.$route.params.backPrevious,
            },
          });
    },
    uploadDocument,
    translate,
  },
};
</script>
