import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/activityLessons";

export default {
  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async batchSave(list) {
    return (await HTTP.post(`${RESOURCE_NAME}/batch`, list)).data;
  },

  async updateOrder(id, order) {
    return (
      await HTTP.patch(
        `${RESOURCE_NAME}/${id}/order`,
        {},
        { params: { order: order } }
      )
    ).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
