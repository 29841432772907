<template>
  <div>
    <a ref="hiddenDownloader" class="d-none" />
    <v-data-table
      :headers="activityTableHeaders"
      :items="homework ? value.homework : value.inclassActivities"
      show-expand
      single-expand
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item`]="{ item, expand, isExpanded }">
        <tr
          :id="'lecture-activity-row ' + item.id"
          :class="
            isExpanded
              ? 'v-data-table__expanded v-data-table__expanded__row'
              : ''
          "
        >
          <td>
            <button
              @click="expand(!isExpanded)"
              type="button"
              :class="
                'v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-down theme--light'.concat(
                  isExpanded ? ' v-data-table__expand-icon--active' : ''
                )
              "
            ></button>
          </td>
          <td class="text-start">
            {{ item.title }}
          </td>
          <td class="text-start">
            {{ item.description }}
          </td>
          <td>
            <v-row
              v-if="
                !loadingRow[item.id] && (isSameTeacher || isSupervisorOfTeacher)
              "
            >
              <v-col
                v-if="!homework && isTeacher"
                style="justify-content: right; display: inline-flex"
              >
                <v-tooltip v-if="item.order > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      :disabled="loadingOrder"
                      @click.stop="updateOrder(item, item.order - 1)"
                    >
                      <v-icon> mdi-menu-up </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("activity.actions.order-up") }}
                  </span>
                </v-tooltip>
                <v-tooltip v-if="item.order < maxOrder" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      :disabled="loadingOrder"
                      @click.stop="updateOrder(item, item.order + 1)"
                    >
                      <v-icon> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("activity.actions.order-down") }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-inline-flex">
                <v-tooltip open-delay="400" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.content != null"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="previewActivity(item)"
                    >
                      <v-icon color="primary"> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("activity.actions.preview") }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.teacherNotes" open-delay="400" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="openTeacherNotes = true"
                    >
                      description
                    </v-icon>
                  </template>
                  <modal-dialog
                    :dialog="openTeacherNotes"
                    v-if="openTeacherNotes && item.teacherNotes"
                    :title="$t('activity.prop.teacher-notes')"
                    :content="item.teacherNotes"
                    :cancelText="$t('actions.close')"
                    @cancel="openTeacherNotes = false"
                    cancelClass="v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text"
                    dialogWith="75%"
                  ></modal-dialog>
                  <span> {{ $t("activity.actions.show-teacher-notes") }}</span>
                </v-tooltip>
                <v-edit-dialog
                  v-if="
                    (homework ||
                      value.homework.findIndex(
                        (h) => h.activityId === item.activityId
                      ) === -1) &&
                    !value.levelTest
                  "
                  :cancel-text="$t('actions.cancel')"
                  large
                  persistent
                  :save-text="$t('actions.save')"
                  @save="assignHomework(item)"
                >
                  <v-tooltip open-delay="400" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" icon>
                        <v-icon> mdi-calendar </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("activity.actions.assign_homework") }}
                    </span>
                  </v-tooltip>
                  <template v-slot:input>
                    <span class="my-8 text-h6">
                      {{ $t("activity.actions.assign_homework") }}
                    </span>
                    <v-container class="mt-2">
                      <dateAndHourPicker
                        :datePickerProp="{
                          data: item.activityInteractions[0].dueDate
                            ? dateToDateTimeArray(
                                utcDateArrayToDateWithTimezone(
                                  item.activityInteractions[0].dueDate
                                )
                              )
                            : date.endDate,
                          label: 'teacherLecture.prop.endTime',
                          mandatory: true,
                          clearable: false,
                          minDate: todayISOString,
                        }"
                        :timePickerProp="{
                          data: item.activityInteractions[0].dueDate
                            ? dateToDateTimeArray(
                                utcDateArrayToDateWithTimezone(
                                  item.activityInteractions[0].dueDate
                                )
                              ).slice(3, 5)
                            : date.endTime,
                          label:
                            'teacher.tabs.schedules.unavailabilityDialog.timeEnd',
                        }"
                        @update-time="updateDateTimeEnd"
                      ></dateAndHourPicker>
                    </v-container>
                  </template>
                </v-edit-dialog>
                <v-tooltip open-delay="400" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isTeacher"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click.stop="manageDeleteDialog(item)"
                    >
                      <v-icon color="error"> delete </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("actions.delete") }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-else align="center" class="d-inline-flex">
              <v-progress-circular
                color="primary"
                indeterminate
                size="21"
              ></v-progress-circular>
            </v-row>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr class="v-data-table__expanded v-data-table__expanded__content">
          <td :colspan="headers.length">
            <v-data-table
              v-if="item.activityInteractions.length > 0"
              :headers="activityInteractionHeaders"
              :items="item.activityInteractions"
              disable-pagination
              disable-sort
              hide-default-footer
              class="ma-4"
            >
              <template v-slot:[`item.grade`]="{ item }">
                <v-edit-dialog
                  v-if="isSameTeacher && item.dateSolved"
                  :cancel-text="$t('actions.cancel')"
                  large
                  persistent
                  :return-value.sync="item.grade"
                  :save-text="$t('actions.save')"
                  @save="updateGrade(item)"
                >
                  <span>
                    {{
                      item.grade != null
                        ? item.grade
                        : $t("activityInteraction.messages.no_grade")
                    }}
                    <v-icon small> mdi-pencil </v-icon>
                  </span>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      {{ $t("activityInteraction.actions.update_grade") }}
                    </div>
                    <number-field
                      v-model="item.grade"
                      :label="$t('activityInteraction.prop.grade')"
                      single-line
                      type="float"
                    ></number-field>
                  </template>
                </v-edit-dialog>
                <span v-else-if="!item.dateSolved">
                  {{ $t("activityInteraction.messages.not_solved") }}
                </span>
                <span v-else>
                  {{
                    item.grade != null
                      ? item.grade
                      : $t("activityInteraction.messages.no_grade")
                  }}
                </span>
              </template>
              <template v-slot:[`item.timeConsumed`]="{ item }">
                <span>
                  {{ item.timeConsumed || "--" }}
                </span>
              </template>
              <template v-slot:[`item.dateSolved`]="{ item }">
                <span v-if="item.dateSolved">
                  {{ item.dateSolved | dateTimeWithTz("medium") }}
                </span>
                <span v-else>
                  {{ $t("activityInteraction.messages.not_solved") }}
                </span>
              </template>

              <template v-slot:[`item.dueDate`]="{ item }">
                <span v-if="item.dueDate">
                  {{ item.dueDate | dateTimeWithTz("medium") }}
                </span>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-icon v-if="item.content" @click="openPDFDialog(item)">
                  mdi-eye
                </v-icon>
                <v-tooltip bottom v-if="item.content">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="primary"
                      @click="downloadContent(item.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      file_download
                    </v-icon>
                  </template>
                  <span>{{ $t("activity.actions.download_file") }}</span>
                </v-tooltip>
                <!-- <v-tooltip bottom v-if="item.recordings">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="primary"
                      @click="playRecording(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-play
                    </v-icon>
                  </template>
                  <span>{{ $t("activity.actions.play_recording") }}</span>
                </v-tooltip> -->
              </template>
            </v-data-table>
            <span
              class="subtitle"
              v-if="item.activityInteractions.length === 0"
            >
              {{ $t("lecture.noResults") }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="deleteActivity"
    ></delete-dialog>
    <v-dialog width="50%" v-model="previewDialog" v-if="previewDialog">
      <activity-preview
        v-if="preview"
        :activity="preview"
        @cancel="closePreview"
      ></activity-preview>
    </v-dialog>
    <PlayRecordingDialog
      v-if="recordingDialog"
      :activityInteractionId="selectedRecording"
      v-on:close-dialog="recordingDialog = false"
    />
    <v-dialog
      v-model="pdfDialog"
      v-if="pdfDialog"
      width="80%"
      @click:outside="closePDFDialog"
    >
      <PDFViewer
        :exercise="selectedEntity"
        :homework="pdfHomework"
        @closeDialog="closePDFDialog"
      >
      </PDFViewer>
    </v-dialog>
  </div>
</template>

<script>
import ActivityPreview from "@/mockups/content-cloud/_components/ActivityPreview.vue";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker";
import NumberField from "@/components/number-field/NumberField.vue";
import PlayRecordingDialog from "./PlayRecordingDialog";

import { mapAuthGetter } from "@/common/mapAuthGetter";
import {
  dateArrayToUTCDateFormatted,
  dateToDateArray,
  dateToDateTimeArray,
  utcDateArrayToDateWithTimezone,
} from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";

import ModalDialog from "@/components/modal_dialog/ModalDialog.vue";

const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

const ActivityInteractionEntityRepository = RepositoryFactory.get(
  "ActivityInteractionEntityRepository"
);

const LectureActivityEntityRepository = RepositoryFactory.get(
  "LectureActivityEntityRepository"
);

const dateNextWeek = new Date();
dateNextWeek.setDate(new Date().getDate() + 7);

export default {
  name: "LectureDetailActivitiesTable",
  components: {
    ActivityPreview,
    DateAndHourPicker,
    DeleteDialog,
    PDFViewer,
    NumberField,
    PlayRecordingDialog,
    ModalDialog,
  },
  props: {
    homework: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSameTeacher: {
      type: Boolean,
      required: true,
    },
    isSupervisorOfTeacher: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: {
        endDate: dateToDateArray(dateNextWeek),
        endTime: ["23", "59"],
      },
      deleteDialog: false,
      deleteLoading: false,
      loadingRow: [],
      loadingOrder: false,
      preview: null,
      previewDialog: false,
      recordingDialog: false,
      selectedRecording: null,
      selectedEntity: null,
      pdfHomework: null,
      pdfDialog: false,
      todayISOString: new Date().toISOString(),
      openTeacherNotes: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isTeacher"]),
    activityTableHeaders() {
      return [
        {
          text: this.$t("activity.prop.title"),
          value: "title",
        },
        {
          text: this.$t("activity.prop.description"),
          value: "description",
        },
        { text: "", value: "action" },
      ];
    },
    activityInteractionHeaders() {
      return [
        {
          text: this.$t("activityInteraction.prop.student"),
          value: "student.name",
        },
        {
          text: this.$t("activityInteraction.prop.grade"),
          value: "grade",
        },
        {
          text: this.$t("activityInteraction.prop.timeConsumed"),
          value: "timeConsumed",
        },
        {
          text: this.$t("activityInteraction.prop.dateSolved"),
          value: "dateSolved",
        },
        {
          text: this.$t("activityInteraction.prop.dueDate"),
          value: "dueDate",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ];
    },
    maxOrder() {
      return Math.max.apply(
        Math,
        this.value.inclassActivities.map(function (o) {
          return o.order;
        })
      );
    },
  },
  methods: {
    assignHomework(item) {
      this.$set(this.loadingRow, item.id, true);
      item.dueDate = dateArrayToUTCDateFormatted([
        ...this.date.endDate,
        ...this.date.endTime,
      ]);
      const options = {
        params: {
          assingHomework: true,
        },
      };
      LectureActivityEntityRepository.save(item, options)
        .then(() => {
          this.$emit("update");
          this.date.endDate = dateToDateArray(dateNextWeek);
          this.date.endTime = ["23", "59"];
        })
        .catch(() =>
          this.$log.debug(
            "Error updating dueDate for item " + JSON.stringify(item)
          )
        )
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
    downloadContent(id) {
      return ActivityInteractionEntityRepository.getContent(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("activity.messages.downloading_file"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("activity.messages.file_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("activity.messages.not_downloading_file"),
              type: "error",
              duration: 30000,
            });
          }
        });
    },
    deleteActivity() {
      this.deleteLoading = true;
      LectureActivityEntityRepository.delete(this.selectedEntity.id)
        .then(() => this.$emit("update"))
        .catch(() =>
          this.$log.debug("Error deleting activity " + this.selectedEntity.id)
        )
        .finally(() => this.manageDeleteDialog());
    },
    manageDeleteDialog(entity) {
      this.selectedEntity = entity;
      this.deleteDialog = entity != null;
      this.deleteLoading = false;
    },
    previewActivity(activity) {
      this.$set(this.loadingRow, activity.id, true);
      if (
        (activity.resourceType === "DOCUMENT" ||
          activity.resourceType === "ACTIVITY") &&
        !activity.typeH5P
      ) {
        this.pdfDialog = true;
        this.pdfHomework = false;
        this.selectedEntity = activity.activityId;
        this.$set(this.loadingRow, activity.id, false);
      } else {
        ActivityEntityRepository.get(activity.activityId)
          .then((res) => {
            this.preview = res;
            this.previewDialog = true;
          })
          .catch(() =>
            this.$log.debug(
              "Error fetching activity with id " + activity.activityId
            )
          )
          .finally(() => this.$set(this.loadingRow, activity.id, false));
      }
    },
    updateOrder(activity, order) {
      this.loadingOrder = true;
      this.$set(this.loadingRow, activity.id, true);
      return LectureActivityEntityRepository.updateOrder(activity.id, order)
        .then(() => {
          this.$emit("updateOrder", activity, order);
          // Next tick for wait data table updates rows order before apply css animations
          this.$nextTick(() => {
            const element = document.getElementById(
              "lecture-activity-row " + activity.id
            );
            element.classList.add("change-out");
            setTimeout(() => {
              element.classList.remove("change-out");
              element.classList.add("change-in");
              setTimeout(() => {
                element.classList.remove("change-in");
                this.loadingOrder = false;
              }, 1000);
            }, 400);
          });
        })
        .catch(() => {
          this.$log.debug(
            "Error updating order for activity with ID " + activity.activityId
          );
          this.loadingOrder = false;
        })
        .finally(() => this.$set(this.loadingRow, activity.id, false));
    },
    updateGrade(item) {
      if (isNaN(parseInt(item.grade))) {
        this.$notify({
          title: this.$t("activityInteraction.error.string_as_grade.title"),
          text: this.$t("activityInteraction.error.string_as_grade.text"),
          type: "warning",
        });
      } else {
        ActivityInteractionEntityRepository.updateGrade(item).catch(() =>
          this.$log.debug(
            "Error updating grade for item " + JSON.stringify(item)
          )
        );
      }
    },
    playRecording(activityInteraction) {
      this.selectedRecording = activityInteraction.id;
      this.recordingDialog = true;
    },
    openPDFDialog(item) {
      this.selectedEntity = item.id;
      this.pdfDialog = true;
      this.pdfHomework = true;
    },
    closePDFDialog() {
      this.pdfDialog = false;
      this.preview = null;
      this.selectedEntity = null;
    },
    closePreview() {
      this.preview = null;
      this.previewDialog = false;
    },
    updateDateTimeEnd(data) {
      this.date.endDate = data.date ? data.date : null;
      this.date.endTime = data.time ? data.time : null;
    },
    dateToDateTimeArray,
    utcDateArrayToDateWithTimezone,
  },
};
</script>
