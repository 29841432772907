<template>
  <edition-detail
    add-button
    :lectures="lectures"
    :students="students"
    :evaluations="evaluations"
    :edition-title="edition.title"
    :start-date="edition.startDate"
    :end-date="edition.endDate"
    :finish-button="isAdHoc"
    :max-students="edition.maxStudents"
    @back="back"
    @change-lecture-pagination="getLectures"
    @close-edition="updateEndDate"
    @change-evaluation-pagination="getEvaluationItems"
    @update:students="getStudents"
  ></edition-detail>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import EditionDetail from "@/mockups/components/EditionDetail";
import checkInvalidID from "@/common/checkInvalidID";
import { generateSort } from "@/common/pagination-utils";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

const LectureEntityRepository = RepositoryFactory.get(
  "LectureEntityRepository"
);

const LectureEvaluationEntityRepository = RepositoryFactory.get(
  "LectureEvaluationEntityRepository"
);

const EditionEntityRepository = RepositoryFactory.get(
  "EditionEntityRepository"
);

export default {
  components: { EditionDetail },
  data() {
    return {
      edition: {},
      students: {
        loading: true,
        items: [],
      },
      lectures: {
        loading: true,
        items: [],
        totalItems: 0,
      },
      evaluations: {
        loading: true,
        items: [],
        totalItems: 0,
      },
    };
  },
  computed: {
    isAdHoc() {
      return this.edition.type && this.edition.type.indexOf("ADHOC") !== -1;
    },
  },
  mounted() {
    this.getStudents();
    this.getEdition();
  },
  methods: {
    async getLectures(pagination) {
      this.lectures.loading = true;
      const options = {
        params: {
          page: pagination.page - 1,
          size: pagination.itemsPerPage,
          sort: generateSort(pagination),
          filters: "edition.id:" + this.$route.params.editionId,
        },
      };
      LectureEntityRepository.getAll(options)
        .then((response) => {
          this.lectures.items = response.content;
          this.lectures.totalItems = response.totalElements;
        })
        .catch(() => {
          this.$log.debug(
            "Error fetching lectures from edition with id " +
              this.$route.params.editionId
          );
        })
        .finally(() => (this.lectures.loading = false));
    },
    getEdition() {
      EditionEntityRepository.get(this.$route.params.editionId)
        .then((data) => (this.edition = data))
        .catch((err) => {
          this.$log.debug(
            "Edition with id " + this.$route.params.editionId + " not found"
          );
          checkInvalidID(err);
        });
    },
    getStudents() {
      this.students.loading = true;
      StudentEntityRepository.getAllByEdition(this.$route.params.editionId)
        .then((res) => (this.students.items = res))
        .catch((err) => {
          this.$log.debug(
            "Edition with id " + this.$route.params.editionId + " not found"
          );
          checkInvalidID(err);
        })
        .finally(() => (this.students.loading = false));
    },
    getEvaluationItems(pagination) {
      const sortMapping = {
        studentName: "attendance.student.userData.user.firstName",
        teacherName: "attendance.lecture.teacher.userData.user.firstName",
      };
      const options = {
        params: {
          page: pagination.page - 1,
          size: pagination.itemsPerPage,
          sort: generateSort(pagination, sortMapping),
        },
      };
      LectureEvaluationEntityRepository.getAllByEdition(
        this.$route.params.editionId,
        options
      ).then((response) => {
        this.evaluations.items = response.content;
        this.evaluations.totalItems = response.totalElements;
        this.evaluations.loading = false;
      });
    },
    updateEndDate(date) {
      this.$set(this.edition, "endDate", date);
    },
    back() {
      this.$router.push({
        name: "Admin Edition List",
        params: { backAction: true },
      });
    },
  },
};
</script>
