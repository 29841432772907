var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-datatable"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"auto"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","offset":"4"}},[_c('debounced-text-field',{staticClass:"d-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('autocomplete',{staticClass:"pt-0",attrs:{"label":_vm.$t('company.prop.type'),"items":_vm.companyTypes,"item-text":_vm.translate,"dense":""},on:{"change":_vm.onSearchChange},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"success ml-2","to":{
                  name: 'Create Company',
                  params: { backPrevious: true },
                }}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.new"))+" ")])],1)],1)],1)],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"rows-clickable",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.onPaginationChange,"click:row":_vm.entityDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name + " " + item.surname)+" ")])]}},{key:"item.dateStart",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeWithTz")(item.dateStart))+" ")])]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getTypeColor(item),"small":""}},[_vm._v(" "+_vm._s(_vm.$t(("company.types." + (item.type))))+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.entityDetail(item)}}},'v-icon',attrs,false),on),[_vm._v(" description ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.detail")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.selectDeactivateCompany(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1)],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.deactivateDialog,"loading":_vm.deleteLoading},on:{"cancel":function($event){_vm.deactivateDialog = false},"submit":_vm.deactivateCompany}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }