var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mr-2",attrs:{"justify":"end"}},[(_vm.creatable)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.addWordDialog = true}}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("wordlist.actions.add-word"))+" ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.wordsTableHeaders,"items":_vm.words,"options":_vm.wordsPagination,"server-items-length":_vm.wordsTotalItems,"loading":_vm.loadingWords,"footer-props":_vm.tableFooterProps},on:{"update:options":_vm.redirectOnTableChange},scopedSlots:_vm._u([{key:"item.wordTopics",fn:function(ref){
var item = ref.item;
return _vm._l((item.wordTopics),function(wordTopic){return _c('v-container',{key:wordTopic.topicId,staticClass:"d-inline"},[_c('v-chip',{staticClass:"mt-2",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(wordTopic.topicName)+" ")])],1)})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.editable)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.openModifyDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.removable)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteWord(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","width":"80%"},model:{value:(_vm.addWordDialog),callback:function ($$v) {_vm.addWordDialog=$$v},expression:"addWordDialog"}},[(_vm.addWordDialog)?_c('word-form',{attrs:{"wordProp":_vm.selectedWord,"language":_vm.language},on:{"cancel":_vm.closeAddWordDialog,"save":_vm.saveNewOrModifiedWord}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }