var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("teacher.tabs.supervising.title")))]),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headersSupervised,"items":_vm.supervisedTeachersByHeadmaster,"search":_vm.searchSupervised,"loading":_vm.supervisedTeachersLoading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center","no-gutters":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"6","md":"8"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('search')},model:{value:(_vm.searchSupervised),callback:function ($$v) {_vm.searchSupervised=$$v},expression:"searchSupervised"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-btn',{attrs:{"color":"success","block":""},on:{"click":function($event){_vm.teachersNotSupervisedDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("actions.add")))])],1):_vm._e()],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.toLectureList(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.lecture_list")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdmin)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","loading":_vm.deleteSupervisingLoading},on:{"click":function($event){return _vm.deleteSupervising(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"65%"},model:{value:(_vm.teachersNotSupervisedDialog),callback:function ($$v) {_vm.teachersNotSupervisedDialog=$$v},expression:"teachersNotSupervisedDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","md":"11"}},[_c('h1',{staticClass:"title ml-4"},[_vm._v("Add teachers supervising")])]),_c('v-col',{attrs:{"cols":"2","md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.teachersNotSupervisedDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"search":_vm.searchNotSupervised,"headers":_vm.headersSupervised,"items":_vm.notSupervisedTeachersList,"loading":_vm.notSupervisedTeachersLoading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-btn',{attrs:{"icon":"","color":"success","loading":_vm.addSupervisingLoading},on:{"click":function($event){return _vm.addSupervising(item)}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()]}},{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('search')},model:{value:(_vm.searchNotSupervised),callback:function ($$v) {_vm.searchNotSupervised=$$v},expression:"searchNotSupervised"}})],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }