<template>
  <div class="height-100">
    <div v-if="loading"><LoadingPage /></div>
    <div
      v-if="!loading"
      class="classroom"
      :class="'classroom--' + virtualClassroomInfo.classroomMode"
    >
      <ClassroomHeader
        :classroomMode="virtualClassroomInfo.classroomMode"
        :startTime="virtualClassroomInfo.startTime"
        :chatId="virtualClassroomInfo.editionChatId"
        @open-chat="openChat"
        :endTime="virtualClassroomInfo.endTime"
        @open-participants="openParticipants()"
        @end-lecture="openEndLectureDialog()"
        @settings="$refs.ClassroomBody.openSettings()"
        @support="supportDialog = true"
        :zoom="zoom"
      ></ClassroomHeader>
      <div id="app" data-app>
        <modal-dialog
          :content="$t('classroom.dialog.content')"
          :dialog="dialog"
          :submitText="$t('classroom.dialog.confirm')"
          :title="$t('classroom.dialog.title')"
          titleClass="warning white--text"
          @submit="endLecture"
          @cancel="dialog = false"
        ></modal-dialog>
      </div>
      <v-dialog persistent :width="300" :value="supportDialog">
        <v-card>
          <v-card-title primary-title class="headline">
            {{ $t("classroom.support.message") }}
          </v-card-title>
          <div class="field-info dialog">
            <v-icon class="field-info__icon">phone</v-icon>
            <a
              :href="'tel:' + $i18n.t('classroom.support.phoneNumberPrefix')"
              class="refs"
              >{{ $t("classroom.support.phoneNumber") }}</a
            >
          </div>
          <div class="field-info dialog">
            <v-icon class="field-info__icon">email</v-icon>
            <a
              :href="'mailto:' + $i18n.t('classroom.support.email')"
              class="refs"
              >{{ $t("classroom.support.email") }}
            </a>
          </div>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn @click="supportDialog = false" class="pb-4">
              {{ $t("classroom.global.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ClassroomBody
        :classroomInfo="virtualClassroomInfo"
        :zoom="zoom"
        ref="ClassroomBody"
      ></ClassroomBody>
      <ClassroomFooter
        :classroomMode="virtualClassroomInfo.classroomMode"
      ></ClassroomFooter>
      <ChatContainer ref="chatLayer"></ChatContainer>
      <ClrmParticipantsList
        ref="participantsLayer"
        :classroomMode="virtualClassroomInfo.classroomMode"
        :zoom="zoom"
        @open-chat="openChat"
      ></ClrmParticipantsList>
    </div>
  </div>
</template>

<script>
import ClassroomHeader from "@/components/classroom/ClassroomHeader.vue";
import ClassroomBody from "@/components/classroom/ClassroomBody.vue";
import ClassroomFooter from "@/components/classroom/ClassroomFooter.vue";

import VirtualClassroomRepository from "@/repositories/components/VirtualClasroomRepository";
import LoadingPage from "../loading-page/LoadingPage.vue";

import Zoom from "@/components/classroom/common/Zoom";
import HostParams from "@/properties";

import ChatContainer from "@/components/chat/ChatContainer.vue";
import ClrmParticipantsList from "@/components/clrm/clrm-participants-list/ClrmParticipantsList.vue";
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import classroomStateGetter from "./store/classroomStateGetter";
import classroomStateSetter from "./store/classroomStateSetter";
import jwt_decode from "jwt-decode";

export default {
  components: {
    ClassroomHeader,
    ClassroomBody,
    ClassroomFooter,
    LoadingPage,
    ChatContainer,
    ClrmParticipantsList,
    ModalDialog,
  },
  props: {},
  data() {
    return {
      dialog: false,
      zoom: new Zoom(HostParams),
      loading: true,
      virtualClassroomInfo: null,
      supportDialog: false,
      interval: null,
    };
  },
  mounted() {
    classroomStateSetter.clearClassroomState();
    this.getClassroomInfo();
    this.startTokenRefresh();
    this.interval = setInterval(() => {
      this.startTokenRefresh();
    }, 5 * 60 * 1000);
  },
  beforeDestroy() {
    if (classroomStateGetter.getSessionState() != null) {
      this.zoom.currentDeviceLeave();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    setTimeout(() => {
      classroomStateSetter.clearClassroomState();
    }, 1000);
  },
  methods: {
    endLecture() {
      VirtualClassroomRepository.endLecture(this.$route.params.id);
    },
    openEndLectureDialog() {
      this.dialog = true;
    },
    openChat(chatId) {
      this.$refs.chatLayer.init(chatId);
    },
    openParticipants() {
      this.$refs.participantsLayer.init();
    },
    async getClassroomInfo() {
      const room = this.$route.params.id;
      this.virtualClassroomInfo = null;
      try {
        this.virtualClassroomInfo =
          await VirtualClassroomRepository.getVirtualClassroomInfo(room);
      } catch (e) {
        this.$log.debug("Error accessing lecture " + room);
        if (
          e.response.status != 403 &&
          e.response.status != 404 &&
          e.response.status != 401
        ) {
          this.$router.back(-1);
        }
        if (e.response.status == 404) {
          this.$router.push({
            name: "NotFound",
          });
        }
        return null;
      }
      // preparando la fecha/hora de inicio
      this.virtualClassroomInfo.startTime[1]--;
      this.virtualClassroomInfo.startTime = new Date(
        Date.UTC(...this.virtualClassroomInfo.startTime)
      );

      this.virtualClassroomInfo.endTime[1]--;
      this.virtualClassroomInfo.endTime = new Date(
        Date.UTC(...this.virtualClassroomInfo.endTime)
      );

      await this.zoom.init(this.virtualClassroomInfo.lectureId);
      this.virtualClassroomInfo.classroomMode = this.virtualClassroomInfo
        .teacher
        ? "teacher"
        : "student";

      this.$log.debug(`generated virtualClassroomInfo:`);
      this.$log.debug(this.virtualClassroomInfo);
      this.loading = false;
    },
    tokenRefresh() {
      VirtualClassroomRepository.refreshToken().then((response) => {
        const token = response.token;
        localStorage.setItem("token", token);
      });
    },
    startTokenRefresh() {
      const token = localStorage.getItem("token");
      const { exp } = jwt_decode(token);
      const now = Date.now().valueOf() / 1000;
      if (exp - now < 6 * 60) {
        this.tokenRefresh();
      }
    },
  },
};
</script>
<style>
.dialog {
  margin-left: 30px;
  font-size: large;
}
.refs {
  color: #339999 !important;
}
.headline {
  color: #22555a !important;
}
</style>
