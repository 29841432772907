<template>
  <v-container fluid>
    <a ref="hiddenDownloader" class="d-none" />
    <!-- Show notification if doesnt have degrees -->
    <v-alert
      v-if="merits.length < 1 && !loadingTeacherMerits"
      border="left"
      colored-border
      type="warning"
      elevation="2"
      dismissible
    >
      {{ $t("teacher.tabs.education.addInformation") }}
    </v-alert>

    <!-- Curriculum vitae row -->
    <v-row>
      <v-col cols="12" md="6">
        <span class="headline"> {{ $t("teacher.tabs.education.cv") }}</span>
      </v-col>

      <v-col cols="12" md="6" class="text-right">
        <v-btn
          v-if="CV.file"
          class="ml-2"
          :loading="CV.loading"
          outlined
          color="primary"
          @click="downloadCVFile"
        >
          <v-icon class="mr-2">file_download</v-icon>
          {{ $t("teacher.tabs.education.downloadCurriculum") }}
        </v-btn>
        <v-btn
          class="ml-2"
          :loading="CV.loading"
          outlined
          color="success"
          @click="chooseFile"
        >
          <v-icon class="mr-2">file_upload</v-icon>
          {{
            !CV.file
              ? $t("teacher.tabs.education.addCurriculum")
              : $t("teacher.tabs.education.updateCurriculum")
          }}
        </v-btn>
        <input
          hidden
          class="d-none"
          type="file"
          ref="fileLoader"
          @change="uploadFile"
        />
        <v-btn
          v-if="CV.file"
          class="ml-2"
          :loading="CV.loading"
          outlined
          color="error"
          @click="showDeleteDialog()"
        >
          <v-icon class="mr-2">delete</v-icon>
          {{ $t("teacher.tabs.education.delCurriculum") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider style="border-color: grey" class="my-4"></v-divider>

    <!-- Diplomas row -->
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline">
          {{ $t("teacher.tabs.education.diplomas") }}</span
        >
      </v-col>
      <v-col cols="12" md="3">
        <v-btn outlined block color="primary" :to="addDiplomaRoute">
          <v-icon class="mr-2"> mdi-certificate </v-icon>
          {{ $t("teacher.tabs.education.addDiploma") }}
        </v-btn>
      </v-col>
      <v-container fluid>
        <v-row row>
          <v-col cols="12">
            <v-data-table
              class="elevation-1s"
              :headers="headers"
              :items="merits"
              :loading="loadingTeacherMerits"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="item.status === 'UNCHECKED' ? 'warning' : 'success'"
                >
                  {{ $t(`merit.prop.status.${item.status}`) }}
                </v-chip>
              </template>
              <template v-slot:[`item.file`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      @click="downloadFile(item.id)"
                      color="primary"
                      :disabled="item.file === null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>preview</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.download") }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isAdmin"
                      v-on="on"
                      v-bind="attrs"
                      :color="item.status === 'UNCHECKED' ? 'success' : 'error'"
                      :loading="changingDiplomaStatus"
                      icon
                      small
                      @click="validateDiploma(item)"
                    >
                      <v-icon small class="ml-2">
                        {{
                          item.status === "CHECKED" ? "thumb_down" : "thumb_up"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      item.status === "CHECKED"
                        ? $t("merit.actions.reject")
                        : $t("merit.actions.approve")
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isAdmin || item.status.name === 'UNCHECKED'"
                      :to="editDiplomaRoute(item.id)"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="warning">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.edit") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.status === 'UNCHECKED'"
                      icon
                      small
                      @click.stop="showDeleteDialog(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-divider style="border-color: grey" class="my-4"></v-divider>

    <!-- Levels row -->
    <v-row>
      <v-col cols="12" md="9">
        <span class="headline"> {{ $t("teacher.tabs.education.levels") }}</span>
      </v-col>
      <v-col cols="12" md="3" class="text-right">
        <v-dialog v-model="levelDialog" max-width="450" v-if="!isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" v-bind="attrs" v-on="on">
              <v-icon class="mr-2"> mdi-plus </v-icon>
              {{ $t("teacher.tabs.education.add_level") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <v-row no-gutters>
                <v-col cols="10">
                  <h5>{{ $t("teacher.tabs.education.level_request") }}</h5>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn icon @click="levelDialog = false">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <level-selector
                v-model="levelSelected"
                :label="$t('level.name')"
              ></level-selector>
            </v-card-text>
            <v-card-actions>
              <v-col class="text-center">
                <v-btn
                  color="success"
                  :disabled="!levelSelected"
                  :loading="sendingLevelRequest"
                  @click="sendLevelRequest"
                >
                  {{ $t("actions.submit") }}
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="elevation-1s"
          :headers="levelHeaders"
          :items="teacherLevels"
          :loading="loadingTeacherLevels"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.level.language.name`]="{ item }">
            {{ $t(`languages.${item.level.language.name}`) }}/{{
              item.level.name
            }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status === 'PENDING'
                  ? 'warning'
                  : item.status === 'REJECTED'
                  ? 'error'
                  : 'success'
              "
            >
              {{ $t(`teacherLevel.status.${item.status}`) }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="confirmLevel(item)"
                  :loading="confirmingLevel"
                  v-if="
                    isAdmin &&
                    (item.status === 'PENDING' || item.status === 'REJECTED')
                  "
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="ml-2" color="success">thumb_up</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.approve") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="rejectLevel(item)"
                  :loading="rejectingLevel"
                  v-if="
                    isAdmin &&
                    (item.status === 'PENDING' || item.status === 'ACCEPTED')
                  "
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="ml-2" color="error"> thumb_down </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.reject") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para la eliminacion de meritos y cv -->
    <delete-dialog
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="() => (selectedEntity ? deleteMerit() : deleteCV())"
    ></delete-dialog>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import { downloadFile, uploadDocument } from "@/common/file-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LevelSelector from "@/components/selectors/LevelSelector";

const MeritEntityRepository = RepositoryFactory.get("MeritEntityRepository");
const FileEntityRepository = RepositoryFactory.get("FileEntityRepository");

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

const TeacherLevelEntityRepository = RepositoryFactory.get(
  "TeacherLevelEntityRepository"
);

export default {
  name: "EducationTab",
  components: { LevelSelector, DeleteDialog },
  props: {
    teacherId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      merits: [],
      CV: { loading: false },
      selectedEntity: {},
      deleteDialog: false,
      levelDialog: false,
      levelSelected: null,
      confirmingLevel: false,
      rejectingLevel: false,
      sendingLevelRequest: false,
      loadingTeacherLevels: false,
      loadingTeacherMerits: false,
      changingDiplomaStatus: false,
      teacherLevels: [],
      loadingDownloadFile: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    addDiplomaRoute() {
      return {
        name: this.isAdmin ? "TeacherMeritFormNew" : "ProfileMeritFormNew",
        params: { backAction: true },
      };
    },
    headers() {
      return [
        {
          text: this.$t("merit.prop.name"),
          value: "name",
        },
        {
          text: this.$t("merit.prop.organization"),
          value: "organization",
        },
        {
          text: this.$t("merit.prop.year"),
          value: "year",
        },
        {
          text: this.$t("merit.prop.status.name"),
          value: "status",
        },
        {
          text: this.$t("merit.prop.file"),
          value: "file",
          sortable: false,
        },
        {
          text: this.$t("merit.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
    levelHeaders() {
      return [
        {
          text: this.$t("teacherLevel.prop.level"),
          value: "level.language.name",
        },
        {
          text: this.$t("teacherLevel.prop.status"),
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  mounted() {
    this._fetchTeacherLevels(this.teacherId);
    this._fetchMerits(this.teacherId);
    this._fetchCV(this.teacherId);
  },
  methods: {
    _fetchCV(id) {
      this.CV.loading = true;
      TeacherEntityRepository.getCV(id)
        .then((data) => (this.CV = { ...this.CV, ...data }))
        .catch(() =>
          this.$log.debug("Error fetching teacher's CV with ID " + id)
        )
        .finally(() => (this.CV.loading = false));
    },
    _fetchTeacherLevels(id) {
      this.loadingTeacherLevels = true;
      let options = { params: { teacher: id } };
      TeacherLevelEntityRepository.getAll(options)
        .then((data) => (this.teacherLevels = data.content))
        .catch(() =>
          this.$log.debug(
            "Error fetching teacher levels with params: " + options
          )
        )
        .finally(() => (this.loadingTeacherLevels = false));
    },
    _fetchMerits(id) {
      this.loadingTeacherMerits = true;
      return MeritEntityRepository.getAll({ params: { teacher: id } })
        .then((res) => (this.merits = res.content))
        .catch(() => this.$log.debug("Error fetching teacher with ID " + id))
        .finally(() => (this.loadingTeacherMerits = false));
    },
    downloadFile(id) {
      this.loadingDownloadFile = true;
      return MeritEntityRepository.downloadMeritFile(id)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("merit.messages.download-file"),
            type: "success",
          });
        })
        .catch(() =>
          this.$notify({
            title: this.$t("merit.error.file-not-loaded"),
            type: "error",
            duration: 30000,
          })
        )
        .finally(() => (this.loadingDownloadFile = false));
    },
    downloadCVFile() {
      this.CV.loading = true;
      return TeacherEntityRepository.downloadCVFile(this.teacherId)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("teacher.tabs.education.downloadingCurriculum"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.response.status === 404) {
            this.$notify({
              title: this.$t("teacher.tabs.education.cv_not_exist"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("teacher.tabs.education.notDownloadingCurriculum"),
              type: "error",
              duration: 30000,
            });
          }
        })
        .finally(() => (this.CV.loading = false));
    },
    editDiplomaRoute(id) {
      return {
        name: this.isAdmin ? "TeacherMeritFormEdit" : "ProfileMeritFormEdit",
        params: { meritId: id, backAction: true },
      };
    },
    deleteMerit() {
      this.loadingTeacherMerits = true;
      MeritEntityRepository.delete(this.selectedEntity.id)
        .then(() =>
          this.merits.splice(this.merits.indexOf(this.selectedEntity), 1)
        )
        .catch(() =>
          this.$log.debug(
            "Error deleting merit with ID " + this.selectedEntity.id
          )
        )
        .finally(() => {
          this.selectedEntity = null;
          this.deleteDialog = false;
          this.loadingTeacherMerits = false;
        });
    },
    showDeleteDialog(entity) {
      if (entity && entity.status !== "UNCHECKED") {
        return this.$notify({
          title: this.$t("merit.error.status-invalid"),
          type: "error",
          duration: 30000,
        });
      }
      this.selectedEntity = entity;
      this.deleteDialog = true;
    },
    validateDiploma(item) {
      this.changingDiplomaStatus = true;
      const status = item.status === "UNCHECKED" ? "CHECKED" : "UNCHECKED";
      MeritEntityRepository.changeStatus(item.id, status)
        .then((data) => {
          item.status = data.status;
          item.status !== "UNCHECKED"
            ? this.$notify({
                title: this.$t("merit.messages.approve-validation"),
                type: "success",
              })
            : this.$notify({
                title: this.$t("merit.messages.reject-validation"),
                type: "success",
              });
        })
        .catch(() =>
          this.$log.debug(
            "Error changing status of merit with ID " +
              item.id +
              " to " +
              status
          )
        )
        .finally(() => (this.changingDiplomaStatus = false));
    },
    uploadFile(htmlElement) {
      uploadDocument(this.CV, "file", this.CV, htmlElement)
        .then(() => this.updateCV(this.CV.file))
        .catch(() => this.$log.debug("Error updating CV"));
    },
    updateCV() {
      this.CV.loading = true;
      TeacherEntityRepository.updateCV(this.teacherId, this.CV.file)
        .then((res) => {
          this.CV.file = res;
          this.$notify({
            title: this.$t("teacher.messages.cv-updated"),
            type: "success",
          });
        })
        .catch(() => this.$log.debug("Error updating teacher CV"))
        .finally(() => (this.CV.loading = false));
    },
    deleteCV() {
      this.CV.loading = true;
      TeacherEntityRepository.deleteCV(this.teacherId)
        .then(() =>
          this.$notify({
            title: this.$t("teacher.messages.cv-deleted"),
            type: "success",
          })
        )
        .catch(() =>
          this.$notify({
            title: this.$t("teacher.messages.cv-deleted"),
            type: "error",
            duration: 30000,
          })
        )
        .finally(() => {
          this.deleteDialog = false;
          this.CV.file = null;
          this.CV.loading = false;
        });
    },
    sendLevelRequest() {
      this.sendingLevelRequest = true;
      TeacherLevelEntityRepository.saveLevelRequest(
        this.levelSelected?.id,
        this.teacherId
      )
        .then((data) => {
          this.teacherLevels.push(data);
          this.levelDialog = false;
          this.levelSelected = null;
        })
        .catch(() =>
          this.$log.debug(
            "Error sending level request for level " +
              this.levelSelected.name +
              " and teacher with ID " +
              this.teacherId
          )
        )
        .finally(() => (this.sendingLevelRequest = false));
    },
    confirmLevel(item) {
      this.confirmingLevel = true;
      TeacherLevelEntityRepository.confirmLevelRequest(item.id)
        .then((data) => {
          let index = this.teacherLevels.findIndex((el) => el.id === item.id);
          this.$set(this.teacherLevels, index, data);
        })
        .catch(() =>
          this.$log.debug("Error confirming level request with ID " + item.id)
        )
        .finally(() => (this.confirmingLevel = false));
    },
    rejectLevel(item) {
      this.rejectingLevel = true;
      TeacherLevelEntityRepository.rejectLevelRequest(item.id)
        .then((data) => {
          let index = this.teacherLevels.findIndex((el) => el.id === item.id);
          this.$set(this.teacherLevels, index, data);
        })
        .catch(() =>
          this.$log.debug("Error confirming level request with ID " + item.id)
        )
        .finally(() => (this.rejectingLevel = false));
    },
    chooseFile() {
      this.$refs.fileLoader.click();
    },
  },
};
</script>
