var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rows-clickable",attrs:{"headers":_vm.headers,"items":_vm.activitiesSelected,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(item.level.name))+" ")]):_vm._e()]}},{key:"item.activityType",fn:function(ref){
var item = ref.item;
return [(item.activityType)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.activityType),"text-color":_vm.getChipTextColor(item.activityType)}},[_vm._v(_vm._s(item.activityType.name))]):_vm._e()]}},{key:"item.resourceType",fn:function(ref){
var item = ref.item;
return [(item.resourceType)?_c('v-chip',{attrs:{"color":_vm.stringToColour(item.resourceType),"text-color":_vm.stringToColourText(item.resourceType)}},[_vm._v(_vm._s(_vm.$t(("activity.resource_type." + (item.resourceType)))))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.removeActivity(item)}}},[_vm._v(" cancel ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }