<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="onPaginationChange"
        >
          <template v-slot:[`item.teacherName`]="{ item }">
            <router-link
              :to="{
                name: 'TeacherEducationTab',
                params: { id: item.teacherId },
              }"
              >{{ item.teacherName }}</router-link
            >
          </template>
          <template v-slot:[`item.level`]="{ item }">
            {{ $t(`languages.${item.level.language.name}`) }}/{{
              item.level.name
            }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top v-if="!loadingRow[item.id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  color="success"
                  @click.stop="levelAction(item, true)"
                >
                  <v-icon> thumb_up </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.approve") }}</span>
            </v-tooltip>

            <v-tooltip top v-if="!loadingRow[item.id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  color="error"
                  @click.stop="levelAction(item, false)"
                >
                  <v-icon> thumb_down </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("actions.reject") }}</span>
            </v-tooltip>

            <loading-spinner v-else></loading-spinner>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { generateSort } from "@/common/pagination-utils";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const TeacherLevelEntityRepository = RepositoryFactory.get(
  "TeacherLevelEntityRepository"
);

export default {
  name: "PendingTeacherLevelApprovalList",
  components: { LoadingSpinner },
  data() {
    return {
      items: [],
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
      },
      tableFooterProps,
      loadingRow: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("teacher.name"),
          value: "teacherName",
        },
        {
          text: this.$t("teacherLevel.prop.level"),
          value: "level",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const sortMapping = {
        teacherName: "teacher.userData.user.firstName",
        level: "level.language.name",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      TeacherLevelEntityRepository.getAllPending(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching pending teacher levels with params: " + options
          )
        )
        .finally(() => (this.isLoading = false));
    },
    levelAction(item, confirm) {
      // Confirms or rejects a level request
      this.$set(this.loadingRow, item.id, true);
      const request = confirm
        ? TeacherLevelEntityRepository.confirmLevelRequest
        : TeacherLevelEntityRepository.rejectLevelRequest;
      request(item.id)
        .then(() => {
          if (this.pagination.page !== 1 && this.items.length === 1) {
            this.pagination.page -= 1;
          } else {
            this.fetchData();
          }
        })
        .catch(() =>
          this.$log.debug(
            "Error " + confirm
              ? "confirming"
              : "rejecting" + " level request with ID " + item.id
          )
        )
        .finally(() => this.$set(this.loadingRow, item.id, false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this.fetchData();
    },
    onPaginationChange(pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
  },
};
</script>
