import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/lectureActivitys";

export default {
  async getAllUploadableBylecture(id) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/lecture/${id}/uploadable`
    );
    return response.data;
  },

  async getHomework(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/homework/${id}`)).data;
  },

  async save(entity, options = {}) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity, options))
        .data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },

  async updateOrder(id, order) {
    return (
      await HTTP.patch(
        `${RESOURCE_NAME}/${id}/order`,
        {},
        { params: { order: order } }
      )
    ).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },

  async batchSave(list, date) {
    return (await HTTP.post(`${RESOURCE_NAME}/homework-due/${date}`, list))
      .data;
  },
};
