<template>
  <div v-if="activity">
    <v-row
      :id="id"
      class="activity-row"
      align="center"
      justify="space-between"
      no-gutters
    >
      <v-col cols="12" md="7">
        <v-row no-gutters>
          <v-col v-if="activity.activity" cols="6" md="4">
            <b>
              {{ $t("activity.prop.title") + ": " }}
            </b>
            <span>{{ activity.activity.title }}</span></v-col
          >
          <v-col v-if="activity.activity" cols="12" md="7">
            <b>
              {{ $t("activity.prop.description") }}
            </b>
            <span> {{ activity.activity.description }}</span></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="12" md="2">
        <v-col class="text-right">
          <v-row no-gutters>
            <v-col class="text-center">
              <v-row v-if="!loading" align="center" justify="center">
                <v-tooltip v-if="activity.order > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      @click="
                        updateOrder(
                          activity,
                          activity.order - 1,
                          activity.order - 1
                        )
                      "
                    >
                      <v-icon>mdi-menu-up</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("activity.actions.order-up") }}
                  </span>
                </v-tooltip>
                <v-tooltip v-if="activity.order < maxOrder" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      @click="
                        updateOrder(
                          activity,
                          activity.order + 1,
                          activity.order - 1
                        )
                      "
                    >
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("activity.actions.order-down") }}
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-else align="center" class="d-inline-flex mr-1">
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="21"
                ></v-progress-circular>
              </v-row>
            </v-col>
            <v-tooltip v-if="activity.activity.content != null" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="success"
                  @click="previewActivity(activity.activity)"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </template>
              <span>{{ $t("activity.actions.preview") }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="
                activity.activity.content != null && activity.activity.typeH5p
              "
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="primary"
                  :href="'/h5p/download/' + activity.activity.content.fileName"
                >
                  <v-icon>download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("activity.actions.download") }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="activity.activity.hasContent && !activity.activity.typeH5p"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="primary"
                  @click="downloadActivity()"
                  ><v-icon>download</v-icon></v-btn
                >
              </template>
              <span>{{ $t("activity.actions.download") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="error"
                  @click="deleteActivity()"
                  ><v-icon>delete</v-icon></v-btn
                >
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-col>
      <v-col v-if="activity.activity.thumbnail != null" cols="12" md="3">
        <v-img :src="thumbnail"></v-img>
      </v-col>
    </v-row>

    <v-dialog width="50%" v-model="previewDialog" @click:outside="closePreview">
      <activity-preview
        v-if="preview"
        :activity="preview"
        @cancel="closePreview"
      ></activity-preview>
    </v-dialog>
    <v-dialog
      v-model="pdfDialog"
      v-if="pdfDialog"
      width="80%"
      @click:outside="closePreview"
    >
      <PDFViewer
        :exercise="preview.id"
        :homework="false"
        @closeDialog="closePreview()"
      >
      </PDFViewer>
    </v-dialog>

    <a ref="hiddenDownloader" class="d-none" />
  </div>
</template>

<script>
import ActivityPreview from "./ActivityPreview.vue";
import PDFViewer from "@/components/pdf-viewer/PDFViewer";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getFileNameFromHeaders } from "@/common/file-utils";

const ActivityLessonEntityRepository = RepositoryFactory.get(
  "ActivityLessonEntityRepository"
);
const ActivityEntityRepository = RepositoryFactory.get(
  "ActivityEntityRepository"
);

export default {
  name: "ActivityLessonDetail",
  components: { ActivityPreview, PDFViewer },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: "activity-container",
    },
    maxOrder: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      preview: {},
      thumbnail: {},
      pdfDialog: false,
      previewDialog: false,
    };
  },
  mounted() {
    if (this.activity.activity.thumbnail != null) {
      this.downloadThumbnail();
    }
  },
  methods: {
    async downloadThumbnail() {
      await ActivityEntityRepository.downloadActivityThumbnail(
        this.activity.activity.id
      )
        .then((res) => {
          const blob = new Blob([res.data]);
          this.thumbnail = URL.createObjectURL(blob);
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching thumbnail photo for activity with ID " +
              this.activity.activity.id
          )
        );
    },
    downloadActivity() {
      if (this.activity.activity.content === null) return;
      return ActivityEntityRepository.downloadActivityFile(
        this.activity.activity.id
      )
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          // get filename from headers
          const filename = getFileNameFromHeaders(response.headers);
          const downloadLink = this.$refs.hiddenDownloader;
          downloadLink.href = url;
          downloadLink.download = filename;
          downloadLink.click();
          window.URL.revokeObjectURL(url);
          this.$notify({
            title: "Downloading file",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "The file could not be downloaded",
            type: "error",
            duration: 30000,
          });
        });
    },
    deleteActivity() {
      ActivityLessonEntityRepository.delete(this.activity.id).then(() => {
        this.$notify({
          title: "Activity Deleted",
          text: this.$t("account.notifications.successfull_deleted"),
          type: "success",
        });
        this.$emit("changed");
      });
    },
    previewActivity(activity) {
      this.preview = activity;
      if (
        (activity.resourceType === "DOCUMENT" ||
          activity.resourceType === "ACTIVITY") &&
        !activity.typeH5p
      ) {
        this.pdfDialog = true;
      } else {
        this.previewDialog = true;
      }
    },
    closePreview() {
      if (
        (this.preview.resourceType === "DOCUMENT" ||
          this.preview.resourceType === "ACTIVITY") &&
        !this.preview.typeH5p
      ) {
        this.pdfDialog = false;
      } else {
        this.previewDialog = false;
      }
      this.preview = null;
    },
    updateOrder(activity, order, index) {
      this.$emit("update-order", activity, order, index);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
