<template>
  <v-container fluid v-if="lectures">
    <v-card class="card-datatable">
      <v-card-title>
        <v-row v-if="header" align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col v-if="closeLecturesCheck" class="text-center">
            <v-checkbox
              v-model="closeLectures"
              :label="$t('lecture.close')"
              @change="redirectOnTableChange()"
            >
            </v-checkbox>
          </v-col>
          <v-col class="text-right" v-if="fullFiltering || datesFiltering">
            <v-btn color="primary" outlined @click="showFilters = !showFilters">
              <v-icon left dark>mdi-magnify</v-icon>
              <span>
                {{
                  showFilters
                    ? $t("actions.hideFilters")
                    : $t("actions.showFilters")
                }}
              </span>
              <v-icon right dark v-if="showFilters">mdi-chevron-up</v-icon>
              <v-icon right dark v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row align="center" v-show="showFilters" justify="center">
          <v-col cols="6" md="3" xl="2" v-if="fullFiltering">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="teachers"
              v-model="teacherFilter"
              :label="$t('teacher.name')"
              :item-text="(item) => item.name + ' ' + item.surname"
              item-value="id"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2" v-if="fullFiltering">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="students"
              v-model="studentFilter"
              :label="$t('student.name')"
              :item-text="(item) => item.name + ' ' + item.surname"
              item-value="id"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2" v-if="fullFiltering">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="products"
              v-model="productFilter"
              :label="$t('product.name')"
              :item-text="(item) => item.title"
              item-value="id"
            ></autocomplete>
          </v-col>
          <v-col cols="6" md="3" xl="2" v-if="fullFiltering">
            <autocomplete
              @change="redirectOnFilterChange"
              :items="lectureStatus"
              v-model="stateFilter"
              :label="$t('lecture.prop.state')"
              :item-text="translate"
            ></autocomplete>
          </v-col>
          <v-col cols="12" md="4" xl="2" v-if="fullFiltering || datesFiltering">
            <dateAndHourPicker
              :datePickerProp="{
                data: startTimeFilter,
                label: 'lecture.prop.startTime',
              }"
              @update-time="
                updateDateTime('startTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
          <v-col cols="12" md="4" xl="2" v-if="fullFiltering || datesFiltering">
            <dateAndHourPicker
              :datePickerProp="{
                data: endTimeFilter,
                label: 'lecture.prop.endTime',
              }"
              @update-time="
                updateDateTime('endTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
        </v-row>
        <v-data-table
          class="rows-clickable"
          :disable-pagination="!pagination"
          :footer-props="tableFooterProps"
          :hide-default-footer="!pagination"
          :headers="headers"
          :items="lectures"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @click:row="onRowClick"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.productName`]="{ item }">
            <span v-if="item.productName">
              {{
                item.productName === "-"
                  ? $t("lecture.levelTest")
                  : item.productName
              }}
            </span>
          </template>
          <template v-slot:[`item.startTime`]="{ item }">
            <span v-if="item.startTime">
              {{ item.startTime | dateTimeWithTz("long") }}
            </span>
          </template>

          <template v-slot:[`item.endTime`]="{ item }">
            <span v-if="item.endTime">
              {{ item.endTime | dateTimeWithTz("long") }}
            </span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <span v-if="item.state">
              {{ $t(`lecture.states.${item.state}`) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip
              v-if="userCanJoin(item) && linkToSession && !isAdmin"
              open-delay="400"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="joinToLecture(item)"
                  icon
                  small
                  ><v-icon color="success">login</v-icon></v-btn
                >
              </template>
              <span>{{ $t("lecture.join") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  dateArrayToDateString,
  dateToMinLocalDateOrMaxLocalDate,
} from "@/common/conversion-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import { joinToLecture, userCanJoin } from "@/common/lecture-common";
import { translate } from "@/common/translation-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import lectureStatus from "@/enumerates/LectureStatus";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);
const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

export default {
  name: "LectureList",
  components: {
    Autocomplete,
    DateAndHourPicker,
  },
  props: {
    header: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullFiltering: {
      type: Boolean,
      required: false,
      default: false,
    },
    datesFiltering: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeLecturesCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkToSession: {
      type: Boolean,
      required: false,
      default: true,
    },
    lectures: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalItems: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      closeLectures: true,
      entitiesPage: {
        ...defaultPaginationSettings,
        sortBy: [],
        sortDesc: [],
      },
      showFilters: false,
      teacherFilter: null,
      teachers: [],
      studentFilter: null,
      students: [],
      productFilter: null,
      products: [],
      startTimeFilter: null,
      endTimeFilter: null,
      stateFilter: null,
      lectureStatus,
      tableFooterProps,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    headers() {
      return [
        {
          text: this.$t("lecture.prop.course"),
          sortable: false,
          value: "productName",
        },
        {
          text: this.$t("lecture.prop.startTime"),
          value: "startTime",
        },
        {
          text: this.$t("lecture.prop.endTime"),
          value: "endTime",
        },
        {
          text: this.$t("lecture.prop.state"),
          value: "state",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    if (this.pagination) {
      //Setting route params
      if (this.$route.query.page) {
        this.entitiesPage.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.pageSize) {
        this.entitiesPage.itemsPerPage = parseInt(this.$route.query.pageSize);
      }
      if (this.$route.query.sort) {
        this.entitiesPage.sortBy = parseStringToSortBy(this.$route.query.sort);
        this.entitiesPagesortDesc = parseStringToSortDesc(
          this.$route.query.sort
        );
      }
    }
    if (this.fullFiltering) {
      if (this.$route.query.teacherFilter) {
        this.showFilters = true;
        let value = parseFloat(this.$route.query.teacherFilter);
        this.teacherFilter = isNaN(value) ? null : value;
      }

      if (this.$route.query.studentFilter) {
        this.showFilters = true;
        let value = parseFloat(this.$route.query.studentFilter);
        this.studentFilter = isNaN(value) ? null : value;
      }

      if (this.$route.query.productFilter) {
        this.showFilters = true;
        let value = parseFloat(this.$route.query.productFilter);
        this.productFilter = isNaN(value) ? null : value;
      }

      if (this.$route.query.stateFilter) {
        this.showFilters = true;
        this.stateFilter = this.$route.query.stateFilter;
      }
      // Fetch filters data
      this._fetchTeachers();
      this._fetchStudents();
      this._fetchProducts();
    }
    if (this.fullFiltering || this.datesFiltering) {
      if (this.$route.query.startTimeFilter) {
        this.showFilters = true;
        this.startTimeFilter = this.$route.query.startTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }

      if (this.$route.query.endTimeFilter) {
        this.showFilters = true;
        this.endTimeFilter = this.$route.query.endTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    }
    if (this.closeLecturesCheck && this.$route.query.closeLectures !== null) {
      this.closeLectures = this.$route.query.closeLectures === "true";
    }
  },
  methods: {
    onParamsUpdate() {
      const options = {
        params: {
          page: this.entitiesPage.page ? this.entitiesPage.page - 1 : undefined,
          size: this.entitiesPage.itemsPerPage
            ? this.entitiesPage.itemsPerPage
            : undefined,
          sort: this.entitiesPage.sortBy
            ? generateSort(this.entitiesPage)
            : undefined,
          teacherId: this.teacherFilter,
          studentId: this.studentFilter,
          productId: this.productFilter,
          startTime:
            this.startTimeFilter &&
            dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.startTimeFilter),
              false
            ),
          endTime:
            this.endTimeFilter &&
            dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.endTimeFilter),
              true
            ),
          state: this.stateFilter,
        },
      };
      if (this.closeLecturesCheck) {
        this.$emit("update:params", options, this.closeLectures);
      } else {
        this.$emit("update:params", options);
      }
    },
    _fetchTeachers() {
      TeacherEntityRepository.getAll()
        .then((data) => (this.teachers = data.content))
        .catch(() =>
          this.$log.debug("Error fetching teachers for show on filter")
        );
    },
    _fetchStudents() {
      StudentEntityRepository.getAll()
        .then((data) => (this.students = data.content))
        .catch(() =>
          this.$log.debug("Error fetching students for show on filter")
        );
    },
    _fetchProducts() {
      ProductEntityRepository.getAll()
        .then((data) => (this.products = data.content))
        .catch(() =>
          this.$log.debug("Error fetching products for show on filter")
        );
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this.onParamsUpdate();
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (this.closeLecturesCheck) {
        query.closeLectures = this.closeLectures.toString();
      }
      if (
        (!this.closeLecturesCheck && this.pagination) ||
        (this.closeLecturesCheck && !this.closeLectures)
      ) {
        this.entitiesPage = pagination;
        query.page = this.entitiesPage.page.toString();
        query.pageSize = this.entitiesPage.itemsPerPage.toString();
        query.sort = generateSort(this.entitiesPage);
      } else {
        query.page = undefined;
        query.pageSize = undefined;
        query.sort = undefined;
      }
      this.changeQueryFilters(query);
      this.redirect(query);
    },
    changeQueryFilters(query) {
      query.teacherFilter =
        this.teacherFilter != null ? this.teacherFilter : undefined;

      query.studentFilter =
        this.studentFilter != null ? this.studentFilter : undefined;

      query.productFilter =
        this.productFilter != null ? this.productFilter : undefined;

      query.startTimeFilter = this.startTimeFilter
        ? dateArrayToDateString(this.startTimeFilter)
        : undefined;

      query.endTimeFilter = this.endTimeFilter
        ? dateArrayToDateString(this.endTimeFilter)
        : undefined;

      query.stateFilter =
        this.stateFilter != null ? this.stateFilter : undefined;
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    userCanJoin,
    joinToLecture,
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    onRowClick(lecture) {
      this.$emit("click:row", lecture);
    },
    translate,
  },
};
</script>
