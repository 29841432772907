<template>
  <div v-if="!isLoading">
    <v-card flat>
      <v-card-title>
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="7"
            class="d-none d-md-block text-center text-sm-right"
          >
            <v-btn class="warning" @click="edit()">
              <v-icon>edit</v-icon>
              {{ $t("actions.edit") }}
            </v-btn>
            <v-btn
              class="primary ml-3 mt-4 mt-sm-0"
              :to="{ name: 'ChangePassword' }"
              v-if="isSameUser"
            >
              <v-icon>vpn_key</v-icon>
              {{ $t("account.actions.change_password") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="start" justify="start">
          <!-- Photo and name -->
          <v-col cols="12" md="3" lg="3">
            <!-- Title for movile version -->
            <v-row
              class="d-sm-block d-md-none"
              align="center"
              justify="space-between"
              no-gutters
            >
              <div class="headline text-center">{{ entity.companyName }}</div>
              <v-col class="text-right">
                <v-btn @click="edit()" class="warning">
                  <v-icon>edit</v-icon>
                  <span class="d-none d-sm-block">
                    {{ $t("actions.edit") }}
                  </span>
                </v-btn>
                <v-btn
                  class="primary ml-2"
                  :to="{ name: 'ChangePassword' }"
                  v-if="isSameUser"
                >
                  <v-icon>vpn_key</v-icon>
                  <span class="d-none d-sm-block">
                    {{ $t("account.actions.change_password") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centered-element">
                <v-img
                  max-height="250"
                  style="margin: auto"
                  max-width="250"
                  :src="photo"
                ></v-img>
              </v-col>
              <v-col cols="12" v-if="isAdmin">
                <span>
                  <v-icon class="mr-1">receipt</v-icon>
                  <b class="mr-4"> {{ $t("profile.fields.billing_id") }}: </b>
                  {{ entity.billingId }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <!-- Another fields -->
          <v-col cols="12" md="9" lg="9">
            <v-row
              class="d-none d-md-block"
              align="center"
              justify="space-between"
              no-gutters
            >
              <div class="headline">
                {{ entity.companyName }}
                <v-chip class="ml-4" color="primary" small>
                  {{ entity.type ? $t(`company.types.${entity.type}`) : null }}
                </v-chip>
              </div>
              <div class="caption">
                {{ entity.displayName }}
              </div>
              <div class="caption">
                {{ entity.login }}
              </div>
            </v-row>

            <v-divider class="my-2"></v-divider>

            <v-row align="end">
              <v-col cols="12">
                <span class="grey--text text--darken-2 font-weight-light">{{
                  $t("profile.contact_info")
                }}</span>
              </v-col>
              <v-col cols="12">
                <span>
                  <v-icon class="mr-1">person</v-icon>
                  <b class="mr-4">
                    {{ $t("company.prop.representative_name") }}:
                  </b>
                  {{ fullName }}
                </span>
              </v-col>
              <v-col class="mt-3" cols="12" md="6" lg="6">
                <span>
                  <v-icon class="mr-1">phone</v-icon>
                  <b class="mr-4"> {{ $t("profile.fields.main_phone") }}: </b>
                  {{ entity.mainPhone }}
                </span>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <span>
                  <v-icon class="mr-1">phone</v-icon>
                  <b class="mr-4">
                    {{ $t("profile.fields.secondary_phone") }}:
                  </b>
                  {{ entity.secondaryPhone }}
                </span>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <span>
                  <v-icon class="mr-1">email</v-icon>
                  <b class="mr-4"> {{ $t("profile.fields.main_email") }}: </b>
                  {{ entity.mainEmail }}
                </span>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <span>
                  <v-icon class="mr-1">email</v-icon>
                  <b class="mr-4">
                    {{ $t("profile.fields.secondary_email") }}:
                  </b>
                  {{ entity.secondaryEmail }}
                </span>
              </v-col>

              <v-col cols="12">
                <v-row align="start">
                  <v-col cols="12" md="2">
                    <v-icon class="mr-1">mdi-home-city</v-icon>
                    <b class="mr-4"> {{ $t("profile.fields.address") }}: </b>
                  </v-col>
                  <v-col cols="12" md="9">
                    <span>{{ fullAddress }}</span>
                  </v-col>
                  <v-col cols="4" sm="2" lg="3" xl="3">
                    <b>{{ $t("profile.fields.accept_emails") }}: </b>
                  </v-col>
                  <v-col cols="8" sm="4" lg="3" xl="2">
                    {{ entity.acceptEmails ? $t("yes") : $t("no") }}
                  </v-col>
                  <v-col cols="4" sm="2" lg="3" xl="3">
                    <b>{{ $t("profile.fields.accept_whats") }}:</b>
                  </v-col>
                  <v-col cols="8" sm="4" lg="3" xl="2">
                    {{ entity.acceptWhats ? $t("yes") : $t("no") }}
                  </v-col>
                  <v-col cols="4" sm="2" lg="3" xl="3">
                    <b>{{ $t("profile.fields.accept_calls") }}:</b>
                  </v-col>
                  <v-col cols="8" sm="4" lg="3" xl="2">
                    {{ entity.acceptCalls ? $t("yes") : $t("no") }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <span class="grey--text text--darken-2 font-weight-light">{{
                  $t("profile.personal_info")
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="entity" justify="start">
              <v-col cols="12" md="6" lg="6">
                <b class="mr-4">{{ $t("profile.fields.nif") }}:</b>
                <span v-if="entity.nif">
                  {{ entity.nif }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="entity" justify="start">
              <v-col cols="12" md="6" lg="6">
                <span v-if="entity.timeZone">
                  <b class="mr-4"> {{ $t("profile.fields.timeZone") }}: </b>
                  {{
                    getOffset(entity.timeZone.name) +
                    " " +
                    $t(`timezones.${entity.timeZone.name}`)
                  }}
                </span>
                <span v-else>
                  <b class="mr-4"> {{ $t("profile.fields.timeZone") }}: </b>
                  {{ $t("profile.fields.timeZone_automatic") }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <loading-page v-else></loading-page>
</template>

<script>
import auth from "@/common/auth";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { getOffset } from "@/common/timeZoneUtils";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const CompanyEntityRepository = RepositoryFactory.get(
  "CompanyEntityRepository"
);
const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  components: {
    LoadingPage,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      user: auth.getUser(),
      entity: {},
      loading: false,
      photo: require("@/assets/icon_visits.png"),
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isLoading() {
      return this.loading;
    },
    fullName() {
      return (
        (this.entity.name ? this.entity.name : "") +
        " " +
        (this.entity.surname ? this.entity.surname : "")
      );
    },
    isSameUser() {
      return this.entity ? this.entity.userId === this.user.id : false;
    },
    fullAddress() {
      const aux = [
        this.entity.address,
        this.entity.postalcode,
        this.entity.town,
        this.entity.country,
      ].filter((i) => i && i !== "");
      return aux.join(", ");
    },
  },
  mounted() {
    this._fetchData(this.companyId);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return CompanyEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          if (response) {
            UserDataEntityRepository.getPhoto(response.userId).then((data) => {
              if (data.size > 0) {
                this.photo = URL.createObjectURL(data);
              }
            });
          }
        })
        .catch(() => this.$log.debug("Error fetching company with ID: " + id))
        .finally(() => (this.loading = false));
    },
    edit() {
      const route = this.$route.params.id
        ? "CompanyView Edit"
        : "ProfileCompanyView Edit";
      this.$router.push({
        name: route,
        params: { backPrevious: true },
      });
    },
    getOffset: getOffset,
  },
};
</script>

<style scoped>
.centered-element {
  margin: auto;
}
</style>
