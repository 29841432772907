<template>
  <v-dialog v-model="showDialog" width="70%">
    <v-card>
      <v-card-title>
        <h2 class="mx-6">
          {{ $t("teacher.tabs.schedules.unavailabilityDialog.title") }}
        </h2>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <!-- Form with the dates -->
      <v-card-text>
        <v-row align="end" justify="end">
          <v-col cols="6" md="6" lg="6">
            <dateAndHourPicker
              :datePickerProp="{
                data: date.startDate,
                label: 'teacher.tabs.schedules.unavailabilityDialog.dateStart',
              }"
              :timePickerProp="{
                data: date.startTime,
                label: 'teacher.tabs.schedules.unavailabilityDialog.timeStart',
              }"
              @update-time="updateDateTimeStart"
              :isFullDay="isFullDay"
            ></dateAndHourPicker>
          </v-col>
          <v-col cols="6" md="6" lg="6">
            <dateAndHourPicker
              :datePickerProp="{
                data: date.endDate,
                label: 'teacher.tabs.schedules.unavailabilityDialog.dateEnd',
              }"
              :timePickerProp="{
                data: date.endTime,
                label: 'teacher.tabs.schedules.unavailabilityDialog.timeEnd',
              }"
              @update-time="updateDateTimeEnd"
              :isFullDay="isFullDay"
            ></dateAndHourPicker>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="2" md="2" lg="2">
            <v-checkbox
              v-model="isFullDay"
              :label="$t('teacher.tabs.schedules.unavailabilityDialog.allDay')"
              @change="handlerCheckButton"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Button actions -->
      <v-card-actions>
        <v-row align="center" justify="space-around">
          <v-col cols="6" md="3" lg="3">
            <v-btn
              block
              :disabled="!checkDisabled"
              color="success"
              @click.stop="persistUnavailability"
            >
              {{ $t("actions.add") }}
            </v-btn>
          </v-col>
          <v-col cols="6" md="3" lg="3">
            <v-btn block color="secondary" @click.stop="cancelUnavailability">
              {{ $t("actions.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateArrayToUTCDateFormatted } from "@/common/conversion-utils";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import moment from "moment-timezone";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherUnavailabilityEntityRepository = RepositoryFactory.get(
  "TeacherUnavailabilityEntityRepository"
);
const StudentUnavailabilityEntityRepository = RepositoryFactory.get(
  "StudentUnavailabilityEntityRepository"
);

export default {
  components: {
    DateAndHourPicker,
  },
  props: {
    value: {
      type: Boolean,
    },
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    timezone: {
      type: Object,
      required: false,
    },
    unavailabilities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      date: {
        startDate: null,
        startTime: ["00", "00"],
        endDate: null,
        endTime: ["23", "59"],
      },
      isFullDay: true,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    checkDisabled() {
      return (
        this.date &&
        this.date.startDate &&
        this.date.endDate &&
        this.date.startTime &&
        this.date.endTime
      );
    },
  },
  methods: {
    /**
     * Methods for updating the dates on the form
     */
    updateDateTimeStart(data) {
      data.date
        ? (this.date.startDate = data.date)
        : (this.date.startDate = null);

      data.time
        ? (this.date.startTime = data.time)
        : (this.date.startTime = null);
    },
    updateDateTimeEnd(data) {
      data.date ? (this.date.endDate = data.date) : (this.date.endDate = null);
      data.time ? (this.date.endTime = data.time) : (this.date.endTime = null);
    },
    /**
     * Actions for the buttons
     */
    persistUnavailability() {
      if (!this._validateDates()) {
        this.$notify({
          title: this.$t(
            "teacher.tabs.schedules.unavailabilityDialog.invalid_range.title"
          ),
          text: this.$t(
            "teacher.tabs.schedules.unavailabilityDialog.invalid_range.text"
          ),
          type: "error",
          duration: 30000,
        });
      } else {
        let repository =
          this.entityType == "teacher"
            ? TeacherUnavailabilityEntityRepository
            : StudentUnavailabilityEntityRepository;
        let unavailability = {
          id: null,
          startDate: dateArrayToUTCDateFormatted(
            [...this.date.startDate, ...this.date.startTime],
            this.timezone
          ),
          endDate: dateArrayToUTCDateFormatted(
            [...this.date.endDate, ...this.date.endTime],
            this.timezone
          ),
        };
        unavailability[this.entityType] = { id: this.entityId };
        repository
          .save(unavailability)
          .then(() => {
            this.$emit("unavailabilityChanged");
            this.showDialog = false;
          })
          .catch(() =>
            this.$log.debug("Error saving unavailability: " + unavailability)
          );
      }
    },
    //Reset the modal forms
    cancelUnavailability() {
      this.date = {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      };
      this.showDialog = false;
    },
    /**
     * Filter new unavailability is out of current unavailabilities dates
     */
    _validateDates() {
      let startDateTime = moment.tz(
        [...this.date.startDate, ...this.date.startTime],
        this.timezone ? this.timezone.name : moment.tz.guess()
      );
      let endDateTime = moment.tz(
        [...this.date.endDate, ...this.date.endTime],
        this.timezone ? this.timezone.name : moment.tz.guess()
      );
      return (
        this.unavailabilities.findIndex((el) => {
          let unavailabilityStart = moment
            .utc(el.startDate)
            .tz(this.timezone ? this.timezone.name : moment.tz.guess());
          let unavailabilityEnd = moment
            .utc(el.endDate)
            .tz(this.timezone ? this.timezone.name : moment.tz.guess());
          // if new unavailability is partially or totally in an unavailability dates range is accepted
          return (
            (startDateTime.isAfter(unavailabilityStart) &&
              startDateTime.isBefore(unavailabilityEnd)) ||
            (endDateTime.isAfter(unavailabilityStart) &&
              endDateTime.isBefore(unavailabilityEnd)) ||
            (startDateTime.isBefore(unavailabilityEnd) &&
              endDateTime.isAfter(unavailabilityEnd))
          );
        }) == -1
      );
    },
    handlerCheckButton() {
      if (this.isFullDay) {
        this.date.startTime = ["00", "00"];
        this.date.endTime = ["23", "59"];
      }
    },
  },
};
</script>

<style></style>
