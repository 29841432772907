<template>
  <v-dialog persistent :value="dialog" width="80%">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("student.tabs.levels.headers.new_language_trial") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"> <v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-row justify="center">
              <v-col cols="12" md="4">
                <!-- Language selector -->
                <language-selector
                  v-model="selectedLang"
                  :filter="hiddenLanguages"
                >
                </language-selector>
              </v-col>
              <v-col cols="12" md="4">
                <!-- Level selector -->
                <level-selector
                  v-model="selectedLevel"
                  :languageFilter="languageFilter"
                  class="required"
                  :disabled="selectedLang == null"
                  :label="$t('level.name')"
                  :rules="[(v) => !!v || $t('level.error.required')]"
                >
                </level-selector>
              </v-col>
              <!-- Limit date selector -->
              <v-col cols="12" md="4">
                <v-menu
                  v-model="menuPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedLimitDate"
                      :label="$t('student.tabs.levels.headers.limit_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedLimitDate"
                    @input="menuPicker = false"
                    :min="today"
                    :locale="localeRoot"
                    :first-day-of-week="$t('datePicker.firstDayOfTheWeek')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="text-center" no-gutters justify="space-around">
          <v-col cols="6" md="3">
            <v-btn color="secondary" @click="$emit('close')"
              ><v-icon class="mr-2">close</v-icon
              >{{ $t(closeButtonText) }}</v-btn
            >
          </v-col>
          <v-col cols="6" md="3">
            <v-btn
              :disabled="!validForm || !selectedLang"
              color="success"
              @click="
                $emit('save', {
                  level: selectedLevel,
                  limitDate: selectedLimitDate,
                })
              "
              ><v-icon class="mr-2">send</v-icon>
              {{ $t(saveButtonText) }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LanguageSelector from "@/components/selectors/LanguageSelector";
import LevelSelector from "@/components/selectors/LevelSelector";
import {
  dateToDateArray,
  localDateToISOString,
} from "@/common/conversion-utils";

export default {
  name: "LanguageSelectorDialog",
  components: {
    LanguageSelector,
    LevelSelector,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    hiddenLanguages: {
      type: Array,
      required: false,
    },
    saveButtonText: {
      type: String,
      default: () => "actions.save",
      required: false,
    },
    closeButtonText: {
      type: String,
      default: () => "actions.cancel",
      required: false,
    },
  },
  data() {
    return {
      selectedLang: null,
      selectedLevel: null,
      selectedLimitDate: null,
      menuPicker: false,
      validForm: true,
      today: localDateToISOString(dateToDateArray(new Date())),
    };
  },
  computed: {
    languageFilter() {
      return this.selectedLang?.id;
    },
    localeRoot() {
      return this.$i18n.locale;
    },
  },
  watch: {
    languages: {
      handler(newValue) {
        this.languages = newValue;
      },
      deep: true,
    },
    selectedLevel(newValue) {
      if (
        newValue &&
        (!this.selectedLang || this.selectedLang.id !== newValue.language.id)
      ) {
        this.selectedLang = newValue.language;
      }
    },
    selectedLang(newValue, oldValue) {
      if (
        newValue &&
        (oldValue ||
          (this.selectedLevel &&
            this.selectedLevel.language.id !== newValue.id))
      ) {
        this.selectedLevel = null;
      }
    },
  },
};
</script>
