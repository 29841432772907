<template>
  <v-container>
    <v-row justify="end" class="mr-2">
      <v-btn v-if="creatable" color="success" @click="addWordDialog = true">
        <v-icon>add</v-icon>
        <span class="d-none d-sm-block">
          {{ $t("wordlist.actions.add-word") }}
        </span>
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <debounced-text-field
          v-model="search"
          append-icon="search"
          class="d-md-inline-block"
          dense
          hide-details
          :label="$t('search')"
          @input="onSearchChange"
        ></debounced-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="wordsTableHeaders"
      :items="words"
      :options="wordsPagination"
      :server-items-length="wordsTotalItems"
      :loading="loadingWords"
      :footer-props="tableFooterProps"
      @update:options="redirectOnTableChange"
    >
      <template v-slot:[`item.wordTopics`]="{ item }">
        <v-container
          class="d-inline"
          v-for="wordTopic in item.wordTopics"
          :key="wordTopic.topicId"
        >
          <v-chip class="mt-2" color="primary" small>
            {{ wordTopic.topicName }}
          </v-chip>
        </v-container>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="editable"
              icon
              color="orange"
              @click="openModifyDialog(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("actions.edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="removable"
              icon
              color="red"
              @click="deleteWord(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("actions.delete") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog scrollable width="80%" v-model="addWordDialog">
      <word-form
        v-if="addWordDialog"
        :wordProp="selectedWord"
        :language="language"
        @cancel="closeAddWordDialog"
        @save="saveNewOrModifiedWord"
      ></word-form>
    </v-dialog>
  </v-container>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import WordForm from "@/mockups/content-cloud/wordlist/WordForm";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const WordEntityRepository = RepositoryFactory.get("WordEntityRepository");

export default {
  name: "WordList",
  props: {
    wordlist: {
      value: Number,
      required: false,
    },
    creatable: {
      value: Boolean,
      default: false,
    },
    editable: {
      value: Boolean,
      default: false,
    },
    language: {
      value: Object,
      required: false,
    },
    removable: {
      value: Boolean,
      default: false,
    },
  },
  components: { DebouncedTextField, WordForm },
  data() {
    return {
      words: [],
      addWordDialog: false,
      loadingWords: false,
      search: this.$route.query.search,
      selectedWord: null,
      wordsPagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort) || ["name"],
        sortDesc: parseStringToSortDesc(this.$route.query.sort) || [false],
      },
      wordsTotalItems: -1,
      tableFooterProps,
    };
  },
  computed: {
    wordsTableHeaders() {
      return [
        {
          text: this.$t("word.prop.name"),
          value: "name",
        },
        {
          text:
            this.language && this.language.name === "CHINESE"
              ? this.$t("word.prop.pronunciation.pinyin")
              : this.$t("word.prop.pronunciation.latin"),
          value: "pronunciation",
        },
        {
          text: this.$t("word.prop.definition"),
          value: "definition",
        },
        {
          text: this.$t("word.prop.topics"),
          sortable: false,
          value: "wordTopics",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  created() {
    this._fetchWords();
  },
  methods: {
    _fetchWords() {
      if (this.wordlist) {
        this.loadingWords = true;
        const options = {
          params: {
            page: this.wordsPagination.page - 1,
            size: this.wordsPagination.itemsPerPage,
            sort: generateSort(this.wordsPagination),
            filters: "wordlist.id:" + this.wordlist,
            search: this.search,
          },
        };
        return WordEntityRepository.getAll(options)
          .then((res) => {
            this.words = res.content;
            this.wordsTotalItems = res.totalElements;
          })
          .catch(() =>
            this.$log.debug(
              "Error fetching words with params: " +
                JSON.stringify(options.params)
            )
          )
          .finally(() => (this.loadingWords = false));
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this._fetchWords(this.$route.params.id);
      }
    },
    redirectOnTableChange(pagination = this.wordsPagination) {
      this.wordsPagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.wordsPagination.page.toString();
      query.pageSize = this.wordsPagination.itemsPerPage.toString();
      query.sort = generateSort(this.wordsPagination);
      query.search = this.search ? this.search : undefined;
      this.redirect(query);
    },
    onSearchChange() {
      if (this.wordsPagination.page !== 1) {
        this.wordsPagination.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    openModifyDialog(item) {
      this.selectedWord = item;
      this.addWordDialog = true;
    },
    saveNewOrModifiedWord(word) {
      this.selectedWord ? this.modifyWord(word) : this.addWord(word);
    },
    addWord(word) {
      if (this.wordlist != null) {
        word.wordlist = this.wordlist;
        this._saveWord(word);
      } else {
        this.$emit("savedWord", this.words);
        this.words.push(word);
      }
      this.closeAddWordDialog();
    },
    modifyWord(wordModified) {
      if (this.wordlist != null) {
        this._saveWord(wordModified);
      } else {
        const index = this.words.indexOf(this.selectedWord);
        this.words.splice(index, 1, wordModified);
      }
      this.selectedWord = null;
      this.closeAddWordDialog();
    },
    _saveWord(word) {
      this.loadingWords = true;
      WordEntityRepository.save(word)
        .then(() => this._fetchWords())
        .catch(() => this.$log.debug("Error saving word " + word.name))
        .finally(() => (this.loadingWords = false));
    },
    closeAddWordDialog() {
      this.selectedWord = null;
      this.addWordDialog = false;
    },
    deleteWord(wordToDelete) {
      if (this.wordlist != null) {
        this.loadingWords = true;
        WordEntityRepository.delete(wordToDelete.id)
          .then(() => this._fetchWords())
          .catch(() =>
            this.$log.debug("Error deleting word with ID " + wordToDelete.id)
          )
          .finally(() => (this.loadingWords = false));
      } else {
        const index = this.words.indexOf(wordToDelete);
        this.words.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped></style>
