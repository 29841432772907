<template>
  <v-container v-if="!loading && course">
    <v-card>
      <v-card-title>
        <!-- Course details -->
        <v-row v-if="!editingDetails" align="center" justify="space-between">
          <v-col cols="12" md="6">
            <h2>
              {{ course.title }}
              <v-chip v-if="course.level" color="warning" class="ma-2">
                {{ course.level.language.name }}
              </v-chip>
              <v-chip v-if="course.level" color="primary" class="ma-2">
                {{ course.level.name }}
              </v-chip>
            </h2>
          </v-col>

          <v-spacer></v-spacer>
          <!-- Button for enter editing details mode -->
          <v-col
            cols="12"
            md="6"
            class="text-right d-md-inline-flex justify-md-end"
          >
            <v-btn class="mt-1 mr-0 mr-md-2" @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>
            <v-btn
              v-if="!editingDetails"
              color="warning"
              class="mt-1 mr-0 mr-md-2"
              @click="editingDetails = !editingDetails"
            >
              <v-icon class="mr-2">mdi-pencil</v-icon>
              {{ $t("course.actions.edit_details") }}</v-btn
            >
            <v-edit-dialog
              :cancel-text="$t('actions.cancel')"
              large
              persistent
              :save-text="$t('actions.save')"
              @save="duplicateCourse"
            >
              <v-btn v-if="!editingDetails" class="mt-1" color="primary">
                <v-icon class="mr-2">content_copy</v-icon>
                {{ $t("course.actions.duplicate") }}
              </v-btn>
              <template v-slot:input>
                <span class="my-8 text-h6">
                  {{ $t("course.actions.duplicate") }}
                </span>
                <v-container class="mt-2">
                  <v-text-field
                    v-model="duplicatedCourseName"
                    class="required"
                    dense
                    :label="$t('course.prop.title')"
                    outlined
                    :rules="[(v) => !!v || $t('course.error.required')]"
                  ></v-text-field>
                </v-container>
              </template>
            </v-edit-dialog>
            <v-btn color="error" class="mt-1 ml-2" @click="deleteDialog = true">
              <v-icon class="mr-2">mdi-delete</v-icon>
              {{ $t("actions.delete") }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <span>
              {{ course.description }}
            </span>
          </v-col>
        </v-row>

        <!-- Course Details Form -->
        <v-row v-else no-gutters>
          <course-form
            :simplified="true"
            :entity="course"
            @saved="saveCourseDetails"
            @cancelEdit="cancelCourseEdit"
          ></course-form>
        </v-row>
      </v-card-title>

      <!-- Units of a course -->
      <v-divider class="primary"></v-divider>
      <v-card-text>
        <simple-unit-list
          @updated="updateInfo()"
          :course="course"
          :units="course.units"
        ></simple-unit-list>
      </v-card-text>
    </v-card>
    <!-- Dialogo para la eliminacion de cursos -->
    <delete-dialog
      :dialog="deleteDialog"
      :loading="loading"
      @cancel="deleteDialog = false"
      @submit="deleteCourse"
    ></delete-dialog>
  </v-container>
  <loading-page v-else> </loading-page>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import CourseForm from "../_components/CourseForm";
import SimpleUnitList from "../_components/SimpleUnitList.vue";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import checkInvalidID from "@/common/checkInvalidID";

const CourseEntityRepository = RepositoryFactory.get("CourseEntityRepository");

export default {
  name: "CourseDetail",
  components: {
    CourseForm,
    SimpleUnitList,
    LoadingPage,
    DeleteDialog,
  },
  data() {
    return {
      course: null,
      loading: false,
      editingDetails: false,
      duplicatedCourseName: null,
      deleteDialog: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  mounted() {
    this._fetchData(this.$route.params.id);
  },
  computed: {
    ...mapAuthGetter(["isContentEditor"]),
  },
  methods: {
    _fetchData(courseId) {
      this.loading = true;
      CourseEntityRepository.get(courseId)
        .then((res) => {
          if (res.units) res.units.sort((a, b) => a.id - b.id);
          this.course = res;
          if (this.isContentEditor) {
            const lastCourse = JSON.stringify({
              id: res.id,
              name: res.title,
            });
            localStorage.setItem("lastCourse", lastCourse);
          }
        })
        .catch((err) => {
          this.$log.debug("Error fetching course with ID " + courseId);
          checkInvalidID(err);
        })
        .finally(() => (this.loading = false));
    },
    // Edit details of a course
    saveCourseDetails(course) {
      Object.assign(this.course, course);
      this.editingDetails = false;
    },
    duplicateCourse() {
      this.loading = true;
      const duplicateCourseObj = {
        id: this.course.id,
        name: this.duplicatedCourseName,
      };
      CourseEntityRepository.duplicate(duplicateCourseObj)
        .then((res) =>
          this.$router.push({
            name: "Course Detail",
            params: { id: res.id, backPrevious: true },
          })
        )
        .catch(() =>
          this.$log.debug("Error duplicating course with id " + this.course.id)
        )
        .finally(() => {
          this.duplicatedCourseName = null;
          this.loading = false;
        });
    },
    cancelCourseEdit() {
      this.editingDetails = false;
    },
    updateInfo() {
      this._fetchData(this.$route.params.id);
    },
    deleteCourse() {
      this.loading = true;
      CourseEntityRepository.delete(this.$route.params.id)
        .then(() => {
          this.$notify({
            title: this.$t("course.messages.deleted_title"),
            text: this.$t("course.messages.deleted_text"),
            type: "success",
          });
          this.$router.push({ name: "Course List" });
        })
        .finally(() => {
          this.loading = false;
          this.deleteDialog = false;
        });
    },
    back() {
      this.$router.push({
        name: "Course List",
        params: { backAction: true },
      });
    },
  },
};
</script>

<style></style>
