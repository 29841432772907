import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/companyStudents";

export default {
  async getAllByCompany(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getByKey(key) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/findByKey`, {
        params: {
          key: key,
        },
      })
    ).data;
  },

  async linkToStudent(userId, key) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/link-to-student`, null, {
        params: {
          user_id: userId,
          key: key,
        },
      })
    ).data;
  },

  async save(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
  },

  async delete(id) {
    return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
  },
};
