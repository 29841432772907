<template>
  <v-alert
    prominent
    outlined
    border="left"
    icon="mdi-alert"
    color="warning"
    text
    dismissible
    v-if="!hasAll"
  >
    <ul>
      <li v-if="!hasStudentLevelEvaluation">
        <span class="text-overline">
          {{
            $t("student.dashboard.information_panel.student_level_evaluation")
          }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileStudentLevelsTab' }"
        >
          {{ $t("student.dashboard.information_panel.link.levels") }}
        </v-btn>
      </li>
      <li v-if="hasStudentLevelEvaluation && !hasLevelTestRequested">
        <span class="text-overline">
          {{ $t("student.dashboard.information_panel.level_test_request") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileStudentLevelsTab' }"
        >
          {{ $t("student.dashboard.information_panel.link.levels") }}
        </v-btn>
      </li>
      <li v-if="!hasAvailabilities">
        <span class="text-overline">
          {{ $t("student.dashboard.information_panel.availabilities") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileStudentSchedulesTab' }"
        >
          {{ $t("student.dashboard.information_panel.link.schedule") }}
        </v-btn>
      </li>
      <li v-if="!hasBalance">
        <span class="text-overline">
          {{ $t("student.dashboard.information_panel.balance") }}
        </span>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'ProfileStudentBalanceTab' }"
        >
          {{ $t("student.dashboard.information_panel.link.balance") }}
        </v-btn>
      </li>
      <li v-if="isAvailable && !hasCourses">
        <span class="text-overline">
          {{ $t("student.dashboard.information_panel.courses") }}
        </span>
        <v-btn text small color="primary" :to="{ name: 'Product List' }">
          {{ $t("student.dashboard.information_panel.link.product_list") }}
        </v-btn>
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: {
    hasStudentLevelEvaluation: {
      type: Boolean,
      required: true,
    },
    hasLevelTestRequested: {
      type: Boolean,
      required: true,
    },
    hasAvailabilities: {
      type: Boolean,
      required: true,
    },
    hasBalance: {
      type: Boolean,
      required: true,
    },
    hasCourses: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasAll() {
      return (
        this.hasStudentLevelEvaluation &&
        this.hasLevelTestRequested &&
        this.hasAvailabilities &&
        this.hasBalance &&
        this.hasCourses
      );
    },
    isAvailable() {
      return (
        this.hasStudentLevelEvaluation &&
        this.hasLevelTestRequested &&
        this.hasAvailabilities &&
        this.hasBalance
      );
    },
  },
};
</script>

<style scoped>
li {
  text-align: initial !important;
}
.v-btn {
  margin-left: 4px;
}
</style>
