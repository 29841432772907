var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"65%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("wallet.transfer_dialog.title"))+" ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('number-field',{staticClass:"required",attrs:{"type":"double","label":_vm.$t('wallet.transfer_dialog.amount'),"rules":[
                  function (v) { return !!v || _vm.$t('wallet.form.validation.amount_required'); },
                  function (v) { return parseInt(v) >= 5 ||
                    _vm.$t('wallet.form.validation.amount_min', {
                      amount: _vm.minAmount,
                      currency: _vm.currency,
                    }); },
                  function (v) { return parseInt(v) <= _vm.balance ||
                    _vm.$t('wallet.form.validation.balance_exceeded'); },
                  function (v) { return parseInt(v) <= 200 ||
                    _vm.$t('wallet.form.validation.amount_max', {
                      amount: _vm.maxAmount,
                      currency: _vm.currency,
                    }); } ]},model:{value:(_vm.transfer.amount),callback:function ($$v) {_vm.$set(_vm.transfer, "amount", $$v)},expression:"transfer.amount"}})],1),_c('v-row',[_c('autocomplete',{staticClass:"required",attrs:{"items":_vm.targets,"label":_vm.$t('wallet.transfer_dialog.student'),"rules":[
                  function (v) { return !!v || _vm.$t('wallet.form.validation.student_required'); } ],"item-text":function (item) { return item.name + ' ' + item.surname; },"item-value":"userId","multiple":_vm.multipleTargets},model:{value:(_vm.transfer.target),callback:function ($$v) {_vm.$set(_vm.transfer, "target", $$v)},expression:"transfer.target"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")]),_c('v-btn',{staticClass:"mx-4 success",attrs:{"disabled":!_vm.validForm,"loading":_vm.loading},on:{"click":function($event){return _vm.$emit('save', _vm.transfer)}}},[_c('v-icon',[_vm._v("mdi-swap-horizontal")]),_vm._v(" "+_vm._s(_vm.$t("wallet.actions.exec_transfer"))+" ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }