import { getUserTimeZone } from "@/common/timeZoneUtils";
import moment from "moment-timezone";

function dateArrayToDate(dateArray) {
  if (dateArray) {
    let dateArrayFormatted = dateArray.slice(0, 6);
    dateArrayFormatted[1] -= 1;
    return new Date(...dateArrayFormatted);
  }
  return null;
}

/** Transforms dateArray [YYYY, MM, DD] to "YYYY-MM-DD" format string **/
function dateArrayToDateString(dateArray) {
  if (dateArray && dateArray.length >= 3) {
    return dateArray
      .slice(0, 3)
      .map((e) => (e < 10 ? "0" + e.toString() : e.toString()))
      .join("-");
  }
  return null;
}

function dateArrayToDateWithTimezone(dateArray) {
  if (dateArray) {
    const dateArrayDate = dateArrayToDate(dateArray);
    const timezoneOffset = dateArrayDate.getTimezoneOffset();
    return new Date(dateArrayDate.getTime() - 60000 * timezoneOffset);
  }
  return null;
}

function dateToDateString(date) {
  if (!date) return null;
  return date.toISOString().slice(0, 10);
}

function localDateToISOString(localDate) {
  const date = localDate
    ? localDate[3] != null
      ? new Date(
          localDate[0],
          localDate[1] - 1,
          localDate[2],
          localDate[3],
          localDate[4],
          localDate[5]
        )
      : new Date(localDate[0], localDate[1] - 1, localDate[2])
    : null;
  // This fixes the time difference
  const dateTimeZone = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  return dateTimeZone.toISOString();
}

function dateToDateArray(date) {
  if (date) {
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  }
  return null;
}

function dateToDateTimeArray(date) {
  if (date) {
    return [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
  }
  return null;
}

// return date in 'yyyy-mm-dd' format
function formatDateInverse(date) {
  if (date) {
    let currentDate = date;
    // if date is in milliseconds
    if (!(date instanceof Date)) {
      let milliseconds = typeof date == "string" ? parseInt(date) : date;
      currentDate = new Date(milliseconds);
    }
    let year = currentDate.getFullYear();
    let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    let day = ("0" + currentDate.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
  }
}

function localDateToVCalendarString(localDate) {
  const year = localDate[0];
  const month = ("0" + localDate[1]).slice(-2);
  const day = ("0" + localDate[2]).slice(-2);
  if (localDate.length < 4) return year + "-" + month + "-" + day;
  const hour = localDate.length >= 4 ? ("0" + localDate[3]).slice(-2) : "00";
  const min = localDate.length >= 5 ? ("0" + localDate[4]).slice(-2) : "00";
  return year + "-" + month + "-" + day + " " + hour + ":" + min;
}

function _getAsLocalDate(mom) {
  let dateformat = mom.format().substring(0, 19);
  return new Date(dateformat);
}

function utcDateArrayToDateWithTimezone(dateArray, timeZone) {
  return _getAsLocalDate(utcDateArrayToMoment(dateArray, timeZone));
}

function localDateToTimezone(date, timeZone) {
  let userTimeZone = timeZone ? timeZone.name : getUserTimeZone();
  let mom = moment(date).tz(userTimeZone);
  return _getAsLocalDate(mom);
}

function utcDateArrayToDateWithTimezoneFormatted(dateArray, timeZone) {
  return utcDateArrayToMoment(dateArray, timeZone).format().substring(0, 16);
}

function utcDateArrayToMoment(dateArray, timeZone) {
  let userTimeZone = timeZone ? timeZone.name : getUserTimeZone();
  let formattedArray = JSON.parse(JSON.stringify(dateArray));
  formattedArray[1] = formattedArray[1] - 1;
  if (formattedArray.length > 6) {
    formattedArray.splice(6);
  }
  return moment.utc(formattedArray).tz(userTimeZone);
}

function momentToUTCDateArray(mmnt) {
  let dateArray = mmnt.utc().toArray();
  dateArray[1] = dateArray[1] + 1;
  return dateArray;
}

function dateArrayToUTCMoment(dateArray, timeZone) {
  const userTimeZone = timeZone ? timeZone.name : getUserTimeZone();
  let formattedArray = JSON.parse(JSON.stringify(dateArray));
  formattedArray[1] = formattedArray[1] - 1;
  return moment.tz(formattedArray, userTimeZone).utc();
}

function dateArrayToUTCDateFormatted(dateArray, timeZone) {
  return dateArrayToUTCMoment(dateArray, timeZone).format().substring(0, 16);
}

function dateToMinLocalDateOrMaxLocalDate(date, max) {
  return max ? date + "T23:59:59" : date + "T00:00:00";
}

function timeSlotToWeekMoment(day, hours, minutes) {
  let distance = (day == 0 ? 7 : day) - 1;
  let date = moment().startOf("isoWeek").add(distance, "days");
  date.hours(hours);
  date.minutes(minutes);
  return date;
}

function timeSlotToUTCTimeSlot(timeSlot, tz) {
  const start = timeSlotToWeekMoment(
    timeSlot.day,
    timeSlot.startTimeHour,
    timeSlot.startTimeMinute
  )
    .tz(tz, true)
    .utc();
  const end = timeSlotToWeekMoment(
    timeSlot.day,
    timeSlot.endTimeHour,
    timeSlot.endTimeMinute
  )
    .tz(tz, true)
    .utc();
  return {
    day: start.day(),
    startTimeHour: start.hour(),
    startTimeMinute: start.minute(),
    endTimeHour: end.hour(),
    endTimeMinute: end.minute(),
  };
}

export {
  dateArrayToDate,
  dateArrayToDateString,
  dateToDateArray,
  dateArrayToDateWithTimezone,
  formatDateInverse,
  localDateToVCalendarString,
  localDateToTimezone,
  utcDateArrayToDateWithTimezone,
  utcDateArrayToDateWithTimezoneFormatted,
  utcDateArrayToMoment,
  momentToUTCDateArray,
  dateArrayToUTCDateFormatted,
  dateToMinLocalDateOrMaxLocalDate,
  dateToDateTimeArray,
  timeSlotToWeekMoment,
  timeSlotToUTCTimeSlot,
  localDateToISOString,
  dateToDateString,
};
