import ActivityCreate from "@/mockups/content-cloud/activity/ActivityCreate";
import ActivityDetail from "@/mockups/content-cloud/activity/ActivityDetail";
import ActivityList from "@/mockups/content-cloud/activity/ActivityList";
import H5PEditor from "@/mockups/content-cloud/activity/H5PEditor";
import MediaLibrary from "@/mockups/content-cloud/activity/MediaLibrary";
import CourseCreate from "@/mockups/content-cloud/course/CourseCreate";
import CourseDetail from "@/mockups/content-cloud/course/CourseDetail";
import CourseList from "@/mockups/content-cloud/course/CourseList";
import CourseSimpleDetail from "@/mockups/content-cloud/course/CourseSimpleDetail";
import LevelTestDetail from "@/mockups/content-cloud/levelTest/LevelTestDetail";
import LevelTestForm from "@/mockups/content-cloud/levelTest/LevelTestForm";
import LevelTestList from "@/mockups/content-cloud/levelTest/LevelTestList";
import TopicDetail from "@/mockups/content-cloud/topic/TopicDetail";
import TopicForm from "@/mockups/content-cloud/topic/TopicForm";
import TopicList from "@/mockups/content-cloud/topic/TopicList";
import WordlistDetail from "@/mockups/content-cloud/wordlist/WordlistDetail";
import WordlistForm from "@/mockups/content-cloud/wordlist/WordlistForm";
import WordlistList from "@/mockups/content-cloud/wordlist/WordlistList";

const routes = [
  {
    path: "/content-editor/course-list",
    name: "Course List",
    component: CourseList,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "contentEditor.headers.course_list",
    },
  },
  {
    path: "/course/:id",
    name: "Course Simple Detail",
    component: CourseSimpleDetail,
    meta: {
      public: true,
      label: "contentEditor.headers.course_detail",
    },
  },
  {
    path: "/content-editor/course/form/new",
    name: "Create Course",
    component: CourseCreate,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "contentEditor.headers.new_course",
    },
  },
  {
    path: "/content-editor/course/:id",
    name: "Course Detail",
    component: CourseDetail,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "contentEditor.headers.course_detail",
    },
  },
  {
    path: "/content-editor/wordlist-list",
    name: "Wordlist List",
    component: WordlistList,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "wordlist.headers.list",
    },
  },
  {
    path: "/content-editor/wordlist/:id",
    name: "Wordlist Detail",
    component: WordlistDetail,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "wordlist.headers.detail",
    },
  },
  {
    path: "/content-editor/wordlist/form/new",
    name: "Wordlist Create",
    component: WordlistForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "wordlist.headers.form",
    },
  },
  {
    path: "/content-editor/wordlist/:id/form",
    name: "Wordlist Form",
    component: WordlistForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "wordlist.headers.form",
    },
  },
  {
    path: "/content-editor/activity-list",
    name: "Activity List",
    component: ActivityList,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "activity.headers.list",
    },
  },
  {
    path: "/activity/:id",
    name: "Activity Detail",
    component: ActivityDetail,
    meta: {
      authority: [
        "ROLE_CONTENT_EDITOR",
        "ROLE_TEACHER",
        "ROLE_TEACHER_CANDIDATE",
        "ROLE_STUDENT",
        "ROLE_STUDENT_CANDIDATE",
      ],
      label: "activity.headers.detail",
    },
  },
  {
    path: "/content-editor/activity/form/new",
    name: "Activity Create",
    component: ActivityCreate,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "activity.headers.create",
    },
  },
  {
    path: "/content-editor/activity/:id/h5p-editor",
    name: "H5PEditor",
    component: H5PEditor,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "activity.headers.h5p_editor",
    },
  },
  {
    path: "/content-editor/topics-list",
    name: "Topic List",
    component: TopicList,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "topic.headers.list",
    },
  },
  {
    path: "/content-editor/topics/form/new",
    name: "Topic Create",
    component: TopicForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "topic.headers.form",
    },
  },
  {
    path: "/content-editor/topics/:id",
    name: "Topic Detail",
    component: TopicDetail,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "topic.headers.detail",
    },
  },
  {
    path: "/content-editor/topics/:id/edit",
    name: "Topic Form",
    component: TopicForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR"],
      label: "topic.headers.form",
      path: "/content-editor/media-list",
    },
  },
  {
    path: "/media-library",
    name: "MediaLibrary",
    component: MediaLibrary,
    meta: {
      authority: [
        "ROLE_CONTENT_EDITOR",
        "ROLE_TEACHER",
        "ROLE_TEACHER_CANDIDATE",
        "ROLE_STUDENT",
        "ROLE_STUDENT_CANDIDATE",
      ],
      label: "activity.headers.media_library",
    },
  },
  {
    path: "/content-editor/level-tests-list",
    name: "LevelTest List",
    component: LevelTestList,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "lesson.headers.test_list",
    },
  },
  {
    path: "/content-editor/level-tests/form/new",
    name: "LevelTest Create",
    component: LevelTestForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "levelTestRequest.headers.create-form",
    },
  },
  {
    path: "/content-editor/level-tests/:id",
    name: "LevelTest Detail",
    component: LevelTestDetail,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "levelTestRequest.headers.detail",
    },
  },
  {
    path: "/content-editor/level-tests/:id/edit",
    name: "LevelTest Form",
    component: LevelTestForm,
    meta: {
      authority: ["ROLE_CONTENT_EDITOR", "ROLE_TEACHER"],
      label: "levelTestRequest.headers.edit",
    },
  },
];

export default routes;
