<template>
  <evaluation-form
    v-model="evaluation"
    title="teacherSupervisorReview.headers.form"
    label="teacherSupervisorReview.prop.comments"
    :editable="canEdit"
    :loading="loading"
    @cancel="$emit('cancel')"
    @save="save"
  ></evaluation-form>
</template>
<script>
import auth from "@/common/auth";
import EvaluationForm from "@/mockups/admin/components/EvaluationForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const TeacherSupervisorReviewEntityRepository = RepositoryFactory.get(
  "TeacherSupervisorReviewEntityRepository"
);

export default {
  name: "TeacherSupervisorReviewForm",
  components: {
    EvaluationForm,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    lectureId: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      evaluation: { ...this.value } || {},
      loading: false,
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.evaluation.supervisorId = auth.getUser().roleId;
      this.evaluation.lectureId = this.lectureId;
      TeacherSupervisorReviewEntityRepository.save(this.evaluation)
        .then((evaluationSaved) => {
          this.$notify({
            title: !this.evaluation.id
              ? this.$t("teacherSupervisorReview.messages.create_success")
              : this.$t("teacherSupervisorReview.messages.update_success"),
            text: this.$t("account.notifications.changes_saved"),
            type: "success",
          });
          this.$emit("save", evaluationSaved);
        })
        .catch(() =>
          this.$log.debug(
            "Error saving teacher supervisor review: " +
              JSON.stringify(this.evaluation)
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
