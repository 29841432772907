<template>
  <div v-if="activityInfo">
    <H5PPlayer
      :exercise="activityInfo.activityH5PId"
      :onXApiStatement="processStatement"
    />
  </div>
</template>
<script>
import H5PPlayer from "@/mockups/content-cloud/activity/H5PPlayer";
import XApiStatementsManagement from "@/mockups/student/components/XApiStatementsManagement";
import { record } from "rrweb";
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "ClrmPlayH5PActivity",
  components: { H5PPlayer },
  data() {
    return {
      processStatement: null,
      activityInfo: null,
      events: [],
      stop: null,
      user: auth.getUser(),
    };
  },
  props: {
    zoom: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
    },
  },
  mounted() {
    this.setPlayer();
  },
  watch: {
    activity(newVal) {
      if (newVal != null) {
        this.setPlayer();
      } else {
        this.stopPlayer();
      }
    },
  },
  computed: {
    ...mapAuthGetter(["isAnyTeacher"]),
  },
  methods: {
    async setPlayer() {
      this.stopPlayer().then(() => {
        this.activityInfo = this.activity;
      });
      if (!this.isAnyTeacher) {
        this.processStatement =
          XApiStatementsManagement.processClassroomStatement.bind(
            this,
            this.activity.lectureActivityId
            // this.startActivityRecording,
            // this.stopRecorder
          );
      }
    },
    // startActivityRecording() {
    //   this.$log.debug("Starting activity recording");
    //   this.stop = record({
    //     emit(event) {
    //       XApiStatementsManagement.pushEvent(event);
    //     },
    //   });
    // },
    stopPlayer() {
      return new Promise((resolve) => {
        this.activityInfo = null;
        this.processStatement = null;
        //this.stopRecorder();
        resolve();
      });
    },
    // stopRecorder() {
    //   if (this.stop) {
    //     this.$log.debug("Stopping activity recording");
    //     this.stop();
    //   }
    //   XApiStatementsManagement.removeEvents();
    // },
  },
  // beforeDestroy() {
  //   this.stopRecorder();
  // },
};
</script>
