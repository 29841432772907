import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/filters";
import i18n from "./plugins/i18n";
import "./App.scss";
import "./custom.scss";
import App from "./App.vue";
import router from "./router";
import Notifications from "vue-notification";
import LoadScript from "vue-plugin-load-script";
import VueCryptojs from "vue-cryptojs";
import VueLogger from "vuejs-logger";
import properties from "./properties";
import IconBase from "@/components/icon-base/IconBase.vue";

// Component for all icons used in LEMSI
Vue.component("icon", IconBase);

Vue.use(Notifications);
Vue.use(LoadScript);
Vue.use(VueCryptojs);

Vue.config.productionTip = false;

properties.LOGGER_OPTIONS.logLevel =
  process.env.NODE_ENV === "production" ? "error" : "debug";
Vue.use(VueLogger, properties.LOGGER_OPTIONS);

Vue.$router = router;
Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
