<template>
  <v-select
    v-model="productType"
    :append-icon="append_icon"
    :clearable="clearable"
    :dense="dense"
    :items="productTypeItems"
    :item-text="(item) => (item.name ? $t(item.name) : '')"
    :label="label"
    :outlined="outlined"
    :rules="rules"
    @change="$emit('change', productType)"
  >
    <template v-slot:selection="{ item }">
      <span>{{ $t(`product.product_type.${item}`) }}</span>
    </template>

    <template v-slot:item="{ item }">
      <span>{{ $t(`product.product_type.${item}`) }}</span>
    </template>

    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-select>
</template>

<script>
import { productTypeConst } from "./productConst";

export default {
  name: "ProductTypeSelector",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("product.product_type.label");
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    append_icon: {
      type: String,
      required: false,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.productType = newValue;
      },
      immediate: true,
    },
    productType(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.productType = this.value;
  },
  data() {
    return {
      productTypeItems: productTypeConst,
      productType: "",
    };
  },
};
</script>

<style></style>
