<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-btn v-if="!showForm" @click="showForm = true" color="success ml-2">
          <v-icon>add</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
        </v-btn>
        <v-btn v-else @click="showForm = false" class="error ml-3">
          <v-icon>mdi-window-close</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("actions.close") }}
          </span>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <company-student-form
            v-if="showForm"
            :companyId="companyId"
            @saved="fetchData"
          >
          </company-student-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            class="data-table"
            :footer-props="tableFooterProps"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options="pagination"
            :server-items-length="totalItems"
            @update:options="onPaginationChange"
          >
            <template v-slot:[`item.state`]="{ item }">
              <v-icon color="success" v-if="item.confirmed"> mdi-check </v-icon>
              <v-icon color="error" v-else> mdi-close </v-icon>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4"
                    icon
                    color="red"
                    @click="showDeleteDialog(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("actions.delete") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="unlinkStudent"
    ></delete-dialog>
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import CompanyStudentForm from "@/mockups/company/components/CompanyStudentForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const CompanyStudentEntityRepository = RepositoryFactory.get(
  "CompanyStudentEntityRepository"
);

export default {
  name: "CompanyStudentListTab",
  components: { DeleteDialog, CompanyStudentForm },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      loading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      selected: null,
      deleteDialog: false,
      showForm: false,
      entity: {},
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "email",
        },
        {
          text: this.$t("account.user_confirmed"),
          value: "state",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      const sortMapping = {
        state: "student",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
          companyId: this.companyId,
        },
      };
      CompanyStudentEntityRepository.getAllByCompany(options)
        .then((res) => {
          this.items = res.content;
          this.totalItems = res.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching company students of company: ")
        )
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.fetchData();
      }
    },
    onPaginationChange(pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
    unlinkStudent() {
      this.loading = true;
      return CompanyStudentEntityRepository.delete(this.selected.id)
        .then(() => {
          if (this.pagination.page !== 1) {
            this.pagination.page = 1;
          } else {
            this.fetchData();
          }
        })
        .catch(() => this.$log.debug("Error deleting company student"))
        .finally(() => {
          this.selected = null;
          this.deleteDialog = false;
          this.deleteLoading = false;
          this.loading = false;
        });
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.deleteDialog = true;
    },
  },
};
</script>
