<template>
  <v-container fluid>
    <v-autocomplete
      v-model="userFilter"
      :label="$t('chat.search-users.users')"
      :items="filteredUsers"
      :item-text="fullName"
      item-value="userId"
      :search-input.sync="userSearch"
      multiple
      clearable
      outlined
      rounded
    ></v-autocomplete>
    <v-select
      v-model="chatTypeFilter"
      :label="$t('chat.chat_type.prop')"
      :items="types"
      :item-text="translate"
      clearable
      outlined
      rounded
    >
    </v-select>
    <v-select
      v-model="archivedFilter"
      :label="$t('chat.archive_status.prop')"
      :items="archiveStatus"
      :item-text="translate"
      outlined
      rounded
    >
    </v-select>
    <v-col class="text-center">
      <v-btn
        class="mt-4"
        @click="$emit('change', userFilter, chatTypeFilter, archivedFilter)"
        >{{ $t("actions.search") }}</v-btn
      >
    </v-col>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import auth from "@/common/auth";
import { getChannelTypeList } from "@/enumerates/ChannelType";
import { translate } from "@/common/translation-utils.js";
import ChatArchiveStatus from "@/enumerates/ChatArchiveStatus";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  props: {
    userFilterValue: {
      required: false,
      type: Array,
      default: () => [],
    },
    chatTypeFilterValue: {
      required: false,
      type: String,
      default: null,
    },
    archivedFilterValue: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUser: auth.getUser(),
      users: [],
      loading: false,
      userFilter: JSON.parse(JSON.stringify(this.userFilterValue)),
      types: [],
      chatTypeFilter: JSON.parse(JSON.stringify(this.chatTypeFilterValue)),
      userSearch: "",
      archivedFilter: JSON.parse(JSON.stringify(this.archivedFilterValue)),
      archiveStatus: ChatArchiveStatus,
    };
  },
  computed: {
    filteredUsers() {
      return this.userSearch
        ? this.users.filter((item) =>
            (item.name + item.surname)
              .replace(/\s+/g, "")
              .toUpperCase()
              .includes(this.userSearch.replace(/\s+/g, "").toUpperCase())
          )
        : this.users;
    },
  },
  created() {
    this.types = getChannelTypeList(this.currentUser.authorities);
    this.getUsers();
  },
  watch: {
    userFilterValue() {
      this.userFilter = JSON.parse(JSON.stringify(this.userFilterValue));
    },
    chatTypeFilterValue() {
      this.chatTypeFilter = JSON.parse(
        JSON.stringify(this.chatTypeFilterValue)
      );
    },
    archivedFilterValue() {
      this.archivedFilter = JSON.parse(
        JSON.stringify(this.archivedFilterValue)
      );
    },
  },
  methods: {
    getUsers() {
      this.loading = true;
      UserDataEntityRepository.getAllFromUserChats(this.currentUser.id)
        .then((response) => {
          this.users = response;
        })
        .finally(() => (this.loading = false));
    },
    fullName(item) {
      return item.name + " " + item.surname;
    },
    translate,
  },
};
</script>
