<template>
  <div class="mt-6 mb-8 pl-3 pr-3">
    <v-card max-width="800px" min-width="260px" class="ml-auto mr-auto">
      <v-card-title class="pb-0 pt-0 pt-md-4">
        <v-row align="center" no-gutters>
          <v-col cols="5" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm" ref="form">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                v-model="user.login"
                autocomplete="username"
                name="login"
                prepend-inner-icon="person"
                type="text"
                class="required"
                :maxlength="50"
                :disabled="isLoading"
                :label="$t('profile.fields.login')"
                :rules="[
                  (v) => !!v || $t('account.form.validation.username_required'),
                  (v) =>
                    !v ||
                    this.regex.LOGIN_REGEX.test(v) ||
                    $t('user_management.loginValid'),
                ]"
                @input="user.login = user.login.toLowerCase()"
              ></v-text-field>
              <v-text-field
                v-model="user.firstName"
                name="firstname"
                prepend-inner-icon="notes"
                type="text"
                class="required"
                :disabled="isLoading"
                :label="$t('profile.fields.name')"
                :rules="[
                  (v) =>
                    !!v || $t('account.form.validation.firstName_required'),
                ]"
              ></v-text-field>
              <v-text-field
                v-model="user.lastName"
                name="lastname"
                prepend-inner-icon="notes"
                type="text"
                class="required"
                :disabled="isLoading"
                :label="$t('profile.fields.surname')"
                :rules="[
                  (v) => !!v || $t('account.form.validation.lastName_required'),
                ]"
              ></v-text-field>
              <language-selector
                v-model="user.langKey"
                class="required"
                :label="$t('profile.fields.language')"
                :rules="[
                  (v) => !!v || $t('account.form.validation.language_required'),
                ]"
              ></language-selector>
              <v-text-field
                v-model="user.email"
                autocomplete="email"
                name="email"
                prepend-inner-icon="email"
                type="email"
                :disabled="isLoading"
                :label="$t('profile.fields.main_email')"
                class="required"
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                autocomplete="new-password"
                name="password"
                prepend-inner-icon="vpn_key"
                class="required"
                maxLength="100"
                :append-icon="hidePassword1 ? 'visibility' : 'visibility_off'"
                :disabled="isLoading"
                :label="$t('account.form.password')"
                :rules="[
                  (v) => !!v || $t('account.form.validation.password_required'),
                  (value) =>
                    value.length <= 100 ||
                    $t('account.form.validation.password_less_chars'),
                  (value) =>
                    value.length > 3 ||
                    this.$t('account.form.validation.password_more_chars'),
                ]"
                :type="hidePassword1 ? 'password' : 'text'"
                @click:append="() => (hidePassword1 = !hidePassword1)"
              ></v-text-field>
              <v-text-field
                v-model="user.password2"
                autocomplete="new-password"
                name="password2"
                prepend-inner-icon="vpn_key"
                class="required"
                :append-icon="hidePassword2 ? 'visibility' : 'visibility_off'"
                :disabled="isLoading"
                :label="$t('account.actions.repeat_password')"
                :rules="[
                  (v) => !!v || $t('account.form.validation.password_required'),
                  passwordConfirmationRule,
                ]"
                :type="hidePassword2 ? 'password' : 'text'"
                @click:append="() => (hidePassword2 = !hidePassword2)"
                @keydown.enter="register"
              ></v-text-field>
              <v-radio-group
                v-model="role"
                :rules="[
                  (v) => !!v || $t('account.form.validation.role_required'),
                ]"
              >
                <template v-slot:label class="required">
                  {{ $t("account.form.role") }}
                  <span style="color: red">*</span>
                </template>
                <v-radio
                  v-for="(option, i) in options"
                  :key="i"
                  :label="$t(option.key)"
                  :value="option.value"
                >
                </v-radio>
              </v-radio-group>
              <div class="text-center">
                <v-btn
                  class="primary mt-4"
                  :disabled="isLoading || !validForm"
                  :loading="isLoading"
                  @click="register"
                >
                  {{ $t("account.form.validation.register.name") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LanguageSelector from "@/components/debouncing-inputs/LanguageSelector.vue";
import regex from "@/common/regex-validation";
import { mapAuthGetter } from "@/common/mapAuthGetter";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  components: {
    LanguageSelector,
  },
  data() {
    return {
      options: [
        {
          key: "student.name",
          value: "STUDENT",
        },
        {
          key: "teacher.name",
          value: "TEACHER",
        },
      ],
      regex,
      validForm: true,
      hidePassword1: true,
      hidePassword2: true,
      loading: false,
      emailRules: [
        (v) => !!v || this.$t("account.form.validation.email_required"),
        (v) =>
          regex.EMAIL_REGEX.test(v) ||
          this.$t("account.form.validation.email_valid"),
      ],
      user: {
        login: "",
        firstName: "",
        email: "",
        lastName: "",
        password: "",
        password2: "",
        langKey: "",
      },
      role: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    passwordConfirmationRule() {
      return () =>
        this.user.password === this.user.password2 ||
        this.$t("account.form.validation.password_match");
    },
    isLoading() {
      return this.loading;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.$refs.form.resetValidation();
    },
  },
  created() {
    if (this.isLogged) {
      this.$notify({
        title: "Error",
        text: this.$t("account.errors.forbidden_access"),
        type: "error",
        duration: 30000,
      });
      this.$router.push({ name: "Unauthorized" });
    }
  },
  methods: {
    register() {
      if (this.validForm) {
        this.loading = true;
        AccountRepository.register(this.user, this.role)
          .then(() => {
            this.$notify({
              title: this.$t("account.notifications.registered"),
              text: this.$t("account.notifications.successfull_registered"),
              type: "success",
              duration: 30000,
            });
            this.$router.push({ name: "Login" });
          })
          .finally(() => (this.loading = false));
      } else {
        this.$notify({
          title: "Error",
          text: this.$t("account.form.validation.form_error"),
          type: "error",
          duration: 30000,
        });
      }
    },
  },
};
</script>
