<template>
  <v-container fluid>
    <v-alert
      v-if="
        isLogged &&
        (isAnyStudent || isCompany) &&
        (entity.state === 'APPROVED' || entity.state === 'REQUESTED') &&
        !loading
      "
      border="left"
      colored-border
      :color="handleVAlertColor()"
      elevation="2"
    >
      {{ $t(`productRequest.messages.${entity.state}`) }}
    </v-alert>
    <v-card v-if="!isLoading && entity">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.back") }} </span>
            </v-btn>

            <v-btn
              class="warning ml-2"
              v-if="isLogged && isAdmin && isEditableProduct"
              @click="edit(entity)"
            >
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.edit") }} </span>
            </v-btn>

            <v-btn
              class="error ml-2"
              v-if="isLogged && isAdmin && isEditableProduct"
              @click="dialog = true"
            >
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.delete") }}
              </span>
            </v-btn>

            <v-btn
              v-if="isLogged && (isAnyStudent || isCompany)"
              :disabled="cannotCreateProductRequest"
              class="primary ml-2"
              @click="showProductRequestDialog"
            >
              <v-icon class="mr-2">school</v-icon>
              <span class="d-sm-block">
                {{ $t("productRequest.enroll-button") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row align="end">
          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.title") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.title }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.description") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.description }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.assistance_type.label") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ $t(`product.assistance_type.${entity.assistanceType}`) }}
          </v-col>

          <template v-if="isLogged && isAdmin">
            <v-col cols="4" md="2" class="text-left font-weight-bold">
              {{ $t("product.recipient_type.label") }}:
            </v-col>
            <v-col cols="8" md="4">
              {{ $t(`product.recipient_type.${entity.recipientType}`) }}
            </v-col>
          </template>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{
              isAdmin
                ? this.$t("product.prop.productType")
                : this.$t("product.prop.courseType")
            }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ $t(`product.product_type.${entity.productType}`) }}
          </v-col>
          <template v-if="isAdmin">
            <v-col cols="4" md="2" class="text-left font-weight-bold">
              {{ $t("product.prop.status") }}:
            </v-col>
            <v-col cols="8" md="4">
              {{ $t(`product.product_status.${entity.productStatus}`) }}
            </v-col>
          </template>
          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.course") }}:
          </v-col>
          <v-col cols="8" md="4">
            <router-link
              :to="{
                name: 'Course Simple Detail',
                params: { id: entity.course.id, backPrevious: true },
              }"
              v-if="entity.course"
            >
              <span>
                {{ entity.course.title + ", " + entity.course.description }}
              </span>
            </router-link>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.level") }}:
          </v-col>
          <v-col cols="8" md="4">
            <v-chip class="mr-1" v-if="entity.level" color="primary">
              {{ entity.level.name }}
            </v-chip>
            <v-chip
              v-if="entity.level && entity.level.language"
              color="warning"
            >
              {{ $t(`languages.${entity.level.language.name}`) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2" light></v-divider>
        <v-row>
          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.start_date") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span v-if="entity.startDate">
              {{ entity.startDate | dateTimeWithoutTz }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.end_date") }}:
          </v-col>
          <v-col cols="8" md="4">
            <span v-if="entity.endDate">
              {{ entity.endDate | dateTimeWithoutTz }}
            </span>
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.min_students") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.minStudents }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.max_students") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.maxStudents }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.class_duration") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.classDuration }}
          </v-col>

          <v-col cols="4" md="2" class="text-left font-weight-bold">
            {{ $t("product.prop.classes_per_week") }}:
          </v-col>
          <v-col cols="8" md="4">
            {{ entity.classesPerWeek }}
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2" light></v-divider>
        <v-row>
          <v-col cols="12">
            <h2>{{ $t("product.prop.tariffs") }}</h2>
          </v-col>
          <v-col cols="12" v-if="entity.tariffs && entity.tariffs.length === 1">
            <v-row>
              <v-col cols="4" md="2" class="text-left font-weight-bold">
                {{ $t("product.prop.price") }}:
              </v-col>
              <v-col cols="8" md="4">
                {{ $n(entity.tariffs[0].price, "currency") }}
              </v-col>

              <v-col cols="4" md="2" class="text-left font-weight-bold">
                {{ $t("product.payment_type.label") }}:
              </v-col>
              <v-col cols="8" md="4">
                {{
                  $t(`product.payment_type.${entity.tariffs[0].paymentType}`)
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="entity.tariffs && entity.tariffs.length !== 1">
            <v-data-table
              :items="entity.tariffs"
              :headers="tariffHeaders"
              :options="{ sortBy: ['minStudents'] }"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.price`]="{ item }">
                <span v-if="item.price">
                  {{ $n(item.price, "currency") }}
                </span>
              </template>
              <template v-slot:[`item.paymentType`]="{ item }">
                <span v-if="item.paymentType">
                  {{ $t(`product.payment_type.${item.paymentType}`) }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>

    <delete-dialog
      :dialog="dialog"
      :loading="loading"
      @cancel="dialog = false"
      @submit="deleteEntity"
    ></delete-dialog>

    <!-- Dialogo para la petición de un curso -->
    <product-request-dialog
      v-if="productRequestDialog"
      :dialog="productRequestDialog"
      :selectedProduct="entity"
      @cancel="cancelProductRequestDialog"
      @submit="saveProductRequest"
    ></product-request-dialog>

    <!-- Dialogo para la petición de un curso como empresa -->
    <company-product-request-dialog
      v-if="companyProductRequestDialog"
      :dialog="companyProductRequestDialog"
      :loading="loadingRequest"
      :selectedProduct="entity"
      @cancel="cancelCompanyProductRequestDialog"
      @submit="saveCompanyProductRequest"
    ></company-product-request-dialog>

    <modal-dialog
      v-if="errorDialog"
      :content="
        $t('product.error.low_balance.detailed', { value: neededAmount })
      "
      :dialog="errorDialog"
      :cancelText="$t('actions.close')"
      :title="$t('product.error.low_balance.title')"
      titleClass="warning white--text"
      titleIcon="mdi-alert"
      @cancel="errorDialog = false"
    ></modal-dialog>
  </v-container>
</template>

<script>
import ProductRequestDialog from "@/mockups/economy/products/_components/ProductRequestDialog.vue";
import checkInvalidID from "@/common/checkInvalidID";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import ModalDialog from "@/components/modal_dialog/ModalDialog";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import auth from "@/common/auth";
import CompanyProductRequestDialog from "@/mockups/economy/products/_components/CompanyProductRequestDialog";
import { timeSlotToUTCTimeSlot } from "@/common/conversion-utils";

const ProductEntityRepository = RepositoryFactory.get(
  "ProductEntityRepository"
);

const ProductRequestEntityRepository = RepositoryFactory.get(
  "ProductRequestEntityRepository"
);

export default {
  name: "ProductDetail",
  components: {
    LoadingPage,
    DeleteDialog,
    ProductRequestDialog,
    ModalDialog,
    CompanyProductRequestDialog,
  },
  data() {
    return {
      loading: false,
      loadingRequest: false,
      entity: {},
      dialog: false,
      productRequestDialog: false,
      companyProductRequestDialog: false,
      user: auth.getUser(),
      errorDialog: false,
      neededAmount: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged", "isAdmin", "isAnyStudent", "isCompany"]),
    isLoading() {
      return this.loading;
    },
    tariffHeaders() {
      return [
        {
          text: this.$t("product.prop.min_students"),
          value: "minStudents",
        },
        { text: this.$t("product.prop.max_students"), value: "maxStudents" },
        { text: this.$t("product.prop.price"), value: "price" },
        { text: this.$t("product.payment_type.label"), value: "paymentType" },
      ];
    },
    isEditableProduct() {
      return (
        this.entity.productStatus === "DRAFT" ||
        this.entity.productStatus === "DISABLED"
      );
    },
    cannotCreateProductRequest() {
      if (this.isAnyStudent && this.entity.recipientType === "COMPANY") {
        return true;
      }
      if (this.isCompany && this.entity.recipientType === "GENERAL") {
        return true;
      }
      return (
        this.entity &&
        (this.entity.state === "APPROVED" || this.entity.state === "REQUESTED")
      );
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return ProductEntityRepository.get(id)
        .then((res) => (this.entity = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "Product List",
        params: { backAction: true },
      });
    },
    edit() {
      this.$router.push({
        name: "Edit Product",
        params: { id: this.entity.id, backPrevious: true },
      });
    },
    deleteEntity() {
      this.loading = true;
      return ProductEntityRepository.delete(this.entity.id)
        .then(() => {
          this.dialog = false;
          this.$router.push({ name: "Admin Product List" });
        })
        .catch(() =>
          this.$log.debug("Error deleting product with ID " + this.entity.id)
        )
        .finally(() => (this.loading = false));
    },
    showProductRequestDialog() {
      if (this.entity.recipientType === "COMPANY") {
        this.companyProductRequestDialog = true;
      } else {
        this.productRequestDialog = true;
      }
    },
    cancelProductRequestDialog() {
      this.productRequestDialog = false;
    },
    cancelCompanyProductRequestDialog() {
      this.companyProductRequestDialog = false;
    },
    async saveProductRequest(productRequest) {
      await this._saveProductRequest(productRequest);
      this._fetchData(this.entity.id);
      this.cancelProductRequestDialog();
    },
    async saveCompanyProductRequest(productRequest) {
      productRequest.schedules = productRequest.schedules.map((el) =>
        timeSlotToUTCTimeSlot(el, auth.getUser().timeZone)
      );
      await this._saveProductRequest(productRequest, "COMPANY");
      this._fetchData(this.entity.id);
      this.cancelCompanyProductRequestDialog();
    },
    _saveProductRequest(productRequest, type = "GENERAL") {
      let saveMethod = ProductRequestEntityRepository.save;
      if (type === "COMPANY") {
        saveMethod = ProductRequestEntityRepository.saveForCompany;
      }
      productRequest.productId = this.entity.id;
      productRequest.userId = auth.getUser().id;
      this.loadingRequest = true;
      return saveMethod(productRequest)
        .then(() => {
          this.$notify({
            title: this.$t("productRequest.messages.create-success"),
            type: "success",
          });
        })
        .catch((e) => {
          this.$log.debug("Could not create product request");
          if (
            e.response.headers["x-app-error"] ===
            "product.error.low_balance.notify"
          ) {
            const error = JSON.parse(e.response.headers["x-app-params"]).param;
            if (error != null) {
              this.student = error.substring(
                error.indexOf("of ") + 3,
                error.indexOf(" is not")
              );
              this.neededAmount = this.$n(
                error.substring(
                  error.indexOf(". ") + 2,
                  error.indexOf(" needed")
                ),
                "currency"
              );
              this.errorDialog = true;
            }
          }
        })
        .finally(() => (this.loadingRequest = false));
    },
    handleVAlertColor() {
      return this.entity && this.entity.state === "APPROVED"
        ? "success"
        : this.entity.state === "REQUESTED" && "warning";
    },
  },
};
</script>
