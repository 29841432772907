import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/levelTestRequests";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getCommonAvailabilities(teacherId, studentId, startDate, endDate) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/findCommonAvailabilities`,
      {
        params: {
          teacher: teacherId,
          student: studentId,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return response.data;
  },

  async getByStudentId(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/students/${id}`)).data;
  },

  async save(entity) {
    if (entity.id) {
      return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
    } else {
      return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
    }
  },
};
