<template>
  <v-container v-if="items">
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col order="1" order-md="2" class="text-right">
            <v-btn color="success ml-2" :to="{ name: 'Topic Create' }">
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="3">
            <debounced-text-field
              append-icon="search"
              class="d-md-inline-block"
              dense
              hide-details
              v-model="nameFilter"
              :label="$t('search')"
            ></debounced-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <language-selector
              dense
              clearable
              outlined
              no-filter
              :debouncing="300"
              v-model="languageFilter"
              :label="$t('topic.prop.language')"
            >
            </language-selector>
          </v-col>

          <v-col cols="12" md="3">
            <topic-type-selector
              dense
              clearable
              outlined
              no-filter
              :debouncing="300"
              v-model="typeFilter"
              :label="$t('topic.prop.type')"
            ></topic-type-selector>
          </v-col>
        </v-row>
        <v-data-table
          class="rows-clickable"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          @click:row="entityDetail"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.language.name`]="{ item }">
            <span v-if="item.language">
              {{ $t(`languages.${item.language.name}`) }}
            </span>
          </template>

          <template v-slot:[`item.topicType.name`]="{ item }">
            <span v-if="item.topicType">
              {{ $t(`topicType.types.${item.topicType.name}`) }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click.stop="entityDetail(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  description
                </v-icon>
              </template>
              <span>{{ $t("actions.detail") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="warning"
                  @click.stop="editEntity(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  edit
                </v-icon>
              </template>
              <span>{{ $t("actions.edit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  @click.stop="showDeleteDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("actions.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-dialog
      :dialog="dialog"
      :loading="deleteLoading"
      @cancel="dialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import LanguageSelector from "@/components/selectors/LanguageSelector";
import TopicTypeSelector from "@/components/selectors/TopicTypeSelector";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");

export default {
  name: "TopicList",
  components: {
    DeleteDialog,
    LanguageSelector,
    TopicTypeSelector,
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      dialog: false,
      selected: null,
      nameFilter: this.$route.query.nameFilter,
      languageFilter: null,
      typeFilter: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort) || ["name"],
        sortDesc: parseStringToSortDesc(this.$route.query.sort) || [false],
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("topic.prop.name"),
          value: "name",
        },
        {
          text: this.$t("topic.prop.language"),
          value: "language.name",
        },
        {
          text: this.$t("topic.prop.type"),
          value: "topicType.name",
        },
        { text: this.$t("actions.actions"), sortable: false, value: "action" },
      ];
    },
    filters() {
      let filters = "";
      filters =
        filters +
        (this.nameFilter != null && this.nameFilter !== ""
          ? "name:" + this.nameFilter.toString() + ","
          : "");
      filters =
        filters +
        (this.languageFilter
          ? "language.id:" + this.languageFilter.id + ","
          : "");
      filters =
        filters +
        (this.typeFilter ? "type.id:" + this.typeFilter.id + "," : "");
      return filters !== "" ? filters : null;
    },
  },
  watch: {
    filters() {
      this.redirectOnFilterChange();
    },
  },
  created() {
    if (this.$route.query.languageFilter) {
      let value = parseFloat(this.$route.query.languageFilter);
      this.languageFilter = isNaN(value) ? null : { id: value };
    }
    if (this.$route.query.typeFilter) {
      let value = parseFloat(this.$route.query.typeFilter);
      this.typeFilter = isNaN(value) ? null : { id: value };
    }
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const sortMapping = {
        "topicType.name": "type.name",
      };
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          filters: this.filters,
          sort: generateSort(this.entitiesPage, sortMapping),
          size: this.entitiesPage.itemsPerPage,
        },
      };
      TopicEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching topics"))
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: "Topic List",
          query: query,
        });
        this.getItems();
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Topic Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Topic Form",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();

      query.sort = generateSort(this.entitiesPage);

      query.nameFilter = this.nameFilter != null ? this.nameFilter : undefined;
      query.languageFilter = this.languageFilter
        ? this.languageFilter.id.toString()
        : undefined;
      query.typeFilter = this.typeFilter
        ? this.typeFilter.id.toString()
        : undefined;

      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.dialog = false;
      this.deleteLoading = false;
    },
    deleteEntity() {
      this.deleteLoading = true;
      TopicEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
  },
};
</script>
