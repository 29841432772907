<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            {{ $t("edition.add_student_dialog.title") }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            {{ $t("edition.add_student_dialog.max", { value: maxStudents }) }}
          </v-col>
        </v-row>
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
          disable-pagination
          hide-default-footer
          v-model="selectedRows"
          show-select
        >
          <template v-slot:[`item.expiration`]="{ item }">
            {{ item.expiration | dateTimeWithoutTz }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto mr-auto success"
          :disabled="!validStudents"
          :loading="loadingAdd"
          @click="addStudents"
        >
          <v-icon>mdi-plus</v-icon>
          <span class="d-none d-sm-block"> {{ $t("actions.add") }} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const WalletEntityRepository = RepositoryFactory.get("WalletEntityRepository");
const RegistrationEntityRepository = RepositoryFactory.get(
  "RegistrationEntityRepository"
);

export default {
  name: "AddStudentDialog",
  props: {
    editionId: {
      type: Number,
      required: true,
    },
    maxStudents: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      loadingAdd: false,
      dialog: false,
      selectedRows: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("wallet.prop.displayName"),
          value: "displayName",
        },
        {
          text: this.$t("wallet.prop.firstName"),
          value: "firstName",
        },
        {
          text: this.$t("wallet.prop.lastName"),
          value: "lastName",
        },
        {
          text: this.$t("wallet.prop.balance"),
          value: "balance",
        },
        {
          text: this.$t("wallet.prop.expiration"),
          value: "expiration",
        },
      ];
    },
    validStudents() {
      return (
        this.selectedRows.length > 0 &&
        this.selectedRows.length <= this.maxStudents
      );
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
      if (val) {
        this._fetchData();
      } else {
        this.items = [];
      }
    },
  },
  methods: {
    _fetchData() {
      this.loading = true;
      WalletEntityRepository.getAvailableStudents(this.editionId)
        .then((data) => (this.items = data))
        .finally(() => (this.loading = false));
    },
    addStudents() {
      this.loadingAdd = true;
      const studentIds = this.selectedRows.map((s) => s.studentId);
      RegistrationEntityRepository.addStudentsToEdition(
        this.editionId,
        studentIds
      )
        .then(() => {
          this.$emit("added");
          this.$notify({
            title: this.$t("edition.add_student_dialog.success.title"),
            text: this.$t("edition.add_student_dialog.success.text"),
            type: "success",
          });
        })
        .finally(() => {
          this.loadingAdd = false;
          this.dialog = false;
        });
    },
  },
};
</script>
