<template>
  <footer class="clrm-footer" :class="'clrm-footer--' + classroomMode">
    <div class="clrm-container-fluid">
      <p>&copy; {{ year }}, Instituto Interculturas</p>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    classroomMode: {
      type: String,
      default: "teacher",
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
