<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-block d-lg-none">
        <v-btn tile plain @click="navDrawer = !navDrawer">
          <v-icon> mdi-tab </v-icon>
          <span class="ml-2">Apartados</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <navigation-drawer
        class="d-block d-lg-none"
        :absolute="true"
        :temporary="true"
        :items="navBarItems"
        v-model="navDrawer"
      >
      </navigation-drawer>
      <v-col class="d-none d-lg-block" md="auto">
        <div>
          <div v-if="!isMe">
            <h3 class="mb-4 pl-2">
              {{ entity.name }} {{ entity.surname }}
              <v-btn
                v-if="chatId"
                class="mb-4"
                color="accent"
                icon
                tile
                @click.stop="$EventBus.$emit('open-chat', chatId)"
              >
                <icon
                  name="chat-simple"
                  width="28"
                  height="28"
                  title="chat"
                ></icon>
              </v-btn>
            </h3>
          </div>
          <navigation-drawer :items="navBarItems" stateless :value="true">
          </navigation-drawer>
        </div>
      </v-col>
      <v-col>
        <v-row v-if="isAdmin && isCandidate">
          <approve-alert
            :alert-message="$t('teacher.messages.alert_message')"
            :approved-message="$t('teacher.messages.alert_approved')"
            :rejected-message="$t('teacher.messages.alert_rejected')"
            :status="alertStatus"
            :loading="loading"
            @approve="approve()"
          ></approve-alert>
        </v-row>
        <v-row>
          <router-view
            v-if="teacherId || $route.params.id"
            :teacherId="
              $route.params.id
                ? parseInt($route.params.id)
                : parseInt(teacherId)
            "
          ></router-view>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApproveAlert from "@/components/approve-alert/ApproveAlert";
import NavigationDrawer from "@/components/navigation-drawer/NavigationDrawer";
import { navBarItemsAdmin, navBarItemsProfile } from "./tabs/navBarItems";
import { isMe, mapAuthGetter } from "@/common/mapAuthGetter";
import { setShowed } from "@/common/navigationDrawer-utils";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import checkInvalidID from "@/common/checkInvalidID";
import ChatRepository from "@/repositories/components/ChatRepository";

const TeacherEntityRepository = RepositoryFactory.get(
  "TeacherEntityRepository"
);

export default {
  name: "TeacherProfile",
  components: {
    NavigationDrawer,
    ApproveAlert,
  },
  data() {
    return {
      navDrawer: false,
      entity: {},
      navBarItems: this.$route.params.id
        ? navBarItemsAdmin
        : navBarItemsProfile,
      loading: false,
      alertStatus: "PENDING",
      teacherId: null,
      userId: null,
      chatId: null,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin"]),
    isMe() {
      return isMe(this.entity.login);
    },
    isCandidate() {
      return this.isRole("ROLE_TEACHER_CANDIDATE");
    },
    isHeadmaster() {
      return (
        this.isRole("ROLE_HEADMASTER") ||
        (this.isAdmin && this.entity.headmaster)
      );
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      TeacherEntityRepository.getByUser()
        .then((res) => {
          this.entity = res;
          this.teacherId = res.id;
          this.userId = res.userId;
          this.setHeadmasterNavbarView();
        })
        .catch((err) => {
          this.$log.debug("Error fetching teacher for current user");
          checkInvalidID(err);
        });
    } else {
      this._fetchData(this.$route.params.id);
    }
  },
  methods: {
    _fetchData(id) {
      return TeacherEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          this.userId = response.userId;
          this.setHeadmasterNavbarView();
          if (this.isAdmin) {
            this.getUserAdminsChat();
          }
        })
        .catch((err) => {
          this.$log.debug("Error fetching teachers with ID " + id);
          checkInvalidID(err);
        });
    },
    getUserAdminsChat() {
      const options = {
        params: {
          userIds: this.userId,
          type: "USER_ADMINS",
        },
      };
      ChatRepository.getChannelsByUserAndType(options)
        .then((response) => {
          if (response.length > 0) {
            this.chatId = response[0].id;
          }
        })
        .catch(() => {
          this.$log.debug(
            "Error getting USER_ADMINS chat for user with id " + this.userId
          );
        });
    },
    isRole(role) {
      return this.entity.authorities
        ? this.entity.authorities.indexOf(role) !== -1
        : false;
    },
    /**
     * Checks if the user has headmaster role
     * if it is headmaster changed the navbar property
     * viewed to true
     */
    setHeadmasterNavbarView() {
      this.$set(
        this,
        "navBarItems",
        setShowed(
          this.navBarItems,
          "teacher.navbar.supervising",
          this.isHeadmaster
        )
      );
    },
    approve() {
      this.loading = true;
      TeacherEntityRepository.approve(this.$route.params.id)
        .then(() => {
          this.alertStatus = "APPROVED";
        })
        .catch(() =>
          this.$log.debug(
            "Error approving teacher with ID: " + this.$route.params.id
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
