import store from "@/common/store";

const notifications = () => {
  return store.state.chat.notifications;
};

const newMessage = () => {
  return store.state.chat.newMessage;
};

export function mapNotificationsGetter(options) {
  const object = {};
  options.forEach((option) => {
    object[option] = eval(option);
  });
  return object;
}

export function getChat() {
  return store.state.chat;
}
