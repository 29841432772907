var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activity)?_c('div',[_c('v-row',{staticClass:"activity-row",attrs:{"id":_vm.id,"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.activity.activity)?_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("activity.prop.title") + ": ")+" ")]),_c('span',[_vm._v(_vm._s(_vm.activity.activity.title))])]):_vm._e(),(_vm.activity.activity)?_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("activity.prop.description"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.activity.activity.description))])]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-col',{staticClass:"text-right"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[(!_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.activity.order > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.updateOrder(
                        _vm.activity,
                        _vm.activity.order - 1,
                        _vm.activity.order - 1
                      )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-up")])],1)]}}],null,false,1924318447)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.order-up"))+" ")])]):_vm._e(),(_vm.activity.order < _vm.maxOrder)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.updateOrder(
                        _vm.activity,
                        _vm.activity.order + 1,
                        _vm.activity.order - 1
                      )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,486487998)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("activity.actions.order-down"))+" ")])]):_vm._e()],1):_c('v-row',{staticClass:"d-inline-flex mr-1",attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"21"}})],1)],1),(_vm.activity.activity.content != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.previewActivity(_vm.activity.activity)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,1403376414)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.preview")))])]):_vm._e(),(
              _vm.activity.activity.content != null && _vm.activity.activity.typeH5p
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","href":'/h5p/download/' + _vm.activity.activity.content.fileName}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("download")])],1)]}}],null,false,3130490511)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download")))])]):_vm._e(),(_vm.activity.activity.hasContent && !_vm.activity.activity.typeH5p)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadActivity()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("download")])],1)]}}],null,false,514446273)},[_c('span',[_vm._v(_vm._s(_vm.$t("activity.actions.download")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteActivity()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,786754165)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])],1)],1)],1),(_vm.activity.activity.thumbnail != null)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-img',{attrs:{"src":_vm.thumbnail}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"50%"},on:{"click:outside":_vm.closePreview},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[(_vm.preview)?_c('activity-preview',{attrs:{"activity":_vm.preview},on:{"cancel":_vm.closePreview}}):_vm._e()],1),(_vm.pdfDialog)?_c('v-dialog',{attrs:{"width":"80%"},on:{"click:outside":_vm.closePreview},model:{value:(_vm.pdfDialog),callback:function ($$v) {_vm.pdfDialog=$$v},expression:"pdfDialog"}},[_c('PDFViewer',{attrs:{"exercise":_vm.preview.id,"homework":false},on:{"closeDialog":function($event){return _vm.closePreview()}}})],1):_vm._e(),_c('a',{ref:"hiddenDownloader",staticClass:"d-none"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }