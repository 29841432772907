<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-md-block">
        <span class="headline no-split-words">
          {{ $t($route.meta.label) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :options.sync="pagination"
          :server-items-length="totalItems"
        >
          <template v-slot:[`item.teacherName`]="{ item }">
            <router-link
              :to="{
                name: 'TeacherReferencesTab',
                params: { id: item.teacherId },
              }"
              >{{ item.teacherName }}</router-link
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top v-if="!loadingRow[item.id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  @click="requestTeacherReference(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">send</v-icon></v-btn
                >
              </template>
              <span>{{ $t("actions.send") }}</span>
            </v-tooltip>
            <loading-spinner v-else></loading-spinner>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";

const ReferenceEntityRepository = RepositoryFactory.get(
  "ReferenceEntityRepository"
);

export default {
  name: "PreliminaryTeacherReferenceList",
  components: { LoadingSpinner },
  data() {
    return {
      items: [],
      isLoading: false,
      totalItems: 0,
      pagination: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
      loadingRow: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("teacher.name"),
          value: "teacherName",
        },
        {
          text: this.$t("reference.prop.fullName"),
          value: "fullName",
        },
        {
          text: this.$t("reference.prop.positionTitle"),
          value: "positionTitle",
        },
        {
          text: this.$t("reference.prop.companyName"),
          value: "companyName",
        },
        {
          text: this.$t("reference.prop.email"),
          value: "email",
        },
        {
          text: this.$t("reference.prop.phone"),
          value: "phone",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.onPaginationChange();
      },
      deep: true,
    },
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const sortMapping = {
        teacherName: "teacher.userData.user.firstName",
      };
      const options = {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: generateSort(this.pagination, sortMapping),
        },
      };
      ReferenceEntityRepository.getAllPreliminary(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug(
            "Error fetching preliminary teacher references with params: " +
              options
          )
        )
        .finally(() => (this.isLoading = false));
    },
    requestTeacherReference(item) {
      this.$set(this.loadingRow, item.id, true);
      ReferenceEntityRepository.requestTeacherReference(item)
        .then((res) => (item.status = res.status))
        .catch(() => this.$log.debug("Error requesting teacher reference"))
        .finally(() => {
          this.fetchData();
          this.$set(this.loadingRow, item.id, false);
        });
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
      }
      this.fetchData();
    },
    onPaginationChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.sort = generateSort(this.pagination);
      this.redirect(query);
    },
  },
};
</script>
