var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"order":"1","order-md":"2"}},[_c('v-btn',{attrs:{"color":"success ml-2","to":{ name: 'Wordlist Create', params: { backPrevious: true } }}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("actions.new"))+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.onSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('LanguageSelector',{attrs:{"dense":"","clearable":!_vm.levelFilter,"outlined":"","debouncing":300,"label":_vm.$t('activity.prop.language'),"show-chips":""},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLanguageChange' ].concat( argsArray ))}},model:{value:(_vm.languageFilter),callback:function ($$v) {_vm.languageFilter=$$v},expression:"languageFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('LevelSelector',{attrs:{"dense":"","clearable":"","outlined":"","debouncing":300,"label":_vm.$t('activity.prop.level'),"language-filter":_vm.languageFilter ? _vm.languageFilter.id : null,"show-language":false},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectorChange.apply(void 0, [ 'onLevelChange' ].concat( argsArray ))}},model:{value:(_vm.levelFilter),callback:function ($$v) {_vm.levelFilter=$$v},expression:"levelFilter"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"rows-clickable elevation-1",attrs:{"footer-props":_vm.tableFooterProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"click:row":_vm.entityDetail,"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.language",fn:function(ref){
var item = ref.item;
return [(item.level.language)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("languages." + item.level.language.name)))+" ")]):_vm._e()]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level)?_c('v-chip',{attrs:{"color":_vm.getChipBackColor(item.level.language),"text-color":_vm.getChipTextColor(item.level.language)}},[_vm._v(" "+_vm._s(item.level.name)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.entityDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("description")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.detail")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.startDeleteAction(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.delete")))])])]}}],null,true)})],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"loading":_vm.deleteLoading},on:{"cancel":_vm.stopDeleteAction,"submit":_vm.deleteWordlist}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }