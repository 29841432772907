import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/teacherLevels";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllPending(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/pending`, options);
    return response.data;
  },

  async saveLevelRequest(levelId, teacherId) {
    return (
      await HTTP.post(`${RESOURCE_NAME}/levelRequest/${levelId}/${teacherId}`)
    ).data;
  },

  async confirmLevelRequest(id) {
    return (await HTTP.patch(`${RESOURCE_NAME}/${id}/confirm`)).data;
  },

  async rejectLevelRequest(id) {
    return (await HTTP.patch(`${RESOURCE_NAME}/${id}/reject`)).data;
  },
};
