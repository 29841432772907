import i18n from "@/plugins/i18n";

function translate(item) {
  return item ? i18n.t(item.text) : "";
}

function translateWithLocale(locale, item, property) {
  return item ? i18n.t(locale + "." + item[property]) : "";
}
export { translate, translateWithLocale };
