var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 py-0"},[_c('v-row',{attrs:{"row":"","no-gutters":""}},[(_vm.datePicker)?_c('v-col',{staticClass:"date-input"},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"my-0 py-0",attrs:{"prepend-icon":"event","label":_vm.datePicker.label ? _vm.$t(_vm.datePicker.label) : _vm.$t('calendar.date'),"hint":_vm.$t('calendar.dateFormat'),"error-messages":_vm.datePickerError || _vm._validateDate(),"persistent-hint":"","readonly":"","clearable":_vm.datePicker.clearable != null ? _vm.datePicker.clearable : true,"disabled":_vm.disabled},on:{"click:clear":_vm._clearDatePicker},model:{value:(_vm.datePicker.innerDateFormatted),callback:function ($$v) {_vm.$set(_vm.datePicker, "innerDateFormatted", $$v)},expression:"datePicker.innerDateFormatted"}},on))]}}],null,false,2568472595),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":_vm.localeRoot,"first-day-of-week":_vm.$t('datePicker.firstDayOfTheWeek'),"nextMonthAriaLabel":_vm.$t('datePicker.nextMonthAriaLabel'),"prevMonthAriaLabel":_vm.$t('datePicker.prevMonthAriaLabel'),"nextYearAriaLabel":_vm.$t('datePicker.nextYearAriaLabel'),"prevYearAriaLabel":_vm.$t('datePicker.prevYearAriaLabel'),"min":_vm.datePicker.minDate,"max":_vm.datePicker.maxDate},on:{"change":_vm._changed,"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.datePicker.innerData),callback:function ($$v) {_vm.$set(_vm.datePicker, "innerData", $$v)},expression:"datePicker.innerData"}})],1)],1):_vm._e(),(_vm.timePicker)?_c('v-col',[_c('v-text-field',{staticClass:"my-0 mr-4 py-0",staticStyle:{"max-width":"150px"},attrs:{"prepend-icon":"access_time","label":_vm.timePicker.label ? _vm.$t(_vm.timePicker.label) : _vm.$t('calendar.initTime'),"hint":_vm.$t('calendar.timeFormat'),"error-messages":_vm.timePickerError || _vm._validateTimePicker(),"disabled":_vm.isFullDay || _vm.disabled,"type":"time","persistent-hint":""},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm._saveTime.apply(void 0, [ _vm.timePicker ].concat( argsArray ))}},model:{value:(_vm.timePicker.innerData),callback:function ($$v) {_vm.$set(_vm.timePicker, "innerData", $$v)},expression:"timePicker.innerData"}})],1):_vm._e(),(_vm.additionalTimePicker)?_c('v-col',[_c('v-text-field',{staticClass:"my-0 py-0",staticStyle:{"max-width":"150px"},attrs:{"prepend-icon":"access_time","label":_vm.additionalTimePicker.label
            ? _vm.$t(_vm.additionalTimePicker.label)
            : _vm.$t('calendar.endTime'),"hint":_vm.$t('calendar.timeFormat'),"error-messages":_vm.additionalTimePickerError || _vm._validateAdditionalTimePicker(),"type":"time","disabled":_vm.disabled,"persistent-hint":""},on:{"input":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm._saveTime.apply(void 0, [ _vm.additionalTimePicker ].concat( argsArray ))}},model:{value:(_vm.additionalTimePicker.innerData),callback:function ($$v) {_vm.$set(_vm.additionalTimePicker, "innerData", $$v)},expression:"additionalTimePicker.innerData"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }