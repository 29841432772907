<template>
  <div class="language-selector">
    <v-select
      :outlined="outlined"
      :dense="dense"
      :items="languages"
      :label="label"
      :rules="rules"
      :loading="loading"
      item-value="value"
      v-model="langSelected"
    >
      <template v-slot:selection="data">
        <v-row no-gutters>
          <v-list-item-icon class="selectable-lang">
            <img
              v-if="data.item.flag"
              :src="data.item.flag"
              width="35"
              height="20"
            />
            <span v-else class="material-icons"> language </span>
          </v-list-item-icon>
          <v-list-item-content class="selectable-lang">
            <v-list-item-title v-html="data.item.title"></v-list-item-title>
          </v-list-item-content>
        </v-row>
      </template>
      <template v-slot:item="data">
        <v-list-item-icon>
          <img
            v-if="data.item.flag"
            :src="data.item.flag"
            width="35"
            height="20"
          />
          <span v-else class="material-icons"> language </span>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.title"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const LanguageRepository = RepositoryFactory.get("LanguageEntityRepository");
export default {
  props: {
    value: {
      type: Number,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.langSelected = newVal;
      },
    },
    langSelected(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this._fetchData();
  },
  data() {
    return {
      loading: false,
      languages: [
        {
          flag: null,
          title: this.$t("languages.all"),
          value: null,
        },
      ],
      langSelected: this.value || {
        flag: null,
        title: this.$t("languages.all"),
        value: null,
      },
    };
  },
  methods: {
    _fetchData() {
      this.loading = true;
      LanguageRepository.getAll()
        .then((data) => {
          data.forEach((item) =>
            this.languages.push({
              flag: require("@/assets/flag_icons/" +
                this.getCountryCode(item.id) +
                "_flag.svg"),
              title: this.$t("languages." + item.name),
              value: item.id,
            })
          );
        })
        // .catch(() => this.$log.debug("Error fetching languages"))
        .finally(() => (this.loading = false));
    },

    getCountryCode(id) {
      switch (id) {
        case 1:
          return "uk";
        case 2:
          return "es";
        case 3:
          return "cn";
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.selectable-lang {
  padding: 0;
  margin: 0;
}
.language-selector {
  width: 100%;
}
</style>
