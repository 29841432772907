import store from "@/common/store";

const isAdmin = () => {
  return store.state.user.authorities.indexOf("ROLE_ADMIN") !== -1;
};

const isTutor = () => {
  return store.state.user.authorities.indexOf("ROLE_TUTOR") !== -1;
};

const isContentEditor = () => {
  return store.state.user.authorities.indexOf("ROLE_CONTENT_EDITOR") !== -1;
};

const isAnyTeacher = () => {
  return (
    store.state.user.authorities.findIndex(
      (a) => a.indexOf("ROLE_TEACHER") !== -1
    ) !== -1
  );
};

const isTeacher = () => {
  return store.state.user.authorities.indexOf("ROLE_TEACHER") !== -1;
};

const isTeacherCandidate = () => {
  return store.state.user.authorities.indexOf("ROLE_TEACHER_CANDIDATE") !== -1;
};

const isAnyStudent = () => {
  return (
    store.state.user.authorities.findIndex(
      (a) => a.indexOf("ROLE_STUDENT") !== -1
    ) !== -1
  );
};

const isStudent = () => {
  return store.state.user.authorities.indexOf("ROLE_STUDENT") !== -1;
};

const isStudentCandidate = () => {
  return store.state.user.authorities.indexOf("ROLE_STUDENT_CANDIDATE") !== -1;
};

const isHeadmaster = () => {
  return store.state.user.authorities.indexOf("ROLE_HEADMASTER") !== -1;
};

const isLogged = () => {
  return store.state.user.logged;
};

const isGuest = () => {
  return (
    store.state.user.authorities.findIndex(
      (a) => a.indexOf("_GUEST") !== -1
    ) !== -1
  );
};

const isAdult = () => {
  return store.state.user.isAdult;
};

const isCompany = () => {
  return store.state.user.authorities.indexOf("ROLE_COMPANY") !== -1;
};

export function isMe(login) {
  return store.state.user.login === login;
}

export function mapAuthGetter(options) {
  const object = {};
  options.forEach((option) => {
    object[option] = eval(option);
  });
  return object;
}
