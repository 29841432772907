<template>
  <v-navigation-drawer
    v-model="localDrawer"
    app
    absolute
    temporary
    hide-overlay
    light
    class="teal lighten-4"
  >
    <!-- My Profile Information -->
    <template v-slot:prepend>
      <v-list-item class="teal" two-line>
        <v-list-item-avatar>
          <v-img :src="photo" :lazy-src="defaultProfilePhoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content v-if="isLogged">
          <v-list-item-title>
            {{ user.displayName || user.firstName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.login }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ loggedAs }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <span> {{ $t("menuBar.userNotLogged") }} </span>
          <span>
            <router-link :to="{ name: 'Login' }">
              {{ $t("account.actions.log_in") }}
            </router-link>
          </span>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider v-if="isLogged" />

    <v-list-group
      v-if="user.authorities.length > 1"
      no-action
      @click.stop.prevent
    >
      <template v-slot:activator>
        <v-list-item-title>
          {{ $t("user_management.authorities") }}
        </v-list-item-title>
      </template>
      <v-list-item
        v-for="authority in authorities"
        :class="
          user.selectedAuthority === authority
            ? 'selected-authority'
            : 'no-selected-authority'
        "
        :key="authority"
        @click="changeAuthority(authority)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("user_management.roles." + authority) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-divider v-if="authorities.length > 0" />

    <v-list-item
      v-for="item in singleItems"
      :key="item.title"
      :to="{ name: item.link, params: item.params, query: item.query }"
    >
      <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
    </v-list-item>

    <v-list-group no-action v-for="menuItem in menuItems" :key="menuItem.title">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ $t(menuItem.title) }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-for="item in menuItem.items">
        <v-list-item
          :key="item.title"
          :to="{ name: item.link, params: item.params, query: item.query }"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="item.divider" :key="item.title + '_divider'" />
      </template>
    </v-list-group>

    <template v-slot:append>
      <div class="pa-2" v-if="isLogged">
        <v-btn color="primary" block @click="logout">{{
          $t("menuBar.logout")
        }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import usersHome from "@/enumerates/UsersHome";

const publicItems = require("./menu-items/PUBLIC_BarItems.json");

export default {
  name: "LateralMenuBar",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultProfilePhoto: require("@/assets/icon_user-accent.png"),
      user: auth.getUser(),
      languages: [
        {
          flag: require("@/assets/flag_icons/uk_flag.svg"),
          language: "EN",
          title: "English",
        },
        {
          flag: require("@/assets/flag_icons/es_flag.svg"),
          language: "ES",
          title: "Español",
        },
        {
          flag: require("@/assets/flag_icons/cn_flag.svg"),
          language: "CN",
          title: "Chinese",
        },
      ],
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    loggedAs() {
      return this.isLogged
        ? this.$t(`menuBar.roles.${this.selectedAuthority}`)
        : "";
    },
    roleItems() {
      let items = [];
      if (!this.user.id || !this.selectedAuthority) {
        return JSON.parse(JSON.stringify(publicItems));
      }
      let authItems = [];
      try {
        authItems = require("./menu-items/" +
          this.selectedAuthority +
          "_BarItems.json");
      } catch (e) {
        this.$log.debug("Error downloading menuItems for role " + auth);
        return JSON.parse(JSON.stringify(publicItems));
      }
      items = items.concat(authItems);
      return JSON.parse(JSON.stringify(items)).filter(
        (i) => i != null && (!i.mustBeAdult || this.isAdult)
      );
    },
    singleItems() {
      return this.roleItems.filter(
        (i) => i.link && (!i.mustBeAdult || this.isAdult)
      );
    },
    menuItems() {
      const auxItems = this.roleItems.filter(
        (menuItem) => !menuItem.link && (!menuItem.mustBeAdult || this.isAdult)
      );
      return this.isAdult
        ? auxItems
        : auxItems.map((i) => {
            i.items = i.items.filter((i) => !i.mustBeAdult || this.isAdult);
            return i;
          });
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("drawer-changed", val);
      },
    },
    selectedAuthority: {
      get: function () {
        return this.user ? auth.getSelectedAuthority() : null;
      },
      set: function (value) {
        auth.setSelectedAuthority(value);
      },
    },
    authorities() {
      return this.user ? this.user.authorities : [];
    },
    photo() {
      return this.user && this.user.photo
        ? this.user.photo
        : this.defaultProfilePhoto;
    },
    isAdult() {
      return this.user ? this.user.isAdult : false;
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    changeAuthority(authority) {
      if (authority != this.selectedAuthority) {
        this.selectedAuthority = authority;
        this.$router.push({ name: usersHome[this.selectedAuthority] });
      }
    },
    logout() {
      auth.logout().then(
        this.$router.push({ name: "Login" }).catch((err) => {
          //Do nothing if navigating to current route, otherwise throw error
          if (!err.name === "NavigationDuplicated") {
            throw err;
          }
        })
      );
    },
  },
};
</script>
<style scoped>
.selected-authority {
  background-color: rgba(66, 139, 202, 0.2);
}

.no-selected-authority {
  cursor: pointer;
  background-color: unset;
}
</style>
