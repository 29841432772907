<template>
  <v-container fluid>
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="auto" class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <debounced-text-field
              append-icon="search"
              class="d-md-inline-block mr-6"
              dense
              hide-details
              v-model="search"
              :label="$t('search')"
              @input="onSearchChange"
            ></debounced-text-field>
            <v-btn
              color="success ml-2"
              :to="{ name: 'Create Student', params: { backPrevious: true } }"
            >
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              class="rows-clickable"
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :options="pagination"
              :server-items-length="totalItems"
              @update:options="onPaginationChange"
              @click:row="entityDetail"
              disable-sort
            >
              <template v-slot:[`item.dateStart`]="{ item }">
                <span>
                  {{ item.dateStart | dateTimeWithTz }}
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      @click.stop="entityDetail(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      description
                    </v-icon>
                  </template>
                  <span>{{ $t("actions.detail") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      @click.stop="selectDeactivateUser(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("actions.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialogo para la eliminacion de usuarios -->
    <delete-dialog
      :dialog="deleteDialog"
      :loading="deleteLoading"
      @cancel="deleteDialog = false"
      @submit="deactivateUser"
    ></delete-dialog>
  </v-container>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);

export default {
  name: "StudentList",
  components: {
    DebouncedTextField,
    DeleteDialog,
  },
  data() {
    return {
      items: [],
      deleteLoading: false,
      isLoading: false,
      totalItems: 0,
      pagination: defaultPaginationSettings,
      search: null,
      selectedEntity: null,
      deleteDialog: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("profile.fields.display_name"),
          value: "displayName",
        },
        {
          text: this.$t("profile.fields.surname"),
          value: "surname",
        },
        {
          text: this.$t("profile.fields.name"),
          value: "name",
        },
        {
          text: this.$t("profile.fields.main_email"),
          value: "mainEmail",
        },
        {
          text: this.$t("profile.fields.main_phone"),
          value: "mainPhone",
        },
        {
          text: this.$t("profile.fields.date_start"),
          value: "dateStart",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  watch: {
    "$route.name"() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      const options = {
        params: {
          page: this.pagination.page - 1,
          authority:
            this.$route.name === "Candidate students list"
              ? "ROLE_STUDENT_CANDIDATE"
              : "ROLE_STUDENT",
          size: this.pagination.itemsPerPage,
          search: this.search ? this.search : undefined,
        },
      };
      StudentEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() =>
          this.$log.debug("Error fetching students with params: " + options)
        )
        .finally(() => (this.isLoading = false));
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "StudentView Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.fetchData();
      }
    },
    onPaginationChange(pagination = this.pagination) {
      this.pagination = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.pagination.page.toString();
      query.pageSize = this.pagination.itemsPerPage.toString();
      query.search = this.search ? this.search : undefined;
      this.redirect(query);
    },
    onSearchChange() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      } else {
        this.onPaginationChange();
      }
    },
    deactivateUser() {
      this.deleteLoading = true;
      UserDataEntityRepository.deactivateUser(this.selectedEntity)
        .then(() => this.fetchData())
        .catch(() =>
          this.$log.debug("Error deactivating user: " + this.selectedEntity)
        )
        .finally(() => {
          this.selectedEntity = null;
          this.deleteDialog = false;
          this.deleteLoading = false;
        });
    },
    selectDeactivateUser(entity) {
      this.selectedEntity = entity;
      this.deleteDialog = true;
    },
  },
};
</script>
