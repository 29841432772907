export default [
  {
    text: "genre.values.FEMALE",
    value: "FEMALE",
  },
  {
    text: "genre.values.MALE",
    value: "MALE",
  },
];
