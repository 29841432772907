<template>
  <v-container fluid v-if="items">
    <a ref="hiddenDownloader" class="d-none" />
    <v-card class="card-datatable">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="4"
            lg="6"
            xl="8"
            order="2"
            order-sm="1"
            class="text-right"
          >
            <v-btn
              class="ml-2"
              outlined
              color="primary"
              :loading="loadingAllPDF"
              @click="allToPDF"
            >
              <v-icon class="mr-2">picture_as_pdf</v-icon>
              {{ $t("invoice.export.pdf") }}
            </v-btn>
            <v-btn
              class="ml-2"
              outlined
              color="primary"
              :loading="loadingXml"
              @click="getXml"
            >
              <v-icon class="mr-2">file_download</v-icon>
              {{ $t("invoice.export.xml") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="4" xl="2">
            <autocomplete
              v-model="userFilter"
              :debouncing="300"
              dense
              :items="filteredUsers"
              :item-text="getFullName"
              item-value="userId"
              :label="$t('invoice.prop.user')"
              :loading="users.loading"
              no-filter
              solo
              :search-input.sync="userSearch"
              @change="redirectOnFilterChange"
            >
            </autocomplete>
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: startTimeFilter,
                label: 'invoice.prop.startTime',
              }"
              @update-time="
                updateDateTime('startTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <dateAndHourPicker
              :datePickerProp="{
                data: endTimeFilter,
                label: 'invoice.prop.endTime',
              }"
              @update-time="
                updateDateTime('endTimeFilter', false, ...arguments)
              "
            ></dateAndHourPicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :footer-props="tableFooterProps"
              :headers="headers"
              :items="items"
              :options="entitiesPage"
              :server-items-length="totalItems"
              :loading="loading"
              @update:options="redirectOnTableChange"
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  v-if="item.studentId"
                  :to="{
                    name: 'StudentBalanceTab',
                    params: { id: item.studentId, backPrevious: true },
                  }"
                >
                  {{ item.name }}
                </router-link>
                <router-link
                  v-else-if="item.userId"
                  :to="{
                    name: 'TutorView Balance',
                    params: { id: item.userId, backPrevious: true },
                  }"
                >
                  {{ item.name }}</router-link
                >
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date | dateTimeWithoutTz }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ $t(`invoice.state.${item.status}`) }}
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      @click.stop="printPDF(item.id)"
                      :loading="loadingPDF"
                      :disabled="loadingPDF"
                      v-bind="attrs"
                      v-on="on"
                    >
                      picture_as_pdf
                    </v-icon>
                  </template>
                  <span>{{ $t("actions.export_pdf") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete.vue";
import {
  dateArrayToDateString,
  dateToMinLocalDateOrMaxLocalDate,
} from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";
import tableFooterProps from "@/common/table-footer-props";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

const UserDataEntityRepository = RepositoryFactory.get(
  "UserDataEntityRepository"
);
const InvoiceEntityRepository = RepositoryFactory.get(
  "InvoiceEntityRepository"
);
const today = new Date();
const defaultStartTime = [
  today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear(),
  today.getMonth() === 0 ? 12 : today.getMonth(),
  today.getDate(),
];
const defaultEndTime = [
  today.getFullYear(),
  today.getMonth() + 1,
  today.getDate(),
];

export default {
  name: "InvoiceList",
  components: {
    Autocomplete,
    DateAndHourPicker,
  },
  data() {
    return {
      items: [],
      showFilters: true,
      userFilter: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      startTimeFilter: null,
      endTimeFilter: null,
      users: {
        items: [],
        loading: false,
      },
      totalItems: 0,
      loading: false,
      firstLoad: true,
      loadingXml: false,
      loadingAllPDF: false,
      loadingPDF: false,
      tableFooterProps,
      userSearch: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("invoice.prop.reference"),
          value: "series",
        },
        {
          text: this.$t("invoice.prop.name"),
          value: "name",
        },
        {
          text: this.$t("invoice.prop.date"),
          value: "date",
        },
        {
          text: this.$t("invoice.prop.state"),
          value: "status",
        },
        {
          text: this.$t("invoice.prop.actions"),
          sortable: false,
          value: "action",
        },
      ];
    },
    filters() {
      return {
        userFilter: this.userFilter,
        startTime: this.startTimeFilter
          ? dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.startTimeFilter),
              false
            )
          : null,
        endTime: this.endTimeFilter
          ? dateToMinLocalDateOrMaxLocalDate(
              dateArrayToDateString(this.endTimeFilter),
              true
            )
          : null,
      };
    },
    filteredUsers() {
      return this.userSearch
        ? this.users.items.filter((item) =>
            (item.name + item.surname)
              .replace(/\s+/g, "")
              .toUpperCase()
              .includes(this.userSearch.replace(/\s+/g, "").toUpperCase())
          )
        : this.users.items;
    },
  },
  created() {
    if (this.$route.query.userFilter) {
      this.showFilters = true;
      let value = parseFloat(this.$route.query.userFilter);
      this.userFilter = isNaN(value) ? null : value;
    }

    if (this.$route.query.startTimeFilter) {
      if (this.$route.query.startTimeFilter === "null") {
        this.startTimeFilter = null;
      } else {
        this.startTimeFilter = this.$route.query.startTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.startTimeFilter = defaultStartTime;
    }

    if (this.$route.query.endTimeFilter) {
      if (this.$route.query.endTimeFilter === "null") {
        this.endTimeFilter = null;
      } else {
        this.endTimeFilter = this.$route.query.endTimeFilter
          .split("-")
          .map((e) => parseInt(e));
      }
    } else {
      this.endTimeFilter = defaultEndTime;
    }

    if (this.firstLoad) {
      this.getItems()
        .then(() => this.getUsers())
        .then(() => (this.firstLoad = false));
    }
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          size: this.entitiesPage.itemsPerPage,
          sort: generateSort(this.entitiesPage),
          ...this.filters,
        },
      };
      return InvoiceEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .catch(() => this.$log.debug("Error fetching invoices"))
        .finally(() => (this.loading = false));
    },
    getUsers() {
      this.users.loading = true;
      const options = {
        params: {
          startTime: this.startTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.startTimeFilter),
                false
              )
            : null,
          endTime: this.endTimeFilter
            ? dateToMinLocalDateOrMaxLocalDate(
                dateArrayToDateString(this.endTimeFilter),
                false
              )
            : null,
        },
      };
      UserDataEntityRepository.getAllWithInvoice(options)
        .then((response) => {
          this.users.items = response;
        })
        .catch(() =>
          this.$log.debug("Error fetching users with associated invoices")
        )
        .finally(() => (this.users.loading = false));
    },
    printPDF(invoiceId) {
      this.loadingPDF = true;
      return InvoiceEntityRepository.downloadPDF(invoiceId)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("invoice.export.download_success"),
            type: "success",
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            this.$notify({
              title: this.$t("invoice.export.not_found"),
              type: "warning",
            });
          } else {
            this.$notify({
              title: this.$t("invoice.export.download_error"),
              type: "error",
              duration: 30000,
            });
          }
        })
        .finally(() => (this.loadingPDF = false));
    },
    allToPDF() {
      this.loadingAllPDF = true;
      const options = {
        params: {
          ...this.filters,
        },
      };
      return InvoiceEntityRepository.allPDF(options)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("invoice.export.download_success"),
            type: "success",
          });
        })
        .catch((response) => {
          this.$notify({
            title: this.$t("invoice.export.download_error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.loadingAllPDF = false));
    },
    redirect(query) {
      if (
        !this.firstLoad &&
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        // Prevent get users when users' filter change
        if (this.$route.query.userFilter === query.userFilter) {
          this.getUsers();
        }
        this.$router.replace({
          name: this.$route.name,
          query: query,
        });
        this.getItems();
      }
    },
    getXml() {
      this.loadingXml = true;
      const options = {
        params: {
          ...this.filters,
        },
      };
      return InvoiceEntityRepository.getXml(options)
        .then((response) => {
          downloadFile(response, this.$refs.hiddenDownloader);
          this.$notify({
            title: this.$t("invoice.export.download_success"),
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t("invoice.export.download_error"),
            type: "error",
            duration: 30000,
          });
        })
        .finally(() => (this.loadingXml = false));
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.userFilter = this.userFilter ? this.userFilter : undefined;
      query.startTimeFilter = this.startTimeFilter
        ? dateArrayToDateString(this.startTimeFilter)
        : "null";
      query.endTimeFilter = this.endTimeFilter
        ? dateArrayToDateString(this.endTimeFilter)
        : "null";
      query.sort = generateSort(this.entitiesPage);
      this.redirect(query);
    },
    updateDateTime(name, hasTime, data) {
      this[name] =
        hasTime && data.date ? data.date.concat(data.time) : data.date;
      this.redirectOnFilterChange();
    },
    getFullName(item) {
      return item.name + " " + item.surname;
    },
  },
};
</script>
