<template>
  <v-dialog persistent fullscreen :value="dialog && selectedProduct">
    <v-card v-if="dialog && selectedProduct">
      <v-card-title primary-title class="primary white--text headline">
        <v-row no-gutters>
          <v-col>
            <v-icon class="primary white--text mr-4"> school </v-icon>
            {{ $t("productRequest.modal.title") }}
          </v-col>
          <v-col class="text-right">
            <v-btn color="white" @click="cancelDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form v-model="validForm">
          <v-row>
            <v-col cols="12" md="6">
              <day-picker
                calendarColor="primary"
                :date="limitDate"
                @change="handleLimitDate"
                outlined
                clearable
                :label="$t('productRequest.prop.limitDate')"
                :futureDates="true"
                :pastDates="false"
              ></day-picker>
            </v-col>
            <v-col cols="12" md="6">
              <autocomplete
                v-model="studentIds"
                :items="students"
                class="required"
                :rules="[
                  (v) => !!v || $t('productRequest.modal.validation.required'),
                  (v) =>
                    v.length >= selectedProduct.minStudents ||
                    $t('productRequest.modal.validation.min_students', {
                      value: selectedProduct.minStudents,
                    }),
                  (v) =>
                    v.length <= selectedProduct.maxStudents ||
                    $t('productRequest.modal.validation.max_students', {
                      value: selectedProduct.maxStudents,
                    }),
                ]"
                outlined
                multiple
                :item-text="getFullName"
                item-value="id"
                :label="$t('productRequest.modal.students')"
                :loading="loadingStudents"
              ></autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span>{{
                $t("productRequest.modal.required-classes-per-week", {
                  value: selectedProduct.classesPerWeek,
                })
              }}</span>
            </v-col>
            <v-col class="text-center">
              <span>{{
                $t("productRequest.modal.required-slots-per-class", {
                  value: slotsPerClass,
                })
              }}</span>
            </v-col>
            <v-col class="text-right">
              <v-btn
                class="mx-4 primary"
                @click="save"
                :loading="loading"
                :disabled="!validSchedule || !validForm"
              >
                {{ $t("actions.send") }}
                <v-icon xs class="ml-2">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet :height="height">
                <v-calendar
                  :value="focus"
                  color="primary"
                  :events="availabilities"
                  :event-color="getEventColor"
                  :interval-height="25"
                  :interval-minutes="30"
                  :interval-count="48"
                  type="week"
                  ref="calendar"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  @click:event="selectSlot"
                  :locale="localeRoot"
                >
                  <template v-slot:event="{ event }">
                    <div class="event-div">
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols="12">
                          <span>
                            {{ event.name }}
                          </span>
                        </v-col>
                        <v-col cols="12" v-if="event.checked">
                          <v-icon small>mdi-check</v-icon>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DayPicker from "@/components/calendar/DayPicker";
import Autocomplete from "@/components/debouncing-inputs/Autocomplete";
import moment from "moment-timezone";
import { timeSlotToWeekMoment } from "@/common/conversion-utils";
import auth from "@/common/auth";
import timeSlots from "@/components/calendar/TimeSlots";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

const TIME_SLOT_LENGTH = 30;
/**
 * Events emitted: cancel, submit
 */
export default {
  name: "CompanyProductRequestDialog",
  components: { DayPicker, Autocomplete },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: "550",
    },
  },
  data() {
    return {
      availabilities: [],
      limitDate: null,
      loadingStudents: false,
      classesPerWeek: null,
      focus: moment().format().substring(0, 10),
      students: [],
      studentIds: [],
      schedules: [],
      validForm: false,
    };
  },
  computed: {
    slotsPerClass() {
      return Math.ceil(this.selectedProduct.classDuration / TIME_SLOT_LENGTH);
    },
    selectedDays() {
      const groupByDay = this.schedules.reduce((r, a) => {
        r[a.day] = [...(r[a.day] || []), a];
        return r;
      }, {});
      return (
        Object.keys(groupByDay).length == this.selectedProduct.classesPerWeek
      );
    },
    validSchedule() {
      if (!this.selectedDays) return false;
      const groupByDay = this.schedules.reduce((r, a) => {
        r[a.day] = [...(r[a.day] || []), a];
        return r;
      }, {});
      let isValid = true;
      Object.keys(groupByDay).forEach((day) => {
        if (!this._checkSlotsPerDay(parseInt(day))) {
          isValid = false;
        }
      });
      return isValid;
    },
    canSelectClassesPerWeek() {
      return (
        this.selectedProduct &&
        this.selectedProduct.productType &&
        this.selectedProduct.productType.split("_")[0] === "ADHOC" &&
        this.selectedProduct.classesPerWeek === null
      );
    },
    localeRoot() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime("09:00");
  },
  created() {
    this._fetchData();
    this.formatAvailabilities();
  },
  methods: {
    _fetchData() {
      this.loadingStudents = true;
      StudentEntityRepository.getAllStudentsOfCompany(auth.getUser().id)
        .then((data) => (this.students = data))
        .finally(() => (this.loadingStudents = false));
    },
    // Parse timeSlot to calendar events
    formatAvailabilities() {
      this.availabilities = [];
      this.availabilities = timeSlots.map((el, idx) => {
        return {
          timeSlot: el,
          name: this._getSlotName(el),
          start: timeSlotToWeekMoment(
            el.day,
            el.startTimeHour,
            el.startTimeMinute
          )
            .format()
            .substring(0, 16),
          end: timeSlotToWeekMoment(el.day, el.endTimeHour, el.endTimeMinute)
            .format()
            .substring(0, 16),
          checked: false,
        };
      });
    },
    getEventColor(event) {
      if (event.checked) {
        if (!this._checkSlotsPerDay(event.timeSlot.day)) {
          return "warning";
        } else {
          return "green";
        }
      } else {
        return event.timeSlot.startTimeHour % 2 == 0 ? "primary" : "accent";
      }
    },
    _checkSlotsPerDay(day) {
      const daySlots = this.schedules.filter((el) => el.day == day);
      if (daySlots.length != this.slotsPerClass) return false;
      else if (this.slotsPerClass > 1) {
        for (const slot of daySlots) {
          if (
            daySlots.filter(
              (el) =>
                (el.startTimeHour == slot.startTimeHour &&
                  el.startTimeMinute == slot.startTimeMinute) ||
                (el.endTimeHour == slot.startTimeHour &&
                  el.endTimeMinute == slot.startTimeMinute) ||
                (el.startTimeHour == slot.endTimeHour &&
                  el.startTimeMinute == slot.endTimeMinute) ||
                (el.endTimeHour == slot.endTimeHour &&
                  el.endTimeMinute == slot.endTimeMinute)
            ).length < 2
          )
            return false;
        }
      }
      return true;
    },
    _getSlotName(slot) {
      return (
        timeSlotToWeekMoment(slot.day, slot.startTimeHour, slot.startTimeMinute)
          .format()
          .substring(11, 16) +
        "/" +
        timeSlotToWeekMoment(slot.day, slot.endTimeHour, slot.endTimeMinute)
          .format()
          .substring(11, 16)
      );
    },
    selectSlot({ event }) {
      const index = this.schedules.findIndex(
        (el) =>
          el.day == event.timeSlot.day &&
          el.startTimeHour == event.timeSlot.startTimeHour &&
          el.startTimeMinute == event.timeSlot.startTimeMinute
      );
      const daySlots = this.schedules.filter(
        (el) => el.day == event.timeSlot.day
      ).length;
      if (event.checked) {
        event.checked = false;
        this.schedules.splice(index, 1);
      } else {
        if (this.selectedDays && daySlots == 0) {
          this.$notify();
        } else if (daySlots >= this.slotsPerClass) {
          this.$notify();
        } else {
          event.checked = true;
          this.schedules.push(event.timeSlot);
        }
      }
    },
    save() {
      this.$emit("submit", {
        limitDate: this.limitDate,
        classesPerWeek: this.classesPerWeek,
        studentIds: this.studentIds,
        schedules: this.schedules,
      });
      this.limitDate = null;
      this.classesPerWeek = null;
    },
    cancelDialog() {
      this.$emit("cancel");
      this.limitDate = null;
      this.classesPerWeek = null;
    },
    handleLimitDate(date) {
      this.limitDate = date;
    },
    getFullName(item) {
      return item.name + " " + item.surname;
    },
  },
};
</script>

<style scoped>
.event-div {
  width: 100%;
  height: 100%;
}
.event-div .row {
  height: 100%;
  text-align: center;
}
.v-calendar >>> .v-calendar-daily_head-day-label {
  display: none;
}
.v-calendar >>> .v-calendar-daily_head-weekday {
  color: black !important;
}
</style>
