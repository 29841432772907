import { HTTP } from "@/common/http-common";

const RESOURCE_NAME = "entities/lectures";

export default {
  async getAll(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}`, options);
    return response.data;
  },

  async getAllWithTeacherPayments(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/teacherPayments`,
      options
    );
    return response.data;
  },

  async getClose(options = {}) {
    const response = await HTTP.get(`${RESOURCE_NAME}/close`, options);
    return response.data;
  },

  async getCloseForTeacher(teacherId) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/close/teacher/${teacherId}`
    );
    return response.data;
  },

  async getByTeacher(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/teacher`)).data;
  },

  async getByStudent(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/student`)).data;
  },

  async getByTutor(id, options = {}) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}/tutor`, options)).data;
  },

  async getByAdmin(id) {
    return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
  },

  async getAllToAttend(options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/getAllLecturesToAttend`,
      options
    );
    return response.data;
  },

  async createLevelTest(entity) {
    return (await HTTP.post(`${RESOURCE_NAME}/levelTest`, entity)).data;
  },

  async changeTeacher(id, entity) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/changeTeacher`, entity))
      .data;
  },

  async changeSchedule(id, entity) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/changeSchedule`, entity))
      .data;
  },

  async getTeacherLectureCount(options = {}) {
    return (await HTTP.get(`${RESOURCE_NAME}/getTeacherLectureCount`, options))
      .data;
  },

  async cancelLecture(id) {
    return (await HTTP.put(`${RESOURCE_NAME}/${id}/cancel`)).data;
  },

  async getCommonAvailabilities(id, options = {}) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/findCommonAvailabilities`, options)
    ).data;
  },

  async settleLectures(options = {}) {
    options.responseType = "blob";
    return HTTP.get(`${RESOURCE_NAME}/settleLectures`, options);
  },

  async settledTeacherLectures(id, options = {}) {
    const response = await HTTP.get(
      `${RESOURCE_NAME}/${id}/getSettledTeacherLectures`,
      options
    );
    return response.data;
  },

  async getPDFSettlement(options = {}) {
    options.responseType = "blob";
    return HTTP.get(`${RESOURCE_NAME}/getPDFSettlement`, options);
  },
};
