<template>
  <v-dialog v-model="dialog" width="75%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <v-btn ref="closeButton" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            {{ $t("wallet.charge_dialog.title") }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <v-row>
                <number-field
                  v-model="charge.amount"
                  prefix="€"
                  type="Double"
                  :disabled="processingPayment"
                  :label="$t('wallet.charge_dialog.amount')"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('wallet.form.validation.amount_required'),
                    (v) =>
                      parseInt(v) >= minAmount ||
                      $t('wallet.form.validation.amount_min', {
                        amount: minAmount,
                        currency: currency,
                      }),
                    (v) =>
                      parseInt(v) <= maxAmount ||
                      $t('wallet.form.validation.amount_max', {
                        amount: maxAmount,
                        currency: currency,
                      }),
                  ]"
                ></number-field>
              </v-row>
              <v-row>
                <v-select
                  v-model="charge.type"
                  :disabled="processingPayment"
                  :items="paymentMethods"
                  :label="$t('wallet.charge_dialog.method')"
                  class="required"
                  :rules="[
                    (v) => !!v || $t('wallet.form.validation.method_required'),
                  ]"
                >
                  <template v-slot:selection="data">
                    <v-row no-gutters>
                      <v-list-item-icon class="selectable-lang">
                        <img :src="data.item.icon" width="35" height="20" />
                      </v-list-item-icon>
                      <v-list-item-content class="selectable-lang">
                        <v-list-item-title>{{
                          $t(data.item.name)
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-row>
                  </template>

                  <template v-slot:item="data">
                    <v-list-item-icon>
                      <img :src="data.item.icon" width="26" height="30" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t(data.item.name)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-col cols="12" class="text-center pt-0">
          <redsys-button
            v-if="charge.type === 'CREDIT_CARD'"
            :amount="Number(charge.amount)"
            :walletId="walletId"
            @completed="onCompleted"
          >
          </redsys-button>
          <paypal-button
            v-else-if="charge.type === 'PAYPAL'"
            :amount="Number(charge.amount)"
            :walletId="walletId"
            @completed="onCompleted"
            @processingPayment="onProcessingPayment"
          ></paypal-button>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumberField from "@/components/number-field/NumberField.vue";
import PayPalButton from "./PayPalButton.vue";
import RedSysButton from "./RedSysButton.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "ChargeDialog",
  components: {
    "number-field": NumberField,
    "paypal-button": PayPalButton,
    "redsys-button": RedSysButton,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    walletId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      charge: {
        amount: null,
        type: null,
      },
      loading: false,
      currency: "€",
      maxAmount: 500,
      minAmount: 5,
      validForm: false,
      paymentMethods: [
        {
          icon: require("@/assets/payment_methods/credit_card.svg"),
          name: "wallet.methods.CREDIT_CARD",
          value: "CREDIT_CARD",
        },
        {
          icon: require("@/assets/payment_methods/paypal.svg"),
          name: "wallet.methods.PAYPAL",
          value: "PAYPAL",
        },
      ],
      processingPayment: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isCompany"]),
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value: {
      handler(newVal, oldVal) {
        this.dialog = newVal;
        // reset form state
        if (newVal !== oldVal)
          this.charge = {
            amount: null,
            type: null,
          };
      },
      immediate: true,
    },
  },
  mounted() {
    this.maxAmount = this.isCompany ? 10000 : 500;
  },
  methods: {
    onCompleted(walletUpdated) {
      this.$emit("charged", walletUpdated);
      this.processingPayment = false;
    },
    onProcessingPayment(state = true) {
      this.processingPayment = state;
    },
    onClose() {
      if (!this.processingPayment) {
        this.dialog = false;
      } else {
        this.$notify({
          title: this.$t(
            "wallet.messages.charge_dialog.processing-payment.title"
          ),
          text: this.$t(
            "wallet.messages.charge_dialog.processing-payment.text"
          ),
          type: "warning",
        });
      }
    },
  },
};
</script>

<style></style>
