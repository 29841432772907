<template>
  <v-card flat>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="6" md="8">
          <span class="headline">
            {{ label ? label : $t("product.prop.tariffs") }}
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right" cols="6" md="3">
          <v-btn
            :disabled="!product.productType"
            @click="openEditDialog()"
            color="success"
            ><v-icon>add</v-icon>{{ $t("actions.add") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="tariffs && tariffs.length > 0">
      <v-card
        v-for="(tariff, index) in tariffs"
        :key="tariff.id"
        align="center"
      >
        <v-card-text v-if="!tariff.markDelete">
          <v-row no-gutters align="center">
            <v-col
              :class="
                tariff.isValid == false ? 'red--text shake' : 'black--text'
              "
              cols="2"
            >
              <b>{{ $t("product.prop.min_students") + ": " }}</b
              >{{
                tariff.minStudents ? tariff.minStudents : $t("product.none")
              }}
            </v-col>
            <v-col
              :class="
                tariff.isValid == false ? 'red--text shake' : 'black--text'
              "
              cols="2"
            >
              <b>{{ $t("product.prop.max_students") + ": " }}</b
              >{{
                tariff.maxStudents ? tariff.maxStudents : $t("product.none")
              }}
            </v-col>

            <v-col cols="2">
              <b>{{ $t("product.prop.price") + ": " }}</b
              >{{ tariff.price }}
            </v-col>
            <v-col cols="2">
              <b>{{ $t("product.payment_type.label") + ": " }}</b
              >{{ $t(`product.payment_type.${tariff.paymentType}`) }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-btn icon color="warning" @click="openEditDialog(tariff, index)"
                ><v-icon>edit</v-icon></v-btn
              >
              <v-btn icon color="error" @click="deleteTariff(index)"
                ><v-icon>delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text v-if="!!showError" class="text-center">
      <h3 :class="!!showError ? 'red--text shake' : 'black--text'">
        {{ $t(showError) }}
      </h3>
    </v-card-text>
    <!-- Dialog for creating / editing a tariff  -->
    <tariff-form-dialog
      v-if="editDialog"
      :product="product"
      :dialog="editDialog"
      :tariff="selectedTariff"
      @save="saveTariff"
      @cancel="closeEditDialog"
    ></tariff-form-dialog>
  </v-card>
</template>

<script>
import TariffFormDialog from "./TariffFormDialog";

export default {
  name: "TariffListCreateForm",
  components: {
    TariffFormDialog,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    product: {
      type: Object,
      required: true,
    },
    showError: {
      type: String,
      required: false,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.tariffs = newValue;
      },
      immediate: true,
    },
    tariffs(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.tariffs = this.value;
  },
  data() {
    return {
      tariffs: [],
      editDialog: false,
      selectedTariff: null,
      selectedIndex: null,
    };
  },
  methods: {
    openEditDialog(tariff, index) {
      this.editDialog = true;
      this.selectedTariff = tariff;
      this.selectedIndex = index;
    },
    closeEditDialog() {
      this.selectedTariff = null;
      this.selectedIndex = null;
      this.editDialog = false;
    },
    saveTariff(tariff) {
      if (this.selectedTariff) {
        tariff.isValid = true;
        this.$set(this.tariffs, this.selectedIndex, tariff);
      } else {
        this.tariffs.push(tariff);
      }
      this.closeEditDialog();
    },
    deleteTariff(index) {
      let elem = this.tariffs[index];
      if (elem.id != null) {
        elem.markDelete = true;
        this.$set(this.tariffs, index, elem);
      } else {
        this.tariffs.splice(index, 1);
      }
      this.closeEditDialog();
    },
  },
};
</script>

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
