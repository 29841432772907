<template>
  <v-container>
    <student-form
      account-fields
      include-tutors
      register-fields
      v-if="!loading"
      v-model="entity"
      @save="save"
    ></student-form>
    <loading-page v-if="loading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import StudentForm from "@/mockups/student/components/StudentForm";
import auth from "@/common/auth.js";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const CompanyStudentEntityRepository = RepositoryFactory.get(
  "CompanyStudentEntityRepository"
);
const StudentEntityRepository = RepositoryFactory.get(
  "StudentEntityRepository"
);

export default {
  components: {
    LoadingPage,
    StudentForm,
  },
  data() {
    return {
      loading: false,
      entity: {
        login: "",
        password: "",
        password2: "",
        langKey: "en",
      },
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
  },
  created() {
    if (this.isLogged) {
      this.$notify({
        title: "Error",
        text: this.$t("account.errors.forbidden_access"),
        type: "error",
        duration: 30000,
      });
      this.$router.push({ name: "Unauthorized" });
    }
    this._fetchData(this.$route.query.key);
  },
  methods: {
    _fetchData(key) {
      this.loading = true;
      return CompanyStudentEntityRepository.getByKey(key)
        .then((response) => {
          // Map response fields to tutor fields
          this.$set(this.entity, "mainEmail", response.email);
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            text: this.$t("companyStudent.error.not_found"),
            type: "error",
            duration: 30000,
          });
          this.$log.debug("Error fetching company student with key " + key);
        })
        .finally(() => (this.loading = false));
    },
    save() {
      this.loading = true;
      StudentEntityRepository.registerCompanyStudent(
        this.entity,
        this.$route.query.key
      )
        .then(() => {
          this.$notify({
            title: "Registered",
            text: this.$t("account.notifications.successfull_registered"),
            type: "success",
          });
          return auth.login({
            login: this.entity.login,
            password: this.entity.password,
            remember_me: false,
          });
        })
        .then(() => {
          this.$router.goToUserHome();
        })
        .catch(() =>
          this.$log.debug("Error registering company student: " + this.entity)
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
