<template>
  <v-container>
    <v-card v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("actions.cancel") }}
              </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save()">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("actions.save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.name"
                type="text"
                :rules="[(v) => !!v || $t('word.error.required')]"
                :label="$t('topic.prop.name')"
                outlined
                class="required"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <language-selector
                dense
                :loading="languageLoading"
                :search-input.sync="languageSearch"
                v-model="entity.language"
                :rules="[(v) => !!v || $t('word.error.required')]"
                :label="$t('topic.prop.language')"
                item-text="name"
                class="required"
                outlined
                return-object
              >
              </language-selector>
            </v-col>

            <v-col cols="12" md="6">
              <topic-type-selector
                dense
                :loading="typeLoading"
                :search-input.sync="typeSearch"
                v-model="entity.topicType"
                :rules="[(v) => !!v || $t('word.error.required')]"
                :label="$t('topic.prop.type')"
                item-text="name"
                class="required"
                outlined
                return-object
              >
              </topic-type-selector>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";

import LanguageSelector from "@/components/selectors/LanguageSelector";

import TopicTypeSelector from "@/components/selectors/TopicTypeSelector";

import RepositoryFactory from "@/repositories/RepositoryFactory";

const TopicEntityRepository = RepositoryFactory.get("TopicEntityRepository");

export default {
  name: "TopicForm",
  components: {
    LoadingPage,
    LanguageSelector,
    TopicTypeSelector,
  },
  data() {
    return {
      validForm: true,
      loading: false,
      entity: {},
      language: [],
      languageSearch: null,
      languageLoading: false,
      type: [],
      typeSearch: null,
      typeLoading: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  created() {
    if (this.$route.params.id) this._fetchData(this.$route.params.id);
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  watch: {
    languageSearch() {
      this.fetchLanguageItems();
    },

    typeSearch() {
      this.fetchTypeItems();
    },
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return TopicEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({ name: "Topic List", params: { backAction: true } });
    },

    save() {
      if (!this.validForm) {
        this.$notify({
          type: "error",
          text: this.$t("topic.error.form-errors"),
          duration: 30000,
        });
      } else {
        this.loading = true;
        TopicEntityRepository.save(this.entity)
          .then(() => {
            if (this.$route.params.id) {
              this.$router.push({
                name: "Topic Detail",
                params: {
                  id: this.entity.id,
                  backAction: this.$route.params.backPrevious,
                },
              });
            } else {
              this.$router.push({
                name: "Topic List",
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
